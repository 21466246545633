import React from 'react';

import { FormControlLabel, FormHelperText, Switch } from '@mui/material';
import { Controller } from 'react-hook-form';

const ControlledSwitchInput = ({
  defaultValue = false,
  name,
  label,
  control,
  color,
  switchProps,
  onChangeHandler = () => {},
  ...props
}) => (
  <Controller
    name={name}
    defaultValue={defaultValue}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <>
        <FormControlLabel
          {...props}
          control={
            <Switch
              color={color}
              {...switchProps}
              onChange={(event, checked) => {
                onChange(checked);
                onChangeHandler(event, checked);
              }}
              checked={value}
            />
          }
          label={label}
        />
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </>
    )}
  />
);

export default ControlledSwitchInput;
