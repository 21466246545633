export const dataGridStyles = {
  marginBottom: 1.6,
  '.MuiDataGrid-row': { paddingRight: '5px' },
  '& .MuiDataGrid-checkboxInput.Mui-disabled': { opacity: 0.38 },
  '.MuiDataGrid-row [data-id="create-mapping-icon"]': {
    opacity: 0
  },
  '.archived-row > .MuiDataGrid-cellCheckbox > .MuiButtonBase-root': {
    opacity: 0
  },
  '.archived-mapping-row > .MuiDataGrid-cellCheckbox > .MuiButtonBase-root': {
    opacity: 0
  },
  '.sync-inprogress-mapping-row > .MuiDataGrid-cellCheckbox > .MuiButtonBase-root': {
    opacity: 0
  },
  '.sync-inprogress-action-row > .MuiDataGrid-cellCheckbox > .MuiButtonBase-root': {
    opacity: 0
  },
  '& .MuiDataGrid-row:hover': {
    '[data-id="create-mapping-icon"]': { opacity: 1 },
    '& .MuiSvgIcon-root': { opacity: 1 },
    cursor: 'pointer'
  },
  '& .MuiDataGrid-columnHeader:nth-child(2) .MuiDataGrid-columnSeparator': {
    visibility: 'hidden'
  },
  '.mapping-row:hover .mapping-name, .action-row:hover .action-name': {
    textDecoration: 'underline'
  },
  '.action-row, .sync-inprogress-action-row': {
    '.MuiDataGrid-cellCheckbox': {
      paddingLeft: 8
    }
  },
  '.mapping-row > .MuiDataGrid-cellCheckbox': {
    paddingLeft: 5
  },
  '.workflow-row > .MuiDataGrid-cell[data-field="__tree_data_group__"]': {
    paddingLeft: 0,
    paddingRight: 0
  },
  '.archived-row > .MuiDataGrid-cell[data-field="__tree_data_group__"]': {
    paddingLeft: 0,
    paddingRight: 0
  },

  '.mapping-row > .MuiDataGrid-cell[data-field="__tree_data_group__"]': {
    margin: 0
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 500
  },
  '.MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-pinnedColumns': {
    backgroundColor: 'white',
    boxShadow: 'none'
  }
};
