import React from 'react';

import { alpha, Box } from '@mui/material';
import InputBase from '@mui/material/InputBase';

import { Custom } from 'components/Icons';
import { otherColors } from 'styles/theme/colors';

const getSearchInputSx = (isDark) => ({
  position: 'relative',
  paddingRight: '0px !important',
  borderRadius: '5px',
  background: isDark ? '#303030' : 'otherColors.selected',
  '& svg': {
    color: (theme) => (isDark ? alpha(theme.palette.other.white, 0.6) : alpha(theme.palette.other.black, 0.5))
  },
  '&:hover': {
    backgroundColor: (theme) => (isDark ? alpha(theme.palette.other.white, 0.15) : alpha(otherColors.selected, 0.85)),
    '& svg': {
      color: (theme) => (isDark ? theme.palette.other.white : theme.palette.other.black)
    }
  }
});

const searchIconWrapperSx = {
  py: 0,
  px: 1,
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const inputBaseSx = {
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: '6px 8px 6px 0px',
    // vertical padding + font size from searchIcon
    paddingLeft: (theme) => `calc(1em + ${theme.spacing(4)})`,
    transition: (theme) => theme.transitions.create('width'),
    width: { xs: '20ch', sm: '100%' },
    fontSize: '14px'
  }
};

const SearchTextInput = ({ sx = {}, xRef, isDark = true, overrideInputSx, text, ...props }) => (
  <Box sx={{ ...getSearchInputSx(isDark), ...sx }}>
    <Box sx={searchIconWrapperSx}>
      <Custom.SearchIcon />
    </Box>
    <InputBase
      placeholder={text || 'Search…'}
      inputProps={{ 'aria-label': 'search' }}
      sx={{ ...inputBaseSx, ...overrideInputSx }}
      ref={xRef}
      onKeyDown={(event) => event.stopPropagation()}
      {...props}
    />
  </Box>
);

export default SearchTextInput;
