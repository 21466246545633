import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { multiSelectFolders } from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown/folderTreeItemHelper';

export const getPlangridSelectEventHandlers = ({
  updateFormValues,
  loadFolders,
  onCompleted = () => {},
  connectionId,
  folders,
  uiFolders,
  parentFolderId,
  setParentFolderId
}) => {
  const selectProjectHandler = async ({ target: { value: projectId } = {} }, childElement) => {
    updateFormValues({
      hub: null,
      project: { id: projectId, name: childElement?.props?.dataText },
      folder: { id: '', name: '' },
      folders: [],
      isSubmitted: false
    });
    await loadFolders({ connectionId, projectId, skip: 0, take: DROPDOWN_DEFAULT_PG_SIZE });
    onCompleted?.();
  };

  const selectFoldersHandler = (event, id, children) =>
    multiSelectFolders({
      event,
      id,
      folders,
      uiFolders,
      children,
      parentFolderId,
      setParentFolderId,
      updateFormValues
    });

  return {
    selectProjectHandler,
    selectFoldersHandler
  };
};
