import { useState } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import {
  syncFetchForesiteCompanies as SYNC_FETCH_FORESITE_COMPANIES,
  syncFetchForesiteFolders as SYNC_FETCH_FORESITE_FOLDERS,
  syncFetchForesiteProjects as SYNC_FETCH_FORESITE_PROJECTS
} from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DROPDOWN_DEFAULT_PG_SIZE, MAX_DROPDOWN_ITEMS_IN_VIEW } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import ForesiteCompanyDropdown from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Foresite/ForesiteCompanyDropdown';
import ForesiteFoldersDropdown from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Foresite/ForesiteFoldersDropdown';
import { getForesiteSelectEventHandlers } from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Foresite/foresiteHelpers';
import ForesiteProjectDropdown from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Foresite/ForesiteProjectDropdown';
import useForesiteHook from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Foresite/useForesiteHook';
import FiltersAutocompleteDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FiltersAutocompleteDropdown';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_FIRST;

const Foresite = (props) => {
  const { connectionId = '', connectionType = '', nodeId = '', fileTypeFilters = [] } = props;
  const { showFilters = false, isSource = false, allowPrivateFiles = false } = props;
  const { getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const [parentFolderId, setParentFolderId] = useState('');

  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { hub, projects: selectedProjects = [], project = selectedProjects?.[0], uiFolders = [] } = formValues;

  const [
    { paginatedLazyLoad: lazyLoadCompanies, paginationHandler: companiesPaginationHandler },
    { loading: isLoadingCompanies, data: { syncFetchForesiteCompanies: ForesiteCompanies = [] } = {} }
  ] = useLazyPaginatedQuery(gql(SYNC_FETCH_FORESITE_COMPANIES), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);
  const [
    { paginatedLazyLoad: lazyLoadProjects, paginationHandler: projectsPaginationHandler },
    { loading: isLoadingProjects, data: { syncFetchForesiteProjects: FProjects = [] } = {} }
  ] = useLazyPaginatedQuery(gql(SYNC_FETCH_FORESITE_PROJECTS), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const onScrollCompanies = () => companiesPaginationHandler(ForesiteCompanies?.length, { connectionId });
  const onScrollProjects = () => projectsPaginationHandler(FProjects?.length, { connectionId, companyId: hub?.id });

  const fetchCompaniesOnLoad = () => {
    if (hub?.id) lazyLoadCompanies({ skip: null, take: null, connectionId });
    if (!hub?.id) lazyLoadCompanies({ skip: ForesiteCompanies?.length, connectionId });
  };
  const fetchProjectsOnLoad = () => {
    if (project?.id) lazyLoadProjects({ skip: null, take: null, connectionId, companyId: hub?.id });
    if (!project?.id) lazyLoadProjects({ skip: FProjects?.length, connectionId, companyId: hub?.id });
  };

  const [
    { lazyLoad: loadFolders, paginationHandler: fetchMoreRootFolders },
    { loading: isLoadingFolders, data: { syncFetchForesiteFolders: ForesiteFolders = [] } = {} }
  ] = useLazyPaginatedQuery(gql(SYNC_FETCH_FORESITE_FOLDERS), FETCH_POLICY, DROPDOWN_DEFAULT_PG_SIZE);

  const fetchOnMountForesiteProjects =
    hub?.id && !isLoadingCompanies && ForesiteCompanies?.length && !FProjects?.length;
  const fetchOnMountForesiteFolders =
    (selectedProjects?.[0]?.id || project?.id) && !isLoadingProjects && FProjects?.length && !ForesiteFolders?.length;

  const foldersDisabled = (isSource ? selectedProjects?.length > 1 : !project?.id) || !FProjects?.length;

  const updateFormValues = (props) => updateConnectorNodeValues({ nodeId, isSource, props });

  const setParentFolderIdOnStackLoad = (parentFolderId) => setParentFolderId(parentFolderId);

  const { selectCompanyHandler, selectProjectsHandler, selectFolderHandler } = getForesiteSelectEventHandlers({
    connectionId,
    projects: FProjects,
    loadFolders,
    loadProjects: lazyLoadProjects,
    updateFormValues,
    hub,
    folders: formValues?.folders || [],
    uiFolders,
    parentFolderId,
    setParentFolderId
  });

  useForesiteHook({
    updateFormValues,
    connectionId,
    connectionType,
    nodeId,
    isLoadingCompanies,
    formValues,
    loadFolders,
    loadProjects: fetchProjectsOnLoad,
    ForesiteCompanies,
    fetchOnMountForesiteProjects,
    fetchOnMountForesiteFolders,
    fetchCompaniesOnLoad
  });

  return (
    <Stack spacing={2}>
      <ForesiteCompanyDropdown
        value={hub?.id}
        onChange={selectCompanyHandler}
        loading={isLoadingCompanies}
        items={ForesiteCompanies}
        fetchPaginatedRecords={onScrollCompanies}
      />
      <ForesiteProjectDropdown
        value={isSource ? selectedProjects.map(({ id }) => id) : project?.id}
        onChange={selectProjectsHandler}
        loading={isLoadingProjects}
        disabled={!hub?.id || !ForesiteCompanies?.length}
        multiple={isSource} /* Multi Project selection is only allowed for source connectors */
        items={FProjects}
        fetchPaginatedRecords={onScrollProjects}
      />
      <ForesiteFoldersDropdown
        connectionId={connectionId}
        folders={ForesiteFolders}
        disabled={isLoadingProjects || foldersDisabled}
        onSelect={selectFolderHandler}
        isSource={isSource}
        isLoadingFolders={isLoadingFolders}
        nodeId={nodeId}
        onFolderStackLoad={setParentFolderIdOnStackLoad}
        fetchMoreRootFolders={fetchMoreRootFolders}
      />
      <FiltersAutocompleteDropdown
        allowPrivateFiles={allowPrivateFiles}
        showFilters={showFilters}
        fileTypeFilters={fileTypeFilters}
        isSource={isSource}
      />
    </Stack>
  );
};

export default Foresite;
