import React, { useRef, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { Typography } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { getSupportedExtensions as GET_SUPPORTED_EXTENSIONS } from 'graphql/queries';
import CustomModal from 'modules/Sync/components/CustomModal';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { CONNECTOR_NODE_TYPES } from 'modules/Sync/Constants/constants';
import {
  ManageIssuesFormProvider,
  useManageIssuesFormContext
} from 'modules/Sync/WorkflowEditor/Contexts/ManageIssuesContext';
import { validateSyncSettings } from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';

import ManageIssuesForm from './ManageIssuesForm';
import { useProceedToNextConnector, useSubmitMapping } from './manageIssuesHelper';
import ModalFooter from './ModalFooter';

const modalProps = {
  sx: { '& .MuiPaper-root': { overflowY: 'visible' } },
  contentStyles: { overflow: 'auto', width: 505, height: 709 }
};

const ModalTitle = ({ actionAlias }) => (
  <Typography fontWeight={600} variant="body4">
    Manage issues
    {actionAlias && (
      <Typography variant="body2">
        <CustomSyncTooltip title={`Action Alias: ${actionAlias}`} sx={{ maxWidth: 410 }}>
          {actionAlias}
        </CustomSyncTooltip>
      </Typography>
    )}
  </Typography>
);

const ManageIssuesAction = ({ open, onClose, onCustomConnect, actionId }) => {
  const {
    source,
    destinations,
    currentConnectorType,
    resetContext,
    activeStep,
    setActiveStep,
    syncSettings,
    action,
    defaultSource
  } = useManageIssuesFormContext();

  const [isSourceSelected, setIsSourceSelected] = useState(currentConnectorType !== CONNECTOR_NODE_TYPES.SOURCE);
  const { data: { getSupportedExtensions: Filters } = {}, loading: isLoadingFilters } = useQuery(
    gql(GET_SUPPORTED_EXTENSIONS),
    { fetchPolicy: FETCH_POLICIES.NO_CACHE.fetchPolicy }
  );
  const isLoading = isLoadingFilters;
  const submitDisabled = isLoading;
  const sourceSubmitDisabled = submitDisabled || !source?.isSubmitted;
  const destinationSubmitDisabled =
    submitDisabled || destinations.some(({ isSubmitted }) => !isSubmitted) || !destinations.length;
  const finalSubmitDisabled = submitDisabled || !validateSyncSettings(syncSettings);

  const prevSource = useRef(source?.nodeId || '');

  const { onNextAction } = useProceedToNextConnector();
  const { onSubmitAction } = useSubmitMapping();

  const onNext = () => {
    onNextAction(prevSource.current, () => {
      /* prevSource should be updated after the destinations are set to empty (if evaluated to true) */
      prevSource.current = source?.nodeId;
    });
    if (!isLoading) setIsSourceSelected(true);
  };

  const onBack = () => {
    if (activeStep === 1) {
      setIsSourceSelected(false);
    }
    setActiveStep(activeStep - 1);
  };

  const onModalClose = (_, skipPrevSourceReset = false) => {
    onClose?.();
    resetContext(() => {
      /* prevSource should only be updated in case of edit action i.e. changing source connector from A -> B 
          & if the form is submitted, skip resetting as activeMapping itself will be updated */
      if (defaultSource.nodeId && !skipPrevSourceReset) {
        prevSource.current = defaultSource.nodeId;
      }
      setIsSourceSelected(false);
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const sourceId = source.nodeId;
    const destIds = destinations.map(({ nodeId }) => nodeId);
    onCustomConnect(actionId, sourceId, destIds, syncSettings?.syncType, syncSettings?.isSyncEnabled);
    onSubmitAction();
    prevSource.current = sourceId;
    return onModalClose?.(event, true);
  };

  return (
    <CustomModal
      disableModalOutsideClick
      draggable
      open={open}
      title={<ModalTitle actionAlias={action?.data?.actionAlias} />}
      onClose={onModalClose}
      footer={
        <ModalFooter
          disableSubmit={finalSubmitDisabled}
          sourceSubmitDisabled={sourceSubmitDisabled}
          destinationSubmitDisabled={destinationSubmitDisabled}
          onClose={onModalClose}
          onBack={onBack}
          onSubmit={onSubmit}
          onNext={onNext}
        />
      }
      {...modalProps}
    >
      <ManageIssuesForm
        actionId={actionId}
        filters={Filters}
        isSourceSelected={isSourceSelected}
        sourceSubmitDisabled={sourceSubmitDisabled}
        destinationSubmitDisabled={destinationSubmitDisabled}
        finalSubmitDisabled={finalSubmitDisabled}
        onBack={onBack}
        onNext={onNext}
      />
    </CustomModal>
  );
};

const ManageIssuesModal = (props) => {
  const { actionId } = props;
  return (
    <ManageIssuesFormProvider actionId={actionId}>
      <ManageIssuesAction {...props} />
    </ManageIssuesFormProvider>
  );
};

export default ManageIssuesModal;
