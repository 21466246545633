import React, { useState, useEffect, useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncConnections as SYNC_CONNECTIONS, syncConnectorsList as SYNC_GET_CONNECTIONS_LIST } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DATAGRID_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

import useWindowsConnectorsQuery from './useWindowsConnectorsQuery';

const { fetchPolicy } = FETCH_POLICIES.CACHE_AND_NETWORK;
const SyncConnectionsContext = React.createContext();

const SyncConnectionsProvider = ({ children }) => {
  const [selectedConnectionIds, setSelectedConnectionIds] = useState([]);
  const [importedConnections, setImportedConnections] = useState([]);
  const [currentConnection, setCurrentConnection] = useState();
  const [headerActionsDisabled, setHeaderActionsDisabled] = useState(false);
  const [importConnectionOpen, setImportConnectionOpen] = useState(false);
  const [confirmImportOpen, setConfirmImportOpen] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [allWindowsConnections, setAllWindowsConnections] = useState([]);
  const { data: allConnectorsList } = useQuery(gql(SYNC_GET_CONNECTIONS_LIST), { fetchPolicy });

  useWindowsConnectorsQuery({ setAllWindowsConnections });

  const connectorsList = useMemo(
    () => ({
      syncConnectorsList: allConnectorsList?.syncConnectorsList?.filter(({ isLicensed }) => isLicensed)
    }),
    [allConnectorsList?.syncConnectorsList]
  );

  const [
    { lazyLoad, paginationHandler, searchHandler, onOrderby: sortHandler, refetchHandler: connectionsRefetchHandler },
    { loading, data: connectionsData }
  ] = useLazyPaginatedQuery(gql(SYNC_CONNECTIONS), fetchPolicy, DATAGRID_DEFAULT_PG_SIZE, false);

  const updateConnectionsAdd = (connectionId, connectionType, connectionName) => {
    setImportedConnections((state) =>
      state.map((item) => {
        if (connectionId === item?.DesktopConnectionId && connectionType === item.connectionType) {
          return { ...item, connectionName, isAdded: true };
        }
        return item;
      })
    );
  };

  const deleteConnection = (connectionId, connectionType) => {
    setImportedConnections((state) =>
      state.filter((item) => connectionId !== item?.DesktopConnectionId || connectionType !== item.connectionType)
    );
  };

  useEffect(() => {
    lazyLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedStateObj = React.useMemo(
    () => ({
      selectedConnectionIds,
      setSelectedConnectionIds,
      paginationHandler,
      searchHandler,
      loading,
      connectionsData,
      currentConnection,
      setCurrentConnection,
      connectorsList,
      headerActionsDisabled,
      setHeaderActionsDisabled,
      sortHandler,
      searchPhrase,
      setSearchPhrase,
      importedConnections,
      setImportedConnections,
      importConnectionOpen,
      setImportConnectionOpen,
      confirmImportOpen,
      setConfirmImportOpen,
      updateConnectionsAdd,
      deleteConnection,
      connectionsRefetchHandler,
      allWindowsConnections
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedConnectionIds,
      setSelectedConnectionIds,
      currentConnection,
      setCurrentConnection,
      connectorsList,
      loading,
      connectionsData,
      headerActionsDisabled,
      setHeaderActionsDisabled,
      searchPhrase,
      importedConnections,
      importConnectionOpen,
      confirmImportOpen,
      allWindowsConnections
    ]
  );

  return <SyncConnectionsContext.Provider value={selectedStateObj}>{children}</SyncConnectionsContext.Provider>;
};

const useSyncConnectionsContext = () => {
  const context = React.useContext(SyncConnectionsContext);
  if (context === undefined) {
    throw new Error('useSyncConnectionsContext must be used within a SyncConnectionsContext');
  }
  return context;
};

export { SyncConnectionsContext, SyncConnectionsProvider, useSyncConnectionsContext };

export const CONNECTIONS_APIS = ['syncConnections', 'syncConnectorsList'];
