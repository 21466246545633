import {
  CONNECTOR_CANVAS_NODE,
  ACTION_CANVAS_NODE,
  FLOATING_CANVAS_EDGE,
  CANVAS_TEMPLATE_CORDINATES
} from 'modules/Sync/Constants/constants';

export const getCanvasTemplate = (input) => {
  const {
    sourceName,
    sourceType,
    destinationName,
    destinationType,
    actionConnector,
    sourceConnector,
    destinationConnector,
    sourceTaskId,
    destinationTaskId
  } = input;

  const template = {
    elements: [
      {
        id: sourceConnector,
        sourcePosition: 'bottom',
        type: CONNECTOR_CANVAS_NODE,
        targetPosition: 'top',
        data: {
          taskId: sourceTaskId,
          connectionType: sourceType,
          disabled: false,
          actionId: sourceConnector,
          isAvailable: false,
          connectorAlias: sourceName
        },
        position: {
          ...CANVAS_TEMPLATE_CORDINATES.SOURCE
        }
      },
      {
        id: destinationConnector,
        sourcePosition: 'bottom',
        type: CONNECTOR_CANVAS_NODE,
        targetPosition: 'top',
        data: {
          taskId: destinationTaskId,
          connectionType: destinationType,
          disabled: false,
          actionId: destinationConnector,
          isAvailable: false,
          connectorAlias: destinationName
        },
        position: {
          ...CANVAS_TEMPLATE_CORDINATES.DESTINATION
        }
      },
      {
        id: actionConnector,
        sourcePosition: 'bottom',
        type: ACTION_CANVAS_NODE,
        targetPosition: 'top',
        data: {
          name: 'Copy Files',
          validations: {
            minConnectorNodesRequired: 2
          },
          actionId: actionConnector,
          isAvailable: true,
          actionAlias: 'Copy Files'
        },
        position: {
          ...CANVAS_TEMPLATE_CORDINATES.ACTION_NODE
        }
      },
      {
        id: `${actionConnector}->${destinationConnector}`,
        source: `${actionConnector}`,
        target: destinationConnector,
        arrowHeadType: 'arrow',
        type: FLOATING_CANVAS_EDGE,
        animated: false,
        sourceHandle: null,
        targetHandle: null
      },
      {
        id: `${sourceConnector}->${actionConnector}`,
        source: sourceConnector,
        target: actionConnector,
        arrowHeadType: 'arrow',
        type: FLOATING_CANVAS_EDGE,
        animated: false,
        sourceHandle: null,
        targetHandle: null
      }
    ],
    position: CANVAS_TEMPLATE_CORDINATES.CANVAS_POSITION,
    zoom: CANVAS_TEMPLATE_CORDINATES.CANVAS_ZOOM
  };

  return JSON.stringify(template);
};
