import { useEffect } from 'react';

import { Button, ButtonGroup } from '@mui/material';

import { Custom } from 'components/Icons';
import CustomTooltip from 'components/Tooltip';
import { LOG_FILTER } from 'modules/Sync/Constants/constants';
import { actionColors, otherColors } from 'styles/theme/colors';

const buttonGroupBorder = {
  borderColor: otherColors.greyOutlineColor,
  ':hover': {
    borderColor: `${otherColors.greyOutlineColor} !important`
  }
};
const activeStyles = {
  background: actionColors.lightSurface.selected,
  svg: { fill: 'black' },
  ...buttonGroupBorder
};

const { ALL, FAILED } = LOG_FILTER;
const ToggleLogsViewBtn = ({ value, onChange, disabled = false }) => {
  const viewErrorLogs = value === FAILED;
  const showAllLogs = () => onChange(ALL);
  const showFailedLogs = () => onChange(FAILED);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => showAllLogs(), []);
  return (
    <ButtonGroup
      variant="outlined"
      sx={{
        borderColor: otherColors.divider.lightSurface,
        width: 80,
        height: 40,
        '.MuiButtonBase-root': { padding: 0 }
      }}
      disabled={disabled}
    >
      <Button sx={!viewErrorLogs ? activeStyles : buttonGroupBorder} onClick={showAllLogs}>
        <CustomTooltip title="View entire log" arrow>
          <Custom.AllLogs fillOpacity={!viewErrorLogs ? 0.87 : 0.5} />
        </CustomTooltip>
      </Button>
      <Button sx={viewErrorLogs ? activeStyles : buttonGroupBorder} onClick={showFailedLogs}>
        <CustomTooltip title="View failed files log" arrow>
          <Custom.ErrorLogs fillOpacity={viewErrorLogs ? 0.87 : 0.5} />
        </CustomTooltip>
      </Button>
    </ButtonGroup>
  );
};

export default ToggleLogsViewBtn;
