import { CONNECTION_KEY_DESKTOP_MAPPING, CONNECTION_TYPES } from 'modules/Sync/Constants/constants';

const extractionKeys = [
  CONNECTION_KEY_DESKTOP_MAPPING.AUTODESKFIELD,
  CONNECTION_KEY_DESKTOP_MAPPING.SHAREPOINT,
  CONNECTION_KEY_DESKTOP_MAPPING.PROCORE,
  CONNECTION_KEY_DESKTOP_MAPPING.PLANGRID
];
export const extractConnectionConfig = (data) => {
  const response = extractionKeys.flatMap((item) => {
    const subItems = data[item].flatMap((connection) => {
      switch (item) {
        case CONNECTION_KEY_DESKTOP_MAPPING.PROCORE:
          return getProcoreData(connection);
        case CONNECTION_KEY_DESKTOP_MAPPING.SHAREPOINT:
          return getSharepointData(connection);
        case CONNECTION_KEY_DESKTOP_MAPPING.PLANGRID:
          return getPlangridData(connection);
        case CONNECTION_KEY_DESKTOP_MAPPING.NEWFORMA:
          return getNewformaData(connection);
        case CONNECTION_KEY_DESKTOP_MAPPING.AUTODESKFIELD:
          return getAutodeskData(connection);
        default:
          return false;
      }
    });
    return subItems;
  });

  return response;
};

const getProcoreData = (obj) => {
  const { connectionId, displayName, email, useCustomApplicationCredentials, clientId, clientSecret, callbackUrl } =
    obj;

  const resData = {
    id: connectionId,
    connectionId: '',
    DesktopConnectionId: connectionId,
    connectionName: displayName,
    connectionType: CONNECTION_TYPES.PROCORE,
    configuration: {
      email,
      useCustomApplicationCredentials,
      clientId,
      clientSecret,
      callbackUrl
    }
  };
  return resData;
};
const getPlangridData = (obj) => {
  const { connectionId, displayName, email, useCustomApplicationCredentials, clientId, clientSecret, callbackUrl } =
    obj;

  const resData = {
    id: connectionId,
    connectionId: '',
    DesktopConnectionId: connectionId,
    connectionName: displayName,
    connectionType: CONNECTION_TYPES.PLANGRID,
    configuration: {
      email,
      useCustomApplicationCredentials,
      clientId,
      clientSecret,
      callbackUrl
    }
  };
  return resData;
};
const getSharepointData = (obj) => {
  const { connectionId, displayName, userName, serverUrl, webListingQuery, tenantId, clientId, clientSecret } = obj;
  const resData = {
    id: connectionId,
    connectionId: '',
    DesktopConnectionId: connectionId,
    connectionName: displayName,
    connectionType: CONNECTION_TYPES.SHAREPOINT,
    configuration: {
      email: userName,
      serverUrl,
      webListingQuery,
      useCustomApplicationCredentials: true,
      tenantId,
      clientId,
      clientSecret,
      callbackUrl: ''
    }
  };
  return resData;
};
const getNewformaData = (obj) => {
  const { connectionId, displayName, email, clientId } = obj;
  const resData = {
    id: connectionId,
    connectionId: '',
    DesktopConnectionId: connectionId,
    connectionName: displayName,
    connectionType: CONNECTION_TYPES.NEWFORMA,
    configuration: {
      email,
      clientId,
      clientSecret: ''
    }
  };
  return resData;
};
const getAutodeskData = (obj) => {
  const {
    connectionId,
    userName,
    password,
    forgeServer,
    forgeHQClientId,
    shouldUploadFileInfoLog,
    glueServer,
    glueCompanyId,
    fieldServer,
    timeout
  } = obj;
  const autodeskRes = [];

  const baseObj = {
    dataSource: '',
    id: connectionId,
    connectionId: '',
    DesktopConnectionId: connectionId,
    configuration: {
      userName,
      password,
      forgeHQ: {
        forgeHQServer: forgeServer,
        forgeHQClientId,
        forgeHQClientSecret: ''
      },
      forgeTeamDocs: {
        forgeServer,
        shouldUploadFileInfoLog
      },
      glue: {
        glueServer,
        glueCompanyId
      },
      field: {
        fieldServer,
        timeout
      },
      isField: false,
      isGlue: false,
      isTeamDocs: false,
      isHQ: false
    }
  };

  if (!obj.disableField) {
    const fieldObj = {
      ...baseObj,
      connectionName: `${obj?.displayName} (Field)`,
      connectionType: CONNECTION_TYPES.AUTODESKFIELD,
      configuration: { ...baseObj.configuration, isField: true, password: '' }
    };
    autodeskRes.push(fieldObj);
  }
  if (!obj.disableGlue) {
    const fieldObj = {
      ...baseObj,
      connectionName: `${obj?.displayName} (Glue)`,
      connectionType: CONNECTION_TYPES.AUTODESKGLUE,
      configuration: { ...baseObj.configuration, isGlue: true, password: '' }
    };
    autodeskRes.push(fieldObj);
  }
  if (!obj.disableHQ) {
    const fieldObj = {
      ...baseObj,
      connectionName: `${obj?.displayName} (HQ)`,
      connectionType: CONNECTION_TYPES.AUTODESKHQ,
      configuration: { ...baseObj.configuration, isHQ: true }
    };
    autodeskRes.push(fieldObj);
  }
  if (!obj.disableTeamDocs) {
    const fieldObj = {
      ...baseObj,
      connectionName: `${obj?.displayName} (Teams/Docs)`,
      connectionType: CONNECTION_TYPES.AUTODESKTEAMDOCS,
      configuration: { ...baseObj.configuration, isTeamDocs: true }
    };
    autodeskRes.push(fieldObj);
  }
  return autodeskRes;
};
