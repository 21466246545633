import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';
import { Columns } from 'components/ItemsDatagridPro';
import Search from 'components/TableComponents/Search';
import { SHOW_LOGS_SEARCH_BAR } from 'modules/Sync/Constants/constants';
import RefreshLogsBtn from 'modules/Sync/Logs/LogsHeader/RefreshLogsBtn';
import { greyColors, surfaceColors, syncColors } from 'styles/theme/colors';

const LogsDataHeader = ({ searchHandler, onRefresh }) => (
  <Box sx={{ marginBottom: 2 }}>
    <Grid container justifyContent="space-between" wrap="nowrap">
      <Grid container item alignItems="center" wrap="nowrap">
        <Typography variant="h5" gutterBottom>
          Logs
        </Typography>
      </Grid>
      {SHOW_LOGS_SEARCH_BAR && (
        <Grid container item alignItems="center" wrap="nowrap" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Search
              handleSearch={searchHandler}
              placeholder=" Search logs..."
              sx={{
                marginLeft: 0,
                '& .MuiInputBase-root': { paddingLeft: 1.5, background: greyColors.grey300 },
                '& .MuiInputBase-input::placeholder': {
                  color: surfaceColors.lightSurface.secondary,
                  opacity: 1
                }
              }}
              startAdornment={
                <Custom.Search style={{ color: surfaceColors.lightSurface.secondary, marginRight: 10 }} />
              }
            />
          </Grid>
        </Grid>
      )}
    </Grid>
    <Grid container width="100%" justifyContent="flex-end" marginTop={1}>
      <Grid container item sx={{ '& .MuiButtonBase-root ': { marginRight: 1 } }} width="auto">
        <Columns
          disableColumnSearch
          typoProps={{ color: syncColors.text }}
          baseButtonSx={{
            '&:hover': { backgroundColor: syncColors.tableHover },
            '&:disabled': { color: 'action.lightSurface.disabled' },
            '.MuiTypography-root.MuiTypography-button': { color: syncColors.text },
            '.MuiSvgIcon-root': { mt: 0.5, alignItems: 'center', color: syncColors.text }
          }}
          popperStackSx={{
            boxShadow:
              '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)'
          }}
          buttonSx={{
            '& .MuiButton-root': {
              '&:hover': { backgroundColor: syncColors.tableHover }
            },
            '& .MuiTypography-button': {
              color: syncColors.text
            }
          }}
        />
      </Grid>
      <Grid item>
        <RefreshLogsBtn onRefresh={onRefresh} />
      </Grid>
    </Grid>
  </Box>
);

export default LogsDataHeader;
