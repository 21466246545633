import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';

import { getMicroFeContainer } from 'helpers/domHelpers';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION } from 'modules/Sync/Constants/constants';

const AutocompleteDropdown = ({
  value,
  onChange,
  options,
  label,
  loading = false,
  limitTags,
  renderInput: customRenderInput,
  renderTags: customRenderTags,
  renderOption: customRenderOption,
  isOptionEqualToValue = (option, value) => option.value === value.value,
  sx = {},
  optional = false,
  ...rest
}) => {
  const renderInput =
    customRenderInput ||
    ((params) => (
      <TextField
        {...params}
        label={`${label}${optional ? '' : '*'}`}
        color="secondary"
        value={value}
        fullWidth
        InputProps={{
          ...params.InputProps,
          startAdornment: loading ? (
            <CircularProgress size={10} color="warning" sx={{ ml: 1, display: 'flex' }} />
          ) : (
            params.InputProps.startAdornment
          )
        }}
      />
    ));

  const renderTags =
    customRenderTags ||
    ((value, getTagProps) => {
      value = value.filter(({ value }) => value !== ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION.value);
      const auxiliaryText = value?.length > limitTags ? `+ ${value.length - limitTags} others` : '';
      return (
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          {value.slice(0, limitTags).map(({ label }, index) => (
            <Chip label={label} {...getTagProps({ index })} size="small" />
          ))}
          <Typography title={auxiliaryText}>{auxiliaryText}</Typography>
        </Box>
      );
    });

  const renderOption =
    customRenderOption ||
    ((props, { value, label }, { selected }) => (
      <MenuItem key={value} value={value} {...props}>
        <Checkbox color="warning" checked={selected} size="small" sx={{ margin: 0, marginRight: 1, padding: 0 }} />
        <CustomSyncTooltip title={label}>{label}</CustomSyncTooltip>
      </MenuItem>
    ));

  return (
    <FormControl size="small" fullWidth color="secondary">
      <Autocomplete
        value={value}
        disableCloseOnSelect
        limitTags={limitTags}
        onChange={onChange}
        size="small"
        multiple
        id="tags-outlined"
        options={options}
        sx={{
          minHeight: '40px',
          '.MuiAutocomplete-listbox': {
            width: '200px',
            maxHeight: '400px',
            height: '100px',
            '& .MuiMenuItem-root': { whiteSpace: 'break-spaces' }
          },
          ...sx
        }}
        renderInput={renderInput}
        renderTags={renderTags}
        renderOption={renderOption}
        loading={loading}
        isOptionEqualToValue={isOptionEqualToValue}
        componentsProps={{ popper: { container: getMicroFeContainer } }}
        {...rest}
      />
    </FormControl>
  );
};

export default AutocompleteDropdown;
