import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { Custom, IconStyled } from 'components/Icons';
import { Columns } from 'components/ItemsDatagridPro';
import Search from 'components/TableComponents/Search';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import { ENABLE_DATA_USAGE_REPORTS } from 'modules/Sync/Constants/constants';
import { useSyncWebhooksContext } from 'modules/Sync/Webhooks/Contexts/WebhooksContext';
import WebhookActionsMenu from 'modules/Sync/Webhooks/WebhooksHeader/WebhookActionsMenu';
import { greyColors, surfaceColors, syncColors, actionColors } from 'styles/theme/colors';

const WebhooksHeader = ({ activeTab, setActiveTab }) => {
  const { webhooksSearchHandler, setSearchPhrase } = useSyncWebhooksContext();

  const searchHandler = (searchPhrase) => {
    setSearchPhrase(searchPhrase);
    webhooksSearchHandler(searchPhrase);
  };

  const onChangeHandler = (event, index) => {
    event.preventDefault();
    setActiveTab(index);
  };

  return (
    <HeaderWrapper>
      <HeaderTemplate onChangeHandler={onChangeHandler} searchHandler={searchHandler} activeTab={activeTab} />
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid container item alignItems="center" wrap="nowrap">
          <WebhookActionsMenu />
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          wrap="nowrap"
          justifyContent="flex-end"
          sx={{
            '& .MuiTypography-root, .MuiSvgIcon-root': { color: syncColors.text, lineHeight: 1.55 }
          }}
        >
          <Columns
            disableColumnSearch
            typoProps={{ color: syncColors.text }}
            baseButtonSx={{
              '&:hover': { backgroundColor: syncColors.tableHover },
              '&:disabled': { color: 'action.lightSurface.disabled' }
            }}
            popperStackSx={{
              boxShadow:
                '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)'
            }}
            buttonSx={{
              '& .MuiButton-root': {
                '&:hover': { backgroundColor: syncColors.tableHover }
              },
              '& .MuiTypography-button': {
                color: syncColors.text
              }
            }}
          />
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
};

export default WebhooksHeader;

const HeaderWrapper = (props) => <Box mb="10px" {...props} />;

const HeaderTemplate = ({ onChangeHandler, activeTab, searchHandler }) => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="h5" sx={{ color: 'black', lineHeight: '2.2' }}>
      <strong>Dashboard</strong>
    </Typography>
    <Tabs
      value={activeTab}
      onChange={onChangeHandler}
      variant="fullWidth"
      sx={{
        width: ENABLE_DATA_USAGE_REPORTS ? '100%' : '30%',
        justifyContent: 'left',
        paddingX: 0,
        marginLeft: 4,
        '& .MuiTabs-flexContainer': { width: '65%', height: '60px', alignItems: 'center' },
        '& .MuiTabs-indicator': { backgroundColor: syncColors.text },
        '& .Mui-selected': { color: `${syncColors.text} !important` }
      }}
    >
      <StyledTab
        label="Webhooks"
        icon={
          <IconStyled
            icon={
              <NavItemTooltip
                title="What is a webhoook? 360 Sync uses webhooks to automate file transfer in real time"
                placement="bottom"
              >
                <Custom.Webhook sx={{ width: 20, height: 20 }} />
              </NavItemTooltip>
            }
            sx={{ marginBottom: '0 !important', display: 'flex', alignItems: 'center' }}
            color={activeTab === 0 ? `${syncColors.text} !important` : actionColors.lightSurface.active}
          />
        }
      />
      {ENABLE_DATA_USAGE_REPORTS && (
        <StyledTab
          label="Data usage"
          icon={
            <IconStyled
              icon={<Custom.DataUsageSvg sx={{ width: 20, height: 20 }} />}
              sx={{ marginBottom: '0 !important', display: 'flex', alignItems: 'center' }}
              color={activeTab === 1 ? `${syncColors.text} !important` : actionColors.lightSurface.active}
            />
          }
        />
      )}
    </Tabs>
    <Search
      disabled
      placeholder="Search webhooks…"
      handleSearch={searchHandler}
      sx={{
        width: 500,
        '& .MuiInputBase-root': {
          paddingLeft: 1.5,
          background: greyColors.grey300
        },
        marginLeft: 0
      }}
      startAdornment={<Custom.Search style={{ fill: surfaceColors.lightSurface.secondary, marginRight: 10 }} />}
    />
  </Stack>
);

const StyledTab = (props) => (
  <Tab
    disableRipple
    sx={{
      justifyContent: 'space-evenly',
      display: 'flex',
      flexFlow: 'row',
      color: 'secondary',
      marginRight: 1,
      fontWeight: 500,
      fontSize: 15,
      marginBottom: '0 !important'
    }}
    {...props}
  />
);
