import { MANAGE_ISSUES_FILTERS, MANAGE_ISSUES_FILTERS_KEYS } from 'modules/Sync/Constants/canvasActionConstants';
import ForgeFilterAssignedToDropdown from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/ForgeFilterAssignedToDropdown';
import ForgeFilterCreatedAtDateRange from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/ForgeFilterCreatedAtDateRange';
import ForgeFilterDueDateRange from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/ForgeFilterDueDateRange';
import ForgeFilterIssueStatusDropdown from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/ForgeFilterIssueStatusDropdown';
import ForgeFilterIssueTypesDropdown from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/ForgeFilterIssueTypesDropdown';
import ForgeFilterLocationsDropdown from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/ForgeFilterLocationsDropdown';
import ForgeFilterRootCausesDropdown from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/ForgeFilterRootCausesDropdown';
import ForgeFilterUsersDropdown from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/ForgeFilterUsersDropdown';

const { CREATED_BY } = MANAGE_ISSUES_FILTERS;

const { status, ownerId, issueSubtypeId, rootCauseId, locationId, assignedTo, createdBy, dueDate, createdAt } =
  MANAGE_ISSUES_FILTERS_KEYS;

const FilterTypeComponentToRender = ({ property, isSource, nodeId, connectionId, disabled = false, selectAll = false }) => {
  const sharedProps = {
    filterKey: property,
    isSource,
    nodeId,
    connectionId,
    disabled,
    selectAll
  };

  switch (property) {
    case status:
      return <ForgeFilterIssueStatusDropdown {...sharedProps} />;
    case issueSubtypeId:
      return <ForgeFilterIssueTypesDropdown {...sharedProps} />;
    case rootCauseId:
      return <ForgeFilterRootCausesDropdown {...sharedProps} />;
    case locationId:
      return <ForgeFilterLocationsDropdown {...sharedProps} />;
    case assignedTo:
      return <ForgeFilterAssignedToDropdown {...sharedProps} />;
    case createdBy:
      return <ForgeFilterUsersDropdown label={CREATED_BY} {...sharedProps} />;
    case dueDate:
      return <ForgeFilterDueDateRange {...sharedProps} />;
    case ownerId:
      return <ForgeFilterUsersDropdown {...sharedProps} />;
    case createdAt:
      return <ForgeFilterCreatedAtDateRange {...sharedProps} />;
    default:
      return [];
  }
};
export default FilterTypeComponentToRender;
