import 'react-flow-renderer/dist/style.css';
import 'react-flow-renderer/dist/theme-default.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import ReactFlow, { Background, MiniMap, useStoreState, useZoomPanHelper } from 'react-flow-renderer';

import { getLayoutedElements } from './AutoLayout';
import FloatingConnectionLine from './FloatingConnectionLine';
import { FlowControls } from './FlowControls';
import workflowEditorBgImg from '../../assets/images/workflow-editor-bg.png';

const FLOW_CANVAS_PROPS = {
  elementsSelectable: true,
  connectionMode: 'loose',
  selectNodesOnDrag: false
};

const DEFAULT_BACKGROUND_STYLES = {
  backgroundImage: `url(${workflowEditorBgImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center'
};

const Loader = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      backgroundColor: 'white'
    }}
  >
    <CircularProgress color="warning" />
  </Box>
);

const FlowCanvas = ({
  elements,
  setElements,
  nodeTypes,
  edgeTypes,
  onElementsRemove,
  onConnect,
  onDrop,
  onDragOver,
  onLoad,
  onNodeMouseEnter,
  onNodeMouseLeave,
  map = false,
  mapProps = {},
  position = [0, 0],
  zoom = 0,
  loading = false,
  variant = 'dots',
  color = 'transparent',
  showControls = false,
  defaultLockState = false,
  onCanvasChange = () => {},
  onCanvasLockStateUpdate = () => {}
}) => {
  const { transform, zoomIn, zoomOut, fitView } = useZoomPanHelper();
  const [isCanvasUnlocked, setIsCanvasUnlocked] = useState(defaultLockState);
  const nodes = useStoreState((state) => state.nodes);
  const fitToView = useRef(false);
  const backgroundStyle = elements?.length && !loading ? {} : DEFAULT_BACKGROUND_STYLES;

  useEffect(() => {
    onCanvasLockStateUpdate(isCanvasUnlocked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCanvasUnlocked]);

  useEffect(() => {
    const [x = 0, y = 0] = position;
    if (x || y || zoom) transform({ x, y, zoom });
  }, [position, transform, zoom]);

  useEffect(() => {
    if (fitToView.current) {
      fitToView.current = false;
      fitView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  const onLayout = useCallback(
    (direction) => {
      /* eslint no-underscore-dangle: ["error", { "allow": ["__rf"] }]  */
      const layoutedElements = getLayoutedElements(elements, direction, nodes[0]?.__rf?.width, nodes[0]?.__rf?.height);
      setElements(layoutedElements);
      fitToView.current = true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elements]
  );

  const toggleIsCanvasLocked = () => setIsCanvasUnlocked(!isCanvasUnlocked);
  return (
    <div style={{ width: '100%', height: '90%' }}>
      <Background style={backgroundStyle} variant={variant} color={color} />
      <ReactFlow
        {...FLOW_CANVAS_PROPS}
        elements={elements}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onElementsRemove={onElementsRemove}
        onConnect={onConnect}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onLoad={onLoad}
        onNodeMouseEnter={onNodeMouseEnter}
        onNodeMouseLeave={onNodeMouseLeave}
        onNodeDragStop={onCanvasChange}
        nodesDraggable={isCanvasUnlocked}
        paneMoveable={isCanvasUnlocked}
        panOnScroll={isCanvasUnlocked}
        zoomOnPinch={isCanvasUnlocked}
        zoomOnScroll={isCanvasUnlocked}
        nodesConnectable={isCanvasUnlocked}
        connectionLineComponent={FloatingConnectionLine}
      >
        {map && <MiniMap nodeStrokeWidth={3} {...mapProps} />}
        {loading && <Loader />}
      </ReactFlow>
      {showControls && elements?.length > 0 && (
        <FlowControls
          onLayout={onLayout}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          fitView={fitView}
          isCanvasUnlocked={isCanvasUnlocked}
          onCanvasLockClick={toggleIsCanvasLocked}
        />
      )}
    </div>
  );
};

export default FlowCanvas;
