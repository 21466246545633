import { forwardRef } from 'react';

import { Tooltip } from '@mui/material';

import { getMicroFeContainer } from 'helpers/domHelpers';

const CustomTooltip = forwardRef((props, ref) => {
  const { children, PopperProps = {}, ...rest } = props;
  return (
    <Tooltip ref={ref} {...rest} PopperProps={{ ...PopperProps, container: getMicroFeContainer() }}>
      {children}
    </Tooltip>
  );
});

export default CustomTooltip;
