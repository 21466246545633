import React from 'react';

import DropdownFormControl from 'modules/Sync/WorkflowEditor/Utils/DropdownFormControl';

const ForesiteCompanyDropdown = ({ value, onChange, loading, items, ...props }) => (
  <DropdownFormControl
    title="Company"
    id="company"
    labelId="company"
    label="company"
    value={value}
    onChange={onChange}
    loading={loading}
    items={items}
    required
    {...props}
  />
);

export default ForesiteCompanyDropdown;
