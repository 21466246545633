import { useParams } from 'react-router-dom';

import useCreateTemplateApiAction from 'modules/Sync/WorkflowEditor/apiActions/createTemplate';

const useSaveTemplateAction = ({ workflowData }, antecedentMethod) => {
  const { id: mappingId } = useParams();
  const { createTemplate } = useCreateTemplateApiAction({
    refetchQueries: ['SyncGetWorkflowMappingTemplatesList', 'SyncGetAllWorkflowMappingTemplates']
  });

  const saveTemplateAction = async (name) => {
    const { mapping: { mappingName } = {} } = workflowData;

    await antecedentMethod();

    await createTemplate({ mappingId, name: name || `Template - ${mappingName}` });
  };
  return { saveTemplateAction };
};

export default useSaveTemplateAction;
