import { useCallback, useState } from 'react';

import { Button, MenuItem, Typography, useTheme } from '@mui/material';

import CustomMenu from 'components/Menu';
import CustomArrowDropDownIcon from 'modules/Sync/components/CustomArrowDropDownIcon';
import { SYNC_NOW, WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import {
  actionMenusApiPayload,
  disableActionMenus
} from 'modules/Sync/Workflows/WorkflowsDataGridTable/workflowsDataGridTableHelper';
import { syncColors } from 'styles/theme/colors';

const WorkflowActionsMenu = ({ workflows }) => {
  const {
    palette: { secondary }
  } = useTheme();
  const {
    selectedWorkflowIds,
    selectedMappingIds,
    selectedActionIds,
    setActiveModal,
    setSelectedActionIds,
    setSelectedMappingIds,
    setSelectedWorkflowIds
  } = useSyncWorkflowsContext();

  const filterSelectedEntities = () => {
    const { selectedWorkflowIdsToSend, selectedMappingIdsToSend, selectedActionIdsToSend } = actionMenusApiPayload({
      selectedWorkflowIds,
      selectedMappingIds,
      selectedActionIds,
      workflows
    });
    setSelectedActionIds(selectedActionIdsToSend);
    setSelectedMappingIds(selectedMappingIdsToSend);
    setSelectedWorkflowIds(selectedWorkflowIdsToSend);
  }
  
  const handleClick = (event) => {
    updateAnchorEl(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const updateAnchorEl = useCallback((element) => setAnchorEl(element), []);
  const showRunSyncModal = () => setActiveModal(SYNC_NOW);
  const showActivateModal = () => setActiveModal(WORKFLOW_MODAL_KEYS.ACTIVATE_WORKFLOW);
  const showDeactivateModal = () => setActiveModal(WORKFLOW_MODAL_KEYS.DEACTIVATE_WORKFLOW);
  const showArchiveModal = () => setActiveModal(WORKFLOW_MODAL_KEYS.ARCHIVE_WORKFLOW);
  const handleClose = () => updateAnchorEl(null);
  const handleRunSyncModalOpen = (event) => {
    event.stopPropagation();
    showRunSyncModal();
    handleClose();
  };
  const handleActivateModalOpen = (event) => {
    event.stopPropagation();
    showActivateModal();
    filterSelectedEntities();
    handleClose();
  };
  const handleDeactivateModalOpen = (event) => {
    event.stopPropagation();
    showDeactivateModal();
    filterSelectedEntities();
    handleClose();
  };
  const handleArchiveModalOpen = (event) => {
    event.stopPropagation();
    showArchiveModal();
    filterSelectedEntities();
    handleClose();
  };

  return (
    <ActionMenu
      anchorEl={anchorEl}
      secondary={secondary}
      handleClose={handleClose}
      handleRunSyncModalOpen={handleRunSyncModalOpen}
      handleActivateModalOpen={handleActivateModalOpen}
      handleDeactivateModalOpen={handleDeactivateModalOpen}
      handleArchiveModalOpen={handleArchiveModalOpen}
      handleClick={handleClick}
      workflows={workflows}
      selectedActionIds={selectedActionIds}
      selectedMappingIds={selectedMappingIds}
      selectedWorkflowIds={selectedWorkflowIds}
    />
  );
};
export default WorkflowActionsMenu;

const ActionMenu = ({
  anchorEl,
  secondary,
  handleClose,
  handleRunSyncModalOpen,
  handleActivateModalOpen,
  handleDeactivateModalOpen,
  handleArchiveModalOpen,
  handleClick,
  workflows,
  selectedWorkflowIds,
  selectedMappingIds,
  selectedActionIds
}) => {
  const open = !!anchorEl;
  const { isActivateActionDisabled, isDeactivateActionDisabled, isArchiveActionDisabled, isRunSyncDisabled } =
    disableActionMenus({ workflows, selectedWorkflowIds, selectedMappingIds, selectedActionIds });

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        endIcon={<CustomArrowDropDownIcon />}
        onClick={handleClick}
        color="warning"
        sx={{
          padding: '0 8px 0 8px',
          height: '32px',
          fontWeight: 500,
          border: `solid 1px ${syncColors.outlinedRestingBorder}`,
          color: syncColors.text,
          background: `linear-gradient(90deg, transparent 67%, ${secondary.outlinedHoverBackground} 33%)`
        }}
      >
        ACTIONS
      </Button>
      <CustomMenu
        id="Actions for items"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        color="warning"
        MenuListProps={{
          sx: { width: '160px' }
        }}
      >
        <MenuItem disabled={isRunSyncDisabled} onClick={handleRunSyncModalOpen}>
          <Typography>Run sync</Typography>
        </MenuItem>
        <MenuItem onClick={handleActivateModalOpen} disabled={isActivateActionDisabled}>
          <Typography>Activate</Typography>
        </MenuItem>
        <MenuItem onClick={handleDeactivateModalOpen} disabled={isDeactivateActionDisabled}>
          <Typography>Deactivate</Typography>
        </MenuItem>
        <MenuItem onClick={handleArchiveModalOpen} disabled={isArchiveActionDisabled}>
          <Typography color="error">Archive</Typography>
        </MenuItem>
      </CustomMenu>
    </>
  );
};
