import { gql } from '@apollo/client';

import getClient from 'apollo/client';
import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncRealTimeLogs as SYNC_REALTIME_LOGS } from 'graphql/queries';
import { DATAGRID_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { formatFilesToRows } from 'modules/Sync/Logs/RealTimeLogsDataGridTable/RealTimeLogsDataGridTableRows';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.NETWORK_ONLY;

export const updateRows = (apiRef, rows) => {
  if (!apiRef.current) return;
  apiRef.current.updateRows(rows);
};

export const getDownloadLogRow = (id, date) => {
  const downloadRowId = `placeholder-children-${id}`;
  const downloadLogsRow = [
    {
      id: downloadRowId,
      path: [id, downloadRowId],
      rowClass: 'loader',
      isDownloadLogs: true,
      isFile: true,
      date,
      onClick: () => null
    }
  ];
  return downloadLogsRow;
};

export const collapseAllRows = ({ apiRef }) => {
  const allRows = Array.from(apiRef.current?.getRowModels()).map((item) => item[1]);
  return allRows.forEach(({ id }, index) => {
    if (index) apiRef.current.setRowChildrenExpansion(id, false);
  });
};

export const handleRowExpansionChange =
  ({ apiRef, timeZone, logFilter, searchPhrase }) =>
  async (node) => {
    const row = apiRef.current?.getRow(node.id);
    const { id, date, isParent } = row;
    const queryParams = { logDate: date, skip: 0, take: DATAGRID_DEFAULT_PG_SIZE, timeZone, logFilter, searchPhrase };
    const childrenRows = [];

    if (!node.childrenExpanded || !row || row.childrenFetched || !isParent) return;

    const client = await getClient();

    const { data: realtimeData } = await client.query({
      query: gql(SYNC_REALTIME_LOGS),
      variables: { query: queryParams },
      fetchPolicy: FETCH_POLICY
    });

    const rows = formatFilesToRows(realtimeData?.syncRealTimeLogs, id);
    const downloadLogsRow = getDownloadLogRow(id, date);
    const fileCount = realtimeData?.syncRealTimeLogs?.length;
    const rowsToAdd = fileCount === DATAGRID_DEFAULT_PG_SIZE ? [...rows, ...downloadLogsRow] : rows;
    childrenRows.push(...rowsToAdd);

    const updatedRows = [...childrenRows, { ...row, childrenFetched: true, descendantCount: childrenRows.length }];
    updateRows(apiRef, updatedRows);
  };

export const expandInitialRow = async ({ apiRef, timeZone, logFilter, searchPhrase }) => {
  const allRows = Array.from(apiRef.current?.getRowModels()).map((item) => item[1]);
  const row = allRows.find(({ isParent }) => isParent);
  const { date, id } = row;

  if (row) {
    const queryParams = { logDate: date, skip: 0, take: DATAGRID_DEFAULT_PG_SIZE, timeZone, logFilter, searchPhrase };
    const childrenRows = [];

    const client = await getClient();

    const { data: realtimeData } = await client.query({
      query: gql(SYNC_REALTIME_LOGS),
      variables: { query: queryParams },
      fetchPolicy: FETCH_POLICY
    });

    const rows = formatFilesToRows(realtimeData?.syncRealTimeLogs, id);

    const fileCount = realtimeData?.syncRealTimeLogs?.length;
    const downloadLogsRow = getDownloadLogRow(id, date);
    const rowsToAdd = fileCount === DATAGRID_DEFAULT_PG_SIZE ? [...rows, ...downloadLogsRow] : rows;
    childrenRows.push(...rowsToAdd);

    const updatedRows = [...childrenRows, { ...row, childrenFetched: true, descendantCount: childrenRows.length }];
    updateRows(apiRef, updatedRows);
    apiRef.current.setRowChildrenExpansion(id, true);
  }
};
