import { Button, Typography } from '@mui/material';

import { syncColors } from 'styles/theme/colors';

const OutlinedButton = ({ label = '', labelId = '', onClick, disabled, sx = {}, icon = null, labelProps = {} }) => (
  <Button
    variant="outlined"
    onClick={onClick}
    disabled={disabled}
    color="secondary"
    sx={{
      width: 175,
      height: 32,
      color: 'secondary.main',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      transition: '0.2s',
      overflow: 'hidden',
      ':hover': { textDecorationColor: 'secondary.main' },
      ...sx
    }}
  >
    {icon}
    <Typography id={labelId} fontWeight={500} fontSize={14} color={syncColors.text} {...labelProps}>
      {label}
    </Typography>
  </Button>
);

export default OutlinedButton;
