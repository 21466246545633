import { gql, useApolloClient, useMutation } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncCreateWindowsFolder as SYNC_CREATE_WINDOWS_FOLDER } from 'graphql/mutations';
import { syncGetWindowsFolders as SYNC_GET_WINDOWS_FOLDERS } from 'graphql/queries';
import useCache from 'hooks/useCache';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { WINDOWS_DROPDOWN_ITEMS_IN_VIEW } from 'modules/Sync/Constants/constants';
import FoldersSelectDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown';
import { getLatestFolders } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';

const {
  NETWORK_ONLY: { fetchPolicy: NETWORK_ONLY_FETCH_POLICY },
  NO_CACHE: { fetchPolicy: NO_CACHE_FETCH_POLICY }
} = FETCH_POLICIES;

const FETCH_FOLDERS_API = 'syncGetWindowsFolders';

const WindowsFoldersDropdown = (props) => {
  const { connectionId, fetchMoreRootFolders, folders, expandedFolderIds } = props;
  const client = useApolloClient();
  const { handleResponse } = useGraphqlResponseHandler();
  const { deleteCache } = useCache();

  const [makeWindowsFolder] = useMutation(gql(SYNC_CREATE_WINDOWS_FOLDER));

  const fetchWindowsFolders = async ({
    parentFolderId,
    fetchPolicy = NO_CACHE_FETCH_POLICY,
    skip = 0,
    take = WINDOWS_DROPDOWN_ITEMS_IN_VIEW
  }) => {
    const { data } = await client.query({
      query: gql(SYNC_GET_WINDOWS_FOLDERS),
      variables: { query: { parentFolderId: encodeURIComponent(parentFolderId), connectionId, skip, take } },
      fetchPolicy
    });
    const response = data?.syncGetWindowsFolders || [];
    return response;
  };

  const fetchMoreFoldersHandler = async ({ isRoot = false, folderId, skip, onCompleted }) => {
    if (!isRoot) {
      const getWindowsFolders = await fetchWindowsFolders({ parentFolderId: folderId, skip });
      onCompleted(getWindowsFolders);
      return getWindowsFolders;
    }
    await fetchMoreRootFolders(skip, { connectionId });
    return onCompleted(getLatestFolders(folders));
  };

  const onFolderExpandHandler = async ({ folderId, onCompleted, skip, take }) => {
    const getWindowsFolders = await fetchWindowsFolders({ parentFolderId: folderId, skip, take });
    return onCompleted(getWindowsFolders, expandedFolderIds);
  };

  const onFolderSelectHandler = ({ onCompleted }) => onCompleted();

  const onCreateFolderHandler = async ({ folderId, folderName, onCompleted }) => {
    await handleResponse(
      makeWindowsFolder,
      {
        variables: {
          query: { connectionId },
          body: { ParentFolderId: encodeURIComponent(folderId), Name: folderName }
        }
      },
      { successMessage: 'Created folder successfully' },
      async () => {
        const getWindowsFoldersParams = {
          parentFolderId: folderId,
          skip: null,
          take: null,
          fetchPolicy: NETWORK_ONLY_FETCH_POLICY
        };
        deleteCache(FETCH_FOLDERS_API);
        const getWindowsFolders = await fetchWindowsFolders(getWindowsFoldersParams);
        onCompleted(getWindowsFolders);
      }
    );
    onCompleted();
  };

  return (
    <FoldersSelectDropdown
      {...props}
      vertical={-100}
      horizontal={675}
      onFolderExpandHandler={onFolderExpandHandler}
      onFolderSelectHandler={onFolderSelectHandler}
      onCreateFolderHandler={onCreateFolderHandler}
      fetchMoreFoldersHandler={fetchMoreFoldersHandler}
    />
  );
};

export default WindowsFoldersDropdown;
