const createPopup = ({ url, title, height, width }) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const externalPopup = window.open(
    url,
    title,
    `width=${width},height=${height},left=${left},top=${top}`
  );
  return externalPopup;
};

const configurePopup = (url) =>
  createPopup({
    url,
    title: '',
    height: 500,
    width: 500
  });

const keepListeningForCode = (externalWindow) =>
  new Promise((resolve) => {
    const intervalRef = window.setInterval(() => {
      if (externalWindow) {
        try {
          const currentUrl = externalWindow.location.href;
          if (currentUrl === 'about:blank') throw new Error('found a blank page');
          const params = new URL(currentUrl).searchParams;
          const code = params.get('code');
          if (!code) {
            resolve('');
          }
          window.clearInterval(intervalRef);
          externalWindow.close();
          resolve(code);
        } catch (error) {
          // eslint-ignore-line
        } finally {
          if (!externalWindow || externalWindow.closed) {
            window.clearInterval(intervalRef);
            resolve('');
          }
        }
      }
    }, 700);
  });

export const triggerAuthentication = async ({ url }) => {
  const externalWindow = configurePopup(url);
  const authCode = await keepListeningForCode(externalWindow);
  return authCode;
};

export const triggerLogoutFlow = async ({ url }) => {
  configurePopup(url);
};
