import React, { useEffect } from 'react';

import { ButtonGroup, Stack, Button } from '@mui/material';
import { useStoreActions, useStoreState } from 'react-flow-renderer';

import {
  ArchivedActive,
  ArchivedInactive,
  SwapHorizontalActive,
  SwapHorizontalInactive,
  WorkflowActive,
  WorkflowInactive
} from 'assets/images';
import CustomTooltip from 'components/Tooltip';
import { filterTypes } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { actionColors, otherColors } from 'styles/theme/colors';

import { getActionNodesWithEdges, getConnectorNodesWithEdges } from './helpers/CopyFilesHelpers/connectionsHelper';
import { getStyledWorkflowElements } from './helpers/getStyledWorkflowElementHelpers';

const STACK_PROPS = {
  spacing: 10,
  direction: 'row',
  justifyContent: 'space-between',
  style: { position: 'relative', zIndex: 9 }
};

const WorkflowEditorFilter = () => {
  const { workflowMappings, workflowElements, setIsSetOpacity, filterType, setFilterType } =
    useSyncWorkflowEditorContext();
  const { setElements } = useStoreActions((actions) => actions);

  const edges = useStoreState((store) => store.edges);
  const connectedConnectorNodes = getConnectorNodesWithEdges(edges);
  const connectedActionNodes = getActionNodesWithEdges(edges);

  const toggleFilter = (filter = filterTypes.ACTIVE) => {
    setIsSetOpacity(true);

    const styledWorkflowElements = getStyledWorkflowElements({
      workflowElements,
      connectedActionNodes,
      connectedConnectorNodes,
      filter,
      workflowMappings
    });

    return setElements(styledWorkflowElements);
  };

  useEffect(() => {
    if (filterType) toggleFilter(filterType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  const activeBg = { background: actionColors.lightSurface.selected };

  return (
    <Stack {...STACK_PROPS} mt={1}>
      <ButtonGroup variant="outlined" sx={{ borderColor: otherColors.divider.lightSurface }}>
        <Button onClick={() => setFilterType(filterTypes.ALL)} sx={filterType === filterTypes.ALL ? activeBg : {}}>
          <CustomTooltip title="Show all" arrow>
            {filterType === filterTypes.ALL ? <WorkflowActive /> : <WorkflowInactive />}
          </CustomTooltip>
        </Button>
        <Button
          onClick={() => setFilterType(filterTypes.ACTIVE)}
          sx={filterType === filterTypes.ACTIVE || !filterType ? activeBg : {}}
        >
          <CustomTooltip title="Active only" arrow>
            {filterType === filterTypes.ACTIVE || !filterType ? <SwapHorizontalActive /> : <SwapHorizontalInactive />}
          </CustomTooltip>
        </Button>
        <Button
          onClick={() => setFilterType(filterTypes.ARCHIVED)}
          sx={filterType === filterTypes.ARCHIVED ? activeBg : {}}
        >
          <CustomTooltip title="Archived only" arrow>
            {filterType === filterTypes.ARCHIVED ? <ArchivedActive /> : <ArchivedInactive />}
          </CustomTooltip>
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default WorkflowEditorFilter;
