import React from 'react';

import { useMutation, gql } from '@apollo/client';
import Typography from '@mui/material/Typography';

import { syncDeleteConnection as DELETE_CONNECTIONS } from 'graphql/mutations';
import { dispatchConnectionGridEvent } from 'helpers/domHelpers';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';

const ConnectionsDeleteModal = ({ connectionIds, open, onClose }) => {
  const { handleResponse } = useGraphqlResponseHandler();
  const { connectionsData } = useSyncConnectionsContext();

  const connectionNotDeletable = connectionsData?.syncConnections?.some(
    ({ id, isConnectionUsed }) => connectionIds.includes(id) && isConnectionUsed
  );

  const [deleteConnections, { loading }] = useMutation(gql(DELETE_CONNECTIONS));

  const onSuccessHandler = () => {
    dispatchConnectionGridEvent()
    onClose()
  }

  const submit = async () => {
    await handleResponse(
      deleteConnections,
      {
        variables: { body: { ConnectionIds: connectionIds } }
      },
      { successMessage: 'Connector/s successfully deleted' }
    );
    onSuccessHandler();
  };

  return (
    <CustomModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={open}
      onClose={onClose}
      title={connectionNotDeletable ? 'Unable to delete' : 'Confirm delete'}
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      footer={
        <ModalFooter
          loading={loading}
          onModalClose={onClose}
          cancelBtnText="NO"
          createBtnText="CONFIRM"
          onSubmit={submit}
          onSubmitDisabled={loading || connectionNotDeletable}
          isDelete
        />
      }
    >
      <Typography
        variant="body1"
        mb="12px"
        sx={{
          width: '500px',
          mb: '20px'
        }}
      >
        {connectionNotDeletable
          ? "One or more selected connectors are in use in one or more mappings. In use connectors can't be deleted."
          : 'Are you sure you want to delete the selected connectors?'}
      </Typography>
    </CustomModal>
  );
};

export default ConnectionsDeleteModal;
