import { Button, Checkbox, CircularProgress, MenuItem } from '@mui/material';

import { Custom } from 'components/Icons';
import CustomTooltip from 'components/Tooltip';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import { useFolderDropdownContext } from 'modules/Sync/WorkflowEditor/Contexts/FolderDropdownContext';
import { actionColors } from 'styles/theme/colors';

const FolderRowMenuLabel = ({
  nodeId,
  folderId,
  parentFolderId,
  text,
  textFormatter,
  onFolderSelect,
  onFolderCreate,
  disabled,
  checkIsFolderUnselectable = () => {},
  multiple = false,
  isChecked = false,
  isSource = false
}) => {
  const { isLoading, isLoadingFolderName } = useFolderDropdownContext();
  const { getConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { folders: selectedFolders = [], folder } = formValues;
  const isSelectedFolder = selectedFolders?.[0]?.id === folderId || folder?.id === folderId;
  const formattedText = textFormatter(text);
  const highlightBg = isSource ? isChecked || isSelectedFolder : folder?.id === folderId;
  const isFolderSelectable = !checkIsFolderUnselectable?.(folderId);

  return (
    <Button
      variant="text"
      className="folder-row"
      disabled={disabled}
      fullWidth
      sx={{
        justifyContent: 'space-between',
        backgroundColor: highlightBg && actionColors.lightSurface.focus,
        height: 35,
        textTransform: 'none'
      }}
    >
      <MenuItem
        value={text}
        data-folder-id={folderId}
        data-text={formattedText}
        onClick={onFolderSelect}
        disabled={disabled || !isFolderSelectable}
        disableRipple
        sx={{ px: 0, '&:hover': { backgroundColor: 'transparent' } }}
      >
        {multiple && isFolderSelectable && (
          <Checkbox
            checked={isChecked}
            color="warning"
            size="small"
            sx={{ padding: 0, marginRight: 1 }}
            onChange={onFolderSelect}
            inputProps={{ 'data-text': formattedText, 'data-parent-id': parentFolderId }}
          />
        )}
        <CustomTooltip title={text} placement="right" arrow disableHoverListener={formattedText.length < 33}>
          <div
            style={{
              maxWidth: '33ch',
              whiteSpace: 'nowrap',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            data-text={formattedText}
          >
            {formattedText}
          </div>
        </CustomTooltip>

        {(isLoading || isLoadingFolderName) && (
          <CircularProgress size={12} color="warning" sx={{ ml: 1, display: 'flex' }} />
        )}
      </MenuItem>
      {onFolderCreate && !isLoadingFolderName && (
        <Custom.AddBoxIcon
          id={`create-folder-icon-${folderId}`}
          data-parent-folder-id={folderId}
          className="create-folder-icon"
          onClick={onFolderCreate}
          size="small"
          sx={{ opacity: 0, position: 'absolute', right: 15, top: 9 }}
        />
      )}
    </Button>
  );
};

export default FolderRowMenuLabel;
