import { useEffect } from 'react';

import { convertToCamelCase } from 'helpers/stringFunctions';
import useCache from 'hooks/useCache';

const useEvictApolloCache = (QUERIES, dependencies = [QUERIES], args = null) => {
  const { deleteCache } = useCache();

  useEffect(() => {
    QUERIES.forEach((query) => deleteCache(convertToCamelCase(query), args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
};

export default useEvictApolloCache;
