import { getMicroFeContainer, getShadowDocument, renderingInMicroFE } from 'helpers/domHelpers';
import { DRAGGED_NODE_ELEMENT_ID, EMPTY_DRAG_ELEMENT } from 'modules/Sync/Constants/constants';

const handleCreateCustomDraggedElement = (event) => {
  const elementCopy = event.target.cloneNode(true);
  elementCopy.id = DRAGGED_NODE_ELEMENT_ID;
  elementCopy.style.pointerEvents = 'none';
  elementCopy.style.position = 'absolute';
  elementCopy.style.zIndex = 9;
  elementCopy.style.width = `${event.target.offsetWidth}px`;

  const dragIcon = document.createElement('div');
  const appRoot = getMicroFeContainer();
  dragIcon.id = EMPTY_DRAG_ELEMENT
  dragIcon.style.pointerEvents = 'none';
  dragIcon.style.position = 'absolute';
  dragIcon.style.zIndex = 9;
  dragIcon.style.width = '10px';
  dragIcon.style.height = '10px';
  dragIcon.style.opacity = 0;
  appRoot.append(dragIcon); /*  this is an empty element to replace the original one with transparency */
  appRoot.append(elementCopy);
  event.dataTransfer.setDragImage(dragIcon, 10, 10);
};

export const onDragStart = (event, _, data, nodeType) => {
  event.dataTransfer.setData('workflowEditor/nodeType', nodeType);
  event.dataTransfer.setData('workflowEditor/nodeData', JSON.stringify(data));
  event.dataTransfer.effectAllowed = 'move';
  handleCreateCustomDraggedElement(event);
};

export const onDrag = (event) => {
  const shadowDocument = getShadowDocument();
  const elementCopy = shadowDocument.getElementById(DRAGGED_NODE_ELEMENT_ID);
  const adjustOffset = renderingInMicroFE ? 0 : 70;
  elementCopy.style.top = `${event.clientY - adjustOffset}px`;
  elementCopy.style.left = `${event.clientX - adjustOffset}px`;
};

export const onDragEnd = () => {
  const shadowDocument = getShadowDocument();
  shadowDocument.getElementById(EMPTY_DRAG_ELEMENT).remove();
  return shadowDocument.getElementById(DRAGGED_NODE_ELEMENT_ID).remove();
};
