import { gql, useLazyQuery } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncLogsDataUsageReport as SYNC_LOGS_DATA_USAGE_REPORT } from 'graphql/queries';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const useLazyLoadLogsDataUsageReportsHook = () => {
  const [loadLogsDataUsageReport, { data: logsDataUsageReport, loading: loadingLogsDataUsageReport }] = useLazyQuery(
    gql(SYNC_LOGS_DATA_USAGE_REPORT),
    {
      errorPolicy: 'all',
      fetchPolicy: FETCH_POLICY
    }
  );

  return {
    loadLogsDataUsageReport,
    logsDataUsageReport,
    loadingLogsDataUsageReport
  };
};

export default useLazyLoadLogsDataUsageReportsHook;
