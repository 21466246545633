import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';
import { MAPPING_ACTIONS } from 'modules/Sync/Constants/constants';
import useSaveFlowConfigApiAction from 'modules/Sync/WorkflowEditor/apiActions/manageIssuesSaveFlowConfig';
import { useManageIssuesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/ManageIssuesContext';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import useSaveMappingAction from 'modules/Sync/WorkflowEditor/editorHooks/useSaveMappingAction';
import { manageIssuesActionPayload } from 'modules/Sync/WorkflowEditor/helpers/ManageIssuesHelpers/manageIssuesPayloadHelper';

const AUTO_HIDE_DURATION = 2500;
const ERROR_MSG = 'Please specify all the required configurations';
const SUCCESS_MSG = 'Saving...';
const ON_SUBMIT_SNACKBAR_KEY = uuidv4();

const { manageIssues: MANAGE_ISSUES_ACTION } = MAPPING_ACTIONS;

export const useProceedToNextConnector = () => {
  const { source, setDestinations, activeStep, setActiveStep, setConnectorNodes } = useManageIssuesFormContext();

  const onNextAction = (prevSource, callBack = () => {}) => {
    if (activeStep === 0) {
      setConnectorNodes((nodes) => [
        ...nodes.map((node) => {
          const newNode = node;
          newNode.data.isAvailable = newNode.id !== source.nodeId;
          return newNode;
        })
      ]);

      if (source?.nodeId !== prevSource) {
        setDestinations([{}]);
      }
    }
    callBack?.();
    setActiveStep(activeStep + 1);
  };

  return { onNextAction };
};

export const useSubmitMapping = () => {
  const { action, activeFlow, source, destinations, syncSettings, includedExtensions, setConnectorNodes } =
    useManageIssuesFormContext();
  const { currentMapping, actionId, mappingId, workflowMappings, setWorkflowMappings, setIsMappingDataSaved } =
    useSyncWorkflowEditorContext();
  const actionAlias = action?.data?.actionAlias || '';
  const { enqueueSnackbar, closeSnackbar } = useSnackbar() || {};
  const [isSaved, setIsSaved] = useState(false);
  const { solitaryFlowId = '' } = activeFlow || {};
  const { saveFlowConfig } = useSaveFlowConfigApiAction();
  const { saveMappingAction } = useSaveMappingAction({
    refetchQueries: ['SyncConnections', 'SyncGetWorkflowMappingConfiguration']
  });

  const saveFlowData = (mappingFlows) => {
    saveFlowConfig({
      mappingId,
      currentMapping,
      mappingFlows,
      onCompleted: () => {
        saveMappingAction({
          successMessage: 'Workflow successfully saved',
          onCompleted: () => {
            closeSnackbar(ON_SUBMIT_SNACKBAR_KEY);
            setIsMappingDataSaved(true);
          },
          onFailed: () => {
            closeSnackbar(ON_SUBMIT_SNACKBAR_KEY);
            setIsMappingDataSaved(false);
          }
        }); /* Trigger canvas save */
      },
      onFailed: () => closeSnackbar(ON_SUBMIT_SNACKBAR_KEY),
      successMessage: ''
    });
  };

  const onSubmitAction = async () => {
    if (!source.isSubmitted || destinations.some(({ isSubmitted }) => !isSubmitted)) {
      return enqueueSnackbar(ERROR_MSG, { autoHideDuration: AUTO_HIDE_DURATION, ...VARIANT_ERROR });
    }

    await setConnectorNodes((nodes) => [
      ...nodes.map((node) => {
        const newNode = node;
        if (destinations.some(({ nodeId }) => nodeId === newNode.id)) newNode.data.isAvailable = false;
        return newNode;
      })
    ]);

    await setWorkflowMappings((currentMappings) => [
      ...currentMappings.filter((mapping) => mapping.actionNodeId !== actionId),
      manageIssuesActionPayload({
        actionNodeId: actionId,
        actionAlias: currentMappings.find((mapping) => mapping.actionNodeId === actionId)?.actionAlias,
        solitaryFlowId,
        isSyncEnabled: syncSettings?.isSyncEnabled,
        syncType: syncSettings?.syncType,
        scheduleSyncConfig: syncSettings?.scheduleSyncConfig,
        includeExtensions: includedExtensions,
        secondaryFilesSetting: null,
        isSyncAllRevisions: true,
        source,
        destinations,
        action: MANAGE_ISSUES_ACTION
      })
    ]);

    setIsSaved(true);

    return enqueueSnackbar(SUCCESS_MSG, {
      key: ON_SUBMIT_SNACKBAR_KEY,
      persist: true,
      ...VARIANT_SUCCESS
    });
  };

  useEffect(() => {
    const currentFlow = workflowMappings?.filter(({ actionNodeId }) => actionNodeId === actionId);

    if (currentFlow.length && isSaved) {
      currentFlow[0].actionAlias = actionAlias;
      saveFlowData(currentFlow);
      setIsSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowMappings, isSaved]);

  return { onSubmitAction };
};
