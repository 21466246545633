import React from 'react';

import { Grid } from '@mui/material';

import { ControlledInputCheckbox } from 'components';
import { SYNC_DAYS } from 'modules/Sync/Constants/constants';

const SyncDaysOptions = ({ updateDaysHandler, control, disabled }) => (
  <Grid container columns={3} columnSpacing={1}>
    {SYNC_DAYS.map((day, idx) => (
      <Grid item key={day} xs={1} sm={1} md={1}>
        <ControlledInputCheckbox
          customCallBack={(event, checked) => updateDaysHandler({ event, checked, name: day })}
          checkboxProps={{ defaultChecked: !idx, inputProps: { 'data-id': day } }}
          name={day}
          control={control}
          label={day}
          disabled={disabled}
          color="secondary"
        />
      </Grid>
    ))}
  </Grid>
);

export default SyncDaysOptions;
