import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';

const useUpdateNodeAction = () => {
  const { workflowElements, setWorkflowElements } = useSyncWorkflowEditorContext();
  const updateNodeAction = async (nodeId, data, callback = () => {}) => {
    const newWorkflowElements = workflowElements.reduce((elements, currentElement) => {
      if (currentElement.id === nodeId) {
        elements.push({
          ...currentElement,
          data: {
            ...currentElement.data,
            ...data
          }
        });
      } else {
        elements.push(currentElement);
      }
      return elements;
    }, []);
    await setWorkflowElements(newWorkflowElements);
    callback?.();
  };

  return { updateNodeAction };
};

export default useUpdateNodeAction;
