import React, { useState } from 'react';

const FolderDropdownContext = React.createContext();

const FolderDropdownProvider = ({ children, expandableFolders = true }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(expandableFolders);
  const [isLoadingFolderName, setIsLoadingFolderName] = useState(false);
  const [createFolderInputVisibility, setCreateFolderInputVisibility] = useState(false);
  const [folders, setFolders] = useState([]);
  const [autoFocus, setAutoFocus] = useState(false);
  const [hasMoreFolders, setHasMoreFolders] = useState(true);
  const memoizedContextState = React.useMemo(
    () => ({
      isLoading,
      setIsLoading,
      isExpanded,
      setIsExpanded,
      isExpandable,
      setIsExpandable,
      isLoadingFolderName,
      setIsLoadingFolderName,
      folders,
      setFolders,
      autoFocus,
      setAutoFocus,
      createFolderInputVisibility,
      setCreateFolderInputVisibility,
      hasMoreFolders,
      setHasMoreFolders
    }),
    [
      isLoading,
      setIsLoading,
      isExpanded,
      setIsExpanded,
      isExpandable,
      setIsExpandable,
      isLoadingFolderName,
      setIsLoadingFolderName,
      folders,
      setFolders,
      autoFocus,
      setAutoFocus,
      createFolderInputVisibility,
      setCreateFolderInputVisibility,
      hasMoreFolders
    ]
  );

  return <FolderDropdownContext.Provider value={memoizedContextState}>{children}</FolderDropdownContext.Provider>;
};

const useFolderDropdownContext = () => {
  const context = React.useContext(FolderDropdownContext);
  if (context === undefined) {
    throw new Error('useFolderDropdownContext must be used within a FolderDropdownProvider');
  }
  return context;
};

export { FolderDropdownContext, FolderDropdownProvider, useFolderDropdownContext };
