const ConnectionsDataGridTableColumns = (handleCellComponent) => [
  {
    field: 'connectionName',
    headerName: 'Name',
    flex: 2,
    maxWidth: 600,
    minWidth: 200,
    editable: false,
    hideable: false,
    resizable: true,
    colSpan: (params) => (params.id === 0 ? 3 : 1),
    renderCell: handleCellComponent
  },
  {
    field: 'connectionType',
    headerName: 'Type',
    flex: 1,
    maxWidth: 400,
    minWidth: 150,
    resizable: true,
    renderCell: handleCellComponent
  },
  {
    field: 'lastModified',
    headerName: 'Date Last Modified',
    flex: 1,
    maxWidth: 400,
    minWidth: 150,
    sortable: true,
    resizable: true,
    renderCell: handleCellComponent
  }
];

export default ConnectionsDataGridTableColumns;
