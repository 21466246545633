import { useEffect } from 'react';

import { gridPaginatedVisibleSortedGridRowEntriesSelector, useGridApiRef } from '@mui/x-data-grid-pro';

import ItemsDataGridPro from 'components/ItemsDatagridPro';
import {
  DATAGRID_DEFAULT_PG_SIZE,
  MAX_DATAGRID_ROWS_IN_VIEW,
  TEMPLATE_GRID_REFRESH_EVENT,
  WORKFLOW_MODAL_KEYS
} from 'modules/Sync/Constants/constants';
import useTemplatesLazyPaginationHook from 'modules/Sync/Workflows/Contexts/useTemplatesLazyPaginationHook';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import NoRowsOverlay from 'modules/Sync/Workflows/TemplatesDataGridTable/NoRowsOverlay';

import TemplatesDataGridCell from './TemplatesDataGridCell';
import { dataGridStyles, defaultDataGridConfig, getColumns, templatesMapper } from './templatesDataGridTableHelper';

const { CREATE_TEMPLATE, DELETE_TEMPLATE, DUPLICATE_TEMPLATE, RENAME_TEMPLATE } = WORKFLOW_MODAL_KEYS;

const TemplatesDataGridTable = ({ activeRows }) => {
  const { setSelectedTemplateIds, setCurrentMapping, setActiveModal, activeTab } = useSyncWorkflowsContext();
  const isTemplateDataGrid = activeTab === 1;
  
  const { templatesPaginationHandler, templatesSortHandler, loadingTemplates, templates, templateRefetchHandler } =
    useTemplatesLazyPaginationHook(isTemplateDataGrid);
  const apiRef = useGridApiRef();

  const showCreateTemplateModal = () => setActiveModal(CREATE_TEMPLATE);
  const showDeleteTemplateModal = () => setActiveModal(DELETE_TEMPLATE);
  const showDuplicateTemplateModal = () => setActiveModal(DUPLICATE_TEMPLATE);
  const showRenameTemplateModal = () => setActiveModal(RENAME_TEMPLATE);

  const handleOnDelete = ({ id }) => {
    setSelectedTemplateIds([id]);
    showDeleteTemplateModal();
  };

  const handleOnDuplicate = ({ id, name, ...rest }) => {
    setCurrentMapping({ workflowMappingId: id, workflowMappingName: name, ...rest });
    return showDuplicateTemplateModal();
  };

  const handleOnRename = ({ id, name, ...rest }) => {
    setCurrentMapping({ workflowMappingId: id, workflowMappingName: name, ...rest });
    return showRenameTemplateModal();
  };

  const scrollToTop = () => apiRef?.current?.scrollToIndexes({ rowIndex: 0 });

  const onScroll = () => templatesPaginationHandler(templates?.syncGetWorkflowMappingTemplatesList?.length);

  const menuItems = (row) => [
    { label: 'Duplicate', clickEvent: () => handleOnDuplicate(row) },
    { label: 'Rename', clickEvent: () => handleOnRename(row) },
    { label: 'Delete', clickEvent: () => handleOnDelete(row), color: '#F44336' }
  ];

  const handleCellComponent = ({ id, field, rowNode, row }) => (
    <TemplatesDataGridCell
      id={id}
      field={field}
      rowNode={rowNode}
      row={row}
      options={menuItems(row)}
      onWaypointEnter={onScroll}
    />
  );

  const columns = getColumns({
    renderCell: handleCellComponent
  });

  const rows = templatesMapper(templates?.syncGetWorkflowMappingTemplatesList);
  const handleSort = (sortModel) =>
    sortModel.length ? templatesSortHandler({ columnName: sortModel?.[0]?.field }) : null;
  useEffect(() => {
    if (!loadingTemplates && apiRef?.current?.windowRef?.current && activeRows.length && rows.length) {
      const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);

      const rowIndex = allRows?.findIndex(({ id }) => id === activeRows?.[0]);

      const indexToScrollAt =
        rowIndex + MAX_DATAGRID_ROWS_IN_VIEW >= allRows.length
          ? allRows.length - 1
          : rowIndex + MAX_DATAGRID_ROWS_IN_VIEW;

      apiRef?.current?.scrollToIndexes({ rowIndex: indexToScrollAt });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, activeRows.length, rows.length, loadingTemplates]);

  useEffect(() => {
    document.addEventListener(TEMPLATE_GRID_REFRESH_EVENT, async () => {
      await templateRefetchHandler({ skip: 0, take: DATAGRID_DEFAULT_PG_SIZE });
      scrollToTop();
    });

    return () => {
      document.removeEventListener(TEMPLATE_GRID_REFRESH_EVENT, templateRefetchHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ItemsDataGridPro
      {...defaultDataGridConfig}
      apiRef={apiRef}
      loading={loadingTemplates}
      rows={rows}
      getRowId={(row) => row.id}
      columns={columns}
      asyncApi={{ onScroll }}
      components={{
        NoRowsOverlay: () => <NoRowsOverlay text="Start by creating a new template." />
      }}
      onRowClick={(data) => {
        if (data?.row) setCurrentMapping({ ...data?.row, workflowMappingName: data?.row?.name });
        if (!data?.row?.id) showCreateTemplateModal();
      }}
      onSelectionModelChange={(_, { api }) => {
        const selectedRows = api?.getSelectedRows();
        if (selectedRows?.size) {
          const selectedTemplateIds = [];
          selectedRows.forEach((_, key) => selectedTemplateIds.push(key));
          setSelectedTemplateIds(selectedTemplateIds);
        }
      }}
      onSortModelChange={handleSort}
      sortingOrder={['asc', 'desc']}
      sx={dataGridStyles}
    />
  );
};

export default TemplatesDataGridTable;
