import { BreadcrumbsItemType, CollapseModeType } from './BreadcrumbTypes';

export function formatBreadcrumbs({
  breadcrumbs,
  maxItems,
  itemsAfterCollapse,
  itemsBeforeCollapse,
  collapseMode
}: {
  breadcrumbs: Array<BreadcrumbsItemType>;
  maxItems?: number;
  itemsAfterCollapse?: number;
  itemsBeforeCollapse?: number;
  collapseMode: CollapseModeType;
}) {
  if (!breadcrumbs || !breadcrumbs.length) return [];
  if (!maxItems || maxItems >= breadcrumbs.length || collapseMode !== 'menu') return breadcrumbs;
  if (!itemsAfterCollapse) itemsAfterCollapse = 1;
  if (!itemsBeforeCollapse) itemsBeforeCollapse = 1;

  const shouldCollapse = breadcrumbs.length > maxItems;

  if (!shouldCollapse) return breadcrumbs;

  const collapsedItems = breadcrumbs.slice(
    itemsBeforeCollapse,
    breadcrumbs.length - itemsAfterCollapse
  );

  const result = [
    ...breadcrumbs.slice(0, itemsBeforeCollapse),
    ...(collapsedItems.length > 0
      ? [
          {
            label: 'collapse',
            type: 'collapse',
            value: collapsedItems
          }
        ]
      : []),
    ...breadcrumbs.slice(breadcrumbs.length - itemsAfterCollapse)
  ];

  return result;
}
