import React from 'react';

import { Box } from '@mui/material';

import { useImportWorkflowsContext } from 'modules/Sync/Connections/components/ImportWorkflowsContext';
import ImportSyncOptions from 'modules/Sync/Connections/Modals/ImportConnectionModal/ImportSyncOptions';
import { parseResponseScheduleSyncJson } from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';
import { greyColors } from 'styles/theme/colors';

const ImportSyncAllWorkflows = () => {
  const { updateAllSyncSettings, syncAllData } = useImportWorkflowsContext();
  const parsedConfig = parseResponseScheduleSyncJson(syncAllData.scheduleSyncConfig);
  const syncSettings = { ...syncAllData, scheduleSyncConfig: parsedConfig };

  return (
    <Box bgcolor={greyColors.grey50} p={2} borderRadius={4}>
      <ImportSyncOptions
        onSyncSettingsChange={() => null}
        setMappingFlowsSyncSettings={() => null}
        mappingFlowsSyncSettings={[]}
        importMethods={{ updateSettings: updateAllSyncSettings }}
        syncSettings={syncSettings}
        isAllSyncTypeAvailable
      />
    </Box>
  );
};

export default ImportSyncAllWorkflows;
