import React from 'react';

import TableCell from '@mui/material/TableCell';

const StatusCell = ({ status }) => (
  <TableCell
    align="left"
    sx={{
      color: `status.${status?.toLowerCase()}`
    }}
  >
    {status}
  </TableCell>
);

export default StatusCell;
