import React from 'react';

import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { MUITheme } from 'constants/globalTypes';

type CenteredLoadSpinnerProps = {
  darkMode?: boolean;
  sx?: SxProps;
  width?: string | number;
  theme?: MUITheme;
};

const CenteredLoadSpinner = ({
  sx,
  darkMode = false,
  width = '100%',
  theme = 'primary'
}: CenteredLoadSpinnerProps) => (
  <SpinnerWrapper sx={sx} width={width}>
    <CircularProgress color={theme} sx={darkMode ? { color: 'white' } : {}} />
  </SpinnerWrapper>
);

export default CenteredLoadSpinner;

type SpinnerWrapperProps = {
  sx?: SxProps;
  width: any;
  children: any;
};

const SpinnerWrapper = ({ sx, width, children }: SpinnerWrapperProps) => (
  <Box mt="20px" width={width} display="flex" justifyContent="center" sx={sx}>
    {children}
  </Box>
);
