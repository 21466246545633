import { useRef } from 'react';

import { removeElements } from 'react-flow-renderer';

import useDeleteFlowDataApiAction from 'modules/Sync/WorkflowEditor/apiActions/deleteFlowData';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import useSaveMappingAction from 'modules/Sync/WorkflowEditor/editorHooks/useSaveMappingAction';

const useDeleteNodeAction = () => {
  const { mappingId, workflowElements, setWorkflowElements, setIsMappingDataSaved } =
    useSyncWorkflowEditorContext();
  const { saveMappingAction } = useSaveMappingAction();
  const { deleteFlowData } = useDeleteFlowDataApiAction({
    refetchQueries: [
      'SyncGetConnectionById',
      'SyncConnections',
      'SyncGetWorkflowMappingConfiguration'
    ]
  });
  const stateRef = useRef();
  stateRef.current = workflowElements;

  const deleteNodeAction = (nodeId, flowId) => {
    let toBeDeleted = {};
    for (let i = 0; i < stateRef.current.length; i += 1) {
      if (stateRef.current[i].id === nodeId) {
        toBeDeleted = stateRef.current[i]; /* this prevents deleted nodes from reappearing */
      }
    }
    if (toBeDeleted) {
      setWorkflowElements((els) => removeElements([toBeDeleted], els));
      if (flowId) {
        setIsMappingDataSaved(false);
      }
      deleteFlowData({
        mappingId,
        flowId,
        successMessage: 'Node deleted',
        onCompleted: () => {
          setIsMappingDataSaved(true);
          saveMappingAction({ successMessage: '' });
        }
      });
    }
  };

  return { deleteNodeAction };
};

export default useDeleteNodeAction;
