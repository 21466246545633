import { arePropertiesInObject } from './objectFunctions';

export const reorderItemInList = (list, itemStartIndex, itemDestinationIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(itemStartIndex, 1);
  result.splice(itemDestinationIndex, 0, removed);

  return result;
};

export const findNode = (id, array, idKey = 'id', childrenKey = 'children') => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of array) {
    if (node[idKey] === id) return node;
    if (node[childrenKey]) {
      const child = findNode(id, node[childrenKey], idKey, childrenKey);
      if (child) return child;
    }
  }
  return null;
};

export const findParent = (id, array, idKey = 'id', childrenKey = 'children', keyToSearch = 'children') => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of array) {
    if (node[keyToSearch] && node[keyToSearch].some((child) => child[idKey] === id)) return node;
    if (node[childrenKey]) {
      const child = findParent(id, node[childrenKey], idKey, childrenKey, keyToSearch);
      if (child) return child;
    }
  }
  return null;
};

export const recursiveToggleKey = (id, array, key, idKey = 'id', childrenKey = 'children') =>
  array.map((node) => {
    if (node[idKey] === id) return { ...node, [key]: !node[key] };
    if (node[childrenKey]) node[childrenKey] = recursiveToggleKey(id, node[childrenKey], key, idKey, childrenKey);
    return node;
  });

export const recursiveToggleEditNodeLabel = (targetNode, array, idKey = 'id', childrenKey = 'children') =>
  array?.map((node) => {
    if (node[idKey] === targetNode[idKey])
      return {
        ...node,
        label: targetNode.label,
        locationName: targetNode.locationName,
        locationIdentifier: targetNode.locationIdentifier,
        prefix: targetNode.prefix,
        isEditing: !node.isEditing,
        isNew: false
      };
    if (node[childrenKey])
      node[childrenKey] = recursiveToggleEditNodeLabel(targetNode, node[childrenKey], idKey, childrenKey);
    return node;
  });

export const recursiveUpdateNode = (targetNode, array, idKey = 'id', childrenKey = 'children') =>
  array?.map((node) => {
    if (node[idKey] === targetNode[idKey]) return targetNode;
    if (node[childrenKey]) node[childrenKey] = recursiveUpdateNode(targetNode, node[childrenKey], idKey, childrenKey);
    return node;
  });

export const recursiveUpdateNodeKeyValue = (
  targetNodeId,
  array,
  updatedLocation = {},
  idKey = 'id',
  childrenKey = 'children'
) =>
  array?.map((node) => {
    if (node[idKey] === targetNodeId) return updatedLocation;
    if (node[childrenKey])
      node[childrenKey] = recursiveUpdateNodeKeyValue(
        targetNodeId,
        node[childrenKey],
        updatedLocation,
        idKey,
        childrenKey
      );
    return node;
  });

export const addChildrenRecursively = (
  targetNode,
  array,
  child,
  insertAtTheBeginning,
  preventOpening,
  idKey = 'id',
  childrenKey = 'children'
) =>
  array?.map((node) => {
    if (node[idKey] === targetNode[idKey])
      return {
        ...node,
        isOpen: preventOpening ? node.isOpen : true,
        [childrenKey]: insertAtTheBeginning
          ? [child, ...(node[childrenKey] || [])]
          : [...(node[childrenKey] || []), child]
      };
    if (node[childrenKey])
      node[childrenKey] = addChildrenRecursively(
        targetNode,
        node[childrenKey],
        child,
        insertAtTheBeginning,
        preventOpening,
        idKey,
        childrenKey
      );
    return node;
  });

export const removeChildrenRecursively = (id, array, idKey = 'id', childrenKey = 'children') =>
  array.reduce((arr, item) => {
    if (item[idKey] !== id) {
      if (item[childrenKey]) {
        item[childrenKey] = removeChildrenRecursively(id, item[childrenKey], idKey, childrenKey);
        if (!item[childrenKey].length) item.childrenTypeName = null;
      }

      arr.push(item);
    }
    return arr;
  }, []);

export const removeOccurrencesRecursively = (array, key, value, childrenKey = 'children') =>
  array.reduce((arr, item) => {
    if (typeof value === 'boolean') {
      if (item[key] === value) return arr;
    } else if (item[key]?.toLowerCase().trim() === value?.toLowerCase().trim()) return arr;

    if (item[childrenKey]) {
      item[childrenKey] = removeOccurrencesRecursively(item[childrenKey], key, value, childrenKey);
      if (!item[childrenKey].length) item.childrenTypeName = null;
    }

    arr.push(item);
    return arr;
  }, []);

export const replaceItemsById = (targetNode, array = [], idKey = 'id', childrenKey = 'children') =>
  array.map((node) => {
    if (node[idKey] === targetNode[idKey]) return targetNode;
    if (node[childrenKey]) {
      node[childrenKey] = replaceItemsById(targetNode, node[childrenKey], idKey, childrenKey);
    }
    return node;
  });

export const clone = (variable = {}) => JSON.parse(JSON.stringify(variable));

export const sort = (array, key = 'sortOrder') => {
  const newArray = [...array];

  return newArray.sort((a, b) => {
    const x = a[key];
    const y = b[key];

    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
  });
};

export const sortByKey = (array, key = 'sortOrder', childrenKey = 'children') => {
  const children = array.reduce((childrenArray, item) => {
    if (item[childrenKey]) item[childrenKey] = sortByKey(item[childrenKey], key, childrenKey);
    childrenArray.push(item);
    return childrenArray;
  }, []);
  return sort(children);
};

export const findIdByLabel = (label, list) => list.find((item) => item.label === label).value;

export const getNumberOfOccurrences = (key, value, array, childrenKey = 'children') =>
  array.reduce((accumulated, item) => {
    if (typeof value === 'boolean') {
      if (item[key] === value) accumulated += 1;
    } else if (item[key]?.toLowerCase().trim() === value?.toLowerCase().trim()) accumulated += 1;

    if (item[childrenKey]) accumulated += getNumberOfOccurrences(key, value, item[childrenKey], childrenKey);
    return accumulated;
  }, 0);

export const getOccurrences = (key, value, array, childrenKey = 'children') =>
  array.reduce((accumulated, item) => {
    if (typeof value === 'boolean') {
      if (item[key] === value) accumulated.push(item);
    } else if (item[key]?.toLowerCase().trim() === value?.toLowerCase().trim()) accumulated.push(item);

    if (item[childrenKey]) accumulated = accumulated.concat(getOccurrences(key, value, item[childrenKey], childrenKey));
    return accumulated;
  }, []);

export const findFirstOccurrence = (key, value, array = [], childrenKey = 'children') => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of array) {
    if (node[key] === value) return node;
    if (node[childrenKey]) {
      const child = findFirstOccurrence(key, value, node[childrenKey], childrenKey);
      if (child) return child;
    }
  }
  return null;
};

export const findAnyOccurrence = (array, key, childrenKey = 'children') =>
  array.reduce((exist, item) => {
    if (exist) return true;
    if (item[key]) return true;
    if (item[childrenKey]) exist = findAnyOccurrence(item[childrenKey], key, childrenKey);
    return exist;
  }, false);

export const updateProperties = (list, id, keyId, itemUpdated, fieldsToUpdate) =>
  list.map((item) => {
    if (id !== item[keyId]) {
      return item;
    }
    const itemMap = { ...item };
    fieldsToUpdate.forEach((fieldToUpdate) => (itemMap[fieldToUpdate.original] = itemUpdated[fieldToUpdate.map]));
    return itemMap;
  });

export const getStatusFilters = (statusList, statusToFilter) => {
  let filterId = '';
  statusList?.forEach((status) => {
    if (!statusToFilter.includes(status.label)) {
      filterId += `${status.value},`;
    }
  });

  return filterId.slice(0, -1);
};

export const disableIfChildrenIsEmpty = (id, array, idKey = 'id', childrenKey = 'children') =>
  array?.map((node) => {
    if (node[idKey] === id) return { ...node, disabled: !node[childrenKey].length };
    if (node[childrenKey]) node[childrenKey] = disableIfChildrenIsEmpty(id, node[childrenKey], idKey, childrenKey);
    return node;
  });

export const isDuplicatedNodeName = ({ inputValue, nodes, nodePropertyName, currentNodeId }) => {
  if (!nodes?.length) return null;

  const isDuplicated = nodes?.some(
    (item) =>
      item[nodePropertyName]?.trim().toLowerCase() === inputValue.trim().toLowerCase() && item?.id !== currentNodeId
  );
  return isDuplicated;
};

export const getFirstEmptyObjectInArray = (arr) => arr.find((item) => Object.keys(item).length === 0);

export const removeDuplicateItemsByKey = (arr, keyName) =>
  [...arr].filter((v, i, a) => a.findIndex((v2) => v2[keyName] === v[keyName]) === i);

// Order array objects by object field
export function recursiveSort(arr, field) {
  if (!field) {
    throw new Error('param field is required on recursiveSort function ');
  }
  if (arr.length <= 1) {
    return arr;
  }
  const pivot = arr[0];
  const rest = arr.slice(1);
  const less = [];
  const greater = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rest.length; i++) {
    if (rest[i][field].toLowerCase() < pivot[field].toLowerCase()) {
      less.push(rest[i]);
    } else {
      greater.push(rest[i]);
    }
  }
  return recursiveSort(less, field).concat(pivot, recursiveSort(greater, field));
}

export function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

export function isMultipleOf(divider, dividend) {
  return dividend % divider === 0;
}

export function findItemByProperties(arr, searchingObject) {
  let foundObject = null;
  arr.forEach((iterator) => {
    const isSearchingObject = arePropertiesInObject(iterator, searchingObject);
    if (isSearchingObject) foundObject = iterator;
  });

  return foundObject;
}
