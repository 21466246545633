import { useEffect } from 'react';

export const useClickAwayListener = (onClickOutside = () => {}) => {
  useEffect(() => {
    const element = document.querySelector('[data-id="0"]');
    const handleClickOutside = (event) => {
      const className = event?.target?.className ?? '';
      const { id = '' } = event?.target ?? {};
      const iconIgnored = typeof className?.baseVal === 'string';
      const functionValidation = iconIgnored ? 'ClickAwayListener-ItemIgnored' : className;

      const classNameValidation =
        functionValidation?.includes('MuiBackdrop-root') ||
        functionValidation?.includes('MuiBackdrop-invisible') ||
        functionValidation?.includes('PrivateSwitchBase-input') ||
        functionValidation?.includes('MuiTypography-root') ||
        functionValidation === '';

      const idValidation = id?.includes('ClickAwayListener-ItemIgnored');

      if (element && !element.contains(event?.target) && !(classNameValidation || idValidation)) onClickOutside(event);
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);
};
