import ItemsDataGridPro from 'components/ItemsDatagridPro';
import NoRowsOverlay from 'modules/Sync/Webhooks/WebhooksDataGridTable/NoRowsOverlay';

import { dataGridStyles, defaultDataGridConfig, groupingColDef } from './WebhooksDataGridTableHelper';

const WebhooksDataGridComponent = (props) => {
  const { rows, columns, apiRef, activeRows, handleSort, loadingWebhooks } = props;
  const { setSelectedWebhookIds, setSelectedWebhookDetails, setWebhookIdWithStatus } = props;
  return (
    <ItemsDataGridPro
      {...defaultDataGridConfig}
      apiRef={apiRef}
      loading={loadingWebhooks}
      isGroupExpandedByDefault={(node) => {
        if (activeRows.length) return activeRows.includes(node.id);
        return rows?.[0]?.id === node?.id || rows?.[1]?.id === node?.id;
      }}
      groupingColDef={groupingColDef}
      getTreeDataPath={(row) => row.path}
      rows={rows}
      getRowId={(row) => row.id}
      getRowClassName={({ row }) => {
        if (row?.isWebhook) return 'webhook-row';
        if (row?.isworkflow) return 'workflow-row';
        if (row?.isMapping) return 'mapping-row';
        return 'action-row';
      }}
      columns={columns}
      components={{
        NoRowsOverlay: () => <NoRowsOverlay text="Create a real-time sync to review webhook status." />
      }}
      onSelectionModelChange={(selectedItems) => {
        const currSelectedWebhookIds = [];
        const currSelectedWebhookDetails = [];
        const currWebhookIdWithStatus = [];
        rows.map((item) => {
          if (selectedItems.includes(item.id)) {
            currSelectedWebhookDetails.push(item.name);
            currWebhookIdWithStatus.push({ WebhookId: item.rowWebhookId, IsActive: !item.isActive });
            return currSelectedWebhookIds.push(item.rowWebhookId);
          }
          return null;
        });
        setSelectedWebhookIds([...new Set(currSelectedWebhookIds)]);
        setSelectedWebhookDetails([...new Set(currSelectedWebhookDetails)]);
        setWebhookIdWithStatus([...new Set(currWebhookIdWithStatus)]);
      }}
      onSortModelChange={handleSort}
      sortingOrder={['asc', 'desc']}
      sx={dataGridStyles}
    />
  );
};
export default WebhooksDataGridComponent;
