import React from 'react';

import { Checkbox, FormControlLabel, Grid } from '@mui/material';

const AutocompleteOption = ({ filters, onClick, labelName, optionProps }) => (
  <Grid item xs={12} sm={8} md={4} lg={4} xl={4} sx={{ margin: 0, padding: 0 }}>
    <FormControlLabel
      {...optionProps}
      control={
        <Checkbox
          size="small"
          color="warning"
          label={labelName}
          checked={filters.includes(labelName)}
          sx={{ padding: 0, margin: 0 }}
        />
      }
      onClick={onClick}
      label={labelName}
      sx={{
        padding: 0,
        margin: 0,
        height: 30,
        '.MuiFormControlLabel-label': { pl: 1 }
      }}
    />
  </Grid>
);

export default AutocompleteOption;
