import React from 'react';

import RightSideFlyoutPaper from 'components/RightSideFlyoutPaper';

import ListFlyoutPaperActionButton from './ListFlyoutPaperActionButton';
import ListFlyoutPaperItem from './ListFlyoutPaperItem';
import ListFlyoutPaperItems from './ListFlyoutPaperItems';
import ListFlyoutPaperSearch from './ListFlyoutPaperSearch';
import ListFlyoutPaperTitle from './ListFlyoutPaperTitle';

const ListFlyoutPaper = ({
  useXButton,
  topRightActionsSx,
  width = '280px',
  onClose,
  sx,
  children
}) => (
  <RightSideFlyoutPaper
    onClose={onClose}
    useXButton={useXButton}
    topRightActionsSx={topRightActionsSx}
    width={width}
    sx={{
      bottom: '16',
      p: 0,
      ...sx
    }}
  >
    {children}
  </RightSideFlyoutPaper>
);

ListFlyoutPaper.Title = ListFlyoutPaperTitle;
ListFlyoutPaper.Search = ListFlyoutPaperSearch;
ListFlyoutPaper.ActionButton = ListFlyoutPaperActionButton;
ListFlyoutPaper.Items = ListFlyoutPaperItems;
ListFlyoutPaper.Item = ListFlyoutPaperItem;

export default ListFlyoutPaper;
