import React from 'react';

import { Chip } from '@mui/material';
import { Box } from '@mui/system';

const FILTERS_LIMIT = 2;

const AutocompleteTag = ({ options }) => {
  const filterCountLarge = options.length > FILTERS_LIMIT;
  const delimiterText = `${filterCountLarge ? '+' : ''}${filterCountLarge ? options.length - FILTERS_LIMIT : ''}`;
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {options.slice(0, FILTERS_LIMIT).map((option) => (
        <Chip key={option} label={option} size="small" />
      ))}
      {delimiterText}
    </Box>
  );
};

export default AutocompleteTag;
