import { useEffect } from 'react';

import { gql } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncArchivedWorkflows as SYNC_ARCHIVED_WORKFLOWS } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DATAGRID_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const useArchivedWorkflowsLazyPaginationHook = (isArchivedDataGrid = false) => {
  const [
    {
      lazyLoad: lazyLoadArchivedWorkflows,
      paginationHandler: archivedWorkflowsPaginationHandler,
      searchHandler: archivedWorkflowsSearchHandler,
      onOrderby: archivedWorkflowsSortHandler,
      refetchHandler: archivedWorkflowsRefetchHandler
    },
    { loading: loadingArchivedWorkflows, data: archivedWorkflows }
  ] = useLazyPaginatedQuery(gql(SYNC_ARCHIVED_WORKFLOWS), FETCH_POLICY, DATAGRID_DEFAULT_PG_SIZE, false);

  useEffect(() => {
    if (isArchivedDataGrid) lazyLoadArchivedWorkflows(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchivedDataGrid]);

  return {
    lazyLoadArchivedWorkflows,
    archivedWorkflowsPaginationHandler,
    archivedWorkflowsSearchHandler,
    archivedWorkflowsSortHandler,
    archivedWorkflowsRefetchHandler,
    loadingArchivedWorkflows,
    archivedWorkflows
  };
};

export default useArchivedWorkflowsLazyPaginationHook;
