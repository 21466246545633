import React from 'react';

import { Stack } from '@mui/material';

import { positiveNumberRules, requiredRule } from 'constants/inputFieldRules';
import {
  SelectField,
  ControlInput
} from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { fieldServerOptions } from 'modules/Sync/Constants/constants';

const FieldInputs = ({ control, disableAllFields }) => {
  const { currentConnection } = useSyncConnectionsContext();

  return (
    <Stack direction="row" gap="16px">
      <SelectField
        key={currentConnection?.connectionId}
        control={control}
        name="configuration.field.fieldServer"
        label="Field Server*"
        rules={requiredRule('Field Server*', '*Required')}
        options={fieldServerOptions}
        defaultValue={fieldServerOptions[0]}
        disabled={disableAllFields}
      />
      <ControlInput
        key={currentConnection?.connectionId}
        control={control}
        type="number"
        name="configuration.field.timeout"
        label="Field Upload Timeout*"
        rules={positiveNumberRules('Field Upload Timeout', 3600, '*Required')}
        defaultValue={3600}
        disabled={disableAllFields}
      />
    </Stack>
  );
};

export default FieldInputs;
