import React from 'react';

import Box from '@mui/material/Box';

import { otherColors, syncColors } from 'styles/theme/colors';

export const StyledActionDnDNode = ({ sx, ...props }) => (
  <Box
    sx={{
      height: 52,
      width: 280,
      border: '1px solid',
      borderRadius: 1,
      borderColor: syncColors.canvasNodeColors.default,
      backgroundColor: syncColors.canvasNodeColors.defaultBackground,
      marginX: 1,
      marginBottom: 2,
      ...sx
    }}
    {...props}
  />
);

export const StyledConnectorDnDNode = ({ sx, ...props }) => (
  <Box
    sx={{
      height: 52,
      width: 280,
      border: '1px solid',
      borderRadius: 1,
      borderColor: syncColors.canvasNodeColors.default,
      backgroundColor: syncColors.canvasNodeColors.background,
      marginX: 1,
      marginBottom: 2,
      ...sx
    }}
    {...props}
  />
);

export const StyledActionNodeThumbnailContainer = ({ sx, ...props }) => (
  <Box
    sx={{
      width: 42,
      backgroundColor: syncColors.canvasNodeColors.background,
      paddingLeft: '10px',
      paddingTop: '15px',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      ...sx
    }}
    {...props}
  />
);

export const StyledConnectorNodeThumbnailContainer = ({ sx, ...props }) => (
  <StyledActionNodeThumbnailContainer
    {...props}
    sx={{
      ...sx,
      background: `linear-gradient(90deg, ${otherColors.selected} 100%, #fff 10%)`
    }}
  />
);

const drawerStyles = {
  root: {
    position: 'absolute',
    zIndex: 999,
    width: 400,
    height: '79vh',
    right: 56,
    bottom: 8,
    backgroundColor: 'rgba(48, 48, 48, 0.95)',
    borderRadius: 4
  },
  parentContainer: {
    paddingTop: 13,
    paddingLeft: 20,
    paddingRight: 17,
    paddingBottom: 17,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    height: 32,
    width: 140,
    color: syncColors.canvasNodeColors.background
  },
  dragIndicator: { color: 'white', fontSize: 'medium', marginLeft: '-18px' },
  infoIcon: {
    height: '15px',
    width: '15px',
    color: 'rgba(255, 255, 255, 0.7)',
    position: 'absolute',
    top: 16,
    left: 95
  },
  dockIcon: {
    width: 12,
    height: 22,
    color: 'rgba(255, 255, 255, 0.26)',
    position: 'absolute',
    top: 13,
    right: 50
  },
  closeBtn: {
    width: 14,
    height: 14,
    color: 'white',
    position: 'absolute',
    top: 8,
    right: 40
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'white',
    marginTop: 11,
    marginBottom: 16
  },
  flexbox: { display: 'flex' },
  flexrow: { display: 'flex', flexDirection: 'row', width: '232px', height: '100%' },
  flexboxSpaced: { display: 'flex', justifyContent: 'space-between' },
  listContainer: { height: '100%', flexGrow: 1, overflowY: 'auto' },
  list: { backgroundColor: 'transparent' },
  thumbIcon: { width: '100%', height: '100%' },
  headingContainer: { marginLeft: 2, fontFamily: 'Roboto' },
  heading: { fontSize: 12, height: 15, textTransform: 'capitalize' }
};

export const actionDrawerStyles = {
  root: { ...drawerStyles.root },
  parentContainer: { ...drawerStyles.parentContainer },
  title: { ...drawerStyles.title },
  dragIndicator: { ...drawerStyles.dragIndicator },
  infoIcon: { ...drawerStyles.infoIcon },
  dockIcon: { ...drawerStyles.dockIcon },
  closeBtn: { ...drawerStyles.closeBtn },
  divider: { ...drawerStyles.divider },
  flexbox: { ...drawerStyles.flexbox },
  flexrow: { ...drawerStyles.flexrow },
  flexboxSpaced: { ...drawerStyles.flexboxSpaced },
  actionsListContainer: { ...drawerStyles.listContainer },
  actionsList: { ...drawerStyles.list },
  actionThumbIcon: { ...drawerStyles.thumbIcon, paddingX: 0, marginTop: -1 },
  actionTitleContainer: { ...drawerStyles.headingContainer },
  actionTitle: { ...drawerStyles.heading, width: 176, height: 16, lineHeight: 1.5 },
  actionDetail: { fontSize: 14, width: 176, height: 16, marginBottom: 6 }
};

export const connectorDrawerStyles = {
  root: { ...drawerStyles.root },
  parentContainer: { ...drawerStyles.parentContainer },
  title: { ...drawerStyles.title },
  dragIndicator: { ...drawerStyles.dragIndicator },
  infoIcon: { ...drawerStyles.infoIcon, left: 130 },
  dockIcon: { ...drawerStyles.dockIcon },
  closeBtn: { ...drawerStyles.closeBtn },
  divider: { ...drawerStyles.divider },
  flexbox: { ...drawerStyles.flexbox },
  flexrow: { ...drawerStyles.flexrow },
  flexboxSpaced: { ...drawerStyles.flexboxSpaced },
  connectionsListContainer: { ...drawerStyles.listContainer },
  connectionsList: { ...drawerStyles.list },
  connectorThumbIcon: { ...drawerStyles.thumbIcon, borderRadius: 4 },
  connectorContainer: {
    ...drawerStyles.headingContainer,
    marginLeft: '4%',
    width: '100%',
    height: '100%'
  },
  connectorTitle: { ...drawerStyles.heading, lineHeight: 1.5 },
  connectorDetailContainer: { fontSize: 14, overflow: 'hidden' },
  connectorDetail: {
    width: '100%',
    height: 25,
    whiteSpace: 'nowrap',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export const propertiesDrawerStyles = {
  container: { width: 467, height: '88%', bottom: 4 },
  title: { justifyContent: 'space-between', width: '100%' },
  tabList: {
    '.MuiTabs-scrollButtons': {
      color: 'white'
    },
    '.MuiTabs-scrollButtons.Mui-disabled': {
      opacity: 0.3
    },
    '.MuiTab-root.Mui-selected': {
      color: syncColors.mainDarkBackground
    },
    '.MuiTabs-indicator ': {
      backgroundColor: syncColors.mainDarkBackground
    }
  },
  tab: { color: 'white', width: 100 },
  tabPanel: { color: 'white', padding: 0 },
  accordion: {
    '.MuiAccordion-root, .MuiAccordionSummary-expandIconWrapper': {
      background: 'transparent',
      color: 'white',
      borderTop: 'none',
      borderBottom: 'none'
    },
    '.MuiAccordionSummary-content': {
      margin: 0
    },
    '.MuiAccordionDetails-root': {
      paddingTop: 0
    },
    'svg path': {
      fill: 'white'
    }
  },
  editIcon: {
    display: 'flex',
    justifyContent: 'right',
    paddingLeft: 16,
    paddingRight: 16
  },
  archivedInfo: {
    display: 'flex',
    justifyContent: 'start',
    gap: 0.5,
    marginTop: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    color: syncColors.mainDarkBackground
  }
};
