import React from 'react';

import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useStoreState } from 'react-flow-renderer';

import { MoreMenu } from 'components';
import { IconStyled } from 'components/Icons';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { CANVAS_NODE_TYPES, filterTypes } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { NodeHandles } from 'modules/Sync/WorkflowEditor/FlowchartNodes/NodeHandles';
import { connectorNodeStyles, actionNodeStyles } from 'modules/Sync/WorkflowEditor/FlowchartNodes/nodeStyles';
import { getConnectorNodesWithEdges } from 'modules/Sync/WorkflowEditor/helpers/CopyFilesHelpers/connectionsHelper';
import { getFilteredNodes, getNodeOpacity } from 'modules/Sync/WorkflowEditor/helpers/getStyledWorkflowElementHelpers';
import { getConnectorIcon } from 'modules/Sync/WorkflowEditor/helpers/SyncIcons';
import DeleteCanvasNodeModal from 'modules/Sync/WorkflowEditor/Modals/DeleteCanvasNodeModal';
import { otherColors, syncColors } from 'styles/theme/colors';

const AUTO_HIDE_DURATION = 2500;
const ERROR_MSG = "Connected Nodes Can't be deleted";

const ConnectorNode = ({ data, id }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const isArchivedTab = queryParameters.has('isArchived');

  const { onDelete } = data;
  const edges = useStoreState((store) => store.edges);
  const { enqueueSnackbar } = useSnackbar() || {};
  const connectedConnectorNodes = getConnectorNodesWithEdges(edges);
  const isConnectedNode = connectedConnectorNodes.includes(id);
  const {
    setConnectorAlias,
    showAliasModal,
    setActionId,
    workflowMappings,
    isSetOpacity,
    isSyncRunning,
    filterType: filter,
    workflowElements
  } = useSyncWorkflowEditorContext();
  const [isOpenDeleteNodeModal, setDeleteNodeModalVisibility] = React.useState(false);
  const showDeleteNodeModal = () => setDeleteNodeModalVisibility(true);
  const hideDeleteNodeModal = () => setDeleteNodeModalVisibility(false);

  const requiredNodes = workflowMappings.filter(
    (node) => (isArchivedTab ? node?.isArchived === true : !node?.isArchived) // isArchived key doesn't exist on updating the flow, hence checking the condition like this
  );

  const requiredActionConnectors = requiredNodes.find(
    (node) => node.source.nodeId === id || node.destinations?.find((index) => index.nodeId === id)
  );

  const { rest } = workflowElements?.find(({ id: elementId }) => elementId === id) || {};
  const selectedNodes = getFilteredNodes({ filter, workflowMappings });
  const { opacity } = getNodeOpacity({ selectedNodes, id, filter, isConnectedNode, ...rest });
  const isBlurred = opacity < 1;

  const deleteNode = () => {
    if (!isConnectedNode) {
      onDelete(id);
      return hideDeleteNodeModal();
    }
    return enqueueSnackbar(ERROR_MSG, { autoHideDuration: AUTO_HIDE_DURATION, ...VARIANT_ERROR });
  };
  const onEditAlias = () => {
    setActionId(id);
    setConnectorAlias(data.connectorAlias);
    showAliasModal();
  };
  const renameOption = { label: 'Rename', clickEvent: onEditAlias };
  const deleteOption = {
    label: 'Delete',
    color: '#F44336',
    clickEvent: showDeleteNodeModal,
    disabled: isConnectedNode
  };
  const isArchivedFilter = filter === filterTypes.ARCHIVED;
  const showOptions = ((isConnectedNode && !isSyncRunning) || !isConnectedNode) && !isBlurred && !isArchivedFilter;
  const options = showOptions ? [...(isConnectedNode ? [] : [deleteOption]), renameOption] : [];

  return (
    <>
      <DeleteCanvasNodeModal open={isOpenDeleteNodeModal} onClose={hideDeleteNodeModal} onSubmit={deleteNode} />
      <Node
        isConnectedNode={isConnectedNode}
        data={data}
        options={options}
        isArchivedTab={isArchivedTab}
        isSetOpacity={isSetOpacity}
        requiredActionConnectors={requiredActionConnectors}
      />
    </>
  );
};

const Node = ({ isConnectedNode, data, options, isArchivedTab, requiredActionConnectors, isSetOpacity }) => (
  <Box
    sx={{
      ...connectorNodeStyles.nodeContainer,
      borderColor: isConnectedNode ? syncColors.statusColors.success : syncColors.canvasNodeColors.default,
      backgroundColor: syncColors.canvasNodeColors.background,
      opacity: !isSetOpacity && !requiredActionConnectors && isConnectedNode && !isArchivedTab ? 0.1 : 1,
      display: !requiredActionConnectors && isArchivedTab && 'none',
      '&:hover': { '& .MuiSvgIcon-root': { opacity: 1 } }
    }}
  >
    <div style={connectorNodeStyles.flexBox}>
      <div
        style={{
          ...connectorNodeStyles.iconContainer,
          backgroundColor: otherColors.selected
        }}
      >
        <div style={connectorNodeStyles.square}>
          <IconStyled
            icon={getConnectorIcon(data.connectionType)}
            sx={{ width: 30, height: 30, position: 'relative', right: 8, bottom: 2 }}
          />
        </div>
      </div>
      <div style={connectorNodeStyles.robotoText}>
        <div
          style={{
            ...connectorNodeStyles.title,
            color: isConnectedNode ? syncColors.statusColors.success : syncColors.canvasNodeColors.default
          }}
        >
          {CANVAS_NODE_TYPES.CONNECTOR}
        </div>
        <div style={connectorNodeStyles.taskDetails.parent}>
          <div style={connectorNodeStyles.taskDetails.child}>
            <CustomSyncTooltip title={data.connectorAlias || data?.taskDetail} sx={{ maxWidth: 120 }}>
              {data.connectorAlias || data?.name || data?.taskDetail}
            </CustomSyncTooltip>
          </div>
        </div>
      </div>
      {!!options.length && (requiredActionConnectors || !isConnectedNode) && !isArchivedTab && (
        <MoreMenu style={actionNodeStyles.optionsMenuOnBottom} options={options} sx={{ opacity: 0 }} />
      )}
    </div>
    <NodeHandles />
  </Box>
);
export default ConnectorNode;
