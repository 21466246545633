import { Chip } from '@mui/material';

import { syncColors } from 'styles/theme/colors';

const defaultProps = {
  variant: 'filled',
  size: 'small'
};

const defaultSx = {
  fontSize: '13px',
  fontWeight: 500,
  height: 24,
  color: 'white'
};

const SyncStatusLabel = (syncStatus) =>
  ({
    [true]: 'Activated',
    [false]: 'Deactivated'
  }[syncStatus]);

const SyncStatusBackground = (syncStatus) =>
  ({
    [true]: syncColors.webhookStatusColors.activate,
    [false]: syncColors.webhookStatusColors.deActivate
  }[syncStatus]);

const WebhookStatusHig = (syncStatus, sx = {}) => (
  <Chip
    {...defaultProps}
    label={SyncStatusLabel(syncStatus)}
    sx={{
      ...defaultSx,
      ...sx,
      background: SyncStatusBackground(syncStatus)
    }}
  />
);

export default WebhookStatusHig;
