import React from 'react';

import { useMutation, gql } from '@apollo/client';
import { FormControl, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { syncDeleteWebhook as DELETE_WEBHOOK } from 'graphql/mutations';
import { dispatchWebhookGridEvent } from 'helpers/domHelpers';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { WEBHOOK_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWebhooksContext } from 'modules/Sync/Webhooks/Contexts/WebhooksContext';
import { errorColors } from 'styles/theme/colors';

import ModalFooter from './ModalFooter';

const DELETE_PROHIBITED_ERROR_MESSAGE = 'Unable to delete webhook(s)';

const DeleteWebhookModal = ({ onClose }) => {
  const { activeModal, selectedWebhookIds, selectedWebhookDetails } = useSyncWebhooksContext();
  const firstSelectedWebhook = selectedWebhookDetails[0];
  const [errorMessage, setErrorMessage] = React.useState('');
  const { handleResponse } = useGraphqlResponseHandler();
  const methods = useForm({ mode: 'all' });
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = methods;
  const [deleteWebhooks, { loading }] = useMutation(gql(DELETE_WEBHOOK));

  const onSuccessHandler = () => {
    dispatchWebhookGridEvent()
    onCloseHandler()
  }

  const onCloseHandler = () => {
    setErrorMessage('');
    onClose();
  };

  const submit = handleSubmit(async (formValues) => {
    const { errors } = await handleResponse(
      deleteWebhooks,
      {
        variables: { body: { WebhooksIds: selectedWebhookIds, Email: formValues.email } }
      },
      { successMessage: 'Webhook successfully deleted' },
      () => {},
      (errorMessage) => {
        if (errorMessage !== DELETE_PROHIBITED_ERROR_MESSAGE) setErrorMessage(errorMessage);
        else onCloseHandler();
      },
      { hidden: true }
    );
    if (!errors) onSuccessHandler();
  });

  const onChangeHandler = () => {
    setErrorMessage('');
  };

  return (
    <CustomModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={activeModal === WEBHOOK_MODAL_KEYS.DELETE_WEBHOOK}
      onClose={onCloseHandler}
      title="Confirm delete"
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      paperSx={{ width: 550 }}
      footer={
        <ModalFooter
          cancelBtnText="CANCEL"
          createBtnText="CONFIRM"
          loading={loading}
          onModalClose={onCloseHandler}
          onSubmit={submit}
          isDelete
        />
      }
    >
      <Typography>
        Deleting this webhook will also delete all of its workflows, mappings and actions. Are you sure you want to
        delete{' '}
        <Typography fontWeight={700} display="inline-flex">
          <CustomSyncTooltip title={firstSelectedWebhook} sx={{ maxWidth: '32ch' }}>
            {firstSelectedWebhook}
          </CustomSyncTooltip>
          {selectedWebhookDetails.length > 1 && <>&nbsp;and {selectedWebhookDetails.length - 1} others</>}
        </Typography>
        ?
      </Typography>
      <Typography fontWeight={500} mt={2} mb="3px" variant="subtitle2">
        Please type your email to confirm deletion:
      </Typography>
      <FormControl size="small" fullWidth>
        <ControlTextField
          required
          control={control}
          email
          name="email"
          label="Email"
          sx={{ marginBottom: '0px', marginTop: '3px' }}
          errors={errors}
          helperText={errors?.message}
          onChange={onChangeHandler}
        />
      </FormControl>
      {errorMessage.length > 0 && (
        <Typography sx={{ color: errorColors.main, fontSize: 13 }}>{errorMessage}</Typography>
      )}
    </CustomModal>
  );
};
export default DeleteWebhookModal;
