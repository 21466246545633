import { gql, useMutation } from '@apollo/client';

import { syncUpdateWorkflowCanvas as SYNC_UPDATE_WORKFLOW_CANVAS } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useSaveCanvasDataApiAction = (queryOptions) => {
  const [saveCanvasNodes] = useMutation(gql(SYNC_UPDATE_WORKFLOW_CANVAS), queryOptions);
  const { handleResponse } = useGraphqlResponseHandler();

  const saveCanvasData = async ({
    mappingId,
    canvasData,
    onCompleted,
    onFailed,
    successMessage = 'Canvas data successfully saved'
  }) =>
    handleResponse(
      saveCanvasNodes,
      {
        variables: {
          body: {
            MappingId: mappingId,
            CanvasNode: canvasData
          }
        }
      },
      { successMessage },
      onCompleted,
      onFailed
    );

  return { saveCanvasData };
};

export default useSaveCanvasDataApiAction;
