import useStopSyncAllFlowsApiAction from 'modules/Sync/WorkflowEditor/apiActions/stopSyncAllFlows';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';

const useSyncMappingAction = () => {
  const { stopSyncAllFlows } = useStopSyncAllFlowsApiAction({
    refetchQueries: ['SyncGetWorkflowMappingConfiguration']
  });

  const { mappingId } = useSyncWorkflowEditorContext();

  const stopMappingAction = () => {
    stopSyncAllFlows({ mappingId });
  };

  return { stopMappingAction };
};

export default useSyncMappingAction;
