export function debounce(func, wait = 300, immediate = false) {
  let timeout;

  return function executedFunction(...args) {
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export function debounceTest(func, delay = 500) {
  let timerId;
  let lastCallTime = 0;

  return function debounced(...args) {
    const currentTime = Date.now();

    if (currentTime - lastCallTime < delay) {
      // The function was called too recently, so don't execute it
      return;
    }

    lastCallTime = currentTime;

    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      func.apply(this, args);
      timerId = null;
    }, delay);
  };
}

export const isObjectEmpty = (obj = {}) => {
  if (!obj) return true;

  const objKeys = Object.keys(obj);
  return !objKeys.length;
};

export const isNavigationKey = (key) =>
  key === 'Home' || key === 'End' || key.indexOf('Arrow') === 0 || key.indexOf('Page') === 0 || key === ' ';

export const validateFileTypes = (files = [], types = []) => {
  const isValid = files.every((file) => {
    const extenstion = file?.name?.split('.')[1];
    return types.includes(extenstion.toLowerCase());
  });
  return isValid;
};

export const withHttp = (url = '') => (url.indexOf('://') === -1 ? `http://${url}` : url);

/* Performs a deep equality check on two JavaScript values. */
export const isEqual = (a, b) => {
  /* If the two values are strictly equal, return true. */
  if (a === b) {
    return true;
  }

  /* Dates are equivalent if their time values are equal. */
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }

  if (a != null && typeof a === 'object' && b != null && typeof b === 'object') {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in a) {
      if (Object.prototype.hasOwnProperty.call(a, key)) {
        if (!Object.prototype.hasOwnProperty.call(b, key)) {
          return false;
        }
        if (!isEqual(a[key], b[key])) {
          return false;
        }
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key in b) {
      if (Object.prototype.hasOwnProperty.call(b, key) && !Object.prototype.hasOwnProperty.call(a, key)) {
        return false;
      }
    }

    return true;
  }

  return false;
};
