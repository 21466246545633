import React from 'react';

import { Box, IconButton, CircularProgress } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';

import { Custom, IconStyled } from 'components/Icons';
import { isNavigationKey } from 'helpers/common';
import { SYNC_LOGS } from 'modules/Sync/Constants/constants';
import { actionColors, surfaceColors } from 'styles/theme/colors';

const { LOG_FOLDER_TERMINAL_STATUS, LOG_ACTION_TERMINAL_STATUS } = SYNC_LOGS;

const RowExpansionIcon = ({ isExpanded = false, isLoading = false }) =>
  isLoading ? (
    <CircularProgress size="1rem" color="secondary" sx={{ mr: 1 }} />
  ) : (
    <Custom.ArrowDropDownIcon
      sx={{
        transform: isExpanded ? 'none' : 'rotate(-90deg)',
        fill: isExpanded ? surfaceColors.lightSurface.primary : actionColors.lightSurface.active
      }}
    />
  );

const RowExpandToggleBtn = ({ handleClick, handleKeyDown, row, isExpanded = false, isLoading = false }) => (
  <>
    {!row?.isFile && (
      <IconButton
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={-1}
        size="small"
        className="expansion-btn"
        disableRipple={isLoading}
        disabled={row.descendantCount <= 0}
      >
        <Box className="expansion-icon">
          {row.descendantCount > 0 ? (
            <RowExpansionIcon isExpanded={isExpanded} isLoading={isLoading} />
          ) : (
            <Box width={24} /> /* Placeholder For Missing Arrow */
          )}
        </Box>
      </IconButton>
    )}
    {!row?.isLoadMore &&
      (row?.isFile ? (
        <IconStyled
          sx={{ ml: 1.5, mr: 0, alignItems: 'center', display: 'flex' }}
          color={actionColors.lightSurface.active}
          icon={<Custom.AttachFile height={19} width={19} />}
        />
      ) : (
        <IconStyled
          icon={
            <Custom.FolderOpen
              height={24}
              width={24}
              fill={isExpanded ? surfaceColors.lightSurface.primary : actionColors.lightSurface.active}
            />
          }
          sx={{
            mr: 1,
            alignItems: 'center',
            display: 'flex'
          }}
        />
      ))}
  </>
);

const collapseSiblings = ({ apiRef, row }) => {
  const allRows = Array.from([...apiRef.current.getRowModels()?.values()]);
  const siblingFolderRows = allRows.filter(
    ({ id, parentId, isFile }) => parentId === row.parentId && id !== row.id && !isFile
  );
  siblingFolderRows.map(({ id: rowId }) => apiRef.current.setRowChildrenExpansion(rowId, false));
};

const getRowsToKeepExpanded = ({ expandedRows, rowId, apiRef }) =>
  expandedRows.reduce((expandedRows, row) => {
    const { id, path } = row;
    if (id !== rowId && !path.includes(rowId)) expandedRows.push(row);
    else apiRef.current.setRowChildrenExpansion(id, false);
    return expandedRows;
  }, []);

const LogFoldersDetailsDataGridGroupingCell = (props) => {
  const { id, field, rowNode, row, expandedRows, setExpandedRows, setPollingItems, autoRefreshFolderDetails } = props;
  const apiRef = useGridApiContext();
  const isLoading = rowNode.childrenExpanded ? !row.childrenFetched : false;

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent('cellNavigationKeyDown', props, event);
    }
  };

  const onRowGroupClick = async (props) => {
    const { id: rowId } = props;
    const row = apiRef.current.getRow(rowId);
    const { terminalStatus, syncFlowMetricId } = row;

    /* If row is collapsed */
    if (expandedRows.find(({ id }) => id === rowId)) {
      setExpandedRows(getRowsToKeepExpanded({ expandedRows, rowId, apiRef }));
      return setPollingItems((pollingItems) => pollingItems.filter(({ id }) => id !== rowId));
    }

    if (row?.parentId) collapseSiblings({ apiRef, row });
    setExpandedRows((expandedRows) => [...expandedRows.filter(({ parentId }) => parentId !== row?.parentId), row]);

    const itemIsSyncing =
      terminalStatus ===
      (syncFlowMetricId ? LOG_ACTION_TERMINAL_STATUS.IN_PROGRESS : LOG_FOLDER_TERMINAL_STATUS.IN_PROGRESS);

    return (
      itemIsSyncing &&
      setPollingItems((pollingItems) => [
        ...(autoRefreshFolderDetails
          ? pollingItems.filter(({ parentId }) => parentId !== row?.parentId)
          : pollingItems),
        row
      ])
    );
  };

  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    onRowGroupClick?.({ event, id, rowNode, field });
  };
  return (
    <Box sx={{ ml: rowNode.depth * 3 }} display="flex">
      <RowExpandToggleBtn
        handleClick={handleClick}
        handleKeyDown={handleKeyDown}
        row={row}
        isExpanded={rowNode?.childrenExpanded}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default LogFoldersDetailsDataGridGroupingCell;
