import { useEffect } from 'react';

import { CONNECTOR_NODE_TYPES } from 'modules/Sync/Constants/constants';
import { usePublishActionFormContext } from 'modules/Sync/WorkflowEditor/Contexts/PublishActionContext';
import ConnectorForm from 'modules/Sync/WorkflowEditor/Modals/PublishModal/ConnectorForm';

const MappingForm = ({ filters = [], showFilters = false, allowPrivateFiles = false, isSource = false, ...rest }) => {
  const { source, setCurrentConnectorType } = usePublishActionFormContext();

  const connectorFormProps = {
    fileTypeFilters: filters || [],
    showFilters,
    allowPrivateFiles,
    isSource,
    ...rest
  };

  useEffect(() => {
    if (isSource) setCurrentConnectorType(CONNECTOR_NODE_TYPES.SOURCE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSource]);

  return (
    <>
      {isSource && (
        <ConnectorForm
          isSource={isSource}
          key={source?.nodeId}
          defaultOption={source?.nodeId}
          connectorFormProps={connectorFormProps}
        />
      )}
    </>
  );
};

export default MappingForm;
