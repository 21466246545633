import React from 'react';

import { Box } from '@mui/material';
import { ControlButton, Controls } from 'react-flow-renderer';

import { Custom, IconStyled } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';

export const FlowControls = ({ onLayout, zoomIn, zoomOut, fitView, isCanvasUnlocked, onCanvasLockClick }) => (
  <Controls showZoom={false} showFitView={false} showInteractive={false} style={{ left: '40px', bottom: '8px' }}>
    <NavItemTooltip title="Vertical align" placement="right">
      <Box>
        <ControlButton onClick={() => onLayout('TB')}>
          <IconStyled icon={<Custom.CanvasVerticalLayout fillOpacity={0.54} />} />
        </ControlButton>
      </Box>
    </NavItemTooltip>
    <NavItemTooltip title="Horizontal align" placement="right">
      <Box>
        <ControlButton onClick={() => onLayout('LR')}>
          <IconStyled icon={<Custom.CanvasHorizontalLayout fillOpacity={0.54} />} />
        </ControlButton>
      </Box>
    </NavItemTooltip>
    <NavItemTooltip title="Zoom in" placement="right">
      <Box>
        <ControlButton onClick={zoomIn}>
          <IconStyled icon={<Custom.CanvasZoomIn fillOpacity={0.54} />} />
        </ControlButton>
      </Box>
    </NavItemTooltip>
    <NavItemTooltip title="Zoom out" placement="right">
      <Box>
        <ControlButton onClick={zoomOut}>
          <IconStyled icon={<Custom.CanvasZoomOut fillOpacity={0.54} />} />
        </ControlButton>
      </Box>
    </NavItemTooltip>
    <NavItemTooltip title="Full screen" placement="right">
      <Box>
        <ControlButton onClick={fitView}>
          <IconStyled icon={<Custom.CanvasFitView fillOpacity={0.54} />} />
        </ControlButton>
      </Box>
    </NavItemTooltip>
    <NavItemTooltip title={isCanvasUnlocked ? 'Unlocked' : 'Locked'} placement="right">
      <Box>
        <ControlButton onClick={onCanvasLockClick}>
          {isCanvasUnlocked ? (
            <IconStyled
              icon={<Custom.CanvasUnLock fillOpacity={0.54} />}
              sx={{ svg: { maxWidth: '24px', maxHeight: '24px' } }}
            />
          ) : (
            <IconStyled
              icon={<Custom.CanvasLock fillOpacity={0.54} />}
              sx={{ svg: { maxWidth: '24px', maxHeight: '24px' } }}
            />
          )}
        </ControlButton>
      </Box>
    </NavItemTooltip>
  </Controls>
);
