import { ApolloClient, from, HttpLink } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import cache from './cache/cache';
import getEnvVars from '../envVars';

const getAuth = (env) => ({
  type: env.AWS_APPSYNC_AUTHENTICATION_TYPE,
  jwtToken: async () => {
    try {
      return getToken();
    } catch (error) {
      return '';
    }
  }
});

const getHttpLink = (env) =>
  new HttpLink({
    uri: env.AWS_APPSYNC_GRAPHQL_ENDPOINT,
    fetchOptions: {
      timeout: 900000 // TODO: TBD -> Timeout issue in production
    }
  });

const getLink = (env) => {
  const url = env.AWS_APPSYNC_GRAPHQL_ENDPOINT;
  const region = env.AWS_APPSYNC_REGION;
  const auth = getAuth(env);
  const httpLink = getHttpLink(env);
  return from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink)
  ]);
};

const defaultOptions = {
  watchQuery: { errorPolicy: 'ignore' },
  query: { errorPolicy: 'all' },
  mutate: { errorPolicy: 'all' }
};

const getClient = async () => {
  const env = await getEnvVars();
  const link = getLink(env);
  return new ApolloClient({
    cache,
    link,
    defaultOptions,
    connectToDevTools: process.env.NODE_ENV !== 'production'
  });
};

export default getClient;

const getToken = async () => {
  if (window?.getJwtToken) return window.getJwtToken();

  const query = /CognitoIdentityServiceProvider.+?.idToken/;
  // eslint-disable-next-line no-restricted-syntax
  for (const i in localStorage) {
    // eslint-disable-next-line no-prototype-builtins
    if (localStorage.hasOwnProperty(i) && (i.match(query) || (!query && typeof i === 'string'))) {
      return localStorage.getItem(i);
    }
  }
  return '';
};
