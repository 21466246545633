import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';

import Modal from 'components/Modal';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';

import { IConfirmLeavingModal } from './@types/confirmLeavingModal';

const ConfirmLeavingModal = ({
  title = 'Confirm cancel',
  children = 'The item has not been saved. Navigating away will lose all changes. Are you sure you want to leave this page?',
  footer = null,
  showDialog,
  allowedLeavingLocations = [],
  titleStyles = { fontWeight: 500 },
  onConfirmNavigation = () => {},
  onCancelNavigation = () => {},
  open,
  sx,
  ...restProps
}: IConfirmLeavingModal) => {
  const [showNavigationPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    showDialog,
    allowedLeavingLocations
  );
  const [openPrompt, setOpenPrompt] = useState<boolean>(false);
  const handleClose = () => {
    setOpenPrompt(false);
    cancelNavigation();
  };

  const handleCancelNavigation = () => {
    handleClose();
    onCancelNavigation();
  };

  useEffect(() => {
    if (showNavigationPrompt || open) setOpenPrompt(showNavigationPrompt || open);
  }, [showNavigationPrompt, open]);

  return (
    <Modal
      {...restProps}
      open={openPrompt}
      onClose={() => handleCancelNavigation()}
      floatingCloseButton
      disableModalOutsideClick
      maxWidth="sm"
      scroll="paper"
      sx={{
        '.MuiPaper-root': { maxHeight: '80vh' },
        ...sx
      }}
      closeButtonColor="#0000008A"
      titleStyles={titleStyles}
      title={title}
      footer={
        footer || (
          <>
            <DeclineButton onClick={() => handleCancelNavigation()} />
            <AcceptButton
              onClick={() => {
                onConfirmNavigation();
                confirmNavigation();
              }}
            />
          </>
        )
      }
    >
      {children}
    </Modal>
  );
};

export default ConfirmLeavingModal;

const DeclineButton = ({ onClick }: { onClick: React.MouseEventHandler<HTMLButtonElement> }) => (
  <Button
    variant="text"
    onClick={onClick}
    sx={{
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      textAlign: 'right',
      fontWeight: '500'
    }}
  >
    NO
  </Button>
);

const AcceptButton = ({ onClick }: { onClick: React.MouseEventHandler<HTMLButtonElement> }) => (
  <Button
    variant="contained"
    color="secondary"
    onClick={onClick}
    sx={{
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      textAlign: 'right',
      fontWeight: '500'
    }}
  >
    YES
  </Button>
);
