import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { excelColors } from 'styles/theme/colors';

const { header, activeRow, alternateRow } = excelColors;
export const getExcelFromJson = ({ columns = [], data = [], fileName = 'sheet' }) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  worksheet.columns = columns;
  worksheet.addRows(data);

  worksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell) => {
      let activeBg = header;
      if (rowNumber !== 1) activeBg = rowNumber % 2 === 0 ? activeRow : alternateRow;

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: activeBg }
      };
    });
    row.commit();
  });

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileName}.xlsx`);
  });
};
