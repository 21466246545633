const realTimeLogsDataGridStyles = {
  marginBottom: 1,
  '.MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '.file.MuiDataGrid-row': {
    height: 110,
    maxHeight: '150px !important'
  },
  '.MuiDataGrid-cell': {
    maxHeight: '100% !important',
    height: '100%'
  }
};

export default realTimeLogsDataGridStyles;
