import { useState } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import {
  getNewformaFolders as GET_NEWFORMA_FOLDERS,
  getNewformaProjects as GET_NEWFORMA_PROJECTS
} from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DROPDOWN_DEFAULT_PG_SIZE, MAX_DROPDOWN_ITEMS_IN_VIEW } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import FiltersAutocompleteDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FiltersAutocompleteDropdown';
import DropdownFormControl from 'modules/Sync/WorkflowEditor/Utils/DropdownFormControl';

import NewformaFoldersDropdown from './NewformaFoldersDropdown';
import { getNewformaSelectEventHandlers } from './newformaHelpers';
import { useNewformaMountHook } from './useNewformaMountHook';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_FIRST;

const Newforma = ({
  fileTypeFilters = [],
  connectionId = '',
  connectionType = '',
  nodeId = '',
  showFilters = false,
  isSource = false
}) => {
  const { getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const [parentFolderId, setParentFolderId] = useState('');

  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { project, folders = [], uiFolders = [] } = formValues;

  const [
    { paginatedLazyLoad: lazyLoadProjects, paginationHandler: projectsPaginationHandler },
    { loading: isLoadingProjects, data: { getNewformaProjects: NewformaProjects = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_NEWFORMA_PROJECTS), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const onScrollProjects = () => projectsPaginationHandler(NewformaProjects?.length, { connectionId });

  const fetchProjectsOnLoad = () => {
    if (project?.id) {
      lazyLoadProjects({ skip: null, take: null, connectionId });
    } else {
      lazyLoadProjects({ skip: NewformaProjects?.length, connectionId });
    }
  };

  const [
    { lazyLoad: loadFolders, paginationHandler: fetchMoreRootFolders },
    { loading: isLoadingFolders, data: { getNewformaFolders: NewformaFolders = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_NEWFORMA_FOLDERS), FETCH_POLICY, DROPDOWN_DEFAULT_PG_SIZE);

  const fetchOnMountNewformaFolders = project?.id && !isLoadingProjects && NewformaProjects?.length;

  const updateFormValues = (props) => updateConnectorNodeValues({ nodeId, isSource, props });

  const setParentFolderIdOnStackLoad = (parentFolderId) => setParentFolderId(parentFolderId);

  const { selectProjectHandler, selectFoldersHandler } = getNewformaSelectEventHandlers({
    updateFormValues,
    loadFolders,
    folders,
    uiFolders,
    connectionId,
    parentFolderId,
    setParentFolderId
  });

  useNewformaMountHook({
    connectionId,
    connectionType,
    nodeId,
    project,
    formValues,
    updateFormValues,
    loadFolders,
    fetchOnMountNewformaFolders,
    NewformaProjects,
    fetchProjectsOnLoad
  });

  return (
    <Stack spacing={2}>
      <DropdownFormControl
        title="Project"
        id="project"
        labelId="project"
        label="project"
        value={project?.id}
        onChange={selectProjectHandler}
        loading={isLoadingProjects}
        items={NewformaProjects}
        fetchPaginatedRecords={onScrollProjects}
        required
      />
      <NewformaFoldersDropdown
        folders={NewformaFolders}
        connectionId={connectionId}
        isLoadingFolders={isLoadingFolders}
        disabled={isLoadingProjects || !project?.id || !NewformaProjects?.length}
        onSelect={selectFoldersHandler}
        isSource={isSource}
        nodeId={nodeId}
        multiple={isSource}
        onFolderStackLoad={setParentFolderIdOnStackLoad}
        fetchMoreRootFolders={fetchMoreRootFolders}
        required
      />
      <FiltersAutocompleteDropdown showFilters={showFilters} fileTypeFilters={fileTypeFilters} isSource={isSource} />
    </Stack>
  );
};

export default Newforma;
