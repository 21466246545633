import { useEffect, useMemo, useState } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useLocation, useParams } from 'react-router-dom';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import { getTimeZone } from 'helpers/dateFunctions';
import { LOGS_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { IsSyncFinished } from 'modules/Sync/helpers/logSyncStatusHelpers';
import useEvictApolloCache from 'modules/Sync/hooks/useEvictApolloCache';
import DownloadSyncLogsModal from 'modules/Sync/Logs/components/DownloadSyncLogsModal';
import useDownloadLogsHook from 'modules/Sync/Logs/components/useDownloadLogsHook';
import { LOGS_DETAILS_APIS, useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import useLazyLoadLogsActionDetailsHook from 'modules/Sync/Logs/Contexts/useLazyLoadLogsActionDetailsHook';
import useLogsDetailsLazyPaginationHook from 'modules/Sync/Logs/Contexts/useLogsDetailsLazyPaginationHook';
import { getLogFileName } from 'modules/Sync/Logs/DownloadLogsService';
import FileDetailsDataGridTable from 'modules/Sync/Logs/LogsDetails/FileDetailsDataGridTable';
import LogFoldersDetailsDataGridTable from 'modules/Sync/Logs/LogsDetails/LogFoldersDetailsDataGridTable';
import LogDetailsHeader from 'modules/Sync/Logs/LogsDetails/LogsDetailsHeader';

const LogDetails = () => {
  const { logsDetailsSearchPhrase, setLogsDetailsSearchPhrase, setActiveModal, logFilter, activeModal } =
    useSyncLogsContext();
  const open = activeModal === LOGS_MODAL_KEYS.DOWNLOAD_SYNC_LOGS;

  const {
    startLogActionDetailsPolling,
    stopLogActionDetailsPolling,
    loadLogActionDetails,
    logActionDetailsData,
    loadingLogActionDetails
  } = useLazyLoadLogsActionDetailsHook();
  const {
    lazyLoadLogsDetails,
    logsDetailsPaginationHandler,
    logsDetailsSearchHandler,
    refetchExistingLogsDetailsPages,
    loadingLogDetails,
    logDetails
  } = useLogsDetailsLazyPaginationHook();

  const actionDetailsDestinations = logActionDetailsData?.syncLogActionDetails?.data?.destinations || [];
  const actionDetailsSyncStatus = logActionDetailsData?.syncLogActionDetails?.syncStatus;
  const syncInProgress = actionDetailsDestinations.length ? !IsSyncFinished(actionDetailsSyncStatus) : false;
  const apiRef = useGridApiRef();
  const timeZone = useMemo(() => getTimeZone(), []);

  const { id: fileSyncLogId = '' } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [debugging, setDebugging] = useState(false);
  const [autoRefreshFolderDetails, setAutoRefreshFolderDetails] = useState(false);
  const [manuallyLoadingLogs, setManuallyLoadingLogs] = useState(false);
  const { search, pathname } = useLocation();
  const [expandedRows, setExpandedRows] = useState([]);

  const { workflowName, mappingName } = logActionDetailsData?.syncLogActionDetails || {};
  const fileName = getLogFileName({ workflowName, mappingName, fileSyncLogId });
  const downloadDisabled = !(fileSyncLogId && workflowName && mappingName);

  const { downloadFile, progress } = useDownloadLogsHook({ fileSyncLogId, syncInProgress, fileName });

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const currentTab = Number(searchParams.get('activeTab'));
    if (currentTab) setActiveTab(currentTab);
    searchParams.delete('activeTab');
    window.history.replaceState({}, '', pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEvictApolloCache(LOGS_DETAILS_APIS, []);

  const searchHandler = (searchPhrase) => {
    setLogsDetailsSearchPhrase(searchPhrase);
    if (!activeTab) return null;
    return logsDetailsSearchHandler(searchPhrase, { fileSyncLogId, IsDebug: debugging, timeZone, logFilter });
  };

  const debugHandler = async (debug) => {
    setDebugging(!!debug);
    const variables = { fileSyncLogId, IsDebug: !!debug, searchPhrase: logsDetailsSearchPhrase, timeZone, logFilter };
    await lazyLoadLogsDetails(variables);
  };

  const hideAllModals = () => setActiveModal('');

  const toggleAutoRefreshFolderDetails = (autoRefresh) => setAutoRefreshFolderDetails(!!autoRefresh);

  const HEADER_PROPS = { logActionDetailsData, apiRef, expandedRows, setActiveModal, progress, logDetails, debugging };
  const OTHER_HEADER_PROPS = { activeTab, setActiveTab, searchHandler, debugHandler, syncInProgress };
  const FOLDER_GRID_PROPS = { apiRef, expandedRows, setExpandedRows, loadLogActionDetails, logActionDetailsData };

  return (
    <ComponentPaneLayout>
      <ItemsDataGridProProvider contextId={activeTab ? 'LogsFileDetailsTable' : 'LogsFoldersDetailsTable'}>
        <LogDetailsHeader
          autoRefreshFolderDetails={autoRefreshFolderDetails}
          loadingLogs={(activeTab ? loadingLogDetails : loadingLogActionDetails) || manuallyLoadingLogs}
          autoRefreshFolderDetailsHandler={toggleAutoRefreshFolderDetails}
          refetchExistingLogsDetailsPages={refetchExistingLogsDetailsPages}
          loadLogActionDetails={loadLogActionDetails}
          syncStatus={actionDetailsSyncStatus}
          {...HEADER_PROPS}
          {...OTHER_HEADER_PROPS}
        />
        {activeTab === 0 && (
          <LogFoldersDetailsDataGridTable
            autoRefreshFolderDetails={autoRefreshFolderDetails}
            startLogActionDetailsPolling={startLogActionDetailsPolling}
            stopLogActionDetailsPolling={stopLogActionDetailsPolling}
            setManuallyLoadingLogs={setManuallyLoadingLogs}
            {...FOLDER_GRID_PROPS}
          />
        )}
        {activeTab === 1 && (
          <FileDetailsDataGridTable
            debugging={debugging}
            logDetails={logDetails}
            logsDetailsPaginationHandler={logsDetailsPaginationHandler}
            loadingLogDetails={loadingLogDetails}
            lazyLoadLogsDetails={lazyLoadLogsDetails}
          />
        )}
        <DownloadSyncLogsModal
          onClose={hideAllModals}
          downloadFile={downloadFile}
          disabled={downloadDisabled}
          open={open}
        />
      </ItemsDataGridProProvider>
    </ComponentPaneLayout>
  );
};

export default LogDetails;
