import React, { useEffect, useMemo } from 'react';

import { Box } from '@mui/material';

import { CONNECTOR_NODE_TYPES } from 'modules/Sync/Constants/constants';
import { useManageIssuesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/ManageIssuesContext';
import ConnectorForm from 'modules/Sync/WorkflowEditor/Modals/ManageIssuesModal/ConnectorForm';

const MappingForm = ({ nodeId, filters = [], showFilters = false, allowPrivateFiles = false, isSource = false }) => {
  const { source, destinations, setCurrentConnectorType } = useManageIssuesFormContext();

  const connectorFormProps = useMemo(
    () => ({
      fileTypeFilters: filters || [],
      showFilters,
      allowPrivateFiles,
      isSource,
      nodeId
    }),
    [allowPrivateFiles, filters, nodeId, isSource, showFilters]
  );

  useEffect(() => {
    if (isSource) setCurrentConnectorType(CONNECTOR_NODE_TYPES.SOURCE);
    else setCurrentConnectorType(CONNECTOR_NODE_TYPES.DESTINATION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSource]);

  return (
    <>
      {isSource && (
        <ConnectorForm
          key={isSource ? source?.nodeId : destinations?.nodeId}
          isSource={isSource}
          defaultOption={isSource ? source?.nodeId : destinations?.nodeId}
          connectorFormProps={connectorFormProps}
        />
      )}
      {!isSource &&
        destinations.map(({ nodeId }, index) => (
          <Box
            sx={{
              marginTop: '10px'
            }}
          >
            <ConnectorForm
              key={nodeId}
              isSource={isSource}
              defaultOption={nodeId}
              connectorFormProps={connectorFormProps}
              label={`Destination ${index + 1}`}
            />
          </Box>
        ))}
    </>
  );
};

export default MappingForm;
