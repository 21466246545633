import { useEffect } from 'react';

import { getSubmittedStatus } from 'modules/Sync/WorkflowEditor/helpers/validationsHelper';

export const useForgeMountHook = ({
  connectionId,
  connectionType,
  nodeId,
  hub,
  formValues,
  updateFormValues,
  lazyLoadProjects,
  fetchOnMountForgeProjects,
  fetchHubsOnLoad
}) => {
  useEffect(() => {
    const isSubmitted = getSubmittedStatus(formValues);
    updateFormValues({ connectionId, connectionType, nodeId, isSubmitted });
    fetchHubsOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchOnMountForgeProjects) {
      lazyLoadProjects({ connectionId, hubId: hub?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnMountForgeProjects]);
};
