import React from 'react';

import { FormControlLabel, Checkbox, Grid, Stack, Collapse } from '@mui/material';

import { Custom } from 'components/Icons';
import { convertToUpperFirstCase } from 'helpers/stringFunctions';

const ROOT_FILTER_GROUP = 'Select all';

const styles = {
  componentProps: {
    typography: {
      sx: {
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        paddingLeft: 0,
        marginLeft: 0
      }
    }
  }
};

const AutoCompleteGroup = ({
  group,
  children,
  id,
  expandedFilterGroups,
  filters,
  defaultFilters,
  selectAllFiltersHandler,
  onFilterGroupTransition,
  filterGroups,
  selectFilterGroupHandler,
  options
}) => {
  const filterGroupExpanded = expandedFilterGroups.includes(group);
  const rootGroupExpanded = expandedFilterGroups.includes(ROOT_FILTER_GROUP);
  const groupLabel = convertToUpperFirstCase(group, '/', '/');
  const groupOptions = options?.filter(({ type }) => group === type);
  const selectedFiltersInGroupCount = groupOptions?.filter(({ name }) => filters?.includes(name))?.length;

  return (
    <Stack spacing={0}>
      {!id && (
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              color="warning"
              label={ROOT_FILTER_GROUP}
              checked={filters.length >= defaultFilters.length}
              indeterminate={filters.length && filters.length < defaultFilters.length}
              onChange={selectAllFiltersHandler}
              sx={{ pr: 0, pl: '1px' }}
            />
          }
          label={
            <span
              role="presentation"
              style={{ display: 'inline-block' }}
              onClick={(event) => onFilterGroupTransition(event, ROOT_FILTER_GROUP)}
            >
              <span style={{ position: 'relative', top: 5 }}>
                {rootGroupExpanded ? (
                  <Custom.KeyboardArrowRight height={20} width={16} style={{ transform: 'rotate(90deg)' }} />
                ) : (
                  <Custom.KeyboardArrowRight height={20} width={16} />
                )}
              </span>
              {ROOT_FILTER_GROUP}
            </span>
          }
          componentsProps={{
            typography: styles.componentProps.typography
          }}
          sx={{ mt: 0, marginLeft: 0, padding: 0, height: 20 }}
        />
      )}
      <Collapse in={rootGroupExpanded} appear={rootGroupExpanded} timeout="auto">
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              color="warning"
              label={group}
              checked={filterGroups.includes(group) && groupOptions.length === selectedFiltersInGroupCount}
              indeterminate={
                filterGroups.includes(group) &&
                selectedFiltersInGroupCount &&
                selectedFiltersInGroupCount < groupOptions.length
              }
              onChange={(e) => selectFilterGroupHandler(e, group)}
              sx={{ paddingRight: 0, paddingLeft: 0 }}
            />
          }
          label={
            <span role="presentation" onClick={(event) => onFilterGroupTransition(event, group)}>
              <span style={{ position: 'relative', top: 5 }}>
                {filterGroupExpanded ? (
                  <Custom.KeyboardArrowRight height={20} width={16} style={{ transform: 'rotate(90deg)' }} />
                ) : (
                  <Custom.KeyboardArrowRight height={20} width={16} />
                )}
              </span>
              {groupLabel}
            </span>
          }
          componentsProps={{
            typography: styles.componentProps.typography
          }}
          sx={{ margin: 0, marginLeft: 2, padding: 0, height: 20 }}
        />
        <Collapse in={filterGroupExpanded} appear={filterGroupExpanded} timeout="auto">
          <Grid container sx={{ margin: 0, paddingLeft: 2, paddingRight: 2 }}>
            {children}
          </Grid>
        </Collapse>
      </Collapse>
    </Stack>
  );
};

export default AutoCompleteGroup;
