import { gql, useMutation } from '@apollo/client';

import { syncRenameAction as SYNC_RENAME_ACTION } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useRenameAction = (queryOptions) => {
  const [renameAction] = useMutation(gql(SYNC_RENAME_ACTION), queryOptions);
  const { handleResponse } = useGraphqlResponseHandler();

  const renameActionAlias = ({
    mappingId,
    solitaryFlowId,
    actionAlias,
    onCompleted,
    onFailed,
    successMessage = 'Action successfully renamed'
  }) =>
    handleResponse(
      renameAction,
      {
        variables: {
          body: { MappingId: mappingId, SolitaryFlowId: solitaryFlowId, ActionAlias: actionAlias }
        }
      },
      { successMessage },
      onCompleted,
      onFailed
    );
  return { renameActionAlias };
};

export default useRenameAction;
