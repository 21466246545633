import React, { useEffect, useState } from 'react';

import { Stack, IconButton } from '@mui/material';
import MUIAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import { BORDER_COLOR } from 'components/Accordion/ControlledAccordion';
import { Custom } from 'components/Icons';
import CustomTooltip from 'components/Tooltip';
import DeleteMenuButton from 'modules/Sync/Connections/components/DeleteMenuButton';

const ImportAccordion = ({
  items = [],
  hideFirstBorder,
  hideLastBorder,
  sx,
  titleSx,
  infoSx,
  expand,
  isConnector = false
}) => {
  const [expanded, setExpanded] = useState(null);
  const handleChange = (panel) => (event, isExpanded) => {
    /* Input Elements can be added to the accordion with 'data-behavior': 'DO-NOT-EXPAND',
    this way the events on those elements won't cause changes in accordion behavior  */
    if (event?.target?.getAttribute('data-behavior') !== 'DO-NOT-EXPAND') {
      setExpanded(isExpanded ? panel : false);
    }
  };

  useEffect(() => {
    if (expand?.id > -1) setExpanded(expand.id);
  }, [expand]);

  if (!items.length) return null;

  return (
    <Stack
      sx={{
        '& .MuiAccordion-root': {
          position: 'unset',
          borderRadius: 0,
          boxShadow: 'none',
          borderBottom: BORDER_COLOR
        },
        '& .MuiAccordion-root:first-of-type': {
          borderTop: hideFirstBorder ? 'none' : BORDER_COLOR
        },
        '& .MuiAccordion-root:last-of-type': {
          borderBottom: hideLastBorder ? 'none' : BORDER_COLOR
        },
        ...sx
      }}
    >
      {items.map(({ id: itemId, ...item }) => (
        <MUIAccordion key={itemId} expanded={itemId === expanded} disableGutters onChange={handleChange(itemId)}>
          <AccordionSummary
            sx={{
              '.MuiAccordionSummary-content': { alignItems: 'center' },
              ...item?.summarySx
            }}
            expandIcon={<Custom.ExpandMore fill={sx?.svgFill} color={sx?.svgColor} fillOpacity={sx?.svgFillOpacity} />}
          >
            <Typography variant="body2" sx={titleSx}>
              {item?.title}
            </Typography>

            {item.warning && (
              <IconButton ml={1} title={item.warning}>
                <Custom.InfoOutlineIcon style={{ width: '18px', height: '18px' }} />
              </IconButton>
            )}

            {item.onDelete && (
              <>
                <DeleteMenuButton
                  onDelete={item.onDelete}
                  title={isConnector ? 'Delete connector' : 'Delete workflow'}
                />
                <CustomTooltip title="Refresh" arrow placement="top">
                  <IconButton mr={1} sx={{ marginRight: '10px' }} onClick={item?.onRefresh}>
                    <Custom.Refresh style={{ width: '16px', height: '16px' }} />
                  </IconButton>
                </CustomTooltip>
              </>
            )}

            {(item?.error || item?.errorMessage) && (
              <CustomTooltip title={item?.errorMessage} arrow placement="top">
                <Custom.Error style={{ marginRight: '10px' }} />
              </CustomTooltip>
            )}
            {item?.isAdded && <Custom.CheckCircleOutlinePrimary style={{ marginLeft: 'auto', marginRight: '10px' }} />}

            <Typography sx={infoSx}>{item.info}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ mt: 1 }}>{item.content}</AccordionDetails>
        </MUIAccordion>
      ))}
    </Stack>
  );
};

export default ImportAccordion;
