import React, { useState } from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { ControlledInputCheckbox } from 'components';
import InputSelectField from 'components/FormComponents/InputSelectField';
import { requiredRule } from 'constants/inputFieldRules';
import { ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { FORMATS } from 'modules/Sync/Constants/constants';

import RecommendTimeButton from './RecommendTimeButton';
import SuggestedTimeOptions from './SuggestTimeOptions';
import SyncDaysOptions from './SyncDaysOptions';

const numberInputPattern = { pattern: '[0-9]{1,2}' };
const requiredInputProps = { required: true, errorOnFocusOnly: true };

const ScheduleSyncFields = (props) => {
  const { onChangeHandler, defaultValues: { hours = 1, minutes = 0, format = FORMATS[0] } = {} } = props;
  const { recommendTime, syncOptionLabels, disableRecommendTime, loadingRecommendTime, disabled = false } = props;
  const methods = useFormContext();
  const { control, setValue } = methods;
  const [isTimeSet, setIsTimeSet] = useState(false);
  const [suggestedTimes, setSuggestedTime] = useState([]);

  const updateTimeSet = (availableSlots) => {
    setIsTimeSet(true);
    if (availableSlots) setSuggestedTime(availableSlots.slice(0, 3));
  };

  const updateTimeHandler = (data) => {
    onChangeHandler(data);
    setIsTimeSet(false);
    setValue(data.name, data.event.target.value);
  };

  const updateDaysHandler = ({ event, checked, name: day }) => {
    onChangeHandler({ event, checked, name: day });
    setIsTimeSet(false);
  };

  const recommendedTimeProps = { isTimeSet, setIsTimeSet, loadingRecommendTime, disabled: disableRecommendTime };
  return (
    <form>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Typography variant="subtitle2">{syncOptionLabels?.chooseDaysDescription}</Typography>
      <SyncDaysOptions updateDaysHandler={updateDaysHandler} control={control} disabled={disabled} />

      {recommendTime && (
        <RecommendTimeButton onClick={() => recommendTime({ callback: updateTimeSet })} {...recommendedTimeProps} />
      )}

      <Stack gap={0.1} sx={{ marginTop: 2 }}>
        <Typography variant="subtitle2">Start time</Typography>
        <Stack gap={2} direction="row">
          <ControlTextField
            name="hours"
            defaultValue={hours}
            onChange={(event) => updateTimeHandler({ event, name: 'hours' })}
            control={control}
            label="Hours"
            type="number"
            min={1}
            max={12}
            sx={{ width: '120px' }}
            disabled={disabled}
            inputProps={{ min: 1, max: 12, value: hours, ...numberInputPattern }}
            {...requiredInputProps}
          />
          <ControlTextField
            name="minutes"
            defaultValue={minutes}
            onChange={(event) => updateTimeHandler({ event, name: 'minutes' })}
            control={control}
            label="Minutes"
            type="number"
            min={0}
            max={59}
            sx={{ width: '120px' }}
            disabled={disabled}
            inputProps={{ min: 0, max: 59, value: minutes, ...numberInputPattern }}
            {...requiredInputProps}
          />
          <InputSelectField
            onSelectChange={(event) => updateTimeHandler({ event, name: 'format' })}
            name="format"
            control={control}
            size="small"
            fullWidth
            defaultValue={format}
            options={FORMATS.map((format) => ({ label: format, value: format }))}
            rules={requiredRule('format')}
            disabled={disabled}
            sx={{ width: '120px' }}
            formControlSx={{ '& .MuiTypography-root': { marginTop: '2px' } }}
            SelectProps={{ value: format }}
          />
        </Stack>

        {!!suggestedTimes.length && (
          <SuggestedTimeOptions
            suggestedTimes={suggestedTimes}
            recommendTime={recommendTime}
            updateTimeSet={updateTimeSet}
          />
        )}
        <ControlledInputCheckbox
          customCallBack={(event, checked) => onChangeHandler({ event, checked, name: 'syncRepeat' })}
          name="syncRepeat"
          control={control}
          label="Repeat"
          color="secondary"
          disabled={disabled}
          checkboxProps={{ disabled: true }}
          inputProps={{ min: 1, max: 12, ...numberInputPattern }}
          {...requiredInputProps}
        />
      </Stack>
    </form>
  );
};

export default ScheduleSyncFields;
