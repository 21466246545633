import { useEffect } from 'react';

import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { getSubmittedStatus } from 'modules/Sync/WorkflowEditor/helpers/validationsHelper';

export const useNewformaMountHook = ({
  connectionId,
  connectionType,
  nodeId,
  project,
  formValues,
  updateFormValues,
  loadFolders,
  fetchOnMountNewformaFolders,
  fetchProjectsOnLoad
}) => {
  useEffect(() => {
    const isSubmitted = getSubmittedStatus(formValues);
    updateFormValues({ connectionId, connectionType, nodeId, isSubmitted });

    fetchProjectsOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchOnMountNewformaFolders) {
      loadFolders({
        connectionId,
        projectId: Buffer.from(project?.id, 'utf-8').toString('base64'),
        skip: 0,
        take: DROPDOWN_DEFAULT_PG_SIZE
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnMountNewformaFolders]);
};
