import React, { useCallback } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FormControl, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { syncCreateMappingTemplate as SYNC_CREATE_MAPPING_TEMPLATE } from 'graphql/mutations';
import { syncValidateName as SYNC_VALIDATE_NAME } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { WORKFLOW_MODAL_KEYS, ROUTES, VALIDATION_ENTITY } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';

import MappingTextField from './MappingTextField';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';

const { SYNC, TEMPLATE_EDITOR } = ROUTES;

const defaultFormValues = { templateName: '' };

const SUCCESS_MESSAGE = 'Template successfully created';

const CreateTemplateModal = ({ onClose }) => {
  const { activeModal } = useSyncWorkflowsContext();
  const navigate = useNavigate();
  const open = activeModal === WORKFLOW_MODAL_KEYS.CREATE_TEMPLATE;
  const { handleResponse } = useGraphqlResponseHandler();

  const methods = useForm({ mode: 'all', defaultValues: defaultFormValues });
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors
  } = methods;
  const [createTemplate, { loading }] = useMutation(gql(SYNC_CREATE_MAPPING_TEMPLATE), {
    refetchQueries: ['SyncGetWorkflowMappingTemplatesList', 'SyncGetAllWorkflowMappingTemplates']
  });

  const [validateName, { errors: validationErrors }] = useLazyQuery(gql(SYNC_VALIDATE_NAME), {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: { params: { entity: VALIDATION_ENTITY.TEMPLATE } }
  });

  const redirectToWorkflowCanvas = (id) =>
    id ? navigate(`${SYNC}${TEMPLATE_EDITOR.replace(':id', id)}`) : '';

  const onModalClose = () => {
    onClose?.();
  };

  const onSubmit = handleSubmit((formValues) => {
    const { templateName } = formValues;

    return handleResponse(
      createTemplate,
      { variables: { body: { TemplateName: templateName } } },
      { successMessage: SUCCESS_MESSAGE },
      (response) => {
        const { syncCreateMappingTemplate: { templateId } = {} } = response || {};
        redirectToWorkflowCanvas(templateId);
      }
    );
  });

  const handleChange = useCallback(
    (searchName) => {
      validateName({
        variables: {
          query: {
            name: encodeURIComponent(searchName.trim())
          }
        }
      });
    },
    [validateName]
  );

  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={<ModalTitle title="Create template" />}
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={
        <ModalFooter
          loading={loading}
          onModalClose={onModalClose}
          onSubmit={onSubmit}
          onSubmitDisabled={!isValid}
        />
      }
    >
      <Stack gap={2}>
        <FormControl size="small" fullWidth>
          <MappingTextField
            errors={errors}
            control={control}
            disabled={loading}
            label="Template Name*"
            name="templateName"
            required
            sx={{ marginBottom: 0, marginTop: 0 }}
            setError={setError}
            clearErrors={clearErrors}
            validateChange={handleChange}
            validationErrors={validationErrors}
          />
        </FormControl>
      </Stack>
    </CustomModal>
  );
};

export default CreateTemplateModal;
