import React from 'react';

import { Stack, Typography } from '@mui/material';

import { actionColors, surfaceColors } from 'styles/theme/colors';

const SuggestedTimeOptions = ({ suggestedTimes, recommendTime, updateTimeSet }) => (
  <Stack gap={2} direction="row" mt={1}>
    <Typography variant="subtitle2">Suggested time: </Typography>
    <Stack gap={0.5} direction="row">
      {suggestedTimes.map((item) => (
        <Typography
          key={item}
          variant="subtitle2"
          sx={{
            color: actionColors.lightSurface.active,
            cursor: 'pointer',
            ':hover': { color: surfaceColors.lightSurface.primary, textDecoration: 'underline' }
          }}
          onClick={() => recommendTime({ callBack: updateTimeSet, suggestedTime: item })}
        >
          {item}
        </Typography>
      ))}
    </Stack>
  </Stack>
);

export default SuggestedTimeOptions;
