/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./types";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const syncDeleteConnection = /* GraphQL */ `mutation SyncDeleteConnection(
  $query: MutationSyncDeleteConnectionQueryInput
  $body: MutationSyncDeleteConnectionBodyInput
) {
  syncDeleteConnection(query: $query, body: $body) {
    connectionsInUsed {
      id
      name
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncDeleteConnectionMutationVariables,
  APITypes.SyncDeleteConnectionMutation
>;
export const syncCreateConnection = /* GraphQL */ `mutation SyncCreateConnection(
  $params: MutationSyncCreateConnectionParamsInput!
  $query: MutationSyncCreateConnectionQueryInput
  $body: MutationSyncCreateConnectionBodyInput
) {
  syncCreateConnection(params: $params, query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncCreateConnectionMutationVariables,
  APITypes.SyncCreateConnectionMutation
>;
export const syncUpdateConnection = /* GraphQL */ `mutation SyncUpdateConnection(
  $params: MutationSyncUpdateConnectionParamsInput!
  $query: MutationSyncUpdateConnectionQueryInput
  $body: MutationSyncUpdateConnectionBodyInput
) {
  syncUpdateConnection(params: $params, query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncUpdateConnectionMutationVariables,
  APITypes.SyncUpdateConnectionMutation
>;
export const syncCreateWorkflow = /* GraphQL */ `mutation SyncCreateWorkflow(
  $query: MutationSyncCreateWorkflowQueryInput
  $body: MutationSyncCreateWorkflowBodyInput
) {
  syncCreateWorkflow(query: $query, body: $body) {
    workflowId
    workflowName
    workflowMapping {
      workflowMappingId
      workflowMappingName
      isSyncRunning
      lastSyncAt
      lastSavedAt
      solitaryFlows {
        solitaryFlowId
        action
        actionNodeId
        actionAlias
        source {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          projects {
            id
            name
            folders {
              id
              name
              items {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          additionalData
          settings {
            isSyncEnabled
            includeExtensions
            secondaryFilesSetting
            isSyncAllRevisions
            syncType
            scheduleSyncConfig
            __typename
          }
          __typename
        }
        destinations {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          project {
            id
            name
            folder {
              id
              name
              __typename
            }
            __typename
          }
          additionalData
          __typename
        }
        syncStatus
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncCreateWorkflowMutationVariables,
  APITypes.SyncCreateWorkflowMutation
>;
export const syncRenameWorkflow = /* GraphQL */ `mutation SyncRenameWorkflow(
  $query: MutationSyncRenameWorkflowQueryInput
  $body: MutationSyncRenameWorkflowBodyInput
) {
  syncRenameWorkflow(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncRenameWorkflowMutationVariables,
  APITypes.SyncRenameWorkflowMutation
>;
export const syncDuplicateWorkflow = /* GraphQL */ `mutation SyncDuplicateWorkflow(
  $query: MutationSyncDuplicateWorkflowQueryInput
  $body: MutationSyncDuplicateWorkflowBodyInput
) {
  syncDuplicateWorkflow(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncDuplicateWorkflowMutationVariables,
  APITypes.SyncDuplicateWorkflowMutation
>;
export const syncCreateMapping = /* GraphQL */ `mutation SyncCreateMapping(
  $query: MutationSyncCreateMappingQueryInput
  $body: MutationSyncCreateMappingBodyInput
) {
  syncCreateMapping(query: $query, body: $body) {
    workflowId
    workflowName
    workflowMapping {
      workflowMappingId
      workflowMappingName
      isSyncRunning
      lastSyncAt
      lastSavedAt
      solitaryFlows {
        solitaryFlowId
        action
        actionNodeId
        actionAlias
        source {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          projects {
            id
            name
            folders {
              id
              name
              items {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          additionalData
          settings {
            isSyncEnabled
            includeExtensions
            secondaryFilesSetting
            isSyncAllRevisions
            syncType
            scheduleSyncConfig
            __typename
          }
          __typename
        }
        destinations {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          project {
            id
            name
            folder {
              id
              name
              __typename
            }
            __typename
          }
          additionalData
          __typename
        }
        syncStatus
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncCreateMappingMutationVariables,
  APITypes.SyncCreateMappingMutation
>;
export const syncRenameMapping = /* GraphQL */ `mutation SyncRenameMapping(
  $query: MutationSyncRenameMappingQueryInput
  $body: MutationSyncRenameMappingBodyInput
) {
  syncRenameMapping(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncRenameMappingMutationVariables,
  APITypes.SyncRenameMappingMutation
>;
export const syncDuplicateMapping = /* GraphQL */ `mutation SyncDuplicateMapping(
  $query: MutationSyncDuplicateMappingQueryInput
  $body: MutationSyncDuplicateMappingBodyInput
) {
  syncDuplicateMapping(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncDuplicateMappingMutationVariables,
  APITypes.SyncDuplicateMappingMutation
>;
export const syncDeleteWorkflow = /* GraphQL */ `mutation SyncDeleteWorkflow(
  $query: MutationSyncDeleteWorkflowQueryInput
  $body: MutationSyncDeleteWorkflowBodyInput
) {
  syncDeleteWorkflow(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncDeleteWorkflowMutationVariables,
  APITypes.SyncDeleteWorkflowMutation
>;
export const syncArchiveWorkflow = /* GraphQL */ `mutation SyncArchiveWorkflow(
  $query: MutationSyncArchiveWorkflowQueryInput
  $body: MutationSyncArchiveWorkflowBodyInput
) {
  syncArchiveWorkflow(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncArchiveWorkflowMutationVariables,
  APITypes.SyncArchiveWorkflowMutation
>;
export const syncRestoreWorkflow = /* GraphQL */ `mutation SyncRestoreWorkflow(
  $query: MutationSyncRestoreWorkflowQueryInput
  $body: MutationSyncRestoreWorkflowBodyInput
) {
  syncRestoreWorkflow(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncRestoreWorkflowMutationVariables,
  APITypes.SyncRestoreWorkflowMutation
>;
export const syncDeactivateWorkflow = /* GraphQL */ `mutation SyncDeactivateWorkflow(
  $query: MutationSyncDeactivateWorkflowQueryInput
  $body: MutationSyncDeactivateWorkflowBodyInput
) {
  syncDeactivateWorkflow(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncDeactivateWorkflowMutationVariables,
  APITypes.SyncDeactivateWorkflowMutation
>;
export const syncActivateWorkflow = /* GraphQL */ `mutation SyncActivateWorkflow(
  $query: MutationSyncActivateWorkflowQueryInput
  $body: MutationSyncActivateWorkflowBodyInput
) {
  syncActivateWorkflow(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncActivateWorkflowMutationVariables,
  APITypes.SyncActivateWorkflowMutation
>;
export const syncDeleteWebhook = /* GraphQL */ `mutation SyncDeleteWebhook(
  $query: MutationSyncDeleteWebhookQueryInput
  $body: MutationSyncDeleteWebhookBodyInput
) {
  syncDeleteWebhook(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncDeleteWebhookMutationVariables,
  APITypes.SyncDeleteWebhookMutation
>;
export const syncDeactivateWebhook = /* GraphQL */ `mutation SyncDeactivateWebhook(
  $query: MutationSyncDeactivateWebhookQueryInput
  $body: MutationSyncDeactivateWebhookBodyInput
) {
  syncDeactivateWebhook(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncDeactivateWebhookMutationVariables,
  APITypes.SyncDeactivateWebhookMutation
>;
export const syncActivateWebhook = /* GraphQL */ `mutation SyncActivateWebhook(
  $query: MutationSyncActivateWebhookQueryInput
  $body: MutationSyncActivateWebhookBodyInput
) {
  syncActivateWebhook(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncActivateWebhookMutationVariables,
  APITypes.SyncActivateWebhookMutation
>;
export const syncDeleteTemplate = /* GraphQL */ `mutation SyncDeleteTemplate(
  $query: MutationSyncDeleteTemplateQueryInput
  $body: MutationSyncDeleteTemplateBodyInput
) {
  syncDeleteTemplate(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncDeleteTemplateMutationVariables,
  APITypes.SyncDeleteTemplateMutation
>;
export const syncRenameTemplate = /* GraphQL */ `mutation SyncRenameTemplate(
  $query: MutationSyncRenameTemplateQueryInput
  $body: MutationSyncRenameTemplateBodyInput
) {
  syncRenameTemplate(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncRenameTemplateMutationVariables,
  APITypes.SyncRenameTemplateMutation
>;
export const syncDuplicateTemplate = /* GraphQL */ `mutation SyncDuplicateTemplate(
  $query: MutationSyncDuplicateTemplateQueryInput
  $body: MutationSyncDuplicateTemplateBodyInput
) {
  syncDuplicateTemplate(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncDuplicateTemplateMutationVariables,
  APITypes.SyncDuplicateTemplateMutation
>;
export const createAutodeskFieldFolder = /* GraphQL */ `mutation CreateAutodeskFieldFolder(
  $query: MutationCreateAutodeskFieldFolderQueryInput
  $body: MutationCreateAutodeskFieldFolderBodyInput
) {
  createAutodeskFieldFolder(query: $query, body: $body) {
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAutodeskFieldFolderMutationVariables,
  APITypes.CreateAutodeskFieldFolderMutation
>;
export const createAutodeskForgeFolder = /* GraphQL */ `mutation CreateAutodeskForgeFolder(
  $query: MutationCreateAutodeskForgeFolderQueryInput
  $body: MutationCreateAutodeskForgeFolderBodyInput
) {
  createAutodeskForgeFolder(query: $query, body: $body) {
    success
    name
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAutodeskForgeFolderMutationVariables,
  APITypes.CreateAutodeskForgeFolderMutation
>;
export const createProcoreFolder = /* GraphQL */ `mutation CreateProcoreFolder(
  $params: MutationCreateProcoreFolderParamsInput!
  $query: MutationCreateProcoreFolderQueryInput
  $body: MutationCreateProcoreFolderBodyInput
) {
  createProcoreFolder(params: $params, query: $query, body: $body) {
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProcoreFolderMutationVariables,
  APITypes.CreateProcoreFolderMutation
>;
export const createSharepointFolder = /* GraphQL */ `mutation CreateSharepointFolder(
  $query: MutationCreateSharepointFolderQueryInput
  $body: MutationCreateSharepointFolderBodyInput
) {
  createSharepointFolder(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.CreateSharepointFolderMutationVariables,
  APITypes.CreateSharepointFolderMutation
>;
export const createNewformaFolder = /* GraphQL */ `mutation CreateNewformaFolder(
  $query: MutationCreateNewformaFolderQueryInput
  $body: MutationCreateNewformaFolderBodyInput
) {
  createNewformaFolder(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.CreateNewformaFolderMutationVariables,
  APITypes.CreateNewformaFolderMutation
>;
export const syncUpdateWorkflowCanvas = /* GraphQL */ `mutation SyncUpdateWorkflowCanvas(
  $query: MutationSyncUpdateWorkflowCanvasQueryInput
  $body: MutationSyncUpdateWorkflowCanvasBodyInput
) {
  syncUpdateWorkflowCanvas(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncUpdateWorkflowCanvasMutationVariables,
  APITypes.SyncUpdateWorkflowCanvasMutation
>;
export const syncUpdateMappingConfigurations = /* GraphQL */ `mutation SyncUpdateMappingConfigurations(
  $query: MutationSyncUpdateMappingConfigurationsQueryInput
  $body: MutationSyncUpdateMappingConfigurationsBodyInput
) {
  syncUpdateMappingConfigurations(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncUpdateMappingConfigurationsMutationVariables,
  APITypes.SyncUpdateMappingConfigurationsMutation
>;
export const syncUpdatePublishActionConfigurations = /* GraphQL */ `mutation SyncUpdatePublishActionConfigurations(
  $query: MutationSyncUpdatePublishActionConfigurationsQueryInput
  $body: MutationSyncUpdatePublishActionConfigurationsBodyInput
) {
  syncUpdatePublishActionConfigurations(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncUpdatePublishActionConfigurationsMutationVariables,
  APITypes.SyncUpdatePublishActionConfigurationsMutation
>;
export const syncUpdateManageIssuesConfigurations = /* GraphQL */ `mutation SyncUpdateManageIssuesConfigurations(
  $query: MutationSyncUpdateManageIssuesConfigurationsQueryInput
  $body: MutationSyncUpdateManageIssuesConfigurationsBodyInput
) {
  syncUpdateManageIssuesConfigurations(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncUpdateManageIssuesConfigurationsMutationVariables,
  APITypes.SyncUpdateManageIssuesConfigurationsMutation
>;
export const syncCreateMappingTemplate = /* GraphQL */ `mutation SyncCreateMappingTemplate(
  $query: MutationSyncCreateMappingTemplateQueryInput
  $body: MutationSyncCreateMappingTemplateBodyInput
) {
  syncCreateMappingTemplate(query: $query, body: $body) {
    templateId
    templateName
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncCreateMappingTemplateMutationVariables,
  APITypes.SyncCreateMappingTemplateMutation
>;
export const syncWorkflowsImport = /* GraphQL */ `mutation SyncWorkflowsImport(
  $query: MutationSyncWorkflowsImportQueryInput
  $body: MutationSyncWorkflowsImportBodyInput
) {
  syncWorkflowsImport(query: $query, body: $body) {
    workflows {
      workflowName
      workflowId
      workflowError
      mappings {
        mappingName
        mappingId
        canvasNode
        mappingError
        solitaryFlowConfigurations {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          source {
            syncDetailsId
            nodeId
            connectionId
            hub {
              id
              name
              __typename
            }
            projects {
              id
              name
              folders {
                id
                name
                items {
                  id
                  name
                  __typename
                }
                __typename
              }
              __typename
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
              __typename
            }
            connectionName
            __typename
          }
          destinations {
            syncDetailsId
            nodeId
            connectionId
            hub {
              id
              name
              __typename
            }
            project {
              id
              name
              folder {
                id
                name
                __typename
              }
              __typename
            }
            additionalData
            connectionName
            __typename
          }
          solitaryFlowError
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncWorkflowsImportMutationVariables,
  APITypes.SyncWorkflowsImportMutation
>;
export const syncMultipleWorkflows = /* GraphQL */ `mutation SyncMultipleWorkflows(
  $query: MutationSyncMultipleWorkflowsQueryInput
  $body: MutationSyncMultipleWorkflowsBodyInput
) {
  syncMultipleWorkflows(query: $query, body: $body) {
    workflows {
      workflowId
      mappings {
        mappingId
        solitaryFlowSettings {
          solitaryFlowId
          isSyncEnabled
          syncType
          scheduleSyncConfig
          syncStatus
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncMultipleWorkflowsMutationVariables,
  APITypes.SyncMultipleWorkflowsMutation
>;
export const syncMigratedWorkflows = /* GraphQL */ `mutation SyncMigratedWorkflows(
  $query: MutationSyncMigratedWorkflowsQueryInput
  $body: MutationSyncMigratedWorkflowsBodyInput
) {
  syncMigratedWorkflows(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncMigratedWorkflowsMutationVariables,
  APITypes.SyncMigratedWorkflowsMutation
>;
export const syncDeleteMappingData = /* GraphQL */ `mutation SyncDeleteMappingData(
  $query: MutationSyncDeleteMappingDataQueryInput
  $body: MutationSyncDeleteMappingDataBodyInput
) {
  syncDeleteMappingData(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncDeleteMappingDataMutationVariables,
  APITypes.SyncDeleteMappingDataMutation
>;
export const syncCreateTemplateFromMapping = /* GraphQL */ `mutation SyncCreateTemplateFromMapping(
  $query: MutationSyncCreateTemplateFromMappingQueryInput
  $body: MutationSyncCreateTemplateFromMappingBodyInput
) {
  syncCreateTemplateFromMapping(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncCreateTemplateFromMappingMutationVariables,
  APITypes.SyncCreateTemplateFromMappingMutation
>;
export const syncDeleteMappingFlow = /* GraphQL */ `mutation SyncDeleteMappingFlow(
  $query: MutationSyncDeleteMappingFlowQueryInput
  $body: MutationSyncDeleteMappingFlowBodyInput
) {
  syncDeleteMappingFlow(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncDeleteMappingFlowMutationVariables,
  APITypes.SyncDeleteMappingFlowMutation
>;
export const syncFlowNow = /* GraphQL */ `mutation SyncFlowNow(
  $query: MutationSyncFlowNowQueryInput
  $body: MutationSyncFlowNowBodyInput
) {
  syncFlowNow(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncFlowNowMutationVariables,
  APITypes.SyncFlowNowMutation
>;
export const syncManageIssueNow = /* GraphQL */ `mutation SyncManageIssueNow(
  $query: MutationSyncManageIssueNowQueryInput
  $body: MutationSyncManageIssueNowBodyInput
) {
  syncManageIssueNow(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncManageIssueNowMutationVariables,
  APITypes.SyncManageIssueNowMutation
>;
export const syncMultipleFlowNow = /* GraphQL */ `mutation SyncMultipleFlowNow(
  $query: MutationSyncMultipleFlowNowQueryInput
  $body: MutationSyncMultipleFlowNowBodyInput
) {
  syncMultipleFlowNow(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncMultipleFlowNowMutationVariables,
  APITypes.SyncMultipleFlowNowMutation
>;
export const syncStopAll = /* GraphQL */ `mutation SyncStopAll(
  $query: MutationSyncStopAllQueryInput
  $body: MutationSyncStopAllBodyInput
) {
  syncStopAll(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncStopAllMutationVariables,
  APITypes.SyncStopAllMutation
>;
export const syncSaveAll = /* GraphQL */ `mutation SyncSaveAll(
  $query: MutationSyncSaveAllQueryInput
  $body: MutationSyncSaveAllBodyInput
) {
  syncSaveAll(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncSaveAllMutationVariables,
  APITypes.SyncSaveAllMutation
>;
export const syncMakeForesiteFolder = /* GraphQL */ `mutation SyncMakeForesiteFolder(
  $query: MutationSyncMakeForesiteFolderQueryInput
  $body: MutationSyncMakeForesiteFolderBodyInput
) {
  syncMakeForesiteFolder(query: $query, body: $body) {
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncMakeForesiteFolderMutationVariables,
  APITypes.SyncMakeForesiteFolderMutation
>;
export const syncCreateWindowsFolder = /* GraphQL */ `mutation SyncCreateWindowsFolder(
  $query: MutationSyncCreateWindowsFolderQueryInput
  $body: MutationSyncCreateWindowsFolderBodyInput
) {
  syncCreateWindowsFolder(query: $query, body: $body) {
    success
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncCreateWindowsFolderMutationVariables,
  APITypes.SyncCreateWindowsFolderMutation
>;
export const syncRenameAction = /* GraphQL */ `mutation SyncRenameAction(
  $query: MutationSyncRenameActionQueryInput
  $body: MutationSyncRenameActionBodyInput
) {
  syncRenameAction(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncRenameActionMutationVariables,
  APITypes.SyncRenameActionMutation
>;
export const syncRealtimeLogsRetry = /* GraphQL */ `mutation SyncRealtimeLogsRetry(
  $query: MutationSyncRealtimeLogsRetryQueryInput
  $body: MutationSyncRealtimeLogsRetryBodyInput
) {
  syncRealtimeLogsRetry(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncRealtimeLogsRetryMutationVariables,
  APITypes.SyncRealtimeLogsRetryMutation
>;
export const syncLogsRetry = /* GraphQL */ `mutation SyncLogsRetry(
  $query: MutationSyncLogsRetryQueryInput
  $body: MutationSyncLogsRetryBodyInput
) {
  syncLogsRetry(query: $query, body: $body)
}
` as GeneratedMutation<
  APITypes.SyncLogsRetryMutationVariables,
  APITypes.SyncLogsRetryMutation
>;
