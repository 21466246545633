import { useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';

import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { LOG_FILE_DOWNLOAD_BATCH_SIZE, WORKFLOW_TABLE_ENTITIES } from 'modules/Sync/Constants/constants';
import { createZipAndDownloadLogs, getLogsFromAPI, saveLogFileToPC } from 'modules/Sync/Logs/DownloadLogsService';

const AUTO_HIDE_DURATION = 2500;

const useDownloadLogsHook = (props) => {
  const { fileSyncLogId, solitaryFlows = [] } = props;
  const { syncInProgress = false } = props;
  const { fileName = '', /* single log (.txt) file name */ zipName = '' } = props;
  let singleFileLogs = [];
  let multipleFilesLogs = {};
  const [progress, setProgress] = useState(0);
  const isComponentMounted = useRef(true);
  const { enqueueSnackbar } = useSnackbar() || {};

  useEffect(
    () => () => {
      isComponentMounted.current = false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const downloadFile = async (variables = {}) => {
    setProgress(1);
    if (solitaryFlows.length > 0) downloadMultipleFiles(variables);
    else downloadSingleFileLogs(singleFileLogs.length, { fileSyncLogId, ...variables });
  };

  const downloadSingleFileLogs = async (skip, requestType, logsFetchedFor = 'action') => {
    if (!isComponentMounted.current) return;

    const IsDebug = false;
    const { entireCount = 0, data = null } =
      (await getLogsFromAPI(requestType, IsDebug, skip, syncInProgress, LOG_FILE_DOWNLOAD_BATCH_SIZE)) || {};

    if (!data?.length) {
      if (logsFetchedFor !== WORKFLOW_TABLE_ENTITIES.MAPPING) {
        /* Only for action logs download */
        enqueueSnackbar(`No logs found for this ${logsFetchedFor}`, {
          autoHideDuration: AUTO_HIDE_DURATION,
          ...VARIANT_ERROR
        });
        setProgress(0);
      }
      return;
    }

    const logsData = data?.map(({ logMessage }) => logMessage);
    if (solitaryFlows.length === 0) {
      singleFileLogs = [...singleFileLogs, ...logsData];
      const fetchedLogsLength = singleFileLogs?.length;
      if (entireCount / fetchedLogsLength > 1) {
        setProgress((100 * fetchedLogsLength) / entireCount);
        await downloadSingleFileLogs(fetchedLogsLength, requestType);
      } else {
        setProgress(0);
        saveLogFileToPC(singleFileLogs, fileName);
      }
    } else {
      const typeId = requestType?.fileSyncLogId || requestType?.solitaryFlowId;
      multipleFilesLogs[typeId].entireCount = entireCount;
      multipleFilesLogs[typeId].logsFetched = [...multipleFilesLogs[typeId].logsFetched, ...logsData];
      const fetchedLogsLength = multipleFilesLogs[typeId].logsFetched.length;
      if (entireCount / fetchedLogsLength > 1) {
        setProgress((100 * fetchedLogsLength) / entireCount);
        await downloadSingleFileLogs(fetchedLogsLength, requestType);
      } else Promise.resolve();
    }
  };

  const downloadMultipleFiles = async (variables) => {
    delete variables?.fileSyncLogId;
    const logFileNamePrefix = fileName.replace('.txt', '');
    multipleFilesLogs = solitaryFlows.reduce((acc, { solitaryFlowId, actionAlias = '' }) => {
      acc[solitaryFlowId] = { entireCount: 0, logsFetched: [], actionAlias };
      return acc;
    }, {});
    const allLogPromises = [];
    Object.keys(multipleFilesLogs).forEach(async (solitaryFlowId) => {
      allLogPromises.push(
        downloadSingleFileLogs(
          multipleFilesLogs[solitaryFlowId].logsFetched.length,
          { solitaryFlowId, ...variables },
          WORKFLOW_TABLE_ENTITIES.MAPPING
        )
      );
    });
    Promise.all(allLogPromises).then(() => {
      setProgress(0);
      const totalLogsCount = Object.values(multipleFilesLogs).reduce(
        (totalLogsCount, { entireCount = 0 }) => totalLogsCount + entireCount,
        0
      );

      if (totalLogsCount) return createZipAndDownloadLogs(multipleFilesLogs, zipName, logFileNamePrefix);

      return enqueueSnackbar('No logs found for this mapping', {
        autoHideDuration: AUTO_HIDE_DURATION,
        ...VARIANT_ERROR
      });
    });
  };

  return { downloadFile, progress };
};

export default useDownloadLogsHook;
