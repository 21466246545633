import { gql, useMutation } from '@apollo/client';

import { syncCreateConnection as POST_CONNECTION, syncUpdateConnection as PATCH_CONNECTION } from 'graphql/mutations';
import { dispatchConnectionGridEvent } from 'helpers/domHelpers';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { CONNECTION_KEY_API_PARAMS_MAPPING } from 'modules/Sync/Constants/constants';

const useUpsertConnectionMutation = () => {
  const { currentConnection, setCurrentConnection } = useSyncConnectionsContext();

  const { handleResponse } = useGraphqlResponseHandler();
  const [addConnection, { loading: creating }] = useMutation(gql(POST_CONNECTION));
  const [updateConnection, { loading: updating }] = useMutation(gql(PATCH_CONNECTION));

  const formatError = (error) => error || 'Something went wrong';
  const handleErrors = (response, onError) => {
    dispatchConnectionGridEvent()
    
    if (response?.syncUpdateConnection === true || response?.syncCreateConnection === true) {
      return setCurrentConnection();
    }
    if (response?.syncUpdateConnection === false) {
      return onError(formatError(response?.syncUpdateConnection));
    }

    if (response?.syncCreateConnection === false) {
      return onError(formatError(response?.syncCreateConnection));
    }
    return onError(response || '');
  };

  const upsertConnection = async ({ formData, authenticationCode, connectionType, onError, onSuccess }) => {
    const connectionId = currentConnection?.connectionId;
    const connectionTypeParam = CONNECTION_KEY_API_PARAMS_MAPPING[connectionType];
    const submitConnectionDTO = {
      ConnectionName: formData.connectionName,
      ...(formData.configuration && {
        Configuration: Buffer.from(
          `${JSON.stringify({
            ...formData.configuration
          })}`,
          'utf-8'
        ).toString('base64')
      }),
      ...(authenticationCode && { AuthenticationCode: authenticationCode })
    };
    if (connectionId) {
      await handleResponse(
        updateConnection,
        {
          variables: {
            params: { connection: connectionTypeParam },
            query: { connectionId },
            body: { ...submitConnectionDTO }
          }
        },
        {
          successMessage: 'Connector successfully updated'
        },
        (response) => handleErrors(response, onError),
        (response) => handleErrors(response, onError)
      );
    } else {
      const { errors } = await handleResponse(
        addConnection,
        {
          variables: {
            params: { connection: connectionTypeParam },
            body: { ...submitConnectionDTO, DesktopConnectionId: formData?.DesktopConnectionId }
          }
        },
        {
          successMessage: 'Connector successfully created'
        },
        (response) => handleErrors(response, onError),
        (response) => handleErrors(response, onError)
      );
      if (errors) {
        onError(errors);
      } else if (onSuccess) onSuccess(formData?.DesktopConnectionId, connectionType, formData?.connectionName);
    }
  };
  return { upsertConnection, loading: creating || updating };
};

export default useUpsertConnectionMutation;
