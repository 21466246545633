import React from 'react';

import Typography from '@mui/material/Typography';

import CustomModal from 'modules/Sync/components/CustomModal';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';

const UnmapActionNodeModal = ({ open, onClose, onSubmit }) => (
  <CustomModal
    disableModalOutsideClick
    open={open}
    onClose={onClose}
    title="Confirm unmapping"
    reducePadding
    titleStyles={{ fontWeight: 'bold' }}
    maxWidth="false"
    footer={
      <ModalFooter
        onModalClose={onClose}
        cancelBtnText="No"
        createBtnText="Confirm"
        onSubmit={onSubmit}
        cancelBtnSx={{ minWidth: 4 }}
        isDelete
      />
    }
  >
    <Typography variant="body1" mb="12px" sx={{ width: '500px', mb: '20px' }}>
      Unmapping will lose all previously mapped data. Are you sure you want to unmap the node?
    </Typography>
  </CustomModal>
);

export default UnmapActionNodeModal;
