import React, { useRef, useState } from 'react';

import { Box, Button, TextField, Typography } from '@mui/material';

const FileInput = ({ label, onChange, error, fileName, multiple, disabled }) => {
  const ref = useRef();
  const [attachment, setAttachment] = useState(null);

  const handleChange = (event) => {
    const files = Array.from(event?.target?.files);
    const [file] = files;
    if (!file) return;
    setAttachment(files);
    if (onChange) onChange(multiple ? files : file);
    event.target.value = '';
  };

  const fileNames = attachment?.map((item) => item.name).join(', ');

  return (
    <Box
      position="relative"
      sx={{
        color: (theme) => (error ? theme.palette.error.main : theme.palette.background.paper),
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
        height: '40px'
      }}
    >
      <TextField
        variant="outlined"
        sx={{
          '& .MuiFormLabel-root.Mui-disabled': {
            color: (theme) => theme.palette.text.secondary
          },
          '.MuiOutlinedInput-root': {
            height: '40px'
          },
          mt: 0
        }}
        margin="normal"
        fullWidth
        disabled
        label={label}
        value={fileName || fileNames || ''}
        error={!!error}
        helperText={error?.message || ' '}
        placeholder="Select a configuration file"
      />

      <Button
        variant="outlined"
        color="secondary"
        sx={{
          height: 40,
          color: 'secondary.main',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          transition: '0.2s',
          overflow: 'hidden',
          ':hover': { textDecorationColor: 'secondary.main' }
        }}
        component="label"
        onKeyDown={(e) => e.keyCode === 32 && ref.current?.click()}
        disabled={disabled}
      >
        <Typography fontWeight={500} fontSize={14}>
          Browse
        </Typography>
        <input ref={ref} type="file" accept="application/JSON" hidden onChange={handleChange} multiple={multiple} />
      </Button>
    </Box>
  );
};

export default FileInput;
