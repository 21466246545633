import React from 'react';

import { useTheme } from '@mui/material';

import ListFlyoutPaper from 'components/ListFlyoutPaper';
import { greyColors } from 'styles/theme/colors';

const StyledPopup = ({ onClose, children, sx = {} }) => {
  const {
    mixins: { toolbar },
    breakpoints
  } = useTheme();
  const toolbarDesktopQuery = breakpoints.up('sm');
  const topBarHeight = toolbar[toolbarDesktopQuery]?.minHeight + 16;

  return (
    <ListFlyoutPaper
      onClose={onClose}
      sx={{
        height: `calc(90vh - ${topBarHeight}px)`,
        top: 'auto',
        bottom: '8px',
        zIndex: 'auto',
        '*::-webkit-scrollbar-track': {
          backgroundColor: greyColors.grey800
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: greyColors.grey400
        },
        '.MuiStack-root': {
          scrollbarColor: `${greyColors.grey400} ${greyColors.grey800}`,
          scrollbarWidth: 'thin'
        },
        ...sx
      }}
      useXButton
      topRightActionsSx={{ top: 8, right: 8 }}
    >
      {children}
    </ListFlyoutPaper>
  );
};

export default StyledPopup;
