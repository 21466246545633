import React from 'react';

import { Box } from '@mui/material';

import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import { LogoutButton } from 'modules/Sync/Connections/ConnectionProperties/FormFields';

export const ProcoreLogoutButton = ({ onReset }) => (
  <NavItemTooltip
    title={
      <div style={{ textAlign: 'center', lineHeight: '22px' }}>
        Select this button to logout of Procore.
        <br />
        A new window will open to log out,
        <br /> then log back in to reset your credentials for 360 Sync.
      </div>
    }
    placement="top"
  >
    <Box component="div">
      <LogoutButton
        onClick={onReset}
        variant="outlined"
        sx={{
          width: 100,
          fontWeight: 500,
          color: 'secondary.text',
          '&:hover': { backgroundColor: 'secondary.outlinedHoverBackground' }
        }}
      >
        LOGOUT
      </LogoutButton>
    </Box>
  </NavItemTooltip>
);
