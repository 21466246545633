import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { Box, CssBaseline } from '@mui/material';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { MemoryRouter } from 'react-router-dom';

import ErrorBoundary from 'app/ErrorBoundary';
import { UserProvider } from 'app/UserContext';
import { BrowserRouter as Router } from 'BrowserRouter';
import SyncRoutes from 'modules/Sync/SyncRoutes';
import { CustomThemeProvider } from 'styles';

const history = createBrowserHistory();

const SnackbarWrapper = (props) => (
  <Box sx={{ '& .SnackbarContent-root': { minWidth: 'unset !important' } }} {...props} />
);

const Notistack = ({ children }) => (
  <SnackbarWrapper>
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} maxSnack={3} autoHideDuration={3000}>
      {children}
    </SnackbarProvider>
  </SnackbarWrapper>
);

const AppWrapper = (props) => <Box sx={{ margin: '0 auto', height: '100vh' }} {...props} />;

const App = ({ serveLocal = false, client }) =>
    serveLocal ? (
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <UserProvider>
            <Router history={history}>
              <CustomThemeProvider>
                <CssBaseline />
                <AppWrapper>
                  <Notistack>
                    <SyncRoutes />
                  </Notistack>
                </AppWrapper>
              </CustomThemeProvider>
            </Router>
          </UserProvider>
        </ApolloProvider>
      </ErrorBoundary>
    ) : (
      <div id="micro-fe-app">
        <ErrorBoundary>
          <ApolloProvider client={client}>
            <UserProvider>
              <MemoryRouter>
                <CustomThemeProvider>
                  <CssBaseline />
                  <AppWrapper>
                    <Notistack>
                      <SyncRoutes />
                    </Notistack>
                  </AppWrapper>
                </CustomThemeProvider>
              </MemoryRouter>
            </UserProvider>
          </ApolloProvider>
        </ErrorBoundary>
      </div>
    );

export default App;
