import { gql } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncRealTimeLogs as SYNC_REAL_TIME_LOGS } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DATAGRID_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const useRealtimeLogsFileLazyPaginationHook = () => {
  const [
    {
      lazyLoad: lazyLoadRealTimeLogFiles,
      paginationHandler: realTimeLogFilesPaginationHandler,
      searchHandler: realTimeLogFilessSearchHandler,
      onOrderby: realTimeLogFilesSortHandler
    },
    { loading: loadingRealtimeLogFiles, data: realTimelogFilesData }
  ] = useLazyPaginatedQuery(gql(SYNC_REAL_TIME_LOGS), FETCH_POLICY, DATAGRID_DEFAULT_PG_SIZE, false);

  return {
    lazyLoadRealTimeLogFiles,
    realTimeLogFilesPaginationHandler,
    realTimeLogFilessSearchHandler,
    realTimeLogFilesSortHandler,
    loadingRealtimeLogFiles,
    realTimelogFilesData
  };
};

export default useRealtimeLogsFileLazyPaginationHook;
