import { useEffect } from 'react';

import {
  collapseAllRows,
  expandInitialRow,
  handleRowExpansionChange
} from 'modules/Sync/Logs/RealTimeLogsDataGridTable/realtimeDataGridHelpers';

const useRealtimeDataGridTableHooks = ({
  apiRef,
  rows,
  timeZone,
  logFilter,
  searchPhrase,
  lazyLoadRealTimeLogs,
  loadingRealtimeLogs,
  realTimelogsData
}) => {
  const rtsLogsDateDataLength = realTimelogsData?.syncRealTimeLogDates?.length;

  useEffect(() => {
    lazyLoadRealTimeLogs({ timeZone, logFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiRef && rows?.length) {
      apiRef?.current?.scrollToIndexes({ rowIndex: 0 });
      collapseAllRows({ apiRef });
      lazyLoadRealTimeLogs({ timeZone, logFilter, searchPhrase });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logFilter]);

  useEffect(() => {
    if (apiRef && !loadingRealtimeLogs && rtsLogsDateDataLength)
      expandInitialRow({ apiRef, timeZone, logFilter, searchPhrase });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, loadingRealtimeLogs]);

  useEffect(
    () =>
      apiRef.current?.subscribeEvent(
        'rowExpansionChange',
        handleRowExpansionChange({ apiRef, timeZone, logFilter, searchPhrase })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiRef, logFilter, searchPhrase]
  );
};

export default useRealtimeDataGridTableHooks;
