import React, { useMemo } from 'react';

import { gql, useMutation } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { CancelButton, CreateButton } from 'components';
import {
  syncMultipleWorkflows as SYNC_MULTIPLE_WORKFLOWS,
  syncMigratedWorkflows as SYNC_MIGRATED_WORKFLOWS
} from 'graphql/mutations';
import { getCleanObj } from 'helpers/objectFunctions';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { useImportWorkflowsContext } from 'modules/Sync/Connections/components/ImportWorkflowsContext';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { ROUTES, SYNC_TYPES } from 'modules/Sync/Constants/constants';
import { getFinalSyncTime } from 'modules/Sync/helpers/extractWorkflowConfig';
import { getSyncTexts } from 'modules/Sync/helpers/importHelpers';
import { syncColors } from 'styles/theme/colors';

const { SYNC, WORKFLOWS } = ROUTES;

const modalProps = {
  sx: { '& .MuiPaper-root': { overflowY: 'visible' } },
  contentStyles: { overflow: 'auto', width: 505, padding: '17px' }
};

const getSyncTypes = (isSyncAll, syncAllData, workflowSyncData) => {
  if (isSyncAll) return syncAllData?.syncType;
  const syncTypes = workflowSyncData.reduce((acc, wfItem) => {
    if (acc.length === SYNC_TYPES.length) return acc;
    const mappingSyncTypes = wfItem?.mappings.map((mapItem) => mapItem?.solitaryFlowSettings?.[0]?.syncType);
    return [...acc, ...mappingSyncTypes];
  }, []);

  const uniqueSyncTypes = [...new Set([...syncTypes])];
  return uniqueSyncTypes?.join(', ') || 'NA';
};

const ModalTitle = () => (
  <Typography fontWeight={600} variant="body4">
    Confirm import from desktop
  </Typography>
);

const SyncText = ({ subject = '', content = '', sx = {}, ...rest }) =>
  subject &&
  content && (
    <Typography sx={sx} {...rest}>
      <strong>{subject}</strong> {content}
    </Typography>
  );

const ConfirmImportAction = ({ open, onClose }) => {
  const navigate = useNavigate();

  const { handleResponse } = useGraphqlResponseHandler();

  const { setImportConnectionOpen, setConfirmImportOpen } = useSyncConnectionsContext();
  const { isSyncAll, syncAllData, workflowSyncData, setIsSyncAll } = useImportWorkflowsContext();
  const [syncAllWorkflows, { loading: loadingAllWorkflows }] = useMutation(gql(SYNC_MIGRATED_WORKFLOWS));
  const [syncWorkflows, { loading: loadingMultipleWorkflows }] = useMutation(gql(SYNC_MULTIPLE_WORKFLOWS));

  const totalMappings = workflowSyncData.reduce((acc, currentItem) => currentItem?.mappings.length + acc, 0);

  const syncTypes = getSyncTypes(isSyncAll, syncAllData, workflowSyncData);
  const isSyncEnabled = useMemo(() => (isSyncAll ? syncAllData?.isSyncEnabled : true), [isSyncAll, syncAllData]);
  const { date: finalDate, time: finalTime, interval } = getFinalSyncTime(workflowSyncData);

  const onModalClose = () => onClose?.();

  const handleModalClose = () => {
    onModalClose?.();
    navigate(`${SYNC}${WORKFLOWS}`);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (isSyncAll) {
      if (!syncAllData?.isSyncEnabled) return handleModalClose();
      const requestData = getCleanObj(syncAllData, ['isSyncEnabled']);
      return handleResponse(
        syncAllWorkflows,
        { variables: { body: requestData } },
        { successMessage: 'Workflows imported successfully' },
        () => handleModalClose()
      );
    }

    return handleResponse(
      syncWorkflows,
      { variables: { body: { Workflows: workflowSyncData } } },
      { successMessage: 'Workflows imported successfully' },
      () => handleModalClose()
    );
  };

  const onBack = () => {
    setImportConnectionOpen(true);
    setConfirmImportOpen(false);
    setIsSyncAll(true);
  };

  const disabledSubmit = loadingAllWorkflows || loadingMultipleWorkflows;

  const { firstSyncText, secondSyncText, lastSyncText } = getSyncTexts({
    workflowSyncData,
    totalMappings,
    interval,
    finalTime,
    finalDate,
    isSyncAll,
    syncAllData
  });

  return (
    <CustomModal
      disableModalOutsideClick
      draggable
      open={open}
      title={<ModalTitle />}
      onClose={onModalClose}
      footer={<Footer onClose={onModalClose} onSubmit={onSubmit} onBack={onBack} disabledSubmit={disabledSubmit} />}
      {...modalProps}
    >
      <SyncText subject="Mappings selected:" content={totalMappings} />
      <SyncText subject="Sync type:" content={syncTypes} />
      {isSyncEnabled && (
        <Box ml={2} my={2}>
          <SyncText subject="First sync:" content={firstSyncText} />
          <SyncText subject="Next sync:" content={secondSyncText} />
          <SyncText subject="Final sync:" content={lastSyncText} />
        </Box>
      )}
      <SyncText
        subject="After confirming:"
        content="If you want to edit a schedule, you can manually change it from the mapping canvas."
        sx={{ lineHeight: 1.5 }}
      />

      <Typography mt={2}>Are you sure you want to continue?</Typography>
    </CustomModal>
  );
};

const ConfirmImportModal = (props) => <ConfirmImportAction {...props} />;

export default ConfirmImportModal;

const Footer = ({ onClose, onBack, onSubmit, disabledSubmit }) => (
  <>
    <CancelButton
      onClick={onClose}
      color={syncColors.text}
      sx={{
        '&:hover': { backgroundColor: 'secondary.light' },
        '&:disabled': { borderColor: 'action.lightSurface.disabled' }
      }}
    />
    <CreateButton
      buttonText="Previous"
      onClick={onBack}
      variant="outlined"
      sx={{
        '&.MuiButton-root': {
          color: syncColors.text,
          '&:disabled': { color: 'action.lightSurface.disabled' }
        }
      }}
    />
    <CreateButton buttonText="Confirm" onClick={onSubmit} disabled={disabledSubmit} />
  </>
);
