import React from 'react';

import { Handle } from 'react-flow-renderer';

export const NodeHandles = () => (
  <>
    <Handle
      position="left"
      id="a"
      style={{
        visibility: 'hidden'
      }}
    />
    <Handle
      position="right"
      id="b"
      style={{
        visibility: 'hidden'
      }}
    />
    <Handle
      position="top"
      id="c"
      style={{
        visibility: 'hidden'
      }}
    />
    <Handle
      position="bottom"
      id="d"
      style={{
        visibility: 'hidden'
      }}
    />
  </>
);
