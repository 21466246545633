import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { ControlledRadioGroup } from 'components';
import Accordion from 'modules/Sync/Connections/components/ImportAccordion';
import { useImportWorkflowsContext } from 'modules/Sync/Connections/components/ImportWorkflowsContext';
import ImportSyncAllWorkflows from 'modules/Sync/Connections/Modals/ImportConnectionModal/ImportSyncAllWorkflows';
import ImportSyncProperties from 'modules/Sync/Connections/Modals/ImportConnectionModal/ImportSyncProperties';
import { SyncWorkflowEditorProvider } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';

const syncTypes = [
  {
    label: 'Sync all workflows',
    value: 'all'
  },
  {
    label: 'Sync individual workflows',
    value: 'individual'
  }
];

const ImportSync = () => {
  const { workflowData, workflowSyncData, isSyncAll, setIsSyncAll } = useImportWorkflowsContext();

  const methods = useForm({
    mode: 'all',
    defaultValues: { syncType: 'all' }
  });

  const { control } = methods;

  const handleSyncTypeOnchange = (event) => {
    const selectedType = event.target.value === 'all';
    setIsSyncAll(selectedType);
  };

  const syncWorkflowData = workflowData.reduce((acc, wfItem) => {
    const workflow = workflowSyncData.find((syncItem) => wfItem.workflowId === syncItem.workflowId);
    if (workflow) {
      const mappings = wfItem?.mappings;

      const updatedMappings = workflow?.mappings.map((mapItem) => {
        const mapping = mappings.find((item) => item.mappingId === mapItem.mappingId) || {};
        const { solitaryFlowConfigurations, mappingName } = mapping;
        return { ...mapItem, mappingName, solitaryFlowConfigurations };
      });

      acc.push({ ...wfItem, mappings: updatedMappings });
    }

    return acc;
  }, []);

  return (
    <>
      <ControlledRadioGroup
        name="syncType"
        radioOptions={syncTypes}
        control={control}
        direction="row"
        sx={{ '.MuiStack-root ': { justifyContent: 'space-between' } }}
        activeColor="secondary"
        onChange={handleSyncTypeOnchange}
      />

      {isSyncAll && <ImportSyncAllWorkflows />}
      {!isSyncAll && (
        <>
          <Typography variant="tableCellBold" mt={0}>
            Choose how and when to sync each workflow/mapping below
          </Typography>
          {!syncWorkflowData.length && <Typography>No Workflows Found!</Typography>}
          {!!syncWorkflowData.length && (
            <Accordion
              hideFirstBorder
              hideLastBorder
              items={syncWorkflowData.map((item, index) => ({
                id: index,
                title: item?.workflowName,
                content: <ImportSyncProperties data={item} />
              }))}
              sx={{
                marginTop: '0 !important',
                '& .MuiAccordionSummary-root, .MuiAccordionDetails-root': { padding: 0 }
              }}
            />
          )}
        </>
      )}
    </>
  );
};

const ImportSyncForm = () => (
  <SyncWorkflowEditorProvider>
    <ImportSync />
  </SyncWorkflowEditorProvider>
);
export default ImportSyncForm;
