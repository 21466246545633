import React, { useEffect, useState } from 'react';

import { FormHelperText } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import {
  Caption,
  ControlTextField
} from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import useUpsertConnectionMutation from 'modules/Sync/Connections/ConnectionProperties/useUpsertConnectionMutation';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { CONNECTION_TYPES } from 'modules/Sync/Constants/constants';

const EMAIL_ERROR_RESPONSE_MSG = 'User Name (Email)';
const EMAIL_ERROR_KEYWORD = 'User Not valid';

export const newformaFormDefaultValues = ({ configuration: selectedConnectionConfig }) => ({
  email: selectedConnectionConfig?.email || '',
  clientId: selectedConnectionConfig?.clientId || '',
  clientSecret: selectedConnectionConfig?.clientSecret || ''
});

const Newforma = ({ actionRenderer, disableAllFields, onSuccessCallback }) => {
  const { currentConnection, setHeaderActionsDisabled } = useSyncConnectionsContext();
  const [validationError, setValidationError] = useState('');
  const { upsertConnection, loading } = useUpsertConnectionMutation();

  const {
    control,
    setError: setFormError,
    formState: { isValid }
  } = useFormContext();

  const setError = (errors) => {
    if (typeof errors === 'string') {
      setValidationError(errors);
    } else {
      const { errorInfo } = errors?.[0] || {};
      setValidationError(Object.values(errorInfo)?.[0]);
    }

    if (
      errors?.includes(EMAIL_ERROR_RESPONSE_MSG) ||
      errors?.[0]?.errorInfo?.[EMAIL_ERROR_KEYWORD]
    ) {
      setFormError('configuration.email', { type: 'invalid', message: 'Invalid Email' });
    }
  };

  useEffect(() => {
    setValidationError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConnection]);

  const updateConnection = async (formData) => {
    setValidationError('');
    delete formData.dataSource;
    setHeaderActionsDisabled(false);
    upsertConnection({
      formData,
      connectionType: CONNECTION_TYPES.NEWFORMA,
      onError: setError,
      onSuccess: onSuccessCallback
    });
  };

  const submitDisabled = !isValid;

  return (
    <>
      <Caption>Credentials</Caption>
      <ControlTextField
        key={currentConnection?.connectionId}
        control={control}
        requiredNoSpace
        email
        name="configuration.email"
        label="Email*"
        errorMessages={{ email: 'Invalid' }}
        disabled={disableAllFields}
      />
      <ControlTextField
        key={currentConnection?.connectionId}
        control={control}
        requiredNoSpace
        name="configuration.clientId"
        label="Client ID*"
        disabled={disableAllFields}
      />
      <ControlTextField
        key={currentConnection?.connectionId}
        control={control}
        requiredNoSpace
        name="configuration.clientSecret"
        label="Client Secret*"
        disabled={disableAllFields}
      />

      <FormHelperText error>{validationError}</FormHelperText>
      {actionRenderer({ onSubmit: updateConnection, submitDisabled, loading })}
    </>
  );
};

export default Newforma;
