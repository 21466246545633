import { getScheduleSyncConfigPayload } from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';

export const getSyncFilesFormEventHandlers = ({
  selectedSourceId,
  currentFlowId,
  currentFlow,
  onSyncSettingsChange,
  setMappingFlowsSyncSettings
}) => {
  const onToggleHandler = (_, checked) => {
    if (selectedSourceId) return onSyncSettingsChange({ isSyncEnabled: checked });

    return setMappingFlowsSyncSettings((mappingFlowsSyncSettings) => [
      ...mappingFlowsSyncSettings.filter(({ solitaryFlowId }) => solitaryFlowId !== currentFlowId),
      { ...currentFlow, isSyncEnabled: checked }
    ]);
  };

  const onSyncTypeChangeHandler = (event) => {
    const { value: syncType } = event.target;
    if (selectedSourceId) return onSyncSettingsChange({ syncType });

    return setMappingFlowsSyncSettings((mappingFlowsSyncSettings) => [
      ...mappingFlowsSyncSettings.filter(({ solitaryFlowId }) => solitaryFlowId !== currentFlowId),
      { ...currentFlow, syncType }
    ]);
  };

  const onScheduleSyncOptionsChange = ({ event, checked, name }) => {
    const { value } = event.target;
    const config =
      checked !== undefined
        ? {
            [name]: checked
          }
        : {
            [name]: value
          };

    if (selectedSourceId) {
      onSyncSettingsChange({
        scheduleSyncConfig: getScheduleSyncConfigPayload(config)
      });
    }

    return setMappingFlowsSyncSettings((mappingFlowsSyncSettings) => [
      ...mappingFlowsSyncSettings.filter(({ solitaryFlowId }) => solitaryFlowId !== currentFlowId),
      {
        ...currentFlow,
        scheduleSyncConfig: getScheduleSyncConfigPayload(config, currentFlow.scheduleSyncConfig)
      }
    ]);
  };

  return { onSyncTypeChangeHandler, onToggleHandler, onScheduleSyncOptionsChange };
};
