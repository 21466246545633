import React, { Children, useMemo } from 'react';

import GridLayout from 'app/Layout/GridLayout';

import ComponentPane from './ComponentPane';

const ComponentPaneLayout = ({ sizes, flyoutMenu, children, componentPaneProps }) => {
  let childrenPanes = useMemo(
    () =>
      Children.map(children, (child) => (
        <ComponentPane renderMenuItems={child?.props?.renderMenuItems} {...componentPaneProps}>
          {child}
        </ComponentPane>
      )),
    [children, componentPaneProps]
  );
  childrenPanes = childrenPanes.filter((child) => child.props.children);

  return (
    <>
      <GridLayout sizes={sizes}>{childrenPanes}</GridLayout>
      {flyoutMenu}
    </>
  );
};

export default ComponentPaneLayout;
