import { gql, useMutation } from '@apollo/client';
import { Typography } from '@mui/material';

import { syncArchiveWorkflow as ARCHIVE_WORKFLOW } from 'graphql/mutations';
import { dispatchWorkflowGridEvent } from 'helpers/domHelpers';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';
import useWorkflowsLazyPaginationHook from 'modules/Sync/Workflows/Contexts/useWorkflowsLazyPaginationHook';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import { actionMenusApiPayload } from 'modules/Sync/Workflows/WorkflowsDataGridTable/workflowsDataGridTableHelper';

const ArchiveWorkflowModal = ({ onClose }) => {
  const { activeModal, selectedWorkflowIds, selectedMappingIds, selectedActionIds } = useSyncWorkflowsContext();
  const open = activeModal === WORKFLOW_MODAL_KEYS.ARCHIVE_WORKFLOW;
  const { workflows } = useWorkflowsLazyPaginationHook();
  const { handleResponse } = useGraphqlResponseHandler();

  const [archiveWorkflow, { loading }] = useMutation(gql(ARCHIVE_WORKFLOW), {
    refetchQueries: ['SyncArchivedWorkflows']
  });

  const onSuccessHandler = () => {
    dispatchWorkflowGridEvent();
    onClose();
  };

  const submit = async () => {
    const { selectedWorkflowIdsToSend, selectedMappingIdsToSend, selectedActionIdsToSend } = actionMenusApiPayload({
      selectedWorkflowIds,
      selectedMappingIds,
      selectedActionIds,
      workflows
    });

    await handleResponse(
      archiveWorkflow,
      {
        variables: {
          body: {
            WorkflowIds: selectedWorkflowIdsToSend,
            MappingIds: selectedMappingIdsToSend,
            SolitaryFlowIds: selectedActionIdsToSend
          }
        }
      },
      { successMessage: 'Workflow/Mapping/Action(s) successfully archived' },
      onSuccessHandler,
      onClose
    );
  };

  return (
    <CustomModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={open}
      onClose={onClose}
      title="Confirm archive"
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      paperSx={{ width: 600 }}
      footer={
        <ModalFooter
          cancelBtnText="NO"
          createBtnText="CONFIRM"
          loading={loading}
          onModalClose={onClose}
          onSubmit={submit}
        />
      }
    >
      <Typography variant="body1" mb="12px">
        Are you sure you want to archive selected workflow/mapping/action(s)?
      </Typography>

      <Typography variant="body2" mt={2}>
        <strong>Note:</strong> <br />
        Schedule syncs for selected workflow/mapping/action(s) will not run after archive.
      </Typography>
    </CustomModal>
  );
};

export default ArchiveWorkflowModal;
