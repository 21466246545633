import React from 'react';

import Connections from './Connections/Connections';
import { SyncConnectionsProvider } from './Connections/ConnectionsContext';
import { SyncLogsProvider } from './Logs/Contexts/LogsContext';
import Logs from './Logs/Logs';
import LogsDetails from './Logs/LogsDetails';
import { SyncWebhooksProvider } from './Webhooks/Contexts/WebhooksContext';
import Webhooks from './Webhooks/Webhooks';
import WorkflowEditor from './WorkflowEditor/WorkflowEditor';
import { SyncWorkflowsProvider } from './Workflows/Contexts/WorkflowsContext';
import Workflows from './Workflows/Workflows';

export const ConnectionsNavContent = () => (
  <SyncConnectionsProvider>
    <Connections />
  </SyncConnectionsProvider>
);

export const WorkflowsNavContent = () => (
  <SyncWorkflowsProvider>
    <Workflows />
  </SyncWorkflowsProvider>
);

export const WorkflowContent = () => <WorkflowEditor />;

export const LogsNavContent = () => (
  <SyncLogsProvider>
    <Logs />
  </SyncLogsProvider>
);

export const LogsDetailsContent = () => (
  <SyncLogsProvider>
    <LogsDetails />
  </SyncLogsProvider>
);
export const WebhooksNavContent = () => (
  <SyncWebhooksProvider>
    <Webhooks />
  </SyncWebhooksProvider>
);

export const ConnectionsCallbackContent = () => <>If nothing happens, close this window</>;
