import React from 'react';

import { SxProps, Typography } from '@mui/material';

type BreadcrumbLabelProps = {
  label: string;
  darkMode: boolean;
  sx: SxProps;
  fontWeight: number;
};

const BreadcrumbLabel = ({ label, darkMode, sx, fontWeight = 600 }: BreadcrumbLabelProps) => (
  <Typography
    variant="body2"
    sx={{
      color: darkMode ? 'surface.darkSurface.primary' : 'rgba(0, 0, 0, 0.9)',
      fontWeight,
      ...sx
    }}
  >
    {label}
  </Typography>
);

export default BreadcrumbLabel;
