import React from 'react';

import { Typography } from '@mui/material';

import CopyToClipboardBtn from 'modules/Sync/components/CopyToClipboardBtn';
import { surfaceColors } from 'styles/theme/colors';

const ImportTips = (props) => {
  const { fileName, textToCopy, isFolder } = props;
  return (
    <Typography
      variant="caption"
      sx={{ mt: '30px !important', lineHeight: '16px' }}
      color={surfaceColors.lightSurface.secondary}
    >
      Tip:
      <ul style={{ marginTop: '5px', paddingLeft: '22px' }}>
        <li>
          Find the <strong>{fileName}</strong> {isFolder ? 'folder' : 'file'} inside the 360 Sync folder on your hard
          drive.
        </li>
        <li>
          <em>Or</em> copy the default path here <CopyToClipboardBtn textToCopy={textToCopy} />
        </li>
        <li>Click Browse and paste it into the address bar.</li>
      </ul>
    </Typography>
  );
};

export default ImportTips;
