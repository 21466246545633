import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncGetWindowsFolders as SYNC_GET_WINDOWS_FOLDERS } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { useManageIssuesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/ManageIssuesContext';
import useWindowsHook from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Windows/useWindowsHook';
import WindowsFoldersDropdown from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Windows/WindowsFoldersDropdown';
import { getWindowsSelectEventHandlers } from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Windows/windowsHelpers';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;
const ERROR_POLICY = 'all';

const Windows = ({ connectionId = '', connectionType = '', nodeId = '', isSource = false }) => {
  const { updateConnectorNodeValues, getConnectorNodeValues } = useManageIssuesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { folders = [], folder = folders?.[0] } = formValues;
  const expandedFolderIds =
    folder?.id?.split('/')?.reduce((accumulator, currentFolderId) => {
      if (!accumulator.length) {
        accumulator.push(`${currentFolderId}/`);
      } else {
        const lastFolderId = accumulator[accumulator.length - 1].replace(/([^/])$/, '$1/');
        accumulator.push(`${lastFolderId}${currentFolderId}`);
      }
      return accumulator;
    }, []) || [];
  const [parentFolderId, setParentFolderId] = useState(expandedFolderIds[expandedFolderIds?.length - 2] || '');

  const { handleResponse } = useGraphqlResponseHandler();

  const [
    { lazyLoad: loadFolders, paginationHandler: fetchMoreRootFolders },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { loading: isLoadingFolders, data: { syncGetWindowsFolders: WindowsFolders = [] } = {} }
  ] = useLazyPaginatedQuery(gql(SYNC_GET_WINDOWS_FOLDERS), FETCH_POLICY, DROPDOWN_DEFAULT_PG_SIZE, ERROR_POLICY);

  const updateFormValues = (props) =>
    updateConnectorNodeValues({
      isSource,
      nodeId,
      props: isSource ? { ...formValues, ...props } : { ...props }
    });

  const { selectFolderHandler } = getWindowsSelectEventHandlers({
    updateFormValues,
    folders,
    parentFolderId,
    setParentFolderId
  });
  useWindowsHook({
    handleResponse,
    loadFolders,
    updateFormValues,
    connectionId,
    connectionType,
    nodeId,
    formValues,
    expandedFolderIds
  });

  return (
    <Stack spacing={2}>
      <WindowsFoldersDropdown
        connectionId={connectionId}
        folders={WindowsFolders}
        onSelect={selectFolderHandler}
        isSource={isSource}
        isLoadingFolders={isLoadingFolders}
        fetchMoreRootFolders={fetchMoreRootFolders}
        nodeId={nodeId}
        multiple={isSource}
        expandedFolderIds={expandedFolderIds}
        required
      />
    </Stack>
  );
};

export default Windows;
