import React from 'react';

import { Stack, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';

const CustomArrowDropDownIcon = ({ disabled, sx = {} }) => {
  const {
    palette: { secondary }
  } = useTheme();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        borderLeft: `solid 1px ${!disabled ? secondary.outlinedRestingBorder : '#cccccc'}`,
        height: '32px',
        paddingLeft: '4px',
        ...sx
      }}
    >
      <Custom.ArrowDropDown fill={!disabled ? secondary.main : '#cccccc'} style={{ width: '16px', margin: '0 4px' }} />
    </Stack>
  );
};

export default CustomArrowDropDownIcon;
