import { MAPPING_ACTIONS, MAPPING_ACTION_TYPES } from 'modules/Sync/Constants/constants';

import { parseRequestScheduleSyncJson } from '../syncHelpers';

const groupItemsByFolder = ({ id, name, parentId, parentName, isFolder, folders = [] }) => {
  let foundExistingFolder = false;

  const groupItems = (folder) => {
    const { id: folderId, name: folderName, items } = folder;
    if (isFolder && folderId === id) {
      foundExistingFolder = true;
      return { id: folderId, name: folderName, items: [...items] };
    }

    if (folderId === parentId) {
      foundExistingFolder = true;
      return { id: folderId, name: folderName, items: [...items, { id, name }] };
    }
    return folder;
  };

  const combinedFolders = folders?.map(groupItems) || [];

  if (!foundExistingFolder) {
    const folderItem = isFolder ? { id, name, items: [] } : { id: parentId, name: parentName, items: [{ id, name }] };
    combinedFolders.push(folderItem);
  }
  return combinedFolders;
};

const getFoldersAndItems = (folders = []) =>
  folders?.reduce((folders, { id, name, parentId, parentName, isFolder = false }) => {
    if (!id && !name) return folders;
    return groupItemsByFolder({ folders, id, name, parentId, parentName, isFolder });
  }, []);

export const getAdditionalData = (additionalData = '') =>
  typeof additionalData === 'string'
    ? additionalData?.split(';')?.reduce((acc, el) => {
        // eslint-disable-next-line prefer-const
        let [key, value] = el.split('=');
        if (key === 'downloadBehavior' || key === 'uploadBehavior' || key === 'connectionType') {
          value = Number(value);
        }
        if (key === 'setFilePermissionsAsAdmin') {
          value = Boolean(value);
        }
        acc[key] = value;
        return acc;
      }, {})
    : additionalData;

export const setAdditionalData = ({
  folderType = 'document',
  downloadBehavior = 0,
  uploadBehavior = 0,
  setFilePermissionsAsAdmin = false,
  connectionType = 0
}) =>
  `folderType=${folderType};downloadBehavior=${downloadBehavior};uploadBehavior=${uploadBehavior};setFilePermissionsAsAdmin=${setFilePermissionsAsAdmin};connectionType=${connectionType}`;

export const publishActionActionPayload = ({
  isSyncEnabled = true,
  syncType,
  scheduleSyncConfig,
  actionNodeId = '',
  actionAlias = '',
  solitaryFlowId = '',
  source = '',
  includeExtensions = [],
  isSyncAllRevisions = true,
  secondaryFilesSetting = null
}) => ({
  solitaryFlowId,
  actionNodeId,
  actionAlias,
  action: MAPPING_ACTIONS.publish,
  source: {
    nodeId: source.nodeId,
    hub: {
      id: source?.hub?.id || '',
      name: source?.hub?.name || ''
    },
    projects: [
      {
        id: source?.project?.id,
        name: source?.project?.name
      }
    ],
    folders: [
      {
        id: source?.folder?.id,
        name: source?.folder?.name
      }
    ],
    additionalData: setAdditionalData({
      folderType: source?.folderType || 'document',
      downloadBehavior: source?.downloadBehavior || 0
    }),
    folderType: source?.folderType || '',
    ...source
  },
  settings: {
    isSyncAllRevisions,
    includeExtensions: JSON.stringify(includeExtensions),
    secondaryFilesSetting,
    isSyncEnabled,
    syncType,
    scheduleSyncConfig
  }
});

export const publishActionMappingConfigPayload = (mappings) =>
  mappings.map(({ solitaryFlowId = '', action, source, settings, actionNodeId, actionAlias }) => ({
    ...(solitaryFlowId ? { solitaryFlowId } : {}),
    action,
    actionNodeId,
    actionAlias,
    actionType: MAPPING_ACTION_TYPES.publishAction,
    source: {
      ...(source?.syncDetailsId ? { syncDetailsId: source?.syncDetailsId } : {}),
      connectionId: source.connectionId,
      nodeId: source.nodeId,
      hub: {
        id: source?.hub?.id || '',
        name: source?.hub?.name || ''
      },
      projects: source?.projects?.map(({ id, name }) => ({
        id,
        name,
        folders: getFoldersAndItems(source?.folders)
      })),
      additionalData: setAdditionalData({
        folderType: source?.folderType || 'document',
        downloadBehavior: source?.downloadBehavior || 0
      }),
      settings: {
        isSyncAllRevisions: settings?.isSyncAllRevisions,
        includeExtensions: settings?.includeExtensions,
        secondaryFilesSetting: settings?.secondaryFilesSetting,
        isSyncEnabled: settings?.isSyncEnabled,
        syncType: settings?.syncType,
        scheduleSyncConfig: parseRequestScheduleSyncJson(settings?.scheduleSyncConfig)
      }
    }
  }));

export const saveSyncConfigPayload = (mappings) =>
  mappings.map(({ solitaryFlowId = '', isSyncEnabled = false, syncType = '', scheduleSyncConfig = '' }) => ({
    solitaryFlowId,
    isSyncEnabled,
    syncType,
    scheduleSyncConfig: parseRequestScheduleSyncJson(scheduleSyncConfig)
  }));
