import React from 'react';

import { Typography } from '@mui/material';
import { useStoreState } from 'react-flow-renderer';

import CustomModal from 'modules/Sync/components/CustomModal';
import { ACTION_CANVAS_NODE } from 'modules/Sync/Constants/constants';
import useSaveSyncConfigApiAction from 'modules/Sync/WorkflowEditor/apiActions/saveSyncConfig';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import useCustomConnectAction from 'modules/Sync/WorkflowEditor/editorHooks/useCustomConnectAction';
import useSaveMappingAction from 'modules/Sync/WorkflowEditor/editorHooks/useSaveMappingAction';
import SyncFilesForm from 'modules/Sync/WorkflowEditor/Forms/Shared/SyncFilesForm/index';
import { parseResponseScheduleSyncJson } from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';
import { validateSyncSettings } from 'modules/Sync/WorkflowEditor/helpers/validationsHelper';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';

const SyncConfigModal = ({ onClose, onSubmit }) => {
  const { isOpenSyncConfigModal, workflowMappings, mappingId } = useSyncWorkflowEditorContext();
  const [mappingFlowsSyncSettings, setMappingFlowsSyncSettings] = React.useState([
    ...workflowMappings.map(({ solitaryFlowId, source: { settings } }) => ({
      ...settings,
      scheduleSyncConfig: parseResponseScheduleSyncJson(settings?.scheduleSyncConfig),
      solitaryFlowId
    }))
  ]);
  const { saveSyncConfig } = useSaveSyncConfigApiAction({
    refetchQueries: ['SyncGetConnectionById', 'SyncGetWorkflowMappingConfiguration']
  });

  const availableActionNodes = useStoreState((store) =>
    store.nodes
      .filter(
        ({ type, id }) =>
          type === ACTION_CANVAS_NODE && workflowMappings.find(({ actionNodeId }) => actionNodeId === id)
      )
      .map((item) => {
        const isDeactivated = workflowMappings.find(({ actionNodeId }) => actionNodeId === item?.id)?.isDeactivated;
        const isArchived = workflowMappings.find(({ actionNodeId }) => actionNodeId === item?.id)?.isArchived;
        return { ...item, isDeactivated, isArchived };
      })
  );

  const onModalClose = () => {
    onClose();
    setMappingFlowsSyncSettings(
      workflowMappings.map(({ solitaryFlowId, source: { settings } }) => ({
        ...settings,
        scheduleSyncConfig: parseResponseScheduleSyncJson(settings?.scheduleSyncConfig),
        solitaryFlowId
      }))
    );
  };

  const { connectAction } = useCustomConnectAction();
  const { saveMappingAction } = useSaveMappingAction();
  const updateCanvasNode = () => {
    mappingFlowsSyncSettings.forEach((solitaryFlow) => {
      const currentFlow = workflowMappings.find((mapping) => mapping.solitaryFlowId === solitaryFlow.solitaryFlowId);
      connectAction(
        currentFlow.actionNodeId,
        currentFlow.source.nodeId,
        currentFlow.destinations?.map((dest) => dest.nodeId),
        solitaryFlow?.syncType,
        solitaryFlow?.isSyncEnabled
      );
    });
  };

  const onModalSubmit = async () => {
    await updateCanvasNode();
    saveSyncConfig({ mappingId, mappingFlowsSyncSettings });
    saveMappingAction({ successMessage: '' });
    return onSubmit();
  };

  React.useEffect(() => {
    setMappingFlowsSyncSettings(
      workflowMappings.map(({ solitaryFlowId, source: { settings } }) => ({
        ...settings,
        scheduleSyncConfig: parseResponseScheduleSyncJson(settings?.scheduleSyncConfig),
        solitaryFlowId
      }))
    );
  }, [workflowMappings]);

  const isSubmitDisabled =
    !availableActionNodes.length ||
    availableActionNodes.every(({ isDeactivated, isArchived }) => isDeactivated || isArchived) ||
    !validateSyncSettings(mappingFlowsSyncSettings);

  return (
    <CustomModal
      disableModalOutsideClick
      open={isOpenSyncConfigModal}
      title={
        <Typography fontWeight={600} variant="body4">
          Action settings
        </Typography>
      }
      onClose={onModalClose}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': { width: 506, height: 700 },
        '[data-cy="modal-container"]': { paddingY: 0 }
      }}
      footer={
        <ModalFooter
          onModalClose={onModalClose}
          cancelBtnText="Cancel"
          createBtnText={mappingFlowsSyncSettings?.some(({ isSyncEnabled }) => isSyncEnabled) ? 'Save & Run' : 'Save'}
          onSubmit={onModalSubmit}
          onSubmitDisabled={isSubmitDisabled}
        />
      }
    >
      <SyncFilesForm
        setMappingFlowsSyncSettings={setMappingFlowsSyncSettings}
        mappingFlowsSyncSettings={mappingFlowsSyncSettings}
        availableActionNodes={availableActionNodes}
      />
    </CustomModal>
  );
};

export default SyncConfigModal;
