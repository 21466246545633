import React from 'react';

import { Typography } from '@mui/material';

import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';

const SyncActionTitle = ({ actionAlias = '' }) => (
  <Typography variant="subtitle2" fontWeight={700} display="flex" textAlign="center" data-behavior="DO-NOT-EXPAND">
    <CustomSyncTooltip
      title={`Action: ${actionAlias}`}
      sx={{ maxWidth: 360, alignItems: 'center', display: 'flex' }}
      boxProps={{ 'data-behavior': 'DO-NOT-EXPAND' }}
    >
      Action: {actionAlias}
    </CustomSyncTooltip>
  </Typography>
);
export default SyncActionTitle;
