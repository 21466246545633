import React, { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { ControlledRadioGroup } from 'components';
import ControlledSwitchInput from 'components/FormComponents/ControlledSwitchInput';
import ControlledTooltip from 'modules/Sync/components/ControlledTooltip';
import { useImportWorkflowsContext } from 'modules/Sync/Connections/components/ImportWorkflowsContext';
import useSyncOptionsHook from 'modules/Sync/Connections/Modals/ImportConnectionModal/useSyncOptionsHook';
import { CONNECTION_TYPES, REALTIME_SYNC, SCHEDULE_SYNC, SYNC_TYPES } from 'modules/Sync/Constants/constants';
import { getFinalSyncTime } from 'modules/Sync/helpers/extractWorkflowConfig';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import ScheduleSyncFields from 'modules/Sync/WorkflowEditor/Forms/Shared/SyncFilesForm/ScheduleSyncFields';
import { getSyncFilesFormEventHandlers } from 'modules/Sync/WorkflowEditor/Forms/Shared/SyncFilesForm/syncFilesFormHelpers';
import useSyncFilesHooks from 'modules/Sync/WorkflowEditor/Forms/Shared/SyncFilesForm/useSyncFilesHooks';

const getDefaultFormValues = ({
  currentSourceScheduleSyncConfig,
  selectedSourceId,
  currentSourceSyncType,
  currentFlow,
  selectedSourceSyncEnabled
}) => ({
  isSyncEnabled: !!(selectedSourceSyncEnabled || currentFlow?.isSyncEnabled),
  syncType: currentFlow?.syncType || currentSourceSyncType,
  Sunday: selectedSourceId ? currentSourceScheduleSyncConfig?.Sunday : currentFlow?.scheduleSyncConfig?.Sunday,
  Monday: selectedSourceId ? currentSourceScheduleSyncConfig?.Monday : currentFlow?.scheduleSyncConfig?.Monday,
  Tuesday: selectedSourceId ? currentSourceScheduleSyncConfig?.Tuesday : currentFlow?.scheduleSyncConfig?.Tuesday,
  Wednesday: selectedSourceId ? currentSourceScheduleSyncConfig?.Wednesday : currentFlow?.scheduleSyncConfig?.Wednesday,
  Thursday: selectedSourceId ? currentSourceScheduleSyncConfig?.Thursday : currentFlow?.scheduleSyncConfig?.Thursday,
  Friday: selectedSourceId ? currentSourceScheduleSyncConfig?.Friday : currentFlow?.scheduleSyncConfig?.Friday,
  Saturday: selectedSourceId ? currentSourceScheduleSyncConfig?.Saturday : currentFlow?.scheduleSyncConfig?.Saturday,
  hours: currentFlow?.scheduleSyncConfig?.hours || currentSourceScheduleSyncConfig.hours,
  minutes: currentFlow?.scheduleSyncConfig?.minutes || currentSourceScheduleSyncConfig.minutes,
  format: currentFlow?.scheduleSyncConfig?.format || currentSourceScheduleSyncConfig.format
});

const isSyncTypeDisabled = ({
  syncType,
  isSyncEnabled,
  isAllSyncTypeAvailable,
  allConnections,
  selectedSourceConnectionType,
  currentFlowConnectionType
}) => {
  if (!isSyncEnabled) return true;

  return syncType === REALTIME_SYNC && !isAllSyncTypeAvailable
    ? !allConnections?.syncConnectorsList?.find(
        ({ name }) => name === selectedSourceConnectionType || name === currentFlowConnectionType
      )?.realTimeSync
    : false;
};

const getSyncTypes = ({
  isSyncEnabled,
  isAllSyncTypeAvailable,
  allConnections,
  selectedSourceConnectionType,
  currentFlowConnectionType,
  tooltipIsOpen,
  setTooltipIsOpen
}) =>
  SYNC_TYPES.map(({ syncType, label, ...rest }) => {
    const disabled = isSyncTypeDisabled({
      syncType,
      isSyncEnabled,
      isAllSyncTypeAvailable,
      allConnections,
      selectedSourceConnectionType,
      currentFlowConnectionType
    });

    return {
      syncType,
      ...rest,
      disabled,
      label:
        disabled && syncType === REALTIME_SYNC ? (
          <ControlledTooltip
            open={tooltipIsOpen}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            title="This connector doesn't support real-time sync"
          >
            <span>{label}</span>
          </ControlledTooltip>
        ) : (
          <span>{label}</span>
        )
    };
  });

const EnabledSyncDescription = ({ syncType, interval, finalTime, finalDate }) => (
  <Typography variant="body2" mt={2}>
    <strong>Description</strong>
    <ul style={{ marginTop: '10px', paddingLeft: '22px' }}>
      {syncType !== SCHEDULE_SYNC && (
        <>
          <li>Copy files right now. This will only occur once.</li> <li>First workflow will start immediately.</li>
        </>
      )}

      {syncType === SCHEDULE_SYNC && <li>First workflow will start at scheduled start time.</li>}
      <li>Each remaining workflow will follow in {interval} minute intervals.</li>
      <li>
        Last workflow est. to {syncType === SCHEDULE_SYNC ? 'be scheduled' : 'start'} at {finalTime} on {finalDate}.
      </li>
      {syncType === REALTIME_SYNC && <li>After the initial sync has completed, Real-Time Sync will be activated</li>}
    </ul>
  </Typography>
);

const EnabledRealtimeDescription = ({ allConnections = [] }) => {
  const unsupportedRealtimeSyncConnections = allConnections
    ?.reduce((acc, item) => {
      const { realTimeSync, name } = item;
      if (!realTimeSync && name !== CONNECTION_TYPES.FORESITE) acc.push(name);
      return acc;
    }, [])
    .join(', ');

  return (
    <Typography variant="body2" mt={2}>
      <strong>Note:</strong> <br />
      Real-time sync is not supported for <strong>{unsupportedRealtimeSyncConnections}</strong>. Mappings using these
      connectors will sync now and can be manually scheduled from the mapping canvas.
    </Typography>
  );
};

const DisabledDescription = () => (
  <Typography variant="body2">
    <strong>Description:</strong> <br />
    Not ready to sync or need to pause an existing sync.
  </Typography>
);

const ScheduleSyncDetail = ({ control, onScheduleChange, hours, minutes, format, interval }) => (
  <Box sx={{ width: 450 }}>
    <ScheduleSyncFields
      control={control}
      onChangeHandler={onScheduleChange}
      defaultValues={{ hours, minutes, format }}
    />

    <Typography variant="body2" mt={2}>
      <strong>Sync interval</strong>
      <Typography>The syncs will be scheduled {interval} minutes apart.</Typography>
    </Typography>
  </Box>
);

const ImportSyncOptions = (props) => {
  const { mappingFlowsSyncSettings = [], onSyncSettingsChange = () => {} } = props;
  const { setMappingFlowsSyncSettings = () => {} } = props;
  const { selectedSource = {}, currentAction = '', syncSettings = {}, importMethods, isAllSyncTypeAvailable } = props;
  const { connectionType = '', connection: { type = '' } = {}, nodeId: selectedSourceId = '' } = selectedSource;
  const { isSyncEnabled: selectedSourceSyncEnabled = false } = syncSettings;
  const { syncType: currentSourceSyncType = SYNC_TYPES[0].value, scheduleSyncConfig = {} } = syncSettings;
  const selectedSourceConnectionType = connectionType || type;
  const { workflowMappings, allConnections } = useSyncWorkflowEditorContext();
  const { workflowData } = useImportWorkflowsContext();
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const { date: finalDate, time: finalTime, interval } = getFinalSyncTime(workflowData);

  const {
    solitaryFlowId: currentFlowId = '',
    source: { connection: { type: currentFlowConnectionType = '' } = {} } = {}
  } = workflowMappings?.find(({ actionNodeId }) => actionNodeId === currentAction) || {};
  const currentFlow = mappingFlowsSyncSettings?.find(({ solitaryFlowId }) => solitaryFlowId === currentFlowId) || {};

  const { control, watch, setValue } = useForm({
    mode: 'all',
    defaultValues: getDefaultFormValues({
      currentFlow,
      currentSourceScheduleSyncConfig: scheduleSyncConfig,
      selectedSourceId,
      currentSourceSyncType,
      selectedSourceSyncEnabled
    })
  });
  const { isSyncEnabled, syncType, hours, minutes, format } = watch();
  const { isDetailed } = SYNC_TYPES.find(({ value }) => value === syncType) || {};

  useSyncFilesHooks({ currentFlowId, setValue, currentFlow, sourceScheduleSyncConfig: scheduleSyncConfig });

  const syncTypes = getSyncTypes({
    isSyncEnabled,
    isAllSyncTypeAvailable,
    allConnections,
    selectedSourceConnectionType,
    currentFlowConnectionType,
    tooltipIsOpen,
    setTooltipIsOpen
  });

  const { onToggleHandler, onSyncTypeChangeHandler, onScheduleSyncOptionsChange } = getSyncFilesFormEventHandlers({
    selectedSourceId,
    currentFlowId,
    currentFlow,
    onSyncSettingsChange,
    setMappingFlowsSyncSettings
  });

  const { onToggle, onTypeChange, onScheduleChange } = useSyncOptionsHook({
    importMethods,
    onToggleHandler,
    onSyncTypeChangeHandler,
    onScheduleSyncOptionsChange,
    syncSettings
  });

  const syncDetails = { hours, minutes, format, interval };

  return (
    <Stack sx={{ width: 450 }}>
      <ControlledSwitchInput
        name="isSyncEnabled"
        control={control}
        label={
          <Typography
            variant="subtitle2"
            display="flex"
            textAlign="center"
            data-behavior="DO-NOT-EXPAND"
            sx={{ fontSize: '16px' }}
          >
            Sync {isSyncEnabled ? 'enabled' : 'disabled'}
          </Typography>
        }
        color="secondary"
        sx={{ marginRight: 0.5, marginBottom: '16px' }}
        switchProps={{ inputProps: { 'data-behavior': 'DO-NOT-EXPAND' } }}
        onChangeHandler={onToggle}
      />
      {isSyncEnabled && (
        <ControlledRadioGroup
          name="syncType"
          radioOptions={syncTypes}
          direction="row"
          control={control}
          activeColor="secondary"
          onChange={onTypeChange}
          defaultValue={syncType}
        />
      )}

      {!isSyncEnabled && <DisabledDescription />}

      {isSyncEnabled && (
        <EnabledSyncDescription syncType={syncType} interval={interval} finalTime={finalTime} finalDate={finalDate} />
      )}

      {isSyncEnabled && syncType === REALTIME_SYNC && (
        <EnabledRealtimeDescription allConnections={allConnections?.syncConnectorsList} />
      )}

      {isSyncEnabled && isDetailed && (
        <ScheduleSyncDetail control={control} onScheduleChange={onScheduleChange} {...syncDetails} />
      )}
    </Stack>
  );
};

export default ImportSyncOptions;
