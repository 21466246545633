import { v4 as uuidv4 } from 'uuid';

import { getFormattedDateTime } from 'helpers/dateFunctions';
import { convertBytes } from 'modules/Sync/helpers/common';

export const formatFoldersToRows = (folders, parentPath, parentLevel = 0) => {
  if (!folders?.length) return [];

  return folders?.map(({ syncFolderMetricId, name, numFiles, totalSize, numSubFolders, terminalStatus }) => ({
    id: syncFolderMetricId,
    syncFolderMetricId,
    name,
    parentId: parentPath?.[parentPath.length - 1] || '',
    path: [...parentPath, syncFolderMetricId],
    numFiles,
    totalSize: convertBytes(totalSize),
    numSubFolders,
    terminalStatus,
    isFile: false,
    level: parentLevel + 1,
    descendantCount: 1,
    rowClass: 'folder'
  }));
};

export const formatFilesToRows = (files, parentPath, parentLevel = 0) => {
  if (!files?.length) return [];
  return files?.map(
    ({ id, name, fileSyncDate, terminalStatus, totalSize, isLoadMore = false, rowClass = 'file', errorCode = '' }) => {
      const randomId = uuidv4();
      const skipDateFormatting = !fileSyncDate || fileSyncDate.includes('PM') || fileSyncDate.includes('AM');
      return {
        id: isLoadMore ? id : randomId,
        name,
        parentId: parentPath?.[parentPath.length - 1] || '',
        path: [...parentPath, randomId],
        fileSyncDate: skipDateFormatting ? fileSyncDate : getFormattedDateTime(fileSyncDate, true),
        terminalStatus,
        isFile: true,
        level: parentLevel + 1,
        descendantCount: 0,
        totalSize: convertBytes(totalSize),
        rowClass,
        errorCode
      };
    }
  );
};

const LogFoldersDetailDataGridTableRows = (logs = []) => {
  if (!logs) return [];

  return logs?.flatMap(({ syncFlowMetricId, name, terminalStatus, totalSize, ...rest }) => [
    {
      id: syncFlowMetricId,
      name,
      parentId: '',
      syncFlowMetricId,
      path: [syncFlowMetricId],
      terminalStatus,
      level: 0,
      rowClass: 'action',
      descendantCount: 1,
      totalSize: convertBytes(totalSize),
      ...rest
    }
  ]);
};

export default LogFoldersDetailDataGridTableRows;
