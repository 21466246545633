import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ControlledRadioGroup } from 'components';
import { getTimeZone } from 'helpers/dateFunctions';
import CustomModal from 'modules/Sync/components/CustomModal';
import { LOG_FILTER } from 'modules/Sync/Constants/constants';
import ModalFooter from 'modules/Sync/Logs/components/ModalFooter';
import ModalTitle from 'modules/Sync/Logs/components/ModalTitle';

const defaultFormValues = { workflowName: '', mappingName: '', templateId: '' };

const { ALL, FAILED, DEBUG } = LOG_FILTER;
const downloadLogTypes = [
  { label: 'Entire log', value: ALL },
  { label: 'Failed files log only', value: FAILED },
  { label: 'Failed files log w/ debug', value: DEBUG }
];
const timeZone = getTimeZone();

const DownloadSyncLogsModal = ({ onClose, downloadDisabled, downloadFile, open = false, logId = '' }) => {
  const { id: fileSyncLogId = logId } = useParams();
  const methods = useForm({
    mode: 'all',
    defaultValues: defaultFormValues
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } = methods;

  const onModalClose = () => {
    onClose?.();
    reset(defaultFormValues);
  };

  const onSubmit = handleSubmit(async ({ logFilter }) => {
    const IsDebug = logFilter !== FAILED;
    let query = { fileSyncLogId, timeZone, logFilter, IsDebug };
    if (logFilter === DEBUG) query = { ...query, logFilter: FAILED };

    const variables = { ...query };
    downloadFile(variables);
    onModalClose();
  });

  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={<ModalTitle title="Download log" />}
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={
        <ModalFooter
          onModalClose={onModalClose}
          onSubmit={onSubmit}
          onSubmitDisabled={!isValid}
          createBtnText="Download"
          loading={downloadDisabled}
        />
      }
    >
      <Stack gap={1}>
        <Typography variant="subtitle1" color="primary">
          Select which log you want to download:
        </Typography>

        <ControlledRadioGroup
          name="logFilter"
          radioOptions={downloadLogTypes}
          direction="column"
          control={control}
          activeColor="secondary"
          defaultValue={downloadLogTypes[0]?.value}
          sx={{ ml: 2 }}
        />
      </Stack>
    </CustomModal>
  );
};

export default DownloadSyncLogsModal;
