import { useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { Box, Button, CircularProgress, ClickAwayListener, Popper } from '@mui/material';
import Typography from '@mui/material/Typography';

import { CreateButton } from 'components';
import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncErrorDetails as SYNC_ERROR_DETAILS } from 'graphql/queries';
import { getMicroFeContainer } from 'helpers/domHelpers';
import { surfaceColors, syncColors } from 'styles/theme/colors';

const commonButtonStyle = {
  width: 'auto',
  height: 24,
  fontSize: 13,
  fontWeight: 500,
  color: surfaceColors.lightSurface.primary,
  backgroundColor: syncColors.mainDarkBackground
};

const activeButtonStyle = {
  '&:hover': { backgroundColor: 'secondary.dark' }
};

const inActivedButtonStyle = {
  cursor: 'not-allowed',
  backgroundColor: syncColors.light,
  '&:hover': { backgroundColor: syncColors.light }
};

const closeButtonStyle = {
  backgroundColor: '#303030',
  color: syncColors.mainDarkBackground,
  border: `1px solid ${syncColors.mainDarkBackground}`,
  height: 24,
  fontSize: 13,
  fontWeight: 500
};

const Loader = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 228
    }}
  >
    <CircularProgress color="warning" />
  </Box>
);

const { fetchPolicy } = FETCH_POLICIES.CACHE_AND_NETWORK;
const ErrorCodeBtn = ({ errorCode, resync = () => null, isResyncAllowed, isResyncVisible = true }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [getErrorDetails, { data, loading }] = useLazyQuery(gql(SYNC_ERROR_DETAILS), {
    fetchPolicy,
    variables: { query: { code: Number(errorCode) } }
  });
  const { message = '', description = '', fix = '' } = data?.syncErrorDetails || {};

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResync = () => {
    resync();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const buttonStyle = isResyncAllowed ? activeButtonStyle : inActivedButtonStyle;

  useEffect(() => {
    if (open) getErrorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <CreateButton
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          buttonText={errorCode}
          sx={{
            padding: 0,
            backgroundColor: syncColors.statusColors.failed,
            '&:hover': {
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${syncColors.statusColors.failed}`
            }
          }}
        />

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          sx={{
            mb: 1,
            minHeight: 228,
            width: 363,
            backgroundColor: '#303030',
            padding: 1,
            boxShadow:
              '0px 4px 18px 3px rgba(0, 0, 0, 0.12), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 6px 6px -3px rgba(0, 0, 0, 0.20)',
            '.MuiTypography-root': { color: 'white' }
          }}
          container={getMicroFeContainer}
        >
          {loading && <Loader />}
          {!loading && (
            <>
              <ErrorDetails message={message} description={description} fix={fix} errorCode={errorCode} />
              <Box
                sx={{ position: 'absolute', bottom: 6, display: 'flex', justifyContent: 'end', gap: 2, width: '95%' }}
              >
                <Button onClick={handleClose} variant="contained" sx={{ '&.MuiButton-root': { ...closeButtonStyle } }}>
                  Close
                </Button>

                {isResyncVisible && (
                  <Button
                    onClick={handleResync}
                    variant="contained"
                    sx={{
                      '&.MuiButton-root': {
                        ...commonButtonStyle,
                        ...buttonStyle
                      }
                    }}
                  >
                    Resync
                  </Button>
                )}
              </Box>
            </>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default ErrorCodeBtn;

const ErrorDetails = ({ message, description, fix, errorCode }) => (
  <Box height="fit-content" mb={4}>
    <Typography variant="body1" fontWeight={700} pl={2} my={1} mb={3} lineHeight={1.6}>
      Error details
    </Typography>
    <Typography variant="body1" sx={{ fontWeight: 400 }} pl={2} lineHeight={1.6} mb={2}>
      {errorCode}: {message}
    </Typography>
    <Typography variant="body1" sx={{ fontWeight: 400 }} pl={2} lineHeight={1.6} mb={2}>
      {description}
    </Typography>
    <Typography variant="body1" sx={{ fontWeight: 400 }} pl={2} lineHeight={1.6} mb={2}>
      Fix: {fix}
    </Typography>
  </Box>
);
