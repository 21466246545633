import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import ItemsDatagridPro from 'components/ItemsDatagridPro';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import useLazyLoadLogsActionFolderDetailsHook from 'modules/Sync/Logs/Contexts/useLazyLoadLogsActionFolderDetailsHook';
import NoRowsOverlay from 'modules/Sync/Logs/LogsDetails/NoRowsOverlay';

import logFoldersDataGridStyles from './logFoldersDataGridStyles';
import LogFoldersDetailsDataGridCell from './LogFoldersDetailDataGridCell';
import LogFoldersDetailDataGridTableRows from './LogFoldersDetailDataGridTableRows';
import LogFoldersDetailsDataGridGroupingCell from './LogFoldersDetailsDataGridGroupingCell';
import LogFoldersDetailsDataGridTableColumns from './LogFoldersDetailsDataGridTableColumns';
import useLogFolderDetailsDataGridTableHooks from './useLogFolderDetailsDataGridTableHooks';

const NoRowsOverlayText = () => (
  <span>
    No syncs have been run. <br />
    There are no logs to display.
  </span>
);

const DATAGRID_PROPS = {
  treeData: true,
  getTreeDataPath: (row) => row.path,
  disableChildrenSorting: true,
  disableChildrenFiltering: true,
  experimentalFeatures: { rowPinning: true, newEditingApi: true },
  density: 'standard',
  checkboxSelection: false,
  disableColumnMenu: true,
  disableSelectionOnClick: true,
  sx: logFoldersDataGridStyles,
  sortingMode: 'client',
  getRowClassName: (params) => params?.row?.rowClass
};

const LogFoldersDetailsDataGridTable = ({
  autoRefreshFolderDetails,
  startLogActionDetailsPolling,
  stopLogActionDetailsPolling,
  loadLogActionDetails,
  logActionDetailsData,
  setManuallyLoadingLogs,
  expandedRows,
  setExpandedRows,
  apiRef
}) => {
  const { logFilter } = useSyncLogsContext();
  const { loadLogActionFolderDetails, stopLogActionFolderDetailsPolling } = useLazyLoadLogsActionFolderDetailsHook();

  const { id: fileSyncLogId = '' } = useParams();
  const [logFoldersInitiating, setLogFoldersInitiating] = useState(true);
  const [pollingItems, setPollingItems] = useState([]); /* Folders/Dest' Stack to be polled on auto refresh */
  const [rows, setRows] = useState(
    LogFoldersDetailDataGridTableRows(logActionDetailsData?.syncLogActionDetails?.data?.destinations)
  );
  const handleCellComponent = ({ field, row }) => <LogFoldersDetailsDataGridCell field={field} row={row} />;

  const columns = LogFoldersDetailsDataGridTableColumns(handleCellComponent);

  const groupingColDef = {
    headerName: '',
    sortable: false,
    maxWidth: 50,
    renderCell: (params) => (
      <LogFoldersDetailsDataGridGroupingCell
        {...params}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        setPollingItems={setPollingItems}
        autoRefreshFolderDetails={autoRefreshFolderDetails}
      />
    )
  };

  useLogFolderDetailsDataGridTableHooks({
    fileSyncLogId,
    loadLogActionDetails,
    stopLogActionDetailsPolling,
    startLogActionDetailsPolling,
    setLogFoldersInitiating,
    autoRefreshFolderDetails,
    pollingItems,
    setRows,
    rows,
    logFoldersInitiating,
    logActionDetailsData,
    loadLogActionFolderDetails,
    setPollingItems,
    stopLogActionFolderDetailsPolling,
    apiRef,
    setExpandedRows,
    setManuallyLoadingLogs,
    logFilter
  });

  return (
    <ItemsDatagridPro
      isGroupExpandedByDefault={(node) => expandedRows?.find(({ id }) => id === node.id)}
      apiRef={apiRef}
      groupingColDef={groupingColDef}
      components={{
        NoRowsOverlay: () => <NoRowsOverlay text={<NoRowsOverlayText />} />
      }}
      columns={columns}
      rows={rows}
      loading={logFoldersInitiating}
      {...DATAGRID_PROPS}
    />
  );
};

export default LogFoldersDetailsDataGridTable;
