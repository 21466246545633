import { gql, useMutation } from '@apollo/client';

import { syncUpdateMappingConfigurations as SYNC_UPDATE_MAPPING_CONFIGURATIONS } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { copyFilesMappingConfigPayload } from 'modules/Sync/WorkflowEditor/helpers/CopyFilesHelpers/copyFilesPayloadHelper';

const useSaveFlowConfigApiAction = (queryOptions) => {
  const [saveFlowConfiguration] = useMutation(gql(SYNC_UPDATE_MAPPING_CONFIGURATIONS), queryOptions);
  const { handleResponse } = useGraphqlResponseHandler();

  const saveFlowConfig = ({
    mappingId,
    mappingFlows,
    onCompleted,
    onFailed,
    successMessage = 'Saved mapping flow configuration successfully'
  }) =>
    handleResponse(
      saveFlowConfiguration,
      {
        variables: {
          body: {
            MappingId: mappingId,
            SolitaryFlowConfigurations: copyFilesMappingConfigPayload(mappingFlows)
          }
        }
      },
      { successMessage },
      onCompleted,
      onFailed
    );
  return { saveFlowConfig };
};

export default useSaveFlowConfigApiAction;
