import React, { useEffect } from 'react';

import { FormControl, Typography } from '@mui/material';
import { useStoreState } from 'react-flow-renderer';
import { useForm } from 'react-hook-form';

import CustomModal from 'modules/Sync/components/CustomModal';
import { ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import useSaveMappingAction from 'modules/Sync/WorkflowEditor/editorHooks/useSaveMappingAction';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';

const ConnectorAliasModal = ({
  connectorAlias = '',
  connectorId = '',
  open = false,
  elements = [],
  onClose,
  onSubmit
}) => {
  const { currentNodeStatus } = useSyncWorkflowEditorContext();
  const { saveMappingAction } = useSaveMappingAction();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      alias: connectorAlias
    }
  });
  const {
    control,
    watch,
    handleSubmit,
    setError,
    reset,
    formState: { errors }
  } = methods;
  const { alias } = watch();
  const edges = useStoreState((store) => store.edges);
  const connectedConnector = edges.some(({ source, target }) => source === connectorId || target === connectorId);

  const validateAlias = () => {
    if (!alias) {
      setError('alias', { type: 'required', message: 'Alias is Required' });
      return false;
    }

    const elementWithSameAlias = elements.find(
      ({ id: connectorElementId, data: { connectorAlias } = {} }) =>
        connectorAlias === alias && connectorElementId !== connectorId
    );

    if (elementWithSameAlias) {
      setError('alias', { type: 'unique', message: 'Alias must be unique' });
      return false;
    }
    return true;
  };

  const onModalClose = () => {
    if (currentNodeStatus) {
      return onClose();
    }

    if (validateAlias() || connectedConnector) {
      return onClose?.(true);
    }

    return onClose();
  };

  const onModalSubmit = handleSubmit((formValues) => {
    if (validateAlias()) {
      onSubmit?.(connectorId, { connectorAlias: formValues?.alias }, () =>
        saveMappingAction({ successMessage: connectedConnector ? 'Connector alias updated' : '' })
      );
      return onClose(true);
    }
    return null;
  });

  const onKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === 'Escape') onModalClose();
  };

  useEffect(() => {
    reset({ alias: connectorAlias });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={
        <Typography fontWeight={600} variant="body4">
          Connector alias
        </Typography>
      }
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={<ModalFooter onModalClose={onModalClose} createBtnText="Done" onSubmit={onModalSubmit} />}
    >
      <FormControl size="small" fullWidth>
        <ControlTextField
          autoFocus
          control={control}
          name="alias"
          label="Alias *"
          maxLength={connectorAlias.length > 32 ? connectorAlias.length : 32}
          errors={errors}
          requiredNoSpace
          errorOnFocusOnly
          onKeyDown={onKeyDown}
          sx={{ margin: 0 }}
        />
      </FormControl>
    </CustomModal>
  );
};

export default ConnectorAliasModal;
