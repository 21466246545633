import { useEffect } from 'react';

import { Box, FormControl, FormControlLabel, Typography } from '@mui/material';
import SwitchComponent from '@mui/material/Switch';
import { useFormContext, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { useImportWorkflowsContext } from 'modules/Sync/Connections/components/ImportWorkflowsContext';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { CONNECTION_NAME, CONNECTION_TYPES, MIGRATION_TOOL } from 'modules/Sync/Constants/constants';
import { importErrors } from 'modules/Sync/helpers/extractWorkflowConfig';
import WorkflowTextField from 'modules/Sync/Workflows/components/WorkflowTextField';
import { surfaceColors, syncColors } from 'styles/theme/colors';

import ImportMappingWindowsSelect from './ImportMappingWindowsSelect';

const { WINDOWS } = CONNECTION_TYPES;
const styles = {
  containerStyles: {
    border: 1,
    borderRadius: '4px',
    borderColor: 'other.border',
    padding: '12px 24px',
    width: '100%',
    height: 'auto',
    overflowY: 'auto',
    mb: 2
  },
  subContainerSTyle: { display: 'flex', justifyContent: 'space-between' }
};

const getMappingNameAttr = (index, mapIndex, isSource) => {
  if (isSource) return `workflowData.${index}.mappings.${mapIndex}.solitaryFlowConfigurations.${0}.source.connectionId`;

  return `workflowData.${index}.mappings.${mapIndex}.solitaryFlowConfigurations.${0}.destinations.${0}.connectionId`;
};
const getTogglleNameAttr = (index, mapIndex) => `workflowData.${index}.mappings.${mapIndex}.shouldOverwrite`;

const OverwriteToggle = ({ name, control }) => {
  const { watch } = useFormContext();
  const value = watch(name);

  return (
    <Box mt={2} textAlign="end">
      <Typography variant="formBoldLabel">Do you want to overwrite this mapping?</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <FormControlLabel
            sx={{ mr: 0, ml: 1 }}
            control={
              <SwitchComponent sx={{ color: syncColors.text }} color="warning" checked={value} onChange={onChange} />
            }
          />
        )}
      />
    </Box>
  );
};

const WorkflowNameField = (props) => {
  const { setError, setValue } = useFormContext();
  const { index, control, isAdded, workflowName, update, data, name } = props;
  const { workflowError } = data;

  const onBlurField = (event) => {
    const { value } = event.target;
    if (value) update(index, { ...data, workflowName: value });
  };

  useEffect(() => {
    if (workflowError) setError(name, { message: workflowError });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setValue(name, workflowName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl fullWidth sx={{ mb: 1, mt: -2 }}>
      <WorkflowTextField
        key={data?.workflowId}
        control={control}
        name={name}
        label="Workflow name*"
        defaultValue={workflowName}
        maxLength={32}
        disabled={isAdded}
        errorOnFocusOnly
        onBlur={onBlurField}
      />
    </FormControl>
  );
};

const MappingNameField = (props) => {
  const { control, name, data, workflowData, workflowId } = props;
  const { mappingName, mappingId, mappingError } = data || {};
  const fieldVisibility = mappingError ? 'block' : 'none';
  const mappingDefaultValue = workflowData
    .find((item) => item.workflowId === workflowId)
    ?.mappings?.find((item) => item.mappingId === mappingId)?.mappingName;

  const { setError, setValue } = useFormContext();
  useEffect(() => {
    if (mappingError) setError(name, { message: mappingError });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setValue(name, mappingName || mappingDefaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingName]);

  return (
    <>
      {!mappingError && (
        <Typography variant="formBoldLabel" sx={{ display: 'flex', gap: '10px', alignItems: 'center', width: '100%' }}>
          Mapping Name: {mappingName}
        </Typography>
      )}

      <WorkflowTextField
        key={mappingId}
        control={control}
        name={name}
        label="Mapping name*"
        defaultValue={mappingName}
        maxLength={32}
        errorOnFocusOnly
        disabled={!mappingError}
        sx={{ width: '100%', display: fieldVisibility }}
      />
    </>
  );
};

const ErrorItem = ({ text }) => (
  <Typography variant="caption" color="red" component="p">
    {text}
  </Typography>
);

const SourceItem = ({
  mapping,
  sourceConnectionNameInput,
  windowsOptions,
  isAdded,
  control,
  mappingId,
  updateWindowsConnection,
  data
}) => {
  if (mapping?.source?.connectionName !== CONNECTION_NAME[CONNECTION_TYPES.WINDOWS])
    return (
      <>
        <Typography variant="caption" paragraph mb={0} color={surfaceColors.lightSurface.secondary}>
          Source
        </Typography>
        <Typography variant="subtitle1" component="div">
          {mapping?.source?.connectionName}
        </Typography>
      </>
    );

  return (
    <ImportMappingWindowsSelect
      connectionNameInput={sourceConnectionNameInput}
      windowsOptions={windowsOptions}
      isAdded={isAdded}
      control={control}
      isSource
      mappingId={mappingId}
      workflowId={data?.workflowId}
      updateWindowsConnection={updateWindowsConnection}
      label="Source"
    />
  );
};

const DestinationItem = ({
  mapping,
  destinationConnectionNameInput,
  windowsOptions,
  isAdded,
  control,
  mappingId,
  updateWindowsConnection,
  data
}) => {
  if (mapping?.destinations?.[0]?.connectionName !== CONNECTION_NAME[CONNECTION_TYPES.WINDOWS])
    return (
      <>
        <Typography variant="caption" paragraph mb={0} color={surfaceColors.lightSurface.secondary}>
          Destination
        </Typography>
        <Typography variant="subtitle1" component="div">
          {mapping?.destinations?.[0]?.connectionName}
        </Typography>
      </>
    );

  return (
    <ImportMappingWindowsSelect
      connectionNameInput={destinationConnectionNameInput}
      windowsOptions={windowsOptions}
      isAdded={isAdded}
      control={control}
      isSource={false}
      mappingId={mappingId}
      workflowId={data?.workflowId}
      updateWindowsConnection={updateWindowsConnection}
      label="Destination"
    />
  );
};

const ImportMappingProperties = ({ data, index, workflowName, update, updateWindowsConnection }) => {
  const { isAdded } = importErrors(MIGRATION_TOOL.WORKFLOW_ERROR, data);
  const { allWindowsConnections } = useSyncConnectionsContext();
  const { workflowData } = useImportWorkflowsContext();
  const { control } = useFormContext();

  const windowsOptions = allWindowsConnections?.reduce((acc, connection) => {
    if (connection.type === WINDOWS)
      acc.push({ value: connection.id, text: connection?.name, label: connection?.name });

    return acc;
  }, []);

  return (
    <Box>
      <WorkflowNameField
        index={index}
        control={control}
        isAdded={isAdded}
        workflowName={workflowName}
        name={`workflowData.${index}.workflowName`}
        update={update}
        data={data}
      />

      {data?.mappings?.map((mappingObj, mapIndex) => {
        const { isAdded } = importErrors(MIGRATION_TOOL.MAPPING_ERROR, mappingObj);
        const { mappingError, mappingId } = mappingObj;
        const mapping = mappingObj?.solitaryFlowConfigurations?.[0];

        const sourceConnectionNameInput = getMappingNameAttr(index, mapIndex, true);
        const destinationConnectionNameInput = getMappingNameAttr(index, mapIndex);
        const mappingFieldName = `workflowData.${index}.mappings.${mapIndex}.mappingName`;

        return (
          <Box sx={styles.containerStyles}>
            <Box sx={{ ...styles.subContainerSTyle, '.MuiBox-root': mappingError ? { flexBasis: '100%' } : {} }}>
              <MappingNameField
                key={mappingFieldName}
                control={control}
                name={mappingFieldName}
                mapIndex={mapIndex}
                data={mappingObj}
                workflowData={workflowData}
                workflowId={data?.workflowId}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }} key={uuidv4()}>
              <Box>
                <SourceItem
                  mapping={mapping}
                  sourceConnectionNameInput={sourceConnectionNameInput}
                  windowsOptions={windowsOptions}
                  isAdded={isAdded}
                  control={control}
                  mappingId={mappingId}
                  updateWindowsConnection={updateWindowsConnection}
                  data={data}
                />
              </Box>

              <Box>
                <DestinationItem
                  mapping={mapping}
                  destinationConnectionNameInput={destinationConnectionNameInput}
                  windowsOptions={windowsOptions}
                  isAdded={isAdded}
                  control={control}
                  mappingId={mappingId}
                  updateWindowsConnection={updateWindowsConnection}
                  data={data}
                />
              </Box>
            </Box>

            {(mappingObj?.isDuplicate || Object.prototype.hasOwnProperty.call(mappingObj, 'shouldOverwrite')) &&
              !isAdded && <OverwriteToggle name={getTogglleNameAttr(index, mapIndex)} control={control} />}
          </Box>
        );
      })}

      {data?.errors?.map((err) => (
        <ErrorItem text={err} />
      ))}
    </Box>
  );
};
export default ImportMappingProperties;
