import React from 'react';

import { Modal } from 'components';
import { getMicroFeContainer } from 'helpers/domHelpers';
import { actionColors } from 'styles/theme/colors';

const CustomModal = ({ children, draggable = false, ...rest }) => (
  <Modal
    {...rest}
    draggable={draggable}
    closeButtonStyles={{ left: 16 }}
    titleContainerStyles={{ cursor: draggable ? 'move' : 'inherit' }}
    closeButtonColor={actionColors.lightSurface.active}
    container={getMicroFeContainer}
  >
    {children}
  </Modal>
);

export default CustomModal;
