import React from 'react';

import Chip from '@mui/material/Chip';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';

const Search = ({
  handleSearch,
  disabled,
  placeholder = 'Search…',
  sx,
  startAdornment = null,
  ...rest
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [searchFilter, setSearchFilter] = React.useState('');

  const updateSearchFilter = (newValue) => {
    handleSearch(newValue);
    setSearchFilter(newValue);
  };

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      updateSearchFilter(inputValue);
      setInputValue('');
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        marginLeft: '28px',
        ...sx
      }}
    >
      {searchFilter && (
        <Chip label={searchFilter} onDelete={() => updateSearchFilter('')} sx={{ mr: 2 }} />
      )}
      <SearchInput
        value={inputValue}
        onKeyDown={onEnter}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        startAdornment={startAdornment}
        {...rest}
      />
    </Stack>
  );
};

export default Search;

const SearchInput = ({ sx, ...props }) => (
  <InputBase
    sx={{
      minWidth: 400,
      maxHeight: 32,
      backgroundColor: '#EEEEEE',
      borderRadius: '5px',
      paddingLeft: '16px',
      width: '100%',
      ...sx
    }}
    {...props}
  />
);
