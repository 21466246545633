import React, { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { CreateButton } from 'components';
import { Custom, IconStyled } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { SyncStatusBackground, SyncStatusLabel } from 'modules/Sync/components/SyncStatusHig';
import { LOGS_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import ErrorCodeBtn from 'modules/Sync/Logs/components/ErrorCodeBtn';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import { actionColors, syncColors } from 'styles/theme/colors';

const TOOLTIP_STYLES = { width: '100%', display: 'inline' };

const DownloadLogsButton = ({ onClick }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }} width="100%">
    <CreateButton
      buttonText="Download the log to view more"
      onClick={onClick}
      variant="text"
      startIcon={<Custom.Download2 />}
      sx={{
        '&.MuiButton-root': {
          width: '100%',
          color: syncColors.text,
          '&:hover': { backgroundColor: 'transparent' },
          '&:disabled': { color: 'action.lightSurface.disabled' }
        }
      }}
    />
  </Box>
);

const DownloadIcon = ({ openDownloadLogsModal }) => (
  <NavItemTooltip title="Download logs">
    <Custom.Download2 onClick={openDownloadLogsModal} />
  </NavItemTooltip>
);

const syncStatusIndicator = (status, sx) => {
  const title = SyncStatusLabel(status);
  const backgroundColor = SyncStatusBackground(status);
  return (
    <NavItemTooltip title={title}>
      <Stack gap={1} direction="horizontal" display="flex" alignItems="center" sx={sx}>
        <Box
          sx={{
            height: 8,
            width: 8,
            borderRadius: '50%',
            justifyContent: 'right',
            backgroundColor
          }}
        />
      </Stack>
    </NavItemTooltip>
  );
};

const CellTooltip = ({ title, children, ...rest }) => (
  <CustomSyncTooltip title={title} tooltipSx={TOOLTIP_STYLES} noWrapper {...rest}>
    {children}
  </CustomSyncTooltip>
);

const ErrorDetails = ({ row, handleRetrySync, isResyncAllowed }) => (
  <>
    <ErrorCodeBtn errorCode={row.errorCode} resync={handleRetrySync} isResyncAllowed={isResyncAllowed} />
    <Custom.SyncIcon
      sx={{ width: 20, height: 20, visibility: isResyncAllowed ? 'visible' : 'hidden' }}
      onClick={handleRetrySync}
    />
  </>
);

const RealTimeLogsDataGridTableCell = ({ field, row, setActiveModal, retrySync, apiRef }) => {
  const [isResyncEnabled, setIsResyncEnabled] = useState(true);
  const { setActiveRealtimeLogDate } = useSyncLogsContext();

  const disableResync = () => {
    setIsResyncEnabled(false);
    apiRef.current.updateRows([{ ...row, isResyncAllowed: false }]);
  };

  const isResyncAllowed = isResyncEnabled && row?.isResyncAllowed;

  const openDownloadLogsModal = () => {
    setActiveRealtimeLogDate(row?.date);
    setActiveModal(LOGS_MODAL_KEYS.DOWNLOAD_LOGS);
  };

  const handleRetrySync = () => {
    if (!isResyncAllowed) return;
    retrySync(row?.fileSyncLogId, disableResync);
  };

  if (row?.isDownloadLogs) return field === 'name' && <DownloadLogsButton onClick={openDownloadLogsModal} />;

  if (field === 'isContactSupportTeam' && row?.fileName) return row[field] ? '' : <Custom.Mail />;

  if (field === 'name') {
    return (
      <Stack
        spacing={1}
        direction="row"
        sx={{ width: '100%', '& .MuiTypography-body2': { display: 'flex' }, '& .action-details': { ml: 7 } }}
      >
        {row?.date && (
          <Typography variant="body2">
            <IconStyled sx={{ ml: 0, mr: 1.5, alignItems: 'center', display: 'flex' }} icon={<Custom.Calendar />} />
            {row.name}
          </Typography>
        )}
        {row?.fileName && (
          <Typography variant="body2" ml={0}>
            <IconStyled
              sx={{ ml: 1.5, mr: 1.5, alignItems: 'center', display: 'flex' }}
              color={actionColors.lightSurface.active}
              icon={<Custom.AttachFile />}
            />
            <CellTooltip title={row.mappingName}>{row.name}</CellTooltip>
          </Typography>
        )}
        {row?.isSolitaryFlow && (
          <Typography variant="body2" ml={3}>
            <IconStyled
              sx={{ ml: 3, mr: 1.5, alignItems: 'center', display: 'flex' }}
              color={actionColors.lightSurface.active}
              icon={<Custom.Mapping />}
            />
            <CellTooltip title={row.mappingName}>{row.name}</CellTooltip>
          </Typography>
        )}
        {row?.isSource && (
          <Typography variant="body2" justifyContent="start" width="80%" className="action-details">
            <Stack gap={6} direction="row" width="100%" sx={{ '& div': { display: 'flex', width: '50%' } }}>
              <Box>
                <strong>Source:</strong>&nbsp;&nbsp;
                <CellTooltip title={row.source}>{row.source}</CellTooltip>
              </Box>
              <Box>
                <strong>Folder:</strong>&nbsp;&nbsp;
                <CellTooltip title={row.name}>{row.name}</CellTooltip>
              </Box>
            </Stack>
          </Typography>
        )}
        {row?.syncStatus && (
          <>
            <Typography variant="body2" justifyContent="start" width="80%" className="action-details">
              {syncStatusIndicator(row.syncStatus, { ml: -3, mr: 2 })}
              <Stack
                gap={6}
                direction="row"
                sx={{ width: '100%', position: 'relative', '& div': { display: 'flex', width: '50%' } }}
              >
                <Box alignItems="center">
                  <strong>Destination: {row.serial}</strong>&nbsp;&nbsp;
                  <CellTooltip title={row.destination}>{row.destination}</CellTooltip>
                </Box>
                <Box alignItems="center" ml={6.25}>
                  <strong>Folder:</strong>&nbsp;&nbsp;
                  <CellTooltip title={row.name}>{row.name}</CellTooltip>
                </Box>
              </Stack>
            </Typography>
            <Box gap={2} display="flex" width="20%" alignItems="center" justifyContent="end" pr={2.5}>
              {row?.errorCode && (
                <ErrorDetails row={row} handleRetrySync={handleRetrySync} isResyncAllowed={isResyncAllowed} />
              )}
            </Box>
          </>
        )}
      </Stack>
    );
  }

  if (field === '*download' && row?.isParent) return <DownloadIcon openDownloadLogsModal={openDownloadLogsModal} />;

  return row[field] ? <CellTooltip title={row[field]}>{row[field]}</CellTooltip> : null;
};

export default RealTimeLogsDataGridTableCell;
