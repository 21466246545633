import { useCallback } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Divider, FormControl, InputLabel, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import InputSelectField from 'components/FormComponents/InputSelectField';
import { syncCreateWorkflow as SYNC_CREATE_WORKFLOW } from 'graphql/mutations';
import { syncValidateName as SYNC_VALIDATE_NAME } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { ROUTES, VALIDATION_ENTITY, WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import useTemplatesLazyPaginationHook from 'modules/Sync/Workflows/Contexts/useTemplatesLazyPaginationHook';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';

import MappingTextField from './MappingTextField';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';
import WorkflowTextField from './WorkflowTextField';

const { SYNC, MAPPING_EDITOR } = ROUTES;

const defaultFormValues = { workflowName: '', mappingName: '', templateId: '' };

// eslint-disable-next-line max-lines-per-function
const CreateWorkflowModal = ({ onClose }) => {
  const { activeModal } = useSyncWorkflowsContext();
  const navigate = useNavigate();
  const open = activeModal === WORKFLOW_MODAL_KEYS.CREATE_WORKFLOW;
  const { handleResponse } = useGraphqlResponseHandler();
  const methods = useForm({
    mode: 'all',
    defaultValues: defaultFormValues
  });
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors, isValid }
  } = methods;
  const { allTemplatesList, loadingAllTemplates } = useTemplatesLazyPaginationHook();

  const [createWorkflow, { loading }] = useMutation(gql(SYNC_CREATE_WORKFLOW), {
    refetchQueries: ['SyncWorkflows']
  });

  const [validateName, { errors: validationErrors }] = useLazyQuery(gql(SYNC_VALIDATE_NAME), {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: { params: { entity: VALIDATION_ENTITY.WORKFLOW } }
  });

  const redirectToWorkflowCanvas = (id) => (id ? navigate(`${SYNC}${MAPPING_EDITOR.replace(':id', id)}`) : '');

  const onModalClose = () => {
    onClose?.();
    reset(defaultFormValues);
  };

  const onSubmit = handleSubmit((formValues) => {
    const { workflowName, mappingName, templateId } = formValues;

    return handleResponse(
      createWorkflow,
      {
        variables: {
          body: {
            WorkflowName: workflowName,
            MappingName: mappingName,
            ...(templateId ? { TemplateMappingId: templateId } : {})
          }
        }
      },
      {
        successMessage: 'Workflow successfully created'
      },
      (response) => {
        const { syncCreateWorkflow: { workflowMapping: { workflowMappingId } = {} } = {} } = response || {};
        redirectToWorkflowCanvas(workflowMappingId);
      }
    );
  });

  const handleChange = useCallback(
    (searchName) => {
      validateName({
        variables: {
          query: {
            name: encodeURIComponent(searchName.trim())
          }
        }
      });
    },
    [validateName]
  );

  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={<ModalTitle title="Create workflow" />}
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={
        <ModalFooter loading={loading} onModalClose={onModalClose} onSubmit={onSubmit} onSubmitDisabled={!isValid} />
      }
    >
      <Stack gap={1}>
        <FormControl size="small" fullWidth>
          <WorkflowTextField
            control={control}
            disabled={loading}
            sx={{ marginBottom: 0, marginTop: 0 }}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            validateChange={handleChange}
            validationErrors={validationErrors}
          />
          <MappingTextField disabled={loading} control={control} errors={errors} />
        </FormControl>
        <Divider />
        <InputLabel id="tempate-section">
          <Typography variant="subtitle2" color="primary">
            Optional: Select an existing template
          </Typography>
        </InputLabel>
        <FormControl size="small" fullWidth>
          <InputSelectField
            name="templateId"
            label="Template"
            control={control}
            color="secondary"
            size="small"
            fullWidth
            options={[
              { label: 'None', value: '' },
              ...(allTemplatesList?.length
                ? allTemplatesList?.map((option) => ({
                    ...option,
                    label: option.templateName,
                    value: option.templateId
                  }))
                : [])
            ]}
            selectProps={{
              SelectProps: {
                loading: loadingAllTemplates,
                disabled: loadingAllTemplates || !allTemplatesList?.length,
                displayEmpty: true
              }
            }}
          />
        </FormControl>
      </Stack>
    </CustomModal>
  );
};

export default CreateWorkflowModal;
