import { ARCHIVED_WORKFLOW_GRID_REFRESH_EVENT, CONNECTION_GRID_REFRESH_EVENT, TEMPLATE_GRID_REFRESH_EVENT, WEBHOOK_GRID_REFRESH_EVENT, WORKFLOW_GRID_REFRESH_EVENT } from 'modules/Sync/Constants/constants';

export const getShadowDocument = () =>
  document?.getElementsByTagName('sync-module-content-web-component')?.[0]?.shadowRoot || document;

const getRootServerRoot = () => document.querySelector('[data-id="micro-fe-root"]');

export const getMicroFeContainer = () =>
  document
    ?.getElementsByTagName('sync-module-content-web-component')?.[0]
    ?.shadowRoot?.getElementById('micro-fe-app') || getRootServerRoot();

export const dispatchMicroFeLoadedEvent = () => {
  const customEvent = new CustomEvent('sync_module_content_web_component_loaded');
  document.dispatchEvent(customEvent);
};

export const dispatchWorkflowGridEvent = () => {
  const customEvent = new CustomEvent(WORKFLOW_GRID_REFRESH_EVENT);
  document.dispatchEvent(customEvent);
};

export const dispatchArchivedWorkflowGridEvent = () => {
  const customEvent = new CustomEvent(ARCHIVED_WORKFLOW_GRID_REFRESH_EVENT);
  document.dispatchEvent(customEvent);
};

export const dispatchTemplateGridEvent = () => {
  const customEvent = new CustomEvent(TEMPLATE_GRID_REFRESH_EVENT);
  document.dispatchEvent(customEvent);
};

export const dispatchConnectionGridEvent = () => {
  const customEvent = new CustomEvent(CONNECTION_GRID_REFRESH_EVENT);
  document.dispatchEvent(customEvent);
};

export const dispatchWebhookGridEvent = () => {
  const customEvent = new CustomEvent(WEBHOOK_GRID_REFRESH_EVENT);
  document.dispatchEvent(customEvent);
};

export const hideOverflowOnMicroFeContainer = () => {
  const container = document.getElementById('sync-module-content-web-component');
  if (container) container.style.overflow = 'hidden';
};

export const renderingInMicroFE = !!getRootServerRoot();
