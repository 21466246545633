export const getForgeSelectEventHandlers = ({ updateFormValues, lazyLoadProjects, connectionId }) => {
  const selectHubHandler = ({ target: { value: hubId } = {} }, childElement) => {
    updateFormValues({
      hub: { id: hubId, name: childElement?.props?.dataText },
      project: { id: '', name: '' },
      filterCriteria: [],
      isSubmitted: false
    });
    lazyLoadProjects({ connectionId, hubId });
  };

  const selectProjectHandler = ({ target: { value: projectId } = {} }, childElement) => {
    updateFormValues({
      project: { id: projectId, name: childElement?.props?.dataText },
      projects: [{ id: projectId, name: childElement?.props?.dataText }],
      filterCriteria: [],
      isSubmitted: true
    });
  };

  return {
    selectHubHandler,
    selectProjectHandler
  };
};
