import { useEffect } from 'react';

import { gql, useApolloClient, useMutation } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { createAutodeskFieldFolder as CREATE_AUTODESK_FIELD_FOLDER } from 'graphql/mutations';
import { getAutodeskFieldFolders as GET_AUTODESK_FIELD_FOLDERS } from 'graphql/queries';
import useCache from 'hooks/useCache';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { DEFAULT_ROOT_FOLDER, DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import FoldersSelectDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown';

const {
  NETWORK_ONLY: { fetchPolicy: NETWORK_ONLY_FETCH_POLICY },
  NO_CACHE: { fetchPolicy: NO_CACHE_FETCH_POLICY }
} = FETCH_POLICIES;

const FETCH_FOLDERS_API = 'getAutodeskFieldFolders';

const formatFolders = (text) => text.match(/(?!.*\/).+/)?.[0] || DEFAULT_ROOT_FOLDER.TEXT;

const getExpandedFolderIds = (folder) =>
  folder?.id?.split('/')?.reduce((accumulator, currentFolderId) => {
    if (!accumulator.length) accumulator.push(currentFolderId);
    else accumulator.push(`${accumulator[accumulator.length - 1]}/${currentFolderId}`);
    return accumulator;
  }, []);

const AutodeskFieldFoldersDropdown = (props) => {
  const {
    connectionId,
    nodeId,
    isSource = false,
    disabled = false,
    isLoadingFolders = false,
    onFolderStackLoad = () => {},
    setParentFolderId
  } = props;
  const client = useApolloClient();
  const { getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const setFormValues = (props) => updateConnectorNodeValues({ isSource, nodeId, props });
  const { projects, project = projects?.[0], folder } = formValues;
  const { handleResponse } = useGraphqlResponseHandler();
  const { deleteCache } = useCache();

  useEffect(() => {
    if (folder?.id && folder?.name) {
      const expandedFolderIds = getExpandedFolderIds(folder);
      setFormValues({ expandedFolderIds });
      const closestParentFolderId = expandedFolderIds[expandedFolderIds?.length - 2] || '';
      setParentFolderId(closestParentFolderId);
      onFolderStackLoad?.(closestParentFolderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [makeForgeFolder] = useMutation(gql(CREATE_AUTODESK_FIELD_FOLDER));

  const fetchFieldFolders = async ({
    folderId,
    fetchPolicy = NO_CACHE_FETCH_POLICY,
    skip = 0,
    take = DROPDOWN_DEFAULT_PG_SIZE
  }) => {
    const { data } = await client.query({
      query: gql(GET_AUTODESK_FIELD_FOLDERS),
      variables: {
        query: {
          connectionId,
          projectId: project?.id,
          parentFolderId: encodeURIComponent(folderId),
          skip,
          take
        }
      },
      fetchPolicy
    });

    const response = data?.getAutodeskFieldFolders || [];
    const autodeskFieldFolders =
      response?.map(({ id, ...rest }) => ({ id: `${DEFAULT_ROOT_FOLDER.ID}/${id}`, ...rest })) || [];

    return { autodeskFieldFolders, response };
  };

  const fetchMoreFoldersHandler = async ({ folderId, skip, onCompleted }) => {
    const { autodeskFieldFolders, response } = await fetchFieldFolders({ folderId, skip });
    onCompleted(response);
    return autodeskFieldFolders;
  };

  const onFolderExpandHandler = async ({ folderId, onCompleted, skip, take }) => {
    const { autodeskFieldFolders } = await fetchFieldFolders({ folderId, skip, take });
    const expandedFolderIds = getExpandedFolderIds(folder);
    return onCompleted(autodeskFieldFolders, expandedFolderIds);
  };

  const onFolderSelectHandler = ({ folderId, onCompleted }) => onCompleted(formatFolders(folderId));

  const onCreateFolderHandler = async ({ folderId, folderName, onCompleted }) => {
    if (projects.length > 1) return;

    await handleResponse(
      makeForgeFolder,
      {
        variables: {
          query: { connectionId },
          body: { ProjectId: project?.id, ParentFolderId: folderId, Name: folderName }
        }
      },
      { successMessage: 'Created folder successfully' },
      async () => {
        const { autodeskFieldFolders } = await fetchFieldFolders({
          folderId,
          fetchPolicy: NETWORK_ONLY_FETCH_POLICY,
          skip: null,
          take: null
        });
        deleteCache(FETCH_FOLDERS_API);
        onCompleted(autodeskFieldFolders);
      }
    );
  };

  return (
    <FoldersSelectDropdown
      {...props}
      isLoading={isLoadingFolders}
      disabled={isLoadingFolders || disabled}
      onFolderExpandHandler={onFolderExpandHandler}
      onFolderSelectHandler={onFolderSelectHandler}
      onCreateFolderHandler={onCreateFolderHandler}
      fetchMoreFoldersHandler={fetchMoreFoldersHandler}
      formatText={formatFolders}
    />
  );
};

export default AutodeskFieldFoldersDropdown;
