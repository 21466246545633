import textVersion from 'textversionjs';

export const extractTextFromHTML = (htmlText) => {
  const conversionConfig = {
    linkProcess: (href, linkText) => `${linkText}-${href}`,
    imgProcess: (src, alt) => `${alt}-${src}`
  };
  const plainText = textVersion(htmlText, conversionConfig);
  return plainText?.trim();
};

const joinCloudSearchQuery = (str = '') =>
  (str ?? '')
    .split(' ')
    .filter((word) => word !== '')
    .map((word) => (!word.includes('%') && !word.includes('-') ? `(prefix '${word}') (term '${word}') ` : `'${word}' `))
    .join(' ');

export const encodeCloudSearchString = (str = '') =>
  new URLSearchParams((str ?? '').trim().replace(/[@#$%&=';,.<>_*]/g, ''))
    .toString()
    .replace('=', '')
    .replace(/\+/g, ' ');

export const joinedAndEncodedCloudSearchQuery = (str = '') => joinCloudSearchQuery(encodeCloudSearchString(str ?? ''));

export const stringifyCloudQuery = (str, id = '', noAssemblies = false) => {
  let query = id
    ? `(and (or (phrase boost%3D5 '${encodeCloudSearchString(str ?? '')}') ${joinedAndEncodedCloudSearchQuery(
        str ?? ''
      )}) (or (and '${id}') (prefix boost%3D5 '${id}')))`
    : `(and ${joinedAndEncodedCloudSearchQuery(str ?? '')})`;
  if (!noAssemblies) return query; // this could be useful later on
  query = query.substring(0, query.length - 1);
  query += " (and has_assembly%3A'0'))";
  return query;
};

export const stringifyCloudQueryPartId = (id) => `(and '${id}')`;

export const encodeURIFn = (str) =>
  encodeURIComponent(str)
    .replace(/[!]/g, '%21')
    .replace(/[*]/g, '%2A')
    .replace(/[']/g, '%27')
    .replace(/[(]/g, '%28')
    .replace(/[)]/g, '%29')
    .replace(/[\\]/g, '%5C');
// .replace(/[-]/g, '%2D')
// .replace(/[_]/g, '%5F')
// .replace(/[.]/g, '%2E')
// .replace(/[~]/g, '%7E');

export const encodeURIWithSpecialCharacterFn = (str) =>
  encodeURIComponent(str)
    .split("'")
    .join("\\'") // adding this because of the special character "'" in category name
    .replace(/[!]/g, '%21')
    .replace(/[*]/g, '%2A')
    .replace(/[']/g, '%27')
    .replace(/[(]/g, '%28')
    .replace(/[)]/g, '%29')
    .replace(/[\\]/g, '%5C');
// .replace(/[-]/g, '%2D')
// .replace(/[_]/g, '%5F')
// .replace(/[.]/g, '%2E')
// .replace(/[~]/g, '%7E');

// cloud search query: (and (prefix field=part_categories_path 'catalogId: pathCategoryPath'))
export const encodePartCategoryPathCloudSearchQuery = (pathCategoryPath, catalogId) => {
  const encodedCategoriesPath = encodeURIFn(
    `(and (prefix field=part_categories_path '${catalogId}: ${pathCategoryPath}'))`
  );
  return encodedCategoriesPath;
};

export const replaceSpaceToDash = (str) => {
  if (!str) return '';
  return str.replace(/\s+/g, '-').toLowerCase();
};

export const convertToCamelCase = (str) => {
  if (!str) return '';
  return str
    .replace(/\s(.)/g, ($1) => $1.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, ($1) => $1.toLowerCase());
};

export const convertToUpperFirstCase = (str, separator, concatenator = '') => {
  if (!str) return '';
  return str
    .split(separator)
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
    .join(concatenator);
};

export const sortAlphabetically = (a, b) => a.name.localeCompare(b.name);

export const slugify = (str) =>
  str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '');

export const truncateText = (text, maxLength) => {
  if (!text) return '';
  if (text?.length <= maxLength) return text;

  const truncatedText = text?.substring(0, maxLength - 3);
  return `${truncatedText}...${text.slice(-4)}`;
};

export const hashCode = (str) => {
  if (!str) return 0;
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i += 1) {
    const chr = str.charCodeAt(i);
    hash = Math.imul(31, hash) + chr;
    hash = Math.floor(hash);
  }
  return hash;
};
