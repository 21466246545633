const logFoldersDataGridStyles = {
  marginBottom: 1,
  '.MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '.expansion-icon': { display: 'flex', alignItems: 'center' },
  '.MuiDataGrid-row.folder': {
    '.name-cell': {
      paddingLeft: 2
    }
  }
};

export default logFoldersDataGridStyles;
