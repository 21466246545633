import React from 'react';

import DropdownFormControl from 'modules/Sync/WorkflowEditor/Utils/DropdownFormControl';

const ProcoreDocumentTypeDropdown = ({ value, onChange, loading, disabled, items, isSource }) => {
  const filteredItems = isSource ? items : items.filter(({ downloadOnly }) => !downloadOnly);

  return (
    <DropdownFormControl
      title="Document Type"
      id="document-type"
      labelId="document-type"
      label="document-type"
      disabled={disabled}
      loading={loading}
      value={loading ? '' : value}
      onChange={onChange}
      items={filteredItems}
      required
      enabledWhileLoading
    />
  );
};

export default ProcoreDocumentTypeDropdown;
