import { MAPPING_ACTIONS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';

const useEditAction = () => {
  const { setActionId, showActionsModal, showPublishModal, showManageIssuesModal } = useSyncWorkflowEditorContext();

  const editAction = (nodeId, action) => {
    setActionId(nodeId);
    if (action === MAPPING_ACTIONS.copyFiles) return showActionsModal();
    if (action === MAPPING_ACTIONS.publish) return showPublishModal();
    if (action === MAPPING_ACTIONS.manageIssues) return showManageIssuesModal();

    return showActionsModal();
  };

  return { editAction };
};

export default useEditAction;
