import React from 'react';

import { Typography, Stack } from '@mui/material';

import { getFormattedDateTime } from 'helpers/dateFunctions';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';

const EditorFooter = () => {
  const { lastSavedAt, lastSyncAt } = useSyncWorkflowEditorContext();

  return (
    <Stack
      spacing={6}
      direction="row"
      style={{
        position: 'absolute',
        bottom: 8,
        left: 86
      }}
    >
      {lastSavedAt && (
        <FooterText text="Last saved: " value={getFormattedDateTime(lastSavedAt, true)} />
      )}
      {lastSyncAt && (
        <FooterText text="Last synced: " value={getFormattedDateTime(lastSyncAt, true)} />
      )}
    </Stack>
  );
};

const FooterText = ({ text, value }) => (
  <Typography variant="body1" sx={footerStyles}>
    <Typography variant="span" sx={{ ...footerStyles, fontWeight: '500' }}>
      {text}
    </Typography>
    {value}
  </Typography>
);

export default EditorFooter;

const footerStyles = {
  fontSize: '14px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  textAlign: 'right',
  color: '#000000DE',
  whiteSpace: 'pre'
};
