import React from 'react';

import { Box, Skeleton } from '@mui/material';

const DropdownPaginationLoader = () => (
  <Box mb={2} sx={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '0 16px' }}>
    {[...Array(1).keys()].map(() => (
      <Skeleton animation="wave" />
    ))}
  </Box>
);

export default DropdownPaginationLoader;
