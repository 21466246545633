import { CONNECTION_TYPES } from 'modules/Sync/Constants/constants';

export const getSubmittedStatus = ({ connection, connectionType, hub, projects, files, file }) => {
  const { AUTODESKHQ, AUTODESKTEAMDOCS, WINDOWS } = CONNECTION_TYPES;
  const connType = connection?.type || connectionType;

  if (connType === AUTODESKHQ || connType === AUTODESKTEAMDOCS) {
    return Boolean(hub?.name && projects?.length);
  }

  if (connType === WINDOWS) {
    return Boolean(files?.length || file?.name);
  }
  return false;
};

export const validateFilterCriteria = (filterCriteria = []) => {
  if (!filterCriteria?.length) return true;
  return filterCriteria.every(({ value }) => !!value?.length);
};
