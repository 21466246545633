import React, { useCallback, useState } from 'react';

import { Button, MenuItem, Typography, useTheme } from '@mui/material';

import CustomMenu from 'components/Menu';
import CustomArrowDropDownIcon from 'modules/Sync/components/CustomArrowDropDownIcon';
import { WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import { syncColors } from 'styles/theme/colors';

const ButtonSx = {
  padding: '0 8px 0 8px',
  height: '32px',
  fontWeight: 500,
  border: `solid 1px ${syncColors.outlinedRestingBorder}`,
  color: syncColors.text
};

const ArchiveActionsMenu = ({ archivedWorkflows }) => {
  const {
    palette: { secondary }
  } = useTheme();
  const { selectedWorkflowIds, selectedMappingIds, selectedActionIds, setActiveModal } = useSyncWorkflowsContext();

  const isDeleteActionDisabled =
    (selectedWorkflowIds.length < 1 && selectedMappingIds.length < 1 && selectedActionIds.length < 1) ||
    !!archivedWorkflows?.syncArchivedWorkflows?.some(({ workflowMapping }) =>
      workflowMapping.some(
        ({ workflowMappingId, isSyncRunning }) => selectedMappingIds.includes(workflowMappingId) && isSyncRunning
      )
    );
  const isRestoreActionDisabled =
    selectedWorkflowIds.length < 1 && selectedMappingIds.length < 1 && selectedActionIds.length < 1;

  const [anchorEl, setAnchorEl] = useState(null);
  const updateAnchorEl = useCallback((element) => setAnchorEl(element), []);
  const open = !!anchorEl;

  const showDeleteModal = () => setActiveModal(WORKFLOW_MODAL_KEYS.DELETE_WORKFLOW);
  const showRestoreModal = () => setActiveModal(WORKFLOW_MODAL_KEYS.RESTORE_WORKFLOW);
  const handleClick = (e) => {
    updateAnchorEl(e.currentTarget);
  };
  const handleClose = () => updateAnchorEl(null);

  const handleRestoreModalOpen = (event) => {
    event.stopPropagation();
    showRestoreModal();
    handleClose();
  };
  const handleDeleteModalOpen = (event) => {
    event.stopPropagation();
    showDeleteModal();
    handleClose();
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        endIcon={<CustomArrowDropDownIcon />}
        onClick={handleClick}
        color="warning"
        sx={
          (ButtonSx,
          {
            padding: '0 8px 0 8px',
            height: '32px',
            fontWeight: 500,
            color: syncColors.text,
            border: `solid 1px ${syncColors.outlinedRestingBorder}`,
            background: `linear-gradient(90deg, transparent 67%, ${secondary.outlinedHoverBackground} 33%)`
          })
        }
      >
        ACTIONS
      </Button>
      <CustomMenu
        id="Actions for items"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        color="warning"
        MenuListProps={{
          sx: { width: '160px' }
        }}
      >
        <MenuItem onClick={handleRestoreModalOpen} disabled={isRestoreActionDisabled}>
          <Typography>Restore</Typography>
        </MenuItem>
        <MenuItem onClick={handleDeleteModalOpen} disabled={isDeleteActionDisabled}>
          <Typography color="error">Delete</Typography>
        </MenuItem>
      </CustomMenu>
    </>
  );
};

export default ArchiveActionsMenu;
