const WebhooksDataGridTableColumns = (handleCellComponent) => [
  {
    field: 'name',
    headerName: 'Webhooks',
    width: 800,
    minWidth: 200,
    maxWidth: 800,
    hideable: false,
    editable: false,
    sortable: false,
    resizable: true,
    renderCell: handleCellComponent
  },
  {
    field: 'lastSyncAt',
    headerName: '',
    width: 200,
    minWidth: 150,
    sortable: false,
    maxWidth: 200,
    resizable: true,
    hide: true,
    renderCell: handleCellComponent
  },
  {
    field: 'isActive',
    headerName: 'Status',
    width: 200,
    minWidth: 100,
    maxWidth: 200,
    sortable: true,
    resizable: true,
    renderCell: handleCellComponent
  },
  {
    field: 'lastSavedAt',
    headerName: '',
    width: 200,
    minWidth: 150,
    maxWidth: 200,
    sortable: false,
    resizable: true,
    hide: true,
    renderCell: handleCellComponent
  }
];

export default WebhooksDataGridTableColumns;
