import React from 'react';

import { FormControlLabel, Checkbox, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import CustomTooltip from 'components/Tooltip';
import { actionColors } from 'styles/theme/colors';

const AutoCompleteTooltip = ({ title, onChange, defaultChecked, checked }) => (
  <>
    <FormControlLabel
      control={
        <Checkbox size="small" color="warning" defaultChecked={defaultChecked} checked={checked} onChange={onChange} />
      }
      label={
        <Typography variant="body2">
          Filter by file type
          <CustomTooltip
            title={
              <ul
                style={{
                  whiteSpace: 'pre-line',
                  lineHeight: '16px',
                  paddingLeft: 20,
                  marginTop: 4,
                  marginBottom: 4
                }}
              >
                {title.split('\n').map((reason) => (
                  <li key={reason}>{reason}</li>
                ))}
              </ul>
            }
            placement="right"
            arrow
          >
            <Custom.InfoOutlineIcon
              fontSize="16px"
              sx={{
                color: actionColors.lightSurface.active,
                position: 'relative',
                top: 2,
                left: 10
              }}
            />
          </CustomTooltip>
        </Typography>
      }
    />
  </>
);

export default AutoCompleteTooltip;
