import { useEffect, useMemo, useState } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { v4 as uuidv4 } from 'uuid';

import ItemsDatagridPro from 'components/ItemsDatagridPro';
import { getTimeZone } from 'helpers/dateFunctions';
import { hashCode } from 'helpers/stringFunctions';
import ErrorCodeBtn from 'modules/Sync/Logs/components/ErrorCodeBtn';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import fileDetailsDataGridStyles from 'modules/Sync/Logs/LogsDetails/FileDetailsDataGridTable/fileDetailsDataGridStyles';
import NoRowsOverlay from 'modules/Sync/Logs/LogsDetails/NoRowsOverlay';

const NoRowsOverlayText = () => (
  <span>
    No syncs have been run. <br />
    There are no logs to display.
  </span>
);

const LogsDetails = (props) => {
  const { debugging, logDetails, logsDetailsPaginationHandler, loadingLogDetails, lazyLoadLogsDetails } = props;
  const apiRef = useGridApiRef();
  const { logsDetailsSearchPhrase, logFilter } = useSyncLogsContext();
  const { id: fileSyncLogId = '' } = useParams();
  const [rows, setRows] = useState([]);
  const handleCellComponent = ({ field, row }) => {
    if (field === 'errorCode') {
      const { errorCode = '' } = row;
      if (!errorCode) return <></>;
      return <ErrorCodeBtn errorCode={errorCode} resync={() => null} isResyncAllowed isResyncVisible={false} />;
    }

    if (row.isLast)
      return (
        <>
          {row[field]}
          <Waypoint key={rows.length} bottomOffset="-20%" onEnter={onScroll} />
        </>
      );

    return row[field] || null;
  };
  const timeZone = useMemo(() => getTimeZone(), []);

  const columns = [
    {
      field: 'logEntry',
      headerName: 'Log File',
      maxWidth: '80%',
      flex: 1,
      sortable: false,
      renderCell: handleCellComponent
    },
    {
      field: 'errorCode',
      headerName: 'Error code',
      maxWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: handleCellComponent
    }
  ];

  const onScroll = () => {
    const skip = rows?.length || 0;
    logsDetailsPaginationHandler(skip, {
      fileSyncLogId,
      searchPhrase: logsDetailsSearchPhrase,
      IsDebug: !!debugging,
      timeZone,
      logFilter
    });
  };

  useEffect(() => {
    const getRows = () =>
      logDetails?.syncLogFileDetails?.reduce((logs, currLog, currIndex, syncLogFileDetailsData) => {
        if (currLog) {
          const { logMessage, errorCode = '' } = currLog;
          logs.push({
            id: `${hashCode(logMessage + uuidv4())}`,
            logEntry: logMessage,
            errorCode,
            isLast: currIndex === syncLogFileDetailsData?.length - 1
          });
        }
        return logs;
      }, []) || [];
    setRows(getRows());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDetails?.syncLogFileDetails?.length]);

  useEffect(() => {
    lazyLoadLogsDetails({
      fileSyncLogId,
      IsDebug: !!debugging,
      searchPhrase: logsDetailsSearchPhrase,
      timeZone,
      logFilter
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSyncLogId, logFilter]);

  return (
    <ItemsDatagridPro
      apiRef={apiRef}
      components={{ NoRowsOverlay: () => <NoRowsOverlay text={<NoRowsOverlayText />} /> }}
      asyncApi={{ onScroll }}
      onRowsScrollEnd={onScroll}
      density="standard"
      checkboxSelection={false}
      disableColumnMenu
      disableSelectionOnClick
      columns={columns}
      rows={rows}
      experimentalFeatures={{ rowPinning: true, newEditingApi: true }}
      loading={loadingLogDetails}
      sx={fileDetailsDataGridStyles}
      getRowHeight={() => 'auto'}
    />
  );
};

export default LogsDetails;
