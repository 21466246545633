import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm } from 'react-hook-form';

import { CenteredLoadSpinner } from 'components';
import CardItem from 'components/CardItem';
import { ActionControl, ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import {
  connectionComponents,
  getConnectionDropDownValue,
  connectionDefaultForms
} from 'modules/Sync/Connections/ConnectionProperties/FormOptions';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';

const setFormConfiguration = (selectedConnection) => {
  const getConnectionTypeDefaultForm = connectionDefaultForms[selectedConnection?.connectionType] || (() => {});
  return getConnectionTypeDefaultForm(selectedConnection);
};

const formDefaultValues = (selectedConnection) => {
  if (!selectedConnection) return null;
  selectedConnection.configuration = selectedConnection?.configuration || {};
  const dataSource = selectedConnection?.connectionType
    ? getConnectionDropDownValue(selectedConnection?.connectionType)
    : '';

  return {
    dataSource,
    connectionName: selectedConnection?.connectionName || '',
    configuration: setFormConfiguration(selectedConnection)
  };
};

const ImportConnectionProperties = ({ currentConnection, setExpandHandler: setExpand }) => {
  const methods = useForm({ mode: 'all', defaultValues: formDefaultValues(currentConnection) });
  const { updateConnectionsAdd } = useSyncConnectionsContext();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = methods;

  const isNewForm = true;

  useEffect(() => {
    setValue('DesktopConnectionId', currentConnection.DesktopConnectionId);
  }, [currentConnection.DesktopConnectionId, setValue, currentConnection.isAdd]);

  const dataSource = currentConnection.connectionType;
  const { isAdded } = currentConnection;

  const handleOnSuccessCallback = (id, type, name) => {
    updateConnectionsAdd(id, type, name);
    setExpand(id, type);
  };

  const SelectedConnection = React.useMemo(() => {
    const SelectedElement = connectionComponents[dataSource];
    return SelectedElement && React.isValidElement(<SelectedElement />) ? (
      <Box>
        <ControlTextField
          key={currentConnection?.connectionId}
          control={control}
          name="connectionName"
          label="Name*"
          maxLength={32}
          errors={errors}
          requiredNoSpace
          errorOnFocusOnly
          disabled={isAdded}
          sx={{ mb: 2, mt: 1 }}
        />

        <SelectedElement
          key={currentConnection?.connectionId}
          actionRenderer={({ onSubmit, submitDisabled, loading, customRenderer }) => (
            <>
              {isAdded ? (
                <Button
                  sx={{
                    minWidth: '100%',
                    maxHeight: 32,
                    background: 'secondary.main',
                    fontSize: 14,
                    borderRadius: '4px',
                    '&:hover': { background: 'secondary.buttonHover' }
                  }}
                  disabled
                >
                  Connector added
                </Button>
              ) : (
                <ActionControl
                  isNewForm={isNewForm}
                  onSubmit={handleSubmit(onSubmit)}
                  isSubmitDisabled={submitDisabled}
                  customRenderer={customRenderer}
                />
              )}
              {loading && <CenteredLoadSpinner />}
            </>
          )}
          dataSource={dataSource}
          connectionSource={dataSource}
          isAutodeskRadioDisabled
          disableAllFields={isAdded}
          onSuccessCallback={handleOnSuccessCallback}
        />
      </Box>
    ) : (
      <>Please select a data source</>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, isAdded]);

  return (
    <FormProvider {...methods}>
      <CardItem showHeader={false} containerSx={{ height: 'auto' }}>
        <Stack>{SelectedConnection}</Stack>
      </CardItem>
    </FormProvider>
  );
};
export default ImportConnectionProperties;
