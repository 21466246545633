export const MANAGE_ISSUES_FILTERS = {
  STATUS: 'Status',
  ISSUE_TYPE: 'Issue Type',
  ROOT_CAUSE: 'Root Cause',
  LOCATION: 'Location',
  ASSIGNED_TO: 'Assigned To',
  CREATED_BY: 'Created By',
  OWNER: 'Owner',
  DUE_DATE: 'Due Date',
  CREATED_AT: 'Created Date'
};

export const MANAGE_ISSUES_FILTERS_KEYS = {
  status: 'status',
  ownerId: 'ownerId',
  issueSubtypeId: 'issueSubtypeId',
  rootCauseId: 'rootCauseId',
  locationId: 'locationId',
  assignedTo: 'assignedTo',
  createdBy: 'createdBy',
  dueDate: 'dueDate',
  createdAt: 'createdAt'
};

export const MANAGE_ISSUES_FILTERS_KEYMAP = {
  status: 'Status',
  ownerId: 'Owner',
  issueSubtypeId: 'Issue Type',
  rootCauseId: 'Root Cause',
  locationId: 'Location',
  assignedTo: 'Assigned To',
  createdBy: 'Created By',
  dueDate: 'Due Date',
  createdAt: 'Created Date'
};

export const DATE_FILTER_SEPARATOR = '..';
