import { gql } from '@apollo/client';
import { saveAs } from 'file-saver';
import fileDownload from 'js-file-download';
import JSZip from 'jszip';

import getClient from 'apollo/client';
import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncLogFileDetailsWithCount as SYNC_LOG_FILE_DETAILS_WITH_COUNT } from 'graphql/queries';
import { getTimeZone } from 'helpers/dateFunctions';

export const getLogsFromAPI = async (requestType, IsDebug, skip, syncInProgress, take) => {
  const client = await getClient();
  const response = await client.query({
    query: gql(SYNC_LOG_FILE_DETAILS_WITH_COUNT),
    variables: {
      query: {
        IsDebug,
        timeZone: getTimeZone(),
        skip,
        take,
        ...requestType
      }
    },
    fetchPolicy: syncInProgress ? FETCH_POLICIES.NO_CACHE.fetchPolicy : FETCH_POLICIES.CACHE_FIRST.fetchPolicy,
    notifyOnNetworkStatusChange: true
  });
  return response.data.syncLogFileDetailsWithCount;
};

export const saveLogFileToPC = (logs, fileName) => {
  const data = logs.join('\n');
  fileDownload(data, fileName);
};

export const createZipAndDownloadLogs = (logsData, zipName, logFileNamePrefix) => {
  const zip = new JSZip();
  Object.keys(logsData).forEach((logItem) => {
    const data = logsData[logItem].logsFetched.join('\n');
    const { actionAlias } = logsData[logItem];
    if (data) zip.file(`${logFileNamePrefix}-${actionAlias}.txt`, data);
  });
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, zipName);
  });
};

export const getLogFileName = ({
  workflowName = '',
  mappingName = '',
  actionName = '',
  fileSyncLogId = '',
  solitaryFlowId = '',
  mappingId = '',
  lastSyncFileDate = '',
  extension = '.txt'
}) => {
  const id = fileSyncLogId || solitaryFlowId || mappingId;
  const name = `${workflowName}_${mappingName}_${actionName}`;
  return `${name}_${id}_${lastSyncFileDate}`
    .replaceAll(' ', '_')
    .replace(/(?=.*)_*$/, '') /* Trim underscore '_' from the name */
    .concat(extension);
};
