import React, { useState } from 'react';

import { SxProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
// import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Custom } from 'components/Icons';
import CustomMenu from 'components/Menu';

type MoreMenuProps = {
  options?: Array<any>;
  style?: any;
  sx?: SxProps;
  sxIconButton?: SxProps;
  sxMenu?: SxProps;
  referenceItemId?: string;
  referenceItemName?: string;
  disabled?: boolean;
  hideMenu?: boolean;
  iconProps?: any;
  handleOpen?: () => void;
  handleExtClose?: () => void;
};

const MoreMenu = ({
  options = [],
  style,
  referenceItemId,
  referenceItemName,
  disabled = false,
  sx,
  hideMenu = false,
  sxIconButton,
  iconProps,
  sxMenu,
  handleOpen = () => {},
  handleExtClose = () => {},
  ...props
}: MoreMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { location } = window;
  const open = Boolean(anchorEl);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    handleOpen();
  };
  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
    handleExtClose();
  };

  const navigateTo = (e: any) => {
    handleClose(e);
    navigate(`${location.pathname}/${referenceItemId}`, {
      state: { linkState: referenceItemName }
    });
  };

  const temporary = [{ label: 'Edit Entitlements', clickEvent: navigateTo }];

  const buildElement = () => {
    if (!options) {
      return temporary.map(({ label, clickEvent }) => (
        <MenuItem data-cy={`more-menu-option-${label}`} key={label} onClick={clickEvent}>
          {label}
        </MenuItem>
      ));
    }
    if (options && Array.isArray(options) && options.length > 0) {
      return options.map(({ label, clickEvent, color, disabled = false, isInactive = false }) => (
        <MenuItem
          data-cy={`more-menu-option-${label}`}
          disabled={isInactive || disabled}
          key={label}
          onClick={(e) => {
            clickEvent();
            handleClose(e);
          }}
          sx={{ color }}
        >
          {label}
        </MenuItem>
      ));
    }
    return null;
  };

  return (
    <>
      <IconButton
        disabled={disabled}
        style={style}
        size="small"
        name="open-more-menu"
        data-testid="more-menu-vertical-icon"
        data-cy="more-menu-vertical-icon"
        onClick={handleClick}
        sx={{ ...sxIconButton }}
        {...iconProps}
      >
        <Custom.MoreVerAlt sx={sx} />
      </IconButton>
      {!hideMenu && (
        <CustomMenu
          id="more-menu"
          data-testid="more-menu"
          data-cy="more-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          sx={sxMenu}
          {...props}
        >
          {buildElement()}
        </CustomMenu>
      )}
    </>
  );
};

export default MoreMenu;
