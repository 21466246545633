import React from 'react';

import { ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';

const HQInputs = ({ control, disableAllFields }) => {
  const { currentConnection } = useSyncConnectionsContext();

  return (
    <>
      <ControlTextField
        key={currentConnection?.connectionId}
        control={control}
        requiredNoSpace
        name="configuration.forgeHQ.forgeHQClientId"
        label="HQ Connection Client ID*"
        disabled={disableAllFields}
      />

      <ControlTextField
        key={currentConnection?.connectionId}
        control={control}
        requiredNoSpace
        name="configuration.forgeHQ.forgeHQClientSecret"
        label="HQ Connection Client Secret*"
        disabled={disableAllFields}
      />
    </>
  );
};

export default HQInputs;
