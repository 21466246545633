import { useEffect } from 'react';

import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { scrollToElement } from 'modules/Sync/helpers/scrollHelpers';
import { useFolderDropdownContext } from 'modules/Sync/WorkflowEditor/Contexts/FolderDropdownContext';

import { createNewFolderRowItem, onFolderClickEvent } from './folderTreeItemHelper';

export const useFolderRowMountHook = ({
  folderId,
  expandedFolderIds,
  onFolderExpandHandler,
  toggleIsExpanded,
  isInitialStackFetched,
  updateSourceUiFolders = () => {}
}) => {
  const { setIsLoading, setIsExpandable, setFolders, setAutoFocus, setHasMoreFolders } = useFolderDropdownContext();

  useEffect(() => {
    if (isInitialStackFetched && expandedFolderIds?.length && expandedFolderIds.includes(folderId)) {
      setAutoFocus(true);
      setIsLoading(true);
      scrollToElement(`[data-folder-id="${folderId}"]`);
      const onCompleted = (folders, expandedIds) => {
        const expanded = expandedIds || expandedFolderIds;
        setFolders(folders);
        setHasMoreFolders(folders?.length >= DROPDOWN_DEFAULT_PG_SIZE);
        setIsExpandable(!!folders.length);
        toggleIsExpanded();
        setIsLoading(false);
        scrollToElement(`[data-folder-id="${folderId}"]`);
        setAutoFocus(true);
        const isLastExpanded = expanded[expanded.length - 1] === folderId;

        if (isLastExpanded) {
          const uiCheckedItems = folders?.map((item) => ({ ...item, parentId: folderId, name: item?.text }));
          updateSourceUiFolders(uiCheckedItems);
        }
      };
      /* Load all the sub-folders when auto-scrolling to first selected folder */
      onFolderExpandHandler?.({ folderId, onCompleted, skip: null, take: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialStackFetched]);
};

export const useFolderRowToggleHook = ({
  folderId,
  onRowExpansion,
  onRowCollapse,
  updateSourceUiFolders,
  uiFolders,
  folders
}) => {
  const { isExpanded } = useFolderDropdownContext();
  useEffect(() => {
    (() => (isExpanded ? onRowExpansion(folderId) : onRowCollapse(folderId)))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId, isExpanded]);

  const isChecked = uiFolders?.find(({ id }) => id === folderId);
  useEffect(() => {
    if (isChecked) updateSourceUiFolders(folders?.map((item) => ({ ...item, parentId: folderId, name: item?.text })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);
};

export const useFolderRowTreeItemEventHandlers = (props) => {
  const { onFolderExpandHandler, onFolderSelectHandler, onCreateFolderHandler, fetchMoreFoldersHandler } = props;
  const { permitFolderCreationForIds, updateFormValues = () => {} } = props;
  const { folderId, isExpanded, toggleIsExpanded, setIsExpanded, onSelect, uiFolders } = props;

  const { folders, setIsLoading, setIsExpandable, setFolders, setHasMoreFolders } = useFolderDropdownContext();
  const { setIsLoadingFolderName, setCreateFolderInputVisibility } = useFolderDropdownContext();
  const hideCreateFolderInput = () => setCreateFolderInputVisibility(false);
  const showCreateFolderInput = () => setCreateFolderInputVisibility(true);

  const onFolderExpand = (event, forceOpen = false) => {
    if (isExpanded) {
      event.preventDefault();
      event.stopPropagation();
      if (!forceOpen) setIsExpanded(false);
    } else {
      onFolderClickEvent(
        event,
        folderId,
        onFolderExpandHandler,
        () => setIsLoading(true),
        (folders) => {
          setFolders(folders); /* Folders will be reset to DROPDOWN_DEFAULT_PG_SIZE, but will be available in cache */
          setHasMoreFolders(folders?.length >= DROPDOWN_DEFAULT_PG_SIZE);
          setIsExpandable(!!folders.length);

          const isFolderSelected = uiFolders?.some((item) => item?.id === folderId);
          if (isFolderSelected) {
            const uiCheckedItems = folders?.map((item) => ({ ...item, parentId: folderId, name: item?.text }));
            updateFormValues({ uiFolders: [...uiCheckedItems, ...uiFolders] });
          }
          if (forceOpen) setIsExpanded(true);
          else toggleIsExpanded();
          setIsLoading(false);
        }
      );
    }
  };

  const onFolderSelect = (event) =>
    onFolderClickEvent(
      event,
      folderId,
      onFolderSelectHandler,
      () => setIsLoadingFolderName(true),
      () => {
        onSelect?.(event, folderId, folders);
        setIsLoadingFolderName(false);
      },
      folders
    );

  const createFolder = (event) =>
    createNewFolderRowItem(event, folderId, onCreateFolderHandler, (folders) => {
      hideCreateFolderInput();
      if (folders?.length) {
        setFolders(folders);
        setIsExpandable(!!folders.length);
      }
      setIsLoading(false);
    });

  const onCreateFolderClick =
    onCreateFolderHandler && (!permitFolderCreationForIds?.length || permitFolderCreationForIds?.includes(folderId))
      ? (e) => onFolderClickEvent(e, null, showCreateFolderInput, () => onFolderExpand(e, true))
      : null;

  const onFoldersFetchMore = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const onCompleted = (response) => setHasMoreFolders(!!(response?.length === DROPDOWN_DEFAULT_PG_SIZE));

    const nextFoldersSet = (await fetchMoreFoldersHandler({ folderId, skip: folders?.length || 0, onCompleted })) || [];
    setFolders((currentFolders) => [...currentFolders, ...nextFoldersSet]);
    const isFolderSelected = uiFolders?.some((item) => item?.id === folderId);
    if (isFolderSelected) {
      const uiCheckedItems = nextFoldersSet?.map((item) => ({ ...item, parentId: folderId, name: item?.text }));
      updateFormValues({ uiFolders: [...uiFolders, ...uiCheckedItems] });
    }
  };

  const onFolderNameChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onKeyDown = (event) => {
    event.stopPropagation();

    if (event.key === 'Escape') hideCreateFolderInput();

    if (event.key === 'Enter' && event.target.value) {
      setIsLoading(true);
      event.preventDefault();
      event.stopPropagation();
      createFolder(event);
    }
  };

  return { onFolderExpand, onFolderSelect, onCreateFolderClick, onFoldersFetchMore, onFolderNameChange, onKeyDown };
};
