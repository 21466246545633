import { getCleanObj } from 'helpers/objectFunctions';
import {
  CONNECTION_NAME,
  CONNECTION_TYPES,
  IMPORT_CONFIG_POSTFIX,
  MIGRATION_TOOL,
  SCHEDULE_SYNC
} from 'modules/Sync/Constants/constants';
import { extractWorkflowConfig, importErrors } from 'modules/Sync/helpers/extractWorkflowConfig';
import { parseResponseScheduleSyncJson, validateSyncSettings } from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';

export const isValidMapping = (mapping) => {
  const { isAdded } = importErrors(MIGRATION_TOOL.MAPPING_ERROR, mapping);
  const { source, destinations } = mapping?.solitaryFlowConfigurations?.[0];
  const validConnectionId = source.connectionId && destinations?.[0].connectionId;
  const isOVerwriting = !mapping?.isDuplicate || mapping?.shouldOverwrite;
  return validConnectionId && isOVerwriting && !isAdded;
};

const filterMappings = ({ acc, mapItem, invalidMappings, item }) => {
  const { source, destinations } = mapItem?.solitaryFlowConfigurations?.[0];

  if (isValidMapping(mapItem)) {
    const keysToClean = ['isDuplicate', MIGRATION_TOOL.MAPPING_ERROR];
    const cleanMapping = getCleanObj(mapItem, keysToClean);
    acc.push(cleanMapping);
  }

  if (!source?.connectionId || !destinations?.[0].connectionId) {
    invalidMappings.push({ ...mapItem, workflowName: item.workflowName, workflowId: item.workflowId });
  }
  return acc;
};

export const getWorkflowsToAdd = (workflowData) => {
  const invalidMappings = [];
  const workflowsToAdd = workflowData.reduce((acc, item) => {
    const filteredMappings = item?.mappings?.reduce(
      (acc, mapItem) => filterMappings({ acc, mapItem, invalidMappings, item }),
      []
    );
    const errorLessItem = getCleanObj(item, ['errors', MIGRATION_TOOL.WORKFLOW_ERROR]);
    acc.push({ ...errorLessItem, mappings: filteredMappings });

    return acc;
  }, []);

  return { workflowsToAdd: workflowsToAdd.map((item) => getCleanObj(item, ['id'])), invalidMappings };
};

export const getConnecorSubmitStatus = (importedConnections) =>
  importedConnections.length > 0 ? !!importedConnections.filter((item) => !item?.isAdded).length : true;

export const getSyncSubmitStatus = (workflowSyncData) => {
  const status = workflowSyncData.reduce((acc, item) => {
    const scheduleMapping = item?.mappings?.find(
      (mapping) =>
        !validateSyncSettings({
          ...mapping?.solitaryFlowSettings?.[0],
          scheduleSyncConfig: parseResponseScheduleSyncJson(mapping?.solitaryFlowSettings?.[0].scheduleSyncConfig)
        })
    );
    if (scheduleMapping) {
      acc.push(scheduleMapping);
    }
    return acc;
  }, []).length;

  return !!status;
};

export const getSyncAllSubmitStatus = (syncAllData) => {
  const syncConfig = {
    ...syncAllData,
    scheduleSyncConfig: parseResponseScheduleSyncJson(syncAllData?.scheduleSyncConfig)
  };

  const status = validateSyncSettings(syncConfig);

  return !status;
};

export const getErrors = (workflows) => {
  let error = null;
  let isWorkflowError = true;
  let errorWorkflow = workflows?.find((item) => item.workflowError) || {};
  error = errorWorkflow?.workflowError;

  if (!error) {
    errorWorkflow = workflows?.reduce((acc, wfItem) => {
      const errorMappings = wfItem.mappings?.filter((item) => item.mappingError);
      if (errorMappings.length) acc.push({ ...wfItem, mappings: errorMappings });
      return acc;
    }, [])?.[0];

    error = errorWorkflow?.mappings?.[0]?.mappingError || null;
    isWorkflowError = false;
  }

  const workflowId = errorWorkflow?.workflowId;
  return { error, isWorkflowError, workflowId };
};

export const getWorkflowDataWithErrors = (workflowData, response) => {
  const updatedWorkflows = workflowData.map((item) => {
    const responseItem = response.find((errorItem) => errorItem.workflowId === item.workflowId);
    const workflowError = responseItem?.workflowError;
    const mappings = item?.mappings?.map((mapItem) => {
      const mapping = responseItem?.mappings?.find((errorItem) => errorItem.mappingId === mapItem.mappingId) || {};
      const solitaryFlowId = mapping?.solitaryFlowConfigurations?.[0]?.solitaryFlowId;
      const solitaryFlowConfigurations = [{ ...mapItem?.solitaryFlowConfigurations?.[0], solitaryFlowId }];
      if (MIGRATION_TOOL.MAPPING_ERROR in mapping)
        return {
          ...mapItem,
          solitaryFlowConfigurations,
          [MIGRATION_TOOL.MAPPING_ERROR]: mapping?.[MIGRATION_TOOL.MAPPING_ERROR]
        };
      return { ...mapItem };
    });

    return { ...item, workflowError, mappings };
  });
  return updatedWorkflows;
};

export const getImportedWorkflows = async (files, syncConnections, getWorkflowDataMethods, workflowData) => {
  let invalidFiles = 0;
  const workflows = await files.reduce(async (accumulator, file) => {
    accumulator = await accumulator;
    if (!file.name.endsWith(IMPORT_CONFIG_POSTFIX)) {
      invalidFiles += 1;
    } else {
      const data = await readFile(file, syncConnections, getWorkflowDataMethods, workflowData);
      return data ? [...accumulator, data] : accumulator;
    }
    return accumulator;
  }, []);

  return { invalidFiles, workflows };
};

export const readFile = async (file, syncConnections, methods, workflowData) => {
  const fileReader = new FileReader();
  return new Promise((resolve) => {
    fileReader.readAsText(file, 'UTF-8');
    fileReader.onload = async (e) => {
      const configJson = JSON.parse(e.target.result);
      const configWorkflowId = configJson.filePath.split('\\')[1].split('_')[0];
      if (!workflowData.find((item) => item.workflowId === configWorkflowId)) {
        const data = await extractWorkflowConfig(configJson, syncConnections, methods);
        return resolve(data);
      }
      return resolve(false);
    };
  });
};

const updateCanvasNodeTaskId = ({ node, connectionId, connectionName, isSource, updateAll = false }) => {
  let parsed = JSON.parse(node);
  const elementIndex = isSource ? 0 : 1;
  const updatedElements = parsed?.elements?.map((item, index) => {
    const isWindowsConnectionAll = updateAll && item?.data?.connectionType === CONNECTION_TYPES.WINDOWS;
    const updateItem = updateAll ? isWindowsConnectionAll : index === elementIndex;
    return updateItem
      ? { ...item, data: { ...item.data, taskId: connectionId, connectorAlias: connectionName } }
      : item;
  });

  parsed = JSON.stringify({ ...parsed, elements: updatedElements });
  return parsed;
};

export const updateAllWindowsConnection = ({ workflowData, connectionId, connectionName }) => {
  const updatedWorkflows = workflowData.map((workflow) => {
    const updatedMappings = workflow.mappings?.map((mapping) => {
      const { isAdded } = importErrors(MIGRATION_TOOL.MAPPING_ERROR, mapping);
      const windowsConnection = CONNECTION_NAME[CONNECTION_TYPES.WINDOWS];
      const sourceConnectionNameEqualsWindows =
        mapping?.solitaryFlowConfigurations[0]?.source.connectionName === windowsConnection;
      const destinationConnectionNameEqualsWindows =
        mapping?.solitaryFlowConfigurations[0]?.destinations?.[0]?.connectionName === windowsConnection;

      const getSourceSolitaryFlowConfigurations = sourceConnectionNameEqualsWindows
        ? { ...mapping?.solitaryFlowConfigurations?.[0]?.source, connectionId }
        : mapping?.solitaryFlowConfigurations[0]?.source;
      const getDestinationSolitaryFlowConfigurations = destinationConnectionNameEqualsWindows
        ? {
            ...mapping?.solitaryFlowConfigurations?.[0]?.destinations?.[0],
            connectionId
          }
        : mapping?.solitaryFlowConfigurations?.[0]?.destinations?.[0];
      const solitaryFlowConfigurations = [
        {
          ...mapping?.solitaryFlowConfigurations[0],
          source: getSourceSolitaryFlowConfigurations,
          destinations: [getDestinationSolitaryFlowConfigurations]
        }
      ];
      return isAdded
        ? mapping
        : {
            ...mapping,
            canvasNode: updateCanvasNodeTaskId({
              node: mapping?.canvasNode,
              connectionId,
              connectionName,
              updateAll: true
            }),
            solitaryFlowConfigurations
          };
    });
    return { ...workflow, mappings: updatedMappings };
  });

  return updatedWorkflows;
};

export const getUpdatedConnectionIdMappings = ({
  workflowData,
  connectionId,
  workflowId,
  mappingId,
  isSource,
  connectionName
}) => {
  const updatedWorkflows = workflowData.map((workflow) => {
    if (workflow.workflowId === workflowId) {
      const updatedMappings = workflow.mappings?.map((mapping) => {
        if (mapping?.mappingId === mappingId) {
          const canvasNode = updateCanvasNodeTaskId({
            node: mapping?.canvasNode,
            connectionId,
            isSource,
            connectionName
          });
          const payload = isSource
            ? { source: { ...mapping?.solitaryFlowConfigurations[0]?.source, connectionId } }
            : {
                destinations: [
                  {
                    ...mapping?.solitaryFlowConfigurations[0]?.destinations?.[0],
                    connectionId
                  }
                ]
              };
          return {
            ...mapping,
            canvasNode,
            solitaryFlowConfigurations: [{ ...mapping?.solitaryFlowConfigurations[0], ...payload }]
          };
        }
        return mapping;
      });
      return { ...workflow, mappings: updatedMappings };
    }
    return workflow;
  });

  return updatedWorkflows;
};

export const getSyncTexts = ({
  workflowSyncData,
  totalMappings,
  interval,
  finalTime,
  finalDate,
  isSyncAll,
  syncAllData
}) => {
  if (isSyncAll) {
    const { syncType } = syncAllData;
    const firstSyncText = syncType !== SCHEDULE_SYNC ? 'Immediate start' : 'Immediate scheduled';
    let secondSyncText = null;
    if (totalMappings > 1)
      secondSyncText =
        syncType !== SCHEDULE_SYNC ? `Starts ${interval} minutes later` : `Scheduled ${interval} minutes later`;
    const lastSyncText =
      syncType !== SCHEDULE_SYNC
        ? `Est. start at ${finalTime} on ${finalDate}`
        : `Est. scheduled at ${finalTime} on ${finalDate}`;

    return { firstSyncText, secondSyncText, lastSyncText };
  }

  const allMappings = workflowSyncData.reduce((acc, item) => {
    acc.push(...item.mappings);
    return acc;
  }, []);

  const firstSyncType = allMappings?.[0]?.solitaryFlowSettings?.[0]?.syncType;
  const secondSyncType = allMappings?.[1]?.solitaryFlowSettings?.[0]?.syncType;
  const lastSyncType = allMappings?.slice(-1)?.solitaryFlowSettings?.[0]?.syncType;

  const firstSyncText = firstSyncType !== SCHEDULE_SYNC ? 'Immediate start' : 'Immediate scheduled';
  let secondSyncText = null;
  if (totalMappings > 1)
    secondSyncText =
      secondSyncType !== SCHEDULE_SYNC ? `Starts ${interval} minutes later` : `Scheduled ${interval} minutes later`;
  const lastSyncText =
    lastSyncType !== SCHEDULE_SYNC
      ? `Est. start at ${finalTime} on ${finalDate}`
      : `Est. scheduled at ${finalTime} on ${finalDate}`;

  return { firstSyncText, secondSyncText, lastSyncText };
};
