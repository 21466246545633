import { CONNECTION_TYPES, FOLDER_TYPES, SCHEDULE_SYNC } from 'modules/Sync/Constants/constants';
import { getAdditionalData } from 'modules/Sync/WorkflowEditor/helpers/CopyFilesHelpers/copyFilesPayloadHelper';

export const getSubmittedStatus = ({
  connection,
  connectionType,
  hub,
  projects,
  folders,
  project,
  folder,
  additionalData
}) => {
  const {
    PROCORE,
    AUTODESKHQ,
    AUTODESKTEAMDOCS,
    AUTODESKFIELD,
    AUTODESKGLUE,
    SHAREPOINT,
    NEWFORMA,
    PLANGRID,
    FORESITE,
    WINDOWS
  } = CONNECTION_TYPES;
  const connType = connection?.type || connectionType;

  if (connType === PROCORE) {
    const { folderType } = getAdditionalData(additionalData);
    const { isFolder = false } = FOLDER_TYPES.find(({ id }) => folderType === id) || {};

    return Boolean(
      (hub?.name && !!projects?.length && (isFolder || folders?.length)) || (project?.name && isFolder) || folder?.name
    );
  }
  if (connType === AUTODESKHQ || connType === AUTODESKTEAMDOCS) {
    return Boolean(
      (hub?.name && projects?.length && folders?.length) || (project?.name && folder?.name) || project?.name
    );
  }
  if (connType === AUTODESKFIELD || connType === AUTODESKGLUE || connType === NEWFORMA || connType === PLANGRID) {
    return Boolean((projects?.length && folders?.length) || (project?.name && folder?.name));
  }
  if (connType === SHAREPOINT) {
    return Boolean(projects?.length >= 1 || folders?.length || folder?.name || project?.name);
  }
  if (connType === FORESITE) {
    return Boolean((hub?.name && !!projects?.length && folders?.length) || (project?.name && folder?.name));
  }
  if (connType === WINDOWS) {
    return Boolean(folders?.length || folder?.name);
  }
  return false;
};

const validateScheduleSyncSettings = (scheduleSyncConfig) => {
  const { Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, hours, minutes, format, timeZone } =
    scheduleSyncConfig || {};
  if (!(Sunday || Monday || Tuesday || Wednesday || Thursday || Friday || Saturday)) return false;
  if (!(hours && minutes && format && timeZone)) return false;
  return true;
};

export const validateSyncSettings = (syncSettings = []) =>
  syncSettings.every(({ isSyncEnabled, scheduleSyncConfig, syncType }) => {
    if (!isSyncEnabled) return true;
    if (syncType !== SCHEDULE_SYNC) return true;
    if (validateScheduleSyncSettings(scheduleSyncConfig)) return true;
    return false;
  });
