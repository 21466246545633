import {
  getScheduleSyncConfigPayload,
  parseRequestScheduleSyncJson
} from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';

const useSyncOptionsHook = ({
  importMethods,
  onToggleHandler,
  onSyncTypeChangeHandler,
  onScheduleSyncOptionsChange,
  syncSettings
}) => {
  const onToggle = (event, checked) => {
    if (!importMethods) {
      onToggleHandler(event, checked);
    } else {
      importMethods?.updateSettings({ isSyncEnabled: checked }, importMethods?.workflowId, importMethods?.mappingId);
    }
  };

  const onTypeChange = (event) => {
    if (!importMethods) {
      onSyncTypeChangeHandler(event);
    } else {
      importMethods?.updateSettings(
        { syncType: event.target.value },
        importMethods?.workflowId,
        importMethods?.mappingId
      );
    }
  };

  const onScheduleChange = (data) => {
    if (!importMethods) {
      onScheduleSyncOptionsChange(data);
    } else {
      const { event, checked, name } = data;
      const { value } = event.target;
      const config = { [name]: checked ?? value };

      const schedule = getScheduleSyncConfigPayload(config, syncSettings?.scheduleSyncConfig);

      importMethods?.updateSettings(
        { scheduleSyncConfig: parseRequestScheduleSyncJson(schedule) },
        importMethods?.workflowId,
        importMethods?.mappingId
      );
    }
  };
  return { onToggle, onTypeChange, onScheduleChange };
};

export default useSyncOptionsHook;
