import useSynManageIssuesNowApiAction from 'modules/Sync/WorkflowEditor/apiActions/syncManageIssuesNow';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';

const useManageIssuesSynNowAction = () => {
  const { mappingId } = useSyncWorkflowEditorContext();
  const { syncFlowNow } = useSynManageIssuesNowApiAction({
    refetchQueries: ['SyncGetWorkflowMappingConfiguration']
  });

  const manageIssuesSyncNowAction = (solitaryFlowId, isUpload) =>
    syncFlowNow({
      mappingId,
      solitaryFlowId,
      isUpload
    });
  return { manageIssuesSyncNowAction };
};

export default useManageIssuesSynNowAction;
