import { gql, useMutation } from '@apollo/client';

import { syncManageIssueNow as SYNC_MANAGE_ISSUE_NOW } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useSynManageIssuesNowApiAction = (queryOptions) => {
  const [runSyncNow, { loading }] = useMutation(gql(SYNC_MANAGE_ISSUE_NOW), queryOptions);
  const { handleResponse } = useGraphqlResponseHandler();

  const syncFlowNow = ({ mappingId, solitaryFlowId, isUpload, onCompleted, successMessage = '' }) =>
    handleResponse(
      runSyncNow,
      { variables: { body: { MappingId: mappingId, SolitaryFlowId: solitaryFlowId, isUpload } } },
      { successMessage: successMessage || `${isUpload ? 'Upload' : 'Download'} successfully submitted` },
      onCompleted
    );

  return { syncFlowNow, loading };
};

export default useSynManageIssuesNowApiAction;
