import React from 'react';

import { FormControl, InputLabel, MenuItem, Typography, Stack } from '@mui/material';
import Select from '@mui/material/Select';

import { Custom } from 'components/Icons';
import { getFirstEmptyObjectInArray } from 'helpers/arrayFunctions';
import { getMicroFeContainer } from 'helpers/domHelpers';
import { useManageIssuesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/ManageIssuesContext';
import {
  formatMappings,
  getAvailableConnections
} from 'modules/Sync/WorkflowEditor/helpers/ManageIssuesHelpers/connectionsHelper';
import RefreshGqlCache from 'modules/Sync/WorkflowEditor/Utils/RefreshGqlCache';

const ConnectorForm = ({ isSource = false, defaultOption, connectorFormProps, label = '' }) => {
  const [selectedOption, setSelectedOption] = React.useState(defaultOption);
  const [connectorDropDownOptions, setConnectorDropDownOptions] = React.useState([]);
  const { source, destinations, setSource, setDestinations, connectorNodes, setConnectorNodes } =
    useManageIssuesFormContext();
  const isLastDestination = destinations.length === 1;

  const showCircularMappingWarning =
    !isSource &&
    source?.connectionId &&
    destinations.find(({ nodeId }) => nodeId === selectedOption)?.connectionId === source?.connectionId;

  const activeConnections = isSource
    ? [source?.connectionType]
    : destinations?.find(({ nodeId }) => selectedOption === nodeId)?.connectionType;

  const onConnectorChange = (nodeId, prevNodeId) =>
    isSource
      ? setSource({ nodeId })
      : setDestinations((destinations) => {
          const destination =
            destinations.find(({ nodeId: id }) => prevNodeId === id) || getFirstEmptyObjectInArray(destinations);
          destination.nodeId = nodeId;
          delete destination.folder;
          delete destination.folders;
          destination.isSubmitted = false;
          return destinations.map((dest) => (dest?.nodeId !== nodeId ? dest : destination));
        });

  React.useEffect(() => {
    const connections = getAvailableConnections(connectorNodes, isSource);
    setConnectorDropDownOptions(formatMappings(connections));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSource, connectorNodes]);

  const onChange = (event) => {
    const currentNodeId = event.target.value;
    const prevNodeId = selectedOption;
    setSelectedOption(currentNodeId);
    setConnectorNodes((nodes) => [
      ...nodes.map((node) => {
        const newNode = node;
        if (node.id === currentNodeId) newNode.data.isAvailable = false;
        if (node.id === prevNodeId) newNode.data.isAvailable = true;
        return newNode;
      })
    ]);
    return onConnectorChange(currentNodeId, prevNodeId);
  };

  const deleteDestination = () => {
    setConnectorNodes((nodes) => [
      ...nodes.map((node) => {
        const newNode = node;
        if (node.id === selectedOption) newNode.data.isAvailable = true;
        return newNode;
      })
    ]);
    setDestinations((destinations) => [...destinations.filter((destination) => destination.nodeId !== selectedOption)]);
    setSelectedOption(defaultOption);
  };

  const destinationStyles = {
    top: activeConnections || !isLastDestination ? -18 : 0,
    position: 'relative'
  };
  return (
    <>
      <FormHeader
        label={label}
        onDelete={!isLastDestination && deleteDestination}
        activeConnections={activeConnections}
        isSource={isSource}
      />

      <FormControl size="small" fullWidth sx={isSource ? {} : destinationStyles} color="secondary">
        <InputLabel id="template">Connector*</InputLabel>
        <Select
          labelId="connector"
          id="connector"
          name="connector"
          value={selectedOption}
          label="connector*"
          onChange={onChange}
          MenuProps={{
            container: getMicroFeContainer
          }}
        >
          {connectorDropDownOptions.map(({ value, name }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {connectorDropDownOptions.find(({ value }) => selectedOption === value)?.componentToRender(connectorFormProps) ||
        ''}
      {showCircularMappingWarning && (
        <Typography variant="caption" align="left">
          *The source & destination are both using the same connection. Make sure this mapping doesn&lsquo;t result in a
          circular reference.
        </Typography>
      )}
    </>
  );
};

const FormHeader = ({ label, onDelete, activeConnections, isSource }) => (
  <>
    {label && (
      <Stack
        direction="row"
        component="span"
        sx={{
          justifyContent: 'end',
          marginLeft: 50,
          marginRight: 4,
          top: -38,
          position: 'relative'
        }}
      >
        <div>
          {onDelete && <Custom.DeleteOutlineActive onClick={onDelete} style={{ cursor: 'pointer', marginRight: 15 }} />}
        </div>
        <div>{activeConnections && !isSource && <RefreshGqlCache connections={activeConnections} />}</div>
      </Stack>
    )}
    {activeConnections && isSource && (
      <div
        style={{
          textAlign: 'right',
          position: 'relative',
          top: -44,
          marginTop: 1,
          marginBottom: -35
        }}
      >
        <RefreshGqlCache connections={activeConnections} />
      </div>
    )}
  </>
);
export default ConnectorForm;
