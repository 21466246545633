const LogsDataGridTableColumns = (handleCellComponent) => [
  {
    field: 'name',
    headerName: 'Workflow / Mapping / Action',
    minWidth: 200,
    flex: 2,
    sortable: true,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'numFiles',
    headerName: 'Files Synced',
    flex: 0.5,
    align: 'center',
    minWidth: 50,
    sortable: false,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'totalSize',
    headerName: 'File Size',
    flex: 0.5,
    minWidth: 50,
    sortable: false,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'syncType',
    headerName: 'Sync Type',
    flex: 0.6,
    minWidth: 50,
    sortable: true,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 0.5,
    minWidth: 50,
    sortable: true,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'destinations',
    headerName: 'Destination(s)',
    flex: 1,
    minWidth: 50,
    sortable: false,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'lastSyncTime',
    headerName: 'Last Sync Time',
    flex: 1,
    minWidth: 50,
    sortable: true,
    renderCell: handleCellComponent
  },
  {
    field: 'download',
    headerName: 'Log',
    flex: 0.4,
    minWidth: 35,
    align: 'start',
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: handleCellComponent
  },
  {
    field: '*resync',
    headerName: '',
    flex: 0.4,
    minWidth: 10,
    align: 'start',
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: handleCellComponent
  }
];

export default LogsDataGridTableColumns;
