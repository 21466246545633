import React from 'react';

import { MenuItem } from '@mui/material';

import { AddOptionButton } from 'components/FormComponents/AddOptionButton';
import { Custom } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import { CANVAS_VALIDATIONS } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import { allowAddMoreDestination } from 'modules/Sync/WorkflowEditor/helpers/canvasHelper';
import { actionColors, syncColors } from 'styles/theme/colors';

const DISABLED_STATE_REASONS = [
  'Connector nodes should be present on canvas',
  `Max ${CANVAS_VALIDATIONS.MAX_DESTINATION_ALLOWED_PER_SOURCE} destinations are allowed per source`,
  'Only 1 empty destination is allowed at a time'
];

const AddMoreDestinationsButton = ({ isSource, onClick }) => {
  const { destinations, connectorNodes } = useCopyFilesFormContext();
  const disabled = !allowAddMoreDestination(destinations, connectorNodes);

  return (
    <>
      {!isSource && (
        <div
          style={{
            display: disabled ? 'flex' : 'inline'
          }}
        >
          <MenuItem
            sx={{
              margin: 0,
              padding: 0,
              display: 'inline-flex',
              '&:hover': {
                backgroundColor: 'secondary.light',
                borderRadius: '4px'
              }
            }}
            onClick={onClick}
            disabled={disabled}
          >
            <AddOptionButton
              sx={{
                color: disabled ? 'secondary.disabled' : syncColors.text,
                pointerEvents: 'none',
                fontWeight: 500,
                padding: '0px',
                margin: '-4px 4px'
              }}
            >
              ADD DESTINATION
            </AddOptionButton>
          </MenuItem>
          <NavItemTooltip
            title={
              disabled ? (
                <ul
                  style={{
                    whiteSpace: 'pre-line',
                    lineHeight: '16px',
                    paddingLeft: 20,
                    marginTop: 4,
                    marginBottom: 4
                  }}
                >
                  {DISABLED_STATE_REASONS.map((reason) => (
                    <li key={reason}>{reason}</li>
                  ))}
                </ul>
              ) : (
                ''
              )
            }
            placement="top-start"
          >
            <span style={{ paddingTop: 5 }}>
              {disabled && <Custom.InfoOutlineIcon fontSize="14" sx={{ color: actionColors.lightSurface.active }} />}
            </span>
          </NavItemTooltip>
        </div>
      )}
    </>
  );
};

export default AddMoreDestinationsButton;
