import React from 'react';

import { ThemeInheritor as ThemeLibs } from '@evolvemep/foresite-components-ui-react';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';

import { getThemeForSyncModule } from './theme/theme';

const CustomThemeProvider = ({ children }) => {
  const theme = getThemeForSyncModule();

  return (
    <MaterialThemeProvider theme={theme}>
      <ThemeLibs theme={theme}>{children}</ThemeLibs>
    </MaterialThemeProvider>
  );
};

export default CustomThemeProvider;
