import { gql, useMutation } from '@apollo/client';

import { syncDeleteMappingData as SYNC_DELETE_MAPPING_DATA } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useDeleteMappingCanvasDataApiAction = (queryOptions) => {
  const [deleteMappingData, { loading }] = useMutation(gql(SYNC_DELETE_MAPPING_DATA), queryOptions);
  const { handleResponse } = useGraphqlResponseHandler();

  const deleteMappingCanvasData = ({
    mappingId,
    onCompleted,
    successMessage = 'Canvas data successfully deleted'
  }) =>
    handleResponse(
      deleteMappingData,
      { variables: { body: { MappingId: mappingId } } },
      { successMessage },
      onCompleted
    );

  return { deleteMappingCanvasData, loading };
};

export default useDeleteMappingCanvasDataApiAction;
