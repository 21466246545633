import React, { useEffect, useRef, useState } from 'react';

import { Typography } from '@mui/material';

import CustomTooltip from 'components/Tooltip';
import useWindowSize from 'hooks/useWindowSize';

import { CounterTracker, OverflowTipProps } from './@types/Tooltip';

const counterTracker = {
  scrollWidth: 0,
  offsetWidth: 0
};

const OverflowTip = ({
  title = '',
  offset = { x: 0, y: 0 },
  placement = 'top',
  children,
  sx = {},
  noWrapper = false
}: OverflowTipProps) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<CounterTracker>(counterTracker);
  const { width } = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      setIsOverflow(textElementRef.current?.scrollWidth > textElementRef.current?.offsetWidth);
    }, 100);
  }, [title, width]);

  return (
    <CustomTooltip
      arrow
      ref={textElementRef}
      title={
        title && (
          <Typography fontWeight="500" fontSize={10} lineHeight="16px" sx={{ wordBreak: 'break-all' }}>
            {title}
          </Typography>
        )
      }
      placement={placement}
      disableHoverListener={!isOverflowed}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [offset?.x, offset?.y]
            }
          }
        ]
      }}
    >
      {noWrapper ? (
        <div
          style={{
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...sx
          }}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </CustomTooltip>
  );
};

export default OverflowTip;
