import React from 'react';

import { Button } from '@mui/material';

import { CreateButtonProps } from './@types/buttons';

const CreateButton = ({
  color = 'secondary',
  disabled,
  sx,
  onClick,
  buttonText = 'CREATE',
  variant = 'contained',
  ...props
}: CreateButtonProps) => (
  <Button
    variant={variant}
    disabled={disabled}
    sx={{
      marginLeft: '8px',
      fontWeight: '500',
      '&:hover': {
        backgroundColor: `${color}.${
          variant === 'contained' ? 'containedHoverBackgroundLight' : 'light'
        }`
      },
      '&:disabled': {
        borderColor: 'action.lightSurface.disabled'
      },
      ...sx
    }}
    onClick={onClick}
    color={color}
    {...props}
  >
    {buttonText}
  </Button>
);

export default CreateButton;
