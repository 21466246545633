import { useEffect } from 'react';

import { ACTION_CANVAS_NODE } from 'modules/Sync/Constants/constants';

const useManageIssuesContextHooks = ({
  extensions,
  setIncludedExtensions,
  nodes,
  setConnectorNodes,
  destinations,
  resetContext,
  activeMapping,
  workflowElements,
  actionId,
  setAction
}) => {
  useEffect(() => {
    setIncludedExtensions(extensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extensions]);

  useEffect(() => {
    setConnectorNodes(nodes.filter((node) => node.type === 'connectorType'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  useEffect(() => {
    setConnectorNodes((nodes) => [
      ...nodes.map((node) => {
        const newNode = node;
        if (destinations.some(({ nodeId }) => nodeId === newNode.id)) newNode.data.isAvailable = false;
        return newNode;
      })
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinations]);

  useEffect(() => {
    resetContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMapping]);

  useEffect(
    () => setAction(workflowElements?.find(({ id, type }) => type === ACTION_CANVAS_NODE && id === actionId) || {}),
    [actionId, setAction, workflowElements]
  );
};

export default useManageIssuesContextHooks;
