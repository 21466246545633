import { gql, useMutation } from '@apollo/client';

import { syncFlowNow as SYNC_FLOW_NOW } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useSyncFlowNowApiAction = (queryOptions) => {
  const [runSyncNow, { loading }] = useMutation(gql(SYNC_FLOW_NOW), queryOptions);
  const { handleResponse } = useGraphqlResponseHandler();

  const syncFlowNow = ({
    mappingId,
    solitaryFlowId,
    onCompleted,
    successMessage = 'Sync successfully submitted'
  }) =>
    handleResponse(
      runSyncNow,
      { variables: { body: { MappingId: mappingId, SolitaryFlowId: solitaryFlowId } } },
      { successMessage },
      onCompleted
    );

  return { syncFlowNow, loading };
};

export default useSyncFlowNowApiAction;
