import { useEffect } from 'react';

import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { scrollToElement } from 'modules/Sync/helpers/scrollHelpers';
import { useFolderDropdownContext } from 'modules/Sync/WorkflowEditor/Contexts/FolderDropdownContext';
import { onFileClickEvent } from 'modules/Sync/WorkflowEditor/Forms/Shared/PublishActionComponents/FoldersSelectDropdown/folderTreeItemHelper';

export const useFolderRowMountHook = ({
  folderId,
  expandedFolderIds,
  onFolderExpandHandler,
  toggleIsExpanded,
  isInitialStackFetched
}) => {
  const { setIsLoading, setIsExpandable, setFolders, setAutoFocus, setHasMoreFolders } = useFolderDropdownContext();

  useEffect(() => {
    if (isInitialStackFetched && expandedFolderIds?.length && expandedFolderIds.includes(folderId)) {
      setAutoFocus(true);
      setIsLoading(true);
      scrollToElement(`[data-folder-id="${folderId}"]`);
      const onCompleted = (folders) => {
        setFolders(folders);
        setHasMoreFolders(folders?.length >= DROPDOWN_DEFAULT_PG_SIZE);
        setIsExpandable(!!folders.length);
        toggleIsExpanded();
        setIsLoading(false);
        scrollToElement(`[data-folder-id="${folderId}"]`);
        setAutoFocus(true);
      };
      /* Load all the sub-folders when auto-scrolling to first selected folder */
      onFolderExpandHandler?.({ folderId, onCompleted, skip: null, take: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialStackFetched]);
};

export const useFolderRowToggleHook = ({ folderId, onFolderRowItemExpansion, onFolderRowItemCollapse }) => {
  const { isExpanded } = useFolderDropdownContext();
  useEffect(() => {
    (() => (isExpanded ? onFolderRowItemExpansion(folderId) : onFolderRowItemCollapse(folderId)))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId, isExpanded]);
};

export const useFolderRowTreeItemEventHandlers = ({
  folderId,
  isFolder,
  isExpanded,
  toggleIsExpanded,
  setIsExpanded,
  onSelect,
  onFolderExpandHandler,
  onFolderSelectHandler,
  fetchMoreFoldersHandler
}) => {
  const { folders, setIsLoading, setIsExpandable, setIsLoadingFolderName, setFolders, setHasMoreFolders } =
    useFolderDropdownContext();
  const onFolderExpand = (event, forceOpen = false) => {
    if (!isExpanded) {
      return onFileClickEvent(
        event,
        folderId,
        onFolderExpandHandler,
        () => setIsLoading(true),
        (folders) => {
          setFolders(folders); /* Folders will be reset to DROPDOWN_DEFAULT_PG_SIZE, but will be available in cache */
          setHasMoreFolders(folders?.length >= DROPDOWN_DEFAULT_PG_SIZE);
          setIsExpandable(!!folders.length);
          if (forceOpen) {
            setIsExpanded(true);
          } else {
            toggleIsExpanded();
          }
          setIsLoading(false);
        }
      );
    }

    event.preventDefault();
    event.stopPropagation();
    return !forceOpen && setIsExpanded(false);
  };

  const onFolderSelect = (event) =>
    !isFolder &&
    onFileClickEvent(
      event,
      folderId,
      onFolderSelectHandler,
      () => setIsLoadingFolderName(true),
      () => {
        onSelect?.(event, folderId);
        setIsLoadingFolderName(false);
      }
    );

  const onFoldersFetchMore = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const onCompleted = (response) => {
      setHasMoreFolders(!!(response?.length === DROPDOWN_DEFAULT_PG_SIZE));
    };
    const nextFoldersSet = await fetchMoreFoldersHandler({
      folderId,
      skip: folders?.length || 0,
      onCompleted
    });
    setFolders((currentFolders) => [...currentFolders, ...nextFoldersSet]);
  };

  return { onFolderExpand, onFolderSelect, onFoldersFetchMore };
};
