import { useEffect } from 'react';

import { ACTION_CANVAS_NODE } from 'modules/Sync/Constants/constants';

const usePublishActionContextHooks = ({
  extensions,
  setIncludedExtensions,
  nodes,
  setConnectorNodes,
  resetContext,
  activeMapping,
  workflowElements,
  actionId,
  setAction
}) => {
  useEffect(() => {
    setIncludedExtensions(extensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extensions]);

  useEffect(() => {
    setConnectorNodes(nodes.filter((node) => node.type === 'connectorType'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  useEffect(() => {
    resetContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMapping]);

  useEffect(
    () => setAction(workflowElements?.find(({ id, type }) => type === ACTION_CANVAS_NODE && id === actionId) || {}),
    [actionId, setAction, workflowElements]
  );
};

export default usePublishActionContextHooks;
