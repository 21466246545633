import { Menu } from '@mui/material';

import { getMicroFeContainer } from 'helpers/domHelpers';

const CustomMenu = ({ children, ...rest }) => (
  <Menu {...rest} container={getMicroFeContainer}>
    {children}
  </Menu>
);

export default CustomMenu;
