import { useState } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { createNewformaFolder as CREATE_NEWFORMA_FOLDER } from 'graphql/mutations';
import { getNewformaSelectedFolderIdStack as GET_NEWFORMA_SELECTED_FOLDER_ID_STACK } from 'graphql/queries';
import useCache from 'hooks/useCache';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import FoldersSelectDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown';
import { getLatestFolders } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';

import { getNewformaSelectEventHandlers } from './newformaHelpers';

const {
  NETWORK_ONLY: { fetchPolicy: NETWORK_ONLY_FETCH_POLICY }
} = FETCH_POLICIES;

const FETCH_FOLDERS_API = 'getNewformaFolders';

const NewformaFoldersDropdown = (props) => {
  const {
    connectionId,
    nodeId,
    isSource = false,
    disabled = false,
    isLoadingFolders = false,
    onFolderStackLoad = () => {},
    fetchMoreRootFolders,
    folders
  } = props;
  const [isInitialStackFetched, setIsInitialStackFetched] = useState(false);
  const { getConnectorNodeValues, destinations, updateConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId, defaultObject: destinations[0] });
  const setFormValues = (props) => updateConnectorNodeValues({ isSource, nodeId, props });
  const { project, folder, folders: uiFolders } = formValues;
  const { handleResponse } = useGraphqlResponseHandler();
  const { deleteCache } = useCache();

  const [loadNewformaFolderStack, { loading: isLoadingNewformaFolderStack }] = useLazyQuery(
    gql(GET_NEWFORMA_SELECTED_FOLDER_ID_STACK)
  );

  const [makeNewformaFolder] = useMutation(gql(CREATE_NEWFORMA_FOLDER));

  const { fetchNewformaFolders } = getNewformaSelectEventHandlers({ connectionId, project });

  const fetchMoreFoldersHandler = async ({ isRoot = false, folderId, skip, onCompleted }) => {
    if (!isRoot) {
      const newformaFolders = await fetchNewformaFolders({ folderId, skip });
      onCompleted(newformaFolders);
      return newformaFolders;
    }
    await fetchMoreRootFolders(skip, {
      connectionId,
      projectId: Buffer.from(project?.id, 'utf-8').toString('base64')
    });
    return onCompleted(getLatestFolders(folders));
  };

  const onFolderExpandHandler = async ({ folderId, onCompleted, skip, take }) => {
    const newformaFolders = await fetchNewformaFolders({ folderId, skip, take });
    return onCompleted(newformaFolders);
  };

  const onFolderSelectHandler = ({ onCompleted }) => onCompleted();

  const onCreateFolderHandler = async ({ folderId, folderName, onCompleted }) => {
    await handleResponse(
      makeNewformaFolder,
      {
        variables: { query: { connectionId }, body: { parentFolderId: folderId, name: folderName } }
      },
      { successMessage: 'Created folder successfully' },
      async () => {
        const newformaFolders = await fetchNewformaFolders({
          folderId,
          fetchPolicy: NETWORK_ONLY_FETCH_POLICY,
          skip: null,
          take: null
        });
        deleteCache(FETCH_FOLDERS_API);
        onCompleted(newformaFolders);
      }
    );
  };

  const onFolderDropdownClick = async () => {
    if (folder?.id && !isInitialStackFetched) {
      await loadNewformaFolderStack({
        variables: {
          query: {
            connectionId,
            projectId: Buffer.from(project?.id, 'utf-8').toString('base64'),
            selectedFolderId: Buffer.from(folder?.id, 'utf-8').toString('base64')
          }
        },
        onCompleted: async (response) => {
          const { getNewformaSelectedFolderIdStack } = response;
          let expandedFolderIds = [...(getNewformaSelectedFolderIdStack || [])];
          expandedFolderIds.push(folder.id);
          expandedFolderIds = [...new Set(expandedFolderIds)];
          const closestParentFolderId = expandedFolderIds[expandedFolderIds.length - 2] || '';
          await setFormValues({
            uiFolders: uiFolders?.map((item) => ({ ...item, parentId: closestParentFolderId })),
            expandedFolderIds
          });

          onFolderStackLoad?.(closestParentFolderId);
          setIsInitialStackFetched(true);
        }
      });
    }
  };

  return (
    <FoldersSelectDropdown
      {...props}
      isLoading={isLoadingFolders}
      disabled={isLoadingFolders || disabled}
      isLoadingFolderStack={isLoadingNewformaFolderStack}
      isInitialStackFetched={isInitialStackFetched}
      onFolderExpandHandler={onFolderExpandHandler}
      onFolderSelectHandler={onFolderSelectHandler}
      onCreateFolderHandler={onCreateFolderHandler}
      onFolderDropdownClick={onFolderDropdownClick}
      fetchMoreFoldersHandler={fetchMoreFoldersHandler}
    />
  );
};

export default NewformaFoldersDropdown;
