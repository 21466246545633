import React, { useState } from 'react';

import { IconButton } from '@mui/material';

import { Custom } from 'components/Icons';
import Tooltip from 'components/Tooltip';

const CopyToClipboardBtn = ({ textToCopy = '' }) => {
  const [open, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState('Copy Link');

  const onClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setTooltipText('Copied to clipboard');
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1000);
    setTimeout(() => {
      setTooltipText('Copy Link');
    }, 1500);
  };

  return (
    <IconButton edge="end" onClick={onClick}>
      <Tooltip title={tooltipText} placement="top" open={open}>
        <Custom.CopyLink />
      </Tooltip>
    </IconButton>
  );
};

export default CopyToClipboardBtn;
