import React, { useState, createContext, useMemo } from 'react';

import { useStoreState } from 'react-flow-renderer';

import { CONNECTOR_CANVAS_NODE, CONNECTOR_NODE_TYPES, SYNC_TYPES } from 'modules/Sync/Constants/constants';
import usePublishActionContextHooks from 'modules/Sync/WorkflowEditor/Contexts/usePublishActionContextHooks';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { getAdditionalData } from 'modules/Sync/WorkflowEditor/helpers/PublishActionHelpers/publishActionPayloadHelper';
import { parseResponseScheduleSyncJson } from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';
import { getSubmittedStatus } from 'modules/Sync/WorkflowEditor/helpers/validationsHelper';

const defaultObject = {};

const CopyFilesFormContext = createContext(defaultObject);

const getDefaultSource = (activeMapping) => {
  const { source, source: { connection, projects, additionalData = '' } = {} } = activeMapping;
  const project = projects?.[0];
  const items =
    project?.folders?.reduce((itemsAcc, { id, name, items }) => {
      const newItems = items?.map((item) => ({ ...item, parentId: id, parentName: name })) || [];
      itemsAcc.push(...newItems);
      return itemsAcc;
    }, []) || [];
  const folders = [...(project?.folders?.map((folder) => ({ ...folder, isFolder: true })) || []), ...items];
  const connectionId = connection?.id;
  const { downloadBehavior = 0 } = getAdditionalData(additionalData) || {};
  const isSubmitted = getSubmittedStatus({ ...source, projects, folders: project?.folders });
  return { ...source, connectionId, project, folders, downloadBehavior, isSubmitted } || {};
};

export const PublishActionFormProvider = ({ children, actionId }) => {
  const { workflowMappings, workflowElements } = useSyncWorkflowEditorContext();
  const activeMapping = useMemo(
    () => workflowMappings?.find(({ actionNodeId }) => actionNodeId === actionId) || defaultObject,
    [actionId, workflowMappings]
  );
  const defaultSource = useMemo(() => getDefaultSource(activeMapping), [activeMapping]);
  const extensions = useMemo(
    () => JSON.parse(activeMapping?.source?.settings?.includeExtensions || '[]'),
    [activeMapping]
  );
  const defaultSyncSettings = useMemo(() => {
    const {
      isSyncEnabled = false,
      syncType = SYNC_TYPES[0].value,
      scheduleSyncConfig
    } = activeMapping?.source?.settings || {};
    return {
      isSyncEnabled,
      syncType,
      scheduleSyncConfig: parseResponseScheduleSyncJson(scheduleSyncConfig)
    };
  }, [activeMapping]);

  const [source, setSource] = useState(defaultSource);
  const [syncSettings, setSyncSettings] = useState(defaultSyncSettings);
  const [action, setAction] = useState(defaultObject);
  const [currentConnectorType, setCurrentConnectorType] = useState(CONNECTOR_NODE_TYPES.SOURCE);
  const nodes = useStoreState((store) => store.nodes);
  const [connectorNodes, setConnectorNodes] = useState(nodes.filter(({ type }) => type === CONNECTOR_CANVAS_NODE));
  const [includedExtensions, setIncludedExtensions] = useState(extensions);
  const [activeStep, setActiveStep] = useState(0);
  const resetContext = (cb = () => {}, delay = 100) => {
    setTimeout(() => {
      setSource((currentSource) => {
        if (defaultSource.connectionId) return { ...currentSource, ...defaultSource };
        return defaultSource;
      });
      setCurrentConnectorType(CONNECTOR_NODE_TYPES.SOURCE);
      setActiveStep(0);
      setIncludedExtensions(extensions);
      cb();
    }, delay);
  };

  const getConnectorNodeValues = ({ isSource, defaultObject = {} }) => (isSource && source) || defaultObject;

  const updateConnectorNodeValues = ({ isSource, props }) =>
    isSource && setSource((source) => ({ ...source, ...props }));

  usePublishActionContextHooks({
    extensions,
    setIncludedExtensions,
    nodes,
    setConnectorNodes,
    resetContext,
    activeMapping,
    workflowElements,
    actionId,
    setAction
  });

  return (
    <CopyFilesFormContext.Provider
      value={{
        activeFlow: activeMapping,
        defaultSource,
        source,
        setSource,
        currentConnectorType,
        setCurrentConnectorType,
        connectorNodes,
        setConnectorNodes,
        resetContext,
        includedExtensions,
        setIncludedExtensions,
        activeStep,
        setActiveStep,
        syncSettings,
        setSyncSettings,
        action,
        setAction,
        getConnectorNodeValues,
        updateConnectorNodeValues
      }}
    >
      {children}
    </CopyFilesFormContext.Provider>
  );
};

export const usePublishActionFormContext = () => {
  const context = React.useContext(CopyFilesFormContext);
  if (context === undefined) {
    throw new Error('usePublishActionFormContext must be used within a PublishActionFormProvider');
  }
  return context;
};
