import React, { useState, createContext, useMemo } from 'react';

import { useStoreState } from 'react-flow-renderer';

import { CONNECTOR_CANVAS_NODE, CONNECTOR_NODE_TYPES, SYNC_TYPES } from 'modules/Sync/Constants/constants';
import useCopyFilesContextHooks from 'modules/Sync/WorkflowEditor/Contexts/useCopyFilesContextHooks';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { getAdditionalData } from 'modules/Sync/WorkflowEditor/helpers/CopyFilesHelpers/copyFilesPayloadHelper';
import { parseResponseScheduleSyncJson } from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';
import { getSubmittedStatus } from 'modules/Sync/WorkflowEditor/helpers/validationsHelper';

const defaultObject = {};

const CopyFilesFormContext = createContext(defaultObject);

const getDefaultSource = (activeMapping) => {
  const { source, source: { connection, projects, additionalData = '' } = {} } = activeMapping;
  const project = projects?.[0];
  const folder = project?.folders?.[0];
  const { downloadBehavior = 0 } = getAdditionalData(additionalData) || {};
  const isSubmitted = getSubmittedStatus({ ...source, projects, folders: project?.folders });
  return (
    {
      ...source,
      connectionId: connection?.id,
      project,
      folder,
      folders: project?.folders || [],
      uiFolders: project?.folders || [],
      downloadBehavior,
      isSubmitted
    } || {}
  );
};

const getDefaultDestinations = (activeMapping) =>
  activeMapping?.destinations?.map((destination) => {
    const { connection, project = {}, project: { folder = {} } = {}, additionalData = '' } = destination;
    const { uploadBehavior = 0 } = getAdditionalData(additionalData) || {};
    const isSubmitted = getSubmittedStatus({ ...destination, project, folder });
    return {
      ...destination,
      connectionId: connection?.id,
      project,
      folder,
      uploadBehavior,
      isSubmitted
    };
  }) || [{}];

export const CopyFilesFormProvider = ({ children, actionId }) => {
  const { workflowMappings, workflowElements } = useSyncWorkflowEditorContext();
  const activeMapping = useMemo(
    () => workflowMappings?.find(({ actionNodeId }) => actionNodeId === actionId) || defaultObject,
    [actionId, workflowMappings]
  );
  const defaultSource = useMemo(() => getDefaultSource(activeMapping), [activeMapping]);
  const defaultDestinations = useMemo(() => getDefaultDestinations(activeMapping), [activeMapping]);
  const extensions = useMemo(
    () => JSON.parse(activeMapping?.source?.settings?.includeExtensions || '[]'),
    [activeMapping]
  );
  const defaultSyncSettings = useMemo(() => {
    const {
      isSyncEnabled = false,
      syncType = SYNC_TYPES[0].value,
      scheduleSyncConfig
    } = activeMapping?.source?.settings || {};
    return { isSyncEnabled, syncType, scheduleSyncConfig: parseResponseScheduleSyncJson(scheduleSyncConfig) };
  }, [activeMapping]);

  const [source, setSource] = useState(defaultSource);
  const [destinations, setDestinations] = useState(defaultDestinations);
  const [syncSettings, setSyncSettings] = useState(defaultSyncSettings);
  const [action, setAction] = useState(defaultObject);
  const [currentConnectorType, setCurrentConnectorType] = useState(CONNECTOR_NODE_TYPES.SOURCE);
  const nodes = useStoreState((store) => store.nodes);
  const [connectorNodes, setConnectorNodes] = useState(nodes.filter(({ type }) => type === CONNECTOR_CANVAS_NODE));
  const [includedExtensions, setIncludedExtensions] = useState(extensions);
  const [activeStep, setActiveStep] = useState(0);
  const resetContext = (cb = () => {}, delay = 100) => {
    setTimeout(() => {
      setSource((currentSource) => {
        if (defaultSource.connectionId) return { ...currentSource, ...defaultSource };
        return defaultSource;
      });
      setCurrentConnectorType(CONNECTOR_NODE_TYPES.SOURCE);
      setDestinations(defaultDestinations);
      setActiveStep(0);
      setIncludedExtensions(extensions);
      cb();
    }, delay);
  };

  const getConnectorNodeValues = ({ nodeId, isSource, defaultObject = {} }) =>
    isSource ? source : destinations?.find(({ nodeId: id }) => id === nodeId) || defaultObject;

  const updateSourceUiFolders = (folders) => {
    setSource((source) => ({ ...source, uiFolders: [...(source?.uiFolders || []), ...folders] }));
  };

  const updateConnectorNodeValues = ({ nodeId, isSource, props }) =>
    isSource
      ? setSource((source) => ({ ...source, ...props }))
      : setDestinations((destinations) =>
          destinations.map((destination) =>
            destination.nodeId === nodeId ? { ...destination, ...props } : destination
          )
        );
  useCopyFilesContextHooks({
    extensions,
    setIncludedExtensions,
    nodes,
    setConnectorNodes,
    destinations,
    resetContext,
    activeMapping,
    workflowElements,
    actionId,
    setAction
  });

  return (
    <CopyFilesFormContext.Provider
      value={{
        activeFlow: activeMapping,
        defaultSource,
        source,
        setSource,
        destinations,
        setDestinations,
        currentConnectorType,
        setCurrentConnectorType,
        connectorNodes,
        setConnectorNodes,
        resetContext,
        includedExtensions,
        setIncludedExtensions,
        activeStep,
        setActiveStep,
        syncSettings,
        setSyncSettings,
        action,
        setAction,
        getConnectorNodeValues,
        updateConnectorNodeValues,
        updateSourceUiFolders
      }}
    >
      {children}
    </CopyFilesFormContext.Provider>
  );
};

export const useCopyFilesFormContext = () => {
  const context = React.useContext(CopyFilesFormContext);
  if (context === undefined) {
    throw new Error('useCopyFilesFormContext must be used within a CopyFilesFormProvider');
  }
  return context;
};
