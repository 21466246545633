import { useEffect } from 'react';

import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

export const useGlueMountHook = ({
  connectionId,
  connectionType,
  nodeId,
  project,
  formValues,
  updateFormValues,
  loadFolders,
  fetchOnMountGlueFolders,
  fetchProjectsOnLoad
}) => {
  useEffect(() => {
    const isSubmitted = !!(formValues?.project?.name && formValues?.folder?.name);
    updateFormValues({ connectionId, connectionType, nodeId, isSubmitted });
    fetchProjectsOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchOnMountGlueFolders) {
      loadFolders({
        connectionId,
        projectId: project?.id,
        skip: 0,
        take: DROPDOWN_DEFAULT_PG_SIZE
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnMountGlueFolders]);
};
