import React, { useEffect, useState } from 'react';

import { FormHelperText, InputAdornment, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { objIsEmpty } from 'helpers/objectFunctions';
import CopyToClipboardBtn from 'modules/Sync/components/CopyToClipboardBtn';
import UncontrolledMultiSelectDropdown from 'modules/Sync/Connections/components/UncontrolledMultiSelectDropdown';
import { Caption, ControlCheckbox, ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import useSharepointMutation from 'modules/Sync/Connections/ConnectionProperties/hooks/useSharepointMutation';
import useUpsertConnectionMutation from 'modules/Sync/Connections/ConnectionProperties/useUpsertConnectionMutation';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { CONNECTION_TYPES } from 'modules/Sync/Constants/constants';

const EMAIL_ERROR_RESPONSE_MSG = 'User Name (Email)';
const EMAIL_ERROR_KEYWORD = 'User Not valid';
const WEB_LISTING_QUERIES = [
  { label: 'Communication Sites', value: 'WebTemplate:GROUP' },
  { label: 'Team Sites', value: 'WebTemplate:SITEPAGEPUBLISHING' },
  { label: 'All Sites', value: 'contentclass:STS_Site' },
  {
    label: 'Team & Communication Sites',
    value: 'contentclass:sts_site webtemplate=SITEPAGEPUBLISHING webtemplate=GROUP'
  },
  {
    label: 'Team & Communication Sites & their Subsites',
    value: 'contentclass:sts_site contentclass:sts_web webtemplate=SITEPAGEPUBLISHING webtemplate=GROUP'
  }
];
const DEFAULT_WEB_LISTING_QUERY = WEB_LISTING_QUERIES[0].value;

const findRedirectURL = (connectorsList) => {
  const defaultAuthUrl = connectorsList?.syncConnectorsList?.find((x) => x?.name === CONNECTION_TYPES.SHAREPOINT)?.url;
  return defaultAuthUrl ? new URL(defaultAuthUrl)?.searchParams?.get('redirect_uri') : '';
};

const WebListingDropdown = ({
  name,
  label,
  value,
  onChange,
  sx = {},
  disabled,
  error,
  options = WEB_LISTING_QUERIES
}) => {
  const listingValue = value?.length ? value?.split(', ') : [];

  return (
    <UncontrolledMultiSelectDropdown
      name={name}
      label={label}
      value={listingValue}
      onChange={onChange}
      sx={sx}
      disabled={disabled}
      error={error}
      options={options}
    />
  );
};

export const sharepointFormDefaultValues = ({ configuration: selectedConnectionConfig }) => ({
  useCustomApplicationCredentials: !!selectedConnectionConfig?.useCustomApplicationCredentials,
  email: selectedConnectionConfig?.email || '',
  serverUrl: selectedConnectionConfig?.serverUrl || '',
  webListingQuery: selectedConnectionConfig?.webListingQuery || DEFAULT_WEB_LISTING_QUERY,
  tenantId: selectedConnectionConfig?.tenantId || '',
  clientId: selectedConnectionConfig?.clientId || '',
  clientSecret: selectedConnectionConfig?.clientSecret || '',
  callbackUrl: selectedConnectionConfig?.callbackUrl || ''
});

const EmailControlField = ({ id, control, disabled }) => (
  <ControlTextField
    key={id}
    control={control}
    requiredNoSpace
    email
    name="configuration.email"
    errorMessages={{ email: 'Invalid' }}
    label="Email*"
    disabled={disabled}
  />
);

const ServerUrlControlField = ({ id, control, disabled }) => (
  <ControlTextField
    key={id}
    control={control}
    requiredNoSpace
    name="configuration.serverUrl"
    label="Server URL*"
    disabled={disabled}
  />
);

const CustomCredsControlField = ({ id, control, disabled }) => (
  <ControlCheckbox
    key={id}
    name="configuration.useCustomApplicationCredentials"
    control={control}
    label={<Typography variant="body2">Use custom application credentials</Typography>}
    checkboxProps={{ size: 'small' }}
    disabled={disabled}
  />
);

const CustomCredentials = ({
  currentConnection,
  control,
  useCustomCredentials,
  disableAllFields,
  defaultCallbackUrl
}) => (
  <>
    <ControlTextField
      key={currentConnection?.connectionId}
      control={control}
      requiredNoSpace={useCustomCredentials}
      name="configuration.tenantId"
      label="Tenant ID*"
      disabled={disableAllFields}
    />
    <ControlTextField
      key={currentConnection?.connectionId}
      control={control}
      requiredNoSpace={useCustomCredentials}
      name="configuration.clientId"
      label="Client ID*"
      disabled={disableAllFields}
    />
    <ControlTextField
      key={currentConnection?.connectionId}
      control={control}
      requiredNoSpace={useCustomCredentials}
      name="configuration.clientSecret"
      label="Client Secret*"
      disabled={disableAllFields}
    />
    <ControlTextField
      key={currentConnection?.connectionId}
      control={control}
      disabled
      name="configuration.callbackUrl"
      label="Callback URL*"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CopyToClipboardBtn textToCopy={defaultCallbackUrl} />
          </InputAdornment>
        )
      }}
    />
  </>
);

const Sharepoint = ({ actionRenderer, disableAllFields, onSuccessCallback }) => {
  const { currentConnection, connectorsList, setHeaderActionsDisabled } = useSyncConnectionsContext();
  const [validationError, setValidationError] = useState('');
  const { upsertConnection, loading } = useUpsertConnectionMutation();
  const { generateAuthCode } = useSharepointMutation();

  const { control, watch, setValue, setError: setFormError, clearErrors, formState, getValues } = useFormContext();
  const { errors } = formState;

  const setError = (errors) => {
    if (typeof errors === 'string') {
      setValidationError(errors);
    } else {
      const { errorInfo } = errors?.[0] || {};
      setValidationError(Object.values(errorInfo)?.[0]);
    }

    if (errors?.includes(EMAIL_ERROR_RESPONSE_MSG) || errors?.[0]?.errorInfo?.[EMAIL_ERROR_KEYWORD]) {
      setFormError('configuration.email', { type: 'invalid', message: 'Invalid Email' });
    }
  };

  useEffect(() => {
    setValidationError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConnection]);

  const updateConnection = async (formData) => {
    setValidationError('');
    const authenticationCode = await generateAuthCode({ formData, onError: setError });
    if (authenticationCode !== 'error') {
      delete formData.dataSource;
      setHeaderActionsDisabled(false);
      upsertConnection({
        formData,
        authenticationCode,
        connectionType: CONNECTION_TYPES.SHAREPOINT,
        onError: setError,
        onSuccess: onSuccessCallback
      });
    }
  };

  const submitDisabled = !objIsEmpty(errors);
  const useCustomCredentials = watch('configuration.useCustomApplicationCredentials');

  const defaultCallbackUrl = React.useMemo(
    () => findRedirectURL(connectorsList),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connectorsList, useCustomCredentials]
  );

  const onWebListingChange = ({ target: { value } }) => {
    const joinedValue = value?.join(', ');
    const webListingFormFieldName = 'configuration.webListingQuery';
    clearErrors(webListingFormFieldName);
    setValue(webListingFormFieldName, joinedValue);
    if (joinedValue === '') setFormError(webListingFormFieldName, { type: 'required', message: '*Required' });
  };

  useEffect(() => {
    if (useCustomCredentials) setValue('configuration.callbackUrl', defaultCallbackUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCallbackUrl, useCustomCredentials]);

  const fieldId = currentConnection?.connectionId;
  return (
    <>
      <Caption>Credentials</Caption>
      <EmailControlField id={fieldId} control={control} disabled={disableAllFields} />
      <ServerUrlControlField id={fieldId} control={control} disabled={disableAllFields} />
      <WebListingDropdown
        name="configuration.webListingQuery"
        label="Web Listing Query*"
        defaultValue={DEFAULT_WEB_LISTING_QUERY}
        disabled={disableAllFields}
        onChange={onWebListingChange}
        value={getValues('configuration.webListingQuery')}
        control={control}
        error={errors?.configuration?.webListingQuery}
        sx={{ mt: 2 }}
      />
      <CustomCredsControlField id={fieldId} control={control} disabled={disableAllFields} />
      {useCustomCredentials && (
        <CustomCredentials
          currentConnection={currentConnection}
          control={control}
          useCustomCredentials={useCustomCredentials}
          disableAllFields={disableAllFields}
          defaultCallbackUrl={defaultCallbackUrl}
        />
      )}
      <FormHelperText error>{validationError}</FormHelperText>
      {actionRenderer({ onSubmit: updateConnection, submitDisabled, loading })}
    </>
  );
};

export default Sharepoint;
