/* eslint-disable max-lines-per-function */
import React, { useMemo, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import SwitchComponent from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import { Custom } from 'components/Icons';
import { getMicroFeContainer } from 'helpers/domHelpers';
import { surfaceColors } from 'styles/theme/colors';

import BaseButton from '../BaseButton';
import { useItemsDataGridPro } from '../ItemsDataGridProProvider';

const normalizeString = (str = '') =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const Columns = ({
  disabled = false,
  sx = {},
  buttonSx = {},
  typoProps = {},
  popperStackSx = {},
  baseButtonSx = {},
  disableColumnSearch = false
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [input, setInput] = useState('');

  const { onGetColumnsReference, onUpdateColumnsFilter, columnsFilter } = useItemsDataGridPro({
    updateColumns: false
  });

  const onClick = (event) => {
    setAnchorEl(event?.currentTarget);
    setOpen((previousOpen) => {
      const lastState = !previousOpen;
      if (!lastState) setInput('');
      return lastState;
    });
  };

  const onClose = () => setOpen(false);

  const onChange = (event) => {
    const { value } = event.target;
    setInput(value);
  };

  const columns = useMemo(() => {
    if (open) {
      const list = onGetColumnsReference() ?? [];
      return list
        .filter(({ headerName }) => Boolean(headerName))
        .filter(({ headerName }) => {
          if (input.length === 0) return true;
          const headerNameNormalized = normalizeString(headerName);
          const inputNormalized = normalizeString(input);
          return headerNameNormalized.includes(inputNormalized);
        });
    }
    return [];
  }, [open, onGetColumnsReference, input]);

  const switchAllContent = (state = false) => {
    const list = onGetColumnsReference() ?? [];
    const finalList = list.filter(({ headerName }) => Boolean(headerName)).filter(({ hideable }) => hideable !== false);
    const result = finalList.reduce((prev, { field }) => ({ ...prev, [field]: state }), {});
    onUpdateColumnsFilter(undefined, undefined, result);
  };

  const onHideAll = () => switchAllContent();

  const onShowAll = () => switchAllContent(true);

  const onSwitch =
    (field) =>
    // eslint-disable-next-line no-unused-vars
    (event, state) => {
      onUpdateColumnsFilter(field, state);
    };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Stack sx={{ sx }}>
        <BaseButton
          disabled={disabled}
          onClick={onClick}
          icon={(others) => (
            <Custom.ViewColumnIcon
              sx={{
                fontSize: '16px',
                color: others.disabled ? surfaceColors.lightSurface.disabled : 'secondary.main',
                marginTop: '3px',
                marginRight: '4px'
              }}
            />
          )}
          sx={baseButtonSx}
        />
        <Popper
          data-testid="LocationWithAWorkPhasePopper-data-testid"
          open={open}
          anchorEl={anchorEl}
          transition
          placement="bottom-end"
          container={getMicroFeContainer}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Stack
                sx={{
                  width: 289,
                  height: 'max-content',
                  pt: 1,
                  pl: 2,
                  pr: 2,
                  pb: 0,
                  bgcolor: 'background.paper',
                  boxShadow:
                    '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
                  borderRadius: 1,
                  ...popperStackSx
                }}
              >
                {!disableColumnSearch && (
                  <>
                    <Typography color="secondary.main" fontSize={12} {...typoProps}>
                      Find Column
                    </Typography>
                    <TextField
                      variant="standard"
                      placeholder="Column Title"
                      InputLabelProps={{ shrink: false }}
                      color="secondary"
                      onChange={onChange}
                    />
                  </>
                )}

                <FormGroup sx={{ height: '100%', mt: 0.5 }}>
                  {columns.map((item) => (
                    <FormControlLabel
                      onChange={onSwitch(item?.field)}
                      key={item?.field}
                      control={
                        <SwitchComponent
                          color="secondary"
                          checked={columnsFilter[item?.field] ?? true}
                          disabled={item.hideable === false}
                        />
                      }
                      label={item?.headerName}
                    />
                  ))}
                </FormGroup>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, ...buttonSx }}>
                  <BaseButton buttonText="HIDE ALL" onClick={onHideAll} />
                  <BaseButton buttonText="SHOW ALL" onClick={onShowAll} />
                </Box>
              </Stack>
            </Fade>
          )}
        </Popper>
      </Stack>
    </ClickAwayListener>
  );
};
