import { useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { getAutodeskForgeSelectedSubItemIdStack as GET_AUTODESK_FORGE_SELECTED_SUB_ITEM_ID_STACK } from 'graphql/queries';
import { usePublishActionFormContext } from 'modules/Sync/WorkflowEditor/Contexts/PublishActionContext';
import FoldersSelectDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/PublishActionComponents/FoldersSelectDropdown';
import { getLatestFolders } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';

import { getForgeSelectEventHandlers } from './forgeHelpers';

const ForgeFoldersDropdown = (props) => {
  const {
    connectionId,
    nodeId,
    isSource = false,
    disabled = false,
    isLoadingFolders = false,
    onFolderStackLoad = () => {},
    fetchMoreRootFolders,
    folders
  } = props;

  const [isInitialStackFetched, setIsInitialStackFetched] = useState(false);
  const { getConnectorNodeValues, updateConnectorNodeValues } = usePublishActionFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const setFormValues = (props) => updateConnectorNodeValues({ isSource, nodeId, props });
  const { hub, project, folders: formFolders, folder = formFolders?.[0] } = formValues;

  const [loadForgeFolderStack, { loading: isLoadingForgeFolderStack }] = useLazyQuery(
    gql(GET_AUTODESK_FORGE_SELECTED_SUB_ITEM_ID_STACK)
  );

  const { fetchForgeFolders } = getForgeSelectEventHandlers({ connectionId, hub, project });

  const fetchMoreFoldersHandler = async ({ isRoot = false, folderId, skip, onCompleted }) => {
    if (!isRoot) {
      return fetchForgeFolders({ folderId, skip, onCompleted });
    }
    await fetchMoreRootFolders(skip, {
      connectionId,
      hubId: hub?.id,
      projectId: project?.id,
      isPublishModel: true
    });
    return onCompleted(getLatestFolders(folders));
  };

  const onFolderExpandHandler = async ({ folderId, onCompleted, skip, take }) => {
    const autodeskForgeSubItems = await fetchForgeFolders({ folderId, skip, take });
    return onCompleted(autodeskForgeSubItems);
  };

  const onFolderSelectHandler = ({ onCompleted }) => onCompleted();

  const onFolderStackLoadCompletion = async (response) => {
    const { getAutodeskForgeSelectedSubItemIdStack } = response;
    let expandedFolderIds = [...(getAutodeskForgeSelectedSubItemIdStack || [])]?.reverse();
    expandedFolderIds = [...new Set(expandedFolderIds)];
    const closestParentFolderId = expandedFolderIds[expandedFolderIds.length - 2] || '';
    await setFormValues({ expandedFolderIds });
    onFolderStackLoad?.(closestParentFolderId);
    setIsInitialStackFetched(true);
  };

  const onFolderDropdownClick = async () => {
    if (folder?.id && !isInitialStackFetched) {
      await loadForgeFolderStack({
        variables: {
          query: {
            connectionId,
            hubId: hub?.id,
            projectId: project?.id,
            selectedFolderId: folder?.id,
            isPublishModel: true
          }
        },
        onCompleted: onFolderStackLoadCompletion
      });
    }
  };

  return (
    <FoldersSelectDropdown
      {...props}
      isLoading={isLoadingFolders}
      disabled={isLoadingFolders || disabled}
      isLoadingFolderStack={isLoadingForgeFolderStack}
      isInitialStackFetched={isInitialStackFetched}
      onFolderExpandHandler={onFolderExpandHandler}
      onFolderSelectHandler={onFolderSelectHandler}
      onFolderDropdownClick={onFolderDropdownClick}
      fetchMoreFoldersHandler={fetchMoreFoldersHandler}
    />
  );
};

export default ForgeFoldersDropdown;
