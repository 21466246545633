import { gql } from '@apollo/client';

import getClient from 'apollo/client';
import { FETCH_POLICIES } from 'constants/globalConstants';
import { getNewformaFolders as GET_NEWFORMA_FOLDERS } from 'graphql/queries';
import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { multiSelectFolders } from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown/folderTreeItemHelper';

const {
  NO_CACHE: { fetchPolicy: NO_CACHE_FETCH_POLICY }
} = FETCH_POLICIES;

export const getNewformaSelectEventHandlers = ({
  updateFormValues,
  loadFolders,
  folders,
  uiFolders,
  onCompleted = () => {},
  connectionId,
  parentFolderId,
  setParentFolderId,
  project = {}
}) => {
  const selectProjectHandler = async ({ target: { value: projectId } = {} }, childElement) => {
    updateFormValues({
      hub: null,
      project: { id: projectId, name: childElement?.props?.dataText },
      folder: { id: '', name: '' },
      folders: [],
      isSubmitted: false
    });
    await loadFolders({
      connectionId,
      projectId: Buffer.from(projectId, 'utf-8').toString('base64'),
      skip: 0,
      take: DROPDOWN_DEFAULT_PG_SIZE
    });
    onCompleted?.();
  };

  const selectFoldersHandler = (event, id, children) =>
    multiSelectFolders({
      event,
      id,
      folders,
      uiFolders,
      children,
      parentFolderId,
      setParentFolderId,
      updateFormValues
    });

  const fetchNewformaFolders = async ({
    folderId,
    fetchPolicy = NO_CACHE_FETCH_POLICY,
    skip = 0,
    take = DROPDOWN_DEFAULT_PG_SIZE
  }) => {
    const client = await getClient();
    const { data } = await client.query({
      query: gql(GET_NEWFORMA_FOLDERS),
      variables: {
        query: {
          connectionId,
          projectId: Buffer.from(project?.id, 'utf-8').toString('base64'),
          parentFolderId: Buffer.from(folderId, 'utf-8').toString('base64'),
          skip,
          take
        }
      },
      fetchPolicy
    });
    const response = data?.getNewformaFolders || [];
    return response;
  };

  return {
    selectProjectHandler,
    selectFoldersHandler,
    fetchNewformaFolders
  };
};
