import React, { useMemo, useState } from 'react';

const SyncWorkflowsContext = React.createContext({});

const SyncWorkflowsProvider = ({ children }) => {
  const [selectedWorkflowIds, setSelectedWorkflowIds] = useState([]);
  const [selectedWorkflowIdsWithStatus, setSelectedWorkflowIdsWithStatus] = useState([]);
  const [selectedMappingIds, setSelectedMappingIds] = useState([]);
  const [selectedMappingIdsWithStatus, setSelectedMappingIdsWithStatus] = useState([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState([]);
  const [selectedActionIds, setSelectedActionIds] = useState([]);
  const [selectedActionIdsWithStatus, setSelectedActionIdsWithStatus] = useState([]);
  const [currentWorkflow, setCurrentWorkflow] = useState({});
  const [currentMapping, setCurrentMapping] = useState({});
  const [activeModal, setActiveModal] = useState('');
  const [searchPhrase, setSearchPhrase] = useState('');
  const [firstSelectedItemName, setFirstSelectedItemName] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [workflowSelectionModel, setWorkflowSelectionModel] = useState([]);

  const selectedStateObj = useMemo(
    () => ({
      selectedWorkflowIds,
      setSelectedWorkflowIds,
      setSelectedWorkflowIdsWithStatus,
      selectedMappingIdsWithStatus,
      setSelectedMappingIdsWithStatus,
      selectedWorkflowIdsWithStatus,
      selectedActionIdsWithStatus,
      setSelectedActionIdsWithStatus,
      currentWorkflow,
      setCurrentWorkflow,
      activeModal,
      setActiveModal,
      selectedMappingIds,
      setSelectedMappingIds,
      currentMapping,
      setCurrentMapping,
      selectedTemplateIds,
      setSelectedTemplateIds,
      searchPhrase,
      firstSelectedItemName,
      setFirstSelectedItemName,
      setSearchPhrase,
      selectedActionIds,
      setSelectedActionIds,
      activeTab,
      setActiveTab,
      workflowSelectionModel,
      setWorkflowSelectionModel
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedWorkflowIds,
      selectedMappingIdsWithStatus,
      selectedWorkflowIdsWithStatus,
      selectedActionIdsWithStatus,
      currentWorkflow,
      activeModal,
      selectedMappingIds,
      currentMapping,
      activeTab,
      selectedTemplateIds,
      searchPhrase,
      selectedActionIds,
      firstSelectedItemName,
      workflowSelectionModel
    ]
  );

  return <SyncWorkflowsContext.Provider value={selectedStateObj}>{children}</SyncWorkflowsContext.Provider>;
};

const useSyncWorkflowsContext = () => {
  const context = React.useContext(SyncWorkflowsContext);
  if (context === undefined) {
    throw new Error('useSyncWorkflowsContext must be used within a SyncWorkflowsContext');
  }
  return context;
};

export { SyncWorkflowsContext, SyncWorkflowsProvider, useSyncWorkflowsContext };

export const WORKFLOWS_APIS = [
  'syncWorkflows',
  'syncArchivedWorkflows',
  'syncGetAllWorkflowMappingTemplates',
  'syncGetWorkflowMappingTemplatesList'
];
