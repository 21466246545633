import { add } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { formatTo } from 'helpers/dateFunctions';
import {
  CONNECTION_NAME,
  CONNECTION_TYPES,
  DEFAULT_ROOT_FOLDER,
  defaultSyncSchedule,
  DESKTOP_CONNECTION_NAME,
  DESKTOP_CONNECTION_TYPE_MAPPING,
  DESKTOP_FOLDER_TYPE_MAPPING,
  DESKTOP_FOLDER_TYPE_MAPPING_API,
  DRAWER_NAV_MENU_ITEMS,
  MAPPING_ACTIONS,
  MIGRATION_TOOL,
  SYNC_NOW
} from 'modules/Sync/Constants/constants';
import { getCanvasTemplate } from 'modules/Sync/helpers/canvasTemplate';
import { formatWindowsFolderId } from 'modules/Sync/helpers/WindowsFolderId';
import { parseRequestScheduleSyncJson } from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';

export const getSyncAllConfigData = (syncData) => {
  const workflowIds = syncData.map((item) => item.workflowId);
  const mappingCount = syncData?.reduce((acc, item) => acc + item?.mappings?.length, 0);
  let interval = 5;
  if (mappingCount <= 96) interval = 15;
  if (mappingCount > 96 && mappingCount <= 144) interval = 10;

  return { workflowIds, mappingCount, interval };
};

export const getFinalSyncTime = (syncData) => {
  const { interval, mappingCount: mappings } = getSyncAllConfigData(syncData);
  const mappingCount = mappings > 0 ? mappings - 1 : mappings;
  const totalScheduledMinutes = mappingCount * interval;

  const lastDate = add(new Date(), { minutes: totalScheduledMinutes });
  const formattedTime = formatTo(lastDate, 'hh:mm A');
  const formattedDate = formatTo(lastDate, 'DD/MM/YY');

  return { date: formattedDate, time: formattedTime, interval };
};

function getFolderType(additionalData) {
  const dataSplit = additionalData?.split(';');
  if (dataSplit?.[0].includes(MIGRATION_TOOL.FOLDER_TYPE)) {
    const connectionType = additionalData?.split(';').find((item) => item.includes('ConnectionType'));
    const folderType = DESKTOP_FOLDER_TYPE_MAPPING_API?.[connectionType];
    return folderType;
  }
  return false;
}

function getAdditionalData(additionalData) {
  if (!additionalData) return additionalData;
  const dataSplit = additionalData.split(';');
  if (dataSplit?.[0].includes(MIGRATION_TOOL.FOLDER_TYPE)) {
    const connectionType = additionalData?.split(';').find((item) => item.includes('ConnectionType'));
    const folderType = DESKTOP_FOLDER_TYPE_MAPPING?.[connectionType];
    dataSplit[0] = `folderType=${folderType}`;

    return dataSplit.join(';');
  }
  return additionalData;
}

function getFolderData(mapping, connectionId, connectionType) {
  const { hubsId, projectId, folderId, name, additionalData } = mapping;

  const res = {
    connectionName: name,
    connectionId,
    companyId: hubsId,
    projectId,
    folderId: name === CONNECTION_NAME[CONNECTION_TYPES.WINDOWS] ? formatWindowsFolderId(folderId) : folderId,
    connectionType,
    connectionTypeId: getFolderType(additionalData)
  };

  return res;
}

const getNameData = async (input, helperMethods) => {
  const { getFolderNameHandler, getProjectNameHandler, getCompanyNameHandler } = helperMethods;
  const { connectionName, connectionType, projectId, companyId } = input;
  const folderData = input?.folderId ? (await Promise.all(
    input?.folderId?.split('|').map(async (item) => {
      const name = await getFolderNameHandler({ ...input, folderId: item }, connectionType);
      return {
        id: getFolderId(connectionName, item),
        name
      };
    })
  )) : []
  const projectName = await getProjectNameHandler(input, connectionType, projectId);
  const companyName = await getCompanyNameHandler(input, connectionType, companyId);

  return { folderData, projectName, companyName };
};

const isNotValidMapping = (mapping, sourceId, destinationId, connections) => {
  const { source, destination } = mapping;
  const windowsConnection = CONNECTION_NAME[CONNECTION_TYPES.WINDOWS];

  if (source.name === windowsConnection || destination?.name === windowsConnection) {
    const isWindowsAvailable = !connections.find((item) => item.type === CONNECTION_TYPES.WINDOWS);
    const isBothWindows = source?.name === windowsConnection && destination?.name === windowsConnection;
    const isSourceWindows = source?.name === windowsConnection && destinationId;
    const isDestinationWindows = destination?.name === windowsConnection && sourceId;

    if (isBothWindows || isSourceWindows || isDestinationWindows) {
      return isWindowsAvailable;
    }

    return true;
  }

  return !sourceId || !destinationId;
};

const getFolderId = (connectionName, id) => {
  if (connectionName === CONNECTION_NAME[CONNECTION_TYPES.WINDOWS]) return formatWindowsFolderId(id);

  if (connectionName !== DESKTOP_CONNECTION_NAME.AUTODESKFIELD) return id;

  return `${DEFAULT_ROOT_FOLDER.ID}/${id}`;
};

const getMappingObj = async (input) => {
  const {
    mapping,
    displayName,
    actionConnector,
    sourceConnector,
    sourceCompanyName,
    sourceProjectName,
    sourceFolders,
    sourceAdditionalData,
    destinationConnector,
    destinationCompanyName,
    desitnationProjectName,
    destinationFolders,
    destinationAdditionalData,
    canvasNode,
    solitaryFlowId,
    sourceSyncDetailsId,
    destinationSyncDetailsId
  } = input;

  let resData = {
    mappingId: mapping.mappingId,
    mappingName: mapping.name,
    solitaryFlowConfigurations: [
      {
        solitaryFlowId,
        action: MAPPING_ACTIONS.copyFiles,
        actionNodeId: actionConnector,
        actionAlias: `${displayName} - ${mapping.name}`,
        source: {
          syncDetailsId: sourceSyncDetailsId,
          nodeId: sourceConnector,
          connectionId: mapping.source.connectionId,
          connectionName: mapping.source.name,
          hub: {
            id: mapping.source.hubsId,
            name: sourceCompanyName
          },
          projects: Array.isArray(sourceProjectName)
            ? sourceProjectName
            : [
                {
                  id: mapping.source.projectId,
                  name: sourceProjectName,
                  folders: sourceFolders
                }
              ],
          additionalData: sourceAdditionalData,
          settings: {
            isSyncAllRevisions: mapping.isSyncAllRevisions || false,
            includeExtensions: JSON.stringify(mapping.includeExtensions) || null,
            secondaryFilesSetting: JSON.stringify(mapping.secondaryFilesSetting) || null,
            isSyncEnabled: false,
            syncType: SYNC_NOW,
            scheduleSyncConfig: parseRequestScheduleSyncJson(defaultSyncSchedule)
          }
        },
        destinations: [
          {
            syncDetailsId: destinationSyncDetailsId,
            nodeId: destinationConnector,
            connectionId: mapping.destination.connectionId,
            connectionName: mapping.destination.name,
            hub: {
              id: mapping.destination.hubsId,
              name: destinationCompanyName
            },
            project: {
              id: mapping.destination.projectId,
              name: desitnationProjectName,
              folder: destinationFolders?.[0] || {}
            },
            additionalData: destinationAdditionalData
          }
        ]
      }
    ],
    canvasNode,
    isDuplicate: !!solitaryFlowId
  };

  if (solitaryFlowId) resData = { ...resData, shouldOverwrite: false };

  return resData;
};

const getMappingData = async (data, helperMethods, connections, existingWorkflow) => {
  const { getTypeFromId, getConnectionIdbyName, getConnectionNameById } = helperMethods;
  const { ACTION, CONNECTOR } = DRAWER_NAV_MENU_ITEMS;
  const errors = [];
  const { workflowMapping: existingMappings } = existingWorkflow;

  const mappings = await data?.mappings?.reduce(async (accumulator, mapping) => {
    accumulator = await accumulator;

    const { workflowMappingId: solitaryFlowId, solitaryFlows } =
      existingMappings?.find((item) => item.workflowMappingId === mapping?.mappingId) || {};

    const {
      actionNodeId,
      source: { syncDetailsId: sourceSyncDetailsId, nodeId: sourceNodeId } = {},
      destinations: [{ syncDetailsId: destinationSyncDetailsId, nodeId: destinationNodeId } = {}] = []
    } = solitaryFlows?.find((item) => item.solitaryFlowId === mapping?.mappingId) || {};

    const actionConnector = actionNodeId || `${ACTION}-${uuidv4()}`;
    const sourceConnector = sourceNodeId || `${CONNECTOR}-${uuidv4()}`;
    const destinationConnector = destinationNodeId || `${CONNECTOR}-${uuidv4()}`;
    const sourceAdditionalData = getAdditionalData(mapping.source.additionalData);
    const destinationAdditionalData = getAdditionalData(mapping.destination.additionalData);

    const sourceType = getTypeFromId(mapping.source.name);
    const sourceConnectionId = getConnectionIdbyName(mapping.source.name, mapping.source.connectionId);
    const destinationType = getTypeFromId(mapping.destination.name);
    const destinationConnectionId = getConnectionIdbyName(mapping.destination.name, mapping.destination.connectionId);
    const sourceConnectionName = getConnectionNameById(mapping.source.connectionId);
    const destinationConnectionName = getConnectionNameById(mapping.destination.connectionId);

    const isNotValid = isNotValidMapping(mapping, sourceConnectionId, destinationConnectionId, connections);

    if (isNotValid) {
      const errorMessage = `Skipped Mapping ${mapping?.name} due to incorrect connection Id`;
      errors.push(errorMessage);
    } else {
      const sourceFolderData = getFolderData(mapping.source, sourceConnectionId, sourceType);
      const destinationFolderData = getFolderData(mapping.destination, destinationConnectionId, destinationType);

      const {
        folderData: sourceFolders,
        projectName: sourceProjectName,
        companyName: sourceCompanyName
      } = await getNameData(sourceFolderData, helperMethods);

      const {
        folderData: destinationFolders,
        projectName: desitnationProjectName,
        companyName: destinationCompanyName
      } = await getNameData(destinationFolderData, helperMethods);

      const canvasInput = {
        sourceName: sourceConnectionName || mapping.source.name,
        sourceType,
        destinationName: destinationConnectionName || mapping.destination.name,
        destinationType,
        actionConnector,
        sourceConnector,
        destinationConnector,
        sourceTaskId: sourceConnectionId,
        destinationTaskId: destinationConnectionId,
        sourceConnectionName,
        destinationConnectionName
      };

      const canvasNode = getCanvasTemplate(canvasInput);

      const mappingObjInput = {
        mapping,
        displayName: data?.displayName,
        actionConnector,
        sourceConnector,
        sourceCompanyName,
        sourceProjectName,
        sourceFolders,
        sourceAdditionalData,
        destinationConnector,
        destinationCompanyName,
        desitnationProjectName,
        destinationFolders,
        destinationAdditionalData,
        canvasNode,
        solitaryFlowId,
        sourceSyncDetailsId,
        destinationSyncDetailsId
      };

      const mappingObj = await getMappingObj(mappingObjInput);
      return [...accumulator, mappingObj];
    }
    return accumulator;
  }, []);

  return { mappings, errors };
};

export const extractWorkflowConfig = async (data, connections = [], getWorkflowDataMethods) => {
  const { getProjectName, getCompanyName, getFolderName, getWorkflowById } = getWorkflowDataMethods;
  let workflowData = {
    workflowName: data.displayName,
    workflowId: data.filePath.split('\\')[1].split('_')[0]
  };

  const getExistingWorkflowData = async (workflowId) => {
    const { data: { getWorkflowById: workflowData = {} } = {} } = await getWorkflowById({
      variables: {
        params: { workflowId }
      }
    });
    return workflowData || {};
  };

  const existingWorkflow = await getExistingWorkflowData(workflowData.workflowId);

  const getProjectNameHandler = async (data, connectionType, projectId) => {
    const resultPromise = (await getProjectName(data, connectionType)) || [];
    if (connectionType === CONNECTION_TYPES.SHAREPOINT) {
      const sharepointProjects = resultPromise?.filter((item) => projectId.includes(item.id));
      if (sharepointProjects.length > 1) {
        return sharepointProjects.map((item) => ({ id: item.id, name: item.text, folders: [] }));
      }
    }

    return resultPromise?.filter((item) => item.id === projectId)?.[0]?.text || '';
  };

  const getCompanyNameHandler = async (data, connectionType, companyId) => {
    const resultPromise = (await getCompanyName(data, connectionType)) || [];
    return resultPromise?.filter((item) => item.id === companyId)?.[0]?.text || '';
  };

  const getFolderNameHandler = async (data, connectionType) => {
    const resultPromise = await getFolderName(data, connectionType);
    return resultPromise || '';
  };

  const getTypeFromId = (name) => DESKTOP_CONNECTION_TYPE_MAPPING?.[name] || name;

  const getConnectionIdbyName = (name, DesktopConnectionId) => {
    const connectionType = DESKTOP_CONNECTION_TYPE_MAPPING[name];
    const connection = connections?.find(
      (item) => item.type === connectionType && item.desktopConnectionId === DesktopConnectionId
    );
    return connection?.id || null;
  };

  const getConnectionNameById = (id) => connections?.find((item) => item.desktopConnectionId === id)?.name;

  const helperMethods = {
    getProjectNameHandler,
    getCompanyNameHandler,
    getFolderNameHandler,
    getTypeFromId,
    getConnectionIdbyName,
    getExistingWorkflowData,
    getConnectionNameById
  };
  const { mappings, errors } = await getMappingData(data, helperMethods, connections, existingWorkflow);
  workflowData = { ...workflowData, mappings, errors };
  return workflowData;
};

export const importErrors = (errorkey, obj = {}) => {
  const isAdded = errorkey in obj && !obj?.[errorkey];
  return { isAdded, error: obj?.[errorkey] };
};
