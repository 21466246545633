import React from 'react';

import { Box, IconButton, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import OutlinedButton from 'modules/Sync/components/OutlinedButton';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { actionColors, greyColors, surfaceColors, syncColors } from 'styles/theme/colors';

const NewConnectionButton = ({ type = 'button' }) => {
  const { currentConnection, setCurrentConnection, setHeaderActionsDisabled, headerActionsDisabled } =
    useSyncConnectionsContext();

  const onClick = () => {
    if (!headerActionsDisabled) {
      setCurrentConnection({ connectionType: '' });
      setHeaderActionsDisabled(true);
    }
  };

  const onClose = () => {
    setCurrentConnection(null);
    setHeaderActionsDisabled(false);
  };

  const RowType = () => (
    <Box
      sx={{
        width: 500,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        transition: '0.2s',
        '&.MuiDataGrid-row': { background: actionColors.lightSurface.selected }
      }}
    >
      <Custom.CloseIcon
        style={{
          fill: actionColors.lightSurface.active,
          cursor: 'pointer',
          marginLeft: -47,
          marginRight: 23
        }}
        onClick={onClose}
      />
      <Typography id="AddItemAction" fontSize={14}>
        New connector
      </Typography>
    </Box>
  );

  const ButtonType = () => (
    <>
      {currentConnection ? (
        <CustomSyncTooltip title="New connector" sx={{ maxWidth: 35 }}>
          <IconButton onClick={onClick} disabled={headerActionsDisabled}>
            <Custom.AddBoxIcon
              style={{
                fill: headerActionsDisabled ? surfaceColors.lightSurface.disabled : greyColors.grey900
              }}
            />
          </IconButton>
        </CustomSyncTooltip>
      ) : (
        <OutlinedButton
          onClick={onClick}
          disabled={headerActionsDisabled}
          icon={
            <Custom.Add
              style={{
                fill: headerActionsDisabled ? syncColors.statusColors.disabled : syncColors.text,
                marginRight: 10,
                height: 12,
                width: 12
              }}
            />
          }
          label="New connector"
          labelId="AddItemAction"
        />
      )}
    </>
  );

  return type === 'row' ? <RowType /> : <ButtonType />;
};

export default NewConnectionButton;
