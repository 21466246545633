import { formatTo } from 'helpers/dateFunctions';
import { ISSUE_MANAGEMENT_FILENAME_RESTRICTED_CHARS_REGEX, ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION, MAPPING_ACTIONS, MAPPING_ACTION_TYPES } from 'modules/Sync/Constants/constants';

import { parseRequestScheduleSyncJson } from '../syncHelpers';

export const getFilter = (property, value = '', operator = 0, negate = false) => ({
  property,
  value,
  operator,
  negate
});

export const getNewFilterCriteria = (filterCriteria = [], selectedFilters = []) => {
  filterCriteria = filterCriteria || [];
  const newFilterCriteria = selectedFilters.reduce((filters, { value }) => {
    const existingFilter = filterCriteria.find(({ property }) => property === value);
    return existingFilter ? [...filters, existingFilter] : [...filters, getFilter(value)];
  }, []);

  return newFilterCriteria;
};

export const updateFilterCriteria = (filterCriteria = [], filter = '', selectedFilterTypeValues) => {
  filterCriteria = filterCriteria || [];
  return filterCriteria.map((currFilterCriteria) =>
    currFilterCriteria.property === filter
      ? { ...currFilterCriteria, value: selectedFilterTypeValues }
      : currFilterCriteria
  );
}

export const getAdditionalData = (additionalData = '') =>
  typeof additionalData === 'string'
    ? additionalData.split(';').reduce((acc, el) => {
        // eslint-disable-next-line prefer-const
        let [key, value] = el.split('=');
        if (key === 'downloadBehavior' || key === 'uploadBehavior' || key === 'connectionType') {
          value = Number(value);
        }
        if (key === 'setFilePermissionsAsAdmin') {
          value = Boolean(value);
        }
        acc[key] = value;
        return acc;
      }, {})
    : additionalData;

export const setAdditionalData = ({
  folderType = 'document',
  downloadBehavior = 0,
  uploadBehavior = 0,
  setFilePermissionsAsAdmin = false,
  connectionType = 0
}) =>
  `folderType=${folderType};downloadBehavior=${downloadBehavior};uploadBehavior=${uploadBehavior};setFilePermissionsAsAdmin=${setFilePermissionsAsAdmin};connectionType=${connectionType}`;

export const manageIssuesActionPayload = ({
  isSyncEnabled = true,
  syncType,
  scheduleSyncConfig,
  actionNodeId = '',
  actionAlias = '',
  solitaryFlowId = '',
  source = '',
  destinations = [],
  includeExtensions = [],
  isSyncAllRevisions = true,
  secondaryFilesSetting = null
}) => ({
  solitaryFlowId,
  actionNodeId,
  actionAlias,
  action: MAPPING_ACTIONS.manageIssues,
  source: {
    nodeId: source.nodeId,
    hub: {
      id: source?.hub?.id || '',
      name: source?.hub?.name || ''
    },
    projects: [
      {
        id: source?.project?.id,
        name: source?.project?.name
      }
    ],
    folders: [
      {
        id: source?.folder?.id,
        name: source?.folder?.name
      }
    ],
    additionalData: setAdditionalData({
      folderType: source?.folderType || 'document',
      downloadBehavior: source?.downloadBehavior || 0
    }),
    filterCriteria: source?.filterCriteria?.map(({ property, value, operator, negate }) => ({
      property,
      value,
      operator,
      negate
    })),
    folderType: source?.folderType || '',
    ...source
  },
  destinations: destinations.map((destination) => ({
    nodeId: destination.nodeId,
    hub: {
      id: destination?.hub?.id || '',
      name: destination?.hub?.name || ''
    },
    projects: [
      {
        id: destination?.project?.id,
        name: destination?.project?.name
      }
    ],
    folders: [
      {
        id: destination?.folder?.id,
        name: destination?.folder?.name
      }
    ],
    additionalData: setAdditionalData({
      folderType: destination?.folderType || 'document',
      uploadBehavior: source?.uploadBehavior || 0
    }),
    folderType: destination?.folderType || '',
    ...destination
  })),
  settings: {
    isSyncAllRevisions,
    includeExtensions: JSON.stringify(includeExtensions),
    secondaryFilesSetting,
    isSyncEnabled,
    syncType,
    scheduleSyncConfig
  }
});

export const manageIssuesMappingConfigPayload = (mappingActions, currentMapping = {}) => {
  const { workflowName = '', mapping: { mappingName = '' } = {} } = currentMapping;
  return mappingActions.map(
    ({ solitaryFlowId = '', action, destinations, source, settings, actionNodeId, actionAlias }) => {
      const date = formatTo();
      const destinationItemName = `${workflowName} ${mappingName} ${actionAlias} ${date.replaceAll(
        '/',
        '-'
      )}.xlsx`.replaceAll(ISSUE_MANAGEMENT_FILENAME_RESTRICTED_CHARS_REGEX, '');

      return {
        ...(solitaryFlowId ? { solitaryFlowId } : {}),
        action,
        actionNodeId,
        actionAlias,
        actionType: MAPPING_ACTION_TYPES.manageIssues,
        source: {
          ...(source?.syncDetailsId ? { syncDetailsId: source?.syncDetailsId } : {}),
          connectionId: source.connectionId,
          nodeId: source.nodeId,
          hub: {
            id: source?.hub?.id || '',
            name: source?.hub?.name || ''
          },
          projects: source?.projects?.map(({ id, name }) => ({
            id,
            name,
            folders: source?.folders?.map(({ id, name }) => ({ id, name }))
          })),
          additionalData: setAdditionalData({
            folderType: source?.folderType || 'document',
            downloadBehavior: source?.downloadBehavior || 0
          }),
          settings: {
            isSyncAllRevisions: settings?.isSyncAllRevisions,
            includeExtensions: settings?.includeExtensions,
            secondaryFilesSetting: settings?.secondaryFilesSetting,
            isSyncEnabled: settings?.isSyncEnabled,
            syncType: settings?.syncType
          },
          filterCriteria: source?.filterCriteria?.map(({ property, value, operator, negate }) => ({
            property,
            value: value.replace(`${ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION.value},`, ''),
            operator,
            negate
          }))
        },
        destinations: destinations.map(
          ({
            syncDetailsId,
            connectionId,
            nodeId,
            hub,
            project,
            projects,
            folder,
            folderType = 'document',
            uploadBehavior = 0
          }) => ({
            ...(syncDetailsId ? { syncDetailsId } : {}),
            connectionId,
            nodeId,
            hub: { id: hub?.id || '', name: hub?.name || '' },
            project: {
              id: project?.id || projects?.[0]?.id || '',
              name: project?.name || projects?.[0]?.name || '',
              folder: {
                id: folder?.id,
                name: folder?.name,
                items: [
                  {
                    id: destinationItemName,
                    name: destinationItemName
                  }
                ]
              }
            },
            additionalData: setAdditionalData({
              folderType,
              uploadBehavior
            })
          })
        )
      };
    }
  );
};

export const saveSyncConfigPayload = (mappings) =>
  mappings.map(({ solitaryFlowId = '', isSyncEnabled = false, syncType = '', scheduleSyncConfig = '' }) => ({
    solitaryFlowId,
    isSyncEnabled,
    syncType,
    scheduleSyncConfig: parseRequestScheduleSyncJson(scheduleSyncConfig)
  }));
