import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import {
  getAutodeskForgeHubs as GET_AUTODESK_FORGE_HUBS,
  getAutodeskForgeProjects as GET_AUTODESK_FORGE_PROJECTS
} from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { MAX_DROPDOWN_ITEMS_IN_VIEW } from 'modules/Sync/Constants/constants';
import { useManageIssuesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/ManageIssuesContext';
import ForgeFilterDropdown from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/ForgeFilterDropdown';
import { getForgeSelectEventHandlers } from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/forgeHelpers';
import ForgeHubDropdown from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/ForgeHubDropdown';
import ForgeProjectDropdown from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/ForgeProjectDropdown';
import { useForgeMountHook } from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/useForgeMountHook';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const AutodeskForge = ({ connectionId = '', connectionType = '', nodeId = '', isSource = false }) => {
  const { getConnectorNodeValues, updateConnectorNodeValues } = useManageIssuesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { hub, project } = formValues;

  const [
    { lazyLoad: lazyLoadHubs, paginationHandler: hubsPaginationHandler },
    { loading: isLoadingHubs, data: { getAutodeskForgeHubs: ForgeHubs = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_AUTODESK_FORGE_HUBS), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const [
    { lazyLoad: lazyLoadProjects, paginationHandler: projectsPaginationHandler },
    { loading: isLoadingProjects, data: { getAutodeskForgeProjects: ForgeProjects = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_AUTODESK_FORGE_PROJECTS), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const fetchOnMountForgeProjects = hub?.id && !isLoadingHubs && ForgeHubs?.length && !ForgeProjects?.length;
  const disableFilters =
    !hub?.id || !project?.id || !ForgeHubs?.length || !ForgeHubs?.length || isLoadingHubs || isLoadingProjects;

  const updateFormValues = (props) => updateConnectorNodeValues({ nodeId, isSource, props });

  const onScrollHubs = () => hubsPaginationHandler(ForgeHubs?.length, { connectionId });
  const onScrollProjects = () => projectsPaginationHandler(ForgeProjects?.length, { connectionId, hubId: hub?.id });
  const fetchHubsOnLoad = () => {
    if (hub?.id) lazyLoadHubs({ connectionId });
    if (!hub?.id) lazyLoadHubs({ skip: ForgeHubs?.length, connectionId });
  };

  const { selectHubHandler, selectProjectHandler } = getForgeSelectEventHandlers({
    updateFormValues,
    lazyLoadProjects,
    connectionId
  });

  useForgeMountHook({
    connectionId,
    connectionType,
    nodeId,
    hub,
    formValues,
    updateFormValues,
    lazyLoadProjects,
    fetchOnMountForgeProjects,
    fetchHubsOnLoad
  });

  return (
    <Stack spacing={2}>
      <ForgeHubDropdown
        value={hub?.id}
        onChange={selectHubHandler}
        loading={isLoadingHubs}
        items={ForgeHubs}
        fetchPaginatedRecords={onScrollHubs}
      />
      <ForgeProjectDropdown
        value={project?.id}
        onChange={selectProjectHandler}
        loading={isLoadingProjects}
        disabled={!hub?.id || !ForgeHubs?.length}
        items={ForgeProjects}
        fetchPaginatedRecords={onScrollProjects}
      />

      <ForgeFilterDropdown
        disabled={disableFilters}
        connectionId={connectionId}
        nodeId={nodeId}
        isSource={isSource}
        selectAll
      />
    </Stack>
  );
};

export default AutodeskForge;
