import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

const NoRowsOverlay = ({ text, sx }) => (
  <Stack
    sx={{
      background: 'white',
      alignItems: 'center',
      marginTop: '128px',
      height: '100%',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative'
    }}
  >
    <Custom.Workflow width={40} height={40} />
    <Typography
      variant="h6"
      sx={{
        color: 'surface.lightSurface.secondary',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        ...sx
      }}
    >
      {text}
    </Typography>
  </Stack>
);

export default NoRowsOverlay;
