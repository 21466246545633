import React from 'react';

import { Stepper, Step, StepLabel, Stack, StepButton, Typography } from '@mui/material';

import { surfaceColors, syncColors } from 'styles/theme/colors';

import ConnectionsForm from './ConnectionsForm';
import ImportSyncForm from './ImportSyncForm';
import WorkflowsForm from './WorkflowsForm';

const STEPS = ['Connectors', 'Workflow', 'Sync'];

const stepProps = {
  '& .MuiStepIcon-root': {
    '&.Mui-active': { color: 'warning.main' },
    '&.Mui-completed': { color: 'warning.dark' }
  }
};

const stepperSx = {
  width: '100%',
  '.MuiStepLabel-iconContainer *': { color: syncColors.main },
  '.MuiStepLabel-iconContainer.Mui-disabled *': {
    color: surfaceColors.lightSurface.disabled
  }
};

const LabelText = ({ isActive, text }) => (
  <Typography
    color={isActive ? surfaceColors.lightSurface.primary : surfaceColors.lightSurface.secondary}
    variant={isActive ? 'subtitle2' : 'body2'}
  >
    {text}
  </Typography>
);

const ImportConnectionsForm = ({ activeStep, isStepDisabled, onClickHandler, error, handleConfigChange, data }) => (
  <Stack spacing={2} sx={{ justifyContent: 'space-between' }}>
    <Stack direction="row" sx={{ justifyContent: 'center', marginBottom: activeStep ? 0 : 2 }}>
      <Stepper sx={stepperSx} activeStep={activeStep} nonLinear={false}>
        {STEPS.map((step, index) => (
          <Step
            key={step}
            active={!isStepDisabled(index)}
            sx={{ ...stepProps, pl: index === 0 ? 0 : 1 }}
            completed={activeStep > index}
          >
            <StepButton disableRipple onClick={() => onClickHandler(index)} sx={{ zIndex: 9, pt: 0, pb: 0 }}>
              <StepLabel>
                <LabelText isActive={activeStep === index} text={step} />
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Stack>
    {activeStep === 0 && <ConnectionsForm />}
    {activeStep === 1 && <WorkflowsForm error={error} handleConfigChange={handleConfigChange} data={data} />}
    {activeStep === 2 && <ImportSyncForm />}
  </Stack>
);

export default ImportConnectionsForm;
