const useDragAction = () => {
  const dragAction = (event) => {
    const e = event;
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  return { dragAction };
};

export default useDragAction;
