import React, { useState } from 'react';

import { Typography } from '@mui/material';

import FileInput from 'modules/Sync/Connections/components/FileInput';
import Accordion from 'modules/Sync/Connections/components/ImportAccordion';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import ImportConnectionProperties from 'modules/Sync/Connections/Modals/ImportConnectionModal/ImportConnectionProperties';
import ImportTips from 'modules/Sync/Connections/Modals/ImportConnectionModal/ImportTips';
import WarningLoader from 'modules/Sync/Connections/Modals/ImportConnectionModal/WarningLoader';
import { MIGRATION_TOOL } from 'modules/Sync/Constants/constants';
import { extractConnectionConfig } from 'modules/Sync/helpers/extractConnectionConfig';

const ConnectionsForm = () => {
  const { importedConnections, setImportedConnections, deleteConnection } = useSyncConnectionsContext();
  const [expand, setExpand] = useState(null);

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleConfigChange = (file) => {
    if (file?.name !== 'Configuration.json') {
      setError({ message: 'Please select correct configuration file' });
      return;
    }
    setError('');
    setIsLoading(true);

    const fileReader = new FileReader();
    fileReader.readAsText(file, 'UTF-8');
    fileReader.onload = (e) => {
      try {
        const configJson = JSON.parse(e.target.result);
        const connections = extractConnectionConfig(configJson);

        setImportedConnections(connections);
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      } catch (e) {
        setError({ message: 'Invalid JSON File' });
        setIsLoading(false);
      }
    };
  };

  const onDeleteHandler = (connectionId, connectionType) => {
    deleteConnection(connectionId, connectionType);
  };

  const fileName = importedConnections.length ? 'Configuration.json' : '';

  const setExpandHandler = (connectionId, connectionType) => {
    const getLastAddedItem = () => {
      const nextItem =
        importedConnections.findIndex(
          (item) => item.DesktopConnectionId === connectionId && item.connectionType === connectionType
        ) + 1;
      const notAddedItem = importedConnections.find((item) => !item?.isAdded);
      const toExpand = importedConnections[nextItem] || notAddedItem || '';
      return toExpand;
    };
    const expand = getLastAddedItem();
    const toExpandId = `${expand?.DesktopConnectionId}-${expand?.connectionType}`;
    setExpand(toExpandId);
  };

  return (
    <>
      <Typography variant="caption" fontWeight="bold">
        Select the Configuration file and 360 Sync will extract your connections
      </Typography>

      <FileInput onChange={handleConfigChange} error={error} fileName={fileName} />

      {!importedConnections.length && (
        <ImportTips fileName="Configuration.json" textToCopy={MIGRATION_TOOL.DEFAULT_CONNECTION_PATH} />
      )}

      {!isLoading && !error && importedConnections.length > 0 && (
        <Accordion
          hideFirstBorder
          hideLastBorder
          expand={expand ? { id: expand } : null}
          isConnector
          items={importedConnections.map((item) => ({
            id: `${item.DesktopConnectionId}-${item.connectionType}`,
            title: item?.connectionName,
            content: <ImportConnectionProperties currentConnection={{ ...item }} setExpandHandler={setExpandHandler} />,
            isAdded: item.isAdded,
            onDelete: !item.isAdded ? () => onDeleteHandler(item.DesktopConnectionId, item.connectionType) : null
          }))}
          sx={{
            marginTop: '0 !important',
            '& .MuiAccordionSummary-root, .MuiAccordionDetails-root': { padding: 0 }
          }}
        />
      )}

      {isLoading && <WarningLoader />}
    </>
  );
};

export default ConnectionsForm;
