import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

const NoRowsOverlay = ({ text, sx }) => (
  <Stack sx={{ background: 'white', alignItems: 'center', textAlign: 'center', marginTop: 25 }}>
    <Custom.LOGS width={42} height={42} />
    {text && (
      <Typography
        variant="h6"
        sx={{
          color: 'surface.lightSurface.secondary',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          ...sx
        }}
      >
        {text}
      </Typography>
    )}
  </Stack>
);

export default NoRowsOverlay;
