import React from 'react';

import { Box, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import { requiredRule } from 'constants/inputFieldRules';
import { SelectField, ControlCheckbox } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { teamsAndDocsServerOptions } from 'modules/Sync/Constants/constants';

const TeamsAndDocsInputs = ({ control, disableAllFields }) => (
  <Box display="flex" flexDirection="column" flexBasis="50%" fullWidth>
    <SelectField
      control={control}
      name="configuration.forgeTeamDocs.forgeServer"
      label="Teams/Docs Server*"
      rules={requiredRule('Teams/Docs Server*', '*Required')}
      options={teamsAndDocsServerOptions}
      defaultValue={teamsAndDocsServerOptions[0]}
      disabled={disableAllFields}
    />
  </Box>
);

export const AdminLogInputs = ({ control, disableAllFields }) => {
  const { currentConnection } = useSyncConnectionsContext();

  return (
    <Box display="flex" flexDirection="column">
      <ControlCheckbox
        key={currentConnection?.connectionId}
        name="configuration.forgeTeamDocs.shouldUploadFileInfoLog"
        control={control}
        label={<Typography variant="body2">Upload file info log</Typography>}
        checkboxProps={{ size: 'small' }}
        disabled={disableAllFields}
      />
      <Box display="flex" gap="12px" m="12px">
        <Custom.WarningIcon alt="Warning Icon" />
        <Typography variant="body2">Only project admin can upload log files</Typography>
      </Box>
    </Box>
  );
};

export default TeamsAndDocsInputs;
