import { gql, useMutation } from '@apollo/client';

import { syncStopAll as SYNC_STOP_ALL } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useStopSyncAllFlowsApiAction = (queryOptions) => {
  const [stopAllSyncs, { loading }] = useMutation(gql(SYNC_STOP_ALL), queryOptions);
  const { handleResponse } = useGraphqlResponseHandler();

  const stopSyncAllFlows = ({
    mappingId,
    onCompleted,
    successMessage = 'Sync successfully stopped'
  }) =>
    handleResponse(
      stopAllSyncs,
      { variables: { body: { MappingId: mappingId } } },
      { successMessage },
      onCompleted
    );

  return { stopSyncAllFlows, loading };
};

export default useStopSyncAllFlowsApiAction;
