import { useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';
import { gridPaginatedVisibleSortedGridRowEntriesSelector, useGridApiRef } from '@mui/x-data-grid-pro';

import ItemsDataGridPro from 'components/ItemsDatagridPro';
import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncActions as SYNC_ACTIONS } from 'graphql/queries';
import { sortAlphabetically } from 'helpers/stringFunctions';
import {
  ARCHIVED_WORKFLOW_GRID_REFRESH_EVENT,
  DATAGRID_DEFAULT_PG_SIZE,
  MAX_DATAGRID_ROWS_IN_VIEW,
  WORKFLOW_GRID_REFRESH_EVENT
} from 'modules/Sync/Constants/constants';
import { isMigrated, IsSyncFinished } from 'modules/Sync/helpers/logSyncStatusHelpers';
import useArchivedWorkflowsLazyPaginationHook from 'modules/Sync/Workflows/Contexts/useArchivedWorkflowsLazyPaginationHook';
import useWorkflowsLazyPaginationHook from 'modules/Sync/Workflows/Contexts/useWorkflowsLazyPaginationHook';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import NoRowsOverlay from 'modules/Sync/Workflows/WorkflowsDataGridTable/NoRowsOverlay';
import { dataGridStyles } from 'modules/Sync/Workflows/WorkflowsDataGridTable/workflowDataGridStylesHelper';
import { WorkflowMenuItems } from 'modules/Sync/Workflows/WorkflowsDataGridTable/WorkflowMenuItems';
import WorkflowsDataGridCell from 'modules/Sync/Workflows/WorkflowsDataGridTable/WorkflowsDataGridCell';
import {
  defaultDataGridConfig,
  getColumns,
  getGroupingColDef,
  getSelectionModelChangeCb,
  workflowsMapper
} from 'modules/Sync/Workflows/WorkflowsDataGridTable/workflowsDataGridTableHelper';

const NoRowsOverlayText = (activeTab) =>
  ({
    0: 'Start by creating a new workflow.',
    2: 'Archived Workflows/Mappings/Actions will appear here.'
  }[activeTab]);

// eslint-disable-next-line max-lines-per-function
const WorkflowsDataGridTable = ({ activeRows }) => {
  const {
    setSelectedWorkflowIds,
    setSelectedMappingIds,
    setSelectedActionIds,
    searchPhrase,
    activeTab,
    setSelectedWorkflowIdsWithStatus,
    setSelectedMappingIdsWithStatus,
    setSelectedActionIdsWithStatus,
    setFirstSelectedItemName,
    workflowSelectionModel,
    setWorkflowSelectionModel
  } = useSyncWorkflowsContext();

  const { data: actionsList } = useQuery(gql(SYNC_ACTIONS), {
    fetchPolicy: FETCH_POLICIES.NO_CACHE.fetchPolicy,
    onCompleted: (actionsList) => actionsList?.syncActions?.sort(sortAlphabetically)
  });
  const availableActionsName = actionsList?.syncActions?.map((action) => action?.actionName);
  const isUnarchivedDataGrid = activeTab === 0;
  const isArchivedDataGrid = activeTab === 2;

  const {
    archivedWorkflowsSortHandler,
    archivedWorkflowsPaginationHandler,
    loadingArchivedWorkflows,
    archivedWorkflows,
    archivedWorkflowsRefetchHandler
  } = useArchivedWorkflowsLazyPaginationHook(isArchivedDataGrid);

  const { workflowsPaginationHandler, workflowsSortHandler, workflows, loadingWorkflows, workflowsRefetchHandler } =
    useWorkflowsLazyPaginationHook(isUnarchivedDataGrid);
  const apiRef = useGridApiRef();

  const handleCellComponent = ({ id, field, rowNode, row }) => (
    <WorkflowsDataGridCell id={id} field={field} rowNode={rowNode} row={row} options={WorkflowMenuItems(row)} />
  );

  const columns = getColumns({ renderCell: handleCellComponent, apiRef });
  const rows = isUnarchivedDataGrid
    ? workflowsMapper(workflows?.syncWorkflows)
    : workflowsMapper(archivedWorkflows?.syncArchivedWorkflows);
  const lastParentRowId = rows?.filter(({ isWorkflow }) => isWorkflow)?.at(-1)?.id;

  const mappingFlowActionIds = rows.reduce((mappingFlowActionIds, row) => {
    if (row?.isAction) mappingFlowActionIds.push(row.id);
    return mappingFlowActionIds;
  }, []);
  const workflowMappingIds = rows.reduce((workflowMappingIds, row) => {
    if (row?.isMapping) workflowMappingIds.push(row.id);
    return workflowMappingIds;
  }, []);
  const workFlowIds = rows.reduce((workFlowIds, row) => {
    if (row?.isWorkflow) workFlowIds.push(row.id);
    return workFlowIds;
  }, []);

  const onScroll = () => {
    if (isUnarchivedDataGrid) workflowsPaginationHandler(workflows?.syncWorkflows?.length, { searchPhrase });
    if (isArchivedDataGrid)
      archivedWorkflowsPaginationHandler(archivedWorkflows?.syncArchivedWorkflows?.length, {
        searchPhrase
      });
  };

  const handleSort = (sortModel) =>
    sortModel.length ? workflowsSortHandler({ columnName: sortModel?.[0]?.field }) : null;
  const handleSortArchived = (sortModel) =>
    sortModel.length ? archivedWorkflowsSortHandler({ columnName: sortModel?.[0]?.field }) : null;
  const onSelectionModelChange = getSelectionModelChangeCb({
    workflowSelectionModel,
    workflowMappingIds,
    mappingFlowActionIds,
    rows,
    apiRef,
    workFlowIds,
    setSelectedActionIds,
    setSelectedMappingIds,
    setSelectedWorkflowIds,
    setWorkflowSelectionModel,
    setSelectedWorkflowIdsWithStatus,
    setSelectedMappingIdsWithStatus,
    setSelectedActionIdsWithStatus,
    setFirstSelectedItemName
  });

  const isRowSelectable = (row) => {
    if (isArchivedDataGrid) return row?.isArchived;

    const currentNodeName = row?.name.split(' -');
    const isSyncFinished = IsSyncFinished(row?.syncStatus);

    if (isSyncFinished && row?.isAction) return availableActionsName?.includes(currentNodeName[0]);
    if (isSyncFinished) return isMigrated(row?.syncStatus);
    return isSyncFinished;
  };
  const getRowClassName = (row) => {
    if (isArchivedDataGrid && !row?.isArchived && row?.isMapping) return 'archived-mapping-row';
    if (isArchivedDataGrid && !row?.isArchived) return 'archived-row';
    if (row?.isWorkflow) return 'workflow-row';
    if (row?.isMapping) return 'mapping-row';
    return 'action-row';
  };
  const scrollToTop = () => apiRef?.current?.scrollToIndexes({ rowIndex: 0 });

  useEffect(() => {
    if (
      !loadingWorkflows &&
      !loadingArchivedWorkflows &&
      apiRef?.current?.windowRef?.current &&
      activeRows.length &&
      rows.length
    ) {
      const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);

      const rowIndex = allRows?.findIndex(({ id }) => id === activeRows?.[0]);

      const indexToScrollAt =
        rowIndex + MAX_DATAGRID_ROWS_IN_VIEW >= allRows.length
          ? allRows.length - 1
          : rowIndex + MAX_DATAGRID_ROWS_IN_VIEW;
      apiRef?.current?.scrollToIndexes({ rowIndex: Number(indexToScrollAt) });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, activeRows.length, rows.length, loadingWorkflows, loadingArchivedWorkflows]);

  useEffect(() => {
    document.addEventListener(WORKFLOW_GRID_REFRESH_EVENT, async () => {
      /*
      TO DO: Optimize the implementation with refetchExistingPages
      */
      await workflowsRefetchHandler({ skip: 0, take: DATAGRID_DEFAULT_PG_SIZE });
      scrollToTop();
    });
    document.addEventListener(ARCHIVED_WORKFLOW_GRID_REFRESH_EVENT, async () => {
      await archivedWorkflowsRefetchHandler({ skip: 0, take: DATAGRID_DEFAULT_PG_SIZE });
      scrollToTop();
    });

    return () => {
      document.removeEventListener(WORKFLOW_GRID_REFRESH_EVENT, workflowsRefetchHandler);
      document.removeEventListener(ARCHIVED_WORKFLOW_GRID_REFRESH_EVENT, archivedWorkflowsRefetchHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ItemsDataGridPro
      {...defaultDataGridConfig}
      key={rows?.[0]?.id}
      apiRef={apiRef}
      isGroupExpandedByDefault={(node) => {
        if (activeRows.length) return activeRows.includes(node.id);
        return rows?.[0]?.id === node?.id || rows?.[1]?.id === node?.id;
      }}
      groupingColDef={getGroupingColDef({ lastParentRowId, onWaypointEnter: onScroll })}
      loading={isUnarchivedDataGrid ? loadingWorkflows : loadingArchivedWorkflows}
      getTreeDataPath={(row) => row.path}
      rows={rows}
      getRowId={(row) => row.id}
      getRowClassName={({ row }) => getRowClassName(row)}
      asyncApi={{ onScroll }}
      columns={columns}
      components={{ NoRowsOverlay: () => <NoRowsOverlay text={NoRowsOverlayText(activeTab)} /> }}
      selectionModel={workflowSelectionModel}
      onSelectionModelChange={onSelectionModelChange}
      isRowSelectable={(data) => isRowSelectable(data.row)}
      onSortModelChange={isUnarchivedDataGrid ? handleSort : handleSortArchived}
      sortingOrder={['asc', 'desc']}
      sx={dataGridStyles}
    />
  );
};

export default WorkflowsDataGridTable;
