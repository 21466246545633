import React from 'react';

import { useLocation } from 'react-router';

import { CancelButton, CreateButton } from 'components';
import { SCHEDULE_SYNC } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import { isTemplateCanvas } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';
import { syncColors } from 'styles/theme/colors';

const getButtonText = (syncSettings, isTemplate = false) => {
  if (isTemplate) {
    return 'Save';
  }

  if (syncSettings?.isSyncEnabled && syncSettings?.syncType !== SCHEDULE_SYNC) {
    return 'Save & Sync';
  }

  return 'Save';
};

const ModalFooter = ({
  disableSubmit,
  sourceSubmitDisabled,
  destinationSubmitDisabled,
  onClose,
  onBack,
  onSubmit,
  onNext
}) => {
  const { activeStep, syncSettings } = useCopyFilesFormContext();
  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === 2;
  const { pathname } = useLocation();
  const isTemplate = React.useMemo(() => isTemplateCanvas(pathname), [pathname]);

  const buttonText = getButtonText(syncSettings, isTemplate);
  return (
    <>
      <CancelButton
        onClick={onClose}
        color={syncColors.text}
        sx={{
          '&:hover': { backgroundColor: 'secondary.light' },
          '&:disabled': { borderColor: 'action.lightSurface.disabled' }
        }}
      />
      {!isFirstStep && (
        <CreateButton
          buttonText="Previous"
          onClick={onBack}
          variant="outlined"
          sx={{
            '&.MuiButton-root': {
              color: syncColors.text,
              '&:disabled': { color: 'action.lightSurface.disabled' }
            }
          }}
        />
      )}
      {!isLastStep && (
        <CreateButton
          buttonText="Next"
          onClick={onNext}
          disabled={isFirstStep ? sourceSubmitDisabled : destinationSubmitDisabled}
          variant="outlined"
          sx={{
            '&.MuiButton-root': {
              color: syncColors.text,
              '&:disabled': { color: 'action.lightSurface.disabled' }
            }
          }}
        />
      )}
      {isLastStep && (
        <CreateButton buttonText={buttonText} onClick={onSubmit} disabled={disableSubmit} />
      )}
    </>
  );
};

export default ModalFooter;
