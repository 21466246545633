import React from 'react';

import { Typography } from '@mui/material';

const ModalTitle = ({ title }) => (
  <Typography fontWeight={600} variant="body4">
    {title}
  </Typography>
);

export default ModalTitle;
