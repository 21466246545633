import React from 'react';

import { Typography, Stack } from '@mui/material';

import { Custom } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';

const ListFlyoutPaperTitle = ({ children, textHelp, sx }) => (
  <Stack
    direction="row"
    sx={{
      color: 'surface.darkSurface.primary',
      pb: '10px',
      mt: 2,
      ml: 2,
      mr: 2,
      borderBottom: '1px solid #CCCCCC',
      ...sx
    }}
  >
    <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>{children}</Typography>
    <NavItemTooltip placement="bottom" title={textHelp}>
      <Custom.InfoRounded
        style={{
          fill: '#ffffffb3',
          marginTop: '5px',
          marginLeft: '9.5px',
          width: '18px'
        }}
      />
    </NavItemTooltip>
  </Stack>
);

export default ListFlyoutPaperTitle;
