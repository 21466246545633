import React from 'react';

import { Box, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import BaseButton from 'components/ItemsDatagridPro/BaseButton';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { actionColors, surfaceColors, syncColors } from 'styles/theme/colors';

const ImportConnectionButton = ({ type = 'button' }) => {
  const { currentConnection, headerActionsDisabled, setImportConnectionOpen, setImportedConnections } =
    useSyncConnectionsContext();

  const onClick = () => {
    if (!headerActionsDisabled) {
      setImportConnectionOpen(true);
    }
  };

  const onClose = () => {
    setImportConnectionOpen(false);
    setImportedConnections([]);
  };

  const RowType = () => (
    <Box
      sx={{
        width: 500,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        transition: '0.2s',
        '&.MuiDataGrid-row': { background: actionColors.lightSurface.selected }
      }}
      onClick={onClose}
    >
      <Custom.CloseIcon
        style={{
          fill: actionColors.lightSurface.active,
          marginLeft: -47,
          marginRight: 23
        }}
      />
      <Typography id="ImportConnectionAction" fontSize={14}>
        Import Connectors
      </Typography>
    </Box>
  );

  const ButtonType = () => (
    <>
      {!currentConnection && (
        <BaseButton
          onClick={onClick}
          title="Import from desktop"
          icon={(others) => (
            <Custom.Import
              sx={{
                fontSize: '16px',
                color: others.disabled ? surfaceColors.lightSurface.disabled : 'secondary.main',
                marginTop: '3px',
                marginRight: '4px'
              }}
            />
          )}
          sx={{
            '&:hover': { backgroundColor: syncColors.tableHover },
            '&:disabled': { color: 'action.lightSurface.disabled' },
            '.MuiStack-root': { alignItems: 'center', gap: '5px' }
          }}
          buttonText="Import"
        />
      )}
    </>
  );

  return type === 'row' ? <RowType /> : <ButtonType />;
};

export default ImportConnectionButton;
