import React from 'react';

import { useMutation, gql } from '@apollo/client';
import Typography from '@mui/material/Typography';

import { syncDeleteTemplate as SYNC_DELETE_TEMPLATE } from 'graphql/mutations';
import { dispatchTemplateGridEvent } from 'helpers/domHelpers';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';

import ModalFooter from './ModalFooter';

const DeleteTemplateModal = ({ onClose }) => {
  const { activeModal, selectedTemplateIds } = useSyncWorkflowsContext();
  const { handleResponse } = useGraphqlResponseHandler();

  const [deleteTemplates, { loading }] = useMutation(gql(SYNC_DELETE_TEMPLATE));

  const onSuccessHandler = () => {
    dispatchTemplateGridEvent();
    onClose();
  };
  const submit = async () => {
    await handleResponse(
      deleteTemplates,
      { variables: { body: { TemplateIds: selectedTemplateIds } } },
      { successMessage: 'Template(s) successfully deleted' },
      onSuccessHandler,
      onClose
    );
  };

  return (
    <CustomModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={activeModal === WORKFLOW_MODAL_KEYS.DELETE_TEMPLATE}
      onClose={onClose}
      title="Confirm delete"
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      paperSx={{ width: 470 }}
      footer={
        <ModalFooter
          cancelBtnText="NO"
          createBtnText="CONFIRM"
          loading={loading}
          onModalClose={onClose}
          onSubmit={submit}
          isDelete
        />
      }
    >
      <Typography variant="body1" mb="12px" sx={{ mb: '20px' }}>
        Are you sure you want to delete the selected template(s)?
      </Typography>
    </CustomModal>
  );
};

export default DeleteTemplateModal;
