import { useState } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { createAutodeskForgeFolder as CREATE_AUTODESK_FORGE_FOLDER } from 'graphql/mutations';
import { getAutodeskForgeSelectedSubItemIdStack as GET_AUTODESK_FORGE_SELECTED_SUB_ITEM_ID_STACK } from 'graphql/queries';
import useCache from 'hooks/useCache';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import FoldersSelectDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown';
import { getLatestFolders } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';

import { getForgeSelectEventHandlers } from './forgeHelpers';

const {
  NETWORK_ONLY: { fetchPolicy: NETWORK_ONLY_FETCH_POLICY }
} = FETCH_POLICIES;

const FETCH_FOLDERS_API = 'getAutodeskForgeSubItems';

const ForgeFoldersDropdown = (props) => {
  const { connectionId, nodeId, onFolderStackLoad = () => {}, fetchMoreRootFolders, folders } = props;
  const { isSource = false, disabled = false, isLoadingFolders = false } = props;

  const [isInitialStackFetched, setIsInitialStackFetched] = useState(false);
  const { getConnectorNodeValues, updateConnectorNodeValues, destinations } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId, defaultObject: destinations[0] });
  const setFormValues = (props) => updateConnectorNodeValues({ isSource, nodeId, props });
  const { hub, project, folder, folders: uiFolders } = formValues;
  const { handleResponse } = useGraphqlResponseHandler();
  const { deleteCache } = useCache();

  const [loadForgeFolderStack, { loading: isLoadingForgeFolderStack }] = useLazyQuery(
    gql(GET_AUTODESK_FORGE_SELECTED_SUB_ITEM_ID_STACK)
  );

  const [makeForgeFolder] = useMutation(gql(CREATE_AUTODESK_FORGE_FOLDER));

  const { fetchForgeFolders } = getForgeSelectEventHandlers({ connectionId, hub, project });

  const fetchMoreFoldersHandler = async ({ isRoot = false, folderId, skip, onCompleted }) => {
    if (!isRoot) {
      const autodeskForgeSubItems = await fetchForgeFolders({ folderId, skip });
      onCompleted(autodeskForgeSubItems);
      return autodeskForgeSubItems;
    }
    await fetchMoreRootFolders(skip, { connectionId, hubId: hub?.id, projectId: project?.id, isPublishModel: false });
    return onCompleted(getLatestFolders(folders));
  };

  const onFolderExpandHandler = async ({ folderId, onCompleted, skip, take }) => {
    const autodeskForgeSubItems = await fetchForgeFolders({ folderId, skip, take });
    return onCompleted(autodeskForgeSubItems);
  };

  const onFolderSelectHandler = ({ onCompleted }) => onCompleted();

  const onCreateFolderHandler = async ({ folderId, folderName, onCompleted }) => {
    await handleResponse(
      makeForgeFolder,
      {
        variables: {
          query: { connectionId },
          body: { ProjectId: project?.id, ParentFolderId: folderId, Name: folderName }
        }
      },
      { successMessage: 'Created folder successfully' },
      async () => {
        const autodeskForgeSubItems = await fetchForgeFolders({
          folderId,
          fetchPolicy: NETWORK_ONLY_FETCH_POLICY,
          skip: null,
          take: null
        });
        deleteCache(FETCH_FOLDERS_API);
        onCompleted(autodeskForgeSubItems);
      }
    );
    onCompleted();
  };

  const onFolderDropdownClick = async () => {
    if (!folder?.id || isInitialStackFetched) return;

    await loadForgeFolderStack({
      variables: {
        query: {
          connectionId,
          hubId: hub?.id,
          projectId: project?.id,
          selectedFolderId: folder?.id,
          isPublishModel: false
        }
      },
      onCompleted: async (response) => {
        const { getAutodeskForgeSelectedSubItemIdStack } = response;
        let expandedFolderIds = [...(getAutodeskForgeSelectedSubItemIdStack || [])]?.reverse();
        expandedFolderIds = [...new Set(expandedFolderIds)];
        const closestParentFolderId = expandedFolderIds[expandedFolderIds.length - 2] || '';
        const parentId = expandedFolderIds.length > 1 ? closestParentFolderId : '';
        await setFormValues({
          uiFolders: uiFolders?.map((item) => ({ ...item, parentId })),
          expandedFolderIds
        });
        onFolderStackLoad?.(closestParentFolderId);
        setIsInitialStackFetched(true);
      }
    });
  };

  return (
    <FoldersSelectDropdown
      {...props}
      isLoading={isLoadingFolders}
      disabled={isLoadingFolders || disabled}
      isLoadingFolderStack={isLoadingForgeFolderStack}
      isInitialStackFetched={isInitialStackFetched}
      onFolderExpandHandler={onFolderExpandHandler}
      onFolderSelectHandler={onFolderSelectHandler}
      onCreateFolderHandler={onCreateFolderHandler}
      onFolderDropdownClick={onFolderDropdownClick}
      fetchMoreFoldersHandler={fetchMoreFoldersHandler}
    />
  );
};

export default ForgeFoldersDropdown;
