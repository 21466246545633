import React from 'react';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import { ENABLE_DATA_USAGE_REPORTS } from 'modules/Sync/Constants/constants';
import useEvictApolloCache from 'modules/Sync/hooks/useEvictApolloCache';
import ActivateWebhookModal from 'modules/Sync/Webhooks/components/ActivateWebhookModal';
import DeactivateWebhookModal from 'modules/Sync/Webhooks/components/DeactivateWebhookModal';
import DeleteWebhookModal from 'modules/Sync/Webhooks/components/DeleteWebhookModal';
import { WEBHOOKS_APIS, useSyncWebhooksContext } from 'modules/Sync/Webhooks/Contexts/WebhooksContext';
import DataUsageDataGridTable from 'modules/Sync/Webhooks/DataUsageDataGridTable/DataUsageDataGridTable';
import WebhooksDataGridTable from 'modules/Sync/Webhooks/WebhooksDataGridTable/WebhooksDataGridTable';
import WebhooksHeader from 'modules/Sync/Webhooks/WebhooksHeader/WebhooksHeader';

const Webhooks = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeRows, setActiveRows] = React.useState([]);
  const { setActiveModal } = useSyncWebhooksContext();

  const hideAllModals = () => {
    setActiveModal();
  };

  useEvictApolloCache(WEBHOOKS_APIS, []);

  return (
    <>
      <ComponentPaneLayout>
        <ItemsDataGridProProvider contextId="WebhooksTable">
          <WebhooksHeader activeTab={activeTab} setActiveTab={setActiveTab} />
          {activeTab === 0 && <WebhooksDataGridTable activeRows={activeRows} setActiveRows={setActiveRows} />}
          {activeTab === 1 && ENABLE_DATA_USAGE_REPORTS && <DataUsageDataGridTable activeRows={activeRows} />}
        </ItemsDataGridProProvider>
      </ComponentPaneLayout>
      <DeleteWebhookModal onClose={hideAllModals} />
      <ActivateWebhookModal onClose={hideAllModals} />
      <DeactivateWebhookModal onClose={hideAllModals} />
    </>
  );
};
export default Webhooks;
