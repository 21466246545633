import { useEffect } from 'react';

import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

export const useSharepointMountHook = ({
  project,
  connectionId,
  connectionType,
  nodeId,
  SharepointSites,
  lazyLoadSites,
  updateFormValues,
  fetchOnMountSharepointFolders,
  siteId,
  loadFolders
}) => {
  const fetchProjectsOnLoad = () => {
    if (project?.id) lazyLoadSites({ skip: null, take: null, connectionId });
    if (!project?.id) lazyLoadSites({ skip: SharepointSites?.length, connectionId });
  };

  useEffect(() => {
    updateFormValues({ connectionId, connectionType, nodeId });
    fetchProjectsOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchOnMountSharepointFolders) {
      loadFolders({
        connectionId,
        siteId,
        skip: 0,
        take: DROPDOWN_DEFAULT_PG_SIZE
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnMountSharepointFolders]);
};
