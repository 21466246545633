export const convertBytes = (sizeInBytes = 0) => {
  const sizeInKB = sizeInBytes / 1024;
  const sizeInMB = sizeInKB / 1024;
  const sizeInGB = sizeInMB / 1024;

  if (sizeInKB < 1) return `${sizeInBytes} B`;
  if (sizeInKB < 1000) return `${sizeInKB.toFixed(2)} KB`;
  if (sizeInMB < 1000) return `${sizeInMB.toFixed(2)} MB`;
  return `${sizeInGB.toFixed(2)} GB`;
};
