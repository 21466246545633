import { gql, useMutation } from '@apollo/client';

import { syncDeleteMappingFlow as SYNC_DELETE_MAPPING_FLOW } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useDeleteFlowDataApiAction = (queryOptions) => {
  const [deleteMappingFlow, { loading }] = useMutation(gql(SYNC_DELETE_MAPPING_FLOW), queryOptions);
  const { handleResponse } = useGraphqlResponseHandler();

  const deleteFlowData = ({
    mappingId,
    flowId,
    onCompleted,
    successMessage = 'Action node deleted successfully'
  }) =>
    handleResponse(
      deleteMappingFlow,
      { variables: { body: { MappingId: mappingId, SolitaryFlowId: flowId } } },
      { successMessage },
      onCompleted
    );

  return { deleteFlowData, loading };
};

export default useDeleteFlowDataApiAction;
