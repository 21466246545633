import { useCallback, useEffect } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Divider, FormControl, InputLabel, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import InputSelectField from 'components/FormComponents/InputSelectField';
import { syncCreateMapping as SYNC_CREATE_MAPPING } from 'graphql/mutations';
import { syncValidateName as SYNC_VALIDATE_NAME } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { ROUTES, VALIDATION_ENTITY, WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import useTemplatesLazyPaginationHook from 'modules/Sync/Workflows/Contexts/useTemplatesLazyPaginationHook';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';

import MappingTextField from './MappingTextField';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';
import WorkflowTextField from './WorkflowTextField';

const { SYNC, MAPPING_EDITOR } = ROUTES;

const defaultFormValues = { workflowName: '', mappingName: '', templateId: '' };

const SUCCESS_MESSAGE = 'Mapping successfully created';

// eslint-disable-next-line max-lines-per-function
const CreateMappingModal = ({ onClose }) => {
  const { currentWorkflow, activeModal } = useSyncWorkflowsContext();
  const { workflowName, workflowId } = currentWorkflow || {};
  const navigate = useNavigate();
  const open = activeModal === WORKFLOW_MODAL_KEYS.CREATE_MAPPING;
  const { handleResponse } = useGraphqlResponseHandler();

  const methods = useForm({ mode: 'all', defaultValues: { ...defaultFormValues, workflowName } });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    setError,
    clearErrors,
    reset
  } = methods;
  useEffect(() => {
    if (workflowName) {
      setValue('workflowName', workflowName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowName]);

  const { allTemplatesList, loadingAllTemplates } = useTemplatesLazyPaginationHook();

  const [createMapping, { loading }] = useMutation(gql(SYNC_CREATE_MAPPING), {
    refetchQueries: ['SyncWorkflows']
  });

  const [validateName, { errors: validationErrors }] = useLazyQuery(gql(SYNC_VALIDATE_NAME), {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: {
      params: { entity: VALIDATION_ENTITY.MAPPING }
    }
  });

  const redirectToWorkflowCanvas = (id) => (id ? navigate(`${SYNC}${MAPPING_EDITOR.replace(':id', id)}`) : '');

  const onModalClose = () => {
    onClose?.();
    reset(defaultFormValues);
  };

  const onSubmit = handleSubmit((formValues) => {
    const { mappingName, templateId: TemplateMappingId } = formValues;
    const body = {
      WorkflowId: workflowId,
      MappingName: mappingName,
      ...(TemplateMappingId ? { TemplateMappingId } : {})
    };

    return handleResponse(createMapping, { variables: { body } }, { successMessage: SUCCESS_MESSAGE }, (response) => {
      const { syncCreateMapping: { workflowMapping: { workflowMappingId } = {} } = {} } = response || {};
      redirectToWorkflowCanvas(workflowMappingId);
    });
  });

  const handleChange = useCallback(
    (searchName) => {
      validateName({
        variables: {
          query: {
            workflowId,
            name: encodeURIComponent(searchName.trim())
          }
        }
      });
    },
    [validateName, workflowId]
  );

  return (
    <form>
      <CustomModal
        disableModalOutsideClick
        open={open}
        title={<ModalTitle title="Create mapping" />}
        onClose={onModalClose}
        sx={{ '& .MuiPaper-root': { width: '475px' } }}
        footer={
          <ModalFooter loading={loading} onModalClose={onModalClose} onSubmit={onSubmit} onSubmitDisabled={!isValid} />
        }
      >
        <Stack gap={1}>
          <FormControl size="small" fullWidth>
            <WorkflowTextField
              control={control}
              disabled={!!workflowId || loading}
              sx={{ marginBottom: 0, marginTop: 0 }}
              errors={errors}
            />

            <MappingTextField
              disabled={loading}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              validateChange={handleChange}
              validationErrors={validationErrors}
            />
          </FormControl>

          <Divider />
          <InputLabel id="tempate-section">Optional: Select an existing template</InputLabel>
          <FormControl size="small" fullWidth>
            <InputSelectField
              name="templateId"
              control={control}
              color="secondary"
              size="small"
              fullWidth
              variant="outlined"
              label="Template"
              options={[
                { label: 'None', value: '' },
                ...allTemplatesList?.map((option) => ({
                  ...option,
                  label: option.templateName,
                  value: option.templateId
                }))
              ]}
              selectProps={{
                SelectProps: {
                  loading: loadingAllTemplates,
                  displayEmpty: true
                }
              }}
            />
          </FormControl>
        </Stack>
      </CustomModal>
    </form>
  );
};

export default CreateMappingModal;
