import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';

const useLoadAction = () => {
  const { workflowElements, setReactFlowInstance, setIsMappingDataSaved } =
    useSyncWorkflowEditorContext();
  const loadAction = (_reactFlowInstance) => {
    setIsMappingDataSaved(true);
    setReactFlowInstance(_reactFlowInstance);
    if (workflowElements?.getSyncWorkflowData) {
      const workflowData = JSON.parse(workflowElements?.getSyncWorkflowData);
      const [x = 0, y = 0] = workflowData.position;
      _reactFlowInstance.setTransform({ x, y, zoom: workflowData.zoom || 0 });
    }
  };

  return { loadAction };
};

export default useLoadAction;
