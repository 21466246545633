import React from 'react';

import Typography from '@mui/material/Typography';

import CustomModal from 'modules/Sync/components/CustomModal';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import useSyncMappingAction from 'modules/Sync/WorkflowEditor/editorHooks/useSyncMappingAction';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';
import { syncColors } from 'styles/theme/colors';

const GlobalSyncModal = ({ onClose, onSubmit }) => {
  const { isOpenGlobalSyncModal, isSyncRunning } = useSyncWorkflowEditorContext();
  const { stopMappingAction } = useSyncMappingAction();

  const onModalSubmit = () => {
    if (isSyncRunning) {
      stopMappingAction();
    }
    return onSubmit();
  };

  return (
    <CustomModal
      disableModalOutsideClick
      open={isOpenGlobalSyncModal}
      onClose={onClose}
      title={isSyncRunning ? 'Stop sync' : 'Confirm sync'}
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      footer={
        <ModalFooter
          onModalClose={onClose}
          cancelBtnText="Cancel"
          createBtnText="Confirm"
          onSubmit={onModalSubmit}
          createBtnSx={{
            backgroundColor: isSyncRunning ? syncColors.statusColors.failed : syncColors.main,
            '&:hover': {
              background: isSyncRunning
                ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${syncColors.statusColors.failed}`
                : syncColors.containedHoverBackgroundLight
            }
          }}
          isDelete={isSyncRunning}
        />
      }
    >
      <Typography variant="body1" mb="12px" sx={{ width: '505px', mb: '20px' }}>
        {isSyncRunning
          ? 'Are you sure you want to stop files from syncing?'
          : 'Are you sure you want to sync files between all the source & destination flow(s)?'}
      </Typography>
    </CustomModal>
  );
};

export default GlobalSyncModal;
