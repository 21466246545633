import React from 'react';

import { Custom } from 'components/Icons';
import OutlinedButton from 'modules/Sync/components/OutlinedButton';
import { WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import { syncColors } from 'styles/theme/colors';

const { CREATE_TEMPLATE } = WORKFLOW_MODAL_KEYS;

const NewTemplateButton = () => {
  const { setActiveModal } = useSyncWorkflowsContext();
  const showCreateTemplateModal = () => setActiveModal(CREATE_TEMPLATE);

  return (
    <OutlinedButton
      onClick={showCreateTemplateModal}
      sx={{ width: 171 }}
      icon={<Custom.Add style={{ fill: syncColors.text, marginRight: 10, height: 12, width: 12 }} />}
      label="New Template"
      labelId="AddItemAction"
    />
  );
};

export default NewTemplateButton;
