import React from 'react';

import { Custom } from 'components/Icons';
import { CONNECTION_TYPES } from 'modules/Sync/Constants/constants';

export const IconType = {
  ACTION: 'ACTION',
  COPY_FILES: 'COPY_FILES',
  MANAGE_ISSUES: 'MANAGE_ISSUES',
  DOWNLOAD_ISSUES: 'DOWNLOAD_ISSUES',
  UPLOAD_ISSUES: 'UPLOAD_ISSUES',
  PUBLISH: 'PUBLISH',
  CONNECTION: 'CONNECTION',
  CONNECTIONS: 'CONNECTIONS',
  TEMPLATE: 'TEMPLATE',
  SETTINGS: 'SETTINGS'
};

export const getSyncIcon = (icon) =>
  ({
    [IconType.ACTION]: <Custom.Action />,
    [IconType.COPY_FILES]: <Custom.CopyFiles />,
    [IconType.MANAGE_ISSUES]: <Custom.ManageIssues />,
    [IconType.DOWNLOAD_ISSUES]: <Custom.DownloadIssues />,
    [IconType.UPLOAD_ISSUES]: <Custom.UploadIssues />,
    [IconType.PUBLISH]: <Custom.Publish />,
    [IconType.CONNECTION]: <Custom.Connection />,
    [IconType.CONNECTIONS]: <Custom.CONNECTIONS />,
    [IconType.TEMPLATE]: <Custom.Template />,
    [IconType.SETTINGS]: <Custom.Settings />
  }[icon]);

export const getConnectorIcon = (type) =>
  ({
    [CONNECTION_TYPES.PROCORE]: <Custom.ProcoreIcon />,
    [CONNECTION_TYPES.AUTODESK]: <Custom.AutodeskIcon />,
    [CONNECTION_TYPES.AUTODESKFIELD]: <Custom.AutodeskIcon />,
    [CONNECTION_TYPES.AUTODESKGLUE]: <Custom.AutodeskIcon />,
    [CONNECTION_TYPES.AUTODESKHQ]: <Custom.AutodeskIcon />,
    [CONNECTION_TYPES.AUTODESKTEAMDOCS]: <Custom.AutodeskIcon />,
    [CONNECTION_TYPES.SHAREPOINT]: <Custom.SharepointIcon />,
    [CONNECTION_TYPES.PLANGRID]: <Custom.PlangridIcon />,
    [CONNECTION_TYPES.NEWFORMA]: <Custom.NewformaIcon />,
    [CONNECTION_TYPES.FORESITE]: <Custom.EvolveForesiteIcon />,
    [CONNECTION_TYPES.WINDOWS]: <Custom.WindowsIcon />
  }[type]);
