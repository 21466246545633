import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { CONNECTION_TYPES } from 'modules/Sync/Constants/constants';

import { triggerAuthentication } from '../authenticationUtils';

const usePlangridMutation = () => {
  const { connectorsList } = useSyncConnectionsContext();

  const authenticateCredentials = async ({ formData, onError }) => {
    let url = connectorsList?.syncConnectorsList?.find(
      (x) => x?.name === CONNECTION_TYPES.PLANGRID
    )?.url;
    if (formData?.configuration?.useCustomApplicationCredentials) {
      const formatURL = new URL(url);
      const params = formatURL.searchParams;
      params.set('client_id', formData?.configuration?.clientId);
      params.set('redirect_uri', formData?.configuration?.callbackUrl);
      formatURL.search = params.toString();
      url = formatURL.toString();
    } else if (!url) {
      onError('Callback URL is not configured on this Source');
      return 'error';
    }
    const code = await triggerAuthentication({
      url
    });
    if (!code) {
      onError('Failed to fetch Authorization Code. Please try again');
      return 'error';
    }
    return code;
  };

  const generateAuthCode = async ({ formData, onError }) => {
    let code = null;
    code = await authenticateCredentials({ formData, onError });
    if (code) {
      return code;
    }
    return '';
  };

  return { generateAuthCode };
};

export default usePlangridMutation;
