import { MAPPING_ACTION_TYPES } from 'modules/Sync/Constants/constants';
import { parseRequestScheduleSyncJson } from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';

const getIdAndName = (item) => {
  const { id = '', name = '' } = item || {};
  return { id: id ?? '', name: name ?? '' };
};

export const getAdditionalData = (additionalData = '') =>
  typeof additionalData === 'string'
    ? additionalData?.split(';')?.reduce((acc, el) => {
        // eslint-disable-next-line prefer-const
        let [key, value] = el.split('=');
        const convertToNum = key === 'downloadBehavior' || key === 'uploadBehavior' || key === 'connectionType';

        if (convertToNum) {
          value = Number(value);
        }
        if (key === 'setFilePermissionsAsAdmin') {
          value = Boolean(value);
        }
        acc[key] = value;
        return acc;
      }, {})
    : additionalData;

export const setAdditionalData = ({
  folderType = 'document',
  downloadBehavior = 0,
  uploadBehavior = 0,
  setFilePermissionsAsAdmin = false,
  connectionType = 0
}) =>
  `folderType=${folderType};downloadBehavior=${downloadBehavior};uploadBehavior=${uploadBehavior};setFilePermissionsAsAdmin=${setFilePermissionsAsAdmin};connectionType=${connectionType}`;

export const copyFilesActionPayload = ({
  isSyncEnabled = true,
  syncType,
  scheduleSyncConfig,
  actionNodeId = '',
  actionAlias = '',
  solitaryFlowId = '',
  source = '',
  destinations = [],
  includeExtensions = [],
  isSyncAllRevisions = true,
  secondaryFilesSetting = null
}) => ({
  solitaryFlowId,
  actionNodeId,
  actionAlias,
  action: 'Copy Files',
  source: {
    nodeId: source.nodeId,
    hub: getIdAndName(source?.hub),
    projects: [getIdAndName(source?.project)],
    folders: [getIdAndName(source?.folder)],
    additionalData: setAdditionalData({
      folderType: source?.folderType || 'document',
      downloadBehavior: source?.downloadBehavior || 0
    }),
    folderType: source?.folderType || '',
    ...source
  },
  destinations: destinations.map((destination) => ({
    nodeId: destination.nodeId,
    hub: getIdAndName(destination?.hub),
    projects: [getIdAndName(destination?.project)],
    folders: [getIdAndName(destination?.folder)],
    additionalData: setAdditionalData({
      folderType: destination?.folderType || 'document',
      uploadBehavior: source?.uploadBehavior || 0
    }),
    folderType: destination?.folderType || '',
    ...destination
  })),
  settings: {
    isSyncAllRevisions,
    includeExtensions: JSON.stringify(includeExtensions),
    secondaryFilesSetting,
    isSyncEnabled,
    syncType,
    scheduleSyncConfig
  }
});

export const copyFilesMappingConfigPayload = (mappings) =>
  mappings.map(({ solitaryFlowId = '', action, destinations, source, settings, actionNodeId, actionAlias }) => {
    const {
      isSyncAllRevisions,
      includeExtensions,
      secondaryFilesSetting,
      isSyncEnabled,
      syncType,
      scheduleSyncConfig
    } = settings || source?.settings;
    return {
      ...(solitaryFlowId ? { solitaryFlowId } : {}),
      action,
      actionNodeId,
      actionAlias,
      actionType: MAPPING_ACTION_TYPES.copyFiles,
      source: {
        ...(source?.syncDetailsId ? { syncDetailsId: source?.syncDetailsId } : {}),
        connectionId: source.connectionId || source?.connection?.id,
        nodeId: source.nodeId,
        hub: getIdAndName(source?.hub),
        projects: source?.projects?.map((project) => ({
          ...getIdAndName(project),
          folders: (source?.folders || project?.folders)?.map((folder) => getIdAndName(folder))
        })),
        additionalData: setAdditionalData({
          folderType: source?.folderType || 'document',
          downloadBehavior: source?.downloadBehavior || 0
        }),
        settings: {
          isSyncAllRevisions,
          includeExtensions,
          secondaryFilesSetting,
          isSyncEnabled,
          syncType,
          scheduleSyncConfig: parseRequestScheduleSyncJson(scheduleSyncConfig)
        }
      },
      destinations: destinations.map(
        ({
          syncDetailsId,
          connectionId,
          connection = {},
          nodeId,
          hub,
          project,
          projects,
          folder,
          folderType = 'document',
          uploadBehavior = 0
        }) => {
          const projectItem = project ?? projects?.[0];
          return {
            ...(syncDetailsId ? { syncDetailsId } : {}),
            connectionId: connectionId || connection?.id,
            nodeId,
            hub: getIdAndName(hub),
            project: {
              ...getIdAndName(projectItem),
              folder: getIdAndName(folder || project?.folder)
            },
            additionalData: setAdditionalData({
              folderType,
              uploadBehavior
            })
          };
        }
      )
    };
  });

export const saveSyncConfigPayload = (mappings) =>
  mappings.map(({ solitaryFlowId = '', isSyncEnabled = false, syncType = '', scheduleSyncConfig = '' }) => ({
    solitaryFlowId,
    isSyncEnabled,
    syncType,
    scheduleSyncConfig: parseRequestScheduleSyncJson(scheduleSyncConfig)
  }));
