import React, { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { syncValidateName as SYNC_VALIDATE_NAME } from 'graphql/queries';
import { VALIDATION_ENTITY } from 'modules/Sync/Constants/constants';

import { ControlTextField } from '../ConnectionProperties/FormFields';

const ConnectionName = ({
  connectionId,
  isDataSourceValid,
  control,
  errors,
  setError,
  clearErrors,
  defaultValue
}) => {
  const [validateName, { errors: validationErrors }] = useLazyQuery(gql(SYNC_VALIDATE_NAME), {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: {
      params: { entity: VALIDATION_ENTITY.CONNECTION }
    }
  });

  const handleChange = useCallback(
    (searchName) => {
      validateName({
        variables: {
          query: {
            name: encodeURIComponent(searchName.trim())
          }
        }
      });
    },
    [validateName]
  );

  return (
    <ControlTextField
      key={connectionId}
      autoFocus={isDataSourceValid}
      control={control}
      errors={errors}
      name="connectionName"
      label="Name*"
      maxLength={32}
      requiredNoSpace
      errorOnFocusOnly
      setError={setError}
      clearErrors={clearErrors}
      validateChange={handleChange}
      validationErrors={validationErrors}
      defaultValue={defaultValue}
    />
  );
};

export default ConnectionName;
