import React from 'react';

import { Typography } from '@mui/material';

import CustomTooltip from 'components/Tooltip';

import { NavItemTooltipProps } from './@types/Tooltip';

const NavItemTooltip = ({ children, title, placement = 'left', sx }: NavItemTooltipProps) => (
  <CustomTooltip
    title={
      title && (
        <Typography fontWeight="500" fontSize={10} lineHeight="16px" sx={{ ...sx }}>
          {title}
        </Typography>
      )
    }
    arrow
    placement={placement}
  >
    {children}
  </CustomTooltip>
);

export default NavItemTooltip;
