// This file and all comments in it are subject to future change

// ------------------------------
// ---  CROSS MODULE COLORS------
// ------------------------------

// these are the cross module colors that will be used by all modules.
// Some of these will override material ui defaults (like Error/success/etc).

export const primaryColors = {
  main: 'rgba(55, 55, 55, 1)',
  light: 'rgba(187, 187, 187, 1)',
  containedHoverBackground: 'rgba(146, 146, 146, 1)',
  outlinedHoverBackground: 'rgba(166, 166, 166, 0.08)',
  outlinedRestingBorder: 'rgba(169, 169, 169, 0.5)'
};

export const surfaceColors = {
  lightSurface: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.5)',
    disabled: 'rgba(0, 0, 0, 0.25)'
  },
  darkSurface: {
    primary: 'rgba(255, 255, 255, 1)',
    primaryOpacity70: 'rgba(255, 255, 255, 0.7)',
    primaryOpacity60: 'rgba(255, 255, 255, 0.6)',
    primaryOpacity20: 'rgba(255, 255, 255, 0.2)',
    primaryOpacity10: 'rgba(255, 255, 255, 0.1)',
    secondary: 'rgba(204,204,204)',
    disabled: 'rgba(128,128,128)'
  }
};

export const actionColors = {
  disabled: '#9E9E9E',
  lightSurface: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.03)',
    selected: 'rgba(0, 0, 0, 0.06)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    focus: 'rgba(0, 0, 0, 0.12)'
  },
  darkSurface: {
    active: 'rgba(255, 255, 255, 0.2)',
    hover: 'rgba(255, 255, 255, 0.1)',
    selected: 'rgba(255, 255, 255, 0.2)',
    disabled: 'rgba(255, 255, 255, 0.26)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    focus: 'rgba(255, 255, 255, 0.12)'
  }
};

export const rightDrawerOverlayColors = {
  background: 'rgba(48, 48, 48, 0.95)',
  action: 'rgba(255, 255, 255, 0.7)'
};

export const errorColors = {
  main: 'rgba(244, 67, 54, 1)',
  dark: 'rgba(227, 27, 12, 1)',
  light: 'rgba(248, 128, 120, 1)',
  containedHoverBackground: 'rgba(171, 47, 38)', // this value was directly taken from figma
  outlinedHoverBackground: 'rgba(244, 67, 54, 0.08)',
  outlinedRestingBorder: 'rgba(244, 67, 54, 0.5)',
  alertContent: 'rgba(244, 67, 54, 0.6)',
  alertBackground: 'rgba(253, 236, 234, 1)'
};

export const statusColors = {
  active: 'rgba(54, 186, 170, 1)',
  pending: 'rgba(216, 159, 48, 1)',
  deleted: 'rgba(206, 45, 45, 1)',
  expired: 'rgba(242, 129, 46, 1)',
  accepted: '#36BAAA',
  rejected: '#CE2D2D',
  canceled: '#CE2D2D',
  submitted: '#37A54F',
  approved: '#36BAAA',
  draft: 'rgba(0, 0, 0, 0.5)'
};

export const kanbanColors = {
  draft: '#19749B',
  notStarted: '#FFA800',
  started: '#4188FF',
  inProgress: '#4188FF',
  completed: '#18AE39',
  border: '#e4e4e4',
  blocked: '#ED5500'
};

export const successColors = {
  main: 'rgba(76, 175, 80, 1)',
  dark: 'rgba(59, 135, 62, 1)',
  light: 'rgba(123, 198, 126, 1)',
  containedHoverBackground: 'rgba(76, 175, 80, 0.3)',
  outlinedHoverBackground: 'rgba(76, 175, 80, 0.5)',
  outlinedRestingBorder: 'rgba(76, 175, 80, 0.08)',
  alertContent: 'rgba(76, 175, 80, 0.6)',
  alertBackground: 'rgba(76, 175, 80, 0.6)'
};

export const otherColors = {
  divider: {
    lightSurface: 'rgba(0, 0, 0, 0.12)',
    darkSurface: 'rgba(255, 255, 255, 0.12)'
  },
  outlineBorder: 'rgba(255, 255, 255, 1)',
  standardInputLine: '#0000006B',
  backdropOverlay: 'rgba(0, 0, 0, 0.5)',
  ratingActive: 'rgba(255, 180, 0, 1)',
  links: 'rgba(74, 147, 255, 1)',
  snackbarBackground: 'rgba(50, 50, 50, 1)',
  navbarBackground: 'rgba(48, 48, 48, 1)',
  black: 'rgba(0, 0, 0, 1)',
  white: 'rgba(255, 255, 255, 1)',
  border: '#CCCCCC',
  selectedDraggable: '#F0F0F0',
  selected: '#E0E0E0',
  dragWrapper: 'rgba(48, 48, 48, 0.95)',
  inputBackground: '#616161',
  greyIcon: '#0000008A',
  greyOutlineColor: '#0000001F'
};

export const receivingStatusColors = {
  complete: 'rgba(24, 174, 57, 1)',
  submittedForApproval: 'rgba(60, 139, 59, 1)',
  inProcess: 'rgba(38, 177, 161, 1)',
  notReceived: 'rgba(54, 83, 186, 1)',
  partiallyReceived: 'rgba(54, 139, 186, 1)',
  outForQuotation: 'rgba(201, 194, 38, 1)',
  submittedToPurchasing: 'rgba(216, 159, 48, 1)',
  purchasingReview: 'rgba(176, 120, 11, 1)',
  rejected: 'rgba(211, 85, 85, 1)',
  recalled: 'rgba(158, 26, 26, 1)',
  cancelled: 'rgba(206, 45, 45, 1)'
};

// EvolveBrandColors - It is the main colors for the evolve website itself,
// such as login, landing page,etc. This is the default secondary color for the theme when no module is selected
// This is also listed as it's own color object inside of pallete 'evolve' just in case we need it.
export const evolveBrandColors = {
  main: 'rgba(16, 20, 61, 1)',
  light: 'rgba(97, 105, 185, 1)',
  dark: 'rgba(16, 20, 61, 1)',
  contrastText: 'rgb(255,255,255)',
  containedHoverBackground: 'rgba(67, 74, 144, 1)',
  outlinedHoverBackground: 'rgba(63, 81, 181, 0.08)',
  outlinedRestingBorder: 'rgba(63, 81, 181, 0.5)'
};

// ------------------------------
// ---INDIVIDUAL MODULE COLORS---
// ------------------------------

// NOTE: The below comment rings true, but for now these colors are the primary colors. The change to secondary colors needs to be made shortly
// these are the colors for each module. In Material Component Use this will be the "secondary" color for the theme.
// The secondary color will change based on the current Selected Module to reflect the colors for the module below.
// see theme.ts for example

export const adminColors = {
  main: 'rgba(21, 170, 152, 1)',
  light: 'rgba(216, 253, 248, 1)',
  dark: 'rgba(41, 157, 143, 1)',
  contrastText: 'rgb(255,255,255)',
  tableActive: 'rgba(54, 186, 170, 0.2)',
  tableHover: 'rgba(54, 186, 170, 0.08)',
  buttonHover: 'rgba(21, 170, 152, 0.7)',
  containedHoverBackground: 'rgba(38, 131, 119, 1)',
  outlinedHoverBackground: 'rgba(54, 186, 170, 0.08)',
  outlinedRestingBorder: 'rgba(54, 186, 170, 0.5)'
};

export const dataAnalyticsColors = {
  main: 'rgba(161, 74, 192, 1)',
  light: 'rgba(250, 236, 255, 1)',
  dark: 'rgba(69, 19, 86, 1)',
  contrastText: 'rgb(255,255,255)',
  tableActive: 'rgba(161, 74, 192, 0.3)',
  tableHover: 'rgba(161, 74, 192, 0.08)',
  containedHoverBackground: 'rgba(113, 52, 135, 1)',
  outlinedHoverBackground: 'rgba(161, 74, 192, 0.08)',
  outlinedRestingBorder: 'rgba(161, 74, 192, 0.5)'
};

export const electricalColors = {
  main: 'rgba(210, 78, 78, 1)',
  light: 'rgba(255, 227, 227, 1)',
  dark: 'rgba(132, 30, 30, 1)',
  contrastText: 'rgb(255,255,255)',
  tableActive: 'rgba(210, 78, 78, 0.3)',
  tableHover: 'rgba(210, 78, 78, 0.08)',
  containedHoverBackground: 'rgba(147, 55, 55, 1)',
  outlinedHoverBackground: 'rgba(210, 78, 78, 0.08)',
  outlinedRestingBorder: 'rgba(210, 78, 78, 0.5)'
};

export const managementColors = {
  main: 'rgba(21, 152, 181, 1)',
  light: 'rgba(201, 245, 255, 1)',
  dark: 'rgba(15, 132, 157, 1)',
  contrastText: 'rgb(255,255,255)',
  tableActive: 'rgba(53, 174, 201, 0.3)',
  tableHover: 'rgba(53, 174, 201, 0.08)',
  buttonHover: 'rgba(21, 152, 181, 0.7)',
  containedHoverBackground: 'rgba(37, 122, 141, 1)',
  outlinedHoverBackground: 'rgba(53, 174, 201, 0.08)',
  outlinedRestingBorder: 'rgba(53, 174, 201, 0.5)'
};

export const fieldColors = {
  main: 'rgba(55, 165, 79, 1)',
  light: 'rgba(215, 255, 223, 1)',
  dark: 'rgba(15, 80, 29, 1)',
  contrastText: 'rgb(255,255,255)',
  tableActive: 'rgba(55, 165, 79, 0.3)',
  tableHover: 'rgba(55, 165, 79, 0.08)',
  containedHoverBackground: 'rgba(39, 116, 55, 1)',
  outlinedHoverBackground: 'rgba(55, 165, 79, 0.08)',
  outlinedRestingBorder: 'rgba(55, 165, 79, 0.5)',
  mainDarkBackground: 'rgba(148, 238, 168, 1)',
  draft: 'rgba(25, 116, 155, 1)',
  approved: 'rgba(99, 173, 40, 1)'
};

export const materialsColors = {
  main: 'rgba(219, 105, 57, 1)',
  light: 'rgba(255, 232, 222, 1)',
  dark: 'rgba(164, 72, 33, 1)',
  contrastText: 'rgb(255,255,255)',
  tableActive: 'rgba(219, 105, 57, 0.3)',
  tableHover: 'rgba(219, 105, 57, 0.08)',
  containedHoverBackground: 'rgba(154, 74, 40, 1)',
  outlinedHoverBackground: 'rgba(219, 105, 57, 0.08)',
  outlinedRestingBorder: 'rgba(219, 105, 57, 0.5)',
  mainDarkBackground: 'rgba(233, 142, 103, 1)'
};

export const mechanicalColors = {
  main: 'rgba(39, 123, 180, 1)',
  light: 'rgba(212, 238, 255, 1)',
  dark: 'rgba(17, 66, 99, 1)',
  contrastText: 'rgb(255,255,255)',
  tableActive: 'rgba(39, 123, 180, 0.3)',
  tableHover: 'rgba(39, 123, 180, 0.08)',
  containedHoverBackground: 'rgba(27, 86, 126, 1)',
  outlinedHoverBackground: 'rgba(39, 123, 180, 0.08)',
  outlinedRestingBorder: 'rgba(39, 123, 180, 0.5)'
};

export const shopColors = {
  main: 'rgba(97, 63, 194, 1)',
  light: 'rgba(230, 222, 254, 1)',
  dark: 'rgba(49, 22, 126, 1)',
  draft: 'rgba(25, 116, 155, 1)',
  contrastText: 'rgb(255,255,255)',
  tableActive: 'rgba(97, 63, 194, 0.3)',
  tableHover: 'rgba(97, 63, 194, 0.08)',
  containedHoverBackground: 'rgba(68, 44, 136, 1)',
  outlinedHoverBackground: 'rgba(97, 63, 194, 0.08)',
  outlinedRestingBorder: 'rgba(97, 63, 194, 0.5)',
  mainDarkBackground: 'rgba(200, 182, 255, 1)'
};

export const greyColors = {
  grey50: 'rgba(250, 250, 250, 1)',
  grey100: 'rgba(245, 245, 245, 1)',
  grey200: 'rgba(238, 238, 238, 1)',
  grey300: 'rgba(224, 224, 224, 1)',
  grey400: 'rgba(189, 189, 189, 1)',
  grey500: 'rgba(158, 158, 158, 1)',
  grey550: 'rgba(127, 127, 127, 1)',
  grey600: 'rgba(117, 117, 117, 1)',
  grey700: 'rgba(97, 97, 97, 1)',
  grey800: 'rgba(79, 79, 79, 1)',
  grey900: 'rgba(66, 66, 66, 1)',
  grey1000: 'rgba(33, 33, 33, 1)'
};

export const syncColors = {
  main: 'rgba(255, 122, 0, 1)',
  light: 'rgba(255, 239, 224, 1)',
  dark: 'rgba(188, 90, 0, 1)',
  text: 'rgb(218, 112, 53, 1)',
  contrastText: 'rgb(255,255,255)',
  tableActive: 'rgba(255, 180, 111, 0.3)',
  tableHover: 'rgba(253, 121, 0, 0.08)',
  containedHoverBackground: 'rgba(117, 56, 0, 0.8)',
  containedHoverBackgroundLight: 'rgba(217, 104, 0, 1)',
  outlinedHoverBackground: 'rgba(253, 121, 0, 0.08)',
  outlinedRestingBorder: 'rgba(255, 122, 0, 0.5)',
  mainDarkBackground: 'rgba(255, 170, 91, 1)',
  primaryColors: {
    ...primaryColors,
    main: 'rgba(255, 122, 0, 1)'
  },
  secondary: {
    mainDarkBackground: 'rgba(255, 170, 91, 1)'
  },
  syncStatusColors: {
    inProgress: '#4188FF',
    pending: '#F3BB4D',
    notStarted: '#F3BB4D',
    success: 'rgba(24, 174, 57, 1)',
    failed: '#F44336',
    terminated: 'rgba(244, 67, 54, 1)',
    disabled: 'rgba(158, 158, 158, 1)',
    upToDate: '#8362AC',
    queued: '#10BBC6',
    completed: '#18AE39',
    cancelled: '#CE2D2D',
    needReview: '#FA6432',
    deactivated: 'rgba(158, 158, 158, 1)'
  },

  webhookStatusColors: {
    activate: '#F3BB4D',
    deActivate: 'rgba(189, 189, 189, 1)'
  },
  statusColors: {
    success: '#4CAF50',
    skipped: 'rgb(197 203 197)',
    filtered: 'rgb(136, 139, 136)',
    inProgress: '#4188FF',
    failed: '#F44336',
    disabled: '#E3E5E6',
    needsReview: '#FA6432',
    syncButtonDefault: '#373737',
    syncButtonDefaultHover: '#929292',
    syncButtonInProgress: '#FF7A00',
    syncButtonInPause: '#F44336'
  },
  canvasNavButtonColors: {
    breadcrumbPrimary: '#00000080',
    breadcrumbSecondary: '#000000DE',
    navButton: '#373737',
    navButtonHover: '#929292'
  },
  canvasNodeColors: {
    realTimeSync: '#009DB2',
    realTimeSyncBackground: '#E6FCFF',
    scheduleSync: '#5B0DFF',
    scheduleSyncBackground: '#EFE7FF',
    syncNow: '#1BDC74',
    syncNowBackground: '#EDFDF4',
    disabled: '#757575',
    disabledBackground: '#F5F5F5',
    default: '#FF7A00',
    defaultBackground: '#FFEFE0',
    background: '#FFF'
  },
  publishActionNodeColors: {
    syncNow: '#1BDC74',
    syncNowBackground: '#EDFDF4'
  },
  canvasEdgeColors: {
    realTimeSync: '#009DB2',
    scheduleSync: '#5B0DFF',
    syncNow: '#1BDC74',
    disabled: '#757575'
  },
  publishActionEdgeColors: {
    syncNow: '#1BDC74'
  },
  manageIssuesEdgeColors: {
    syncNow: '#009db2'
  },
  logsColors: {
    failedFileCount: 'rgba(244, 67, 54, 1)',
    dataUsageReportFlyout: '#FF7A00'
  }
};

export const designColors = {
  main: 'rgba(0, 145, 189, 1)',
  contrastText: 'rgb(255,255,255)',
  light: 'rgba(210, 245, 255, 1)',
  dark: 'rgba(3, 98, 128, 1)',
  draft: 'rgba(25, 116, 155, 1)',
  tableActive: 'rgba(0, 145, 189, 0.25)',
  tableHover: 'rgba(0, 145, 189, 0.16)',
  containedHoverBackground: 'rgba(1, 109, 143, 1)',
  outlinedHoverBackground: 'rgba(0, 145, 189, 0.08)',
  outlinedRestingBorder: 'rgba(0, 145, 189, 0.5)',
  mainDarkBackground: 'rgba(67, 210, 255, 1)'
};

export const excelColors = {
  header: '4f81bd',
  activeRow: 'b8cce4',
  alternateRow: 'dce6f1'
};
