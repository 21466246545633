import { useState } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import {
  getPlangridFolders as GET_PLANGRID_FOLDERS,
  getPlangridProjects as GET_PLANGRID_PROJECTS
} from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DROPDOWN_DEFAULT_PG_SIZE, MAX_DROPDOWN_ITEMS_IN_VIEW } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import FiltersAutocompleteDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FiltersAutocompleteDropdown';
import DropdownFormControl from 'modules/Sync/WorkflowEditor/Utils/DropdownFormControl';

import PlangridFoldersDropdown from './PlangridFoldersDropdown';
import { getPlangridSelectEventHandlers } from './plangridHelpers';
import { usePlangridMountHook } from './usePlangridMountHook';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_FIRST;
const Plangrid = ({
  fileTypeFilters = [],
  connectionId = '',
  connectionType = '',
  nodeId = '',
  showFilters = false,
  isSource = false
}) => {
  const { getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const [parentFolderId, setParentFolderId] = useState('');

  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { project, folders = [], uiFolders = [] } = formValues;

  const [
    { paginatedLazyLoad: lazyLoadProjects, paginationHandler: projectsPaginationHandler },
    { loading: isLoadingProjects, data: { getPlangridProjects: PlangridProjects = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_PLANGRID_PROJECTS), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const onScrollProjects = () => projectsPaginationHandler(PlangridProjects?.length, { connectionId });

  const fetchProjectsOnLoad = () => {
    if (project?.id) lazyLoadProjects({ skip: null, take: null, connectionId });
    if (!project?.id) lazyLoadProjects({ skip: PlangridProjects?.length, connectionId });
  };

  const [
    { lazyLoad: loadFolders, paginationHandler: fetchMoreRootFolders },
    { loading: isLoadingFolders, data: { getPlangridFolders: PlangridFolders = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_PLANGRID_FOLDERS), FETCH_POLICY, DROPDOWN_DEFAULT_PG_SIZE);

  const fetchOnMountPlangridFolders = project?.id && !isLoadingProjects && PlangridProjects?.length;

  const updateFormValues = (props) => updateConnectorNodeValues({ nodeId, isSource, props });

  const setParentFolderIdOnStackLoad = (parentFolderId) => setParentFolderId(parentFolderId);

  const { selectProjectHandler, selectFoldersHandler } = getPlangridSelectEventHandlers({
    updateFormValues,
    loadFolders,
    connectionId,
    folders,
    uiFolders,
    parentFolderId,
    setParentFolderId
  });

  usePlangridMountHook({
    connectionId,
    connectionType,
    nodeId,
    project,
    formValues,
    fetchOnMountPlangridFolders,
    updateFormValues,
    loadFolders,
    fetchProjectsOnLoad
  });

  return (
    <Stack spacing={2}>
      <DropdownFormControl
        title="Project"
        id="project"
        labelId="project"
        label="project"
        value={project?.id}
        onChange={selectProjectHandler}
        loading={isLoadingProjects}
        items={PlangridProjects}
        fetchPaginatedRecords={onScrollProjects}
        required
      />
      <PlangridFoldersDropdown
        folders={PlangridFolders}
        connectionId={connectionId}
        disabled={isLoadingProjects || !project?.id || !PlangridProjects?.length}
        onSelect={selectFoldersHandler}
        isLoadingFolders={isLoadingFolders}
        isSource={isSource}
        nodeId={nodeId}
        multiple={isSource}
        onFolderStackLoad={setParentFolderIdOnStackLoad}
        fetchMoreRootFolders={fetchMoreRootFolders}
        required
      />
      <FiltersAutocompleteDropdown showFilters={showFilters} fileTypeFilters={fileTypeFilters} isSource={isSource} />
    </Stack>
  );
};

export default Plangrid;
