import React, { useState } from 'react';

import { Box, IconButton } from '@mui/material';

import { Custom } from 'components/Icons';
import CustomMenu from 'components/Menu';

const DropDownMenuProperties = ({ renderMenuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        style={{ width: '40px', height: '40px' }}
        size="large"
      >
        <Custom.Menu />
      </IconButton>
      {renderMenuItems && (
        <CustomMenu
          id="positioned-menu"
          aria-labelledby="positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ marginLeft: 4 }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          MenuListProps={{ sx: { minWidth: '140px' } }}
        >
          {renderMenuItems()}
        </CustomMenu>
      )}
    </Box>
  );
};

export default DropDownMenuProperties;
