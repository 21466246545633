import { multiSelectFolders } from 'modules/Sync/WorkflowEditor/Forms/Shared/ManageIssuesComponents/FoldersSelectDropdown/folderTreeItemHelper';

export const getWindowsSelectEventHandlers = ({
  updateFormValues,
  folders,
  uiFolders,
  parentFolderId,
  setParentFolderId
}) => {
  const selectFolderHandler = (event, id, children) =>
    multiSelectFolders({
      event,
      id,
      folders,
      uiFolders,
      children,
      parentFolderId,
      setParentFolderId,
      updateFormValues
    });

  return { selectFolderHandler };
};
