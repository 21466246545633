import StatusBadge from 'components/StatusBadges/StatusBadge';
import { SYNC_LOGS } from 'modules/Sync/Constants/constants';
import { syncColors } from 'styles/theme/colors';

export const SyncStatusLabel = (syncStatus) =>
  ({
    [SYNC_LOGS.LOGS_SYNC_STATUS.IN_PROGRESS]: 'In progress',
    [SYNC_LOGS.LOGS_SYNC_STATUS.COMPLETED]: 'Completed',
    [SYNC_LOGS.LOGS_SYNC_STATUS.PENDING]: 'Pending',
    [SYNC_LOGS.LOGS_SYNC_STATUS.FAILED]: 'Failed',
    [SYNC_LOGS.LOGS_SYNC_STATUS.UP_TO_DATE]: 'Up to date',
    [SYNC_LOGS.LOGS_SYNC_STATUS.DISABLED]: 'Disabled',
    [SYNC_LOGS.LOGS_SYNC_STATUS.CANCELLED]: 'Canceled',
    [SYNC_LOGS.LOGS_SYNC_STATUS.IN_THE_QUEUE]: 'In the queue',
    [SYNC_LOGS.LOGS_SYNC_STATUS.NEED_REVIEW]: 'Needs review',
    [SYNC_LOGS.LOGS_SYNC_STATUS.DEACTIVATED]: 'Deactivated',
    [true]: 'In Progress',
    [false]: 'Not Started'
  }[syncStatus]);

export const SyncStatusBackground = (syncStatus) =>
  ({
    [SYNC_LOGS.LOGS_SYNC_STATUS.IN_PROGRESS]: syncColors.syncStatusColors.inProgress,
    [SYNC_LOGS.LOGS_SYNC_STATUS.COMPLETED]: syncColors.syncStatusColors.success,
    [SYNC_LOGS.LOGS_SYNC_STATUS.PENDING]: syncColors.syncStatusColors.pending,
    [SYNC_LOGS.LOGS_SYNC_STATUS.FAILED]: syncColors.syncStatusColors.failed,
    [SYNC_LOGS.LOGS_SYNC_STATUS.UP_TO_DATE]: syncColors.syncStatusColors.upToDate,
    [SYNC_LOGS.LOGS_SYNC_STATUS.DISABLED]: syncColors.syncStatusColors.disabled,
    [SYNC_LOGS.LOGS_SYNC_STATUS.CANCELLED]: syncColors.syncStatusColors.cancelled,
    [SYNC_LOGS.LOGS_SYNC_STATUS.IN_THE_QUEUE]: syncColors.syncStatusColors.queued,
    [SYNC_LOGS.LOGS_SYNC_STATUS.NEED_REVIEW]: syncColors.syncStatusColors.needReview,
    [SYNC_LOGS.LOGS_SYNC_STATUS.DEACTIVATED]: syncColors.syncStatusColors.disabled,
    [true]: syncColors.syncStatusColors.inProgress,
    [false]: syncColors.syncStatusColors.pending
  }[syncStatus]);

const SyncStatusHig = (syncStatus, sx = {}) => (
  <StatusBadge
    label={SyncStatusLabel(syncStatus)}
    sx={{ height: '20px', ml: 2, background: SyncStatusBackground(syncStatus), ...sx }}
  />
);

export default SyncStatusHig;
