import React, { useEffect, useState } from 'react';

import { useLazyQuery, gql } from '@apollo/client';
import Auth from '@aws-amplify/auth';

import { userByName } from 'graphql/queries';

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [getUserByName] = useLazyQuery(gql(userByName));
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [updateViewHandler, setUpdateViewHandler] = useState(null);

  const handleUpdateView = (params) => setUpdateViewHandler(params || Math.random());

  useEffect(() => {
    let active = true;

    const check = async () => {
      try {
        const amplifyRefreshedUser = await Auth.currentAuthenticatedUser();
        if (!amplifyRefreshedUser) return;

        const evolveBackendUser = await getUserByName({
          variables: { params: { userName: amplifyRefreshedUser.username } }
        });

        const res = evolveBackendUser.data.userByName;

        amplifyRefreshedUser.companyId = res.companyId;
        amplifyRefreshedUser.userId = res.userId;
        amplifyRefreshedUser.firstName = res.userFirstName;
        amplifyRefreshedUser.lastName = res.userLastName;
        amplifyRefreshedUser.personalPhoneNumber = res.personalPhoneNumber;
        amplifyRefreshedUser.workPhoneNumber = res.workPhoneNumber;
        amplifyRefreshedUser.isAdmin = res.isAdmin;
        amplifyRefreshedUser.userTypes = res.userTypes;
        amplifyRefreshedUser.userPhotoId = res.userPhotoId;

        if (active) setUser(amplifyRefreshedUser);
      } catch (error) {
        if (active) setUser(null);
      } finally {
        setLoading(false);
      }
    };

    check();

    return () => {
      active = false;
      setLoading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUser]);

  const userObj = React.useMemo(
    () => ({
      user,
      setUser,
      loading,
      handleUpdateView,
      updateViewHandler
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, setUser, loading, updateViewHandler]
  );

  return <UserContext.Provider value={userObj}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a AuthContext');
  }
  return context;
};

export { UserContext, UserProvider, useUser };
