import { Divider, ListItem, ListItemText } from '@mui/material';

import CustomTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { otherColors } from 'styles/theme/colors';

const TabListItem = ({ title, content }) => (
  <ListItem alignItems="flex-start" sx={{ margin: 0, padding: 0 }}>
    <ListItemText sx={{ margin: 0 }} primary={<ListItemPrimaryText title={title} content={content} />} />
  </ListItem>
);

const ListItemPrimaryText = ({ title, content }) => (
  <>
    <div
      style={{
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        columnGap: '2em',
        marginTop: 0.1,
        fontSize: 14
      }}
    >
      <TitleLayout tooltip={title}>{title}</TitleLayout>
      <ContentLayout tooltip={content}>{content}</ContentLayout>
    </div>
    <CustomStyledDivider />
  </>
);

const TitleLayout = ({ tooltip, children }) => (
  <CustomTooltip
    title={tooltip}
    sx={{
      width: '34%',
      maxWidth: '34%',
      textTransform: 'capitalize',
      color: otherColors.white
    }}
  >
    {children}
  </CustomTooltip>
);

const ContentLayout = ({ tooltip, children }) => (
  <CustomTooltip
    title={tooltip}
    sx={{
      width: '66%',
      maxWidth: '66%',
      textAlign: 'right',
      marginLeft: 'auto',
      color: otherColors.white
    }}
  >
    {children}
  </CustomTooltip>
);

const CustomStyledDivider = () => (
  <Divider
    sx={{
      borderTop: 'thin solid rgba(255, 255, 255, 0.3)',
      marginTop: 0.1,
      marginBottom: 0
    }}
  />
);

export default TabListItem;
