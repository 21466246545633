import React from 'react';

import { FormControl, InputLabel, MenuItem, Stack } from '@mui/material';
import Select from '@mui/material/Select';

import { Custom } from 'components/Icons';
import { getMicroFeContainer } from 'helpers/domHelpers';
import { usePublishActionFormContext } from 'modules/Sync/WorkflowEditor/Contexts/PublishActionContext';
import {
  formatMappings,
  getAvailableConnections
} from 'modules/Sync/WorkflowEditor/helpers/PublishActionHelpers/connectionsHelper';
import RefreshGqlCache from 'modules/Sync/WorkflowEditor/Utils/RefreshGqlCache';

const ConnectorForm = ({ isSource = false, defaultOption, connectorFormProps, label = '' }) => {
  const [selectedOption, setSelectedOption] = React.useState(defaultOption);
  const [connectorDropDownOptions, setConnectorDropDownOptions] = React.useState([]);
  const { source, setSource, connectorNodes, setConnectorNodes } = usePublishActionFormContext();
  const activeConnections = isSource && [source?.connectionType];

  const onConnectorChange = (nodeId) => isSource && setSource({ nodeId });

  React.useEffect(() => {
    const connections = getAvailableConnections(connectorNodes, isSource, selectedOption);
    setConnectorDropDownOptions(formatMappings(connections));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSource, connectorNodes]);

  const onChange = (event) => {
    const currentNodeId = event.target.value;
    const prevNodeId = selectedOption;
    setSelectedOption(currentNodeId);
    setConnectorNodes((nodes) => [
      ...nodes.map((node) => {
        const newNode = node;
        if (node.id === currentNodeId) newNode.data.isAvailable = false;
        if (node.id === prevNodeId) newNode.data.isAvailable = true;
        return newNode;
      })
    ]);
    return onConnectorChange(currentNodeId, prevNodeId);
  };

  return (
    <>
      <FormHeader label={label} activeConnections={activeConnections} isSource={isSource} />

      <FormControl size="small" fullWidth color="secondary">
        <InputLabel id="template">Connector*</InputLabel>
        <Select
          labelId="connector"
          id="connector"
          name="connector"
          value={selectedOption}
          label="connector*"
          onChange={onChange}
          MenuProps={{
            container: getMicroFeContainer
          }}
        >
          {connectorDropDownOptions.map(({ value, name }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {connectorDropDownOptions.find(({ value }) => selectedOption === value)?.componentToRender(connectorFormProps) ||
        ''}
    </>
  );
};

const FormHeader = ({ label, onDelete, activeConnections, isSource }) => (
  <>
    {label && (
      <Stack
        direction="row"
        component="span"
        sx={{
          justifyContent: 'end',
          marginLeft: 50,
          marginRight: 4,
          top: -38,
          position: 'relative'
        }}
      >
        <div>
          {onDelete && <Custom.DeleteOutlineActive onClick={onDelete} style={{ cursor: 'pointer', marginRight: 15 }} />}
        </div>
        <div>{activeConnections && !isSource && <RefreshGqlCache connections={activeConnections} />}</div>
      </Stack>
    )}
    {activeConnections && isSource && (
      <div
        style={{
          textAlign: 'right',
          position: 'relative',
          top: -44,
          marginTop: 1,
          marginBottom: -35
        }}
      >
        <RefreshGqlCache connections={activeConnections} />
      </div>
    )}
  </>
);
export default ConnectorForm;
