import React from 'react';

import { Breadcrumbs, Button, ButtonGroup, Stack, Typography, Link, CircularProgress } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Custom, IconStyled } from 'components/Icons';
import { ROUTES } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { actionColors, syncColors } from 'styles/theme/colors';

const TemplateEditorToolbar = ({ onSaveAction, onDeleteAction, onCreateWorkflowAction, workflowData = {} }) => {
  const { mapping: { mappingName } = {} } = workflowData;
  const { workflowElements } = useSyncWorkflowEditorContext();
  const disabledApiActions = !workflowElements.length;
  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color={syncColors.canvasNavButtonColors.breadcrumbPrimary}
      component={RouterLink}
      to={`${ROUTES.SYNC}${ROUTES.WORKFLOWS}?activeTab=1`}
    >
      Templates
    </Link>,
    <Typography key="2" color={syncColors.canvasNavButtonColors.breadcrumbSecondary}>
      {mappingName}
      {!mappingName && <CircularProgress color="secondary" size={10} />}
    </Typography>
  ];

  return (
    <Stack spacing={10} direction="row" justifyContent="space-between" style={{ position: 'relative', zIndex: 9 }}>
      <Breadcrumbs separator="/" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      <ButtonGroup
        color="secondary"
        sx={{ width: '25em', justifyContent: 'space-evenly' }}
        disabled={disabledApiActions}
      >
        {onDeleteAction && (
          <CustomButton
            onClick={onDeleteAction}
            disabled={disabledApiActions}
            icon={<Custom.DeleteOutline />}
            label="DELETE"
            color={syncColors.canvasNavButtonColors.navButton}
          />
        )}
        {onSaveAction && (
          <CustomButton
            onClick={onSaveAction}
            disabled={disabledApiActions}
            icon={<Custom.Save />}
            label="SAVE"
            color={syncColors.canvasNavButtonColors.navButton}
          />
        )}
        {onCreateWorkflowAction && (
          <Button
            variant="contained"
            onClick={onCreateWorkflowAction}
            sx={{
              borderTopLeftRadius: '4px !important',
              borderBottomLeftRadius: '4px !important',
              backgroundColor: syncColors.canvasNavButtonColors.navButton,
              '&:hover': {
                background: syncColors.canvasNavButtonColors.navButtonHover
              }
            }}
          >
            Create Workflow
          </Button>
        )}
      </ButtonGroup>
    </Stack>
  );
};

export default TemplateEditorToolbar;

const CustomButton = ({ disabled, onClick, icon, label, color }) => (
  <Button
    variant="text"
    startIcon={<IconStyled icon={icon} color={disabled ? actionColors.lightSurface.disabled : '#373737'} />}
    onClick={onClick}
    disabled={disabled}
    sx={{
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      color
    }}
  >
    {label}
  </Button>
);
