import { Box, IconButton, Stack } from '@mui/material';
import { gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import { Waypoint } from 'react-waypoint';

import { Custom } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import { isNavigationKey } from 'helpers/common';
import { SyncStatusBackground, SyncStatusLabel } from 'modules/Sync/components/SyncStatusHig';
import { actionColors, surfaceColors } from 'styles/theme/colors';

export const syncStatusIndicator = (status, sx) => {
  const title = SyncStatusLabel(status);
  const backgroundColor = SyncStatusBackground(status);

  return (
    <NavItemTooltip title={title} sx={{ marginLeft: 0 }} placement="top">
      <Stack gap={1} direction="horizontal" display="flex" alignItems="center" sx={sx}>
        <Box
          sx={{
            height: 8,
            width: 8,
            borderRadius: '50%',
            justifyContent: 'right',
            backgroundColor
          }}
        />
      </Stack>
    </NavItemTooltip>
  );
};

const LogsDataGridTableGroupingCell = (props) => {
  const { id, field, row, rowNode, onWaypointEnter, lastParentRowId } = props;
  const { workflowName = '', mappingName = '' } = row || {};

  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent('cellNavigationKeyDown', props, event);
    }
  };

  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  if (filteredDescendantCount <= 0) return <>{syncStatusIndicator(row.syncStatus, { ml: 4 })}</>;

  return (
    <>
      {workflowName && syncStatusIndicator(row.syncStatus)}
      {mappingName && syncStatusIndicator(row.syncStatus, { ml: 2, mr: -2 })}
      <Box sx={{ ml: rowNode.depth * 2 }}>
        <IconButton onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={-1} size="small">
          <Box className="expansion-icon">
            {rowNode?.childrenExpanded ? (
              <Custom.ArrowDropDownIcon style={{ color: surfaceColors.lightSurface.primary }} />
            ) : (
              <Custom.ArrowDropDownIcon
                style={{ color: actionColors.lightSurface.active, transform: 'rotate(-90deg)' }}
              />
            )}
          </Box>
        </IconButton>
      </Box>
      {row?.id === lastParentRowId && <Waypoint key={row?.uid} bottomOffset="-20%" onEnter={onWaypointEnter} />}
    </>
  );
};

export default LogsDataGridTableGroupingCell;
