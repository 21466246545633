import React from 'react';

import { CircularProgress } from '@mui/material';

import { CancelButton, CreateButton } from 'components';
import { primaryColors, syncColors } from 'styles/theme/colors';

const ModalFooter = ({
  loading = false,
  onModalClose,
  onSubmit,
  cancelBtnText = 'CANCEL',
  createBtnText = 'Done',
  cancelBtnSx = {},
  createBtnSx = {},
  isDelete
}) => (
  <>
    {loading && <CircularProgress size={20} color="warning" />}
    <CancelButton
      buttonText={cancelBtnText}
      onClick={onModalClose}
      disabled={loading}
      color={isDelete ? primaryColors.main : syncColors.text}
      sx={cancelBtnSx}
    />
    <CreateButton
      buttonText={createBtnText}
      onClick={onSubmit}
      disabled={loading}
      sx={{
        backgroundColor: isDelete ? syncColors.statusColors.failed : syncColors.main,
        '&:hover': {
          background: isDelete
            ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${syncColors.statusColors.failed}`
            : syncColors.containedHoverBackgroundLight
        },
        ...createBtnSx
      }}
    />
  </>
);
export default ModalFooter;
