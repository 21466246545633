import { Button, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Custom, IconStyled } from 'components/Icons';
import CustomTooltip from 'components/Tooltip';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { LOG_FILTER, ROUTES, SYNC_LOGS } from 'modules/Sync/Constants/constants';
import { IsSyncFailedOrNeedsReview } from 'modules/Sync/helpers/logSyncStatusHelpers';
import DownloadLogFile from 'modules/Sync/Logs/components/DownloadLogFile';
import { syncStatusIndicator } from 'modules/Sync/Logs/LogsDataGridTable/LogsDataGridTableGroupingCell';
import { actionColors, otherColors, surfaceColors, syncColors } from 'styles/theme/colors';

const { SCHEDULED, REAL_TIME_SYNC, SYNCED, DOWNLOAD_ISSUES, UPLOAD_ISSUES } = SYNC_LOGS.LOGS_SYNC_TYPE;
const { FAILED } = LOG_FILTER;

const syncTypeIndicator = (type) => {
  let title = '';
  let Icon = null;
  switch (type) {
    case DOWNLOAD_ISSUES:
      Icon = () => <Custom.DownloadIssues sx={{ width: 20, height: 20 }} width={18} />;
      title = 'Get issues';
      break;
    case UPLOAD_ISSUES:
      Icon = () => <Custom.UploadIssues sx={{ width: 20, height: 20 }} width={18} />;
      title = 'Upload issues';
      break;
    case SCHEDULED:
      Icon = () => <Custom.UpdateIcon sx={{ width: 20, height: 20 }} width={18} />;
      title = 'Scheduled';
      break;
    case REAL_TIME_SYNC:
      Icon = () => <Custom.SyncIcon sx={{ width: 20, height: 20 }} width={18} />;
      title = 'Real time';
      break;
    case SYNCED:
    default:
      Icon = () => <Custom.Complete sx={{ width: 20, height: 20 }} width={18} />;
      title = 'Synced';
      break;
  }

  return (
    <Typography variant="body2" sx={{ display: 'flex' }}>
      <IconStyled
        sx={{ ml: 0, mr: 0.5, alignItems: 'center', display: 'flex' }}
        color={actionColors.lightSurface.active}
        icon={<Icon />}
      />
      {title}
    </Typography>
  );
};

const NumFilesIndicator = ({ text, alertCount, onClick }) =>
  alertCount ? (
    <CustomTooltip title="View failed files" arrow placement="top">
      <Typography variant="body2" color={syncColors.logsColors.failedFileCount}>
        <Button
          variant="contained"
          sx={{
            padding: 0,
            backgroundColor: syncColors.statusColors.failed,
            ':hover': { backgroundColor: syncColors.statusColors.failed }
          }}
          onClick={onClick}
        >
          {text}
        </Button>
      </Typography>
    </CustomTooltip>
  ) : (
    <Typography variant="body2" pr={1}>
      {text}
    </Typography>
  );

const MappingIcon = ({ isRowExpanded }) => (
  <IconStyled
    sx={{ ml: 1.5, mr: 1.5, alignItems: 'center', display: 'flex' }}
    color={isRowExpanded ? surfaceColors.lightSurface.primary : actionColors.lightSurface.active}
    icon={<Custom.Mapping />}
  />
);

const ResyncButton = ({ handleRetry, fileSyncLogId, isResyncAllowed }) =>
  isResyncAllowed ? (
    <CustomTooltip title="Resync" arrow placement="top">
      <Custom.SyncIcon
        sx={{
          width: 20,
          height: 20,
          color: otherColors.greyIcon
        }}
        onClick={() => handleRetry(fileSyncLogId)}
      />
    </CustomTooltip>
  ) : (
    <></>
  );
const getErrorLogsLink = (id) =>
  `${ROUTES.SYNC}${ROUTES.LOGS_DETAILS.replace(':id', id.replaceAll('/', '_'))}?filter=${FAILED}`;

const LogsDataGridTableCell = ({ field, row, rowNode, handleRetry }) => {
  const navigate = useNavigate();
  const { fileSyncLogId = '', workflowName = '', mappingName = '', name = '', fileName, source } = row || {};
  const downloadDisabled = !fileSyncLogId;
  const isRowExpanded = rowNode?.childrenExpanded;
  const navigateErrorFiles = () => navigate(getErrorLogsLink(row?.fileSyncLogId));

  if (field === 'name')
    return (
      <Stack gap={1} direction="horizontal" sx={{ '& .MuiTypography-body2': { display: 'flex' } }} width="100%">
        {workflowName && (
          <Link
            underline="none"
            key={workflowName}
            component={RouterLink}
            to={`${ROUTES.SYNC}${ROUTES.WORKFLOWS}?id=${row?.workflowId}`}
          >
            <Typography variant="body2" className="workflow-name">
              <IconStyled
                sx={{ mr: 1.5, alignItems: 'center', display: 'flex' }}
                color={isRowExpanded ? surfaceColors.lightSurface.primary : actionColors.lightSurface.active}
                icon={<Custom.Workflow />}
              />
              <CustomSyncTooltip title={workflowName} sx={{ maxWidth: 150 }}>
                {workflowName}
              </CustomSyncTooltip>
            </Typography>
          </Link>
        )}
        {mappingName && (
          <Link
            underline="none"
            key={mappingName}
            component={RouterLink}
            to={`${ROUTES.SYNC}${ROUTES.MAPPING_EDITOR.replace(':id', row?.mappingId)}`}
          >
            <Typography variant="body2" color={syncColors.text} className="mapping-name" ml={0}>
              <MappingIcon isRowExpanded={isRowExpanded} />
              <CustomSyncTooltip title={mappingName} sx={{ maxWidth: 150 }}>
                {mappingName}
              </CustomSyncTooltip>
            </Typography>
          </Link>
        )}
        {source && (
          <>
            {syncStatusIndicator(row.syncStatus)}
            <Link
              underline="none"
              key={mappingName}
              component={RouterLink}
              to={`${ROUTES.SYNC}${ROUTES.LOGS_DETAILS.replace(':id', row?.fileSyncLogId.replaceAll('/', '_'))}`}
              sx={{ width: '100%' }}
            >
              <Typography variant="body2" color={syncColors.text} className="action-name" ml={1}>
                <IconStyled
                  sx={{ ml: 0.5, mr: 1, alignItems: 'center', display: 'flex' }}
                  icon={<Custom.ActionAlt />}
                />
                <CustomSyncTooltip title={name} sx={{ maxWidth: '100%' }}>
                  {name}
                </CustomSyncTooltip>
              </Typography>
            </Link>
          </>
        )}
      </Stack>
    );

  if (field === 'syncType') return row[field] !== '' ? syncTypeIndicator(row[field]) : '';

  if (field === 'download')
    return (
      source && (
        <DownloadLogFile
          fileSyncLogId={fileSyncLogId}
          fileName={fileName}
          placement="left"
          disabled={downloadDisabled}
          syncInProgress
        />
      )
    );

  if (field === '*resync') {
    const isResyncAllowed = !!IsSyncFailedOrNeedsReview(Number(row?.syncStatus));
    return <ResyncButton handleRetry={handleRetry} fileSyncLogId={fileSyncLogId} isResyncAllowed={isResyncAllowed} />;
  }

  if (field === 'numFiles') {
    const alertCount = IsSyncFailedOrNeedsReview(Number(row?.syncStatus));
    const text = row[field];
    return <NumFilesIndicator alertCount={alertCount} text={text} onClick={navigateErrorFiles} />;
  }

  return (
    (
      <CustomSyncTooltip title={row[field]} sx={{ maxWidth: 200 }}>
        {row[field]}
      </CustomSyncTooltip>
    ) || null
  );
};

export default LogsDataGridTableCell;
