import React from 'react';

import DropdownFormControl from 'modules/Sync/WorkflowEditor/Utils/DropdownFormControl';

const ForgeHubDropdown = ({ value, onChange, loading, items, ...props }) => (
  <DropdownFormControl
    title="Hub"
    id="hub"
    labelId="hub"
    label="hub"
    value={value}
    onChange={onChange}
    loading={loading}
    items={items}
    required
    {...props}
  />
);

export default ForgeHubDropdown;
