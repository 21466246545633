import React from 'react';

import { Custom } from 'components/Icons';

export const EIconType = {
  NAV_ITEM_1: 'NAV_ITEM_1',
  NAV_ITEM_2: 'NAV_ITEM_2',
  NAV_ITEM_3: 'NAV_ITEM_3',
  NAV_ITEM_4: 'NAV_ITEM_4',
  NAV_ITEM_5: 'NAV_ITEM_5',
  FOLDER: 'FOLDER'
};

export const getNavIcon = (icon) =>
  ({
    [EIconType.NAV_ITEM_1]: <Custom.NavItem1 />,
    [EIconType.NAV_ITEM_2]: <Custom.NavItem2 />,
    [EIconType.NAV_ITEM_3]: <Custom.NavItem3 />,
    [EIconType.NAV_ITEM_4]: <Custom.NavItem4 />,
    [EIconType.NAV_ITEM_5]: <Custom.NavItem5 />,
    [EIconType.FOLDER]: <Custom.Folder />
  }[icon]);
