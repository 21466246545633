import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { CONNECTION_TYPES } from 'modules/Sync/Constants/constants';

import { triggerAuthentication, triggerLogoutFlow } from '../authenticationUtils';

const useProcoreMutation = () => {
  const { connectorsList } = useSyncConnectionsContext();

  const authenticateCredentials = async ({ formData, onError }) => {
    let url = connectorsList?.syncConnectorsList?.find(
      (x) => x?.name === CONNECTION_TYPES.PROCORE
    )?.url;

    if (formData?.configuration?.useCustomApplicationCredentials) {
      const formatURL = new URL(url);
      const params = formatURL.searchParams;
      params.set('client_id', formData?.configuration?.clientId);
      params.set('redirect_uri', formData?.configuration?.callbackUrl);
      formatURL.search = params.toString();
      url = formatURL.toString();
    } else if (!url) {
      onError('Callback URL is not configured on this Source');
      return 'error';
    }

    const code = await triggerAuthentication({
      url
    });
    if (!code) {
      onError('Failed to fetch Authorization Code. Please try again');
      return 'error';
    }
    return code;
  };

  const generateAuthCode = async ({ formData, onError }) => {
    let code = null;
    code = await authenticateCredentials({ formData, onError });
    if (code) {
      return code;
    }
    return '';
  };

  const logoutUser = async ({ onError }) => {
    const url = connectorsList?.syncConnectorsList?.find(
      (x) => x?.name === CONNECTION_TYPES.PROCORE
    )?.logoutUrl;
    if (!url) {
      onError('Logout URL is not configured on this Source');
      return;
    }
    await triggerLogoutFlow({
      url
    });
  };

  return { generateAuthCode, logoutUser };
};

export default useProcoreMutation;
