import { useEffect, useRef, useState } from 'react';

import { IconButton } from '@mui/material';

import ConfirmLeavingModal from 'components/ConfirmLeavingModal/ConfirmLeavingModal';
import { Custom, IconStyled } from 'components/Icons';
import CircularProgressWithLabel from 'components/ProgressBars/CircularProgressWithLabel';
import CustomTooltip from 'components/Tooltip';
import DownloadSyncLogsModal from 'modules/Sync/Logs/components/DownloadSyncLogsModal';
import useDownloadLogsHook from 'modules/Sync/Logs/components/useDownloadLogsHook';
import { actionColors, greyColors } from 'styles/theme/colors';

const DownloadLogFile = (props) => {
  const { fileSyncLogId, solitaryFlows = [], sx = {}, placement = 'top' } = props;
  const { disabled = false, syncInProgress = false, tooltipInfo = 'Download File' } = props;
  const { fileName = '' /* single log (.txt) file name */, zipName = '' } = props;
  const [open, setOpen] = useState(false);
  const isComponentMounted = useRef(true);

  const { downloadFile, progress } = useDownloadLogsHook({
    fileSyncLogId,
    syncInProgress,
    fileName,
    solitaryFlows,
    zipName
  });

  const downloadDisabled = progress !== 0 || disabled;

  useEffect(
    () => () => {
      isComponentMounted.current = false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const openModal = () => setOpen(true);
  const hideAllModals = () => setOpen(false);

  return (
    <>
      <CustomTooltip title={tooltipInfo} arrow placement={placement}>
        <IconButton onClick={openModal} sx={{ width: 35, height: 35, ...sx }} disabled={downloadDisabled}>
          <IconStyled
            sx={{ mx: 1.5, alignItems: 'center', display: 'flex' }}
            className={!progress && 'download-log-file'}
            icon={
              progress ? (
                <CircularProgressWithLabel value={progress} />
              ) : (
                <Custom.Download2 fill={downloadDisabled ? greyColors.grey500 : actionColors.lightSurface.active} />
              )
            }
          />
        </IconButton>
      </CustomTooltip>
      <DownloadSyncLogsModal
        onClose={hideAllModals}
        downloadFile={downloadFile}
        disabled={downloadDisabled}
        logId={fileSyncLogId}
        open={open}
      />
      <NavigateAwayModal showDialog={progress} />
    </>
  );
};

export const NavigateAwayModal = ({ showDialog }) => (
  <ConfirmLeavingModal showDialog={showDialog} title="Download in Progress">
    Log file download is in progress. Are you sure you want to cancel the download and proceed?
  </ConfirmLeavingModal>
);
export default DownloadLogFile;
