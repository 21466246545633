import React from 'react';

import { useMutation, gql } from '@apollo/client';
import { Typography } from '@mui/material';

import { syncDeactivateWebhook as DEACTIVATE_WEBHOOK } from 'graphql/mutations';
import { dispatchWebhookGridEvent } from 'helpers/domHelpers';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { WEBHOOK_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWebhooksContext } from 'modules/Sync/Webhooks/Contexts/WebhooksContext';

import ModalFooter from './ModalFooter';

const DeactivateWebhookModal = ({ onClose }) => {
  const { activeModal, selectedWebhookIds, setSelectedWebhookIds } = useSyncWebhooksContext();
  const { handleResponse } = useGraphqlResponseHandler();

  const [deactivateWebhook, { loading }] = useMutation(gql(DEACTIVATE_WEBHOOK));

  const onSuccessHandler = () => {
    dispatchWebhookGridEvent()
    onClose()
  }

  const submit = async () => {
    await handleResponse(
      deactivateWebhook,
      {
        variables: { body: { WebhookIds: selectedWebhookIds } }
      },
      { successMessage: 'Webhook(s) successfully deactivated' }
    );
    onSuccessHandler();
    setSelectedWebhookIds([]);
  };

  return (
    <CustomModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={activeModal === WEBHOOK_MODAL_KEYS.DEACTIVATE_WEBHOOK}
      onClose={onClose}
      title="Confirm Deactivate"
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      paperSx={{ width: 550 }}
      footer={
        <ModalFooter
          cancelBtnText="NO"
          createBtnText="CONFIRM"
          loading={loading}
          onModalClose={onClose}
          onSubmit={submit}
        />
      }
    >
      <Typography mb={1}>Are you sure you want to deactivate selected webhook(s)</Typography>
    </CustomModal>
  );
};
export default DeactivateWebhookModal;
