import React, { useEffect } from 'react';

import { Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumbs } from 'components/NavigationComponents';
import { ROUTES } from 'modules/Sync/Constants/constants';
import useLazyLoadLogsActionDetailsHook from 'modules/Sync/Logs/Contexts/useLazyLoadLogsActionDetailsHook';

const LogsDetailsToolbar = () => {
  const { logActionDetailsData, loadLogActionDetails } = useLazyLoadLogsActionDetailsHook();
  const { id: fileSyncLogId = '' } = useParams();

  const {
    actionAlias = '',
    mappingName = '',
    workflowName = '',
    workflowId = '',
    mappingId = ''
  } = logActionDetailsData?.syncLogActionDetails || {};
  const uid = `${workflowId}-${mappingId}-${fileSyncLogId}`;

  const navigate = useNavigate();

  const breadcrumbs = [
    {
      label: 'Logs',
      onClick: () => navigate(`${ROUTES.SYNC}${ROUTES.LOGS}`)
    },
    {
      label: workflowName,
      onClick: () => navigate(`${ROUTES.SYNC}${ROUTES.LOGS}?id=${uid}`)
    },
    {
      label: mappingName,
      onClick: () => navigate(`${ROUTES.SYNC}${ROUTES.LOGS}?id=${uid}`)
    },
    {
      label: actionAlias
    }
  ];

  useEffect(() => {
    if (fileSyncLogId) {
      const fetchFoldersData = async () => {
        await loadLogActionDetails({ variables: { query: { fileSyncLogId } } });
      };
      fetchFoldersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSyncLogId]);

  return (
    <Stack
      spacing={10}
      direction="row"
      justifyContent="space-between"
      sx={{ position: 'relative', zIndex: 9, width: 'auto' }}
    >
      <Breadcrumbs separator="/" aria-label="breadcrumb" collapseMode="menu" maxItems={2} breadcrumbs={breadcrumbs} />
    </Stack>
  );
};

export default LogsDetailsToolbar;
