import { Box, CircularProgress, Typography } from '@mui/material';

const defaultSX = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const CircularProgressWithLabel = ({ value, sx = {}, suffix = '%' }) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress color="secondary" variant="determinate" value={value} size={35} />
    <Box sx={{ ...defaultSX, ...sx }}>
      <Typography variant="caption" component="div" color="secondary" fontSize={10}>{`${Math.round(
        value
      )}${suffix}`}</Typography>
    </Box>
  </Box>
);

export default CircularProgressWithLabel;
