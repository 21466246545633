import { Button, CircularProgress, MenuItem, Tooltip } from '@mui/material';

import { Custom } from 'components/Icons';
import { useFolderDropdownContext } from 'modules/Sync/WorkflowEditor/Contexts/FolderDropdownContext';
import { useManageIssuesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/ManageIssuesContext';
import { actionColors } from 'styles/theme/colors';

const FolderRowMenuLabel = ({
  nodeId,
  folderId,
  text,
  textFormatter,
  onFolderSelect,
  onFolderCreate,
  disabled,
  isChecked = false,
  isSource = false
}) => {
  const { isLoading, isLoadingFolderName } = useFolderDropdownContext();
  const { getConnectorNodeValues } = useManageIssuesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { folders: selectedFolders = [], folder } = formValues;
  const isSelectedFolder = selectedFolders?.[0]?.id === folderId || folder?.id === folderId;
  const formattedText = textFormatter(text);
  const highlightBg = isSource ? isChecked || isSelectedFolder : folder?.id === folderId;

  return (
    <Button
      variant="text"
      className="folder-row"
      disabled={disabled}
      fullWidth
      sx={{
        justifyContent: 'space-between',
        backgroundColor: highlightBg && actionColors.lightSurface.focus,
        height: 35,
        textTransform: 'none'
      }}
    >
      <MenuItem
        value={text}
        data-folder-id={folderId}
        data-text={formattedText}
        onClick={onFolderSelect}
        disableRipple
        sx={{ px: 0, '&:hover': { backgroundColor: 'transparent' } }}
      >
        <Tooltip title={text} placement="right" arrow disableHoverListener={formattedText.length < 40}>
          <span
            style={{
              justifyContent: 'left',
              width: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              overflowWrap: 'break-word',
              textOverflow: 'ellipsis'
            }}
            data-text={formattedText}
          >
            {formattedText}
          </span>
        </Tooltip>

        {(isLoading || isLoadingFolderName) && (
          <CircularProgress size={12} color="warning" sx={{ ml: 1, display: 'flex' }} />
        )}
      </MenuItem>
      {onFolderCreate && !isLoadingFolderName && (
        <Custom.AddBoxIcon
          id={`create-folder-icon-${folderId}`}
          data-parent-folder-id={folderId}
          className="create-folder-icon"
          onClick={onFolderCreate}
          size="small"
          sx={{ opacity: 0, position: 'absolute', right: 15, top: 5 }}
        />
      )}
    </Button>
  );
};

export default FolderRowMenuLabel;
