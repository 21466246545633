import { useParams } from 'react-router-dom';

import useSaveCanvasDataApiAction from 'modules/Sync/WorkflowEditor/apiActions/saveCanvasData';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';

const useSaveMappingAction = (queryOptions = {}) => {
  const { setWorkflowElements, reactFlowInstance, setIsMappingDataSaved } = useSyncWorkflowEditorContext();
  const { id: mappingId } = useParams();
  const { saveCanvasData } = useSaveCanvasDataApiAction(queryOptions);

  const saveMappingAction = ({
    successMessage = 'Saved Mapping Successfully',
    onCompleted = () => {},
    onFailed = () => {}
  }) => {
    const mappingsConfig = reactFlowInstance?.toObject();
    const canvasData = JSON.stringify(mappingsConfig);
    setWorkflowElements(mappingsConfig?.elements);
    setIsMappingDataSaved(true);
    return saveCanvasData({ mappingId, canvasData, successMessage, onCompleted, onFailed });
  };

  return { saveMappingAction };
};

export default useSaveMappingAction;
