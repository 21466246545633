// eslint-disable-next-line
export default {
  fontFamily: 'Roboto, sans-serif',
  h1: {
    fontSize: 96,
    fontWeight: 'lighter'
  },
  h2: {
    fontSize: 60,
    fontWeight: 'lighter'
  },
  h3: {
    fontSize: 48,
    fontWeight: 'normal'
  },
  h4: {
    fontSize: 34,
    lineHeight: '48px',
    fontWeight: 'normal'
  },
  h5: {
    fontSize: 24,
    lineHeight: '40px',
    fontWeight: '600'
  },
  h6: {
    fontSize: 20,
    fontWeight: '500',
    lineHeight: '32px'
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 'normal'
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: '24px'
  },
  body1: {
    fontSize: 16,
    lineHeight: '32px',
    fontWeight: 'normal'
  },
  body3: {
    fontSize: 16,
    lineHeight: '32px',
    fontWeight: '600'
  },
  body2: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: '400'
  },
  caption: {
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.4px'
  },
  overline: {
    fontSize: 12,
    lineHeight: '32px',
    fontWeight: 400
  },
  tableHeader: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500
  },
  tableCellBold: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '30px'
  },
  tableCell: {
    fontSize: 14,
    lineHeight: '30px'
  },
  formBoldLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  informationText: {
    fontWeight: 'bold',
    fontSize: 14
  },
  button: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: 'secondary.dark',
    lineHeight: '24px'
  }
};
