import { WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { IsSyncFinished } from 'modules/Sync/helpers/logSyncStatusHelpers';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';

export const WorkflowMenuItems = (row) => {
  const {
    activeTab,
    setActiveModal,
    setCurrentWorkflow,
    setCurrentMapping,
    setSelectedWorkflowIds,
    setSelectedMappingIds,
    setSelectedActionIds,
    setFirstSelectedItemName
  } = useSyncWorkflowsContext();
  const {
    ARCHIVE_WORKFLOW,
    DUPLICATE_MAPPING,
    DUPLICATE_WORKFLOW,
    RENAME_WORKFLOW,
    RENAME_MAPPING,
    ACTIVATE_WORKFLOW,
    DEACTIVATE_WORKFLOW,
    DELETE_WORKFLOW,
    RESTORE_WORKFLOW
  } = WORKFLOW_MODAL_KEYS;

  const showDuplicateWorkflowModal = () => setActiveModal(DUPLICATE_WORKFLOW);
  const showDuplicateMappingModal = () => setActiveModal(DUPLICATE_MAPPING);
  const showRenameWorkflowModal = () => setActiveModal(RENAME_WORKFLOW);
  const showRenameMappingModal = () => setActiveModal(RENAME_MAPPING);
  const showDeactivateModal = () => setActiveModal(DEACTIVATE_WORKFLOW);
  const showActivateModal = () => setActiveModal(ACTIVATE_WORKFLOW);
  const showArchiveModal = () => setActiveModal(ARCHIVE_WORKFLOW);
  const showDeleteModal = () => setActiveModal(DELETE_WORKFLOW);
  const showRestoreModal = () => setActiveModal(RESTORE_WORKFLOW);
  const handleOnDuplicate = ({ id, name, workflowMapping, ...rest }) => {
    if (workflowMapping) {
      setCurrentWorkflow({ workflowId: id, workflowName: name, workflowMapping, ...rest });
      return showDuplicateWorkflowModal();
    }
    setCurrentMapping({ workflowMappingId: id, workflowMappingName: name, ...rest });
    return showDuplicateMappingModal();
  };

  const handleOnRename = ({ id, name, workflowMapping, ...rest }) => {
    if (workflowMapping) {
      setCurrentWorkflow({ workflowId: id, workflowName: name, workflowMapping, ...rest });
      return showRenameWorkflowModal();
    }
    setCurrentMapping({ workflowMappingId: id, workflowMappingName: name, ...rest });
    return showRenameMappingModal();
  };

  const handleRestoreWorkflow = async ({ id, isWorkflow, isMapping, isAction }) => {
    if (isWorkflow) setSelectedWorkflowIds([id]);
    if (isMapping) setSelectedMappingIds([id]);
    if (isAction) setSelectedActionIds([id]);
    showRestoreModal();
  };
  const handleDeleteWorkflow = ({ id, isWorkflow, isMapping, isAction, name }) => {
    if (isWorkflow) setSelectedWorkflowIds([id]);
    if (isMapping) setSelectedMappingIds([id]);
    if (isAction) setSelectedActionIds([id]);
    setFirstSelectedItemName(name);
    showDeleteModal();
  };

  const handleOnDeactivate = ({ id, isWorkflow, isMapping, isAction }) => {
    if (isWorkflow) setSelectedWorkflowIds([id]);
    if (isMapping) setSelectedMappingIds([id]);
    if (isAction) setSelectedActionIds([id]);
    showDeactivateModal();
  };
  const handleOnActivate = ({ id, isWorkflow, isMapping, isAction }) => {
    if (isWorkflow) setSelectedWorkflowIds([id]);
    if (isMapping) setSelectedMappingIds([id]);
    if (isAction) setSelectedActionIds([id]);
    showActivateModal();
  };
  const handleOnArchive = async ({ id, isWorkflow, isMapping, isAction }) => {
    if (isWorkflow) setSelectedWorkflowIds([id]);
    if (isMapping) setSelectedMappingIds([id]);
    if (isAction) setSelectedActionIds([id]);
    showArchiveModal();
  };

  return activeTab === 0
    ? [
        ...((row?.isAction || row?.isDeactivated) ? [] : [{ label: 'Duplicate', clickEvent: () => handleOnDuplicate(row) }]),
        ...(row?.isAction ? [] : [{ label: 'Rename', clickEvent: () => handleOnRename(row) }]),
        ...(!row?.isAction && IsSyncFinished(row?.syncStatus)
          ? [
              {
                label: row?.isDeactivated ? 'Activate' : 'Deactivate',
                clickEvent: () => (row?.isDeactivated ? handleOnActivate(row) : handleOnDeactivate(row))
              }
            ]
          : []),
        ...(IsSyncFinished(row?.syncStatus)
          ? [{ label: 'Archive', color: '#F44336', clickEvent: () => handleOnArchive(row) }]
          : [])
      ]
    : [
        ...[{ label: 'Restore', clickEvent: () => handleRestoreWorkflow(row) }],
        ...[{ label: 'Delete', color: '#F44336', clickEvent: () => handleDeleteWorkflow(row) }]
      ];
};
