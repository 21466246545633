import { useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';
import { FormControl } from '@mui/material';
import { useForm } from 'react-hook-form';

import { syncDuplicateMapping as SYNC_DUPLICATE_MAPPING } from 'graphql/mutations';
import { dispatchWorkflowGridEvent } from 'helpers/domHelpers';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { SYNC_ENTITY_NAME_MAX_LENGTH, WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';

import MappingTextField from './MappingTextField';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';

const DuplicateMappingModal = ({ onClose }) => {
  const { currentMapping, activeModal } = useSyncWorkflowsContext();
  const { workflowMappingName, workflowMappingId, workflowId } = currentMapping || {};
  const open = activeModal === WORKFLOW_MODAL_KEYS.DUPLICATE_MAPPING;
  const { handleResponse } = useGraphqlResponseHandler();

  const methods = useForm({
    mode: 'all',
    defaultValues: { mappingName: workflowMappingName }
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid }
  } = methods;

  useEffect(() => {
    if (workflowMappingName) {
      const name = `${workflowMappingName} - clone`;
      setValue(
        'mappingName',
        name.length > SYNC_ENTITY_NAME_MAX_LENGTH ? name.slice(0, SYNC_ENTITY_NAME_MAX_LENGTH) : name
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowMappingName]);

  const [duplicateMapping, { loading }] = useMutation(gql(SYNC_DUPLICATE_MAPPING));

  const onModalClose = () => {
    onClose?.();
  };

  const onSuccessHandler = () => {
    dispatchWorkflowGridEvent();
    onModalClose();
  };

  const onSubmit = handleSubmit((formValues) => {
    const { mappingName } = formValues;

    return handleResponse(
      duplicateMapping,
      {
        variables: {
          body: { MappingName: mappingName, MappingId: workflowMappingId, WorkflowId: workflowId }
        }
      },
      {
        successMessage: 'Mapping successfully duplicated'
      },
      onSuccessHandler,
      onModalClose
    );
  });

  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={<ModalTitle title="Duplicate mapping" />}
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={
        <ModalFooter loading={loading} onModalClose={onModalClose} onSubmit={onSubmit} onSubmitDisabled={!isValid} />
      }
    >
      <FormControl size="small" fullWidth>
        <MappingTextField
          disabled={loading}
          control={control}
          errors={errors}
          maxLength={SYNC_ENTITY_NAME_MAX_LENGTH}
        />
      </FormControl>
    </CustomModal>
  );
};

export default DuplicateMappingModal;
