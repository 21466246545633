import { gql, useLazyQuery } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncLogActionFolderDetails as SYNC_LOG_ACTION_FOLDER_DETAILS } from 'graphql/queries';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const useLazyLoadLogsActionFolderDetailsHook = () => {
  const [
    loadLogActionFolderDetails,
    {
      data: logActionFolderDetailsData,
      loading: loadingLogActionFolderDetails,
      refetch: refetchLogActionFolderDetails,
      startPolling: startLogActionFolderDetailsPolling,
      stopPolling: stopLogActionFolderDetailsPolling
    }
  ] = useLazyQuery(gql(SYNC_LOG_ACTION_FOLDER_DETAILS), {
    errorPolicy: 'all',
    fetchPolicy: FETCH_POLICY
  });

  return {
    loadLogActionFolderDetails,
    logActionFolderDetailsData,
    loadingLogActionFolderDetails,
    refetchLogActionFolderDetails,
    startLogActionFolderDetailsPolling,
    stopLogActionFolderDetailsPolling
  };
};

export default useLazyLoadLogsActionFolderDetailsHook;
