import React from 'react';

import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';

const ControlledInputCheckbox = ({
  defaultValue = false,
  name,
  label,
  control,
  customCallBack = () => {},
  color,
  checkboxProps = {},
  ...props
}) => (
  <Controller
    name={name}
    defaultValue={defaultValue}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <>
        <FormControlLabel
          label={label}
          {...props}
          control={
            <Checkbox
              checked={value === 0 ? false : value}
              color={color}
              onChange={(e, checked) => {
                onChange(checked);
                customCallBack(e, checked);
              }}
              value={value}
              {...checkboxProps}
            />
          }
        />
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </>
    )}
  />
);

export default ControlledInputCheckbox;
