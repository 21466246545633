
import { gql, useQuery } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncConnections as SYNC_CONNECTIONS } from 'graphql/queries';

import { CONNECTION_TYPES } from '../Constants/constants';

const { fetchPolicy } = FETCH_POLICIES.CACHE_AND_NETWORK;

const { WINDOWS } = CONNECTION_TYPES;
const useWindowsConnectorsQuery = ({ setAllWindowsConnections }) => {
  useQuery(gql(SYNC_CONNECTIONS), {
    fetchPolicy,
    variables: {
      query: {
        skip: 0,
        take: 40,
        searchPhrase: 'windows'
      }
    },
    onCompleted: (connectionsList) =>
      setAllWindowsConnections(connectionsList?.syncConnections?.filter((item) => item.type === WINDOWS))
  });
};

export default useWindowsConnectorsQuery;
