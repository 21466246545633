import React, { useCallback, useEffect, useState } from 'react';

import { MIGRATION_TOOL } from 'modules/Sync/Constants/constants';
import { getSyncAllConfigData, importErrors } from 'modules/Sync/helpers/extractWorkflowConfig';

const ImportWorkflowsContext = React.createContext();

const syncAllDefaultState = {
  workflowIds: [],
  syncType: 'SyncNow',
  scheduleSyncConfig: '{}',
  interval: 5,
  mappingCount: 1,
  isSyncEnabled: false
};

const ImportWorkflowProvider = ({ children }) => {
  const [isSyncAll, setIsSyncAll] = useState(true);
  const [isWorkflowLoading, setIsWorkflowLoading] = useState(false);
  const [workflowData, setWorkflowData] = useState([]);
  const [workflowSyncData, setWorkflowSyncData] = useState([]);
  const [syncAllData, setSyncAllData] = useState(syncAllDefaultState);

  const updateSettings = (settings, workflowId, mappingId) => {
    const updatedData = workflowSyncData.map((item) => {
      if (item.workflowId === workflowId) {
        const currentItem = item;
        const updatedMappings = currentItem?.mappings?.map((mapItem) => {
          if (mapItem.mappingId !== mappingId) return mapItem;
          return {
            ...mapItem,
            solitaryFlowSettings: [
              {
                ...mapItem?.solitaryFlowSettings[0],
                ...settings
              }
            ]
          };
        });
        return { ...item, mappings: updatedMappings };
      }
      return item;
    });
    setWorkflowSyncData(updatedData);
  };

  const updateAllSyncSettings = (settings) => {
    setSyncAllData((state) => ({ ...state, ...settings }));
  };

  const resetContext = () => {
    setWorkflowData([]);
    setWorkflowSyncData([]);
    setSyncAllData(syncAllDefaultState);
    setIsSyncAll(true);
  };

  const reduceMappings = (acc, mapItem) => {
    const { isAdded, error } = importErrors(MIGRATION_TOOL.MAPPING_ERROR, mapItem);
    if (isAdded && !error) {
      const solitaryObj = mapItem?.solitaryFlowConfigurations[0];
      const mapItemReq = {
        mappingId: mapItem.mappingId,
        solitaryFlowSettings: [
          {
            solitaryFlowId: solitaryObj?.solitaryFlowId,
            isSyncEnabled: solitaryObj?.source.settings?.isSyncEnabled,
            syncType: solitaryObj?.source.settings?.syncType,
            scheduleSyncConfig: solitaryObj?.source.settings?.scheduleSyncConfig
          }
        ]
      };
      acc.push(mapItemReq);
    }
    return acc;
  };

  const setWorkflowsSyncData = useCallback(() => {
    const syncData = [];
    workflowData?.forEach((item) => {
      const { isAdded, error } = importErrors(MIGRATION_TOOL.WORKFLOW_ERROR, item);
      if (isAdded && !error) {
        syncData.push({
          workflowId: item.workflowId,
          mappings: item?.mappings.reduce(reduceMappings, [])
        });
      }
    });
    const updatedData = syncData.filter((item) => item?.mappings?.length > 0);
    setWorkflowSyncData(updatedData);
    const syncAllConfig = getSyncAllConfigData(updatedData);
    setSyncAllData((state) => ({ ...state, ...syncAllConfig }));
  }, [workflowData]);

  const selectedStateObj = React.useMemo(
    () => ({
      workflowData,
      setWorkflowData,
      updateSettings,
      workflowSyncData,
      setWorkflowSyncData,
      resetContext,
      isWorkflowLoading,
      setIsWorkflowLoading,
      updateAllSyncSettings,
      isSyncAll,
      setIsSyncAll,
      syncAllData
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workflowSyncData, workflowData, syncAllData, isSyncAll, isWorkflowLoading]
  );

  useEffect(() => {
    setWorkflowsSyncData();
  }, [workflowData, setWorkflowsSyncData]);

  return <ImportWorkflowsContext.Provider value={selectedStateObj}>{children}</ImportWorkflowsContext.Provider>;
};

const useImportWorkflowsContext = () => {
  const context = React.useContext(ImportWorkflowsContext);
  if (context === undefined) {
    throw new Error('useImportWorkflowsContext must be used within a ImportWorkflowsContext');
  }
  return context;
};

export { ImportWorkflowProvider, ImportWorkflowsContext, useImportWorkflowsContext };
