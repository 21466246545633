import { formatTo } from 'helpers/dateFunctions';
import { ACTION_STATUS, CONNECTION_NAME } from 'modules/Sync/Constants/constants';
import { getFileStatus } from 'modules/Sync/helpers/getStatus';

export const formatFilesToRows = (files, id) =>
  files?.flatMap(({ fileName, fileSyncTime, solitaryFlows }) => {
    const encodedFileName = window.btoa(fileName).slice(0, 10);
    const fileSyncAt = formatTo(new Date(fileSyncTime), 'hh:mm A');
    const fileStatus = getFileStatus(solitaryFlows);
    const fileId = `${encodedFileName}-${fileSyncTime}`;
    return [
      {
        id: fileId,
        path: [id, fileId],
        name: `${fileName}  - (${fileSyncAt})`,
        fileSyncAt,
        fileName,
        fileStatus
      },
      ...solitaryFlows?.flatMap(
        ({ workflowId, workflowName, mappingId, mappingName, source, destinations, actionAlias, sourcePath }) => {
          const firstFileSyncLogId = destinations?.[0]?.fileSyncLogId;
          const flowUid = `${fileId}-${firstFileSyncLogId}`;
          return [
            {
              id: flowUid,
              path: [id, fileId, flowUid],
              name: `${workflowName} / ${mappingName}`,
              actionAlias,
              workflowId,
              mappingId,
              source: CONNECTION_NAME[source],
              destinations: destinations?.map(({ destination }) => CONNECTION_NAME[destination]).join(', '),
              isSolitaryFlow: true
            },
            ...[
              {
                isSource: true,
                id: `${sourcePath}-${flowUid}`,
                path: [id, fileId, flowUid, `${sourcePath}-${flowUid}`],
                source: CONNECTION_NAME[source],
                name: sourcePath,
                filePath: sourcePath,
                isFile: true
              },
              ...destinations?.map(
                (
                  {
                    fileSyncLogId,
                    destination,
                    destinationPath,
                    syncStatus,
                    errorCode,
                    isResyncAllowed,
                    isContactSupportTeam
                  },
                  index
                ) => ({
                  id: fileSyncLogId,
                  fileSyncLogId,
                  serial: index + 1,
                  path: [id, fileId, flowUid, fileSyncLogId],
                  name: destinationPath,
                  destination: CONNECTION_NAME[destination],
                  filePath: destinationPath,
                  syncStatus,
                  errorCode,
                  isResyncAllowed,
                  isContactSupportTeam,
                  isFile: true
                })
              )
            ]
          ];
        }
      )
    ];
  });

const RealTimeLogsDataGridTableRows = (logs = []) => {
  if (!logs) return [];

  return logs.flatMap(({ date, syncStatus: logStatus = ACTION_STATUS.DISABLED }, index) => {
    const name = formatTo(new Date(date));
    const id = `${name}-${index}`;
    const fileRows = [{ id, path: [id], name, date, isParent: true, logStatus, childrenFetched: !index }];

    return fileRows;
  });
};

export default RealTimeLogsDataGridTableRows;
