import { useEffect, useState } from 'react';

import { FormControl, InputAdornment } from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

import { Custom } from 'components/Icons';
import { formatTo } from 'helpers/dateFunctions';
import { getMicroFeContainer } from 'helpers/domHelpers';

import { ControlInput } from '../Connections/ConnectionProperties/FormFields';

const DATE_PATTERN = 'MM/DD/YYYY';

const DateRangePickerInput = ({ label, dateRange = [], onChange, sx = {}, required = false, disabled = false }) => {
  const [value, setValue] = useState([null, null]);

  const { control, reset } = useForm({ defaultValues: { datePicker: '' } });

  const handleOnChange = (newVal) => {
    const [startDate, endDate] = newVal;
    if (!startDate || !endDate) return false;

    const dateFrom = formatTo(startDate);
    const dateTo = formatTo(endDate);

    reset({ datePicker: `${dateFrom} - ${dateTo}` });
    return onChange({ dateFrom, dateTo });
  };

  useEffect(() => {
    const [startDate, endDate] = dateRange;
    if (!startDate || !endDate) return false;

    const dateFrom = formatTo(startDate, DATE_PATTERN);
    const dateTo = formatTo(endDate, DATE_PATTERN);

    reset({ datePicker: `${dateFrom} - ${dateTo}` });
    setValue([dayjs(startDate), dayjs(endDate)]);

    return () => setValue([null, null]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  return (
    <FormControl fullWidth sx={{ m: 1 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          value={value}
          onChange={handleOnChange}
          disabled={disabled}
          label={label}
          renderInput={(startProps) => {
            const { onClick } = startProps;
            return (
              <ControlInput
                control={control}
                name="datePicker"
                onClick={onClick}
                fullWidth
                label={`${label}${required ? '*' : ''}`}
                data-cy="date-picker"
                disabled={disabled}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Custom.CalendarTodayOutlined />
                    </InputAdornment>
                  )
                }}
                sx={{ ...sx, mt: 0, mb: 0 }}
              />
            );
          }}
          PopperProps={{ container: getMicroFeContainer }}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default DateRangePickerInput;
