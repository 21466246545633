import { gql, useLazyQuery } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncLogActionDetails as SYNC_LOG_ACTION_DETAILS } from 'graphql/queries';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const useLazyLoadLogsActionDetailsHook = () => {
  const [
    loadLogActionDetails,
    {
      data: logActionDetailsData,
      loading: loadingLogActionDetails,
      refetch: refetchLogActionDetails,
      startPolling: startLogActionDetailsPolling,
      stopPolling: stopLogActionDetailsPolling
    }
  ] = useLazyQuery(gql(SYNC_LOG_ACTION_DETAILS), {
    errorPolicy: 'all',
    fetchPolicy: FETCH_POLICY
  });

  return {
    loadLogActionDetails,
    logActionDetailsData,
    loadingLogActionDetails,
    refetchLogActionDetails,
    startLogActionDetailsPolling,
    stopLogActionDetailsPolling
  };
};

export default useLazyLoadLogsActionDetailsHook;
