import { InMemoryCache } from '@apollo/client';

import QueryPolicies from './QueryPolicies';

const cache = new InMemoryCache({
  typePolicies: {
    Query: QueryPolicies
  }
});

export default cache;
