import { useEffect } from 'react';

import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';
import { MIGRATION_TOOL } from 'modules/Sync/Constants/constants';
import { importErrors } from 'modules/Sync/helpers/extractWorkflowConfig';
import {
  getErrors,
  getImportedWorkflows,
  getWorkflowDataWithErrors,
  getWorkflowsToAdd
} from 'modules/Sync/helpers/importHelpers';

const useImportMethodsHooks = ({
  setActiveStep,
  fields,
  enqueueSnackbar,
  importWorkflow,
  setWorkflowData,
  replace,
  setError,
  setIsWorkflowLoading,
  connectionsData,
  getWorkflowDataMethods,
  workflowData
}) => {
  const importWorkflowOnComplatePayload = (fields, response) => {
    const { syncWorkflowsImport } = response;
    const updatedWorkflows = getWorkflowDataWithErrors(fields, syncWorkflowsImport?.workflows);
    const { error } = getErrors(syncWorkflowsImport?.workflows);
    setWorkflowData(updatedWorkflows);
    replace(updatedWorkflows);
    if (!error) {
      enqueueSnackbar('Workflows successfully imported', { ...VARIANT_SUCCESS });
      setActiveStep((state) => state + 1);
    } else {
      enqueueSnackbar(error, { ...VARIANT_ERROR });
    }
  };
  const saveWorkflows = (fields) => {
    const mappingsToAdd = fields.filter((item) => {
      const mappings = item?.mappings?.filter((mapItem) => {
        const { isAdded } = importErrors(MIGRATION_TOOL.MAPPING_ERROR, mapItem);
        return !isAdded;
      });
      return mappings.length;
    }).length;

    const { workflowsToAdd, invalidMappings } = getWorkflowsToAdd(fields);
    if (!mappingsToAdd && !workflowsToAdd.length) return setActiveStep((state) => state + 1);

    if (invalidMappings.length) {
      const { workflowName } = invalidMappings[0];
      const message = `Data needed in ${workflowName}`;
      return enqueueSnackbar(message, { ...VARIANT_ERROR });
    }

    return importWorkflow({
      variables: { body: { Workflows: workflowsToAdd } },
      onCompleted: async (response) => {
        importWorkflowOnComplatePayload(fields, response);
      }
    });
  };
  const handleConfigChange = async (files) => {
    try {
      setError('');
      setIsWorkflowLoading(true);
      const { invalidFiles, workflows } = await getImportedWorkflows(
        files,
        connectionsData,
        getWorkflowDataMethods,
        fields
      );

      if (invalidFiles && !workflows.length) setError({ message: 'Invalid JSON File' });
      setWorkflowData([...fields, ...workflows]);
      setIsWorkflowLoading(false);
    } catch {
      setError({ message: 'Invalid JSON File' });
      setIsWorkflowLoading(false);
    }
    return true;
  };

  useEffect(() => {
    replace(workflowData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowData]);

  return { saveWorkflows, handleConfigChange };
};

export default useImportMethodsHooks;
