import React from 'react';

import { Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { MoreMenu } from 'components';
import { Custom, IconStyled } from 'components/Icons';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { ROUTES } from 'modules/Sync/Constants/constants';
import WebhookStatusHig from 'modules/Sync/Webhooks/components/WebhookStatusHig';
import { actionColors, surfaceColors, syncColors } from 'styles/theme/colors';

const getActionLink = ({ row }) => {
  const { isArchived: isActionArchived, isMappingArchived, solitaryFlowId, mappingId = '' } = row;

  const url = new URL(`${window.location.origin}${ROUTES.SYNC}${ROUTES.MAPPING_EDITOR.replace(':id', mappingId)}`);

  const archivedQuery = isActionArchived || isMappingArchived ? { isArchived: 1 } : {};
  const activeAction = !isMappingArchived && !isActionArchived ? { activeAction: solitaryFlowId } : {};

  const queryParams = new URLSearchParams({ ...archivedQuery, ...activeAction });

  return `${url.pathname}?${queryParams}`;
};
const WebhooksDataGridCell = ({ id, field, row, rowNode, options }) => {
  const isExpanded = rowNode?.childrenExpanded;
  const iconColor = isExpanded ? surfaceColors.lightSurface.primary : actionColors.lightSurface.active;

  const archivedQuery = row?.isArchived ? '?isArchived=1' : '';
  if (id !== 0 && field.toLowerCase().includes('name')) {
    if (row.isWebhook) {
      return (
        <Typography variant="body2" sx={{ display: 'flex' }} className="webhook-name">
          <IconStyled
            sx={{ mr: 1.5, alignItems: 'center', display: 'flex' }}
            color={iconColor}
            icon={<Custom.Webhook />}
          />
          {row.name}
        </Typography>
      );
    }

    if (row.isworkflow) {
      return (
        <Typography variant="body2" sx={{ display: 'flex', marginLeft: 2 }} className="workflow-name">
          <IconStyled
            sx={{ marginRight: 1.5, alignItems: 'center', display: 'flex' }}
            color={iconColor}
            icon={<Custom.Workflow />}
          />
          {row.name}
        </Typography>
      );
    }
    if (row?.isMapping) {
      return (
        <Link
          underline="none"
          key={row?.name}
          component={RouterLink}
          to={`${ROUTES.SYNC}${ROUTES.MAPPING_EDITOR.replace(':id', row?.mappingId)}${archivedQuery}`}
        >
          <Typography
            variant="body2"
            color={syncColors.text}
            sx={{ display: 'flex', marginLeft: 4 }}
            className="mapping-name"
          >
            <IconStyled
              sx={{ ml: 1.5, mr: 1.5, alignItems: 'center', display: 'flex' }}
              color={iconColor}
              icon={<Custom.Mapping />}
            />
            {row.name}
          </Typography>
        </Link>
      );
    }

    const actionLink = getActionLink({ row });

    return (
      <Link underline="none" key={row.name} component={RouterLink} to={actionLink}>
        <Typography
          variant="body2"
          color={syncColors.text}
          sx={{ display: 'flex', marginLeft: 6 }}
          className="action-name"
        >
          <IconStyled sx={{ ml: 0.5, mr: 1, alignItems: 'center', display: 'flex' }} icon={<Custom.ActionAlt />} />
          <CustomSyncTooltip title={row.name} sx={{ maxWidth: '100%' }}>
            {row.name}
          </CustomSyncTooltip>
        </Typography>
      </Link>
    );
  }
  if (id !== 0 && field === '*options' && row?.isWebhook) {
    return (
      <MoreMenu
        options={options}
        sx={{ fontSize: 21, opacity: 0, ':hover': { opacity: 1 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    );
  }
  if (id !== 0 && field === 'isActive' && row?.isWebhook) {
    return WebhookStatusHig(row[field], { ml: row.isAction ? -1.8 : '' });
  }

  return (
    (
      <Typography variant="body2" width="100%">
        {row[field]}
      </Typography>
    ) || null
  );
};

export default WebhooksDataGridCell;
