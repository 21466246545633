import { useState } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncMakeForesiteFolder as SYNC_MAKE_FORESITE_FOLDER } from 'graphql/mutations';
import { syncFetchForesiteSelectedFolderIdStack as SYNC_FETCH_FORESITE_SELECTED_FOLDER } from 'graphql/queries';
import useCache from 'hooks/useCache';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import { getForesiteSelectEventHandlers } from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Foresite/foresiteHelpers';
import FoldersSelectDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown';
import { getLatestFolders } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';

const {
  NETWORK_ONLY: { fetchPolicy: NETWORK_ONLY_FETCH_POLICY }
} = FETCH_POLICIES;

const FETCH_FOLDERS_API = 'syncFetchForesiteFolders';

const ForesiteFoldersDropdown = (props) => {
  const { connectionId, nodeId, isSource = false, isLoadingFolders = false, disabled = false } = props;
  const { onFolderStackLoad = () => {}, fetchMoreRootFolders, folders: propFolders } = props;
  const { deleteCache } = useCache();

  const [isInitialStackFetched, setIsInitialStackFetched] = useState(false);
  const { getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const setFormValues = (props) => updateConnectorNodeValues({ nodeId, isSource, props });
  const { hub, projects, project = projects?.[0], folders, folder = folders?.[0] } = formValues;
  const { handleResponse } = useGraphqlResponseHandler();

  const [loadForesiteFolderStack, { loading: isLoadingStack }] = useLazyQuery(gql(SYNC_FETCH_FORESITE_SELECTED_FOLDER));

  const [makeForesiteFolder] = useMutation(gql(SYNC_MAKE_FORESITE_FOLDER));

  const { fetchForsiteFolders } = getForesiteSelectEventHandlers({ hub, project, connectionId });

  const fetchMoreFoldersHandler = async ({ isRoot = false, folderId, skip, onCompleted }) => {
    if (!isRoot) {
      const foresiteFolders = await fetchForsiteFolders({ parentFolderId: folderId, skip });
      onCompleted(foresiteFolders);
      return foresiteFolders;
    }
    await fetchMoreRootFolders(skip, { connectionId, companyId: hub?.id, projectId: project?.id });
    return onCompleted(getLatestFolders(propFolders));
  };

  const onFolderExpandHandler = async ({ folderId, onCompleted, skip, take }) => {
    const foresiteFolders = await fetchForsiteFolders({ parentFolderId: folderId, skip, take });
    return onCompleted(foresiteFolders);
  };

  const onFolderSelectHandler = ({ onCompleted }) => onCompleted();

  const onCreateFolderHandler = async ({ folderId, folderName, onCompleted }) => {
    await handleResponse(
      makeForesiteFolder,
      {
        variables: {
          query: { connectionId },
          body: { CompanyId: hub?.id, ProjectId: project?.id, ParentFolderId: folderId, FolderName: folderName }
        }
      },
      { successMessage: 'Created folder successfully' },
      async () => {
        const foresiteFolders = await fetchForsiteFolders({
          parentFolderId: folderId,
          fetchPolicy: NETWORK_ONLY_FETCH_POLICY,
          skip: null,
          take: null
        });
        deleteCache(FETCH_FOLDERS_API);
        onCompleted(foresiteFolders);
      }
    );
    onCompleted();
  };

  const onFolderDropdownClick = async () => {
    if (!folder?.id || isInitialStackFetched) return;

    await loadForesiteFolderStack({
      variables: {
        query: {
          connectionId,
          companyId: hub?.id,
          projectId: project?.id,
          selectedFolderId: folder?.id
        }
      },
      onCompleted: async (response) => {
        const { syncFetchForesiteSelectedFolderIdStack } = response;
        let expandedFolderIds = [...(syncFetchForesiteSelectedFolderIdStack || [])]?.reverse();
        expandedFolderIds = [...new Set(expandedFolderIds)];
        const closestParentFolderId = expandedFolderIds[expandedFolderIds.length - 2] || '';
        await setFormValues({
          uiFolders: folders?.map((item) => ({ ...item, parentId: closestParentFolderId })),
          expandedFolderIds
        });
        onFolderStackLoad?.(closestParentFolderId);
        setIsInitialStackFetched(true);
      }
    });
  };

  return (
    <FoldersSelectDropdown
      {...props}
      vertical={-170}
      horizontal={675}
      multiple={isSource}
      isLoading={isLoadingFolders}
      disabled={isLoadingFolders || disabled}
      isLoadingFolderStack={isLoadingStack}
      isInitialStackFetched={isInitialStackFetched}
      onFolderExpandHandler={onFolderExpandHandler}
      onFolderSelectHandler={onFolderSelectHandler}
      onCreateFolderHandler={onCreateFolderHandler}
      onFolderDropdownClick={onFolderDropdownClick}
      fetchMoreFoldersHandler={fetchMoreFoldersHandler}
    />
  );
};

export default ForesiteFoldersDropdown;
