import { useEffect } from 'react';

import { useImportWorkflowsContext } from '../../components/ImportWorkflowsContext';

const useImportWorkflowsHook = ({ onMountCallback }) => {
  const { setIsSyncAll } = useImportWorkflowsContext();

  useEffect(() => setIsSyncAll(true), [setIsSyncAll]);

  useEffect(() => {
    onMountCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useImportWorkflowsHook;
