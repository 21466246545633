import React, { useEffect, useState } from 'react';

import { FormHelperText, InputAdornment, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import CopyToClipboardBtn from 'modules/Sync/components/CopyToClipboardBtn';
import { Caption, ControlTextField, ControlCheckbox } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import useProcoreMutation from 'modules/Sync/Connections/ConnectionProperties/hooks/useProcoreMutation';
import useUpsertConnectionMutation from 'modules/Sync/Connections/ConnectionProperties/useUpsertConnectionMutation';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { CONNECTION_TYPES } from 'modules/Sync/Constants/constants';

import { ProcoreLogoutButton } from './ProcoreFields';

const EMAIL_ERROR_RESPONSE_MSG = 'User Name (Email)';
const EMAIL_ERROR_KEYWORD = 'User Not valid';

const findRedirectURL = (connectorsList) => {
  const defaultAuthUrl = connectorsList?.syncConnectorsList?.find(
    (connector) => connector?.name === CONNECTION_TYPES.PROCORE
  )?.url;
  return defaultAuthUrl ? new URL(defaultAuthUrl)?.searchParams?.get('redirect_uri') : '';
};

export const procoreFormDefaultValues = ({ configuration: selectedConnectionConfig }) => ({
  useCustomApplicationCredentials: !!selectedConnectionConfig?.useCustomApplicationCredentials,
  email: selectedConnectionConfig?.email || '',
  clientId: selectedConnectionConfig?.clientId || '',
  clientSecret: selectedConnectionConfig?.clientSecret || '',
  callbackUrl: selectedConnectionConfig?.callbackUrl || ''
});

// eslint-disable-next-line max-lines-per-function
const Procore = ({ actionRenderer, disableAllFields, onSuccessCallback }) => {
  const { currentConnection, connectorsList, setHeaderActionsDisabled } = useSyncConnectionsContext();
  const [validationError, setValidationError] = useState('');
  const { upsertConnection, loading } = useUpsertConnectionMutation();
  const { generateAuthCode, logoutUser } = useProcoreMutation();

  const {
    control,
    watch,
    setValue,
    setError: setFormError,
    formState: { isValid }
  } = useFormContext();

  const setError = (errors) => {
    if (typeof errors === 'string') {
      setValidationError(errors);
    } else {
      const { errorInfo } = errors?.[0] || {};
      setValidationError(Object.values(errorInfo)?.[0]);
    }

    if (errors?.includes(EMAIL_ERROR_RESPONSE_MSG) || errors?.[0]?.errorInfo?.[EMAIL_ERROR_KEYWORD]) {
      setFormError('configuration.email', { type: 'invalid', message: 'Invalid Email' });
    }
  };

  useEffect(() => {
    setValidationError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConnection]);

  const updateConnection = async (formData) => {
    setValidationError('');
    const authenticationCode = await generateAuthCode({ formData, onError: setError });

    if (authenticationCode !== 'error') {
      delete formData.dataSource;
      setHeaderActionsDisabled(false);
      upsertConnection({
        formData,
        authenticationCode,
        connectionType: CONNECTION_TYPES.PROCORE,
        onError: setError,
        onSuccess: onSuccessCallback
      });
    }
  };

  const onReset = async () => {
    await logoutUser({ onError: setError });
  };

  const submitDisabled = !isValid;
  const useCustomCredentials = watch('configuration.useCustomApplicationCredentials');

  const defaultCallbackUrl = React.useMemo(
    () => findRedirectURL(connectorsList),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connectorsList, useCustomCredentials]
  );

  useEffect(() => {
    if (useCustomCredentials) {
      setValue('configuration.callbackUrl', defaultCallbackUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCallbackUrl, useCustomCredentials]);

  return (
    <>
      <Caption>Credentials</Caption>
      <ControlTextField
        key={currentConnection?.connectionId}
        control={control}
        requiredNoSpace
        email
        name="configuration.email"
        label="Email*"
        errorMessages={{ email: 'Invalid' }}
        errorOnFocusOnly
        disabled={disableAllFields}
      />
      <ControlCheckbox
        name="configuration.useCustomApplicationCredentials"
        control={control}
        label={<Typography variant="body2">Use custom application credentials</Typography>}
        checkboxProps={{ size: 'small' }}
        disabled={disableAllFields}
      />
      {useCustomCredentials && (
        <>
          <ControlTextField
            key={currentConnection?.connectionId}
            control={control}
            requiredNoSpace={useCustomCredentials}
            name="configuration.clientId"
            label="Client ID*"
            disabled={disableAllFields}
          />
          <ControlTextField
            key={currentConnection?.connectionId}
            control={control}
            requiredNoSpace={useCustomCredentials}
            name="configuration.clientSecret"
            label="Client Secret*"
            disabled={disableAllFields}
          />
          <ControlTextField
            key={currentConnection?.connectionId}
            control={control}
            disabled
            name="configuration.callbackUrl"
            label="Callback URL"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipboardBtn textToCopy={defaultCallbackUrl} />
                </InputAdornment>
              )
            }}
          />
        </>
      )}
      <FormHelperText error>{validationError}</FormHelperText>
      {actionRenderer({
        onSubmit: updateConnection,
        submitDisabled,
        loading,
        customRenderer: <ProcoreLogoutButton onReset={onReset} />
      })}
    </>
  );
};

export default Procore;
