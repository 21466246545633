import { useState } from 'react';

import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { createProcoreFolder as CREATE_PROCORE_FOLDER } from 'graphql/mutations';
import {
  getProcoreFolders as GET_PROCORE_FOLDERS,
  getProcoreSelectedFolderIdStack as GET_PROCORE_FOLDER_STACK
} from 'graphql/queries';
import useCache from 'hooks/useCache';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import {
  DEFAULT_ROOT_FOLDER,
  DRAWING_TYPE_FOLDER_ID,
  DROPDOWN_DEFAULT_PG_SIZE,
  FOLDER_TYPES
} from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import FoldersSelectDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown';
import { getLatestFolders } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';

const {
  NETWORK_ONLY: { fetchPolicy: NETWORK_ONLY_POLICY },
  NO_CACHE: { fetchPolicy: NO_CACHE_FETCH_POLICY }
} = FETCH_POLICIES;

const FETCH_FOLDERS_API = 'getProcoreFolders';

const isFoldersExpansionEnabled = (folderId, folderType) =>
  folderId === DEFAULT_ROOT_FOLDER.ID ||
  FOLDER_TYPES.find(({ id, expandableFolders }) => folderType === id && expandableFolders);

const createProcoreFolderApiVariables = ({ folderType, connectionId, hubId, projectId, folderId, folderName }) => ({
  variables: {
    params: { folderType },
    query: { connectionId },
    body: {
      CompanyId: hubId,
      ProjectId: projectId,
      ParentFolderId: folderId,
      Name: folderName
    }
  }
});

const ProcoreFoldersDropdown = (props) => {
  const { connectionId, nodeId, isSource = false, isLoadingFolders = false, disabled = false } = props;
  const { onFolderStackLoad = () => {}, fetchMoreRootFolders } = props;
  const client = useApolloClient();
  const [isInitialStackFetched, setIsInitialStackFetched] = useState(false);
  const { getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const setFormValues = (props) => updateConnectorNodeValues({ nodeId, isSource, props });
  const { folderType = '', hub: { id: hubId = '' } = {}, projects, folders } = formValues;
  const { project: { id: projectId = '' } = projects?.[0] || {} } = formValues;
  const { folder = folders?.[0], folders: uiFolders } = formValues;
  const isDrawingTypeSelected = folderType === DRAWING_TYPE_FOLDER_ID;
  const { handleResponse } = useGraphqlResponseHandler();
  const { deleteCache } = useCache();

  const [loadProcoreFolderStack, { loading: isLoadingStack }] = useLazyQuery(gql(GET_PROCORE_FOLDER_STACK));

  const [makeProcoreFolder] = useMutation(gql(CREATE_PROCORE_FOLDER));

  const fetchProcoreFolders = async (params) => {
    const { parentFolderId, fetchPolicy = NO_CACHE_FETCH_POLICY, skip = 0, take = DROPDOWN_DEFAULT_PG_SIZE } = params;
    const companyId = hubId;
    const parentId = encodeURIComponent(parentFolderId);
    const queryParams = { connectionId, companyId, projectId, parentFolderId: parentId, skip, take };
    const variables = { params: { folderType }, query: queryParams };

    const { data } = await client.query({ query: gql(GET_PROCORE_FOLDERS), variables, fetchPolicy });
    return data?.getProcoreFolders || [];
  };

  const fetchMoreFoldersHandler = async ({ isRoot = false, folderId, skip, onCompleted }) => {
    if (!isRoot) {
      const procoreFolders = await fetchProcoreFolders({ parentFolderId: folderId, skip });
      onCompleted(procoreFolders);
      return procoreFolders;
    }
    await fetchMoreRootFolders(skip, { connectionId, companyId: hubId, projectId });
    return onCompleted(getLatestFolders(folders));
  };

  const onFolderExpandHandler = async ({ folderId, onCompleted, skip, take }) => {
    const procoreFolders = isFoldersExpansionEnabled(folderId, folderType)
      ? await fetchProcoreFolders({ parentFolderId: folderId, skip, take })
      : [];
    return onCompleted(procoreFolders);
  };

  const onFolderSelectHandler = ({ onCompleted }) => onCompleted();

  const onCreateFolderHandler = FOLDER_TYPES.find(({ id }) => id === folderType)?.allowCreateFolder
    ? async ({ folderId, folderName, onCompleted }) => {
        await handleResponse(
          makeProcoreFolder,
          createProcoreFolderApiVariables({ folderType, connectionId, hubId, projectId, folderId, folderName }),
          { successMessage: 'Created folder successfully' },
          async () => {
            const params = { parentFolderId: folderId, fetchPolicy: NETWORK_ONLY_POLICY, skip: null, take: null };
            deleteCache(FETCH_FOLDERS_API);
            const procoreFolders = await fetchProcoreFolders(params);
            onCompleted(procoreFolders);
          }
        );
        onCompleted();
      }
    : null;

  const onFolderDropdownClick = async () => {
    if (isDrawingTypeSelected) {
      await setFormValues({ ...formValues, expandedFolderIds: [DEFAULT_ROOT_FOLDER.ID, folder.id] });
      onFolderStackLoad?.(DEFAULT_ROOT_FOLDER.ID);
      return setIsInitialStackFetched(true);
    }
    if (folder?.id && !isInitialStackFetched) {
      const queryParams = { connectionId, companyId: hubId, projectId, selectedFolderId: folder?.id };
      return loadProcoreFolderStack({
        variables: { params: { folderType }, query: queryParams },
        onCompleted: async (response) => {
          const { getProcoreSelectedFolderIdStack } = response;
          let expandedFolderIds = [...(getProcoreSelectedFolderIdStack || [])]?.reverse();
          expandedFolderIds = [...new Set([...expandedFolderIds, folder.id])];
          const closestParentFolderId = expandedFolderIds[expandedFolderIds.length - 2] || '';
          const uiFoldersWithParent = uiFolders?.map((item) => ({ ...item, parentId: closestParentFolderId }));
          await setFormValues(
            isSource ? { ...formValues, expandedFolderIds, uiFolders: uiFoldersWithParent } : { expandedFolderIds }
          );
          if (closestParentFolderId) onFolderStackLoad?.(closestParentFolderId);
          setIsInitialStackFetched(true);
        }
      });
    }
    return null;
  };

  return (
    <FoldersSelectDropdown
      {...props}
      isLoading={isLoadingFolders}
      disabled={isLoadingFolders || disabled}
      checkIsFolderUnselectable={(folderId) => folderId === DEFAULT_ROOT_FOLDER.ID}
      isLoadingFolderStack={isLoadingStack}
      isInitialStackFetched={isInitialStackFetched}
      onFolderExpandHandler={onFolderExpandHandler}
      onFolderSelectHandler={onFolderSelectHandler}
      onCreateFolderHandler={onCreateFolderHandler}
      onFolderDropdownClick={onFolderDropdownClick}
      permitFolderCreationForIds={isDrawingTypeSelected ? [DEFAULT_ROOT_FOLDER.ID] : []}
      fetchMoreFoldersHandler={fetchMoreFoldersHandler}
    />
  );
};

export default ProcoreFoldersDropdown;
