import React, { useCallback, useState } from 'react';

import { Button, MenuItem, Typography, useTheme } from '@mui/material';

import CustomMenu from 'components/Menu';
import CustomArrowDropDownIcon from 'modules/Sync/components/CustomArrowDropDownIcon';
import ConnectionsDeleteModal from 'modules/Sync/Connections/components/ConnectionsDeleteModal';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { syncColors } from 'styles/theme/colors';

const ActionsMenu = () => {
  const {
    selectedConnectionIds,
    setSelectedConnectionIds,
    setCurrentConnection,
    connectionsData,
    headerActionsDisabled
  } = useSyncConnectionsContext();
  const isActionDisabled =
    selectedConnectionIds.length < 1 ||
    connectionsData?.syncConnections?.some(
      ({ id, isConnectionUsed }) => selectedConnectionIds.includes(id) && isConnectionUsed
    );
  const {
    palette: { secondary }
  } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const updateAnchorEl = useCallback((element) => setAnchorEl(element), []);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    updateAnchorEl(e.currentTarget);
  };
  const handleClose = () => updateAnchorEl(null);

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setSelectedConnectionIds([]);
    setCurrentConnection();
  };
  const handleDeleteModalOpen = (e) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    handleClose();
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        endIcon={<CustomArrowDropDownIcon disabled={headerActionsDisabled} />}
        onClick={handleClick}
        disabled={headerActionsDisabled}
        color="secondary"
        sx={{
          padding: '0 8px 0 8px',
          height: '32px',
          fontWeight: 500,
          border: `solid 1px ${syncColors.outlinedRestingBorder}`,
          color: syncColors.text,
          background: `linear-gradient(90deg, transparent 67%, ${secondary.outlinedHoverBackground} 33%)`
        }}
      >
        ACTIONS
      </Button>
      <CustomMenu
        id="Actions for items"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disabled={headerActionsDisabled}
        MenuListProps={{
          sx: { width: '160px' }
        }}
      >
        <MenuItem onClick={handleDeleteModalOpen} disabled={isActionDisabled}>
          <Typography color="error">Delete</Typography>
        </MenuItem>
      </CustomMenu>
      <ConnectionsDeleteModal
        connectionIds={selectedConnectionIds}
        open={showDeleteModal}
        onClose={handleDeleteModalClose}
      />
    </>
  );
};
export default ActionsMenu;
