import { useState } from 'react';

import { gql, useApolloClient, useLazyQuery } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import {
  getAutodeskGlueFolders as GET_AUTODESK_GLUE_FOLDERS,
  getAutodeskGlueSelectedFolderIdStack as GET_AUTODESK_GLUE_SELECTED_FOLDER_ID_STACK
} from 'graphql/queries';
import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import FoldersSelectDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown';
import { getLatestFolders } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';

const {
  NO_CACHE: { fetchPolicy: NO_CACHE_FETCH_POLICY }
} = FETCH_POLICIES;

const GlueFoldersDropdown = (props) => {
  const {
    connectionId,
    nodeId,
    isSource = false,
    isLoadingFolders = false,
    disabled = false,
    onFolderStackLoad = () => {},
    fetchMoreRootFolders,
    folders
  } = props;
  const client = useApolloClient();
  const [isInitialStackFetched, setIsInitialStackFetched] = useState(false);
  const { getConnectorNodeValues, destinations, updateConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId, defaultObject: destinations[0] });
  const setFormValues = (props) => updateConnectorNodeValues({ isSource, nodeId, props });
  const { project, folder, folders: uiFolders } = formValues;

  const [loadGlueFolderStack, { loading: isLoadingGlueFolderStack }] = useLazyQuery(
    gql(GET_AUTODESK_GLUE_SELECTED_FOLDER_ID_STACK)
  );

  const fetchGlueFolders = async ({
    folderId,
    fetchPolicy = NO_CACHE_FETCH_POLICY,
    skip = 0,
    take = DROPDOWN_DEFAULT_PG_SIZE
  }) => {
    const { data } = await client.query({
      query: gql(GET_AUTODESK_GLUE_FOLDERS),
      variables: {
        query: {
          connectionId,
          projectId: project?.id,
          parentFolderId: folderId,
          skip,
          take
        }
      },
      fetchPolicy
    });

    const response = data?.getAutodeskGlueFolders || [];
    return response;
  };

  const fetchMoreFoldersHandler = async ({ isRoot = false, folderId, skip, onCompleted }) => {
    if (!isRoot) {
      const autodeskGlueFolders = await fetchGlueFolders({ folderId, skip });
      onCompleted(autodeskGlueFolders);
      return autodeskGlueFolders;
    }
    await fetchMoreRootFolders(skip, { connectionId, projectId: project?.id });
    return onCompleted(getLatestFolders(folders));
  };

  const onFolderExpandHandler = async ({ folderId, onCompleted, skip, take }) => {
    const autodeskGlueFolders = await fetchGlueFolders({ folderId, skip, take });
    return onCompleted(autodeskGlueFolders);
  };

  const onFolderSelectHandler = ({ onCompleted }) => onCompleted();

  const onFolderDropdownClick = async () => {
    if (folder?.id && !isInitialStackFetched) {
      await loadGlueFolderStack({
        variables: {
          query: { connectionId, projectId: project?.id, selectedFolderId: folder?.id }
        },
        onCompleted: async (response) => {
          const { getAutodeskGlueSelectedFolderIdStack } = response;
          let expandedFolderIds = [...(getAutodeskGlueSelectedFolderIdStack || [])]?.reverse();
          expandedFolderIds.push(folder.id);
          expandedFolderIds = [...new Set(expandedFolderIds)];
          const closestParentFolderId = expandedFolderIds[expandedFolderIds?.length - 2] || '';

          await setFormValues({
            uiFolders: uiFolders?.map((item) => ({ ...item, parentId: closestParentFolderId })),
            expandedFolderIds
          });
          onFolderStackLoad?.(closestParentFolderId);
          setIsInitialStackFetched(true);
        }
      });
    }
  };

  return (
    <FoldersSelectDropdown
      {...props}
      isLoading={isLoadingFolders}
      disabled={isLoadingFolders || disabled}
      isLoadingFolderStack={isLoadingGlueFolderStack}
      isInitialStackFetched={isInitialStackFetched}
      onFolderExpandHandler={onFolderExpandHandler}
      onFolderSelectHandler={onFolderSelectHandler}
      onFolderDropdownClick={onFolderDropdownClick}
      fetchMoreFoldersHandler={fetchMoreFoldersHandler}
    />
  );
};

export default GlueFoldersDropdown;
