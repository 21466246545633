import { Checkbox, CircularProgress, MenuItem } from '@mui/material';

import CustomTooltip from 'components/Tooltip';
import { useFolderDropdownContext } from 'modules/Sync/WorkflowEditor/Contexts/FolderDropdownContext';
import { usePublishActionFormContext } from 'modules/Sync/WorkflowEditor/Contexts/PublishActionContext';
import { actionColors } from 'styles/theme/colors';

const FolderRowMenuLabel = ({
  nodeId,
  folderId,
  isFolder = false,
  parentFolderId,
  parentFolderName,
  text,
  textFormatter,
  onFolderSelect,
  disabled,
  multiple = false,
  isChecked = false,
  isSource = false
}) => {
  const { isLoading, isLoadingFolderName } = useFolderDropdownContext();
  const { getConnectorNodeValues } = usePublishActionFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { folders: selectedFolders = [], folder } = formValues;
  const isSelectedFolder = selectedFolders?.[0]?.id === folderId || folder?.id === folderId;
  const formattedText = textFormatter(text);
  return (
    <MenuItem
      className="folder-row"
      value={text}
      sx={{
        justifyContent: 'left',
        backgroundColor: (isChecked || isSelectedFolder) && actionColors.lightSurface.focus,
        height: 35
      }}
      data-folder-id={folderId}
      data-text={formattedText}
      data-parent-id={parentFolderId}
      data-parent-name={parentFolderName}
      onClick={onFolderSelect}
      disabled={disabled}
    >
      {!isFolder && multiple && (
        <Checkbox
          checked={isChecked}
          color="warning"
          size="small"
          sx={{ padding: 0, marginRight: 1 }}
          onChange={onFolderSelect}
          inputProps={{
            'data-text': formattedText,
            'data-parent-id': parentFolderId,
            'data-parent-name': parentFolderName
          }}
        />
      )}
      <CustomTooltip title={text} placement="right" arrow disableHoverListener={formattedText.length < 40}>
        <div
          style={{
            maxWidth: 350,
            whiteSpace: 'nowrap',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          data-text={formattedText}
        >
          {formattedText}
        </div>
      </CustomTooltip>

      {(isLoading || isLoadingFolderName) && <CircularProgress size={15} color="warning" sx={{ marginLeft: 1 }} />}
    </MenuItem>
  );
};

export default FolderRowMenuLabel;
