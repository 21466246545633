import React from 'react';

import { Breadcrumbs, Button, ButtonGroup, Stack, Typography, Link, CircularProgress } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Custom, IconStyled } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import { ROUTES } from 'modules/Sync/Constants/constants';
import DownloadLogFile from 'modules/Sync/Logs/components/DownloadLogFile';
import { getLogFileName } from 'modules/Sync/Logs/DownloadLogsService';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { getSyncUserReadableState } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';
import { getSyncIcon, IconType } from 'modules/Sync/WorkflowEditor/helpers/SyncIcons';
import { actionColors, surfaceColors, syncColors } from 'styles/theme/colors';

const STACK_PROPS = {
  spacing: 10,
  direction: 'row',
  justifyContent: 'space-between',
  style: { position: 'relative', zIndex: 9 }
};

const getSyncStatusBtn = (isSyncRunning, isSyncButtonHovered, isSyncButtonDisabled) => {
  if (isSyncRunning) {
    return isSyncButtonHovered ? (
      <div>
        <IconStyled icon={<Custom.StopFilled />} color={syncColors.statusColors.syncButtonInPause} />
      </div>
    ) : (
      <div>
        <IconStyled icon={<Custom.Stop />} color={syncColors.statusColors.syncButtonInPause} />
      </div>
    );
  }

  return (
    <NavItemTooltip title={getSyncUserReadableState(isSyncRunning)} placement="bottom">
      <div style={{ height: '30px' }}>
        <IconStyled
          icon={
            <Custom.SmartDisplay
              color={
                isSyncButtonHovered || isSyncButtonDisabled
                  ? syncColors.statusColors.syncButtonDefaultHover
                  : syncColors.statusColors.syncButtonDefault
              }
            />
          }
          style={{ borderRadius: 4 }}
        />
      </div>
    </NavItemTooltip>
  );
};

const WorkflowEditorToolbar = ({
  onSaveAction,
  onDeleteAction,
  onSaveTemplateAction,
  onSyncAction,
  onRunAction,
  workflowData = {},
  isArchived
}) => {
  const { isSyncRunning, workflowMappings, workflowElements, isInQueue, mappingId } = useSyncWorkflowEditorContext();
  const [isSyncButtonHovered, setIssyncButtonHovered] = React.useState(false);
  const { workflowName, workflowId, mapping: { mappingName } = {} } = workflowData || {};
  const disableAllActions = workflowElements.length < 1;
  const disableMappingSyncAction = workflowMappings.length < 1 || isInQueue;
  const disableDeleteAction = isSyncRunning || disableAllActions || isInQueue;
  const disableSaveAction = false; /* TODO: Save should not be disabled on empty canvas, but instead when there's no change */
  const disableTemplateAction = disableAllActions;
  const solitaryFlows = workflowMappings.map(({ solitaryFlowId = '', actionAlias = '' }) => ({ solitaryFlowId, actionAlias }));

  const handleMouseEnter = () => setIssyncButtonHovered(true);
  const handleMouseLeave = () => setIssyncButtonHovered(false);

  return (
    <>
      <Stack {...STACK_PROPS}>
        <CustomBreadCrumbs
          workflowId={workflowId}
          workflowName={workflowName}
          mappingName={mappingName}
          isArchived={isArchived}
          primaryColor={syncColors.canvasNavButtonColors.breadcrumbPrimary}
          secondaryColor={syncColors.canvasNavButtonColors.breadcrumbSecondary}
        />
        {!isArchived && (
          <ButtonGroup sx={{ width: '25em', justifyContent: 'space-evenly', alignItems: 'center' }}>
            {!!solitaryFlows.length && (
              <DownloadLogFile
                solitaryFlows={solitaryFlows}
                fileName={getLogFileName({ workflowName, mappingName })}
                zipName={getLogFileName({ workflowName, mappingName, mappingId, extension: '.zip' })}
                placement="left"
                syncInProgress
                tooltipInfo="Download latest logs for all actions"
              />
            )}
            <CustomButton
              onClick={onSaveTemplateAction}
              disabled={disableTemplateAction}
              icon={getSyncIcon(IconType.TEMPLATE)}
              label="TEMPLATE"
              color={syncColors.canvasNavButtonColors.navButton}
            />
            <CustomButton
              onClick={onSaveAction}
              disabled={disableSaveAction}
              icon={<Custom.Save />}
              label="SAVE"
              color={syncColors.canvasNavButtonColors.navButton}
            />
            <CustomButton
              onClick={onDeleteAction}
              disabled={disableDeleteAction}
              icon={<Custom.DeleteOutline />}
              label="DELETE"
              color={syncColors.canvasNavButtonColors.navButton}
            />

            <NavItemTooltip title={getSyncUserReadableState(isSyncRunning)} placement="bottom">
              <Button
                variant="text"
                disabled={disableMappingSyncAction}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}
                onClick={isSyncRunning ? onRunAction : onSyncAction}
                sx={{ padding: '0px' }}
              >
                {getSyncStatusBtn(isSyncRunning, isSyncButtonHovered, disableMappingSyncAction)}
              </Button>
            </NavItemTooltip>
          </ButtonGroup>
        )}
      </Stack>
      {!isArchived && isSyncRunning && (
        <Stack
          gap={2}
          direction="row"
          sx={{
            m: 1,
            position: 'absolute',
            right: 50,
            zIndex: 8,
            backgroundColor: surfaceColors.darkSurface.primaryOpacity70
          }}
        >
          <CircularProgress color="secondary" size={35} />
          <Typography variant="body1">Syncing in progress...</Typography>
        </Stack>
      )}
    </>
  );
};

export default WorkflowEditorToolbar;

const CustomBreadCrumbs = ({ workflowId, workflowName, mappingName, primaryColor, secondaryColor, isArchived }) => (
  <Breadcrumbs separator="/" aria-label="breadcrumb">
    <Link
      underline="none"
      key="1"
      color={primaryColor}
      component={RouterLink}
      to={!isArchived ? `${ROUTES.SYNC}${ROUTES.WORKFLOWS}` : `${ROUTES.SYNC}${ROUTES.WORKFLOWS}?activeTab=2`}
    >
      Workflows
    </Link>
    ,
    <Link
      key={1}
      underline="none"
      color="text.primary"
      component={RouterLink}
      to={
        !isArchived
          ? `${ROUTES.SYNC}${ROUTES.WORKFLOWS}?id=${workflowId}`
          : `${ROUTES.SYNC}${ROUTES.WORKFLOWS}?id=${workflowId}&activeTab=2`
      }
    >
      <Typography key={workflowName} color={primaryColor}>
        {workflowName}
        {!workflowName && <CircularProgress color="secondary" size={10} />}
      </Typography>
    </Link>
    ,
    <Typography key={mappingName} color={secondaryColor}>
      {mappingName}
      {!mappingName && <CircularProgress color="secondary" size={10} />}
    </Typography>
  </Breadcrumbs>
);

const CustomButton = ({ disabled, onClick, icon, label, color }) => (
  <Button
    variant="text"
    startIcon={<IconStyled icon={icon} color={disabled ? actionColors.lightSurface.disabled : '#373737'} />}
    onClick={onClick}
    disabled={disabled}
    sx={{
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      height: '30px',
      borderRadius: '4px',
      textTransform: 'uppercase',
      color
    }}
  >
    {label}
  </Button>
);
