import { Box, CircularProgress, IconButton } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { Waypoint } from 'react-waypoint';

import { Custom } from 'components/Icons';
import { isNavigationKey } from 'helpers/common';
import { syncStatusIndicator } from 'modules/Sync/Logs/LogsDataGridTable/LogsDataGridTableGroupingCell';
import { actionColors, surfaceColors } from 'styles/theme/colors';

const RowExpansionIcon = ({ isExpanded = false, isLoading = false }) =>
  isLoading ? (
    <CircularProgress size="1rem" color="secondary" sx={{ mr: 1 }} />
  ) : (
    <Custom.ArrowDropDownIcon
      sx={{
        transform: isExpanded ? 'none' : 'rotate(-90deg)',
        fill: isExpanded ? surfaceColors.lightSurface.primary : actionColors.lightSurface.active
      }}
    />
  );

const RowExpandToggleBtn = ({ handleClick, handleKeyDown, row, isExpanded = false, isLoading = false }) => (
  <>
    {!row?.isFile && (
      <IconButton
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={-1}
        size="small"
        className="expansion-btn"
        disableRipple={isLoading}
        disabled={row.descendantCount <= 0}
      >
        <Box className="expansion-icon" display="flex">
          <RowExpansionIcon isExpanded={isExpanded} isLoading={isLoading} />
        </Box>
      </IconButton>
    )}
  </>
);

const RealTimeLogDetailsDataGridGroupingCell = (props) => {
  const { id, field, row, rowNode, lastParentRowId, onWaypointEnter } = props;
  const { fileName = '' } = row || {};
  const apiRef = useGridApiContext();
  const isLoading = rowNode.childrenExpanded ? !row.childrenFetched : false;

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent('cellNavigationKeyDown', props, event);
    }
  };

  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <>
      {row?.logStatus && syncStatusIndicator(row?.logStatus)}
      {fileName && row?.fileStatus && syncStatusIndicator(row?.fileStatus, { ml: 2, mr: -2 })}

      <Box sx={{ ml: rowNode.depth * 2 }}>
        <RowExpandToggleBtn
          handleClick={handleClick}
          handleKeyDown={handleKeyDown}
          row={row}
          isExpanded={rowNode?.childrenExpanded}
          isLoading={isLoading && row?.isParent}
        />
        {id === lastParentRowId && <Waypoint key={lastParentRowId} bottomOffset="-20%" onEnter={onWaypointEnter} />}
      </Box>
    </>
  );
};

export default RealTimeLogDetailsDataGridGroupingCell;
