import useSyncFlowNowApiAction from 'modules/Sync/WorkflowEditor/apiActions/syncFlowNow';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';

const useSyncNowAction = () => {
  const { mappingId } = useSyncWorkflowEditorContext();
  const { syncFlowNow } = useSyncFlowNowApiAction({
    refetchQueries: ['SyncGetWorkflowMappingConfiguration']
  });

  const syncNowAction = (id, solitaryFlowId) =>
    syncFlowNow({
      mappingId,
      solitaryFlowId
    });
  return { syncNowAction };
};

export default useSyncNowAction;
