// import awsmobile from './aws-exports';

// This helper allows us to dynamically load env variables from the env.json file,
//   so that we can control this app without rebuilding and deploying the app.
// This also allows us to promote the same build of the app to multiple different environments.

// Memoize the env variables so that we can ensure the file only loads once.
let envVariables;
const getEnvVars = async () => {
    if (envVariables) return envVariables;

    // set defaults from the compiled configuration files
    envVariables = {
        REACT_APP_ENV: process.env.REACT_APP_ENV,
        REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
        REACT_APP_FORESITE_FIELD_UI_URL: process.env.REACT_APP_FORESITE_FIELD_UI_URL,
        REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID: process.env.REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
        REACT_APP_AMPLIFY_AUTH_USER_POOL_ID: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
        REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID
        // AWS_PROJECT_REGION: awsmobile.aws_project_region,
        // AWS_APPSYNC_GRAPHQL_ENDPOINT: awsmobile.aws_appsync_graphqlEndpoint,
        // AWS_APPSYNC_REGION: awsmobile.aws_appsync_region,
        // AWS_APPSYNC_AUTHENTICATION_TYPE: awsmobile.aws_appsync_authenticationType,
        // AWS_COGNITO_IDENTITY_POOL_ID: awsmobile.aws_cognito_identity_pool_id,
        // AWS_COGNITO_REGION: awsmobile.aws_cognito_region,
        // AWS_USER_POOLS_ID: awsmobile.aws_user_pools_id,
        // AWS_USER_POOLS_WEB_CLIENT_ID: awsmobile.aws_user_pools_web_client_id
    }

    // if an env.json file is present, overwrite the environment variables with these values
    const root = window.location.origin;
    const url = `${root.replace('foresite.', 'foresite-sync.')}/env.json`;
    const response = await fetch(url);
    // const response = await fetch('/env.json');
    if (response.ok) {
        const ev = await response.json();
        envVariables = { ...envVariables, ...ev };
    }

    return envVariables;
}

export default getEnvVars;
