import { getShadowDocument } from 'helpers/domHelpers';
import { DEFAULT_ROOT_FOLDER, DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

export const onFolderClickEvent = (event, folderId, mainMethod, precedentMethod, antecedentMethod) => {
  event.preventDefault();
  event.stopPropagation();
  precedentMethod?.();
  mainMethod?.({ folderId, onCompleted: antecedentMethod, skip: 0 });
};

export const createNewFolderRowItem = (event, folderId, mainMethod, onCompleted) => {
  const shadowDocument = getShadowDocument();
  event.preventDefault();
  event.stopPropagation();
  const folderName = shadowDocument.getElementById('create-folder')?.value;
  mainMethod?.({ folderId, folderName, onCompleted });
};

export const multiSelectFolders = ({
  event,
  id,
  folders,
  uiFolders,
  children,
  parentFolderId,
  setParentFolderId,
  updateFormValues,
  overrideIsSubmitted
}) => {
  const childrenIds = children?.map((item) => item.id);
  const currentParentFolderId = event.target.getAttribute('data-parent-id') || '';
  const folderName = event.target.getAttribute('data-text');
  let selectedFolders = folders;
  const isSelected = uiFolders?.some(({ id: folderId }) => id === folderId);

  if (isSelected) {
    setParentFolderId(currentParentFolderId);
    const filteredFolders = uiFolders?.filter(
      ({ id: folderId, parentId }) =>
        folderId !== id && !childrenIds?.includes(folderId) && parentId === currentParentFolderId
    );

    const filteredIds = filteredFolders?.map((item) => item.id);
    const filteredUiFolders = uiFolders?.filter(
      ({ id: folderId, parentId }) =>
        (filteredIds?.includes(folderId) || filteredIds?.includes(parentId)) && folderId !== id
    );
    return updateFormValues({
      folders: filteredFolders,
      uiFolders: filteredUiFolders,
      folder: filteredFolders[0],
      isSubmitted: !!filteredFolders?.length
    });
  }

  selectedFolders =
    parentFolderId === currentParentFolderId
      ? selectedFolders?.concat({ id, name: folderName, parentId: currentParentFolderId })
      : [{ id, name: folderName, parentId: currentParentFolderId }];

  const selectedUIFolders =
    parentFolderId === currentParentFolderId
      ? uiFolders?.concat({ id, name: folderName, parentId: currentParentFolderId })
      : [{ id, name: folderName, parentId: currentParentFolderId }];

  setParentFolderId(currentParentFolderId);

  return updateFormValues({
    folders: selectedFolders,
    folder: selectedFolders[selectedFolders.length - 1],
    uiFolders: selectedUIFolders,
    isSubmitted: typeof overrideIsSubmitted === 'undefined' ? true : overrideIsSubmitted
  });
};

export const getFoldersSelectDropdownEventHandlers = ({
  permitFolderCreationForIds,
  expandableFolders,
  updateFormValues,
  isSource,
  formValues,
  expandedFolderIds,
  setIsFolderDropdownOpen,
  isFolderDropdownOpen,
  onFolderDropdownClick,
  selectDropdownRef,
  setHasMoreRootFolders,
  fetchMoreFoldersHandler,
  rootParentFolderId,
  folders
}) => {
  const getExpandableFolders = (id) => permitFolderCreationForIds.includes(id) || expandableFolders;

  const setExpandedFolderIds = (folderIds) =>
    updateFormValues(isSource ? { ...formValues, expandedFolderIds: folderIds } : { expandedFolderIds: folderIds });

  const onFolderRowItemExpansion = (folderId) => setExpandedFolderIds([...new Set([...expandedFolderIds, folderId])]);

  const onFolderRowItemCollapse = (folderId) =>
    setExpandedFolderIds(expandedFolderIds.filter((id) => id !== folderId || id === DEFAULT_ROOT_FOLDER.ID));

  const closeFolderDropdownHandler = () => setIsFolderDropdownOpen(false);

  const clickFolderDropdownHandler = async () => {
    if (!isFolderDropdownOpen) {
      onFolderDropdownClick();
      setIsFolderDropdownOpen(true);
    }
  };

  const onBlurFolderDropdownHandler = (event) => {
    if (!selectDropdownRef?.current?.contains(event.target)) closeFolderDropdownHandler();
  };

  const onFoldersFetchMore = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const onCompleted = (folders) => setHasMoreRootFolders(!!(folders?.length === DROPDOWN_DEFAULT_PG_SIZE));
    await fetchMoreFoldersHandler({
      isRoot: true,
      folderId: rootParentFolderId,
      skip: folders?.length || 0,
      onCompleted
    });
  };

  return {
    getExpandableFolders,
    onFolderRowItemExpansion,
    onFolderRowItemCollapse,
    closeFolderDropdownHandler,
    clickFolderDropdownHandler,
    onBlurFolderDropdownHandler,
    onFoldersFetchMore
  };
};
