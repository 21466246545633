import React from 'react';

import DropdownFormControl from 'modules/Sync/WorkflowEditor/Utils/DropdownFormControl';

const ForesiteProjectDropdown = ({ value, onChange, loading, disabled, items, ...props }) => (
  <DropdownFormControl
    title="Project"
    id="project"
    labelId="project"
    label="project"
    value={value}
    onChange={onChange}
    loading={loading}
    disabled={disabled}
    items={items}
    required
    {...props}
  />
);

export default ForesiteProjectDropdown;
