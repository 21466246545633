import React from 'react';

import { CancelButton, CreateButton } from 'components';
import { syncColors } from 'styles/theme/colors';

const ModalFooter = ({
  disableSubmit,
  connectorsSubmitDisabled,
  workflowsSubmitDisabled,
  onClose,
  onBack,
  onSubmit,
  onNext,
  onSkip,
  activeStep
}) => {
  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === 2;

  const buttonText = 'Save & Sync';
  return (
    <>
      <CancelButton
        onClick={onClose}
        color={syncColors.text}
        sx={{
          '&:hover': { backgroundColor: 'secondary.light' },
          '&:disabled': { borderColor: 'action.lightSurface.disabled' }
        }}
      />
      {isFirstStep && (
        <CreateButton
          buttonText="Skip"
          onClick={onSkip}
          variant="outlined"
          sx={{
            '&.MuiButton-root': {
              color: syncColors.text,
              '&:disabled': { color: 'action.lightSurface.disabled' }
            }
          }}
        />
      )}

      {!isFirstStep && (
        <CreateButton
          buttonText="Previous"
          onClick={onBack}
          variant="outlined"
          sx={{
            '&.MuiButton-root': {
              color: syncColors.text,
              '&:disabled': { color: 'action.lightSurface.disabled' }
            }
          }}
        />
      )}
      {!isLastStep && (
        <CreateButton
          buttonText="Next"
          onClick={onNext}
          disabled={isFirstStep ? connectorsSubmitDisabled : workflowsSubmitDisabled}
          variant="outlined"
          sx={{
            '&.MuiButton-root': {
              color: syncColors.text,
              '&:disabled': { color: 'action.lightSurface.disabled' }
            }
          }}
        />
      )}
      {isLastStep && <CreateButton buttonText={buttonText} onClick={onSubmit} disabled={disableSubmit} />}
    </>
  );
};

export default ModalFooter;
