import { useEffect } from 'react';

const useConnectionPropertiesHooks = ({
  reset,
  formDefaultValues,
  currentConnection,
  dataSource,
  setValue,
  setFormConfiguration
}) => {
  useEffect(() => {
    reset(formDefaultValues(currentConnection));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConnection]);

  useEffect(() => {
    if (
      dataSource &&
      dataSource !== currentConnection?.connectionType &&
      !currentConnection?.connectionId
    ) {
      setValue('configuration', setFormConfiguration({ connectionType: dataSource }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);
};

export default useConnectionPropertiesHooks;
