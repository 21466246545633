import React from 'react';

import Typography from '@mui/material/Typography';

import CustomModal from 'modules/Sync/components/CustomModal';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';

const DeleteCanvasNodeModal = ({ open, onClose, onSubmit }) => (
  <CustomModal
    disableModalOutsideClick
    open={open}
    onClose={onClose}
    title="Confirm delete"
    reducePadding
    titleStyles={{ fontWeight: 'bold' }}
    maxWidth="false"
    footer={
      <ModalFooter
        onModalClose={onClose}
        cancelBtnText="Cancel"
        createBtnText="Confirm"
        onSubmit={onSubmit}
        isDelete
      />
    }
  >
    <Typography variant="body1" mb="12px" sx={{ width: '500px', mb: '20px' }}>
      Are you sure you want to delete this node from the canvas?
    </Typography>
  </CustomModal>
);

export default DeleteCanvasNodeModal;
