import React, { useCallback, useState } from 'react';

import { Button, MenuItem, Typography, useTheme } from '@mui/material';

import CustomMenu from 'components/Menu';
import CustomArrowDropDownIcon from 'modules/Sync/components/CustomArrowDropDownIcon';
import { WEBHOOK_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWebhooksContext } from 'modules/Sync/Webhooks/Contexts/WebhooksContext';
import { syncColors } from 'styles/theme/colors';

const WorkflowActionsMenu = () => {
  const { setActiveModal, selectedWebhookIds, webhooks } = useSyncWebhooksContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const updateAnchorEl = useCallback((element) => setAnchorEl(element), []);
  const open = !!anchorEl;

  const showDeleteModal = () => {
    setActiveModal(WEBHOOK_MODAL_KEYS.DELETE_WEBHOOK);
  };
  const showActivateModal = () => {
    setActiveModal(WEBHOOK_MODAL_KEYS.ACTIVATE_WEBHOOK);
  };
  const showDeactivateModal = () => {
    setActiveModal(WEBHOOK_MODAL_KEYS.DEACTIVATE_WEBHOOK);
  };

  const handleClick = (e) => {
    updateAnchorEl(e.currentTarget);
  };
  const handleClose = () => updateAnchorEl(null);

  const handleDeleteModalOpen = (e) => {
    e.stopPropagation();
    showDeleteModal();
    handleClose();
  };

  const handleActivateModalOpen = (e) => {
    e.stopPropagation();
    showActivateModal();
    handleClose();
  };
  const handleDeactivateModalOpen = (e) => {
    e.stopPropagation();
    showDeactivateModal();
    handleClose();
  };

  const activatedWebhookSelected =
    selectedWebhookIds?.some((id) =>
      webhooks?.syncWebhooks?.find(({ webhookId, isActive }) => webhookId === id && isActive)
    ) || false;
  const deactivatedWebhookSelected =
    selectedWebhookIds?.some((id) =>
      webhooks?.syncWebhooks?.find(({ webhookId, isActive }) => webhookId === id && !isActive)
    ) || false;

  return (
    <>
      <ActionButton handleClick={handleClick} />
      <CustomMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        color="warning"
        MenuListProps={{
          sx: { width: '160px' }
        }}
      >
        <MenuItem
          onClick={handleActivateModalOpen}
          disabled={activatedWebhookSelected || selectedWebhookIds.length < 1}
        >
          <Typography>Activate</Typography>
        </MenuItem>
        <MenuItem
          onClick={handleDeactivateModalOpen}
          disabled={deactivatedWebhookSelected || selectedWebhookIds.length < 1}
        >
          <Typography>Deactivate</Typography>
        </MenuItem>
        <MenuItem onClick={handleDeleteModalOpen} disabled={selectedWebhookIds.length < 1}>
          <Typography color="error">Delete</Typography>
        </MenuItem>
      </CustomMenu>
    </>
  );
};

const ActionButton = ({ handleClick }) => {
  const {
    palette: { secondary }
  } = useTheme();
  return (
    <Button
      variant="outlined"
      size="small"
      endIcon={<CustomArrowDropDownIcon />}
      onClick={handleClick}
      color="warning"
      sx={{
        padding: '0 8px 0 8px',
        height: '32px',
        fontWeight: 500,
        border: `solid 1px ${syncColors.outlinedRestingBorder}`,
        color: syncColors.text,
        background: `linear-gradient(90deg, transparent 67%, ${secondary.outlinedHoverBackground} 33%)`
      }}
    >
      ACTIONS
    </Button>
  );
};
export default WorkflowActionsMenu;
