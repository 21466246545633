import React, { useMemo } from 'react';

import { Box, FormControl } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FormProvider, useForm } from 'react-hook-form';

import { CenteredLoadSpinner } from 'components';
import CardItem from 'components/CardItem';
import InputSelectField from 'components/FormComponents/InputSelectField';
import Header from 'components/Header';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';

import { ActionControl } from './FormFields';
import {
  connectionComponents,
  connectionDefaultForms,
  connectionOptions,
  getConnectionDropDownValue
} from './FormOptions';
import useConnectionPropertiesHooks from './useConnectionPropertiesHooks';
import ConnectionName from '../components/ConnectionName';

const INPUT_SELECT_PROPS = {
  color: 'secondary',
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
  margin: 'normal'
};

const setFormConfiguration = (selectedConnection) => {
  const getConnectionTypeDefaultForm =
    connectionDefaultForms[selectedConnection?.connectionType] || (() => {});
  return getConnectionTypeDefaultForm(selectedConnection);
};

const formDefaultValues = (selectedConnection) => {
  if (!selectedConnection) return null;
  selectedConnection.configuration = selectedConnection?.configuration || {};
  const dataSource = selectedConnection?.connectionType
    ? getConnectionDropDownValue(selectedConnection?.connectionType)
    : '';

  return {
    dataSource,
    connectionName: selectedConnection?.connectionName || '',
    configuration: setFormConfiguration(selectedConnection)
  };
};

const ConnectionProperties = () => {
  const { currentConnection, connectorsList } = useSyncConnectionsContext();
  const methods = useForm({ mode: 'all', defaultValues: formDefaultValues(currentConnection) });
  const {
    control,
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors
  } = methods;
  const { dataSource } = watch();
  const isNewForm = !currentConnection?.connectionId;
  const licensedConnectionOptions = connectionOptions.filter(({ id }) =>
    connectorsList?.syncConnectorsList?.some((connector) => connector.name.includes(id))
  );

  useConnectionPropertiesHooks({
    reset,
    formDefaultValues,
    currentConnection,
    dataSource,
    setValue,
    setFormConfiguration
  });

  const SelectedConnection = React.useMemo(() => {
    const SelectedElement = dataSource
      ? connectionComponents[dataSource]
      : connectionComponents[currentConnection?.connectionType];
    return SelectedElement && React.isValidElement(<SelectedElement />) ? (
      <Box marginTop={3}>
        <SelectedElement
          key={currentConnection?.connectionId}
          actionRenderer={({ onSubmit, submitDisabled, loading, customRenderer }) => (
            <>
              <ActionControl
                isNewForm={isNewForm}
                onSubmit={handleSubmit(onSubmit)}
                isSubmitDisabled={submitDisabled}
                customRenderer={customRenderer}
              />
              {loading && <CenteredLoadSpinner />}
            </>
          )}
          dataSource={dataSource}
        />
      </Box>
    ) : (
      <>Please select a data source</>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, currentConnection]);

  const isDataSourceValid = useMemo(
    () => !!licensedConnectionOptions.some(({ id }) => dataSource === id),
    [dataSource, licensedConnectionOptions]
  );

  return (
    <FormProvider {...methods}>
      <Header title="Connector Properties" />
      <CardItem containerProps={{ sx: { height: 'min-content' } }} showHeader={false}>
        <Stack>
          <Title>General</Title>
          <Stack direction="row" gap="16px">
            <InputSelectField
              key={dataSource}
              name="dataSource"
              label="Data Source*"
              control={control}
              disabled={!isNewForm && currentConnection.isConnectionUsed}
              options={licensedConnectionOptions}
              selectProps={{ SelectProps: { displayEmpty: true }, autoFocus: !dataSource }}
              onSelectChange={() => {
                setValue('configuration', setFormConfiguration({ connectionType: dataSource }));
              }}
              {...INPUT_SELECT_PROPS}
            />

            {isDataSourceValid && (
              <FormControl fullWidth sx={{ mt: '16px', mb: '8px' }}>
                <ConnectionName
                  connectionId={currentConnection?.connectionId}
                  autoFocus={isDataSourceValid}
                  control={control}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  defaultValue={currentConnection?.connectionName}
                />
              </FormControl>
            )}
          </Stack>
          {SelectedConnection}
        </Stack>
      </CardItem>
    </FormProvider>
  );
};
export default ConnectionProperties;

const Title = (props) => (
  <Typography variant="formBoldLabel" sx={{ margin: 0, lineHeight: 0.5 }} {...props} />
);
