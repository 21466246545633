import React from 'react';

import { Grid } from '@mui/material';

import { requiredRule } from 'constants/inputFieldRules';
import {
  ControlTextField,
  SelectField
} from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { glueServerOptions } from 'modules/Sync/Constants/constants';

const GlueInputs = ({ control, disableAllFields }) => {
  const { currentConnection } = useSyncConnectionsContext();

  return (
    <Grid item container justifyContent="space-between" spacing={2}>
      <Grid item xs={6}>
        <ControlTextField
          key={currentConnection?.connectionId}
          control={control}
          requiredNoSpace
          name="configuration.glue.glueCompanyId"
          label="Glue Company ID*"
          disabled={disableAllFields}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectField
          key={currentConnection?.connectionId}
          control={control}
          rules={requiredRule('Glue Server*', '*Required')}
          name="configuration.glue.glueServer"
          label="Glue Server*"
          options={glueServerOptions}
          defaultValue={glueServerOptions[0]}
          disabled={disableAllFields}
        />
      </Grid>
    </Grid>
  );
};

export default GlueInputs;
