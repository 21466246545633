import { gql, useMutation } from '@apollo/client';

import { syncCreateTemplateFromMapping as SYNC_CREATE_TEMPLATE_FROM_MAPPING } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useCreateTemplateApiAction = (queryOptions) => {
  const [saveMappingTemplate, { loading }] = useMutation(
    gql(SYNC_CREATE_TEMPLATE_FROM_MAPPING),
    queryOptions
  );
  const { handleResponse } = useGraphqlResponseHandler();

  const createTemplate = ({
    mappingId,
    name,
    onCompleted,
    successMessage = 'Mapping successfully saved as template'
  }) =>
    handleResponse(
      saveMappingTemplate,
      { variables: { body: { MappingId: mappingId, Name: name } } },
      { successMessage },
      onCompleted
    );

  return { createTemplate, loading };
};

export default useCreateTemplateApiAction;
