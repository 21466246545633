import React from 'react';

import { CircularProgress } from '@mui/material';

import { CreateButton } from 'components';
import { Custom } from 'components/Icons';
import OutlinedButton from 'modules/Sync/components/OutlinedButton';

const RecommendTimeButton = ({ isTimeSet, setIsTimeSet, onClick, disabled, loadingRecommendTime }) => {
  const timeSetHandler = () => setIsTimeSet(true);
  const buttonStyles = {
    fontSize: '13px',
    height: '24px',
    marginLeft: 0,
    marginTop: '16px'
  };

  return (
    <>
      {!isTimeSet && (
        <CreateButton
          buttonText="RECOMMEND BEST TIME"
          onClick={() => onClick(timeSetHandler)}
          sx={{ ...buttonStyles }}
          disabled={disabled}
          startIcon={loadingRecommendTime && <CircularProgress style={{ width: 14, height: 14, marginRight: 5 }} />}
        />
      )}

      {isTimeSet && (
        <OutlinedButton
          icon={<Custom.CheckCircleOutlinePrimary style={{ width: 18, height: 18, marginRight: 5 }} />}
          label="Recommended best time chosen"
          sx={{ width: 'auto', textTransform: 'none', ...buttonStyles }}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default RecommendTimeButton;
