import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { CreateButton } from 'components';
import { syncColors } from 'styles/theme/colors';

export const generateRandomIdForLoadMoreRow = () => `placeholder-children-${uuidv4()}`;

const LoadMoreBtn = ({ onClick }) => (
  <Box my={2} sx={{ display: 'flex', justifyContent: 'center' }} width="100%">
    <CreateButton
      buttonText="Load More Files"
      onClick={onClick}
      variant="text"
      sx={{
        '&.MuiButton-root': {
          width: '100%',
          color: syncColors.text,
          '&:hover': { backgroundColor: 'transparent' },
          '&:disabled': { color: 'action.lightSurface.disabled' }
        }
      }}
    />
  </Box>
);

export default LoadMoreBtn;
