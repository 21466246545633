import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';
import useSaveFlowConfigApiAction from 'modules/Sync/WorkflowEditor/apiActions/publishFileSaveFlowConfig';
import { usePublishActionFormContext } from 'modules/Sync/WorkflowEditor/Contexts/PublishActionContext';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import useSaveMappingAction from 'modules/Sync/WorkflowEditor/editorHooks/useSaveMappingAction';
import { publishActionActionPayload } from 'modules/Sync/WorkflowEditor/helpers/PublishActionHelpers/publishActionPayloadHelper';

const AUTO_HIDE_DURATION = 2500;
const ERROR_MSG = 'Please specify all the required configurations';
const SUCCESS_MSG = 'Saving...';
const ON_SUBMIT_SNACKBAR_KEY = uuidv4();

export const useProceedToNextConnector = () => {
  const { activeStep, setActiveStep, setConnectorNodes } = usePublishActionFormContext();

  const onNextAction = (prevSource, callBack = () => {}) => {
    if (activeStep === 0) {
      setConnectorNodes((nodes) => [...nodes.map((node) => node)]);
    }
    callBack?.();
    setActiveStep(activeStep + 1);
  };

  return { onNextAction };
};

export const useSubmitMapping = () => {
  const { action, activeFlow, source, syncSettings, includedExtensions, setConnectorNodes } =
    usePublishActionFormContext();
  const { actionId, mappingId, workflowMappings, setWorkflowMappings, setIsMappingDataSaved } =
    useSyncWorkflowEditorContext();
  const actionAlias = action?.data?.actionAlias || '';
  const { enqueueSnackbar, closeSnackbar } = useSnackbar() || {};
  const [isSaved, setIsSaved] = useState(false);
  const { solitaryFlowId = '' } = activeFlow || {};
  const { saveFlowConfig } = useSaveFlowConfigApiAction({
    refetchQueries: ['SyncConnections', 'SyncGetWorkflowMappingConfiguration']
  });
  const { saveMappingAction } = useSaveMappingAction();

  const saveFlowData = (mappingFlows) =>
    saveFlowConfig({
      mappingId,
      mappingFlows,
      onCompleted: () => {
        setTimeout(() => {
          saveMappingAction({
            successMessage: 'Workflow successfully saved',
            onCompleted: () => {
              closeSnackbar(ON_SUBMIT_SNACKBAR_KEY);
              setIsMappingDataSaved(true);
            },
            onFailed: () => {
              closeSnackbar(ON_SUBMIT_SNACKBAR_KEY);
              setIsMappingDataSaved(false);
            }
          }); /* Trigger canvas save */
        }, 1000);
      },
      onFailed: () => closeSnackbar(ON_SUBMIT_SNACKBAR_KEY),
      successMessage: ''
    });

  const onSubmitAction = async () => {
    if (!source.isSubmitted) {
      return enqueueSnackbar(ERROR_MSG, { autoHideDuration: AUTO_HIDE_DURATION, ...VARIANT_ERROR });
    }

    await setConnectorNodes((nodes) => [
      ...nodes.map((node) => {
        const newNode = node;
        return newNode;
      })
    ]);

    await setWorkflowMappings((currentMappings) => [
      ...currentMappings.filter((mapping) => mapping.actionNodeId !== actionId),
      publishActionActionPayload({
        actionNodeId: actionId,
        actionAlias: currentMappings.find((mapping) => mapping.actionNodeId === actionId)?.actionAlias,
        solitaryFlowId,
        isSyncEnabled: syncSettings?.isSyncEnabled,
        syncType: syncSettings?.syncType,
        scheduleSyncConfig: syncSettings?.scheduleSyncConfig,
        includeExtensions: includedExtensions,
        source
      })
    ]);

    setIsSaved(true);

    return enqueueSnackbar(SUCCESS_MSG, {
      key: ON_SUBMIT_SNACKBAR_KEY,
      persist: true,
      ...VARIANT_SUCCESS
    });
  };

  useEffect(() => {
    const currentFlow = workflowMappings?.filter(({ actionNodeId }) => actionNodeId === actionId);

    if (currentFlow.length && isSaved) {
      currentFlow[0].actionAlias = actionAlias;
      saveFlowData(currentFlow);
      setIsSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowMappings, isSaved]);

  return { onSubmitAction };
};
