/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useStoreState } from 'react-flow-renderer';
import { useLocation } from 'react-router-dom';

import { MoreMenu } from 'components';
import { Custom, IconStyled } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import { VARIANT_ERROR, VARIANT_INFO } from 'constants/snackbarConstants';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import {
  CANVAS_NODE_TYPES,
  ISSUE_MANAGEMENT_DOWNLOAD,
  DISABLE_CANVAS_BULK_LOG_DONWLOAD,
  LOG_FILE_DOWNLOAD_BATCH_SIZE,
  MAPPING_ACTIONS
} from 'modules/Sync/Constants/constants';
import { NavigateAwayModal } from 'modules/Sync/Logs/components/DownloadLogFile';
import DownloadSyncLogsModal from 'modules/Sync/Logs/components/DownloadSyncLogsModal';
import { getLogFileName, getLogsFromAPI, saveLogFileToPC } from 'modules/Sync/Logs/DownloadLogsService';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { actionNodeStyles } from 'modules/Sync/WorkflowEditor/FlowchartNodes/nodeStyles';
import { getActionNodesWithEdges } from 'modules/Sync/WorkflowEditor/helpers/CopyFilesHelpers/connectionsHelper';
import { getFilteredNodes, getNodeOpacity } from 'modules/Sync/WorkflowEditor/helpers/getStyledWorkflowElementHelpers';
import { getSyncTypeConfig, isTemplateCanvas } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';
import { getSyncIcon, IconType } from 'modules/Sync/WorkflowEditor/helpers/SyncIcons';
import DeleteCanvasNodeModal from 'modules/Sync/WorkflowEditor/Modals/DeleteCanvasNodeModal';
import DownloadNowConfirmationModal from 'modules/Sync/WorkflowEditor/Modals/DownloadNowConfirmatonModal';
import SyncNowConfirmationModal from 'modules/Sync/WorkflowEditor/Modals/SyncNowConfirmationModal';
import UnmapActionNodeModal from 'modules/Sync/WorkflowEditor/Modals/UnmapActionNodeModal';
import UploadNowConfirmationModal from 'modules/Sync/WorkflowEditor/Modals/UploadNowConfirmationModal';
import { surfaceColors, syncColors } from 'styles/theme/colors';

import { NodeHandles } from './NodeHandles';

const AUTO_HIDE_DURATION = 2500;
const ERROR_MSG = "Connected Nodes Can't be deleted";
const { manageIssues: MANAGE_ISSUES_ACTION, publish: PUBLISH_ACTION } = MAPPING_ACTIONS;

const getSelectedElements = ({ workflowElements, id }) => {
  const {
    id: actionId = '',
    data: { actionAlias = '' } = {},
    ...rest
  } = workflowElements?.find(({ id: elementId }) => elementId === id) || {};

  return { actionId, actionAlias, rest };
};

const ActionNode = ({ data, id }) => {
  const { onDelete, onUnMap, onEdit, onSyncNow, onDownloadNow, onUploadNow } = data;
  const { workflowMappings, workflowElements, isSetOpacity, isInQueue, currentMapping } =
    useSyncWorkflowEditorContext();
  const {
    showActionAliasModal,
    setActionAlias,
    setActionId,
    actionNodesInSync,
    filterType: filter
  } = useSyncWorkflowEditorContext();
  const [progress, setProgress] = useState(0);
  const edges = useStoreState((store) => store.edges);
  const { enqueueSnackbar } = useSnackbar() || {};
  const queryParameters = new URLSearchParams(window.location.search);
  const { pathname: templatePathname } = useLocation();
  const pathname = queryParameters.get('isArchived');
  const connectedConnectorNodes = getActionNodesWithEdges(edges);
  const isConnectedNode = connectedConnectorNodes.includes(id);
  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);
  const [isOpenDeleteNodeModal, setDeleteNodeModalVisibility] = React.useState(false);
  const [isOpenUnmapNodeModal, setUnmapNodeModalVisibility] = React.useState(false);
  const [isOpenSyncNowModal, setSyncNowModalVisibility] = React.useState(false);
  const [isOpenDownloadNowModal, setDownloadNowModalVisibility] = React.useState(false);
  const [isOpenUploadNowModal, setUploadNowModalVisibility] = React.useState(false);
  const showDeleteNodeModal = () => setDeleteNodeModalVisibility(true);
  const hideDeleteNodeModal = () => setDeleteNodeModalVisibility(false);
  const showUnmapNodeModal = () => setUnmapNodeModalVisibility(true);
  const hideUnmapNodeModal = () => setUnmapNodeModalVisibility(false);
  const showSyncNowModal = () => setSyncNowModalVisibility(true);
  const hideSyncNowModal = () => setSyncNowModalVisibility(false);
  const showDownloadModal = () => setIsOpenDownloadModal(true);
  const hideDownloadModal = () => setIsOpenDownloadModal(false);
  const showDownloadNowModal = () => setDownloadNowModalVisibility(true);
  const hideDownloadNowModal = () => setDownloadNowModalVisibility(false);
  const showUploadNowModal = () => setUploadNowModalVisibility(true);
  const hideUploadNowModal = () => setUploadNowModalVisibility(false);
  const isTemplate = React.useMemo(() => isTemplateCanvas(templatePathname), [templatePathname]);
  const isArchivedTab = pathname !== null;
  const { solitaryFlowId = '', action } = workflowMappings?.find(({ actionNodeId }) => actionNodeId === id) || {};
  const isPublishAction = action === PUBLISH_ACTION;
  const isManageIssuesAction = action === MANAGE_ISSUES_ACTION;

  const isArchivedNode = workflowMappings.filter((node) => node.actionNodeId === id);
  const { isArchived, isDeactivated } = isArchivedNode?.[0] || {};
  const isActionInSync = actionNodesInSync.includes(id);
  let logsFetched = [];
  const { actionId, actionAlias, rest } = getSelectedElements({ workflowElements, id });
  const {
    mapping: { mappingName, isDeactivated: isDeactivatedMapping },
    workflowName
  } = currentMapping;

  const selectedNodes = getFilteredNodes({ filter, workflowMappings });
  const { opacity } = getNodeOpacity({ selectedNodes, id, filter, isConnectedNode, ...rest });
  const isBlurred = opacity < 1;

  const deleteNode = () => {
    if (!isConnectedNode) {
      onDelete(id);
      return hideDeleteNodeModal();
    }
    return enqueueSnackbar(ERROR_MSG, { autoHideDuration: AUTO_HIDE_DURATION, ...VARIANT_ERROR });
  };
  const unmapNode = () => {
    onUnMap(id, solitaryFlowId);
    return hideUnmapNodeModal();
  };
  const editNode = () => onEdit(id, rest?.actionType);
  const onSync = () => {
    onSyncNow(id, solitaryFlowId);
    return hideSyncNowModal();
  };
  const onDownload = () => {
    onDownloadNow(solitaryFlowId, false);
    return hideDownloadNowModal();
  };
  const onUpload = () => {
    onUploadNow(solitaryFlowId, true);
    return hideUploadNowModal();
  };

  const onEditAlias = () => {
    setActionId(actionId);
    setActionAlias(actionAlias);
    showActionAliasModal();
  };

  const downloadLatestLogs = async (variables = {}, take = LOG_FILE_DOWNLOAD_BATCH_SIZE) => {
    delete variables?.fileSyncLogId;
    setProgress(1);
    enqueueSnackbar(`Starting the download`, {
      autoHideDuration: AUTO_HIDE_DURATION,
      ...VARIANT_INFO
    });
    const { entireCount = 0, data = null } =
      (await getLogsFromAPI({ solitaryFlowId, ...variables }, true, logsFetched.length, true, take)) || {};

    if (!data?.length) {
      setProgress(0);
      return enqueueSnackbar(`No logs found for this action`, {
        autoHideDuration: AUTO_HIDE_DURATION,
        ...VARIANT_ERROR
      });
    }

    const logsData = data?.map(({ logMessage }) => logMessage);
    logsFetched = [...logsFetched, ...logsData];

    const fetchedLogsLength = logsFetched?.length;
    if (entireCount / fetchedLogsLength > 1) {
      setProgress((100 * fetchedLogsLength) / entireCount);
      return downloadLatestLogs(variables, fetchedLogsLength);
    }

    setProgress(0);
    return saveLogFileToPC(logsFetched, getLogFileName({ workflowName, mappingName, solitaryFlowId }));
  };

  const syncLabel = isPublishAction ? 'Publish now' : 'Sync now';
  const editOption = { label: 'Edit', clickEvent: editNode, disabled: isInQueue };
  const downloadOption = { label: 'Download issues', clickEvent: showDownloadNowModal };
  const uploadOption = { label: 'Upload issues', clickEvent: showUploadNowModal };
  const syncOption = {
    label: syncLabel,
    clickEvent: showSyncNowModal,
    disabled: !isConnectedNode || !solitaryFlowId || isInQueue
  };
  const renameOption = { label: 'Rename', clickEvent: onEditAlias };
  const deleteOption = {
    label: 'Delete',
    color: '#F44336',
    clickEvent: showDeleteNodeModal,
    disabled: isConnectedNode
  };
  const unmapOption = {
    label: 'Unmap',
    clickEvent: showUnmapNodeModal,
    disabled: !isConnectedNode || !solitaryFlowId || isInQueue,
    color: 'red'
  };
  const downloadLatestLogOption = {
    label: 'Download Latest Log',
    clickEvent: () => showDownloadModal()
  };
  const downloadLast10LogOption = {
    label: 'Download Last 10 Log',
    clickEvent: () => downloadLatestLogs({}, 10),
    disabled: !isConnectedNode || !solitaryFlowId || isInQueue
  };

  const showOptions = ((isConnectedNode && !isActionInSync) || !isConnectedNode) && !isArchived && !isBlurred;
  const options = showOptions
    ? [
        ...(isDeactivatedMapping && !isConnectedNode ? [] : [editOption]),
        ...(isTemplate || !isConnectedNode || !solitaryFlowId || !isManageIssuesAction
          ? []
          : [downloadOption, uploadOption]),
        ...(isTemplate || !isConnectedNode || !solitaryFlowId || isDeactivated || isManageIssuesAction
          ? []
          : [syncOption]),
        ...(!isConnectedNode || !solitaryFlowId || isInQueue ? [] : [downloadLatestLogOption]),
        ...(DISABLE_CANVAS_BULK_LOG_DONWLOAD ? [] : [downloadLast10LogOption]),
        renameOption,
        ...(isConnectedNode ? [] : [deleteOption]),
        ...(!isConnectedNode || !solitaryFlowId ? [] : [unmapOption])
      ]
    : [];

  return (
    <>
      <DeleteCanvasNodeModal open={isOpenDeleteNodeModal} onClose={hideDeleteNodeModal} onSubmit={deleteNode} />
      <UnmapActionNodeModal open={isOpenUnmapNodeModal} onClose={hideUnmapNodeModal} onSubmit={unmapNode} />
      <SyncNowConfirmationModal open={isOpenSyncNowModal} onClose={hideSyncNowModal} onSubmit={onSync} />
      <DownloadSyncLogsModal
        onClose={hideDownloadModal}
        downloadFile={downloadLatestLogs}
        logId={solitaryFlowId}
        open={isOpenDownloadModal}
      />
      <NavigateAwayModal showDialog={progress} />
      <DownloadNowConfirmationModal
        open={isOpenDownloadNowModal}
        onClose={hideDownloadNowModal}
        onSubmit={onDownload}
      />
      <UploadNowConfirmationModal open={isOpenUploadNowModal} onClose={hideUploadNowModal} onSubmit={onUpload} />
      <Node
        id={actionId}
        data={data}
        workflowMappings={workflowMappings}
        options={options}
        isArchived={isArchived}
        isArchivedTab={isArchivedTab}
        isSetOpacity={isSetOpacity}
        isTemplate={isTemplate}
        isManageIssuesAction={isManageIssuesAction}
        progress={progress}
      />
    </>
  );
};

const Node = ({
  id,
  data,
  workflowMappings,
  options,
  isArchived,
  isArchivedTab,
  isSetOpacity,
  isTemplate,
  isManageIssuesAction,
  progress = 0
}) => {
  const windowsTabOpacity = isArchived ? 0.1 : 1;
  const { isSyncEnabled, syncType } =
    workflowMappings?.find(({ actionNodeId }) => actionNodeId === id)?.source?.settings || {};
  const { action } = workflowMappings?.find(({ actionNodeId }) => actionNodeId === id) || {};
  const {
    label,
    alternateLabel,
    icon: syncIcon,
    color: syncColor,
    backgroundColor: syncBackgroundColor
  } = getSyncTypeConfig(syncType, isSyncEnabled && !isTemplate, action) || {};
  let syncStatus = alternateLabel || label;
  const defaultNodeColor = syncColor || syncColors.canvasNodeColors.default;

  let nodeSyncIcon = syncIcon;
  if (isManageIssuesAction) {
    const isDownload = syncType === ISSUE_MANAGEMENT_DOWNLOAD;
    nodeSyncIcon = isDownload ? <Custom.DownloadIssues /> : <Custom.UploadIssues />;
    syncStatus = isDownload ? 'Download' : 'Upload';
  }

  const actionIcon = data?.icon || IconType.ACTION;

  return (
    <Box
      sx={{
        ...actionNodeStyles.nodeContainer,
        borderColor: defaultNodeColor,
        backgroundColor: syncBackgroundColor || syncColors.canvasNodeColors.defaultBackground,
        opacity: !isSetOpacity && !isArchivedTab && windowsTabOpacity && action !== MANAGE_ISSUES_ACTION,
        display: isArchivedTab && !isArchived && 'none',
        '&:hover': { '& .MuiSvgIcon-root': { opacity: 1 } }
      }}
    >
      <div style={actionNodeStyles.flexBox}>
        <div style={{ ...actionNodeStyles.iconContainer, backgroundColor: 'white' }}>
          <div style={actionNodeStyles.whiteSquare}>
            {!!progress && (
              <CircularProgress
                variant="indeterminate"
                size={35}
                value={progress}
                sx={{ position: 'absolute', top: 8, left: 4, zIndex: 1, color: defaultNodeColor }}
              />
            )}
            <IconStyled
              icon={getSyncIcon(actionIcon)}
              color={defaultNodeColor}
              sx={{ height: 20, width: 20, svg: { height: 20, width: 20 } }}
            />
          </div>
        </div>
        <div style={actionNodeStyles.robotoText}>
          <div style={{ ...actionNodeStyles.title, color: defaultNodeColor }}>{CANVAS_NODE_TYPES.ACTION}</div>
          <div style={actionNodeStyles.taskDetails.parent}>
            <div style={actionNodeStyles.taskDetails.child}>
              <CustomSyncTooltip title={data.actionAlias || data?.taskDetail} sx={{ maxWidth: 120 }}>
                {data.actionAlias || data?.taskDetail}
              </CustomSyncTooltip>
            </div>
          </div>
          <div style={{ width: 38, height: 46, marginBottom: 6, color: surfaceColors.lightSurface.primary }}>
            <div>
              <div style={{ whiteSpace: 'nowrap' }} />
            </div>
          </div>
        </div>
        {isSyncEnabled && !isTemplate && (
          <NavItemTooltip title={syncStatus} placement="top">
            <div style={{ position: 'fixed', right: 4, top: 2 }}>{nodeSyncIcon}</div>
          </NavItemTooltip>
        )}

        {!!options.length && (
          <MoreMenu style={actionNodeStyles.optionsMenuOnBottom} options={options} sx={{ opacity: 0 }} />
        )}
      </div>
      <NodeHandles />
    </Box>
  );
};

export default ActionNode;
