import React from 'react';

import Typography from '@mui/material/Typography';

import CustomModal from 'modules/Sync/components/CustomModal';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';

const DEFAULT_TITLE =
  'Are you sure you want to sync files between the source & destination(s) immediately?';

const SyncNowConfirmationModal = ({ open, onClose, onSubmit, title = DEFAULT_TITLE }) => (
  <CustomModal
    disableModalOutsideClick
    open={open}
    onClose={onClose}
    title="Confirm sync"
    reducePadding
    titleStyles={{ fontWeight: 'bold' }}
    maxWidth="false"
    footer={
      <ModalFooter
        onModalClose={onClose}
        cancelBtnText="Cancel"
        createBtnText="Confirm"
        onSubmit={onSubmit}
      />
    }
  >
    <Typography variant="body1" mb="12px" sx={{ width: '500px', mb: '20px' }}>
      {title}
    </Typography>
  </CustomModal>
);

export default SyncNowConfirmationModal;
