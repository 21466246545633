import React from 'react';

import client from 'apollo/client';
import { convertToCamelCase } from 'helpers/stringFunctions';
import { CONNECTION_TYPES, DRAWER_NAV_MENU_ITEMS } from 'modules/Sync/Constants/constants';
import AutodeskForge from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/Forge';
import Windows from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Windows/Windows';

export const formatMappings = (connectorDropDownOptions) => {
  if (!connectorDropDownOptions) return [];
  return connectorDropDownOptions.map((options) => ({
    value: options.id,
    name: options.data.connectorAlias || options.data?.taskDetail,
    componentToRender: (props) => {
      const type = options.data.connectionType;
      return (
        <>
          {type === CONNECTION_TYPES.AUTODESKTEAMDOCS && (
            <AutodeskForge
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.AUTODESKTEAMDOCS}
            />
          )}
          {type === CONNECTION_TYPES.WINDOWS && (
            <Windows
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.WINDOWS}
            />
          )}
        </>
      );
    }
  }));
};

export const getAvailableConnections = (nodes, isSource = false) => {
  const requiredConnection = isSource ? CONNECTION_TYPES.AUTODESKTEAMDOCS : CONNECTION_TYPES.WINDOWS;

  const connections = nodes.filter((node) => node.data.connectionType === requiredConnection);

  const sortedConnections = connections?.sort((item1, item2) =>
    item1?.data?.connectorAlias?.localeCompare(item2?.data?.connectorAlias)
  );

  return sortedConnections;
};

export const getConnectorNodesWithEdges = (edges) =>
  edges.reduce((nodes, { source, target }) => {
    if (source.includes(DRAWER_NAV_MENU_ITEMS.CONNECTOR)) {
      nodes.push(source);
    }
    if (target.includes(DRAWER_NAV_MENU_ITEMS.CONNECTOR)) {
      nodes.push(target);
    }
    return nodes;
  }, []);

export const getActionNodesWithEdges = (edges) =>
  edges.reduce((nodes, { source, target }) => {
    if (source.includes(DRAWER_NAV_MENU_ITEMS.ACTION)) {
      nodes.push(source);
    }
    if (target.includes(DRAWER_NAV_MENU_ITEMS.ACTION)) {
      nodes.push(target);
    }
    return nodes;
  }, []);

export const getSyncQueriesForRefetch = (connections) => {
  const queries = [];

  if (connections.includes(CONNECTION_TYPES.AUTODESKHQ) || connections.includes(CONNECTION_TYPES.AUTODESKTEAMDOCS)) {
    queries.push('GetAutodeskForgeHubs', 'GetAutodeskForgeProjects', 'GetAutodeskForgeSubItems');
  }

  if (connections.includes(CONNECTION_TYPES.WINDOWS)) {
    queries.push('SyncGetWindowsFolders');
  }
  return queries;
};

export const getSyncFolderQueriesForConnection = (connections) => {
  const queries = [];

  if (connections.includes(CONNECTION_TYPES.WINDOWS)) queries.push('SyncGetWindowsFolders');
  return queries;
};

export const refreshConnections = async ({ workflowMappings }) => {
  if (workflowMappings?.length) {
    const connectionsToRefresh = [
      ...new Set(
        ...workflowMappings?.map(({ source, destinations }) => [
          source?.connection?.type,
          ...destinations?.map(({ connection: { type } = {} }) => type)
        ])
      )
    ];

    getSyncQueriesForRefetch(connectionsToRefresh).forEach(async (query) => {
      await client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: convertToCamelCase(query)
      });
    });
  }
};
