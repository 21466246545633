const TemplatesDataGridTableColumns = (handleCellComponent) => [
  {
    field: 'templateName',
    headerName: 'Templates',
    flex: 100,
    hideable: false,
    editable: false,
    renderCell: handleCellComponent
  }
];

export default TemplatesDataGridTableColumns;
