import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { MoreMenu } from 'components';
import { Custom, IconStyled } from 'components/Icons';
import { ROUTES } from 'modules/Sync/Constants/constants';
import { actionColors, syncColors } from 'styles/theme/colors';

const TemplatesDataGridCell = ({ id, field, row, options, onWaypointEnter }) => {
  if (field.toLowerCase().includes('name')) {
    return (
      <Link
        underline="none"
        key={row.name}
        component={RouterLink}
        to={`${ROUTES.SYNC}${ROUTES.TEMPLATE_EDITOR.replace(':id', id)}`}
      >
        <Typography variant="body2" sx={{ display: 'flex' }} color={syncColors.text}>
          <IconStyled
            sx={{ marginRight: 1.5, alignItems: 'center', display: 'flex' }}
            color={actionColors.lightSurface.active}
            icon={<Custom.Template width="20px" height="20px" />}
          />
          {row.name}
        </Typography>
        {row?.isLast && <Waypoint key={id} bottomOffset="-20%" onEnter={onWaypointEnter} />}
      </Link>
    );
  }
  if (id !== 0 && field === '*options') {
    return (
      <MoreMenu
        options={options}
        sx={{ fontSize: 21, opacity: 0, ':hover': { opacity: 1 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    );
  }

  return row[field] || null;
};

export default TemplatesDataGridCell;
