import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import Tooltip from 'components/Tooltip';

const ControlledTooltip = ({ open, onOpen, onClose, title, children, sx = {}, placement = 'top', boxProps = {} }) => (
  <Tooltip
    open={open}
    onOpen={onOpen}
    onClose={onClose}
    title={
      title && (
        <Typography fontWeight="500" fontSize={10} lineHeight="16px" sx={{ ...sx }}>
          {title}
        </Typography>
      )
    }
    placement={placement}
    arrow
  >
    <Box
      component="div"
      sx={{
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...sx
      }}
      {...boxProps}
    >
      {children}
    </Box>
  </Tooltip>
);

export default ControlledTooltip;
