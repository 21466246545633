import { useCallback } from 'react';

import { Checkbox, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { getMicroFeContainer } from 'helpers/domHelpers';

const DropdownFormControl = ({
  title = '',
  id = '',
  labelId = '',
  label = '',
  value = '',
  onChange = null,
  loading = false,
  disabled = false,
  multiple = false,
  items = [],
  required = false,
  enabledWhileLoading = false,
  fetchPaginatedRecords,
  ...rest
}) => {
  const handleScroll = useCallback(
    (event) => {
      const selectElement = event.target;
      if (selectElement) {
        const isAtBottom = selectElement.scrollTop + selectElement.clientHeight === selectElement.scrollHeight;
        if (isAtBottom && fetchPaginatedRecords) {
          fetchPaginatedRecords();
        }
      }
    },
    [fetchPaginatedRecords]
  );

  return (
    <FormControl size="small" fullWidth color="secondary">
      <InputLabel id={id} disabled={loading}>
        {title}
        {required && '*'}
      </InputLabel>
      <Select
        labelId={labelId}
        id={id}
        label={`${label}${required ? '*' : ''}`}
        onChange={onChange}
        value={value}
        name={id}
        disabled={enabledWhileLoading ? disabled : disabled || loading}
        startAdornment={loading && <CircularProgress size={10} color="warning" sx={{ marginLeft: 1 }} />}
        MenuProps={{
          sx: {
            width: '200px',
            maxHeight: '400px',
            '& .MuiMenuItem-root': { whiteSpace: 'break-spaces' }
          },
          PaperProps: {
            onScroll: (event) => {
              handleScroll(event);
            }
          },
          container: getMicroFeContainer
        }}
        multiple={multiple}
        SelectDisplayProps={{ sx: { color: '#FF7A00' } }}
        renderValue={
          multiple &&
          ((selectedItemIds) => {
            const itemsToRender = items?.reduce((itemsToRender, item) => {
              if (selectedItemIds.includes(item.id)) {
                itemsToRender.push(item);
              }
              return itemsToRender;
            }, []);

            return itemsToRender?.length > 1
              ? `${itemsToRender.find(({ id }) => id === value[0])?.text} and ${itemsToRender.length - 1} other`
              : itemsToRender?.[0]?.text;
          })
        }
        {...rest}
      >
        {items?.map(({ id, text, disabled = false }) => (
          <MenuItem key={id} value={id} disabled={disabled} dataText={text}>
            {multiple && (
              <Checkbox
                color="warning"
                checked={value.indexOf(id) > -1}
                size="small"
                sx={{ margin: 0, marginRight: 1, padding: 0 }}
              />
            )}
            <span id={`dropdown-item-${id}`}>{text}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownFormControl;
