import React from 'react';

import { Badge, Typography } from '@mui/material';

const StatusBadge = ({ variant, label, sx = {}, isLate, ...props }) => {
  const finalVariant = isLate ? 'pastDaysRemaining' : variant;

  return (
    <Badge variant={finalVariant} sx={{ width: 'fit-content', borderRadius: '64px', ...sx }} {...props}>
      <Typography
        component="span"
        sx={{
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '20px',
          color: 'primary.contrastText',
          padding: '0 8px'
        }}
      >
        {label || '---'}
      </Typography>
    </Badge>
  );
};

export default StatusBadge;
