import { ACTION_CANVAS_NODE, CONNECTOR_CANVAS_NODE, filterTypes } from 'modules/Sync/Constants/constants';

export const getFilteredNodes = ({ filter, workflowMappings }) => {
  const filterArchive = filter === filterTypes.ARCHIVED;
  return workflowMappings
    .filter((item) => item.isArchived === filterArchive)
    .reduce((allNodes, { source, destinations, actionNodeId }) => {
      const sourceAndActionNodes = [actionNodeId, source.nodeId];
      const destinationNodes =
        destinations?.reduce(
          (selectedNodes, { nodeId }) => [...allNodes, ...selectedNodes, nodeId],
          sourceAndActionNodes
        ) || [];
      const mappingNodes = [...new Set([...destinationNodes, ...sourceAndActionNodes, ...allNodes])];
      return mappingNodes;
    }, []);
};

export const getNodeOpacity = ({ selectedNodes, id, filter, isConnectedNode, target, source }) => {
  if (!(isConnectedNode || filter === filterTypes.ARCHIVED)) return { opacity: 1 };
  const nodeRoots = [target, source];
  const isActiveNode =
    target && source
      ? nodeRoots.every((nodeId) => selectedNodes.includes(nodeId))
      : selectedNodes.some((nodeId) => id === nodeId);
  return {
    opacity: isActiveNode ? 1 : 0.1
  };
};

export const getStyledWorkflowElements = ({
  workflowElements,
  connectedActionNodes,
  connectedConnectorNodes,
  filter,
  workflowMappings
}) => {
  if (filter === filterTypes.ALL) return workflowElements.map((el) => ({ ...el, style: { opacity: 1 } }));

  const selectedNodes = getFilteredNodes({ filter, workflowMappings });

  return workflowElements.map(({ id, type, ...rest }) => {
    let isConnectedNode = true;
    if (type === ACTION_CANVAS_NODE) isConnectedNode = connectedActionNodes.includes(id);
    if (type === CONNECTOR_CANVAS_NODE) isConnectedNode = connectedConnectorNodes.includes(id);

    const nodeOpacity = getNodeOpacity({ selectedNodes, id, filter, isConnectedNode, ...rest });
    return {
      id,
      ...rest,
      style: nodeOpacity
    };
  });
};
