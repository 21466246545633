import {
  DEFAULT_ROOT_FOLDER,
  DOCUMENT_TYPE_FOLDER_ID,
  FOLDER_TYPES,
  DROPDOWN_DEFAULT_PG_SIZE,
  RFI_TYPE_FOLDER_ID,
  SUBMITTAL_TYPE_FOLDER_ID
} from 'modules/Sync/Constants/constants';
import { multiSelectFolders } from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown/folderTreeItemHelper';
import { setAdditionalData } from 'modules/Sync/WorkflowEditor/helpers/CopyFilesHelpers/copyFilesPayloadHelper';

export const getProcoreSelectEventHandlers = ({
  connectionId,
  folderTypes,
  setFolderTypes,
  updateFormValues,
  loadProjects,
  loadFolderType,
  folders,
  uiFolders,
  parentFolderId,
  setParentFolderId,
  isSource
}) => {
  const resetFolderTypes = () => setFolderTypes(FOLDER_TYPES);

  const selectCompanyHandler = (event, childElement) => {
    event.preventDefault();
    const hubId = event.target.value;
    updateFormValues({
      hub: { id: hubId, name: childElement?.props?.dataText },
      projects: [],
      project: { id: '', name: '' },
      folderType: '',
      folders: [],
      folder: { id: '', name: '' },
      isSubmitted: false
    });
    resetFolderTypes();
    loadProjects({ connectionId, companyId: hubId });
  };

  const selectProjectHandler = (event, childElement) => {
    event.preventDefault();
    const projectId = event.target.value;
    updateFormValues({
      projects: [{ id: projectId, name: childElement?.props?.dataText }],
      project: { id: projectId, name: childElement?.props?.dataText },
      folderType: '',
      folders: [],
      folder: { id: '', name: '' },
      isSubmitted: false
    });
    resetFolderTypes();
  };

  const selectFolderTypeHandler = async (event) => {
    event.preventDefault();
    const folderTypeId = event.target.value;
    let folder = {};
    let folders = [];
    if (folderTypeId === RFI_TYPE_FOLDER_ID || folderTypeId === SUBMITTAL_TYPE_FOLDER_ID) {
      const folderName = FOLDER_TYPES.find(({ id }) => folderTypeId === id)?.text || folderTypeId;
      folder = { id: 0, name: folderName };
      folders = [{ id: 0, name: folderName }];
    }

    const isSubmitted = FOLDER_TYPES.find(({ id, isFolder }) => folderTypeId === id && isFolder) || false;

    updateFormValues({
      folderType: folderTypeId,
      folders,
      folder,
      isSubmitted,
      additionalData: setAdditionalData({ folderType: folderTypeId })
    });

    const onCompleted = (response) => {
      const fetchedFolders = Object.values(response)?.[0];
      const isFolderTypeDisabled = !isSubmitted && !fetchedFolders?.length;
      const newFolderTypes = folderTypes.reduce((folderTypeAcc, currFolderType) => {
        if (currFolderType.id === folderTypeId) {
          currFolderType.disabled = isFolderTypeDisabled;
        }
        folderTypeAcc.push(currFolderType);
        return folderTypeAcc;
      }, []);

      setFolderTypes(newFolderTypes);
    };

    return isSubmitted ? onCompleted({ getProcoreFolders: [] }) : loadFolderType(folderTypeId, onCompleted);
  };

  const selectFolderHandler = (event, id, children) => {
    event.preventDefault();
    multiSelectFolders({
      event,
      id,
      folders,
      uiFolders,
      children,
      parentFolderId,
      setParentFolderId,
      updateFormValues,
      overrideIsSubmitted: isSource ? undefined : id !== DEFAULT_ROOT_FOLDER.ID
    });
  };
  return {
    selectCompanyHandler,
    selectProjectHandler,
    selectFolderTypeHandler,
    selectFolderHandler
  };
};

export const getFolderTypeLoader =
  ({ connectionId, hub, project, loadFolders, cacheCleanupCb = () => {} }) =>
  (folderType, onCompleteCallBack = () => {}, onErrorCallBack = () => {}, connectionOptions = {}) => {
    const gqlConnectionConfig = {
      connectionId,
      companyId: hub?.id,
      projectId: project?.id,
      skip: 0,
      take: DROPDOWN_DEFAULT_PG_SIZE
    };

    const queryParams = {
      params: { folderType }
    };

    const queryConfig = {
      onCompleted: (...args) => onCompleteCallBack(...args),
      onError: (...args) => onErrorCallBack(...args),
      ...connectionOptions
    };

    cacheCleanupCb?.();
    return folderType === DOCUMENT_TYPE_FOLDER_ID && loadFolders(gqlConnectionConfig, 0, queryParams, queryConfig);
  };
