import { useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import getClient from 'apollo/client';
import { FETCH_POLICIES } from 'constants/globalConstants';
import { VARIANT_INFO } from 'constants/snackbarConstants';
import { syncLogActionFolderDetails as SYNC_LOG_ACTION_FOLDER_DETAILS } from 'graphql/queries';
import { getTimeZone } from 'helpers/dateFunctions';
import OutlinedButton from 'modules/Sync/components/OutlinedButton';
import { DATAGRID_DEFAULT_PG_SIZE, LOG_FILTER, SYNC_LOGS } from 'modules/Sync/Constants/constants';
import { IsSyncFinished } from 'modules/Sync/helpers/logSyncStatusHelpers';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';

import { updateRows } from '../LogFoldersDetailsDataGridTable/logFolderDetailsDataGridHelpers';
import { formatFoldersToRows } from '../LogFoldersDetailsDataGridTable/LogFoldersDetailDataGridTableRows';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.NETWORK_ONLY;
const {
  LOG_FOLDER_TERMINAL_STATUS: { IN_PROGRESS: LOG_FOLDER_IN_PROGRESS_STATUS },
  LOG_ACTION_TERMINAL_STATUS: { IN_PROGRESS: LOG_MAPPING_IN_PROGRESS_STATUS }
} = SYNC_LOGS;
const AUTO_HIDE_DURATION = 2000;

const fetchRefreshedFolders = async ({ row, fileSyncLogId }) => {
  const { syncFlowMetricId, syncFolderMetricId, path: parentPath, level, terminalStatus } = row;
  const queryParams = { fileSyncLogId };

  if (terminalStatus !== LOG_FOLDER_IN_PROGRESS_STATUS || terminalStatus !== LOG_MAPPING_IN_PROGRESS_STATUS) return [];
  if (syncFolderMetricId) queryParams.syncFolderMetricId = syncFolderMetricId;
  if (syncFlowMetricId) queryParams.syncFlowMetricId = syncFlowMetricId;

  const client = await getClient();
  const { data: actionFolderDetailsData } = await client.query({
    query: gql(SYNC_LOG_ACTION_FOLDER_DETAILS),
    variables: { query: queryParams },
    fetchPolicy: FETCH_POLICY
  });
  const folders = actionFolderDetailsData?.syncLogActionFolderDetails || [];
  return formatFoldersToRows(folders, parentPath, level);
};

const RefreshLogsDetailsBtn = ({
  logDetails,
  loadLogActionDetails,
  refetchExistingLogsDetailsPages,
  logActionDetailsData,
  apiRef,
  expandedRows,
  loading = false,
  debugging = false,
  logFilter = LOG_FILTER.ALL
}) => {
  const { logsDetailsSearchPhrase } = useSyncLogsContext();
  const { syncLogActionDetails = {} } = logActionDetailsData || {};
  const logActionSyncStatus = syncLogActionDetails?.syncStatus;
  const { id: fileSyncLogId = '' } = useParams();
  const [refreshing, setRefreshingStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar() || {};
  const timeZone = useMemo(() => getTimeZone(), []);
  const disabled = refreshing || loading;

  const handleClick = async () => {
    setRefreshingStatus(true);

    if (IsSyncFinished(logActionSyncStatus)) {
      enqueueSnackbar('Sync is completed for this action', {
        autoHideDuration: AUTO_HIDE_DURATION,
        ...VARIANT_INFO
      });
      return setRefreshingStatus(false);
    }

    /* Refresh log folders */
    const currentCount = logDetails?.syncLogFileDetails?.length;
    const take = DATAGRID_DEFAULT_PG_SIZE;
    const skip = currentCount % take === 0 ? (currentCount / take - 1) * take : Math.floor(currentCount / take) * take;
    const query = {
      fileSyncLogId,
      skip,
      take,
      searchPhrase: logsDetailsSearchPhrase,
      IsDebug: !!debugging,
      timeZone,
      logFilter
    };
    refetchExistingLogsDetailsPages(query, true, 0);

    /* Refresh log file  */
    loadLogActionDetails({ variables: { query: { fileSyncLogId, logFilter } }, fetchPolicy: FETCH_POLICY });
    const fetchFolderData = async (row) => {
      const folders = await fetchRefreshedFolders({ row, fileSyncLogId, logFilter });
      return folders;
    };
    const fetchRefreshedFoldersForExpandedRows = expandedRows.map((row) => fetchFolderData(row));

    return Promise.all(fetchRefreshedFoldersForExpandedRows).then((rows) => {
      setRefreshingStatus(false);
      updateRows(apiRef, rows.flat());
    });
  };

  return (
    <OutlinedButton
      onClick={handleClick}
      disabled={disabled}
      sx={{ border: 'solid 1px', whiteSpace: 'nowrap', width: 171 }}
      labelProps={{
        disabled,
        color: disabled ? 'inherit' : 'secondary.main',
        sx: { cursor: disabled ? 'not-allowed' : 'pointer' }
      }}
      label="REFRESH LOGS"
      labelId="refreshLogs"
    />
  );
};

export default RefreshLogsDetailsBtn;
