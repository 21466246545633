import { useEffect } from 'react';

import { FOLDER_TYPES } from 'modules/Sync/Constants/constants';
import { getAdditionalData } from 'modules/Sync/WorkflowEditor/helpers/CopyFilesHelpers/copyFilesPayloadHelper';
import { getSubmittedStatus } from 'modules/Sync/WorkflowEditor/helpers/validationsHelper';

export const useProcoreMountHook = ({
  formValues,
  updateFormValues,
  connectionId,
  connectionType,
  nodeId,
  loadProjects,
  setFolderTypes,
  loadFolderType,
  fetchOnMountProcoreProjects,
  fetchOnMountProcoreFolders,
  fetchCompaniesOnLoad
}) => {
  const { hub, additionalData, folderType: localFolderType } = formValues;
  const { folderType } = getAdditionalData(additionalData) || localFolderType || {};
  const isSubmitted = getSubmittedStatus(formValues);

  useEffect(() => {
    updateFormValues({
      connectionId,
      connectionType,
      nodeId,
      folderType,
      isSubmitted
    });
    fetchCompaniesOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchOnMountProcoreProjects) {
      loadProjects({ connectionId, companyId: hub?.id });
    }
    if (fetchOnMountProcoreFolders) {
      const onCompleteCallback = () => setFolderTypes(FOLDER_TYPES);
      loadFolderType(folderType, onCompleteCallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnMountProcoreProjects, fetchOnMountProcoreFolders]);
};
