import { CONNECTION_TYPES, CONNECTION_KEY_LABEL_MAPPING } from 'modules/Sync/Constants/constants';

import Autodesk, { autodeskFormDefaultValues } from './Credentials/Autodesk';
import Foresite, { foresiteFormDefaultValues } from './Credentials/Foresite';
import Newforma, { newformaFormDefaultValues } from './Credentials/Newforma';
import Plangrid, { plangridFormDefaultValues } from './Credentials/Plangrid';
import Procore, { procoreFormDefaultValues } from './Credentials/Procore';
import Sharepoint, { sharepointFormDefaultValues } from './Credentials/Sharepoint';
import Windows, { windowsFormDefaultValues } from './Credentials/Windows';

export const connectionOptions = [
  {
    label: CONNECTION_KEY_LABEL_MAPPING[CONNECTION_TYPES.AUTODESK],
    id: CONNECTION_TYPES.AUTODESK,
    value: CONNECTION_TYPES.AUTODESK
  },
  {
    label: CONNECTION_KEY_LABEL_MAPPING[CONNECTION_TYPES.FORESITE],
    id: CONNECTION_TYPES.FORESITE,
    value: CONNECTION_TYPES.FORESITE
  },
  {
    label: CONNECTION_KEY_LABEL_MAPPING[CONNECTION_TYPES.NEWFORMA],
    id: CONNECTION_TYPES.NEWFORMA,
    value: CONNECTION_TYPES.NEWFORMA
  },
  {
    label: CONNECTION_KEY_LABEL_MAPPING[CONNECTION_TYPES.PLANGRID],
    id: CONNECTION_TYPES.PLANGRID,
    value: CONNECTION_TYPES.PLANGRID
  },
  {
    label: CONNECTION_KEY_LABEL_MAPPING[CONNECTION_TYPES.PROCORE],
    id: CONNECTION_TYPES.PROCORE,
    value: CONNECTION_TYPES.PROCORE
  },
  {
    label: CONNECTION_KEY_LABEL_MAPPING[CONNECTION_TYPES.SHAREPOINT],
    id: CONNECTION_TYPES.SHAREPOINT,
    value: CONNECTION_TYPES.SHAREPOINT
  },
  {
    label: CONNECTION_KEY_LABEL_MAPPING[CONNECTION_TYPES.WINDOWS],
    id: CONNECTION_TYPES.WINDOWS,
    value: CONNECTION_TYPES.WINDOWS
  }
];

export const connectionComponents = {
  [CONNECTION_TYPES.PROCORE]: Procore,
  [CONNECTION_TYPES.AUTODESK]: Autodesk,
  [CONNECTION_TYPES.AUTODESKFIELD]: Autodesk,
  [CONNECTION_TYPES.AUTODESKGLUE]: Autodesk,
  [CONNECTION_TYPES.AUTODESKHQ]: Autodesk,
  [CONNECTION_TYPES.AUTODESKTEAMDOCS]: Autodesk,
  [CONNECTION_TYPES.SHAREPOINT]: Sharepoint,
  [CONNECTION_TYPES.PLANGRID]: Plangrid,
  [CONNECTION_TYPES.NEWFORMA]: Newforma,
  [CONNECTION_TYPES.FORESITE]: Foresite,
  [CONNECTION_TYPES.WINDOWS]: Windows
};

export const connectionDefaultForms = {
  [CONNECTION_TYPES.PROCORE]: procoreFormDefaultValues,
  [CONNECTION_TYPES.AUTODESK]: autodeskFormDefaultValues,
  [CONNECTION_TYPES.AUTODESKFIELD]: autodeskFormDefaultValues,
  [CONNECTION_TYPES.AUTODESKGLUE]: autodeskFormDefaultValues,
  [CONNECTION_TYPES.AUTODESKHQ]: autodeskFormDefaultValues,
  [CONNECTION_TYPES.AUTODESKTEAMDOCS]: autodeskFormDefaultValues,
  [CONNECTION_TYPES.SHAREPOINT]: sharepointFormDefaultValues,
  [CONNECTION_TYPES.PLANGRID]: plangridFormDefaultValues,
  [CONNECTION_TYPES.NEWFORMA]: newformaFormDefaultValues,
  [CONNECTION_TYPES.FORESITE]: foresiteFormDefaultValues,
  [CONNECTION_TYPES.WINDOWS]: windowsFormDefaultValues
};

export const getConnectionDropDownValue = (connectionType) => {
  switch (connectionType) {
    case CONNECTION_TYPES.PROCORE:
      return CONNECTION_TYPES.PROCORE;
    case CONNECTION_TYPES.AUTODESK:
    case CONNECTION_TYPES.AUTODESKFIELD:
    case CONNECTION_TYPES.AUTODESKGLUE:
    case CONNECTION_TYPES.AUTODESKHQ:
    case CONNECTION_TYPES.AUTODESKTEAMDOCS:
      return CONNECTION_TYPES.AUTODESK;
    case CONNECTION_TYPES.SHAREPOINT:
      return CONNECTION_TYPES.SHAREPOINT;
    case CONNECTION_TYPES.PLANGRID:
      return CONNECTION_TYPES.PLANGRID;
    case CONNECTION_TYPES.NEWFORMA:
      return CONNECTION_TYPES.NEWFORMA;
    case CONNECTION_TYPES.FORESITE:
      return CONNECTION_TYPES.FORESITE;
    case CONNECTION_TYPES.WINDOWS:
      return CONNECTION_TYPES.WINDOWS;
    default:
      return connectionType;
  }
};
