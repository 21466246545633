import { useEffect, useMemo } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncGetAutodeskForgeFiltersList as SYNC_GET_AUTODESK_FORGE_FILTERS_LIST } from 'graphql/queries';
import AutocompleteDropdown from 'modules/Sync/components/AutocompleteDropdown';
import { MANAGE_ISSUES_FILTERS_KEYMAP } from 'modules/Sync/Constants/canvasActionConstants';
import {
  ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION,
  MAX_DROPDOWN_ITEMS_IN_VIEW
} from 'modules/Sync/Constants/constants';
import { useManageIssuesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/ManageIssuesContext';
import FilterTypeComponentToRender from 'modules/Sync/WorkflowEditor/Forms/ManageIssues/Autodesk/Forge/FilterTypeComponentToRender';
import { getNewFilterCriteria } from 'modules/Sync/WorkflowEditor/helpers/ManageIssuesHelpers/manageIssuesPayloadHelper';
import { validateFilterCriteria } from 'modules/Sync/WorkflowEditor/helpers/ManageIssuesHelpers/validationHelper';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;
const FILTERS_LIMIT = 2;

const ForgeFilterDropdown = ({ disabled = false, connectionId, nodeId, isSource, selectAll = false }) => {
  const { getConnectorNodeValues, updateConnectorNodeValues } = useManageIssuesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { hub, project, filterCriteria = [] } = formValues;

  const [
    lazyLoadFilters,
    { loading: isLoadingFilters, data: { syncGetAutodeskForgeFiltersList: filterOptions = [] } = {} }
  ] = useLazyQuery(gql(SYNC_GET_AUTODESK_FORGE_FILTERS_LIST), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const value = useMemo(() => {
    const currentFilters = filterCriteria?.map(({ property }) => ({
      label: MANAGE_ISSUES_FILTERS_KEYMAP[property],
      value: property
    }));

    if (filterCriteria?.length === filterOptions?.length)
      currentFilters.push(ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION);

    return currentFilters && filterOptions?.length ? currentFilters : [];
  }, [filterCriteria, filterOptions]);

  const options = useMemo(() => {
    const standardOptions =
      filterOptions?.map(({ title = '', id = '', isActive } = {}) => ({ value: id, label: title, isActive })) || [];
    const selectAllOption = ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION;
    return selectAll ? [selectAllOption, ...standardOptions] : standardOptions;
  }, [filterOptions, selectAll]);

  const onFilterChange = (_, selectedFilters, selectionType, selectedOption) => {
    const filterCriteriaValidate = selectedFilters.filter(
      ({ value }) => value !== ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION.value
    );
    const selectedAll =
      selectionType === 'selectOption' &&
      selectedOption?.option?.value === ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION.value;
    const deselectedAll =
      selectionType === 'removeOption' &&
      selectedOption?.option?.value === ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION.value;

    let selectedFiltersToUpdate = selectionType === 'removeOption' ? filterCriteriaValidate : selectedFilters;
    if (selectedAll) selectedFiltersToUpdate = options;
    if (deselectedAll) selectedFiltersToUpdate = [];

    const newFilterCriteria = getNewFilterCriteria(filterCriteria, selectedFiltersToUpdate);
    const props = {
      filterCriteria: newFilterCriteria,
      isSubmitted: validateFilterCriteria(newFilterCriteria)
    };
    updateConnectorNodeValues({ nodeId, isSource, props });
  };

  useEffect(() => {
    if (connectionId && hub?.id && project?.id && !disabled) {
      lazyLoadFilters({ variables: { query: { connectionId, hubId: hub?.id, projectId: project?.id } } });
    }
  }, [connectionId, disabled, hub?.id, lazyLoadFilters, project?.id]);

  return (
    <>
      <AutocompleteDropdown
        value={value}
        onChange={onFilterChange}
        options={options}
        label="Filter"
        limitTags={FILTERS_LIMIT}
        disabled={disabled}
        loading={isLoadingFilters}
        getOptionLabel={(option) => option.label}
        optional
      />
      {filterCriteria?.map(({ property, value }) => (
        <FilterTypeComponentToRender
          key={property}
          property={property}
          value={value}
          isSource={isSource}
          nodeId={nodeId}
          connectionId={connectionId}
          disabled={disabled || isLoadingFilters}
          selectAll
        />
      ))}
    </>
  );
};

export default ForgeFilterDropdown;
