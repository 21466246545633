import React, { useEffect, useState } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';

import { ControlledAutoComplete, ControlledInputCheckbox } from 'components';
import SimpleControlledAutoComplete from 'components/FormComponents/SimpleControlledAutoComplete';
import { emailRegEx } from 'constants/regEx';

// eslint-disable-next-line max-lines-per-function
export const ControlTextField = ({
  control,
  name,
  label,
  required,
  requiredNoSpace = false,
  email,
  disabled,
  maxLength = null,
  max = null,
  min = null,
  errors = {},
  errorMessages = {},
  sx = {},
  defaultValue,
  setError,
  clearErrors,
  validateChange,
  validationErrors,
  onClick = (event) => event.stopPropagation(),
  ...rest
}) => {
  const [searchNameDebounced, setSearchNameDebounced] = useState('');
  const [searchName, setSearchName] = useState(null);

  const getFirstError = (obj) => {
    const firstKey = Object.keys(obj)[0];
    // Get the value associated with the first key
    const firstValue = obj[firstKey] || null;
    // Return the first element from the value
    return Array.isArray(firstValue) ? firstValue[0] : 'An Error Occurred';
  };

  const {
    field: { onChange }
  } = useController({
    name,
    control,
    rules: {
      validate: (value) => {
        if (!validateChange) return true;

        if (!validationErrors || value === defaultValue) return null;

        if (searchName && validationErrors) {
          const errorObj = validationErrors[0]?.errorInfo;
          if (errorObj) {
            const error = getFirstError(errorObj);
            return error || 'Error';
          }
          return null;
        }

        return true;
      }
    }
  });

  const onChangeHandler = (event) => {
    const currentVal = event.target.value;
    setSearchNameDebounced(currentVal);
    onChange(currentVal);
  };

  const onKeyDown = (event) => event.stopPropagation();

  useEffect(() => {
    if (validateChange) {
      if (!searchName) return;
      // this section calls the API through prop
      validateChange(searchName);
    }
  }, [searchName, validateChange]);

  useEffect(() => {
    const timer = setTimeout(() => setSearchName(searchNameDebounced), 300);
    return () => clearTimeout(timer);
  }, [searchNameDebounced]);

  useEffect(() => {
    if (validateChange) {
      if (searchName && validationErrors && searchName !== defaultValue) {
        const errorObj = validationErrors[0]?.errorInfo;
        if (errorObj) {
          const error = getFirstError(errorObj);
          setError(name, { message: error || 'Error' });
          return;
        }
        clearErrors(name);
      }
      clearErrors(name);
    }
  }, [validationErrors, name, clearErrors, setError, searchName, defaultValue, validateChange]);

  return (
    <Box sx={{ position: 'relative' }}>
      <InputForesiteField
        name={name}
        control={control}
        label={label}
        disabled={disabled}
        size="small"
        color="secondary"
        rules={
          !disabled && {
            ...((required || requiredNoSpace) && {
              required: errorMessages?.required || '*Required'
            }),
            ...(email && {
              pattern: { value: emailRegEx, message: errorMessages?.email || `Invalid ${label}` }
            }),
            ...(min && { min: { value: min } }),
            ...(max && { max: { value: max } }),
            ...(maxLength && {
              maxLength: {
                value: maxLength,
                message: errorMessages?.maxLength || `${maxLength}/${maxLength}`
              }
            })
          }
        }
        helperText={typeof errors === 'string' ? errors : null}
        maxLength={maxLength}
        required={required}
        sx={{ '.MuiFormHelperText-root': { margin: 0 }, ...sx }}
        onChange={validateChange && onChangeHandler}
        onKeyDown={onKeyDown}
        onClick={onClick}
        {...rest}
      />
    </Box>
  );
};

export const ControlInput = ({ ...props }) => <InputForesiteField size="small" color="secondary" {...props} />;

export const SimpleSelectField = ({ label, options, ...props }) => (
  <SimpleControlledAutoComplete label={label} options={options} size="small" fullWidth {...props} />
);

export const SelectField = ({ label, options, ...props }) => (
  <ControlledAutoComplete label={label} options={options} size="small" fullWidth {...props} />
);

export const Caption = ({ children, ...props }) => (
  <Typography variant="subtitle2" fontWeight="600" {...props}>
    {children}
  </Typography>
);

export const ControlCheckbox = ({ control, name, label, ...props }) => (
  <ControlledInputCheckbox color="secondary" name={name} control={control} label={label} {...props} />
);

export const ActionControl = ({ isNewForm, onSubmit, isSubmitDisabled, customRenderer }) => (
  <Stack mt={2} direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
    {customRenderer}
    <TestConnectionButton
      disabled={isSubmitDisabled}
      onClick={onSubmit}
      variant="contained"
      type="submit"
      color="secondary"
    >
      {isNewForm ? 'TEST CONNECTION' : 'RESET CONNECTION'}
    </TestConnectionButton>
  </Stack>
);

const TestConnectionButton = (props) => (
  <Button
    sx={{
      minWidth: 180,
      maxHeight: 32,
      background: 'secondary.main',
      fontSize: 14,
      borderRadius: '4px',
      '&:hover': {
        background: 'secondary.buttonHover'
      }
    }}
    {...props}
  />
);

export const LogoutButton = ({ children, sx, ...props }) => (
  <Button
    color="secondary"
    sx={{
      minWidth: 100,
      maxHeight: 32,
      fontSize: 14,
      borderRadius: '4px',
      color: 'secondary.main',
      borderColor: 'secondary.main',
      '&:hover': {
        backgroundColor: 'secondary.main',
        borderColor: 'secondary.main'
      },
      ...sx
    }}
    {...props}
  >
    {children}
  </Button>
);
