import React from 'react';

import { useMutation, gql } from '@apollo/client';
import Typography from '@mui/material/Typography';

import { syncMultipleFlowNow as RUN_SYNC_MULTIPLE } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { SYNC_NOW, WORKFLOW_TABLE_ENTITIES } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';

import ModalFooter from './ModalFooter';

const DEFAULT_TITLE = 'Are you sure you want to sync files between the source & destination(s) immediately?';

const RunSyncModal = ({ onClose }) => {
  const { activeModal, selectedActionIds } = useSyncWorkflowsContext();
  const { handleResponse } = useGraphqlResponseHandler();
  const cleanedSolitaryFlowIds = selectedActionIds?.map(
    (id) => id.replaceAll(`${WORKFLOW_TABLE_ENTITIES.SOLITARY_FLOW}-`, '') /* TODO: SolitaryFlowId should be unique */
  );

  const [runSyncMultiple, { loading }] = useMutation(gql(RUN_SYNC_MULTIPLE), {
    refetchQueries: ['SyncWorkflows']
  });

  const submit = async () => {
    await handleResponse(
      runSyncMultiple,
      {
        variables: {
          body: {
            SolitaryflowIds: cleanedSolitaryFlowIds
          }
        }
      },
      { successMessage: 'Run sync saved' }
    );
    onClose();
  };

  return (
    <CustomModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={activeModal === SYNC_NOW}
      onClose={onClose}
      title="Confirm sync"
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      paperSx={{ width: 600 }}
      footer={<ModalFooter cancelBtnText="Cancel" createBtnText="CONFIRM" onModalClose={onClose} onSubmit={submit} />}
    >
      <Typography variant="body1" mb="12px" sx={{ mb: '20px' }}>
        {DEFAULT_TITLE}
      </Typography>
    </CustomModal>
  );
};

export default RunSyncModal;
