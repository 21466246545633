import { useState } from 'react';

import { Box } from '@mui/material';

import { CreateButton } from 'components';
import DropdownPaginationLoader from 'modules/Sync/components/DropdownPaginationLoader';
import { useFolderDropdownContext } from 'modules/Sync/WorkflowEditor/Contexts/FolderDropdownContext';
import { syncColors } from 'styles/theme/colors';

const LoadMoreBtn = ({ onClick, buttonText = { buttonText }, sx = {} }) => (
  <Box my={2} sx={{ display: 'flex', justifyContent: 'center', ...sx }}>
    <CreateButton
      buttonText={buttonText}
      onClick={onClick}
      variant="outlined"
      sx={{
        '&.MuiButton-root': {
          color: syncColors.text,
          '&:disabled': { color: 'action.lightSurface.disabled' }
        }
      }}
    />
  </Box>
);

const LoadMoreFoldersBtn = ({ folderId, fetchMoreFolders, showMoreBtn = true, buttonText = 'Load more', sx = {} }) => {
  const { hasMoreFolders, isExpanded } = useFolderDropdownContext();
  const [loadingNextFoldersSet, setIsLoadingNextFoldersSet] = useState(false);
  const isVisible = showMoreBtn && fetchMoreFolders && isExpanded && !loadingNextFoldersSet && hasMoreFolders;

  const loadMoreFolders = async (event) => {
    event.stopPropagation();
    setIsLoadingNextFoldersSet(true);
    await fetchMoreFolders(event);
    setIsLoadingNextFoldersSet(false);
  };

  const onClick = (event) => loadMoreFolders(event, folderId);

  return (
    <>
      {isVisible && <LoadMoreBtn onClick={onClick} buttonText={buttonText} sx={sx} />}
      {loadingNextFoldersSet && <DropdownPaginationLoader />}
    </>
  );
};

export const LoadMoreRootFoldersBtn = ({
  folderId,
  fetchMoreFolders,
  showMoreBtn = true,
  buttonText = 'Load more',
  sx = {}
}) => {
  const [loadingNextRootFoldersSet, setIsLoadingNextRootFoldersSet] = useState(false);
  const isVisible = showMoreBtn && fetchMoreFolders && !loadingNextRootFoldersSet;

  const loadMoreFolders = async (event) => {
    event.stopPropagation();
    setIsLoadingNextRootFoldersSet(true);
    await fetchMoreFolders(event);
    setIsLoadingNextRootFoldersSet(false);
  };

  const onClick = (event) => loadMoreFolders(event, folderId);

  return (
    <>
      {isVisible && <LoadMoreBtn onClick={onClick} buttonText={buttonText} sx={sx} />}
      {loadingNextRootFoldersSet && <DropdownPaginationLoader />}
    </>
  );
};

export default LoadMoreFoldersBtn;
