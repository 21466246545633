import React, { useState } from 'react';

import { LOG_FILTER } from 'modules/Sync/Constants/constants';

const SyncLogsContext = React.createContext();

const { ALL } = LOG_FILTER;

const SyncLogsProvider = ({ children }) => {
  const [currentLogFile, setCurrentLogFile] = useState({});
  const [activeLogTab, setActiveLogTab] = React.useState(0);
  const [logSearchPhrase, setLogsSearchPhrase] = useState('');
  const [activeModal, setActiveModal] = useState('');
  const [realTimeLogSearchPhrase, setRealTimeLogsSearchPhrase] = useState('');
  const [logsDetailsSearchPhrase, setLogsDetailsSearchPhrase] = React.useState('');
  const [logFilter, setLogFilter] = useState(ALL);
  const [activeRealtimeLogDate, setActiveRealtimeLogDate] = useState('');

  const selectedStateObj = React.useMemo(
    () => ({
      currentLogFile,
      setCurrentLogFile,
      activeLogTab,
      setActiveLogTab,
      logSearchPhrase,
      setLogsSearchPhrase,
      realTimeLogSearchPhrase,
      setRealTimeLogsSearchPhrase,
      logsDetailsSearchPhrase,
      setLogsDetailsSearchPhrase,
      activeModal,
      setActiveModal,
      logFilter,
      setLogFilter,
      activeRealtimeLogDate,
      setActiveRealtimeLogDate
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentLogFile,
      activeLogTab,
      logSearchPhrase,
      realTimeLogSearchPhrase,
      logsDetailsSearchPhrase,
      activeModal,
      logFilter,
      activeRealtimeLogDate
    ]
  );

  return <SyncLogsContext.Provider value={selectedStateObj}>{children}</SyncLogsContext.Provider>;
};

const useSyncLogsContext = () => {
  const context = React.useContext(SyncLogsContext);
  if (context === undefined) {
    throw new Error('useSyncLogsContext must be used within a SyncLogsContext');
  }
  return context;
};

export { SyncLogsContext, SyncLogsProvider, useSyncLogsContext };

export const LOGS_APIS = ['syncLogs', 'syncRealTimeLogs'];

export const LOGS_DETAILS_APIS = ['syncLogFileDetails', 'syncLogActionFolderDetails', 'syncLogActionFileDetails'];
