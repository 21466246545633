import React, { useCallback, useEffect } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FormControl } from '@mui/material';
import { useForm } from 'react-hook-form';

import { syncRenameMapping as SYNC_RENAME_MAPPING } from 'graphql/mutations';
import { syncValidateName as SYNC_VALIDATE_NAME } from 'graphql/queries';
import { dispatchWorkflowGridEvent } from 'helpers/domHelpers';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { WORKFLOW_MODAL_KEYS, VALIDATION_ENTITY } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';

import MappingTextField from './MappingTextField';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';

const { RENAME_MAPPING } = WORKFLOW_MODAL_KEYS;

const RenameMappingModal = ({ onClose }) => {
  const { currentMapping, activeModal } = useSyncWorkflowsContext();
  const { workflowMappingName, workflowMappingId, workflowId } = currentMapping || {};
  const open = activeModal === RENAME_MAPPING;
  const { handleResponse } = useGraphqlResponseHandler();

  const methods = useForm({
    mode: 'all',
    defaultValues: { mappingName: workflowMappingName }
  });
  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors
  } = methods;

  const [renameMapping, { loading }] = useMutation(gql(SYNC_RENAME_MAPPING));

  useEffect(() => {
    if (workflowMappingName) {
      setValue('mappingName', workflowMappingName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowMappingName]);

  const onModalClose = () => {
    onClose?.();
  };

  const [validateName, { errors: validationErrors }] = useLazyQuery(gql(SYNC_VALIDATE_NAME), {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: {
      params: { entity: VALIDATION_ENTITY.MAPPING }
    }
  });

  const handleChange = useCallback(
    (searchName) => {
      validateName({
        variables: {
          query: {
            workflowId,
            name: encodeURIComponent(searchName.trim())
          }
        }
      });
    },
    [validateName, workflowId]
  );

  const onSuccessHandler = () => {
    dispatchWorkflowGridEvent();
    onModalClose();
  };

  const onSubmit = handleSubmit((formValues) => {
    const { mappingName } = formValues;
    return handleResponse(
      renameMapping,
      {
        variables: {
          body: { MappingName: mappingName, MappingId: workflowMappingId, WorkflowId: workflowId }
        }
      },
      {
        successMessage: 'Mapping successfully renamed'
      },
      onSuccessHandler,
      onModalClose
    );
  });

  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={<ModalTitle title="Rename mapping" />}
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={
        <ModalFooter loading={loading} onModalClose={onModalClose} onSubmit={onSubmit} onSubmitDisabled={!isValid} />
      }
    >
      <FormControl size="small" fullWidth>
        <MappingTextField
          disabled={loading}
          control={control}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          validateChange={handleChange}
          validationErrors={validationErrors}
          defaultValue={workflowMappingName}
        />
      </FormControl>
    </CustomModal>
  );
};

export default RenameMappingModal;
