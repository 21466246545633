import { useEffect } from 'react';

import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { getSubmittedStatus } from 'modules/Sync/WorkflowEditor/helpers/validationsHelper';

const useForesiteHook = ({
  updateFormValues,
  connectionId,
  connectionType,
  nodeId,
  loadFolders,
  loadProjects,
  formValues,
  ForesiteCompanies,
  fetchOnMountForesiteProjects,
  fetchOnMountForesiteFolders,
  fetchCompaniesOnLoad
}) => {
  const { hub, projects: selectedProjects = [], project = selectedProjects?.[0] } = formValues;
  const defaultHub = ForesiteCompanies?.[0];
  const isSubmitted = getSubmittedStatus(formValues);

  useEffect(() => {
    updateFormValues({ connectionId, connectionType, nodeId, isSubmitted, hub: defaultHub });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ForesiteCompanies?.length, nodeId]);

  useEffect(() => {
    if (fetchOnMountForesiteProjects) loadProjects({ connectionId, companyId: hub?.id || defaultHub?.id });
    if (fetchOnMountForesiteFolders) {
      const projectId = selectedProjects?.[0]?.id || project?.id;
      loadFolders({
        connectionId,
        companyId: hub?.id,
        projectId,
        skip: 0,
        take: DROPDOWN_DEFAULT_PG_SIZE
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnMountForesiteProjects, fetchOnMountForesiteFolders]);

  useEffect(() => {
    fetchCompaniesOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useForesiteHook;
