import { useMemo } from 'react';

import { isValid } from 'date-fns';

import { formatTo } from 'helpers/dateFunctions';
import DateRangePickerInput from 'modules/Sync/components/DateRangePickerInput';
import { DATE_FILTER_SEPARATOR, MANAGE_ISSUES_FILTERS } from 'modules/Sync/Constants/canvasActionConstants';
import { useManageIssuesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/ManageIssuesContext';
import { updateFilterCriteria } from 'modules/Sync/WorkflowEditor/helpers/ManageIssuesHelpers/manageIssuesPayloadHelper';
import { validateFilterCriteria } from 'modules/Sync/WorkflowEditor/helpers/ManageIssuesHelpers/validationHelper';

const FILTER = MANAGE_ISSUES_FILTERS.DUE_DATE;
const pattern = 'YYYY/MM/DD';
const convertToDayJs = (date) => formatTo(date, pattern);

const ForgeFilterDueDateRange = ({ label = FILTER, filterKey = '', isSource, nodeId, ...rest }) => {
  const { getConnectorNodeValues, updateConnectorNodeValues } = useManageIssuesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { filterCriteria = [] } = formValues;

  const currentDueDateRange = useMemo(
    () => filterCriteria.find(({ property }) => property === filterKey)?.value?.split(DATE_FILTER_SEPARATOR) || [],
    [filterCriteria, filterKey]
  );

  const onChange = async ({ dateFrom, dateTo }) => {
    if (dateFrom && dateTo && isValid(new Date(dateFrom)) && isValid(new Date(dateTo))) {
      const dateRange = `${convertToDayJs(dateFrom)}${DATE_FILTER_SEPARATOR}${convertToDayJs(dateTo)}`;
      const newFilterCriteria = updateFilterCriteria(filterCriteria, filterKey, dateRange);
      const props = {
        filterCriteria: newFilterCriteria,
        isSubmitted: validateFilterCriteria(newFilterCriteria)
      };
      updateConnectorNodeValues({ nodeId, isSource, props });
    }
  };

  return <DateRangePickerInput label={label} dateRange={currentDueDateRange} required onChange={onChange} {...rest} />;
};

export default ForgeFilterDueDateRange;
