import React, { useEffect } from 'react';

import { AccordionList } from '@evolvemep/foresite-components-ui-react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Stack, Tab } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useStoreActions } from 'react-flow-renderer';

import { Archived } from 'assets/images';
import DraggableBox from 'components/DraggableComponents/DraggableBox';
import { Custom } from 'components/Icons';
import ListFlyoutPaper from 'components/ListFlyoutPaper';
import { filterTypes } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { propertiesDrawerStyles } from 'modules/Sync/WorkflowEditor/Drawers/drawer-styles';
import ActionTab from 'modules/Sync/WorkflowEditor/Drawers/PropertiesDrawer/ActionTab';
import DestinationTab from 'modules/Sync/WorkflowEditor/Drawers/PropertiesDrawer/DestinationTab';
import SourceTab from 'modules/Sync/WorkflowEditor/Drawers/PropertiesDrawer/SourceTab';
import StyledPopup from 'modules/Sync/WorkflowEditor/Drawers/StyledPopup';
import { syncColors } from 'styles/theme/colors';

const editStyle = { cursor: 'pointer', height: 15, width: 15 };

const PREVENT_SELECTION_SX = {
  '-webkit-user-select': 'none' /* Safari */,
  '-ms-user-select': 'none' /* IE 10 and IE 11 */,
  'user-select': 'none',
  zIndex: 'auto'
};

const Loader = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    <CircularProgress color="warning" />
  </Box>
);

const PropertiesHandle = () => (
  <Box className="handle" sx={{ cursor: 'move' }}>
    <ListFlyoutPaper.Title textHelp="View canvas mapping configurations">
      <Typography variant="h6"> Properties</Typography>
    </ListFlyoutPaper.Title>
  </Box>
);

const ArchivedInfo = () => (
  <Box sx={propertiesDrawerStyles.archivedInfo}>
    <Archived style={{ fill: syncColors.mainDarkBackground }} />
    <Typography variant="formBoldLabel">This action is archived</Typography>
  </Box>
);

const getNodeOpacity = (selectedNodes, id, rest) => {
  const nodeRoots = [rest?.target, rest?.source];
  const isActiveNode =
    rest?.target && rest?.source
      ? nodeRoots.every((nodeId) => selectedNodes.includes(nodeId))
      : selectedNodes.some((nodeId) => id === nodeId);
  return {
    opacity: isActiveNode ? 1 : 0.1
  };
};

const PropertiesDrawer = (props) => {
  const { onClose, editMappingAction, draggable, selectedFlow = {}, isArchivedTab = false, loading = false } = props;
  const { defaultValue = 0 /* Action Index Initially Selected */ } = props;

  const [value, setValue] = React.useState(defaultValue);
  const { workflowMappings, workflowElements, isInQueue, actionNodesInSync, availableActionsName } =
    useSyncWorkflowEditorContext();
  const { setIsCanvasUnlocked, setIsSetOpacity, setFilterType } = useSyncWorkflowEditorContext();
  const { setElements } = useStoreActions((actions) => actions);

  useEffect(() => {
    if (selectedFlow?.actionNodeId) onFlowSelect(selectedFlow);
    return () => {
      setIsSetOpacity(false);
      if (workflowElements?.length) setElements(workflowElements.map((el) => ({ ...el, style: { opacity: 1 } })));
      setIsCanvasUnlocked(true);
      setFilterType(filterTypes.ACTIVE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlow, workflowElements?.length]);

  const handleChange = (_, newValue) => setValue(newValue);

  const onCloseHandler = () => {
    setElements(workflowElements.map((el) => ({ ...el, style: { opacity: 1 } })));
    setIsCanvasUnlocked(true);
    onClose();
  };

  const onEdit = (nodeId, action) => () => editMappingAction(nodeId, action);

  const onFlowSelect = ({ actionNodeId = '', source = {}, destinations = [] }) => {
    setIsSetOpacity(true);
    if (!actionNodeId) return;
    const selectedNodes = destinations.reduce(
      (selectedNodes, { nodeId }) => [...selectedNodes, nodeId],
      [actionNodeId, source.nodeId]
    );
    const styledWorkflowElements = workflowElements.map(({ id, ...rest }) => ({
      id,
      ...rest,
      style: getNodeOpacity(selectedNodes, id, rest)
    }));
    setElements(styledWorkflowElements);
    setIsCanvasUnlocked(false);
  };

  const isActionNodeInSync = (id) => actionNodesInSync.includes(id);

  return (
    <DraggableBox draggable={draggable} handle=".handle" sx={PREVENT_SELECTION_SX}>
      <StyledPopup onClose={onCloseHandler} sx={propertiesDrawerStyles.container}>
        <PropertiesHandle />
        {loading && <Loader />}
        {!loading && (
          <ListFlyoutPaper.Items>
            <TabContext value={value}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                aria-label="scrollable auto tabs example"
                sx={propertiesDrawerStyles.tabList}
              >
                {workflowMappings.map(
                  ({ actionNodeId, ...rest }, index) =>
                    (!isArchivedTab || (isArchivedTab && rest?.isArchived)) && (
                      <Tab
                        key={actionNodeId}
                        label={`0${index + 1}`}
                        sx={propertiesDrawerStyles.tab}
                        value={index}
                        onClick={() => onFlowSelect({ actionNodeId, ...rest })}
                      />
                    )
                )}
              </TabList>
              <Stack sx={{ width: '98%', overflowY: 'overlay', marginBottom: 2 }}>
                {workflowMappings.map(
                  ({ destinations, source, actionAlias, actionNodeId, isArchived, action }, index) => (
                    <TabPanel key={actionNodeId} value={index} sx={propertiesDrawerStyles.tabPanel}>
                      {!isArchived &&
                        !isInQueue &&
                        !isActionNodeInSync(actionNodeId) &&
                        availableActionsName?.includes(action) && (
                          <div style={propertiesDrawerStyles.editIcon}>
                            <Custom.Edit style={editStyle} onClick={onEdit(actionNodeId, action)} />
                          </div>
                        )}
                      {isArchived && <ArchivedInfo />}
                      <CustomAccordionList
                        actionAlias={actionAlias}
                        action={action}
                        source={source}
                        destinations={destinations}
                        isArchived={isArchived}
                      />
                    </TabPanel>
                  )
                )}
              </Stack>
            </TabContext>
          </ListFlyoutPaper.Items>
        )}
      </StyledPopup>
    </DraggableBox>
  );
};
const CustomAccordionList = ({ actionAlias, source, destinations = [], isArchived, action }) => (
  <AccordionList
    hideFirstBorder
    hideLastBorder
    items={[
      {
        title: 'Action',
        defaultExpanded: true,
        content: <ActionTab data={actionAlias} isArchived={isArchived} />
      },
      {
        title: 'Source',
        defaultExpanded: true,
        content: <SourceTab data={source} action={action} />
      },
      ...destinations?.map((destination, index) => ({
        title: `Destination ${index + 1}`,
        defaultExpanded: !index,
        content: <DestinationTab data={destination} />
      }))
    ]}
    titleSx={{ fontWeight: 700, fontSize: 14 }}
    infoSx={{ fontWeight: 400, fontSize: 14 }}
    sx={propertiesDrawerStyles.accordion}
  />
);

export default PropertiesDrawer;
