import Amplify from 'aws-amplify';

import getEnvVars from './envVars';

const configureAmplify = async () => {
  const env = await getEnvVars();

  const config = {
    "aws_project_region": env.AWS_PROJECT_REGION,
    "aws_appsync_graphqlEndpoint": env.AWS_APPSYNC_GRAPHQL_ENDPOINT,
    "aws_appsync_region": env.AWS_APPSYNC_REGION,
    "aws_appsync_authenticationType": env.AWS_APPSYNC_AUTHENTICATION_TYPE,
    "aws_cognito_identity_pool_id": env.AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": env.AWS_COGNITO_REGION,
    "aws_user_pools_id": env.AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": env.AWS_USER_POOLS_WEB_CLIENT_ID,
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
  };

    const auth = {
        identityPoolId: env.REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
        userPoolId: env.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
        userPoolWebClientId: env.REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID
    };

  Amplify.configure({
    ...config,
    ...{
      Auth: auth
    }
  });
};
export { configureAmplify };
