import { useState } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import {
  getProcoreCompanies as GET_PROCORE_COMPANIES,
  getProcoreFolders as GET_PROCORE_FOLDERS,
  getProcoreProjects as GET_PROCORE_PROJECTS
} from 'graphql/queries';
import useCache from 'hooks/useCache';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import {
  DEFAULT_ROOT_FOLDER,
  DRAWING_TYPE_FOLDER_ID,
  DROPDOWN_DEFAULT_PG_SIZE,
  FOLDER_TYPES,
  MAX_DROPDOWN_ITEMS_IN_VIEW
} from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import FiltersAutocompleteDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FiltersAutocompleteDropdown';

import ProcoreCompanyDropdown from './ProcoreCompanyDropdown';
import ProcoreDocumentTypeDropdown from './ProcoreDocumentTypeDropdown';
import ProcoreFoldersDropdown from './ProcoreFoldersDropdown';
import { getFolderTypeLoader, getProcoreSelectEventHandlers } from './procoreHelpers';
import ProcoreProjectDropdown from './ProcoreProjectDropdown';
import { useProcoreMountHook } from './useProcoreHooks';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_FIRST;

// eslint-disable-next-line max-lines-per-function
const Procore = (props) => {
  const { fileTypeFilters = [], connectionId = '', connectionType = '', nodeId = '' } = props;
  const { showFilters = false, allowPrivateFiles = false, isSource = false } = props;
  const { activeFlow, getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const updateFormValues = (props) => updateConnectorNodeValues({ nodeId, isSource, props });
  const { deleteCache } = useCache();

  const [parentFolderId, setParentFolderId] = useState('');
  const [folderTypes, setFolderTypes] = useState(FOLDER_TYPES);
  const { hub, projects, project = projects?.[0], folderType, folders = [], uiFolders = [] } = formValues;
  const connectionTypeId = folderTypes.find(({ id }) => id === folderType)?.text || '';
  const ProcoreDrawingRootFolders = [{ id: DEFAULT_ROOT_FOLDER.ID, text: DEFAULT_ROOT_FOLDER.TEXT }];

  const [
    { paginatedLazyLoad: lazyLoadCompanies, paginationHandler: companiesPaginationHandler },
    { loading: isLoadingCompanies, data: { getProcoreCompanies: ProcoreCompanies = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_PROCORE_COMPANIES), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);
  const [
    { paginatedLazyLoad: lazyLoadProcoreProjects, paginationHandler: projectsPaginationHandler },
    { loading: isLoadingProjects, data: { getProcoreProjects: ProcoreProjects = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_PROCORE_PROJECTS), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const [
    { lazyLoad: loadFolders, paginationHandler: fetchMoreRootFolders },
    { loading: isLoadingFolders, data: { getProcoreFolders: ProcoreFolders = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_PROCORE_FOLDERS), FETCH_POLICY, DROPDOWN_DEFAULT_PG_SIZE);

  const onScrollCompanies = () => companiesPaginationHandler(ProcoreCompanies?.length, { connectionId });

  const onScrollProjects = () =>
    projectsPaginationHandler(ProcoreProjects?.length, { connectionId, companyId: hub?.id });

  const fetchCompaniesOnLoad = () => {
    if (hub?.id) lazyLoadCompanies({ skip: null, take: null, connectionId });
    if (!hub?.id) lazyLoadCompanies({ skip: ProcoreCompanies?.length, connectionId });
  };

  const fetchProjectsOnLoad = () => {
    if (project?.id) lazyLoadProcoreProjects({ skip: null, take: null, connectionId, companyId: hub?.id });
    if (!project?.id) lazyLoadProcoreProjects({ skip: ProcoreProjects?.length, connectionId, companyId: hub?.id });
  };

  const loadFolderType = getFolderTypeLoader({
    connectionId,
    hub,
    project,
    loadFolders,
    cacheCleanupCb: () => deleteCache('GetProcoreFolders')
  });

  const fetchOnMountProcoreProjects =
    hub?.id && !isLoadingCompanies && ProcoreCompanies?.length && !ProcoreProjects?.length;
  const fetchOnMountProcoreFolders =
    activeFlow?.solitaryFlowId &&
    project?.id &&
    !isLoadingProjects &&
    ProcoreProjects?.length &&
    !ProcoreFolders?.length;

  const { isFolder: folderSelectionIsOptional = false, expandableFolders = true } =
    FOLDER_TYPES.find(({ id }) => folderType === id) || {};

  const currentFolders = folderType === DRAWING_TYPE_FOLDER_ID ? ProcoreDrawingRootFolders : ProcoreFolders;

  const setParentFolderIdOnStackLoad = (parentFolderId) => setParentFolderId(parentFolderId);

  const { selectCompanyHandler, selectProjectHandler, selectFolderTypeHandler, selectFolderHandler } =
    getProcoreSelectEventHandlers({
      connectionId,
      folderTypes,
      setFolderTypes,
      updateFormValues,
      loadProjects: lazyLoadProcoreProjects,
      loadFolderType,
      projects,
      folders,
      uiFolders,
      parentFolderId,
      setParentFolderId,
      isSource
    });

  useProcoreMountHook({
    formValues,
    updateFormValues,
    connectionId,
    connectionType,
    nodeId,
    loadProjects: fetchProjectsOnLoad,
    setFolderTypes,
    loadFolderType,
    fetchOnMountProcoreProjects,
    fetchOnMountProcoreFolders,
    fetchCompaniesOnLoad
  });

  return (
    <Stack spacing={2}>
      <ProcoreCompanyDropdown
        value={hub?.id}
        onChange={selectCompanyHandler}
        loading={isLoadingCompanies}
        items={ProcoreCompanies}
        fetchPaginatedRecords={onScrollCompanies}
      />
      <ProcoreProjectDropdown
        value={project?.id}
        onChange={selectProjectHandler}
        loading={isLoadingProjects}
        disabled={!hub?.id || !ProcoreCompanies?.length}
        items={ProcoreProjects}
        fetchPaginatedRecords={onScrollProjects}
      />

      <ProcoreDocumentTypeDropdown
        disabled={isLoadingProjects || !project?.id || !ProcoreProjects?.length}
        value={folderType}
        onChange={selectFolderTypeHandler}
        isSource={isSource}
        items={ProcoreProjects?.length ? folderTypes : []} /* show selected folder type after projects are loaded */
      />

      {!folderSelectionIsOptional && (
        <ProcoreFoldersDropdown
          folders={currentFolders}
          connectionId={connectionId}
          disabled={isLoadingProjects || !folderType || !ProcoreProjects?.length || isLoadingFolders}
          expandableFolders={expandableFolders}
          connectionTypeId={connectionTypeId}
          isLoadingFolders={isLoadingFolders}
          onSelect={selectFolderHandler}
          isSource={isSource}
          nodeId={nodeId}
          multiple={isSource}
          onFolderStackLoad={setParentFolderIdOnStackLoad}
          fetchMoreRootFolders={fetchMoreRootFolders}
          vertical={-170}
          horizontal={675}
          required
        />
      )}
      <FiltersAutocompleteDropdown
        allowPrivateFiles={allowPrivateFiles}
        showFilters={showFilters}
        fileTypeFilters={fileTypeFilters}
        isSource={isSource}
        nodeId={nodeId}
      />
    </Stack>
  );
};

export default Procore;
