import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

import { getMicroFeContainer } from 'helpers/domHelpers';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';

const UncontrolledMultiSelectDropdown = ({
  name,
  label,
  value: fieldValue,
  onChange,
  sx = {},
  disabled,
  error,
  options,
  multiple = true
}) => {
  const renderDropdownValue = (selectedItemIds) => {
    if (!selectedItemIds?.length) return '';

    const itemsToRender = options?.reduce((itemsToRender, item) => {
      if (selectedItemIds.includes(item.value)) itemsToRender.push(item);
      return itemsToRender;
    }, []);

    return itemsToRender?.length > 1
      ? `${itemsToRender.find(({ value }) => value === fieldValue[0])?.value} and ${itemsToRender.length - 1} other`
      : itemsToRender?.[0]?.value;
  };

  return (
    <FormControl size="small" fullWidth color="secondary" sx={sx} error={!!error?.type}>
      <InputLabel id={label} disabled={disabled}>
        {label}
      </InputLabel>
      <Select
        labelId={label}
        id={label}
        label={label}
        onChange={onChange}
        value={fieldValue}
        name={name}
        disabled={disabled}
        MenuProps={{
          sx: { width: '200px', maxHeight: '400px', '& .MuiMenuItem-root': { whiteSpace: 'break-spaces' } },
          container: getMicroFeContainer
        }}
        multiple={multiple}
        SelectDisplayProps={{ sx: { color: '#FF7A00' } }}
        renderValue={renderDropdownValue}
      >
        {options?.map(({ label, value, disabled = false }) => (
          <MenuItem key={label} value={value} disabled={disabled}>
            <Checkbox
              color="warning"
              checked={fieldValue.includes(value)}
              size="small"
              sx={{ margin: 0, marginRight: 1, padding: 0 }}
            />
            <CustomSyncTooltip title={value}>{value}</CustomSyncTooltip>
          </MenuItem>
        ))}
      </Select>
      {error?.message && (
        <FormHelperText error sx={{ m: 0, lineHeight: 1.4 }}>
          {error?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default UncontrolledMultiSelectDropdown;
