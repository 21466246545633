import { useEffect, useRef, useState } from 'react';

import { TreeView } from '@mui/lab';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { getMicroFeContainer } from 'helpers/domHelpers';
import { DEFAULT_ROOT_FOLDER, DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { FolderDropdownProvider } from 'modules/Sync/WorkflowEditor/Contexts/FolderDropdownContext';
import { usePublishActionFormContext } from 'modules/Sync/WorkflowEditor/Contexts/PublishActionContext';
import FolderRowTreeItem from 'modules/Sync/WorkflowEditor/Forms/Shared/PublishActionComponents/FoldersSelectDropdown/FolderRowTreeItem';
import { LoadMoreRootFoldersBtn } from 'modules/Sync/WorkflowEditor/Forms/Shared/PublishActionComponents/FoldersSelectDropdown/LoadMoreFoldersBtn';

import { getFoldersSelectDropdownEventHandlers } from './folderTreeItemHelper';

const styles = {
  selectMenu: {
    width: '350px',
    maxHeight: 525,
    '& .MuiPaper-root ': {
      width: '430px',
      left: '340px',
      height: 400
    },
    '& .MuiMenuItem-root': { whiteSpace: 'break-spaces' },
    '& .MuiList-root ': { padding: 0 }
  },
  treeView: { height: 400, overflowY: 'auto', left: '340px' }
};

const renderSelectFieldValue =
  ({ isLoadingFolderStack, disabled, multiple, value, selectedFolders }) =>
  (selectedFolderIds) => {
    if (isLoadingFolderStack) return <SelectLoader isLoading />;
    if (disabled) return ''; /* If folder dropdown is disabled don't show any value */
    if (!multiple) return value;

    const foldersToRender = selectedFolders.reduce((foldersToRender, item) => {
      // eslint-disable-next-line react/destructuring-assignment
      const selectedFoldersHaveItem = selectedFolderIds?.includes(item.id);
      if (item.id && !item?.isFolder && selectedFoldersHaveItem) foldersToRender.push(item);
      return foldersToRender;
    }, []);

    const firstFolderName = foldersToRender[0]?.name;
    const foldersLength = foldersToRender.length;
    return foldersLength > 1 ? `${firstFolderName} and ${foldersLength - 1} other` : firstFolderName;
  };

const getSelectProps = ({
  vertical,
  horizontal,
  multiple = false,
  disabled = false,
  isLoading = false,
  selectedFoldersIds,
  value,
  isLoadingFolderStack,
  selectedFolders,
  isFolderDropdownOpen,
  clickFolderDropdownHandler,
  closeFolderDropdownHandler,
  selectDropdownRef,
  required = false
}) => ({
  labelId: 'Item Name',
  id: 'folder',
  label: `Item Name${required ? '*' : ''}`,
  displayEmpty: true,
  MenuProps: {
    sx: styles.selectMenu,
    MenuListProps: { sx: { height: '100%' } },
    anchorOrigin: { vertical, horizontal },
    elevation: 1,
    container: getMicroFeContainer
  },
  SelectDisplayProps: {
    sx: { '.MuiInputBase-input': { display: 'flex', alignItems: 'center' }, borderColor: '#FF7A00' }
  },
  disabled,
  startAdornment: isLoading ? <SelectLoader isLoading /> : '',
  multiple,
  value: multiple ? selectedFoldersIds : value,
  renderValue: renderSelectFieldValue({ isLoadingFolderStack, disabled, multiple, value, selectedFolders }),
  open: isFolderDropdownOpen,
  onClick: disabled ? null : clickFolderDropdownHandler,
  onClose: closeFolderDropdownHandler,
  ref: selectDropdownRef
});

const getTreeProps = ({ multiple, selectedFolders, folder, expandedFolderIds }) => ({
  'aria-label': 'folder',
  fullWidth: true,
  sx: styles.treeView,
  selected: multiple ? selectedFolders : folder,
  expanded: expandedFolderIds,
  multiSelect: multiple
});

const FoldersSelectDropdown = (props) => {
  const { folders, connectionId, connectionTypeId, onSelect, nodeId, isSource = false, disabled = false } = props;
  const { isLoading = false, isLoadingFolderStack = false, expandableFolders = true, formatText } = props;
  const { onFolderExpandHandler, onFolderSelectHandler, onCreateFolderHandler } = props;
  const { onFolderDropdownClick = () => {}, fetchMoreFoldersHandler = () => {}, multiple = false } = props;
  const { rootParentFolderId = '', rootParentFolderName = '', vertical = -50, horizontal = 675 } = props;
  const { permitFolderCreationForIds = [], isInitialStackFetched = true, required = false } = props;

  const { getConnectorNodeValues, updateConnectorNodeValues } = usePublishActionFormContext();
  const [isFolderDropdownOpen, setIsFolderDropdownOpen] = useState(false);
  const [hasMoreRootFolders, setHasMoreRootFolders] = useState(folders?.length >= DROPDOWN_DEFAULT_PG_SIZE);
  const selectDropdownRef = useRef(null);
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const updateFormValues = (props) => updateConnectorNodeValues({ nodeId, isSource, props });
  const { folder, expandedFolderIds = [] } = formValues;
  const { folder: { name: value = '' } = {}, folders: selectedFolders = [] } = formValues;
  const selectedFoldersIds = selectedFolders.map(({ id }) => id);

  const {
    getExpandableFolders,
    onFolderRowItemExpansion,
    onFolderRowItemCollapse,
    clickFolderDropdownHandler,
    closeFolderDropdownHandler,
    onFoldersFetchMore
  } = getFoldersSelectDropdownEventHandlers({
    permitFolderCreationForIds,
    expandableFolders,
    isSource,
    formValues,
    expandedFolderIds,
    setIsFolderDropdownOpen,
    isFolderDropdownOpen,
    onFolderDropdownClick,
    selectDropdownRef,
    setHasMoreRootFolders,
    fetchMoreFoldersHandler,
    rootParentFolderId,
    updateFormValues,
    folders
  });

  useEffect(() => {
    setHasMoreRootFolders(folders?.length >= DROPDOWN_DEFAULT_PG_SIZE);
  }, [folders?.length]);

  return (
    <FormControl size="small" fullWidth color="secondary">
      <FolderLabel isLoading={isLoading} required={required} />
      <Select
        {...getSelectProps({
          vertical,
          horizontal,
          multiple,
          disabled,
          isLoading,
          selectedFoldersIds,
          value,
          isLoadingFolderStack,
          selectedFolders,
          isFolderDropdownOpen,
          clickFolderDropdownHandler,
          closeFolderDropdownHandler,
          selectDropdownRef,
          required
        })}
      >
        {!isLoadingFolderStack && (
          <TreeView {...getTreeProps({ multiple, selectedFolders, folder, expandedFolderIds })}>
            {getFolderRow({
              folders,
              getExpandableFolders,
              rootParentFolderId,
              rootParentFolderName,
              nodeId,
              connectionId,
              connectionTypeId,
              onSelect,
              isSource,
              onFolderRowItemExpansion,
              onFolderRowItemCollapse,
              onFolderExpandHandler,
              onFolderSelectHandler,
              onCreateFolderHandler,
              fetchMoreFoldersHandler,
              isInitialStackFetched,
              formatText,
              multiple,
              hasMoreRootFolders,
              onFoldersFetchMore
            })}
          </TreeView>
        )}
      </Select>
    </FormControl>
  );
};

const getFolderRow = ({
  folders,
  getExpandableFolders,
  rootParentFolderId,
  rootParentFolderName,
  nodeId,
  connectionId,
  connectionTypeId,
  onSelect,
  isSource,
  onFolderRowItemExpansion,
  onFolderRowItemCollapse,
  onFolderExpandHandler,
  onFolderSelectHandler,
  onCreateFolderHandler,
  fetchMoreFoldersHandler,
  isInitialStackFetched,
  formatText,
  multiple,
  hasMoreRootFolders,
  onFoldersFetchMore
}) =>
  folders?.length ? (
    <>
      {folders?.map(({ id, text, isFolder }) => (
        <FolderDropdownProvider expandableFolders={getExpandableFolders(id)}>
          <FolderRowTreeItem
            key={id}
            depth={0}
            folderId={id}
            isFolder={isFolder}
            parentFolderId={rootParentFolderId}
            parentFolderName={rootParentFolderName}
            nodeId={nodeId}
            text={text}
            connectionId={connectionId}
            connectionTypeId={connectionTypeId}
            onSelect={onSelect}
            isSource={isSource}
            onFolderRowItemExpansion={onFolderRowItemExpansion}
            onFolderRowItemCollapse={onFolderRowItemCollapse}
            onFolderExpandHandler={onFolderExpandHandler}
            onFolderSelectHandler={onFolderSelectHandler}
            onCreateFolderHandler={onCreateFolderHandler}
            fetchMoreFoldersHandler={fetchMoreFoldersHandler}
            isInitialStackFetched={isInitialStackFetched}
            formatText={formatText}
            multiple={multiple}
          />
        </FolderDropdownProvider>
      ))}
      <LoadMoreRootFoldersBtn
        showMoreBtn={hasMoreRootFolders && rootParentFolderId !== DEFAULT_ROOT_FOLDER.ID}
        fetchMoreFolders={onFoldersFetchMore}
        folderId={rootParentFolderId}
      />
    </>
  ) : (
    <NoResults />
  );

const FolderLabel = ({ isLoading, required }) => (
  <InputLabel id="Item Name" disabled={isLoading}>
    Item Name{required && '*'}
  </InputLabel>
);

const NoResults = () => (
  <MenuItem disabled sx={{ justifyContent: 'center' }}>
    No Results
  </MenuItem>
);

const SelectLoader = ({ isLoading, size = 10, sx = {} }) =>
  isLoading && <CircularProgress size={size} color="warning" sx={sx} />;

export default FoldersSelectDropdown;
