import React from 'react';

import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import CustomModal from 'modules/Sync/components/CustomModal';
import useDeleteMappingCanvasDataApiAction from 'modules/Sync/WorkflowEditor/apiActions/deleteMappingCanvasData';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';

const DeleteCanvasModal = ({ onClose, onSubmit }) => {
  const { setWorkflowElements, setCanvasData, isOpenDeleteCanvasModal } =
    useSyncWorkflowEditorContext();
  const { id: mappingId } = useParams();
  const { deleteMappingCanvasData, loading } = useDeleteMappingCanvasDataApiAction({
    refetchQueries: ['SyncGetWorkflowMappingConfiguration']
  });

  const submit = async () => {
    await deleteMappingCanvasData({
      mappingId,
      onCompleted: () => {
        setWorkflowElements([]);
        setCanvasData({ position: [0, 0], zoom: 1 });
      }
    });
    onSubmit();
  };

  return (
    <CustomModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={isOpenDeleteCanvasModal}
      onClose={onClose}
      title="Confirm delete"
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      footer={
        <ModalFooter
          loading={loading}
          onModalClose={onClose}
          cancelBtnText="Cancel"
          createBtnText="Confirm"
          onSubmit={submit}
          isDelete
        />
      }
    >
      <Typography variant="body1" mb="12px" sx={{ width: '505px', mb: '20px' }}>
        Are you sure you want to delete the entire canvas data?
      </Typography>
    </CustomModal>
  );
};

export default DeleteCanvasModal;
