import React from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';

import ItemsDataGridPro from 'components/ItemsDatagridPro';
import NoRowsOverlay from 'modules/Sync/Webhooks/DataUsageDataGridTable/NoRowsOverlay';

const DataUsageDataGridTable = () => {
  const apiRef = useGridApiRef();

  return (
    <ItemsDataGridPro
      checkboxSelection={false}
      apiRef={apiRef}
      getTreeDataPath={(row) => row.path}
      getRowId={(row) => row.id}
      getRowClassName={(params) => (params?.row?.workflows ? 'webhook-row' : 'mapping-row')}
      components={{
        NoRowsOverlay: () => <NoRowsOverlay text="Data Usage Dashboard...coming soon!" />
      }}
      sortingOrder={['asc', 'desc']}
    />
  );
};

export default DataUsageDataGridTable;
