import { TreeItem } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';

import { Custom } from 'components/Icons';
import { ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import {
  FolderDropdownProvider,
  useFolderDropdownContext
} from 'modules/Sync/WorkflowEditor/Contexts/FolderDropdownContext';
import FolderRowMenuLabel from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown/FolderRowMenuLabel';
import LoadMoreFoldersBtn from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown/LoadMoreFoldersBtn';
import {
  useFolderRowMountHook,
  useFolderRowToggleHook,
  useFolderRowTreeItemEventHandlers
} from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown/useFolderRowHooks';

const defaultFormatter = (text) => text;

const getCreateFolderInputProps = ({ isLoading, control, onFolderNameChange, hideCreateFolderInput, onKeyDown }) => ({
  autoFocus: true,
  id: 'create-folder',
  name: 'folder-name',
  label: 'Folder Name',
  maxLength: 24,
  sx: { width: '98%' },
  required: true,
  errorOnFocusOnly: true,
  InputProps: { endAdornment: isLoading && <CircularProgress size={15} color="warning" /> },
  control,
  onClick: onFolderNameChange,
  onBlur: hideCreateFolderInput,
  onKeyDown
});

const RowToggleIcon = ({ isExpandable, isExpanded }) =>
  isExpandable &&
  (isExpanded ? (
    <Custom.KeyboardArrowRight width={30} height={30} style={{ transform: 'rotate(90deg)' }} />
  ) : (
    <Custom.KeyboardArrowRight width={30} height={30} />
  ));

const FolderRowTreeItem = (props) => {
  const { folderId, parentFolderId, text, connectionId, connectionTypeId, onSelect, nodeId, depth } = props;
  const { isSource = false, disabled = false, formatText = defaultFormatter, multiple = false } = props;
  const { onFolderRowItemExpansion: onRowExpansion, onFolderRowItemCollapse: onRowCollapse } = props;
  const { onFolderSelectHandler, onFolderExpandHandler, onCreateFolderHandler, fetchMoreFoldersHandler } = props;
  const { permitFolderCreationForIds = [], expandableFolders = true, checkIsFolderUnselectable = () => {} } = props;
  const { isInitialStackFetched = true, preventCloseOnSelect = false } = props;

  const { getConnectorNodeValues, updateConnectorNodeValues, updateSourceUiFolders } = useCopyFilesFormContext();
  const { isLoading, isExpanded, setIsExpanded, isExpandable, folders } = useFolderDropdownContext();
  const { createFolderInputVisibility, setCreateFolderInputVisibility } = useFolderDropdownContext();

  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const updateFormValues = (props) => updateConnectorNodeValues({ nodeId, isSource, props });
  const toggleIsExpanded = () => setIsExpanded((status) => !status);
  const { expandedFolderIds = [], uiFolders = [] } = formValues;
  const hideCreateFolderInput = () => setCreateFolderInputVisibility(false);
  const { control } = useForm({ mode: 'all', defaultValues: { newFolderName: '' } });

  const { onFolderExpand, onFolderSelect, onCreateFolderClick, onFoldersFetchMore, onFolderNameChange, onKeyDown } =
    useFolderRowTreeItemEventHandlers({
      folderId,
      parentFolderId,
      isExpanded,
      toggleIsExpanded,
      setIsExpanded,
      onSelect,
      onFolderExpandHandler,
      onFolderSelectHandler,
      onCreateFolderHandler,
      fetchMoreFoldersHandler,
      permitFolderCreationForIds,
      updateFormValues,
      uiFolders
    });

  useFolderRowMountHook({
    folderId,
    expandedFolderIds,
    onFolderExpandHandler,
    toggleIsExpanded,
    isInitialStackFetched,
    updateSourceUiFolders,
    uiFolders
  });

  useFolderRowToggleHook({ folderId, onRowExpansion, onRowCollapse, updateSourceUiFolders, uiFolders, folders });

  return (
    <StyledTreeItemRoot
      nodeId={folderId}
      label={
        <FolderRowMenuLabel
          nodeId={nodeId}
          folderId={folderId}
          parentFolderId={parentFolderId}
          text={text}
          textFormatter={formatText}
          onFolderSelect={onFolderSelect}
          onFolderCreate={onCreateFolderClick}
          disabled={disabled}
          multiple={multiple}
          isChecked={formValues?.uiFolders?.find(({ id }) => id === folderId)}
          isSource={isSource}
          checkIsFolderUnselectable={checkIsFolderUnselectable}
        />
      }
      onClick={onFolderExpand}
      icon={<RowToggleIcon isExpandable={isExpandable} isExpanded={isExpanded} />}
      disabled={disabled}
      data-tree-node={`folder-tree-node-${folderId}`}
    >
      {createFolderInputVisibility && (
        <ControlTextField
          {...getCreateFolderInputProps({ isLoading, control, onFolderNameChange, hideCreateFolderInput, onKeyDown })}
        />
      )}
      {getFolderRow({
        folders,
        permitFolderCreationForIds,
        expandableFolders,
        depth,
        folderId,
        connectionId,
        connectionTypeId,
        onSelect,
        isSource,
        onRowExpansion,
        onRowCollapse,
        onFolderExpandHandler,
        onFolderSelectHandler,
        onCreateFolderHandler,
        fetchMoreFoldersHandler,
        isInitialStackFetched,
        formatText,
        nodeId,
        multiple,
        preventCloseOnSelect,
        onFoldersFetchMore,
        parentFolderId
      })}
    </StyledTreeItemRoot>
  );
};

const StyledTreeItemRoot = (props) => (
  <TreeItem
    sx={{
      '& .folder-row': {
        '&:hover': {
          '& .create-folder-icon': {
            opacity: 1
          }
        }
      },
      '& .Mui-focused': {
        background: 'transparent !important'
      },
      '& .MuiTreeItem-content:hover': {
        background: 'transparent'
      }
    }}
    {...props}
  />
);

const getFolderRow = ({
  folders,
  permitFolderCreationForIds,
  expandableFolders,
  depth,
  folderId,
  connectionId,
  connectionTypeId,
  onSelect,
  isSource,
  onRowExpansion,
  onRowCollapse,
  onFolderExpandHandler,
  onFolderSelectHandler,
  onCreateFolderHandler,
  fetchMoreFoldersHandler,
  isInitialStackFetched,
  formatText,
  nodeId,
  multiple,
  preventCloseOnSelect,
  onFoldersFetchMore,
  parentFolderId
}) => (
  <>
    {folders?.map(({ id, text }) => (
      <FolderDropdownProvider expandableFolders={permitFolderCreationForIds?.includes(id) || expandableFolders}>
        <FolderRowTreeItem
          key={id}
          depth={depth + 1}
          folderId={id}
          parentFolderId={folderId}
          text={text}
          connectionId={connectionId}
          connectionTypeId={connectionTypeId}
          onSelect={onSelect}
          isSource={isSource}
          onFolderRowItemExpansion={onRowExpansion}
          onFolderRowItemCollapse={onRowCollapse}
          onFolderExpandHandler={onFolderExpandHandler}
          onFolderSelectHandler={onFolderSelectHandler}
          onCreateFolderHandler={onCreateFolderHandler}
          fetchMoreFoldersHandler={fetchMoreFoldersHandler}
          isInitialStackFetched={isInitialStackFetched}
          formatText={formatText}
          nodeId={nodeId}
          multiple={multiple}
          permitFolderCreationForIds={permitFolderCreationForIds}
          preventCloseOnSelect={preventCloseOnSelect}
        />
      </FolderDropdownProvider>
    )) || ''}
    <LoadMoreFoldersBtn
      showMoreBtn={!!folders?.length}
      fetchMoreFolders={onFoldersFetchMore}
      folderId={parentFolderId}
    />
  </>
);

export default FolderRowTreeItem;
