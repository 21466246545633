import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import Draggable from 'react-draggable';

const DraggableBox = ({ children, draggable, handle, sx = {} }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => (document.body.style.overflow = '');
  }, []);

  return draggable ? (
    <Draggable handle={handle || ''}>
      <Box sx={{ zIndex: 2, ...sx }} position="absolute" top={0} bottom={0} right={0}>
        {children}
      </Box>
    </Draggable>
  ) : (
    children
  );
};

export default DraggableBox;
