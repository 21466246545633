import React from 'react';

import { MenuItem, Typography } from '@mui/material';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import { CONNECTIONS_APIS, useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import useEvictApolloCache from 'modules/Sync/hooks/useEvictApolloCache';

import { FetchWorkflowDataProvider } from './components/FetchWorkflowDataContext';
import { ImportWorkflowProvider } from './components/ImportWorkflowsContext';
import ConnectionHeader from './ConnectionHeader/ConnectionHeader';
import ConnectionProperties from './ConnectionProperties/ConnectionProperties';
import ConnectionsDataGridTable from './ConnectionsDataGridTable/ConnectionsDataGridTable';
import ImportConnectionsModal from './Modals/ImportConnectionModal';
import ConfirmImportModal from './Modals/ImportConnectionModal/ConfirmModal/ConfirmImportModal';

const MenuOptions = ({ onMenuItemClick }) => (
  <MenuItem onClick={onMenuItemClick}>
    <Typography variant="body1">Close pane</Typography>
  </MenuItem>
);

const Connections = () => {
  const {
    currentConnection,
    setCurrentConnection,
    setHeaderActionsDisabled,
    importConnectionOpen,
    setImportConnectionOpen,
    setImportedConnections,
    confirmImportOpen,
    setConfirmImportOpen
  } = useSyncConnectionsContext();
  const onMenuItemClick = () => {
    setCurrentConnection(null);
    setHeaderActionsDisabled(false);
  };

  const handleOnClose = () => {
    setImportConnectionOpen(false);
    setConfirmImportOpen(false);
    setImportedConnections([]);
  };

  const handleOnCloseConfirmModal = () => {
    setImportConnectionOpen(true);
    setConfirmImportOpen(false);
  };

  useEvictApolloCache(CONNECTIONS_APIS, []);

  return (
    <ComponentPaneLayout sizes={currentConnection ? [5, 4] : []}>
      <ConnectionsTableContent>
        <ImportConnectionsModal open={importConnectionOpen} onClose={handleOnClose} />
        <ConfirmImportModal open={confirmImportOpen} onClose={handleOnCloseConfirmModal} />
      </ConnectionsTableContent>

      {currentConnection && (
        <ConnectionProperties renderMenuItems={() => <MenuOptions onMenuItemClick={onMenuItemClick} />} />
      )}
    </ComponentPaneLayout>
  );
};

export default Connections;

export const ConnectionsTableContent = ({ children }) => (
  <ImportWorkflowProvider>
    <FetchWorkflowDataProvider>
      <ItemsDataGridProProvider contextId="ConnectionsTable">
        {children}
        <ConnectionHeader />
        <ConnectionsDataGridTable />
      </ItemsDataGridProProvider>
    </FetchWorkflowDataProvider>
  </ImportWorkflowProvider>
);
