import { useEffect, useMemo, useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { gridPaginatedVisibleSortedGridRowEntriesSelector, useGridApiRef } from '@mui/x-data-grid-pro';

import ItemsDataGridPro from 'components/ItemsDatagridPro';
import { syncLogsRetry as SYNC_LOGS_RETRY } from 'graphql/mutations';
import { getMicroFeContainer } from 'helpers/domHelpers';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { MAX_DATAGRID_ROWS_IN_VIEW } from 'modules/Sync/Constants/constants';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import useLogsLazyPaginationHook from 'modules/Sync/Logs/Contexts/useLogsLazyPaginationHook';
import logsDataGridStyles from 'modules/Sync/Logs/LogsDataGridTable/logsDataGridStyles';
import LogsDataGridTableCell from 'modules/Sync/Logs/LogsDataGridTable/LogsDataGridTableCell';
import LogsDataGridTableColumns from 'modules/Sync/Logs/LogsDataGridTable/LogsDataGridTableColumns';
import LogsDataGridTableRows from 'modules/Sync/Logs/LogsDataGridTable/LogsDataGridTableRows';
import LogsDataHeader from 'modules/Sync/Logs/LogsDataGridTable/LogsDataHeader';
import NoRowsOverlay from 'modules/Sync/Logs/NoRowsOverlay';

const NoRowsOverlayText = () => <span>Start by syncing a mapping or workflow.</span>;

const LogsDataGridTable = ({ activeRows }) => {
  const { handleResponse } = useGraphqlResponseHandler();
  const { setLogsSearchPhrase, logSearchPhrase: searchPhrase } = useSyncLogsContext();
  const { loadingLogs, logsData, lazyLoadLogs, logsPaginationHandler, logsSearchHandler, logsSortHandler } =
    useLogsLazyPaginationHook();
  const apiRef = useGridApiRef();
  const [expandedRows, setExpandedRows] = useState([]);

  const [retrySync] = useMutation(gql(SYNC_LOGS_RETRY));
  const handleRetrySync = async (FileSyncLogId, callback = () => null) => {
    if (!FileSyncLogId) return;
    await handleResponse(
      retrySync,
      {
        variables: {
          query: { FileSyncLogId }
        }
      },
      { successMessage: 'Retrying sync for the file' },
      () => callback?.()
    );
  };

  const handleCellComponent = ({ field, row, ...rest }) => (
    <LogsDataGridTableCell field={field} row={row} handleRetry={handleRetrySync} {...rest} />
  );
  const onScroll = () => logsPaginationHandler(logsData?.syncLogs?.length, { searchPhrase });

  const columns = LogsDataGridTableColumns(handleCellComponent);
  const rows = useMemo(() => LogsDataGridTableRows(logsData?.syncLogs), [logsData]);

  const handleSort = (sortModel) => (sortModel.length ? logsSortHandler({ columnName: sortModel?.[0]?.field }) : null);
  const onSearch = (phrase) => {
    setLogsSearchPhrase(phrase);
    return logsSearchHandler(phrase);
  };

  useEffect(() => {
    if (!loadingLogs && apiRef?.current?.windowRef?.current && activeRows.length && rows.length) {
      const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);

      const rowIndex = allRows?.findIndex(({ id }) => id.includes(activeRows?.[0]));

      const indexToScrollAt =
        rowIndex + MAX_DATAGRID_ROWS_IN_VIEW >= allRows.length
          ? allRows.length - 1
          : rowIndex + MAX_DATAGRID_ROWS_IN_VIEW;

      apiRef?.current?.scrollToIndexes({ rowIndex: Number(indexToScrollAt) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, activeRows.length, rows.length, loadingLogs]);

  useEffect(() => {
    lazyLoadLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newExpandedRows = rows?.find((row) => activeRows.includes(row?.uid))?.parentIds || [];
    setExpandedRows(newExpandedRows);
  }, [activeRows, rows]);

  return (
    <>
      <LogsDataHeader searchHandler={onSearch} onRefresh={lazyLoadLogs} />
      <ItemsDataGridPro
        key={expandedRows.length}
        apiRef={apiRef}
        initialState={{ pinnedColumns: { left: [], right: ['*download'] } }}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay text={<NoRowsOverlayText />} />
        }}
        slotProps={{
          PopperProps: { container: getMicroFeContainer() }
        }}
        density="standard"
        checkboxSelection={false}
        disableColumnMenu
        disableSelectionOnClick
        columns={columns}
        rows={rows}
        loading={loadingLogs}
        experimentalFeatures={{ rowPinning: true, newEditingApi: !true }}
        asyncApi={{ onScroll }}
        onRowsScrollEnd={onScroll}
        onSortModelChange={handleSort}
        sortingOrder={['asc', 'desc']}
        getRowClassName={(row) => (activeRows.includes(row.id) ? 'active-log-row' : '')}
        sx={logsDataGridStyles}
      />
    </>
  );
};

export default LogsDataGridTable;
