import { createTheme } from '@mui/material/styles';

import { getMicroFeContainer } from 'helpers/domHelpers';

import breakpoints from './breakpoints';
import {
  errorColors,
  primaryColors,
  successColors,
  surfaceColors,
  actionColors,
  statusColors,
  otherColors,
  syncColors,
  kanbanColors,
  greyColors
} from './colors';
import components from './components';
import typography from './typography';

export const getTheme = (activeModuleColors) =>
  createTheme({
    palette: {
      // overriding material color objects
      primary: primaryColors,
      secondary: activeModuleColors,
      error: errorColors,
      success: successColors,
      background: {
        default: '#dbdbdb'
      },
      // adding custom color objects
      surface: surfaceColors,
      action: actionColors,
      status: statusColors,
      other: otherColors,
      myWhite: {
        main: 'white'
      },
      kanban: kanbanColors,
      greyColors
    },
    breakpoints: {
      values: breakpoints
    },
    typography,
    components: {
      ...components(activeModuleColors),
      MuiPortal: {
        defaultProps: {
          container: getMicroFeContainer()
        }
      },
      MuiDataGrid: {
        defaultProps: {
          container: getMicroFeContainer()
        }
      },
      MuiSelect: {
        defaultProps: {
          container: getMicroFeContainer()
        }
      },
      MuiPopover: {
        defaultProps: {
          container: getMicroFeContainer()
        }
      },
      MuiMenu: {
        defaultProps: {
          container: getMicroFeContainer()
        }
      }
    }
  });

export const getThemeForSyncModule = () => getTheme(syncColors);