import { useEffect, useMemo } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncGetAutodeskForgeFiltersIssueStatus as SYNC_GET_AUTODESK_FORGE_FILTERS_ISSUE_STATUS } from 'graphql/queries';
import AutocompleteDropdown from 'modules/Sync/components/AutocompleteDropdown';
import { MANAGE_ISSUES_FILTERS } from 'modules/Sync/Constants/canvasActionConstants';
import {
  ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION,
  MAX_DROPDOWN_ITEMS_IN_VIEW
} from 'modules/Sync/Constants/constants';
import { useManageIssuesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/ManageIssuesContext';
import { updateFilterCriteria } from 'modules/Sync/WorkflowEditor/helpers/ManageIssuesHelpers/manageIssuesPayloadHelper';
import { validateFilterCriteria } from 'modules/Sync/WorkflowEditor/helpers/ManageIssuesHelpers/validationHelper';

const FILTER_TYPE_LIMIT = 1;
const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;
const FILTER = MANAGE_ISSUES_FILTERS.STATUS;

const ForgeFilterIssueStatusDropdown = ({
  label = FILTER,
  filterKey = '',
  limitTags = FILTER_TYPE_LIMIT,
  selectAll = false,
  isSource,
  nodeId,
  connectionId,
  ...rest
}) => {
  const { getConnectorNodeValues, updateConnectorNodeValues } = useManageIssuesFormContext();
  const [
    lazyLoadFiltersIssueStatus,
    { data: { syncGetAutodeskForgeFiltersIssueStatus: issueStatusFilterOptions = [] } = {} }
  ] = useLazyQuery(gql(SYNC_GET_AUTODESK_FORGE_FILTERS_ISSUE_STATUS), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { hub, project, filterCriteria = [] } = formValues;

  const value = useMemo(() => {
    const currentIssueStatus = filterCriteria?.find(({ property }) => property === filterKey).value;
    const parsedCurrentIssueStatus = currentIssueStatus.split(',').map((value) => ({
      value,
      label: value ? issueStatusFilterOptions?.find(({ id }) => id === value)?.text : ''
    }));

    if (parsedCurrentIssueStatus?.length === issueStatusFilterOptions?.length)
      parsedCurrentIssueStatus.push(ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION);

    return currentIssueStatus && issueStatusFilterOptions?.length ? parsedCurrentIssueStatus : [];
  }, [filterCriteria, filterKey, issueStatusFilterOptions]);

  const standardOptions = useMemo(
    () => issueStatusFilterOptions?.map(({ id = '', text = '' } = {}) => ({ value: id, label: text })) || [],
    [issueStatusFilterOptions]
  );

  const options = useMemo(() => {
    const selectAllOption = ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION;
    return selectAll ? [selectAllOption, ...standardOptions] : standardOptions;
  }, [selectAll, standardOptions]);

  /* SyntheticBaseEvent for checkbox selection,
    list of all checked values after selection,
    selectOption | removeOption,
    selected or removed option (with value & label) */
  const onFilterTypeChange = (_, selectedFilterTypeValues, selectionType, selectedOption) => {
    let filterTypeValues = selectedFilterTypeValues.filter(
      ({ value }) => value !== ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION.value
    );

    const selectedAll =
      selectionType === 'selectOption' &&
      selectedOption?.option?.value === ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION.value;
    const deselectedAll =
      selectionType === 'removeOption' &&
      selectedOption?.option?.value === ISSUE_MANAGEMENT_FILTERS_SELECT_ALL_OPTION.value;

    if (selectedAll) filterTypeValues = options;
    if (deselectedAll) filterTypeValues = [];

    const mergedFilterTypeValue = filterTypeValues.map(({ value }) => value).join(',');
    const newFilterCriteria = updateFilterCriteria(filterCriteria, filterKey, mergedFilterTypeValue);

    const props = {
      filterCriteria: newFilterCriteria,
      isSubmitted: validateFilterCriteria(newFilterCriteria)
    };
    updateConnectorNodeValues({ nodeId, isSource, props });
  };

  useEffect(() => {
    lazyLoadFiltersIssueStatus({ variables: { query: { connectionId, hubId: hub?.id, projectId: project?.id } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AutocompleteDropdown
      value={value}
      onChange={onFilterTypeChange}
      options={options}
      label={label}
      limitTags={limitTags}
      multiple
      {...rest}
    />
  );
};

export default ForgeFilterIssueStatusDropdown;
