import { WEBHOOK_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWebhooksContext } from 'modules/Sync/Webhooks/Contexts/WebhooksContext';

const WebhookMenuItems = (row) => {
  const { setActiveModal, setSelectedWebhookIds, setSelectedWebhookDetails } =
    useSyncWebhooksContext();

  const showDeleteWebhookModal = () => setActiveModal(WEBHOOK_MODAL_KEYS.DELETE_WEBHOOK);
  const showActivateWebhookModal = () => setActiveModal(WEBHOOK_MODAL_KEYS.ACTIVATE_WEBHOOK);
  const showDeactivateWebhookModal = () => setActiveModal(WEBHOOK_MODAL_KEYS.DEACTIVATE_WEBHOOK);

  const handleOnDelete = (row) => {
    setSelectedWebhookIds([]);
    setSelectedWebhookDetails([]);
    setSelectedWebhookDetails([row.name]);
    setSelectedWebhookIds([row.rowWebhookId]);
    showDeleteWebhookModal();
  };
  const handleOnActivate = (row) => {
    setSelectedWebhookIds([]);
    setSelectedWebhookIds([row.rowWebhookId]);
    showActivateWebhookModal();
  };
  const handleOnDeactivate = (row) => {
    setSelectedWebhookIds([]);
    setSelectedWebhookIds([row.rowWebhookId]);
    showDeactivateWebhookModal();
  };

  return [
    {
      label: !row.isActive ? 'Activate' : 'Deactivate',
      clickEvent: () => (!row.isActive ? handleOnActivate(row) : handleOnDeactivate(row))
    },
    { label: 'Delete', clickEvent: () => handleOnDelete(row), color: '#F44336' }
  ];
};
export default WebhookMenuItems;
