import React, { useEffect, useMemo } from 'react';

import { Typography } from '@mui/material';

import Accordion from 'components/Accordion/ControlledAccordion';
import { CONNECTOR_NODE_TYPES } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import AddMoreDestinationsButton from 'modules/Sync/WorkflowEditor/Modals/CopyFilesModal/AddMoreDestinationsButton';
import ConnectorForm from 'modules/Sync/WorkflowEditor/Modals/CopyFilesModal/ConnectorForm';

const MappingForm = ({ nodeId, filters = [], showFilters = false, allowPrivateFiles = false, isSource = false }) => {
  const { source, destinations, setDestinations, setCurrentConnectorType } = useCopyFilesFormContext();

  const connectorFormProps = useMemo(
    () => ({
      fileTypeFilters: filters || [],
      showFilters,
      allowPrivateFiles,
      isSource,
      nodeId
    }),
    [allowPrivateFiles, filters, nodeId, isSource, showFilters]
  );

  const onAddDestinationClick = () => setDestinations((destinations) => [...destinations, {}]);

  useEffect(() => {
    if (isSource) setCurrentConnectorType(CONNECTOR_NODE_TYPES.SOURCE);
    else setCurrentConnectorType(CONNECTOR_NODE_TYPES.DESTINATION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSource]);

  return (
    <>
      {isSource && (
        <ConnectorForm
          key={source?.nodeId}
          isSource={isSource}
          defaultOption={source?.nodeId}
          connectorFormProps={connectorFormProps}
        />
      )}
      {!isSource && (
        <Accordion
          hideFirstBorder
          hideLastBorder
          items={destinations.map(({ nodeId }, index) => ({
            nodeId,
            id: index,
            title: <Typography variant="subtitle2">Destination {index + 1}</Typography>,
            content: (
              <ConnectorForm
                key={nodeId}
                isSource={isSource}
                defaultOption={nodeId}
                connectorFormProps={connectorFormProps}
                label={`Destination ${index + 1}`}
              />
            )
          }))}
          sx={{
            marginTop: '0 !important',
            '& .MuiAccordionSummary-root, .MuiAccordionDetails-root': { padding: 0 }
          }}
        />
      )}
      <AddMoreDestinationsButton key={destinations.length} isSource={isSource} onClick={onAddDestinationClick} />
    </>
  );
};

export default MappingForm;
