import { gql, useMutation } from '@apollo/client';

import { syncSaveAll as SYNC_SAVE_ALL } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { saveSyncConfigPayload } from 'modules/Sync/WorkflowEditor/helpers/CopyFilesHelpers/copyFilesPayloadHelper';

const useSaveSyncConfigApiAction = (queryOptions) => {
  const [saveMappingFlowStatusConfiguration] = useMutation(gql(SYNC_SAVE_ALL), queryOptions);
  const { handleResponse } = useGraphqlResponseHandler();

  const saveSyncConfig = ({
    mappingId,
    mappingFlowsSyncSettings,
    onCompleted,
    successMessage = 'Mapping successfully saved'
  }) =>
    handleResponse(
      saveMappingFlowStatusConfiguration,
      {
        variables: {
          body: {
            MappingId: mappingId,
            SolitaryFlowSettings: saveSyncConfigPayload(mappingFlowsSyncSettings)
          }
        }
      },
      { successMessage },
      onCompleted
    );
  return { saveSyncConfig };
};

export default useSaveSyncConfigApiAction;
