import { gql, useMutation } from '@apollo/client';

import { syncUpdatePublishActionConfigurations as SYNC_UPDATE_PUBLISH_ACTION_CONFIGURATIONS } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { publishActionMappingConfigPayload } from 'modules/Sync/WorkflowEditor/helpers/PublishActionHelpers/publishActionPayloadHelper';

const useSaveFlowConfigApiAction = (queryOptions) => {
  const [saveFlowConfiguration] = useMutation(gql(SYNC_UPDATE_PUBLISH_ACTION_CONFIGURATIONS), queryOptions);
  const { handleResponse } = useGraphqlResponseHandler();

  const saveFlowConfig = ({
    mappingId,
    mappingFlows,
    onCompleted,
    onFailed,
    successMessage = 'Saved mapping flow configuration successfully'
  }) =>
    handleResponse(
      saveFlowConfiguration,
      {
        variables: {
          body: {
            MappingId: mappingId,
            SolitaryFlowPublishModelConfigurations: publishActionMappingConfigPayload(mappingFlows)
          }
        }
      },
      { successMessage },
      onCompleted,
      onFailed
    );
  return { saveFlowConfig };
};

export default useSaveFlowConfigApiAction;
