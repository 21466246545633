import { Stack, Typography } from '@mui/material';

import CustomTooltip from 'components/Tooltip';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import {
  getFileTerminalStatus,
  getFolderTerminalStatus,
  getMappingTerminalStatus
} from 'modules/Sync/helpers/logSyncStatusHelpers';
import ErrorCodeBtn from 'modules/Sync/Logs/components/ErrorCodeBtn';
import LoadMoreBtn from 'modules/Sync/Logs/components/LoadMoreBtn';

const terminalStatusIndicator = (status, syncType) => {
  let title = '';
  let backgroundColor = 'transparent';

  switch (syncType) {
    case 'action':
      [backgroundColor, title] = getMappingTerminalStatus(status);
      break;
    case 'folder':
      [backgroundColor, title] = getFolderTerminalStatus(status);
      break;
    case 'file':
    default:
      [backgroundColor, title] = getFileTerminalStatus(status);
      break;
  }

  return (
    <CustomTooltip title={title} arrow>
      <div style={{ display: 'flex', alignItems: 'right' }}>
        <div
          style={{
            height: 8,
            width: 8,
            borderRadius: '50%',
            marginLeft: 12,
            marginRight: 8,
            marginTop: 3,
            justifyContent: 'right',
            backgroundColor
          }}
        />
      </div>
    </CustomTooltip>
  );
};

const LogFoldersDetailsDataGridCell = ({ field, row }) => {
  if (row?.isLoadMore) {
    return field === 'name' && <LoadMoreBtn onClick={row?.onClick} />;
  }

  if (field === 'name') {
    return (
      <Stack
        spacing={1}
        direction="row"
        className={`${field}-cell`}
        sx={{
          '& .MuiTypography-body2': { display: 'flex' },
          alignItems: 'center',
          display: 'flex',
          width: '100%',
          maxWidth: 600
        }}
      >
        <Typography
          variant="body2"
          sx={{ overflowWrap: 'break-word', wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          <CustomSyncTooltip
            title={row[field]}
            sx={{
              ml: row?.level ? row?.level * 3 : 2,
              maxWidth: '30vw'
            }}
          >
            {row[field]}
          </CustomSyncTooltip>
        </Typography>
      </Stack>
    );
  }

  if (field === 'terminalStatus') {
    return terminalStatusIndicator(row[field], row.rowClass);
  }

  if (row?.isFile && field === 'errorCode' && row?.errorCode) {
    return <ErrorCodeBtn errorCode={row?.errorCode} isResyncAllowed isResyncVisible={false} />;
  }

  return (
    (
      <Typography variant="body2" width="100%" className={`${field}-cell`}>
        {row[field]}
      </Typography>
    ) || null
  );
};

export default LogFoldersDetailsDataGridCell;
