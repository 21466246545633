import { useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';
import { Box, Button, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import SwitchComponent from '@mui/material/Switch';
import { useLocation, useParams } from 'react-router-dom';

import { Custom } from 'components/Icons';
import { Columns } from 'components/ItemsDatagridPro';
import CircularProgressWithLabel from 'components/ProgressBars/CircularProgressWithLabel';
import Search from 'components/TableComponents/Search';
import CustomTooltip from 'components/Tooltip';
import { syncLogsRetry as SYNC_LOGS_RETRY } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import {
  DATAGRID_DEFAULT_PG_SIZE,
  LOG_FILTER,
  LOGS_MODAL_KEYS,
  SHOW_LOGS_SEARCH_BAR,
  SYNC_LOGS
} from 'modules/Sync/Constants/constants';
import ToggleLogsViewBtn from 'modules/Sync/Logs/components/ToggleLogsViewBtn';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import LogsDetailsToolbar from 'modules/Sync/Logs/LogsDetails/LogsDetailsHeader/LogsDetailsToolbar';
import RefreshLogsDetailsBtn from 'modules/Sync/Logs/LogsDetails/LogsDetailsHeader/RefreshLogsDetailsBtn';
import { otherColors, surfaceColors, syncColors } from 'styles/theme/colors';

const { FAILED } = SYNC_LOGS.LOGS_SYNC_STATUS;
const columnSxProps = {
  typoProps: { color: syncColors.text },
  baseButtonSx: {
    '&:hover': { backgroundColor: syncColors.tableHover },
    '&:disabled': { color: 'action.lightSurface.disabled' },
    '.MuiTypography-root.MuiTypography-button': { color: syncColors.text },
    '.MuiSvgIcon-root': { mt: 0, color: syncColors.text },
    '.MuiStack-root': { alignItems: 'center' }
  },
  popperStackSx: {
    boxShadow:
      '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)'
  },
  buttonSx: {
    '& .MuiButton-root': { '&:hover': { backgroundColor: syncColors.tableHover } },
    '& .MuiTypography-button': { color: syncColors.text }
  }
};

const tabsSx = {
  width: '30%',
  '.Mui-selected': { color: `${syncColors.text} !important` },
  '.MuiTabs-indicator': { backgroundColor: syncColors.text }
};

const syncBtnSx = {
  minWidth: 'auto',
  width: 40,
  height: 32,
  padding: 0,
  borderColor: syncColors.canvasNodeColors.default,
  ':hover': { borderColor: syncColors.canvasNodeColors.default }
};

const LogsSearchBar = ({ activeTab, searchHandler }) =>
  SHOW_LOGS_SEARCH_BAR && (
    <Search
      placeholder={`Search ${activeTab ? 'Log File...' : 'Folders…'}`}
      handleSearch={searchHandler}
      sx={{
        ml: 18,
        '& .MuiInputBase-root': { paddingLeft: 1 },
        visibility: activeTab ? 'inherit' : 'hidden'
      }}
      startAdornment={<Custom.Search style={{ fill: surfaceColors.lightSurface.secondary, marginRight: 10 }} />}
    />
  );

const LoadingIndicator = ({ loadingLogs, syncInProgress }) => (
  <Grid item alignItems="center" wrap="nowrap" justifyContent="center" display="flex" width="80%">
    {(loadingLogs || syncInProgress) && (
      <>
        <CircularProgress color="secondary" size={25} />
        <Typography variant="body2" sx={{ ml: 1, fontWeight: 400, letterSpacing: '0.15px' }}>
          {loadingLogs && 'Loading...'}
          {!loadingLogs && syncInProgress && 'Sync in progress...'}
        </Typography>
      </>
    )}
  </Grid>
);

const DebugSwitch = ({ onDebugToggle, debugging }) => (
  <Grid item>
    <FormControlLabel
      onChange={onDebugToggle}
      control={<SwitchComponent sx={{ color: syncColors.text }} color="warning" />}
      checked={debugging}
      label={
        <Typography variant="body1" sx={{ fontWeight: 400, letterSpacing: '0.15px' }}>
          Debug
        </Typography>
      }
    />
  </Grid>
);

const AutoRefreshSwitch = ({ onAutoRefreshFolderDetailsToggle, autoRefreshFolderDetails }) => (
  <Grid item>
    <FormControlLabel
      onChange={onAutoRefreshFolderDetailsToggle}
      control={<SwitchComponent sx={{ color: syncColors.text }} color="warning" />}
      checked={autoRefreshFolderDetails}
      label={
        <Typography variant="body1" sx={{ fontWeight: 400, letterSpacing: '0.15px', whiteSpace: 'nowrap' }}>
          Auto Refresh
        </Typography>
      }
      sx={{ mr: 0.5 }}
    />
  </Grid>
);

const ResyncButton = ({ handleRetrySync, retryLoading }) => (
  <CustomTooltip title="Resync" arrow placement="top">
    <Button variant="outlined" sx={{ ...syncBtnSx }} onClick={handleRetrySync} disabled={retryLoading}>
      <Custom.SyncIcon sx={{ fill: syncColors.canvasNodeColors.default }} />
    </Button>
  </CustomTooltip>
);

const LogUtilityButtons = ({ openDownloadLogsModal, progress, loading }) => {
  const { logFilter, setLogFilter } = useSyncLogsContext();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const filter = searchParams.get('filter');

  useEffect(() => {
    if (filter) setLogFilter(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  return (
    <Stack direction="row" justifyContent="space-between" mr="auto" gap={2}>
      <ToggleLogsViewBtn value={logFilter} onChange={setLogFilter} disabled={loading} />
      <Stack direction="row" justifyContent="space-between" mr="auto" gap={1}>
        <CustomTooltip title="Download file" arrow placement="top">
          <Button
            variant="outlined"
            sx={{ minWidth: 'auto', width: 40, height: 40, padding: 0, borderColor: otherColors.greyOutlineColor }}
            onClick={openDownloadLogsModal}
            disabled={progress}
          >
            {progress ? <CircularProgressWithLabel value={progress} /> : <Custom.Download2 fillOpacity={0.5} />}
          </Button>
        </CustomTooltip>
      </Stack>
    </Stack>
  );
};

const LogDetailsHeader = (props) => {
  const { activeTab, setActiveTab, searchHandler, setActiveModal, debugHandler, setExpandedRows } = props;
  const { loadingLogs = false, syncInProgress = false, debugging, logDetails, expandedRows, apiRef } = props;
  const { loadLogActionDetails, logActionDetailsData, autoRefreshFolderDetails } = props;
  const { autoRefreshFolderDetailsHandler, refetchExistingLogsDetailsPages, progress, syncStatus } = props;
  const { handleResponse } = useGraphqlResponseHandler();
  const { logFilter, setLogFilter } = useSyncLogsContext();
  const { id: fileSyncLogId = '' } = useParams();

  const [retrySync, { loading: retryLoading }] = useMutation(gql(SYNC_LOGS_RETRY));
  const handleRetrySync = async () => {
    if (!fileSyncLogId) return;
    await handleResponse(
      retrySync,
      { variables: { query: { FileSyncLogId: fileSyncLogId } } },
      { successMessage: 'Retrying sync for the file' }
    );
  };

  const onChangeHandler = (event, index) => {
    event.preventDefault();
    setActiveTab(index);
    setLogFilter(LOG_FILTER.ALL);
  };

  const openDownloadLogsModal = () => setActiveModal(LOGS_MODAL_KEYS.DOWNLOAD_SYNC_LOGS);

  const onDebugToggle = (_, state) =>
    debugHandler(state, logDetails?.syncLogFileDetails?.length || DATAGRID_DEFAULT_PG_SIZE);

  const onAutoRefreshFolderDetailsToggle = (_, state) => autoRefreshFolderDetailsHandler(state);

  useEffect(() => {
    if (fileSyncLogId) {
      const fetchFoldersData = async () => loadLogActionDetails({ variables: { query: { fileSyncLogId, logFilter } } });
      fetchFoldersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSyncLogId, logFilter]);

  return (
    <Box sx={{ marginBottom: 1 }}>
      <Stack direction="row" justifyContent="space-between">
        <LogsDetailsToolbar />
        <Tabs value={activeTab} onChange={onChangeHandler} variant="fullWidth" sx={tabsSx}>
          <StyledTab label="Folders" />
          <StyledTab label="Log File" />
        </Tabs>
        <LogsSearchBar activeTab={activeTab} searchHandler={searchHandler} />
      </Stack>
      <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center" mt={2}>
        <LogUtilityButtons openDownloadLogsModal={openDownloadLogsModal} progress={progress} loading={loadingLogs} />
        <LoadingIndicator loadingLogs={loadingLogs} syncInProgress={syncInProgress} />
        <Grid
          container
          item
          spacing={2}
          alignItems="center"
          wrap="nowrap"
          justifyContent="flex-end"
          width="auto"
          sx={{ '& .MuiButtonBase-root ': { marginRight: 0 } }}
        >
          {!activeTab && (
            <>
              <Grid item>
                <Columns disableColumnSearch {...columnSxProps} />
              </Grid>
              <AutoRefreshSwitch
                onAutoRefreshFolderDetailsToggle={onAutoRefreshFolderDetailsToggle}
                autoRefreshFolderDetails={autoRefreshFolderDetails}
              />
            </>
          )}
          {!!activeTab && <DebugSwitch onDebugToggle={onDebugToggle} debugging={debugging} />}
          <Grid item>
            <RefreshLogsDetailsBtn
              logDetails={logDetails}
              activeTab={activeTab}
              debugging={debugging}
              loading={loadingLogs}
              refetchExistingLogsDetailsPages={refetchExistingLogsDetailsPages}
              loadLogActionDetails={loadLogActionDetails}
              logActionDetailsData={logActionDetailsData}
              apiRef={apiRef}
              expandedRows={expandedRows}
              setExpandedRows={setExpandedRows}
              logFilter={logFilter}
            />
          </Grid>
          <Grid item>
            {syncStatus === FAILED && <ResyncButton handleRetrySync={handleRetrySync} retryLoading={retryLoading} />}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogDetailsHeader;

const StyledTab = ({ sx, ...rest }) => (
  <Tab
    disableRipple
    {...rest}
    sx={{
      color: 'secondary',
      marginRight: 1,
      fontWeight: 500,
      fontSize: 15,
      ...sx
    }}
  />
);
