import { FORMATS, SCHEDULE_SYNC, SYNC_DAYS } from 'modules/Sync/Constants/constants';

export const getSyncScheduledDays = ({ Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday }) => [
  ...(Sunday ? ['Sunday'] : []),
  ...(Monday ? ['Monday'] : []),
  ...(Tuesday ? ['Tuesday'] : []),
  ...(Wednesday ? ['Wednesday'] : []),
  ...(Thursday ? ['Thursday'] : []),
  ...(Friday ? ['Friday'] : []),
  ...(Saturday ? ['Saturday'] : [])
];

export const parseResponseScheduleSyncJson = (scheduleSyncJson) => {
  const scheduleSyncConfig = JSON.parse(scheduleSyncJson || '{}');
  const { days = [], startTime = '' } = scheduleSyncConfig;
  const SUN = days.includes('SUN');
  const MON = days.includes('MON');
  const TUE = days.includes('TUE');
  const WED = days.includes('WED');
  const THU = days.includes('THU');
  const FRI = days.includes('FRI');
  const SAT = days.includes('SAT');
  const [hours = '', minutes = '', format = 'AM'] = startTime.split(/[:,\s]/);
  return {
    Sunday: !!SUN,
    Monday: !!MON,
    Tuesday: !!TUE,
    Wednesday: !!WED,
    Thursday: !!THU,
    Friday: !!FRI,
    Saturday: !!SAT,
    days: [
      ...(SUN ? ['Sunday'] : []),
      ...(MON ? ['Monday'] : []),
      ...(TUE ? ['Tuesday'] : []),
      ...(WED ? ['Wednesday'] : []),
      ...(THU ? ['Thursday'] : []),
      ...(FRI ? ['Friday'] : []),
      ...(SAT ? ['Saturday'] : [])
    ],
    hours,
    minutes,
    format,
    ...scheduleSyncConfig
  };
};

export const parseRequestScheduleSyncJson = (scheduleSyncConfig = {}) => {
  const { hours = '', minutes = '', format = '' } = scheduleSyncConfig || {};
  const startTime = `${hours}:${minutes} ${format}`;
  const days = SYNC_DAYS.reduce((days, day) => {
    if (scheduleSyncConfig?.[day]) {
      days.push(day.slice(0, 3).toUpperCase());
    }
    return days;
  }, []);

  return JSON.stringify({
    days,
    startTime,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    repeatDurationMinutes: 0,
    repeatMinutes: 0
  });
};

export const getScheduleSyncConfigPayload = (config, currentConfigObj = {}) => ({
  ...currentConfigObj,
  ...(config.Sunday !== undefined ? { Sunday: config.Sunday } : {}),
  ...(config.Monday !== undefined ? { Monday: config.Monday } : {}),
  ...(config.Tuesday !== undefined ? { Tuesday: config.Tuesday } : {}),
  ...(config.Wednesday !== undefined ? { Wednesday: config.Wednesday } : {}),
  ...(config.Thursday !== undefined ? { Thursday: config.Thursday } : {}),
  ...(config.Friday !== undefined ? { Friday: config.Friday } : {}),
  ...(config.Saturday !== undefined ? { Saturday: config.Saturday } : {}),
  ...(config.hours !== undefined ? { hours: config.hours } : {}),
  ...(config.minutes !== undefined ? { minutes: config.minutes } : {}),
  ...(config.format !== undefined ? { format: config.format } : {}),
  repeatMinutes: 0,
  repeatDurationMinutes: 0
});

export const validateSyncSettings = (syncSettings) => {
  if (!syncSettings?.isSyncEnabled) {
    return true;
  }

  if (syncSettings?.syncType && syncSettings?.syncType !== SCHEDULE_SYNC) {
    return true;
  }

  const { scheduleSyncConfig, scheduleSyncConfig: { format, hours, minutes } = {} } = syncSettings || {};

  if (
    scheduleSyncConfig &&
    getSyncScheduledDays(scheduleSyncConfig)?.length > 0 &&
    FORMATS.includes(format) &&
    hours &&
    Number(hours) < 13 &&
    Number(hours) > 0 &&
    minutes &&
    Number(minutes) < 60 &&
    Number(minutes) >= 0
  ) {
    return true;
  }

  return false;
};
