import React from 'react';

import { Box, IconButton } from '@mui/material';
import { gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';

import { Custom } from 'components/Icons';
import { isNavigationKey } from 'helpers/common';
import { surfaceColors } from 'styles/theme/colors';

const WebhooksDataGridGroupingCell = (props) => {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent('cellNavigationKeyDown', props, event);
    }
  };

  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  if (filteredDescendantCount <= 0) return null;

  return (
    <Box sx={{ ml: rowNode.depth * 3 }} className="expansion-icon">
      <IconButton onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={-1} size="small">
        {rowNode?.childrenExpanded ? (
          <Custom.ArrowDropDownIcon style={{ fill: surfaceColors.lightSurface.primary }} />
        ) : (
          <Custom.ArrowDropDownIcon
            style={{
              transform: 'rotate(-90deg)'
            }}
          />
        )}
      </IconButton>
    </Box>
  );
};

export default WebhooksDataGridGroupingCell;
