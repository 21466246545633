import { useMemo } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import ItemsDataGridPro from 'components/ItemsDatagridPro';
import { syncRealtimeLogsRetry as SYNC_REALTIME_LOGS_RETRY } from 'graphql/mutations';
import { getTimeZone } from 'helpers/dateFunctions';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { DATAGRID_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import useRealtimeLogsFileLazyPaginationHook from 'modules/Sync/Logs/Contexts/useRealtimeLogsFileLazyPaginationHook';
import useRealtimeLogsLazyPaginationHook from 'modules/Sync/Logs/Contexts/useRealtimeLogsLazyPaginationHook';
import NoRowsOverlay from 'modules/Sync/Logs/NoRowsOverlay';
import { getDownloadLogRow } from 'modules/Sync/Logs/RealTimeLogsDataGridTable/realtimeDataGridHelpers';
import RealTimeLogDetailsDataGridGroupingCell from 'modules/Sync/Logs/RealTimeLogsDataGridTable/RealTimeLogDetailsDataGridGroupingCell';
import realTimeLogsDataGridStyles from 'modules/Sync/Logs/RealTimeLogsDataGridTable/realTimeLogsDataGridStyles';
import RealTimeLogsDataGridTableCell from 'modules/Sync/Logs/RealTimeLogsDataGridTable/RealTimeLogsDataGridTableCell';
import RealTimeLogsDataGridTableColumns from 'modules/Sync/Logs/RealTimeLogsDataGridTable/RealTimeLogsDataGridTableColumns';
import RealTimeLogsDataGridTableRows, {
  formatFilesToRows
} from 'modules/Sync/Logs/RealTimeLogsDataGridTable/RealTimeLogsDataGridTableRows';
import RealTimeLogsDataHeader from 'modules/Sync/Logs/RealTimeLogsDataGridTable/RealTimeLogsDataHeader';
import useRealtimeDataGridTableHooks from 'modules/Sync/Logs/RealTimeLogsDataGridTable/useRealtimeDataGridTableHooks';

const NoRowsOverlayText = () => <span>Sync a mapping or workflow to view log reports.</span>;

const DATAGRID_PROPS = {
  treeData: true,
  getTreeDataPath: (row) => row.path,
  disableChildrenSorting: true,
  disableChildrenFiltering: true,
  experimentalFeatures: { rowPinning: true, newEditingApi: true },
  density: 'standard',
  checkboxSelection: false,
  disableColumnMenu: true,
  disableSelectionOnClick: true,
  sortingMode: 'client',
  getRowClassName: (params) => params?.row?.rowClass
};

const getGroupingColDef = ({ lastParentRowId, onScroll }) => ({
  headerName: '',
  sortable: false,
  maxWidth: 50,
  renderCell: (params) => (
    <RealTimeLogDetailsDataGridGroupingCell {...params} onWaypointEnter={onScroll} lastParentRowId={lastParentRowId} />
  )
});

const RealTimeLogsDataGridTable = () => {
  const { handleResponse } = useGraphqlResponseHandler();
  const { setRealTimeLogsSearchPhrase, realTimeLogSearchPhrase, setActiveModal, logFilter } = useSyncLogsContext();
  const {
    realTimeLogsPaginationHandler,
    loadingRealtimeLogs,
    realTimelogsData,
    realTimeLogsSortHandler,
    realTimeLogsSearchHandler,
    lazyLoadRealTimeLogs
  } = useRealtimeLogsLazyPaginationHook();
  const { lazyLoadRealTimeLogFiles, realTimelogFilesData, loadingRealtimeLogFiles } =
    useRealtimeLogsFileLazyPaginationHook();

  const isLoadingRows = loadingRealtimeLogs || loadingRealtimeLogFiles;
  const [retryRealtimeSync] = useMutation(gql(SYNC_REALTIME_LOGS_RETRY));
  const handleRetrySync = async (FileSyncLogId, callback = () => null) => {
    if (!FileSyncLogId) return;
    await handleResponse(
      retryRealtimeSync,
      { variables: { query: { FileSyncLogId } } },
      { successMessage: 'Retrying sync for the file' },
      () => callback()
    );
  };

  const apiRef = useGridApiRef();
  const timeZone = useMemo(() => getTimeZone(), []);

  const handleCellComponent = ({ field, row }) => (
    <RealTimeLogsDataGridTableCell
      field={field}
      row={row}
      setActiveModal={setActiveModal}
      retrySync={handleRetrySync}
      apiRef={apiRef}
    />
  );
  const queryProps = { searchPhrase: realTimeLogSearchPhrase, timeZone, logFilter };
  const onScroll = () =>
    realTimeLogsPaginationHandler(realTimelogsData?.syncRealTimeLogDates?.length, { ...queryProps });

  const columns = RealTimeLogsDataGridTableColumns(handleCellComponent);
  const rows = useMemo(
    () => RealTimeLogsDataGridTableRows(realTimelogsData?.syncRealTimeLogDates),
    [realTimelogsData?.syncRealTimeLogDates]
  );

  let fileRows = [];
  if (rows?.length) {
    const { date, id } = rows[0];
    fileRows = formatFilesToRows(realTimelogFilesData?.syncRealTimeLogs, id) || [];
    if (realTimelogFilesData?.syncRealTimeLogs?.length === DATAGRID_DEFAULT_PG_SIZE) {
      const downloadRow = getDownloadLogRow(id, date);
      fileRows.push(...downloadRow);
    }
  }

  const lastParentRowId = rows?.filter(({ isParent }) => isParent)?.at(-1)?.id;
  const groupingColDef = getGroupingColDef({ lastParentRowId, onScroll });
  const allRows = useMemo(
    () => [...rows, ...fileRows],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [realTimelogsData?.syncRealTimeLogDates, realTimelogFilesData?.syncRealTimeLogs]
  );

  const handleSort = (sortModel) =>
    sortModel.length ? realTimeLogsSortHandler({ columnName: sortModel?.[0]?.field }) : null;

  const onSearch = async (phrase) => {
    setRealTimeLogsSearchPhrase(phrase);
    await realTimeLogsSearchHandler(phrase, { timeZone, logFilter });
  };

  const HOOK_PROPS = { apiRef, rows, timeZone, logFilter, realTimelogsData, loadingRealtimeLogs };
  useRealtimeDataGridTableHooks({
    searchPhrase: realTimeLogSearchPhrase,
    lazyLoadRealTimeLogs,
    lazyLoadRealTimeLogFiles,
    ...HOOK_PROPS
  });

  return (
    <>
      <RealTimeLogsDataHeader
        searchHandler={onSearch}
        apiRef={apiRef}
        lazyLoadRealTimeLogs={lazyLoadRealTimeLogs}
        isLoading={isLoadingRows}
      />
      <ItemsDataGridPro
        apiRef={apiRef}
        groupingColDef={groupingColDef}
        isGroupExpandedByDefault={(node) => rows?.[0]?.id === node?.id || rows?.[1]?.id === node?.id}
        components={{ NoRowsOverlay: () => <NoRowsOverlay text={<NoRowsOverlayText />} /> }}
        columns={columns}
        rows={allRows}
        loading={isLoadingRows}
        asyncApi={{ onScroll }}
        onSortModelChange={handleSort}
        sortingOrder={['asc', 'desc']}
        sx={realTimeLogsDataGridStyles}
        {...DATAGRID_PROPS}
      />
    </>
  );
};

export default RealTimeLogsDataGridTable;
