const WorkflowsDataGridTableColumns = (handleCellComponent) => [
  {
    field: 'name',
    headerName: 'Workflow/Mapping/Action',
    width: 250,
    minWidth: 200,
    maxWidth: 550,
    hideable: false,
    editable: false,
    resizable: true,
    renderCell: handleCellComponent
  },
  {
    field: 'syncStatus',
    headerName: 'Status',
    width: 100,
    minWidth: 120,
    maxWidth: 200,
    sortable: false,
    resizable: true,
    renderCell: handleCellComponent
  },
  {
    field: 'syncType',
    headerName: 'Sync Type',
    width: 150,
    minWidth: 100,
    maxWidth: 200,
    sortable: false,
    resizable: true,
    renderCell: handleCellComponent
  },
  {
    field: 'lastSyncAt',
    headerName: 'Last Synced',
    width: 200,
    minWidth: 150,
    sortable: false,
    maxWidth: 200,
    resizable: true,
    renderCell: handleCellComponent
  },
  {
    field: 'lastSavedAt',
    headerName: 'Last Saved',
    width: 200,
    minWidth: 150,
    maxWidth: 200,
    sortable: false,
    resizable: true,
    renderCell: handleCellComponent
  }
];

export default WorkflowsDataGridTableColumns;
