import React, { useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

import getClient from 'apollo/client';
import { Custom } from 'components/Icons';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { convertToCamelCase } from 'helpers/stringFunctions';
import useCache from 'hooks/useCache';
import {
  getSyncFolderQueriesForConnection,
  getSyncQueriesForRefetch
} from 'modules/Sync/WorkflowEditor/helpers/CopyFilesHelpers/connectionsHelper';
import { surfaceColors } from 'styles/theme/colors';

import { loggingHelper } from '../helpers/canvasHelper';

const AUTO_HIDE_DURATION = 5000;

const RefreshGqlCache = ({ connections }) => {
  const { enqueueSnackbar } = useSnackbar() || {};
  const { deleteCache } = useCache();
  const [loading, setLoading] = useState(false);

  const deleteFetchedFolderPages = async (connections) => {
    /* Clean all the paginated data for specified connection(s) folders */
    const queries = getSyncFolderQueriesForConnection(connections);
    await Promise.all(queries.map(async (query) => deleteCache(convertToCamelCase(query))));
  };

  const onRefresh = async () => {
    const queriesToRefetch = getSyncQueriesForRefetch(connections);
    setLoading(true);
    await deleteFetchedFolderPages(connections);
    const refetchQuery = async (promisedResponse, query) => {
      const response = await promisedResponse;

      response?.map(({ errors }) =>
        errors?.forEach((error) => {
          let errorMessage = '';

          if (error?.errorInfo) {
            errorMessage = Object.values(error?.errorInfo).toString();
          } else if (error?.message) {
            errorMessage = error?.message;
          }

          enqueueSnackbar(errorMessage, {
            autoHideDuration: AUTO_HIDE_DURATION,
            ...VARIANT_ERROR
          });
        })
      );

      const client = await getClient();
      return client.refetchQueries({ include: [query] });
    };

    try {
      await queriesToRefetch.reduce(refetchQuery, Promise.resolve());
    } catch {
      loggingHelper(`Error refetching following APIs: ${queriesToRefetch.join(',')}`);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <CircularProgress size={15} color="warning" sx={{ marginTop: 1 }} />
  ) : (
    <Custom.Refresh
      onClick={onRefresh}
      style={{ fill: surfaceColors.lightSurface.secondary, marginTop: 1, cursor: 'pointer' }}
    />
  );
};

export default RefreshGqlCache;
