import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';

import EmptyDataGridCell from 'modules/Sync/components/EmptyDataGridCell';
import TemplatesDataGridTableColumns from 'modules/Sync/Workflows/TemplatesDataGridTable/TemplatesDataGridTableColumns';

export const initialRows = [{ id: 0, name: '+ New Template' }];

export const templatesMapper = (templates = []) => {
  if (!templates) return [];
  return templates.map(({ templateId, templateName }, index) => ({
    id: templateId,
    name: templateName || <EmptyDataGridCell />,
    isLast: index === templates?.length - 1
  }));
};

export const getColumns = ({ renderCell, renderHeader }) => [
  { ...GRID_CHECKBOX_SELECTION_COL_DEF, type: '', hideable: false },
  { ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF, hideable: false },
  ...TemplatesDataGridTableColumns(renderCell),
  {
    field: '*options',
    headerName: '',
    flex: 1,
    align: 'right',
    hideable: false,
    sortable: false,
    renderCell,
    renderHeader
  }
];

export const defaultDataGridConfig = {
  density: 'standard',
  disableColumnMenu: true,
  disableSelectionOnClick: true,
  experimentalFeatures: { rowPinning: true, newEditingApi: true },
  initialState: { pinnedColumns: { left: [], right: ['*options'] } }
};

export const dataGridStyles = {
  marginBottom: 1,
  '.MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer',
      '& .MuiSvgIcon-root': { opacity: 1 },
      '.MuiTypography-root': {
        textDecoration: 'underline'
      }
    }
  },
  '.mapping-row > .MuiDataGrid-cellCheckbox': {
    paddingLeft: 5
  },
  '.mapping-row > .MuiDataGrid-cell[data-field="__tree_data_group__"]': {
    margin: 0
  },
  '.MuiDataGrid-columnHeader[data-field="*options"] .MuiDataGrid-columnHeaderDraggableContainer': {
    '.MuiDataGrid-columnHeaderTitleContainer:last-of-type': {
      justifyContent: 'right',
      marginRight: '8px'
    }
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 500
  },
  '.MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-pinnedColumns': {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  }
};
