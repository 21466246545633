import React from 'react';

import { List } from '@mui/material';

import TabListItem from './TabListItem';

const ActionTab = ({ data: actionAlias, isArchived }) => {
  const title = isArchived ? 'Action (Archived)' : 'Action';
  return (
    <List sx={{ paddingTop: 0 }}>
      <TabListItem title={title} content={actionAlias} />
    </List>
  );
};

export default ActionTab;
