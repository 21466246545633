import { useEffect, useMemo } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { RightSideFlyout } from 'components';
import { Custom } from 'components/Icons';
import ListFlyoutPaper from 'components/ListFlyoutPaper';
import { formatTo } from 'helpers/dateFunctions';
import { convertBytes } from 'modules/Sync/helpers/common';
import useLazyLoadLogsDataUsageReportsHook from 'modules/Sync/Logs/Contexts/useLazyLoadLogsDataUsageReportsHook';
import { surfaceColors, syncColors } from 'styles/theme/colors';

const currentYear = formatTo(Date.now(), 'YYYY');

const LogsDataUsageInfo = () => {
  const { loadLogsDataUsageReport, logsDataUsageReport, loadingLogsDataUsageReport } =
    useLazyLoadLogsDataUsageReportsHook();

  const { monthlyDataUsage = 0, yearlyDataUsage = 0 } = logsDataUsageReport?.syncLogsDataUsageReport || {};

  useEffect(() => {
    loadLogsDataUsageReport();
  }, [loadLogsDataUsageReport]);

  const flyoutItems = useMemo(
    () => ({
      staticList: [
        {
          section: 'logs-data-usage',
          icon: () => <Custom.DataUsage width={24} height={24} fill="#FFF" />,
          children: ({ onPanelClose }) => (
            <ListFlyoutPaper
              key="logs-data-usage"
              defaultSection="logs-data-usage"
              onClose={onPanelClose}
              sx={{
                width: 363,
                top: 'auto',
                bottom: 25,
                zIndex: 'auto',
                left: 68,
                color: '#FFF'
              }}
              topRightActionsSx={{ display: 'none' }}
            >
              <ListFlyoutPaper.Items sx={{ m: 1 }}>
                <Typography variant="body1" fontWeight={700} pl={2} my={1}>
                  Total Data
                </Typography>
                <Typography variant="body1" fontWeight={400} pl={2}>
                  {convertBytes(monthlyDataUsage)} used this month
                </Typography>
                <Typography variant="body1" fontWeight={400} pl={2}>
                  {convertBytes(yearlyDataUsage)} used in {currentYear}
                </Typography>
                <Box mt={2} display="flex" justifyContent="right">
                  <Button
                    onClick={onPanelClose}
                    variant="contained"
                    sx={{
                      '&.MuiButton-root': {
                        backgroundColor: syncColors.mainDarkBackground,
                        color: surfaceColors.lightSurface.primary,
                        width: 40,
                        fontWeight: 500,
                        '&:hover': { backgroundColor: 'secondary.dark' }
                      }
                    }}
                  >
                    Done
                  </Button>
                </Box>
              </ListFlyoutPaper.Items>
            </ListFlyoutPaper>
          )
        }
      ]
    }),
    [monthlyDataUsage, yearlyDataUsage]
  );

  return (
    !loadingLogsDataUsageReport && (
      <RightSideFlyout
        flyoutItems={flyoutItems}
        iconsContainerSx={{
          bottom: 25,
          left: 48,
          borderRadius: 20,
          '& .MuiStack-root': { borderRadius: 20 },
          '& :hover': { borderRadius: 20 },
          backgroundColor: syncColors.logsColors.dataUsageReportFlyout,
          boxShadow:
            '0px 4px 18px 3px rgba(0, 0, 0, 0.12), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 6px 6px -3px rgba(0, 0, 0, 0.20)'
        }}
      />
    )
  );
};

export default LogsDataUsageInfo;
