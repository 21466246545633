/* eslint-disable no-underscore-dangle */
import React from 'react';

import createCache from '@emotion/cache';
import ReactDOM from 'react-dom';
import '@mui/styles';

import getClient from 'apollo/client';
import App from 'App';
import { dispatchMicroFeLoadedEvent, hideOverflowOnMicroFeContainer } from 'helpers/domHelpers';
import { Wrapper } from 'Wrapper';

class SyncModuleContentWebComponent extends HTMLElement {
  constructor() {
    super();
    this.currentTitle = `${this.getAttribute('title')}`;
    this._client = window.__APOLLO_CLIENT__;
    this.root = this.attachShadow({ mode: 'open' });
    this.emotionRoot = document.createElement('style');
    this.mountPoint = document.createElement('div');
  }

  connectedCallback() {
    this.root.appendChild(this.emotionRoot);
    this.root.appendChild(this.mountPoint);
    
    // eslint-disable-next-line no-eval
    window.eval('getSyncUIScriptElement')();

    const cache = createCache({
      key: 'css',
      prepend: true,
      container: this.emotionRoot
    });

    getClient().then((client) => {
      this.render(cache, this.mountPoint, client);

      dispatchMicroFeLoadedEvent();
      
      hideOverflowOnMicroFeContainer();
    })


  }

  disconnectedCallback() {
    ReactDOM.unmountComponentAtNode(this.mountPoint);
    this.shadowRoot.removeChild(this.mountPoint);
  }

  render(cache, mountPoint, client) {
    ReactDOM.render(
      <Wrapper cache={cache} mountPoint={mountPoint}>
        <App client={client}/>
      </Wrapper>,
      mountPoint
    );
  }
}

export { SyncModuleContentWebComponent };
