import React from 'react';

import { Button } from '@mui/material';

export const AddOptionButton = ({ children, sx = {} }) => (
  <Button
    sx={{
      fontWeight: 400,
      letterSpacing: '0.15px',
      fontSize: '14px',
      textTransform: 'none',
      width: '100%',
      height: '40px',
      justifyContent: 'start',
      pl: 2,
      cursor: 'pointer',
      ...sx
    }}
  >
    + {children}
  </Button>
);
