import React from 'react';

import { Stack } from '@mui/material';
import { grey } from '@mui/material/colors';

import SearchTextInput from 'components/SearchTextInput';

const ListFlyoutPaperSearch = ({
  sx,
  InputSx,
  placeholder = 'Search Tasks...',
  disabled = false,
  onSearch = () => {}
}) => (
  <Stack sx={{ p: 2, ...sx }}>
    <SearchTextInput
      disabled={disabled}
      placeholder={placeholder}
      sx={{
        width: 'auto',
        mt: '2px',
        backgroundColor: grey[700],
        '&:hover': {
          backgroundColor: grey[700]
        },
        ...InputSx
      }}
      overrideInputSx={{ color: '#ffff' }}
      onChange={onSearch}
    />
  </Stack>
);

export default ListFlyoutPaperSearch;
