import * as React from 'react';

// import { useModule } from 'app/ModuleContext';

import IconsController from './IconsController';

const RightSideFlyout = ({
  flyoutItems = {},
  defaultSection = null,
  onDock = () => {},
  dynamicList = [],
  closeFlyoutMenu = false,
  setCloseFlyoutMenu = () => {},
  fixed,
  iconsContainerSx = {},
  hasAlreadyDecisions = false,
  addItemsLoading = false
}) => {
  const [section, setSection] = React.useState(defaultSection);
  const [itemSelected, setItemSelected] = React.useState(null);
  const [childrenSelected, setChildrenSelected] = React.useState(null);
  // const { popperRef, rightSideFlyoutRef } = useModule();

  const onSwitchSection = React.useCallback(
    (type) => {
      if (type === section) {
        setItemSelected(null);
        setSection(null);
      } else {
        // if (popperRef.current?.isOpen) popperRef.current?.close();
        setSection(type);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [section]
  );
  // React.useImperativeHandle(rightSideFlyoutRef, () => ({
  //   close: () => onSwitchSection(null)
  // }));

  React.useEffect(() => {
    if (closeFlyoutMenu) {
      onSwitchSection(null);
      setCloseFlyoutMenu(false);
    }
  }, [closeFlyoutMenu, onSwitchSection, setCloseFlyoutMenu]);

  React.useEffect(() => {
    if (flyoutItems?.staticList && flyoutItems?.dynamicList) {
      throw new Error('RightSideFlyout can not use staticList and dynamicList at same time');
    }
    if (flyoutItems?.staticList && section) {
      const sectionSelected = (flyoutItems?.staticList ?? []).find((item) => item.section === section);
      setChildrenSelected(sectionSelected);
      setItemSelected(sectionSelected);
    }
    if (flyoutItems?.dynamicList && section) {
      const sectionSelected = (flyoutItems?.dynamicList?.sections ?? []).find((item) => item.section === section);
      setChildrenSelected(flyoutItems.dynamicList);
      setItemSelected(sectionSelected);
      flyoutItems.dynamicList?.trigger(section);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  return (
    <>
      <IconsController
        onClick={onSwitchSection}
        sectionSelected={section}
        config={flyoutItems}
        fixed={fixed}
        iconsContainerSx={iconsContainerSx}
      />
      {section &&
        itemSelected &&
        childrenSelected.children({
          onPanelClose: () => onSwitchSection(null),
          forceSwitchSection: (section) => onSwitchSection(section),
          onDock,
          data: dynamicList,
          section,
          hasAlreadyDecisions,
          addItemsLoading
        })}
    </>
  );
};

export default RightSideFlyout;
