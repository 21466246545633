import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';
import { Columns } from 'components/ItemsDatagridPro';
import Search from 'components/TableComponents/Search';
import NewTemplateButton from 'modules/Sync/Workflows/components/NewTemplateButton';
import NewWorkflowButton from 'modules/Sync/Workflows/components/NewWorkflowButton';
import useArchivedWorkflowsLazyPaginationHook from 'modules/Sync/Workflows/Contexts/useArchivedWorkflowsLazyPaginationHook';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import ArchiveActionsMenu from 'modules/Sync/Workflows/WorkflowsHeader/ArchiveActionMenu';
import TemplateActionsMenu from 'modules/Sync/Workflows/WorkflowsHeader/TemplateActionsMenu';
import WorkflowActionsMenu from 'modules/Sync/Workflows/WorkflowsHeader/WorkflowActionsMenu';
import { greyColors, surfaceColors, syncColors } from 'styles/theme/colors';

const SearchBar = ({ placeholder, searchHandler }) => (
  <Search
    placeholder={placeholder}
    handleSearch={searchHandler}
    sx={{
      width: 500,
      '& .MuiInputBase-root': {
        paddingLeft: 1.5,
        background: greyColors.grey300
      },
      marginLeft: 0
    }}
    startAdornment={<Custom.Search style={{ fill: surfaceColors.lightSurface.secondary, marginRight: 10 }} />}
  />
);

const WorkflowsHeader = ({ workflows, archivedWorkflows, workflowsSearchHandler, templatesSearchHandler }) => {
  const { setSearchPhrase, activeTab, setActiveTab } = useSyncWorkflowsContext();

  const { archivedWorkflowsSearchHandler } = useArchivedWorkflowsLazyPaginationHook();
  const searchHandler = (searchPhrase) => {
    setSearchPhrase(searchPhrase);
    return (
      (activeTab === 1 && templatesSearchHandler(searchPhrase)) ||
      (activeTab === 0 && workflowsSearchHandler(searchPhrase)) ||
      (activeTab === 2 && archivedWorkflowsSearchHandler(searchPhrase))
    );
  };

  const onChangeHandler = (event, index) => {
    event.preventDefault();
    setActiveTab(index);
  };

  return (
    <HeaderWrapper>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" sx={{ color: 'black', lineHeight: '2.2' }}>
          <strong>Workflows</strong>
        </Typography>
        <Tabs value={activeTab} onChange={onChangeHandler} variant="fullWidth" sx={Tabsx}>
          <StyledTab label="Workflows" />
          <StyledTab label="Templates" />
          <StyledTab label="Archive" />
        </Tabs>

        {activeTab === 0 && <SearchBar searchHandler={searchHandler} placeholder="Search workflows..." />}
        {activeTab === 1 && <SearchBar searchHandler={searchHandler} placeholder="Search templates..." />}
        {activeTab === 2 && <SearchBar searchHandler={searchHandler} placeholder="Search workflows..." />}
        
      </Stack>
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid container item alignItems="center" wrap="nowrap">
          {activeTab === 0 && <WorkflowActionsMenu workflows={workflows} />}
          {activeTab === 1 && <TemplateActionsMenu />}
          {activeTab === 2 && <ArchiveActionsMenu archivedWorkflows={archivedWorkflows} />}
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          wrap="nowrap"
          justifyContent="flex-end"
          sx={{
            '& .MuiTypography-root, .MuiSvgIcon-root': { color: syncColors.text, lineHeight: 1.55 }
          }}
        >
          {!activeTab && (
            <Columns
              disableColumnSearch
              typoProps={{ color: syncColors.text }}
              baseButtonSx={{
                '&:hover': { backgroundColor: syncColors.tableHover },
                '&:disabled': { color: 'action.lightSurface.disabled' }
              }}
              popperStackSx={{
                boxShadow:
                  '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)'
              }}
              buttonSx={{
                '& .MuiButton-root': {
                  '&:hover': { backgroundColor: syncColors.tableHover }
                },
                '& .MuiTypography-button': {
                  color: syncColors.text
                }
              }}
            />
          )}
          {activeTab === 0 && <NewWorkflowButton />}
          {activeTab === 1 && <NewTemplateButton />}
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
};

export default WorkflowsHeader;

const HeaderWrapper = (props) => <Box mb="10px" {...props} />;

const Tabsx = {
  width: '100%',
  justifyContent: 'left',
  paddingX: 0,
  marginLeft: 4,
  '& .MuiTabs-flexContainer': { width: '60%' },
  '& .MuiTabs-indicator': { backgroundColor: syncColors.text },
  '& .Mui-selected': { color: `${syncColors.text} !important` }
};

const StyledTab = (props) => (
  <Tab
    disableRipple
    sx={{
      fontWeight: 'bold',
      fontSize: 15,
      color: 'secondary'
    }}
    {...props}
  />
);
