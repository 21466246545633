import InputSelectField from 'components/FormComponents/InputSelectField';

const ImportMappingWindowsSelect = (props) => {
  const {
    connectionNameInput,
    windowsOptions,
    isAdded,
    control,
    isSource,
    updateWindowsConnection,
    workflowId,
    mappingId,
    label
  } = props;

  const onSelectChange = (event) => {
    const connectionId = event?.target?.value;
    updateWindowsConnection({ workflowId, mappingId, connectionId, isSource });
  };

  return (
    <InputSelectField
      name={connectionNameInput}
      label={label || 'Select a connection'}
      control={control}
      options={windowsOptions}
      disabled={isAdded}
      onSelectChange={onSelectChange}
      sx={{ width: '190px' }}
    />
  );
};

export default ImportMappingWindowsSelect;
