import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { ControlledRadioGroup } from 'components';
import { formatTo, getTimeZone } from 'helpers/dateFunctions';
import useRealtimeLogsDownloader from 'hooks/useRealtimeLogsDownloader';
import CustomModal from 'modules/Sync/components/CustomModal';
import { LOG_FILTER, LOGS_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';

import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';

const defaultFormValues = { workflowName: '', mappingName: '', templateId: '' };

const { ALL, FAILED, COMPLETED } = LOG_FILTER;
const downloadLogTypes = [
  { label: 'Entire log', value: ALL },
  { label: 'Failed files log only', value: FAILED },
  { label: 'Completed files log', value: COMPLETED }
];
const timeZone = getTimeZone();

const DownloadLogsModal = ({ onClose }) => {
  const { activeModal, activeRealtimeLogDate: logDate } = useSyncLogsContext();
  const { downloadRealtimeLogsHandler, loading } = useRealtimeLogsDownloader({});

  const open = activeModal === LOGS_MODAL_KEYS.DOWNLOAD_LOGS;
  const methods = useForm({
    mode: 'all',
    defaultValues: defaultFormValues
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } = methods;

  const onModalClose = () => {
    onClose?.();
    reset(defaultFormValues);
  };

  const onSubmit = handleSubmit(async ({ logFilter }) => {
    const variables = { query: { logDate, timeZone, logFilter } };
    const formattedDate = formatTo(new Date(logDate), 'YYYY-MM-DD')
    const fileName = `Realtime-Logs-${formattedDate}`;
    await downloadRealtimeLogsHandler(variables, fileName);
    onModalClose();
  });

  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={<ModalTitle title="Download log" />}
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={
        <ModalFooter
          onModalClose={onModalClose}
          onSubmit={onSubmit}
          onSubmitDisabled={!isValid}
          createBtnText="Download"
          loading={loading}
        />
      }
    >
      <Stack gap={1}>
        <Typography variant="subtitle1" color="primary">
          Select which log you want to download:
        </Typography>

        <ControlledRadioGroup
          name="logFilter"
          radioOptions={downloadLogTypes}
          direction="column"
          control={control}
          activeColor="secondary"
          onChange={() => null}
          defaultValue={downloadLogTypes[0]?.value}
          sx={{
            ml: 2
          }}
        />
      </Stack>
    </CustomModal>
  );
};

export default DownloadLogsModal;
