import React, { useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { RightSideFlyout } from 'components';
import { IconStyled } from 'components/Icons';
import { objIsEmpty } from 'helpers/objectFunctions';
import { DRAWER_NAV_MENU_ITEMS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { IconType, getSyncIcon } from 'modules/Sync/WorkflowEditor/helpers/SyncIcons';

import ActionsDrawer from './Drawers/ActionsDrawer';
import ConnectorsDrawer from './Drawers/ConnectorsDrawer';
import PropertiesDrawer from './Drawers/PropertiesDrawer';

const DrawerNavMenu = ({ editMappingAction }) => {
  const { search } = useLocation();

  const { workflowMappings, currentMapping } = useSyncWorkflowEditorContext();
  const [selectedFlow, setSelectedFlow] = useState({});
  const queryParameters = new URLSearchParams(search);
  const isArchivedTab = queryParameters.get('isArchived');
  const activeActionId = queryParameters.get('activeAction');

  const defaultValue = activeActionId
    ? workflowMappings.findIndex(({ solitaryFlowId }) => solitaryFlowId === activeActionId)
    : 0;
  const activeAction = workflowMappings[defaultValue];

  const flyoutItems = useMemo(
    () => ({
      staticList: [
        {
          section: DRAWER_NAV_MENU_ITEMS.CONNECTOR,
          icon: () => <IconStyled icon={getSyncIcon(IconType.CONNECTIONS)} />,
          children: ({ onPanelClose }) => <ConnectorsDrawer onClose={onPanelClose} draggable />
        },
        {
          section: DRAWER_NAV_MENU_ITEMS.ACTION,
          icon: () => <IconStyled icon={getSyncIcon(IconType.ACTION)} />,
          children: ({ onPanelClose }) => <ActionsDrawer onClose={onPanelClose} draggable />
        },
        {
          section: DRAWER_NAV_MENU_ITEMS.PROPERTIES,
          icon: () => <IconStyled icon={getSyncIcon(IconType.SETTINGS)} />,
          children: ({ onPanelClose }) => (
            <PropertiesDrawer
              onClose={onPanelClose}
              editMappingAction={editMappingAction}
              isArchivedTab={isArchivedTab}
              selectedFlow={selectedFlow}
              defaultValue={defaultValue}
              loading={objIsEmpty(currentMapping)}
              draggable
            />
          )
        }
      ]
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentMapping, isArchivedTab, selectedFlow]
  );

  useEffect(() => {
    if (!activeAction?.actionNodeId) return;

    if (isArchivedTab) {
      const ArchivedWorkflows = workflowMappings.filter((el) => el.isArchived);
      setSelectedFlow(activeAction || ArchivedWorkflows?.[0]);
    }
    if (objIsEmpty(selectedFlow) && workflowMappings?.length) {
      setSelectedFlow(activeAction || workflowMappings?.[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAction?.actionNodeId]);

  return (
    <RightSideFlyout
      fixed
      key={selectedFlow?.actionNodeId}
      flyoutItems={flyoutItems}
      iconsContainerSx={{ bottom: 4 }}
      defaultSection={activeActionId ? DRAWER_NAV_MENU_ITEMS.PROPERTIES : null}
    />
  );
};

export default DrawerNavMenu;
