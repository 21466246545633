import React, { useCallback, useState } from 'react';

import { Button, MenuItem, Typography, useTheme } from '@mui/material';

import CustomMenu from 'components/Menu';
import CustomArrowDropDownIcon from 'modules/Sync/components/CustomArrowDropDownIcon';
import { WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import { syncColors } from 'styles/theme/colors';

const TemplateActionsMenu = () => {
  const {
    palette: { secondary }
  } = useTheme();
  const { selectedTemplateIds, setActiveModal } = useSyncWorkflowsContext();
  const isDeleteActionDisabled = selectedTemplateIds.length < 1;

  const [anchorEl, setAnchorEl] = useState(null);
  const updateAnchorEl = useCallback((element) => setAnchorEl(element), []);
  const open = !!anchorEl;

  const showDeleteModal = () => setActiveModal(WORKFLOW_MODAL_KEYS.DELETE_TEMPLATE);

  const handleClick = (e) => {
    updateAnchorEl(e.currentTarget);
  };
  const handleClose = () => updateAnchorEl(null);

  const handleDeleteModalOpen = (e) => {
    e.stopPropagation();
    showDeleteModal();
    handleClose();
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        endIcon={<CustomArrowDropDownIcon />}
        onClick={handleClick}
        color="warning"
        sx={{
          padding: '0 8px 0 8px',
          height: '32px',
          fontWeight: 500,
          color: syncColors.text,
          border: `solid 1px ${syncColors.outlinedRestingBorder}`,
          background: `linear-gradient(90deg, transparent 67%, ${secondary.outlinedHoverBackground} 33%)`
        }}
      >
        ACTIONS
      </Button>
      <CustomMenu
        id="Actions for items"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        color="warning"
        MenuListProps={{
          sx: { width: '160px' }
        }}
      >
        <MenuItem onClick={handleDeleteModalOpen} disabled={isDeleteActionDisabled}>
          <Typography color="error">Delete</Typography>
        </MenuItem>
      </CustomMenu>
    </>
  );
};
export default TemplateActionsMenu;
