import React from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { format, parse } from 'date-fns';
import { useSnackbar } from 'notistack';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { syncRecommendedScheduleTimes as SYNC_RECOMMENDED_SCHEDULE_TIMES } from 'graphql/queries';
import Accordion from 'modules/Sync/Connections/components/ImportAccordion';
import { DESKTOP_CONNECTION_TYPE_MAPPING } from 'modules/Sync/Constants/constants';
import { getSyncAllConfigData } from 'modules/Sync/helpers/extractWorkflowConfig';
import SyncFilesForm from 'modules/Sync/WorkflowEditor/Forms/Shared/SyncFilesForm';
import { parseResponseScheduleSyncJson } from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';

import { useImportWorkflowsContext } from '../../components/ImportWorkflowsContext';

const { fetchPolicy } = FETCH_POLICIES.CACHE_FIRST;

const ImportSyncProperties = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar() || {};
  const { updateSettings, workflowSyncData } = useImportWorkflowsContext();
  const [getRecommendedTimes, { loading }] = useLazyQuery(gql(SYNC_RECOMMENDED_SCHEDULE_TIMES), { fetchPolicy });
  const { mappingCount, interval } = getSyncAllConfigData(workflowSyncData);

  const { mappings } = data;
  const onChangeHandler = () => {};

  const getUsedSlots = (days) =>
    workflowSyncData?.reduce((acc, workflowItem) => {
      workflowItem?.mappings?.forEach((mapItem) => {
        const { days: scheduleDays, startTime } =
          JSON.parse(mapItem?.solitaryFlowSettings?.[0]?.scheduleSyncConfig) || {};
        if (days.some((day) => scheduleDays.includes(day))) acc.push(startTime);
      });
      return acc;
    }, []);

  const getRecommendedTimesOnCompletePayload = (response, days, callback, scheduleConfig, workflowId, mappingId) => {
    const { syncRecommendedScheduleTimes } = response;
    const usedSlots = getUsedSlots(days);
    const filteredSlots = syncRecommendedScheduleTimes?.filter(
      (item) => !usedSlots.includes(format(parse(item, 'h:mm a', new Date()), 'h:mm a'))
    );

    if (filteredSlots?.[0]) {
      const updatedScheduleConfig = JSON.stringify({ ...scheduleConfig, startTime: filteredSlots?.[0] });
      callback?.(filteredSlots);
      updateSettings({ scheduleSyncConfig: updatedScheduleConfig }, workflowId, mappingId);
    } else {
      enqueueSnackbar('No new slots found. Please enter start time manually', { ...VARIANT_ERROR });
    }
  };

  const getRecommendedTimeHandler = async ({ config, workflowId, mappingId, callback, suggestedTime }) => {
    const scheduleConfig = JSON.parse(config);
    const { days = [] } = scheduleConfig;

    if (suggestedTime) {
      const updatedScheduleConfig = JSON.stringify({ ...scheduleConfig, startTime: suggestedTime });
      updateSettings({ scheduleSyncConfig: updatedScheduleConfig }, workflowId, mappingId);
      return callback?.();
    }

    if (days.length) {
      getRecommendedTimes({
        variables: { query: { mappingCount, interval, days: days.join(',') } },
        onCompleted: async (response) => {
          getRecommendedTimesOnCompletePayload(response, days, callback, scheduleConfig, workflowId, mappingId);
        },
        fetchPolicy
      });
    }
    return true;
  };

  return (
    <Box mx={2}>
      <Accordion
        hideFirstBorder
        hideLastBorder
        items={mappings?.map((item, index) => {
          const importMethods = { workflowId: data?.workflowId, mappingId: item?.mappingId, updateSettings };
          const mappingSetting = workflowSyncData
            ?.find((item) => item.workflowId === data?.workflowId)
            ?.mappings?.find((mapItem) => mapItem.mappingId === item.mappingId)?.solitaryFlowSettings?.[0];

          const sourceItem = item?.solitaryFlowConfigurations?.[0]?.source;

          const parsedMappingSetting = {
            ...mappingSetting,
            scheduleSyncConfig: parseResponseScheduleSyncJson(mappingSetting?.scheduleSyncConfig)
          };

          const disableRecommendTime = loading || !JSON.parse(mappingSetting?.scheduleSyncConfig)?.days?.length;

          return {
            id: index,
            title: <Typography variant="formBoldLabel">{item?.mappingName}</Typography>,
            content: (
              <SyncFilesForm
                onSyncSettingsChange={onChangeHandler}
                syncSettings={parsedMappingSetting || {}}
                importMethods={importMethods}
                selectedAction={defaultAction}
                selectedSource={{
                  connectionType: DESKTOP_CONNECTION_TYPE_MAPPING?.[sourceItem?.connectionName] || ''
                }}
                recommendTime={({ callback, suggestedTime }) =>
                  getRecommendedTimeHandler({
                    config: mappingSetting?.scheduleSyncConfig,
                    workflowId: data?.workflowId,
                    mappingId: item.mappingId,
                    callback,
                    suggestedTime
                  })
                }
                disableRecommendTime={disableRecommendTime}
                loadingRecommendTime={loading}
              />
            )
          };
        })}
        sx={{
          marginTop: '0 !important',
          '& .MuiAccordionSummary-root, .MuiAccordionDetails-root': { padding: 0 }
        }}
      />
    </Box>
  );
};

export default ImportSyncProperties;

const defaultAction = {
  id: '###'
};
