import { useState } from 'react';

import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';

import getClient from 'apollo/client';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { syncGetRealtimeLogDetails as SYNC_GET_REALTIME_LOG_DETAILS } from 'graphql/queries';
import { formatTo } from 'helpers/dateFunctions';
import { getExcelFromJson } from 'helpers/jsonToExcelHelpers';

const getLogsFromAPI = async ({ variables }) => {
  const client = await getClient();
  const response = await client.query({
    query: gql(SYNC_GET_REALTIME_LOG_DETAILS),
    variables
  });
  return response?.data?.syncGetRealtimeLogDetails || {};
};

export default function useRealtimeLogsDownloader() {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const downloadRealtimeLogsHandler = async (variables, fileName = 'sheet') => {
    try {
      setLoading(true);
      const { data, headers } = await getLogsFromAPI({ variables });
      if (!data) throw new Error('No data found');

      const columns = Object.keys(headers).reduce((prevItem, item) => {
        if (item !== '__typename') prevItem.push({ header: headers[item], key: item });
        return prevItem;
      }, []);

      const updatedData = data?.map(({ syncTime, ...item }) => ({
        ...item,
        syncTime: formatTo(new Date(syncTime), 'YYYY-MM-DD hh:mm A')
      }));
      await getExcelFromJson({ columns, data: updatedData, fileName });
    } catch (err) {
      const errorMessage = err?.message || 'Error downloading this file';
      enqueueSnackbar(errorMessage, VARIANT_ERROR);
    } finally {
      setLoading(false);
    }
  };

  return { downloadRealtimeLogsHandler, loading };
}
