import { format } from 'date-fns';
import dayjs from 'dayjs';

// return a date with the format yyyy-mm-dd
export const formatToBackendDateString = (date) => date.toISOString().slice(0, 10);

export const getNextDays = (days = 0) => {
  const today = new Date();
  return today.setDate(today.getDate() + days);
};

export const TODAY = getNextDays();
export const YESTERDAY = getNextDays(-1);
export const TOMORROW = getNextDays(1);

export const addInitialZero = (value) => (value < 10 ? `0${value}` : value);

export const getParsedDate = (renewalDate) => {
  if (!renewalDate) return null;
  if (renewalDate?.nativeEvent instanceof Event) return null;
  const date = new Date(`${renewalDate}Z`);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${addInitialZero(month)}/${addInitialZero(day)}/${date.getFullYear()}`;
};

export const getDaysBetween = (firstDate, secondDate) => {
  const oneDay = 24 * 60 * 60 * 1000;
  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);

  return Math.round((firstDate - secondDate) / oneDay);
};

export const getParsedDateMinifiedYear = (value) => {
  const date = new Date(value);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${addInitialZero(month)}/${addInitialZero(day)}/${date.getFullYear().toString().slice(-2)}`;
};

export const getDaysRemaining = (workRequestStatusName, daysRemaining) =>
  workRequestStatusName === 'Completed' ? `${daysRemaining}`.replace('-', '') : daysRemaining;

export const getFormattedDateTime = (date, hour12 = false, separator = '  ', appendToDate = 'Z') => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12,
    timeZone
  };
  const dateObj = new Date(`${date}${appendToDate}`);
  return new Intl.DateTimeFormat('en', options).format(dateObj).replaceAll(',', separator);
};

export const isPastDate = (needBy) => new Date(new Date(needBy).toDateString()) < new Date(new Date().toDateString());

export const addCurrentHours = (date) => {
  const currentDate = new Date();
  const currentTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
  const dateTimeString = `${getParsedDate(date)} ${currentTime}`;
  const dateTime = new Date(dateTimeString);
  return Boolean(date) === false ? null : format(dateTime, 'EEE LLL d yyyy H:mm:ss xxxx');
};

export const formatLocalTime = (date) => new Date(date?.includes('Z') ? date : `${date}Z`);

export const getTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone;
};

export const formatTo = (date: number | Date, pattern = 'MM/DD/YYYY') => dayjs(date).format(pattern);
