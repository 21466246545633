import React from 'react';

import { CircularProgress, Box } from '@mui/material';

const WarningLoader = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      backgroundColor: 'white'
    }}
  >
    <CircularProgress color="warning" />
  </Box>
);

export default WarningLoader;
