import { useCallback, useEffect, useState } from 'react';

import { Transition } from 'history';
import { useLocation, useNavigate } from 'react-router';

import { useBlocker } from './useBlocker';

export function useCallbackPrompt(
  when: any,
  allowedLeavingLocations: string[] = []
): [boolean, () => void, () => void] {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [lastLocation, setLastLocation] = useState<Transition | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setLastLocation(null);
  }, []);

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation: Transition) => {
      if (allowedLeavingLocations?.includes(nextLocation.location.pathname)) {
        nextLocation.retry();
        confirmNavigation();
        setLastLocation(nextLocation);
        return true;
      }
      if (
        !confirmedNavigation &&
        (nextLocation.location.pathname !== location.pathname ||
          nextLocation.location.hash !== location.hash ||
          nextLocation.location.search !== location.search)
      ) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, location]
  );

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(
        lastLocation.location?.pathname +
          lastLocation.location?.hash +
          lastLocation.location?.search
      );
      setConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
