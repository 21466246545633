const fileDetailsDataGridStyles = {
  marginBottom: 1,
  '.MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: 0.25 },
  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: 1 },
  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: 2 }
};

export default fileDetailsDataGridStyles;
