import React from 'react';

import { Radio } from '@mui/material';

const RadioOption = ({ sx, ...restProps }) => (
  <Radio
    sx={{
      ...sx,
      '& .MuiSvgIcon-root': {
        fontSize: 17
      }
    }}
    color="secondary"
    {...restProps}
  />
);

export default RadioOption;
