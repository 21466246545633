import { ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { SYNC_ENTITY_NAME_MAX_LENGTH } from 'modules/Sync/Constants/constants';

const WorkflowTextField = ({
  control,
  required = true,
  disabled,
  maxLength = SYNC_ENTITY_NAME_MAX_LENGTH,
  errors,
  ...rest
}) => (
  <ControlTextField
    control={control}
    name="workflowName"
    label="Workflow Name*"
    maxLength={maxLength}
    errors={errors}
    requiredNoSpace={required}
    disabled={disabled}
    {...rest}
  />
);
export default WorkflowTextField;
