import React from 'react';

import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { StylesProvider, jssPreset } from '@mui/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { create } from 'jss';
import { SnackbarProvider } from 'notistack';

import CustomThemeProvider from 'styles/CustomThemeProvider';

const jss = create({ plugins: [...jssPreset().plugins] });

LicenseInfo.setLicenseKey(
  'e625c1783873d81d841b945e37de9d60Tz00NzgyOSxFPTE2OTAxMzI3NzI0NjksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const Wrapper = ({ children, mountPoint, cache }) => (
  <CacheProvider value={cache}>
    <StylesProvider jss={jss}>
      <CustomThemeProvider shadowRootElement={mountPoint}>
        <CssBaseline />

        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          maxSnack={3}
          autoHideDuration={3000}
        >
          {children}
        </SnackbarProvider>
      </CustomThemeProvider>
    </StylesProvider>
  </CacheProvider>
);

export { Wrapper };
