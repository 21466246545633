import { v4 as uuidv4 } from 'uuid';

import { formatToBackendDateString, getFormattedDateTime } from 'helpers/dateFunctions';
import EmptyDataGridCell from 'modules/Sync/components/EmptyDataGridCell';
import { convertBytes } from 'modules/Sync/helpers/common';
import { getLogFileName } from 'modules/Sync/Logs/DownloadLogsService';

const DEFAULT_FIELDS = {
  syncType: '',
  source: '',
  destinations: '',
  '*download': ''
};

const EmptyCell = () => <EmptyDataGridCell placeholder="" />;

const getFileCount = (succeded = 0, total = 0) => `${succeded ?? 0}/${total ?? 0}`;

const parseActionsForLogsTable = ({
  workflowName,
  workflowId,
  mappingName,
  mappingId,
  actionAlias,
  source,
  destinations,
  fileSyncLogId,
  syncStatus,
  lastSyncTime,
  totalFiles = 0,
  successfulFilesCount = 0,
  totalSize = 0,
  ...rest
}) => {
  const flowUid = uuidv4();
  const lastSyncFileDate = formatToBackendDateString(new Date(lastSyncTime));
  const fileName = getLogFileName({
    workflowName,
    mappingName,
    fileSyncLogId,
    lastSyncFileDate
  });

  return {
    ...DEFAULT_FIELDS,
    id: `${fileSyncLogId}-${flowUid}`,
    uid: `${workflowId}-${mappingId}-${fileSyncLogId}`,
    source: source?.name,
    destinations: destinations?.map(({ name }) => name).join(', '),
    fileSyncLogId,
    syncStatus,
    name: `${workflowName} / ${mappingName} / ${actionAlias}` || '',
    lastSyncTime: getFormattedDateTime(lastSyncTime, true) || <EmptyCell />,
    fileName,
    numFiles: getFileCount(successfulFilesCount, totalFiles),
    totalSize: convertBytes(totalSize),
    ...rest
  };
};

const LogsDataGridTableRows = (logs = []) => {
  if (!logs) return [];
  return logs.map((log) => parseActionsForLogsTable(log));
};

export default LogsDataGridTableRows;
