import { useMemo } from 'react';

import { getTimeZone } from 'helpers/dateFunctions';
import OutlinedButton from 'modules/Sync/components/OutlinedButton';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import useLogsLazyPaginationHook from 'modules/Sync/Logs/Contexts/useLogsLazyPaginationHook';
import { collapseAllRows } from 'modules/Sync/Logs/RealTimeLogsDataGridTable/realtimeDataGridHelpers';

const RefreshLogsBtn = ({ hidden = false, apiRef, lazyLoadRealTimeLogs, disabled = false }) => {
  const { activeLogTab, logFilter, realTimeLogSearchPhrase: searchPhrase } = useSyncLogsContext();

  const { lazyLoadLogs } = useLogsLazyPaginationHook();
  const timeZone = useMemo(() => getTimeZone(), []);
  const handleRefetchRealtimeLogs = async () => {
    apiRef?.current?.scrollToIndexes({ rowIndex: 0 });
    collapseAllRows({ apiRef });
    lazyLoadRealTimeLogs({ timeZone, logFilter, searchPhrase });
  };

  const handleClick = () => (activeLogTab ? handleRefetchRealtimeLogs() : lazyLoadLogs());

  return hidden ? (
    ''
  ) : (
    <OutlinedButton
      onClick={handleClick}
      sx={{ border: 'solid 1px', whiteSpace: 'nowrap', width: 171 }}
      label="REFRESH LOGS"
      disabled={disabled}
    />
  );
};

export default RefreshLogsBtn;
