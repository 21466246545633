import { useRef } from 'react';

import useDeleteFlowDataApiAction from 'modules/Sync/WorkflowEditor/apiActions/deleteFlowData';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import useSaveMappingAction from 'modules/Sync/WorkflowEditor/editorHooks/useSaveMappingAction';

const useUnmapAction = () => {
  const {
    mappingId,
    workflowElements,
    workflowMappings,
    setWorkflowMappings,
    setWorkflowElements
  } = useSyncWorkflowEditorContext();
  const { saveMappingAction } = useSaveMappingAction();
  const { deleteFlowData } = useDeleteFlowDataApiAction({
    refetchQueries: [
      'SyncGetConnectionById',
      'SyncConnections',
      'SyncGetWorkflowMappingConfiguration'
    ]
  });
  const elementsRef = useRef();
  const mappingsRef = useRef();
  elementsRef.current = workflowElements;
  mappingsRef.current = workflowMappings;

  const getFilteredElements = (nodeId) =>
    elementsRef.current.filter((value) => nodeId !== value.source && nodeId !== value.target);

  const getFilteredMappings = (nodeId) =>
    mappingsRef.current.filter(({ actionNodeId }) => nodeId !== actionNodeId);

  const unmapAction = (nodeId, flowId) => {
    deleteFlowData({
      mappingId,
      flowId,
      successMessage: 'Mapping flow unmapped',
      onCompleted: () => {
        const elements = getFilteredElements(nodeId);
        const mappings = getFilteredMappings(nodeId);
        setWorkflowElements(elements);
        setWorkflowMappings(mappings);
        saveMappingAction({ successMessage: '' }); /* Trigger canvas save */
      }
    });
  };

  return { unmapAction };
};

export default useUnmapAction;
