import React from 'react';

import { useMutation, gql } from '@apollo/client';
import { FormControl, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { syncDeleteWorkflow as DELETE_WORKFLOW } from 'graphql/mutations';
import { dispatchArchivedWorkflowGridEvent } from 'helpers/domHelpers';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';
import useArchivedWorkflowsLazyPaginationHook from 'modules/Sync/Workflows/Contexts/useArchivedWorkflowsLazyPaginationHook';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import { cleanedSolitaryFlowId } from 'modules/Sync/Workflows/WorkflowsDataGridTable/workflowsDataGridTableHelper';
import { errorColors } from 'styles/theme/colors';

const DeleteWorkflowModal = ({ onClose }) => {
  const { activeModal } = useSyncWorkflowsContext();
  let newSelectedMappingIds = [];
  let newSelectedActionIds = [];
  const mappingIdsNottoDelete = [];
  const actionIdsNottoDelete = [];
  const open = activeModal === WORKFLOW_MODAL_KEYS.DELETE_WORKFLOW;

  const [errorMessage, setErrorMessage] = React.useState('');
  const { selectedWorkflowIds, selectedMappingIds, selectedActionIds } = useSyncWorkflowsContext();
  const { archivedWorkflows } = useArchivedWorkflowsLazyPaginationHook();

  const selectedItemsLength = selectedWorkflowIds.length + selectedMappingIds.length + selectedActionIds.length;
  const methods = useForm({
    mode: 'all'
  });
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = methods;
  const cleanedSolitaryFlowIds = selectedActionIds?.map(
    (id) => cleanedSolitaryFlowId(id) /* TODO: SolitaryFlowId should be unique */
  );
  archivedWorkflows?.syncArchivedWorkflows.forEach((index) => {
    const workflowMappings = index.workflowMapping;
    let solitaryFlows = [];

    if (selectedWorkflowIds.includes(index.workflowId)) {
      workflowMappings.forEach((mapping) => {
        if (selectedMappingIds.includes(mapping.workflowMappingId)) {
          mappingIdsNottoDelete.push(mapping.workflowMappingId);
        }
      });
    }
    workflowMappings.forEach((mapping) => {
      if (selectedMappingIds.includes(mapping.workflowMappingId)) {
        solitaryFlows = mapping.solitaryFlows;
        solitaryFlows.forEach((solitaryFlow) => {
          if (cleanedSolitaryFlowIds.includes(solitaryFlow.solitaryFlowId)) {
            actionIdsNottoDelete.push(solitaryFlow.solitaryFlowId);
          }
        });
      }
    });

    newSelectedMappingIds = selectedMappingIds.filter((itemId) => !mappingIdsNottoDelete.includes(itemId));

    newSelectedActionIds = cleanedSolitaryFlowIds.filter((itemId) => !actionIdsNottoDelete.includes(itemId));
  });
  const { handleResponse } = useGraphqlResponseHandler();

  const [deleteWorkflows, { loading }] = useMutation(gql(DELETE_WORKFLOW));

  const onCloseHandler = () => {
    setErrorMessage('');
    onClose();
  };
  const onChangeHandler = () => {
    setErrorMessage('');
  };

  const onSuccessHandler = () => {
    dispatchArchivedWorkflowGridEvent();
    onCloseHandler();
  };

  const submit = handleSubmit(async (formValues) => {
    await handleResponse(
      deleteWorkflows,
      {
        variables: {
          body: {
            WorkflowIds: selectedWorkflowIds,
            MappingIds: newSelectedMappingIds,
            SolitaryFlowIds: newSelectedActionIds,
            Email: formValues.email
          }
        }
      },
      { successMessage: 'Workflow/Mapping/Action(s) successfully deleted' },
      onSuccessHandler,
      (errorMessage) => {
        setErrorMessage(errorMessage);
      },
      { hidden: true }
    );
  });

  return (
    <DeleteModal
      loading={loading}
      open={open}
      onCloseHandler={onCloseHandler}
      submit={submit}
      selectedItemsLength={selectedItemsLength}
      onChangeHandler={onChangeHandler}
      control={control}
      errors={errors}
      errorMessage={errorMessage}
    />
  );
};
const DeleteModal = ({
  loading,
  open,
  onCloseHandler,
  submit,
  selectedItemsLength,
  onChangeHandler,
  control,
  errors,
  errorMessage
}) => {
  const { firstSelectedItemName } = useSyncWorkflowsContext();

  return (
    <CustomModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={open}
      onClose={onCloseHandler}
      title="Confirm delete"
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      paperSx={{ width: 600 }}
      footer={
        <ModalFooter
          cancelBtnText="NO"
          createBtnText="CONFIRM"
          loading={loading}
          onModalClose={onCloseHandler}
          onSubmit={submit}
          isDelete
        />
      }
    >
      <Typography variant="body1" mb="12px">
        Are you sure you want to delete the selected workflow/mapping/action(s)&nbsp;
        <Typography fontWeight={700} display="inline-flex">
          <Typography fontWeight={700}> {firstSelectedItemName}</Typography>
          {selectedItemsLength > 1 && <Typography>&nbsp;and {selectedItemsLength - 1} other{selectedItemsLength > 2 && 's'}</Typography>}
        </Typography>?
      </Typography>

      <Typography pb="6px">Please type your email to confirm deletion:</Typography>
      <FormControl size="small" fullWidth>
        <ControlTextField
          autoFocus
          required
          email
          control={control}
          name="email"
          label="Email"
          maxLength={32}
          errors={errors}
          helperText={errors?.message}
          errorOnFocusOnly
          onChange={onChangeHandler}
          sx={{ margin: 0 }}
        />
      </FormControl>
      {errorMessage.length > 0 && (
        <Typography sx={{ color: errorColors.main, fontSize: 13 }}>{errorMessage}</Typography>
      )}
    </CustomModal>
  );
};

export default DeleteWorkflowModal;
