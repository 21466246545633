import React from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import { Custom, IconStyled } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import { syncColors } from 'styles/theme/colors';

const LogHeader = () => {
  const { activeLogTab, setActiveLogTab } = useSyncLogsContext();

  const onChangeHandler = (event, index) => {
    event.preventDefault();
    setActiveLogTab(index);
  };
  return (
    <Box sx={{ position: 'fixed', left: 250, top: 0 }}>
      <Tabs
        value={activeLogTab}
        onChange={onChangeHandler}
        variant="fullWidth"
        sx={{
          paddingX: 1,
          '.MuiTabs-flexContainer': { height: '60px' },
          '.Mui-selected': { color: `${syncColors.text} !important` },
          '.MuiTabs-indicator': { backgroundColor: syncColors.text }
        }}
      >
        <StyledTab
          label="Classic Sync"
          icon={
            <IconStyled
              icon={
                <NavItemTooltip title="Classic Sync Logs" placement="bottom">
                  <Custom.UpdateIcon sx={{ width: 20, height: 20 }} />
                </NavItemTooltip>
              }
              sx={{ marginBottom: '0 !important', display: 'flex', alignItems: 'center' }}
            />
          }
          sx={{ width: 180 }}
        />
        <StyledTab
          label="Real-Time Sync"
          icon={
            <NavItemTooltip title="Real Time Sync Logs" placement="bottom">
              <Custom.SyncIcon sx={{ width: 20, height: 20 }} />
            </NavItemTooltip>
          }
          sx={{ width: 180 }}
        />
      </Tabs>
    </Box>
  );
};

export default LogHeader;

const StyledTab = ({ sx, ...rest }) => (
  <Tab
    disableRipple
    {...rest}
    sx={{
      justifyContent: 'space-evenly',
      display: 'flex',
      flexFlow: 'row',
      color: 'secondary',
      marginRight: 1,
      fontWeight: 500,
      fontSize: 15,
      ...sx
    }}
  />
);
