export const getObjectKeyByValue = (obj, value) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, keyValue] of Object.entries(obj)) {
    if (value === keyValue) {
      return key;
    }
  }
  return '';
};

export const getCleanObj = (obj, clean = []) => {
  const cleanItem = obj;
  clean.forEach((e) => delete cleanItem[e]);
  return cleanItem;
};
export const objIsEmpty = (obj) => Object.keys(obj).length === 0;

export const arePropertiesInObject = (obj1, obj2) =>
  Object.keys(obj2).every((key) => Object.prototype.hasOwnProperty.call(obj1, key) && obj1[key] === obj2[key]);
