import React, { useRef, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { Stack, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import { getSupportedExtensions as GET_SUPPORTED_EXTENSIONS } from 'graphql/queries';
import CustomModal from 'modules/Sync/components/CustomModal';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { CONNECTOR_NODE_TYPES } from 'modules/Sync/Constants/constants';
import {
  PublishActionFormProvider,
  usePublishActionFormContext
} from 'modules/Sync/WorkflowEditor/Contexts/PublishActionContext';
import { validateSyncSettings } from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';

import ModalFooter from './ModalFooter';
import PublishActionForm from './PublishActionForm';
import { useProceedToNextConnector, useSubmitMapping } from './publishActionHelper';

const modalProps = {
  sx: { '& .MuiPaper-root': { overflowY: 'visible' }, '& #MuiDailog-Drag-Handle': { paddingLeft: 1 } },
  contentStyles: { overflow: 'auto', width: 505, height: 709 }
};

const ModalTitle = ({ actionAlias }) => (
  <Stack direction="row" alignItems="baseline">
    <Custom.DragIndicatorIcon sx={{ height: 16 }} />
    <Typography fontWeight={600} variant="body4">
      Publish
      {actionAlias && (
        <Typography variant="body2">
          <CustomSyncTooltip title={`Action Alias: ${actionAlias}`} sx={{ maxWidth: 410 }}>
            {actionAlias}
          </CustomSyncTooltip>
        </Typography>
      )}
    </Typography>
  </Stack>
);

const PublishAction = ({ open, onClose, onCustomConnect, actionId }) => {
  const { source, currentConnectorType, resetContext, activeStep, setActiveStep, syncSettings, action, defaultSource } =
    usePublishActionFormContext();

  const [isSourceSelected, setIsSourceSelected] = useState(currentConnectorType !== CONNECTOR_NODE_TYPES.SOURCE);
  const { data: { getSupportedExtensions: Filters } = {}, loading: isLoadingFilters } = useQuery(
    gql(GET_SUPPORTED_EXTENSIONS),
    { fetchPolicy: 'no-cache' }
  );
  const isLoading = isLoadingFilters;
  const submitDisabled = isLoading;
  const sourceSubmitDisabled = submitDisabled || !source?.isSubmitted;
  const finalSubmitDisabled = submitDisabled || !validateSyncSettings(syncSettings);

  const prevSource = useRef(source?.nodeId || '');

  const { onNextAction } = useProceedToNextConnector();
  const { onSubmitAction } = useSubmitMapping();

  const onNext = () => {
    onNextAction(prevSource.current, () => {
      /* prevSource should be updated after clicking on previous */
      prevSource.current = source?.nodeId;
    });
    if (!isLoading) setIsSourceSelected(true);
  };

  const onBack = () => {
    if (activeStep === 1) {
      setIsSourceSelected(false);
    }
    setActiveStep(activeStep - 1);
  };

  const onModalClose = (_, skipPrevSourceReset = false) => {
    onClose?.();
    resetContext(() => {
      /* prevSource should only be updated in case of edit action i.e. changing source connector from A -> B 
          & if the form is submitted, skip resetting as activeMapping itself will be updated */
      if (defaultSource.nodeId && !skipPrevSourceReset) {
        prevSource.current = defaultSource.nodeId;
      }
      setIsSourceSelected(false);
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const sourceId = source.nodeId;
    onCustomConnect(actionId, sourceId, [], syncSettings?.syncType, syncSettings?.isSyncEnabled);
    onSubmitAction();
    prevSource.current = sourceId;
    return onModalClose?.(event, true);
  };

  return (
    <CustomModal
      disableModalOutsideClick
      draggable
      open={open}
      title={<ModalTitle actionAlias={action?.data?.actionAlias} />}
      onClose={onModalClose}
      footer={
        <ModalFooter
          disableSubmit={finalSubmitDisabled}
          sourceSubmitDisabled={sourceSubmitDisabled}
          onClose={onModalClose}
          onBack={onBack}
          onSubmit={onSubmit}
          onNext={onNext}
        />
      }
      {...modalProps}
    >
      <PublishActionForm
        actionId={actionId}
        filters={Filters}
        isSourceSelected={isSourceSelected}
        sourceSubmitDisabled={sourceSubmitDisabled}
        finalSubmitDisabled={finalSubmitDisabled}
        onBack={onBack}
        onNext={onNext}
      />
    </CustomModal>
  );
};

const PublishModal = (props) => {
  const { actionId } = props;
  return (
    <PublishActionFormProvider actionId={actionId}>
      <PublishAction {...props} />
    </PublishActionFormProvider>
  );
};

export default PublishModal;
