import React from 'react';

import { Box } from '@mui/system';

import OverflowTip from 'components/Tooltip/OverflowTip';

const CustomSyncTooltip = ({
  title,
  children,
  sx = {},
  tooltipSx = {},
  boxProps = {},
  placement = 'top',
  offset = { x: 0, y: 0 }
}) => (
  <OverflowTip title={title} placement={placement} offset={offset} sx={tooltipSx}>
    <Box
      component="div"
      sx={{
        display: 'inline',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...sx
      }}
      {...boxProps}
    >
      {children}
    </Box>
  </OverflowTip>
);

export default CustomSyncTooltip;
