import { useCallback, useState } from 'react';

import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { createSharepointFolder as CREATE_SHAREPOINT_FOLDER } from 'graphql/mutations';
import {
  getSharepointFolders as GET_SHAREPOINT_FOLDERS,
  getSharepointSelectedFolderIdStack as GET_SHAREPOINT_SELECTED_FOLDER_ID_STACK
} from 'graphql/queries';
import useCache from 'hooks/useCache';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import FoldersSelectDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown';
import { multiSelectFolders } from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown/folderTreeItemHelper';
import { getLatestFolders } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';

const formatFolders = (text) => text.match(/(?!.*\/).+/)?.[0];

const {
  NETWORK_ONLY: { fetchPolicy: NETWORK_ONLY_POLICY },
  NO_CACHE: { fetchPolicy: NO_CACHE_FETCH_POLICY }
} = FETCH_POLICIES;

const FETCH_FOLDERS_API = 'getSharepointFolders';

const SharepointFoldersDropdown = (props) => {
  const { connectionId, nodeId, isSource = false, isLoadingFolders = false, disabled = false } = props;
  const { fetchMoreRootFolders, folders: propFolders } = props;
  const [isInitialStackFetched, setIsInitialStackFetched] = useState(false);
  const [parentFolderId, setParentFolderId] = useState('');
  const client = useApolloClient();
  const { getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { projects = [], project = projects?.[0], folders, folder = folders?.[0], uiFolders = [] } = formValues;
  const { handleResponse } = useGraphqlResponseHandler();
  const { deleteCache } = useCache();

  const [loadSharepointFolderStack, { loading: isLoadingSharepointFolderStack }] = useLazyQuery(
    gql(GET_SHAREPOINT_SELECTED_FOLDER_ID_STACK)
  );

  const [makeSharepointFolder] = useMutation(gql(CREATE_SHAREPOINT_FOLDER));

  const updateFormValues = useCallback(
    (props) => updateConnectorNodeValues({ isSource, nodeId, props }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues, isSource, nodeId]
  );

  const fetchSharepointFolders = async (params) => {
    const { folderId, fetchPolicy = NO_CACHE_FETCH_POLICY, skip = 0, take = DROPDOWN_DEFAULT_PG_SIZE } = params;
    const variables = { query: { connectionId, siteId: project?.id, parentFolderId: folderId, skip, take } };
    const { data } = await client.query({ query: gql(GET_SHAREPOINT_FOLDERS), variables, fetchPolicy });
    return data?.getSharepointFolders || [];
  };

  const fetchMoreFoldersHandler = async ({ isRoot = false, folderId, skip, onCompleted }) => {
    if (!isRoot) {
      const sharepointFolders = await fetchSharepointFolders({ folderId, skip });
      onCompleted(sharepointFolders);
      return sharepointFolders;
    }
    await fetchMoreRootFolders(skip, { connectionId, siteId: project?.id });
    return onCompleted(getLatestFolders(propFolders));
  };

  const onFolderExpandHandler = async ({ folderId, onCompleted, skip, take }) => {
    const sharepointFolders = await fetchSharepointFolders({ folderId, skip, take });
    return onCompleted(sharepointFolders);
  };

  const onFolderSelectHandler = ({ onCompleted }) => onCompleted();

  const onCreateFolderHandler = async ({ folderId, folderName, onCompleted }) => {
    await handleResponse(
      makeSharepointFolder,
      {
        variables: {
          query: { connectionId },
          body: { SiteId: project?.id, ParentFolderId: folderId, Name: folderName }
        }
      },
      { successMessage: 'Created folder successfully' },
      async () => {
        const sharepointFoldersParams = { folderId, fetchPolicy: NETWORK_ONLY_POLICY, skip: null, take: null };
        deleteCache(FETCH_FOLDERS_API);
        const sharepointFolders = await fetchSharepointFolders(sharepointFoldersParams);
        onCompleted(sharepointFolders);
      }
    );
  };

  const onFolderDropdownClick = async () => {
    if (folder.id && !isInitialStackFetched) {
      await loadSharepointFolderStack({
        variables: { query: { connectionId, siteId: project?.id, selectedFolderId: folder?.id } },
        onCompleted: async (response) => {
          const { getSharepointSelectedFolderIdStack } = response;
          let expandedFolderIds = [...(getSharepointSelectedFolderIdStack || [])]?.reverse();
          expandedFolderIds.push(folder.id);
          expandedFolderIds = [...new Set(expandedFolderIds)];
          const closestParentFolderId = expandedFolderIds[expandedFolderIds?.length - 2] || '';
          setParentFolderId(closestParentFolderId);
          const newUiFolders = uiFolders?.map((item) => ({ ...item, parentId: closestParentFolderId }));
          await updateFormValues(
            isSource ? { ...formValues, expandedFolderIds, uiFolders: newUiFolders } : { expandedFolderIds }
          );
          setIsInitialStackFetched(true);
        }
      });
    }
  };

  const selectFolderHandler = (event, id, children) => {
    const params = { event, id, folders, uiFolders, children, parentFolderId, setParentFolderId, updateFormValues };
    return multiSelectFolders(params);
  };

  return (
    <FoldersSelectDropdown
      {...props}
      onSelect={disabled ? null : selectFolderHandler}
      isLoading={isLoadingFolders}
      disabled={isLoadingFolders || disabled}
      isLoadingFolderStack={isLoadingSharepointFolderStack}
      onFolderExpandHandler={onFolderExpandHandler}
      onFolderSelectHandler={onFolderSelectHandler}
      onCreateFolderHandler={onCreateFolderHandler}
      onFolderDropdownClick={onFolderDropdownClick}
      formatText={formatFolders}
      fetchMoreFoldersHandler={fetchMoreFoldersHandler}
      isInitialStackFetched={isInitialStackFetched}
    />
  );
};

export default SharepointFoldersDropdown;
