import { gql } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncRealTimeLogDates as SYNC_REAL_TIME_LOG_DATES } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DATAGRID_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const useRealtimeLogsLazyPaginationHook = () => {
  const [
    {
      lazyLoad: lazyLoadRealTimeLogs,
      paginationHandler: realTimeLogsPaginationHandler,
      searchHandler: realTimeLogsSearchHandler,
      onOrderby: realTimeLogsSortHandler
    },
    { loading: loadingRealtimeLogs, data: realTimelogsData }
  ] = useLazyPaginatedQuery(gql(SYNC_REAL_TIME_LOG_DATES), FETCH_POLICY, DATAGRID_DEFAULT_PG_SIZE, false);

  return {
    lazyLoadRealTimeLogs,
    realTimeLogsPaginationHandler,
    realTimeLogsSearchHandler,
    realTimeLogsSortHandler,
    loadingRealtimeLogs,
    realTimelogsData
  };
};

export default useRealtimeLogsLazyPaginationHook;
