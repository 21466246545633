import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';
import { Columns } from 'components/ItemsDatagridPro';
import Search from 'components/TableComponents/Search';
import ImportConnectionButton from 'modules/Sync/Connections/components/ImportConnectionButton';
import NewConnectionButton from 'modules/Sync/Connections/components/NewConnectionButton';
import ActionsMenu from 'modules/Sync/Connections/ConnectionHeader/ActionsMenu';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { greyColors, surfaceColors, syncColors } from 'styles/theme/colors';

const ConnectionHeader = () => {
  const { searchHandler, headerActionsDisabled, setSearchPhrase } = useSyncConnectionsContext();

  const onSearch = (searchPhrase) => {
    setSearchPhrase(searchPhrase);
    searchHandler(searchPhrase);
  };

  return (
    <HeaderWrapper>
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid container item alignItems="center" wrap="nowrap">
          <Typography variant="h5" gutterBottom>
            Connectors
          </Typography>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          wrap="nowrap"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <Search
              handleSearch={onSearch}
              placeholder="Search connectors..."
              sx={{
                '& .MuiInputBase-root': { paddingLeft: 1, background: greyColors.grey300 },
                '& .MuiInputBase-input::placeholder': {
                  color: surfaceColors.lightSurface.secondary,
                  opacity: 1
                }
              }}
              startAdornment={<Custom.Search style={{ marginRight: 10 }} />}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-around" wrap="nowrap">
        <Grid container item alignItems="center" wrap="nowrap">
          <ActionsMenu />
        </Grid>
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          justifyContent="flex-end"
          gap={1}
          sx={{
            '& .MuiTypography-root, .MuiSvgIcon-root': {
              color: headerActionsDisabled ? surfaceColors.lightSurface.disabled : syncColors.text,
              lineHeight: 1.55
            }
          }}
        >
          <Columns
            disableColumnSearch
            disabled={headerActionsDisabled}
            baseButtonSx={{
              '&:hover': { backgroundColor: syncColors.tableHover },
              '&:disabled': { color: 'action.lightSurface.disabled' }
            }}
            popperStackSx={{
              boxShadow:
                '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)'
            }}
            typoProps={{ color: syncColors.text }}
            buttonSx={{
              '& .MuiButton-root': {
                '&:hover': { backgroundColor: syncColors.tableHover }
              },
              '& .MuiTypography-button': {
                color: syncColors.text
              }
            }}
          />
          <ImportConnectionButton />
          <NewConnectionButton />
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
};

export default ConnectionHeader;

const HeaderWrapper = (props) => <Box mb="8px" {...props} />;
