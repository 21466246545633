import { useEffect } from 'react';

import { getSubmittedStatus } from 'modules/Sync/WorkflowEditor/helpers/validationsHelper';

const useWindowsHook = ({
  handleResponse,
  loadFolders,
  updateFormValues,
  connectionId,
  connectionType,
  nodeId,
  formValues,
  expandedFolderIds,
  uiFolders,
  parentFolderId
}) => {
  const isSubmitted = getSubmittedStatus(formValues);

  useEffect(() => {
    updateFormValues({
      connectionId,
      connectionType,
      nodeId,
      isSubmitted,
      expandedFolderIds,
      uiFolders: uiFolders?.map((item) => ({ ...item, parentId: parentFolderId })),
      folders: uiFolders?.map((item) => ({ ...item, parentId: parentFolderId }))
    });
    handleResponse(
      loadFolders,
      {
        connectionId
      },
      { successMessage: '' },
      () => {},
      () => {},
      {
        error: {
          autoHideDuration: 5000
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useWindowsHook;
