import { Box, IconButton } from '@mui/material';
import { gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import { Waypoint } from 'react-waypoint';

import { Custom } from 'components/Icons';
import { isNavigationKey } from 'helpers/common';
import { actionColors, surfaceColors } from 'styles/theme/colors';

const WorkflowsDataGridGroupingCell = (props) => {
  const { id, field, rowNode, lastParentRowId, onWaypointEnter } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent('cellNavigationKeyDown', props, event);
    }
  };

  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    filteredDescendantCount > 0 && (
      <Box sx={{ ml: rowNode.depth * 1.5 }} className="expansion-icon">
        <IconButton onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={-1} size="small">
          {rowNode?.childrenExpanded ? (
            <Custom.ArrowDropDownIcon style={{ fill: surfaceColors.lightSurface.primary }} />
          ) : (
            <Custom.ArrowDropDownIcon style={{ transform: 'rotate(-90deg)', fill: actionColors.lightSurface.active }} />
          )}
        </IconButton>
        {id === lastParentRowId && <Waypoint key={lastParentRowId} bottomOffset="-20%" onEnter={onWaypointEnter} />}
      </Box>
    )
  );
};

export default WorkflowsDataGridGroupingCell;
