import React, { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { syncValidateName as SYNC_VALIDATE_NAME } from 'graphql/queries';
import CustomModal from 'modules/Sync/components/CustomModal';
import { ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { VALIDATION_ENTITY } from 'modules/Sync/Constants/constants';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';

const defaultFormValues = (templateName) => ({ templateName: templateName || '' });

const CreateTemplateModal = ({ templateName = '', open = false, onClose, onSubmit }) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: defaultFormValues(templateName)
  });
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isValid }
  } = methods;

  const onModalClose = () => {
    onClose();
  };

  const onModalSubmit = handleSubmit((formValues) => {
    onSubmit?.(formValues?.templateName);
    return onModalClose();
  });

  const [validateName, { errors: validationErrors }] = useLazyQuery(gql(SYNC_VALIDATE_NAME), {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: { params: { entity: VALIDATION_ENTITY.TEMPLATE } }
  });

  const handleChange = useCallback(
    (searchName) => {
      validateName({
        variables: {
          query: {
            name: encodeURIComponent(searchName.trim())
          }
        }
      });
    },
    [validateName]
  );

  const onKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === 'Escape') onModalClose();
  };

  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={
        <Typography fontWeight={600} variant="body4">
          Save mapping as template
        </Typography>
      }
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={
        <ModalFooter
          onModalClose={onModalClose}
          createBtnText="Save"
          onSubmit={onModalSubmit}
          onSubmitDisabled={!isValid}
        />
      }
    >
      <ControlTextField
        control={control}
        name="templateName"
        label="Template Name*"
        maxLength={32}
        errors={errors}
        requiredNoSpace
        sx={{ marginBottom: 0, marginTop: 0 }}
        setError={setError}
        clearErrors={clearErrors}
        validateChange={handleChange}
        validationErrors={validationErrors}
        onKeyDown={onKeyDown}
      />
    </CustomModal>
  );
};

export default CreateTemplateModal;
