import { useEffect } from 'react';

import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { getSubmittedStatus } from 'modules/Sync/WorkflowEditor/helpers/validationsHelper';

export const useForgeMountHook = ({
  connectionId,
  connectionType,
  nodeId,
  hub,
  project,
  formValues,
  updateFormValues,
  loadSubItems,
  loadProjects,
  fetchOnMountForgeProjects,
  fetchOnMountForgeSubItems,
  fetchHubsOnLoad
}) => {
  useEffect(() => {
    const isSubmitted = getSubmittedStatus(formValues);
    updateFormValues({ connectionId, connectionType, nodeId, isSubmitted });
    fetchHubsOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchOnMountForgeProjects) {
      loadProjects({
        connectionId,
        hubId: hub?.id
      });
    }
    if (fetchOnMountForgeSubItems) {
      loadSubItems({
        connectionId,
        hubId: hub?.id,
        projectId: project?.id,
        isPublishModel: true,
        skip: 0,
        take: DROPDOWN_DEFAULT_PG_SIZE
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnMountForgeProjects, fetchOnMountForgeSubItems]);
};
