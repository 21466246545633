import { useState } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncGetWindowsFolders as SYNC_GET_WINDOWS_FOLDERS } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import useWindowsHook from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Windows/useWindowsHook';
import WindowsFoldersDropdown from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Windows/WindowsFoldersDropdown';
import { getWindowsSelectEventHandlers } from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Windows/windowsHelpers';
import FiltersAutocompleteDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FiltersAutocompleteDropdown';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_FIRST;
const ERROR_POLICY = 'all';

const Windows = ({
  connectionId = '',
  connectionType = '',
  nodeId = '',
  fileTypeFilters = [],
  showFilters = false,
  isSource = false,
  allowPrivateFiles = false
}) => {
  const { updateConnectorNodeValues, getConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { folders = [], folder = folders?.[0], uiFolders = [] } = formValues;

  const expandedFolderIds =
    folder?.id?.split('/')?.reduce((accumulator, currentFolderId) => {
      if (!accumulator.length) {
        accumulator.push(`${currentFolderId}/`);
      } else {
        const lastFolderId = accumulator[accumulator.length - 1].replace(/([^/])$/, '$1/');
        accumulator.push(`${lastFolderId}${currentFolderId}`);
      }
      return accumulator;
    }, []) || [];
  const [parentFolderId, setParentFolderId] = useState(expandedFolderIds[expandedFolderIds?.length - 2] || '');

  const { handleResponse } = useGraphqlResponseHandler();
  const [
    { lazyLoad: loadFolders, paginationHandler: fetchMoreRootFolders },
    { loading: isLoadingFolders, data: { syncGetWindowsFolders: WindowsFolders = [] } = {} }
  ] = useLazyPaginatedQuery(gql(SYNC_GET_WINDOWS_FOLDERS), FETCH_POLICY, DROPDOWN_DEFAULT_PG_SIZE, ERROR_POLICY);

  const updateFormValues = (props) =>
    updateConnectorNodeValues({
      isSource,
      nodeId,
      props: isSource ? { ...formValues, ...props } : { ...props }
    });

  const { selectFolderHandler } = getWindowsSelectEventHandlers({
    updateFormValues,
    folders,
    uiFolders,
    parentFolderId,
    setParentFolderId
  });

  useWindowsHook({
    handleResponse,
    loadFolders,
    updateFormValues,
    connectionId,
    connectionType,
    nodeId,
    formValues,
    expandedFolderIds,
    uiFolders,
    parentFolderId
  });

  return (
    <Stack spacing={2}>
      <WindowsFoldersDropdown
        connectionId={connectionId}
        folders={WindowsFolders}
        onSelect={selectFolderHandler}
        isSource={isSource}
        isLoading={isLoadingFolders}
        disabled={isLoadingFolders}
        fetchMoreRootFolders={fetchMoreRootFolders}
        nodeId={nodeId}
        multiple={isSource}
        expandedFolderIds={expandedFolderIds}
        required
      />

      <FiltersAutocompleteDropdown
        allowPrivateFiles={allowPrivateFiles}
        showFilters={showFilters}
        fileTypeFilters={fileTypeFilters}
        isSource={isSource}
      />
    </Stack>
  );
};

export default Windows;
