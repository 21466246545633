import React, { useCallback, useEffect } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Divider, FormControl, InputLabel, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { syncCreateWorkflow as SYNC_CREATE_WORKFLOW } from 'graphql/mutations';
import { syncValidateName as SYNC_VALIDATE_NAME } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import { VALIDATION_ENTITY } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import MappingTextField from 'modules/Sync/Workflows/components/MappingTextField';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';
import ModalTitle from 'modules/Sync/Workflows/components/ModalTitle';
import WorkflowTextField from 'modules/Sync/Workflows/components/WorkflowTextField';

const defaultFormValues = { workflowName: '', mappingName: '', templateName: '' };

// eslint-disable-next-line max-lines-per-function
const CreateWorkflowModal = ({ open = false, onClose, onSubmit }) => {
  const { handleResponse } = useGraphqlResponseHandler();
  const {
    mappingId: templateId,
    currentMapping: { mapping: { mappingName: templateName = '' } = {} }
  } = useSyncWorkflowEditorContext();
  const methods = useForm({ mode: 'all', defaultValues: { ...defaultFormValues, templateName } });
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    setError,
    clearErrors
  } = methods;
  const [createWorkflow, { loading }] = useMutation(gql(SYNC_CREATE_WORKFLOW), {
    refetchQueries: ['SyncWorkflows']
  });

  useEffect(() => {
    setValue('templateName', templateName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateName]);

  const onModalClose = () => {
    onClose?.();
  };

  const onSubmitHandler = handleSubmit((formValues) => {
    const { workflowName, mappingName } = formValues;

    return handleResponse(
      createWorkflow,
      {
        variables: {
          body: {
            WorkflowName: workflowName,
            MappingName: mappingName,
            TemplateMappingId: templateId
          }
        }
      },
      {
        successMessage: 'Workflow successfully created'
      },
      onSubmit
    );
  });

  const [validateName, { errors: validationErrors }] = useLazyQuery(gql(SYNC_VALIDATE_NAME), {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: { params: { entity: VALIDATION_ENTITY.WORKFLOW } }
  });

  const handleChange = useCallback(
    (searchName) => {
      validateName({
        variables: {
          query: {
            name: encodeURIComponent(searchName.trim())
          }
        }
      });
    },
    [validateName]
  );

  const onKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === 'Escape') onModalClose();
  };
  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={<ModalTitle title="Create workflow" />}
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={
        <ModalFooter
          loading={loading}
          onModalClose={onModalClose}
          onSubmit={onSubmitHandler}
          onSubmitDisabled={!isValid}
        />
      }
    >
      <Stack gap={1}>
        <FormControl size="small" fullWidth color="secondary">
          <WorkflowTextField
            control={control}
            disabled={loading}
            errors={errors}
            sx={{ marginBottom: 0, marginTop: 0 }}
            setError={setError}
            clearErrors={clearErrors}
            validateChange={handleChange}
            validationErrors={validationErrors}
            onKeyDown={onKeyDown}
          />
          <MappingTextField disabled={loading} control={control} errors={errors} onKeyDown={onKeyDown} />
        </FormControl>
        <Divider />
        <InputLabel id="tempate-section">
          <Typography variant="subtitle2" color="primary">
            From existing template
          </Typography>
        </InputLabel>
        <FormControl size="small" fullWidth>
          <ControlTextField
            control={control}
            name="templateName"
            label="Template Name"
            disabled
            errors={errors}
            sx={{ marginBottom: 0, marginTop: 0 }}
          />
        </FormControl>
      </Stack>
    </CustomModal>
  );
};

export default CreateWorkflowModal;
